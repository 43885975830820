import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Tab,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";
import useNotistack from "../../../../hooks/useNotistack";
import CloseButton from "../../../../components/UI/CloseButton";
import { useRecordSelection } from "../../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import { colors } from "../../../../constants/colors";
import {
  Content,
  Heading,
  NoShadaowWrapper,
} from "../../../../components/style/style";
import InfoLabel from "../../../../components/UI/InfoLabel";
import { RoundTabs } from "../../../../components/UI/RoundTabs";
import InputField from "../../../../components/UI/InputField";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import BtnLink from "../../../../components/UI/BtnLink";
import Choices from "../../Choices";

const Students = ({
  formik,
  handleClose,
  fieldTypeList,
  sectionOptions,
  flowType,
  stageNames,
  setStageNames,
  subStageNames,
  setSubStageNames,
}) => {
  const [open, setOpen] = useState(false);
  const [openField, setOpenField] = useState(false);
  const [opensubStage, setOpensubStage] = useState(false);
  const [openStage, setOpenStage] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [stageTabIndex, setStageTabIndex] = useState(0);
  const [deleteStageIndex, setDeleteStageIndex] = useState(null);
  const [index, setIndex] = useState();
  const [stageIndex, setStageIndex] = useState();
  const [subsectionIndex, setSubsectionIndex] = useState();
  const [fieldIndex, setFieldIndex] = useState();
  const { selectedRecord } = useRecordSelection();
  const [dataChange, setDataChange] = useState(false);
  const [existingFlag, setExistingFlag] = useState(false);
  const [dataChangeCount, setDataChangeCount] = useState(0);

  const [uniqueStageNameError, setUniqueStageNameError] = useState("");

  const [uniqueSubStageNameError, setUniqueSubStageNameError] = useState("");
  const [uniqueFieldNameError, setUniqueFieldNameError] = useState("");

  // -----------states to set error index for flow -----------
  const [errorFlowIndex, setErrorFlowIndex] = useState(0);
  const [errorStageIndex, setErrorStageIndex] = useState(0);
  const [errorSubStageIndex, setErrorSubStageIndex] = useState(0);
  const [errorFieldIndex, setErrorFieldIndex] = useState(0);
  const [isSectionName, setIsSectionName] = useState(false);
  const [oneFlow, setOneFlow] = useState(false);

  const { notify } = useNotistack();
  const { t } = useTranslation();

  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  // -------------get GUID of 12 Aplhanumeric charcters------------------------
  const getGUID = () => {
    return Array.from(Array(12), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleStageTabChange = (event, newIndex) => {
    setStageTabIndex(newIndex);
  };

  const handleDailog = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };
  const handleFieldDailog = (index) => {
    setOpenField(true);
  };
  const handleSubstageDailog = (index) => {
    setOpensubStage(true);
  };
  const handleStageDailog = (index) => {
    setDeleteStageIndex(index);
    setOpenStage(true);
  };

  const removeStage = (index, stageIndex) => {
    setIsDirty(true);

    let previousDeleteFlow = formik.values.delete_flow || [];

    const subStageIds = formik.values.student_flows[index]["stages"][
      stageIndex
    ]["sub_stage_list"].map((subStage) => subStage.id);

    const newIds = subStageIds.filter((id) => !previousDeleteFlow.includes(id));

    const updatedDeleteFlow = [...previousDeleteFlow, ...newIds];

    formik.setFieldValue("delete_flow", updatedDeleteFlow);

    [...formik.values.student_flows][index]["stages"].splice(stageIndex, 1);
    formik.setFieldValue(
      `student_flows.${index}`,
      formik.values.student_flows[index]
    );
  };

  const removesubStage = (index, stageIndex, subsectionIndex) => {
    let previousDeleteFlow = formik.values.delete_flow || [];

    const subStageId =
      formik.values.student_flows[index]["stages"][stageIndex][
        "sub_stage_list"
      ][subsectionIndex].id;

    const updatedDeleteFlow = [...previousDeleteFlow, subStageId];

    formik.setFieldValue("delete_flow", updatedDeleteFlow);

    [...formik.values.student_flows][index]["stages"][stageIndex][
      "sub_stage_list"
    ].splice(subsectionIndex, 1);
    formik.setFieldValue(
      `student_flows.${index}.stages.${stageIndex}.sub_stage_list`,
      formik.values.student_flows[index]["stages"][stageIndex]["sub_stage_list"]
    );
  };

  const removeField = (index, stageIndex, subsectionIndex, fieldIndex) => {
    [...formik.values.student_flows][index]["stages"][stageIndex][
      "sub_stage_list"
    ][subsectionIndex]["field_json"].splice(fieldIndex, 1);
    formik.setFieldValue(
      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subsectionIndex}.field_json`,
      formik.values.student_flows[index]["stages"][stageIndex][
        "sub_stage_list"
      ][subsectionIndex]["field_json"]
    );
    let count = dataChangeCount - 1;
    if (count <= 0) {
      formik.setFieldValue(
        `student_flows.${index}.data_change`,
        false || existingFlag
      );
    }
  };

  const reduceTab = async (index) => {
    if (index === tabIndex && index !== 0) {
      setTabIndex(index - 1);
    }
  };

  const reduceStageTab = async (index) => {
    if (index === stageTabIndex && index !== 0) {
      setStageTabIndex(index - 1);
    }
  };

  useEffect(() => {
    if (formik.values.student_flows?.length <= tabIndex) {
      setTabIndex(formik.values.student_flows?.length - 1);
    }
  }, [tabIndex, formik.values.student_flows?.length]);

  const removeFlow = (index) => {
    let updatedDeleteFlow = formik.values.delete_flow || [];

    formik.values.student_flows[index]["stages"].forEach((stage) => {
      stage["sub_stage_list"].forEach((subStage) => {
        const subStageId = subStage.id;
        if (!updatedDeleteFlow.includes(subStageId)) {
          updatedDeleteFlow.push(subStageId);
        }
      });
    });

    formik.setFieldValue("delete_flow", updatedDeleteFlow);
    let oldFlowData = [...formik.values.student_flows];
    oldFlowData.splice(index, 1);
    formik.setFieldValue(`student_flows`, oldFlowData);
  };

  const handleYes = () => {
    setOpen(false);
    reduceTab(deleteIndex).then(() => removeFlow(deleteIndex));
  };
  const handleNo = () => {
    setOpen(false);
  };
  const handleFieldYes = () => {
    removeField(index, stageIndex, subsectionIndex, fieldIndex);
    setOpenField(false);
  };
  const handleFieldNo = () => {
    setOpenField(false);
  };
  const handlesubStageYes = () => {
    removesubStage(index, stageIndex, subsectionIndex);
    setOpensubStage(false);
  };
  const handlesubStageNo = () => {
    setOpensubStage(false);
  };

  const handleStageYes = () => {
    reduceStageTab(deleteStageIndex).then(() => removeStage(index, stageIndex));
    setOpenStage(false);
  };
  const handleStageNo = () => {
    setOpenStage(false);
  };

  const handleFormChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    setIsDirty(true);
  };

  const addFields = (
    choices,
    index,
    stageIndex,
    subSectionIndex,
    fieldIndex
  ) => {
    if (choices) {
      let oldFields = [...choices];
      console.log("old choicces ->>>>> ", oldFields);
      oldFields.push("");
      console.log("new choicces ->>>>> ", oldFields);
      formik.setFieldValue(
        `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subSectionIndex}.field_json.${fieldIndex}.choices`,
        oldFields
      );
    } else {
      formik.setFieldValue(
        `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subSectionIndex}.field_json.${fieldIndex}.choices`,
        []
      );
    }
  };

  const checkDataChange = (value, index) => {
    let res = value || existingFlag;
    setExistingFlag(res);
    if (!dataChange) {
      formik.setFieldValue(`student_flows.${index}.data_change`, res);
    }
  };

  const checkUniqueStageName = (value, flowIndex, stageIndex) => {
    const uniqueElements = [];
    const duplicates = [];

    stageNames.forEach((stageName, stageNameIndex) => {
      if (uniqueElements.includes(stageName.toLowerCase())) {
        duplicates.push(stageName.toLowerCase());
      } else {
        uniqueElements.push(stageName.toLowerCase());
      }
    });

    if (
      uniqueElements.includes(value.toLowerCase()) &&
      uniqueElements.indexOf(value) !== stageIndex
    ) {
      // setUniqueStageNameError("Stage Name Already Exists.");
      setErrorFlowIndex(flowIndex);
      setErrorStageIndex(stageIndex);
    } else {
      setUniqueStageNameError("");
    }
  };

  const checkUniqueSubStageName = (
    value,
    stageNameValue,
    flowIndex,
    stageIndex,
    subStageIndex
  ) => {
    const uniqueElements = [];
    const duplicates = [];

    subStageNames.forEach((subStageName, subStageNameIndex) => {
      let subStage = subStageName[stageNameValue];
      subStage?.forEach((ele, index) => {
        if (uniqueElements.includes(ele.toLowerCase())) {
          duplicates.push(ele.toLowerCase());
        } else {
          uniqueElements.push(ele.toLowerCase());
        }
      });
    });

    if (
      uniqueElements.includes(value.toLowerCase()) &&
      uniqueElements.indexOf(value) !== subStageIndex
    ) {
      // setUniqueSubStageNameError("Sub Stage Name Already Exists.");
      setErrorFlowIndex(flowIndex);
      setErrorStageIndex(stageIndex);
      setErrorSubStageIndex(subStageIndex);
    } else {
      setUniqueSubStageNameError("");
    }
  };

  const updateSubStageObject = (index, stageIndex) => {
    let currentStageNameIndex = stageNames.indexOf(
      formik.values.student_flows?.[index]?.stages[stageIndex]?.stage_name
    );

    let currentStageName =
      formik.values.student_flows?.[index]?.stages[stageIndex]?.stage_name;

    if (
      !subStageNames?.[currentStageNameIndex]?.[currentStageName]?.includes(
        formik.values.student_flows[index].stages[stageIndex].sub_stage_list[
          formik.values.student_flows[index].stages[stageIndex].sub_stage_list
            .length - 1
        ].sub_stage_name
      )
    ) {
      setSubStageNames([]);
      formik.values?.student_flows[index]?.stages.forEach(
        (stage, stageIndex) => {
          let subStageArr = [];

          // ------------------set sub Stages Name --------------------
          stage.sub_stage_list.forEach((subStage, subStageIndex) => {
            subStageArr.push(subStage.sub_stage_name);
          });
          setSubStageNames((prev) => [
            ...prev,
            {
              [stage.stage_name]: [...subStageArr],
            },
          ]);
        }
      );
    }
  };

  // -----------------------------show disabled based on goal flow student count ------------------------

  const checkGoalCountIsDisabled = (index, field) => {
    return (
      formik.values.student_flows[index].goal_count > 0 && field?.length > 0
    );
  };

  // set section name documents for stage documents
  useEffect(() => {
    if (!isSectionName) {
      formik.values.student_flows.forEach((flow, flowIndex) => {
        flow.stages.forEach((stage, stageIndex) => {
          stage.sub_stage_list.forEach((subStage, subStageIndex) => {
            if (stage.stage_name === "Documents") {
              formik.setFieldValue(
                `student_flows.${flowIndex}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.section_name`,
                "Documents"
              );
            }
          });
        });
      });

      setIsSectionName(true);
    }

    if (!oneFlow) {
      if (
        formik.values.student_flows[0].stages[0].sub_stage_list[0].field_json[0]
          .field_name
      ) {
        setOneFlow(true);
      }
    }
  }, [formik.values.student_flows]);

  useEffect(() => {
    setOneFlow(false);
  }, [selectedRecord]);

  return (
    <>
      {isEditable && oneFlow ? (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                maxWidth: "100%",
                background: "aliceblue",
              }}
            >
              <RoundTabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {formik.values.student_flows.map((item, index) => (
                  <Tab
                    key={`flow_tab${index}`}
                    label={
                      index === tabIndex ? (
                        <TextField
                          id={`name${index}`}
                          label={t("Flow Name")}
                          size="small"
                          name={`name`}
                          value={item.name}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `student_flows.${index}.name`,
                              event.target.value.replace(/^\s+/g, "")
                            );
                            setIsDirty(true);
                          }}
                          width="100%"
                          helperText={t(
                            formik.touched.student_flows?.[index]?.name &&
                              formik.errors.student_flows?.[index]?.name &&
                              formik.errors.student_flows[index].name
                          )}
                          error={Boolean(
                            formik.touched.student_flows?.[index]?.name &&
                              formik.errors.student_flows?.[index]?.name &&
                              formik.errors.student_flows[index].name
                          )}
                          required
                        />
                      ) : (
                        <Typography
                          variant="string"
                          sx={{
                            pr: 1,
                            textAlign: "center",
                            color: Boolean(
                              formik.touched.student_flows &&
                                formik.errors?.student_flows &&
                                formik.errors?.student_flows[index]
                            )
                              ? "red"
                              : "inherit",
                          }}
                        >
                          {item.name}
                        </Typography>
                      )
                    }
                    style={{
                      paddingRight:
                        formik.values.student_flows?.length === 1 ? "12px" : 0,
                    }}
                    icon={
                      formik.values.student_flows?.length > 1 &&
                      index === tabIndex ? (
                        <Close
                          onClick={() => {
                            handleDailog(index);
                          }}
                          style={{
                            color: colors.red,
                            padding: 0,
                            marginRight: "7px",
                          }}
                        />
                      ) : null
                    }
                    iconPosition="end"
                  />
                ))}
                <IconButton
                  style={{ padding: 10, marginLeft: 6 }}
                  onClick={() => {
                    formik.setFieldValue("student_flows", [
                      ...formik.values.student_flows,
                      {
                        name: `Flow ${formik.values.student_flows.length + 1}`,
                        flow_guid: getGUID(),
                        default: false,
                        data_change: true,
                        stages: [
                          {
                            stage_name: "Documents",
                            stage_guid: getGUID(),
                            sub_stage_list: [
                              {
                                id: 0,
                                sub_stage_name: "",
                                order_by: 0,
                                sub_stage_guid: getGUID(),
                                field_json: [
                                  {
                                    type: fieldTypeList[0].value,
                                    field_name: "",
                                    field_guid: getGUID(),
                                    validity_check: false,
                                    submission_date: false,
                                    start_date: false,
                                    expiry_date: false,
                                    choices: [],
                                    task_check: true,
                                    completion_date: false,
                                  },
                                ],
                                section_name: sectionOptions[0].value,
                              },
                            ],
                          },
                        ],
                      },
                    ]);
                    setTabIndex(formik.values.student_flows?.length || 0);
                    setIsDirty(true);
                  }}
                >
                  <Add style={{ color: colors.primarySelected }} />
                </IconButton>
              </RoundTabs>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              padding: "12px",
              background: colors.white,
              borderRadius: tabIndex ? "12px" : "0px 12px 12px 12px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          >
            <Grid container spacing={2}>
              {formik.values.student_flows.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  key={`student_flows${index}`}
                  style={{ display: tabIndex === index ? "block" : "none" }}
                >
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <FormControlLabel
                        disabled={formik.values.student_flows[index].default}
                        control={
                          <Checkbox
                            onChange={(event) => {
                              // Update all checkboxes in the array to false
                              const updatedFlows =
                                formik.values.student_flows.map((flow, i) => ({
                                  ...flow,
                                  default:
                                    formik.values.student_flows?.length === 1
                                      ? true
                                      : i === index
                                      ? event.target.checked
                                      : false,
                                }));

                              // Update formik values with the modified student_flows array
                              formik.setFieldValue(
                                "student_flows",
                                updatedFlows
                              );

                              // Set the form as dirty
                              setIsDirty(true);
                            }}
                            checked={formik.values.student_flows[index].default}
                          />
                        }
                        label={t("Make Default Flow")}
                        labelPlacement="start"
                      />
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item sm={12} style={{ paddingLeft: "28px" }}>
                        <Box
                          sx={{
                            maxWidth: "100%",
                            background: "aliceblue",
                          }}
                        >
                          <RoundTabs
                            value={stageTabIndex}
                            onChange={handleStageTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                          >
                            {item.stages.map((stage, stageIndex) => (
                              <Tab
                                key={`stage_tab_${stageIndex}`}
                                label={
                                  stageIndex === stageTabIndex ? (
                                    <>
                                      <TextField
                                        id={`stage_name${stageIndex}`}
                                        size="small"
                                        name={`stage_name`}
                                        label={t(`Stage Name`)}
                                        value={stage.stage_name}
                                        disabled={stageIndex === 0}
                                        onChange={(event) => {
                                          formik.setFieldValue(
                                            `student_flows.${index}.stages.${stageIndex}.stage_name`,
                                            event.target.value.replace(
                                              /^\s+/g,
                                              ""
                                            )
                                          );
                                          setIsDirty(true);
                                        }}
                                        onKeyUp={(event) => {
                                          checkUniqueStageName(
                                            event.target.value
                                              .replace(/^\s+/g, "")
                                              .trim(),

                                            index,
                                            stageIndex
                                          );
                                        }}
                                        width="100%"
                                        helperText={t(
                                          formik.touched.student_flows?.[index]
                                            ?.stages?.[stageIndex]
                                            ?.stage_name &&
                                            formik.errors.student_flows?.[index]
                                              ?.stages?.[stageIndex]
                                              ?.stage_name &&
                                            formik.errors.student_flows[index]
                                              .stages[stageIndex].stage_name
                                        )}
                                        error={Boolean(
                                          formik.touched.student_flows?.[index]
                                            ?.stages?.[stageIndex]
                                            ?.stage_name &&
                                            formik.errors.student_flows?.[index]
                                              ?.stages?.[stageIndex]
                                              ?.stage_name &&
                                            formik.errors.student_flows[index]
                                              .stages[stageIndex].stage_name
                                        )}
                                        required
                                      />

                                      {errorFlowIndex === index &&
                                        errorStageIndex === stageIndex && (
                                          <p
                                            className={"error"}
                                            style={{ color: "red" }}
                                          >
                                            {uniqueStageNameError}
                                          </p>
                                        )}
                                    </>
                                  ) : (
                                    <Typography
                                      variant="string"
                                      sx={{
                                        pr: 1,
                                        textAlign: "center",
                                        color: Boolean(
                                          formik.touched.student_flows &&
                                            formik.errors?.student_flows &&
                                            formik.errors?.student_flows[index]
                                              ?.stages?.[stageIndex]
                                        )
                                          ? "red"
                                          : "inherit",
                                      }}
                                    >
                                      {stage?.stage_name}
                                    </Typography>
                                  )
                                }
                                style={{
                                  paddingRight: "12px",
                                }}
                                icon={
                                  formik.values.student_flows[index].stages
                                    ?.length > 1 &&
                                  stageIndex !== 0 && (
                                    <Tooltip
                                      title={
                                        checkGoalCountIsDisabled(
                                          index,
                                          selectedRecord?.student_flows?.[index]
                                            ?.stages?.[stageIndex]?.stage_name
                                        )
                                          ? t(
                                              "Delete button is disabled because Flow is mapped with Students"
                                            )
                                          : ""
                                      }
                                    >
                                      <span>
                                        <CloseButton
                                          onClick={() => {
                                            setIndex(index);
                                            setStageIndex(stageIndex);
                                            setFieldIndex(stage.id);
                                            handleStageDailog();
                                          }}
                                          style={{
                                            opacity:
                                              formik.values.student_flows[index]
                                                .goal_count > 0 &&
                                              selectedRecord?.student_flows?.[
                                                index
                                              ]?.stages[stageIndex]?.stage_name
                                                .length > 0
                                                ? 0.5
                                                : 1,
                                          }}
                                          disabled={
                                            formik.values.student_flows[index]
                                              .goal_count > 0 &&
                                            selectedRecord?.student_flows?.[
                                              index
                                            ]?.stages[stageIndex]?.stage_name
                                              .length > 0
                                              ? true
                                              : false
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                  )
                                }
                                iconPosition="end"
                              />
                            ))}

                            <IconButton
                              style={{ padding: 10, marginLeft: 6 }}
                              onClick={() => {
                                setIsDirty(true);
                                formik.setFieldValue(
                                  `student_flows.${index}.stages`,
                                  [
                                    ...item["stages"],
                                    {
                                      stage_name: "",
                                      stage_guid: getGUID(),
                                      sub_stage_list: [
                                        {
                                          id: 0,
                                          name: "",
                                          order_by: 0,
                                          sub_stage_guid: getGUID(),

                                          field_json: [
                                            {
                                              type: "",
                                              field_name: "",
                                              field_guid: getGUID(),
                                              validity_check: false,
                                              submission_date: false,
                                              start_date: false,
                                              expiry_date: false,
                                              choices: [],
                                              task_check: true,
                                              completion_date: false,
                                            },
                                          ],
                                          section_name: "",
                                        },
                                      ],
                                    },
                                  ]
                                );
                                if (
                                  !stageNames.includes(
                                    formik.values.student_flows[index].stages[
                                      formik.values.student_flows[index].stages
                                        .length - 1
                                    ].stage_name
                                  )
                                ) {
                                  setStageNames((prev) => [
                                    ...prev,
                                    formik.values.student_flows[index].stages[
                                      formik.values.student_flows[index].stages
                                        .length - 1
                                    ].stage_name,
                                  ]);
                                }
                                setDataChangeCount((prev) => prev + 1);
                                setDataChange(true);
                                formik.setFieldValue(
                                  `student_flows.${index}.data_change`,
                                  true
                                );
                                setStageTabIndex(
                                  formik.values.student_flows?.[index]?.stages
                                    ?.length || 0
                                );
                              }}
                            >
                              <Add
                                style={{
                                  color: colors.primarySelected,
                                }}
                              />
                            </IconButton>
                          </RoundTabs>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {item.stages.map((stage, stageIndex) =>
                      stageIndex === stageTabIndex ? (
                        <>
                          <Grid
                            item
                            sm={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "12px",
                            }}
                          >
                            {stage.sub_stage_list?.map(
                              (sub_stage, subStageIndex) => (
                                <NoShadaowWrapper>
                                  <Heading
                                    style={{
                                      background: "aliceblue",
                                      borderBottom: "2px solid #0b439629",
                                      padding: "1px 10px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Grid item sm={4}>
                                        <InputField
                                          id={`sub_stage_name${subStageIndex}`}
                                          label={t("Section Name")}
                                          name={`sub_stage_name`}
                                          value={sub_stage.sub_stage_name}
                                          onChange={(event) => {
                                            formik.setFieldValue(
                                              `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.sub_stage_name`,
                                              event.target.value.replace(
                                                /^\s+/g,
                                                ""
                                              )
                                            );
                                            setIsDirty(true);
                                          }}
                                          onKeyUp={(event) => {
                                            checkUniqueSubStageName(
                                              event.target.value
                                                .replace(/^\s+/g, "")
                                                .trim(),
                                              formik.values.student_flows[index]
                                                .stages[stageIndex].stage_name,
                                              index,
                                              stageIndex,
                                              subStageIndex
                                            );
                                          }}
                                          width="100%"
                                          helperText={t(
                                            formik.touched.student_flows?.[
                                              index
                                            ]?.stages?.[stageIndex]
                                              ?.sub_stage_list?.[subStageIndex]
                                              ?.sub_stage_name &&
                                              formik.errors.student_flows?.[
                                                index
                                              ]?.stages?.[stageIndex]
                                                ?.sub_stage_list?.[
                                                subStageIndex
                                              ]?.sub_stage_name &&
                                              formik.errors.student_flows[index]
                                                .stages[stageIndex]
                                                .sub_stage_list?.[subStageIndex]
                                                ?.sub_stage_name
                                          )}
                                          error={Boolean(
                                            (formik.touched.student_flows?.[
                                              index
                                            ]?.stages?.[stageIndex]
                                              ?.sub_stage_list?.[subStageIndex]
                                              ?.sub_stage_name ||
                                              formik.submitCount > 0) &&
                                              formik.errors.student_flows?.[
                                                index
                                              ]?.stages?.[stageIndex]
                                                ?.sub_stage_list?.[
                                                subStageIndex
                                              ]?.sub_stage_name &&
                                              formik.errors.student_flows[index]
                                                .stages[stageIndex]
                                                .sub_stage_list?.[subStageIndex]
                                                ?.sub_stage_name
                                          )}
                                          required
                                        />
                                      </Grid>
                                      <Grid item sm={3}>
                                        <SelectDropdown
                                          id={`section${index}`}
                                          name={"section_name"}
                                          options={
                                            formik.values.student_flows[index]
                                              .stages[stageIndex].stage_name ==
                                            "Documents"
                                              ? sectionOptions.filter(
                                                  (option) => {
                                                    return (
                                                      option.value ===
                                                      "documents"
                                                    );
                                                  }
                                                )
                                              : sectionOptions.filter(
                                                  (option) => {
                                                    return (
                                                      option.value !==
                                                      "documents"
                                                    );
                                                  }
                                                )
                                          }
                                          label={t("Select Tab")}
                                          disabled={
                                            formik.values.student_flows[index]
                                              .stages[stageIndex].stage_name ==
                                            "Documents"
                                          }
                                          onChange={(_, selected) => {
                                            formik.setFieldValue(
                                              `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.section_name`,
                                              selected.value
                                            );
                                            setIsDirty(true);
                                          }}
                                          value={
                                            formik.values.student_flows[index]
                                              .stages[stageIndex].stage_name ==
                                            "Documents"
                                              ? sectionOptions[0].label
                                              : sectionOptions.find(
                                                  (option) =>
                                                    option.value ===
                                                    formik.values.student_flows[
                                                      index
                                                    ].stages[stageIndex]
                                                      .sub_stage_list[
                                                      subStageIndex
                                                    ].section_name
                                                ) ||
                                                formik.values.student_flows[
                                                  index
                                                ].stages[stageIndex]
                                                  .sub_stage_list[subStageIndex]
                                                  .section_name
                                          }
                                          disableClearable
                                          width="100%"
                                          helperText={
                                            formik.touched.student_flows?.[
                                              index
                                            ]?.stages?.[stageIndex]
                                              ?.sub_stage_list?.[subStageIndex]
                                              ?.section_name &&
                                            formik.errors.student_flows?.[index]
                                              ?.stages?.[stageIndex]
                                              ?.sub_stage_list?.[subStageIndex]
                                              ?.section_name &&
                                            formik.errors.student_flows?.[index]
                                              ?.stages?.[stageIndex]
                                              ?.sub_stage_list?.[subStageIndex]
                                              ?.section_name
                                          }
                                          error={Boolean(
                                            formik.touched.student_flows?.[
                                              index
                                            ]?.stages?.[stageIndex]
                                              ?.sub_stage_list?.[subStageIndex]
                                              ?.section_name &&
                                              formik.errors.student_flows?.[
                                                index
                                              ]?.stages?.[stageIndex]
                                                ?.sub_stage_list?.[
                                                subStageIndex
                                              ]?.section_name &&
                                              formik.errors.student_flows?.[
                                                index
                                              ]?.stages?.[stageIndex]
                                                ?.sub_stage_list?.[
                                                subStageIndex
                                              ]?.section_name
                                          )}
                                          required
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        sm={
                                          formik.values.student_flows[index]
                                            .stages[stageIndex].sub_stage_list
                                            ?.length > 1
                                            ? 3
                                            : 5
                                        }
                                      ></Grid>
                                      {formik.values.student_flows[index]
                                        .stages[stageIndex].sub_stage_list
                                        ?.length > 1 && (
                                        <Grid
                                          item
                                          sm={2}
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              checkGoalCountIsDisabled(
                                                index,
                                                selectedRecord?.student_flows?.[
                                                  index
                                                ]?.stages?.[stageIndex]
                                                  ?.sub_stage_list[
                                                  subStageIndex
                                                ]?.sub_stage_name
                                              )
                                                ? t(
                                                    "Delete button is disabled because Flow is mapped with Students"
                                                  )
                                                : ""
                                            }
                                          >
                                            <span>
                                              <CloseButton
                                                onClick={() => {
                                                  setIndex(index);
                                                  setStageIndex(stageIndex);
                                                  setSubsectionIndex(
                                                    subStageIndex
                                                  );
                                                  handleSubstageDailog();
                                                  setIsDirty(true);
                                                }}
                                                style={{
                                                  opacity:
                                                    formik.values.student_flows[
                                                      index
                                                    ].goal_count > 0 &&
                                                    selectedRecord
                                                      ?.student_flows?.[index]
                                                      ?.stages[stageIndex]
                                                      ?.sub_stage_list?.[
                                                      subStageIndex
                                                    ]?.sub_stage_name.length > 0
                                                      ? 0.5
                                                      : 1,
                                                }}
                                                disabled={
                                                  formik.values.student_flows[
                                                    index
                                                  ].goal_count > 0 &&
                                                  selectedRecord
                                                    ?.student_flows?.[index]
                                                    ?.stages[stageIndex]
                                                    ?.sub_stage_list?.[
                                                    subStageIndex
                                                  ]?.sub_stage_name.length > 0
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </span>
                                          </Tooltip>
                                        </Grid>
                                      )}
                                    </Grid>
                                    {errorFlowIndex === index &&
                                      errorStageIndex === stageIndex &&
                                      errorSubStageIndex === subStageIndex && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {uniqueSubStageNameError}
                                        </p>
                                      )}
                                  </Heading>

                                  <Content
                                    component={"div"}
                                    style={{ padding: "6px" }}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item sm={12}>
                                        {sub_stage.field_json.map(
                                          (field, fieldIndex) => (
                                            <Grid container spacing={2}>
                                              <Grid item sm={1.5}>
                                                <Tooltip
                                                  title={
                                                    checkGoalCountIsDisabled(
                                                      index,
                                                      selectedRecord
                                                        ?.student_flows?.[index]
                                                        ?.stages?.[stageIndex]
                                                        ?.sub_stage_list?.[
                                                        subStageIndex
                                                      ]?.field_json?.[
                                                        fieldIndex
                                                      ]?.type
                                                    )
                                                      ? t(
                                                          "Delete button is disabled because Flow is mapped with Students"
                                                        )
                                                      : ""
                                                  }
                                                >
                                                  <span>
                                                    <SelectDropdown
                                                      id={`field_type${index}`}
                                                      name={"type"}
                                                      options={
                                                        formik.values
                                                          .student_flows[index]
                                                          .stages[stageIndex]
                                                          .stage_name ==
                                                        "Documents"
                                                          ? fieldTypeList.filter(
                                                              (option) => {
                                                                return (
                                                                  option.value ===
                                                                    "file_field" ||
                                                                  option.value ===
                                                                    "task"
                                                                );
                                                              }
                                                            )
                                                          : fieldTypeList
                                                      }
                                                      label={t("Field Type")}
                                                      disabled={checkGoalCountIsDisabled(
                                                        index,
                                                        selectedRecord
                                                          ?.student_flows?.[
                                                          index
                                                        ]?.stages?.[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ]?.field_json?.[
                                                          fieldIndex
                                                        ]?.type
                                                      )}
                                                      onChange={(
                                                        _,
                                                        selected
                                                      ) => {
                                                        formik.setFieldValue(
                                                          `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.type`,
                                                          selected.value
                                                        );
                                                        setIsDirty(true);

                                                        if (
                                                          selected.value ===
                                                          "dropdown"
                                                        ) {
                                                          addFields(
                                                            field.choices,
                                                            index,
                                                            stageIndex,
                                                            subStageIndex,
                                                            fieldIndex
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        fieldTypeList.find(
                                                          (option) =>
                                                            option.value ===
                                                            field.type
                                                        ) || ""
                                                      }
                                                      disableClearable
                                                      width="100%"
                                                      helperText={
                                                        formik.touched
                                                          .student_flows?.[
                                                          index
                                                        ]?.stages?.[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ]?.field_json?.[
                                                          fieldIndex
                                                        ]?.type &&
                                                        formik.errors
                                                          .student_flows?.[
                                                          index
                                                        ]?.stages?.[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ]?.field_json?.[
                                                          fieldIndex
                                                        ]?.type &&
                                                        formik.errors
                                                          .student_flows[index]
                                                          .stages[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ].field_json[fieldIndex]
                                                          .type
                                                      }
                                                      error={Boolean(
                                                        formik.touched
                                                          .student_flows?.[
                                                          index
                                                        ]?.stages?.[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ]?.field_json?.[
                                                          fieldIndex
                                                        ]?.type &&
                                                          formik.errors
                                                            .student_flows?.[
                                                            index
                                                          ]?.stages?.[
                                                            stageIndex
                                                          ]?.sub_stage_list?.[
                                                            subStageIndex
                                                          ]?.field_json?.[
                                                            fieldIndex
                                                          ]?.type &&
                                                          formik.errors
                                                            .student_flows[
                                                            index
                                                          ].stages[stageIndex]
                                                            ?.sub_stage_list?.[
                                                            subStageIndex
                                                          ].field_json[
                                                            fieldIndex
                                                          ].type
                                                      )}
                                                      required
                                                    />
                                                  </span>
                                                </Tooltip>
                                              </Grid>

                                              <Grid item sm={4}>
                                                <InputField
                                                  id={`field_name${fieldIndex}`}
                                                  label={t("Field Name")}
                                                  name={`field_name`}
                                                  value={field.field_name}
                                                  onChange={(event) => {
                                                    formik.setFieldValue(
                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.field_name`,
                                                      event.target.value.replace(
                                                        /^\s+/g,
                                                        ""
                                                      )
                                                    );
                                                    setIsDirty(true);
                                                  }}
                                                  width="100%"
                                                  helperText={
                                                    formik.touched
                                                      .student_flows?.[index]
                                                      ?.stages?.[stageIndex]
                                                      ?.sub_stage_list?.[
                                                      subStageIndex
                                                    ]?.field_json?.[fieldIndex]
                                                      ?.field_name &&
                                                    formik.errors
                                                      .student_flows?.[index]
                                                      ?.stages?.[stageIndex]
                                                      ?.sub_stage_list?.[
                                                      subStageIndex
                                                    ]?.field_json?.[fieldIndex]
                                                      ?.field_name &&
                                                    formik.errors.student_flows[
                                                      index
                                                    ].stages[stageIndex]
                                                      ?.sub_stage_list?.[
                                                      subStageIndex
                                                    ].field_json[fieldIndex]
                                                      .field_name
                                                  }
                                                  error={Boolean(
                                                    formik.touched
                                                      .student_flows?.[index]
                                                      ?.stages?.[stageIndex]
                                                      ?.sub_stage_list?.[
                                                      subStageIndex
                                                    ]?.field_json?.[fieldIndex]
                                                      ?.field_name &&
                                                      formik.errors
                                                        .student_flows?.[index]
                                                        ?.stages?.[stageIndex]
                                                        ?.sub_stage_list?.[
                                                        subStageIndex
                                                      ]?.field_json?.[
                                                        fieldIndex
                                                      ]?.field_name &&
                                                      formik.errors
                                                        .student_flows[index]
                                                        .stages[stageIndex]
                                                        ?.sub_stage_list?.[
                                                        subStageIndex
                                                      ].field_json[fieldIndex]
                                                        .field_name
                                                  )}
                                                  required
                                                />
                                                {errorFlowIndex === index &&
                                                  errorStageIndex ===
                                                    stageIndex &&
                                                  errorSubStageIndex ===
                                                    subStageIndex &&
                                                  errorFieldIndex ===
                                                    fieldIndex && (
                                                    <p
                                                      style={{
                                                        color: "red",
                                                      }}
                                                    >
                                                      {uniqueFieldNameError}
                                                    </p>
                                                  )}
                                              </Grid>

                                              {formik.values.student_flows[
                                                index
                                              ].stages[stageIndex]
                                                .sub_stage_list[subStageIndex]
                                                .field_json[fieldIndex].type ===
                                              "file_field" ? (
                                                <>
                                                  <Grid item sm={1.4}>
                                                    <Tooltip
                                                      title={t(
                                                        "Submission Date"
                                                      )}
                                                      placement="top"
                                                      arrow
                                                    >
                                                      <div>
                                                        {" "}
                                                        <FormControlLabel
                                                          sx={{ pt: 1 }}
                                                          disabled={
                                                            formik.values
                                                              .student_flows[
                                                              index
                                                            ].stages[stageIndex]
                                                              .sub_stage_list[
                                                              subStageIndex
                                                            ].field_json[
                                                              fieldIndex
                                                            ].type !==
                                                            "file_field"
                                                          }
                                                          control={
                                                            <Checkbox
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                event.target
                                                                  .checked &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type !==
                                                                  "dropdown"
                                                                  ? formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.submission_date`,
                                                                      true
                                                                    )
                                                                  : formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.submission_date`,
                                                                      false
                                                                    );
                                                                setIsDirty(
                                                                  true
                                                                );

                                                                selectedRecord
                                                                  ?.student_flows?.[
                                                                  index
                                                                ]?.stages?.[
                                                                  stageIndex
                                                                ]
                                                                  ?.sub_stage_list?.[
                                                                  subStageIndex
                                                                ]?.field_json?.[
                                                                  fieldIndex
                                                                ]
                                                                  ?.submission_date ===
                                                                  false &&
                                                                  checkDataChange(
                                                                    event.target
                                                                      .checked,
                                                                    index
                                                                  );
                                                              }}
                                                              checked={
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ]
                                                                  .submission_date &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type ===
                                                                  "file_field"
                                                                  ? true
                                                                  : false
                                                              }
                                                            ></Checkbox>
                                                          }
                                                          label={t("Sub Date")}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                    {/* )} */}
                                                  </Grid>
                                                  <Grid item sm={1.5}>
                                                    <Tooltip
                                                      title={t("Issue Date")}
                                                      placement="top"
                                                      arrow
                                                    >
                                                      <div>
                                                        <FormControlLabel
                                                          sx={{ pt: 1 }}
                                                          disabled={
                                                            formik.values
                                                              .student_flows[
                                                              index
                                                            ].stages[stageIndex]
                                                              .sub_stage_list[
                                                              subStageIndex
                                                            ].field_json[
                                                              fieldIndex
                                                            ].type !==
                                                            "file_field"
                                                          }
                                                          control={
                                                            <Checkbox
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                event.target
                                                                  .checked &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type !==
                                                                  "dropdown"
                                                                  ? formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.start_date`,
                                                                      true
                                                                    )
                                                                  : formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.start_date`,
                                                                      false
                                                                    );
                                                                setIsDirty(
                                                                  true
                                                                );

                                                                selectedRecord
                                                                  ?.student_flows?.[
                                                                  index
                                                                ]?.stages?.[
                                                                  stageIndex
                                                                ]
                                                                  ?.sub_stage_list?.[
                                                                  subStageIndex
                                                                ]?.field_json?.[
                                                                  fieldIndex
                                                                ]
                                                                  ?.start_date ===
                                                                  false &&
                                                                  checkDataChange(
                                                                    event.target
                                                                      .checked,
                                                                    index
                                                                  );
                                                              }}
                                                              checked={
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].start_date &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type ===
                                                                  "file_field"
                                                                  ? true
                                                                  : false
                                                              }
                                                            ></Checkbox>
                                                          }
                                                          label={t("Iss Date")}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                    {/* )} */}
                                                  </Grid>
                                                  <Grid item sm={1.5}>
                                                    <Tooltip
                                                      title={t("Expiry Date")}
                                                      placement="top"
                                                      arrow
                                                    >
                                                      <div>
                                                        <FormControlLabel
                                                          sx={{ pt: 1 }}
                                                          disabled={
                                                            formik.values
                                                              .student_flows[
                                                              index
                                                            ].stages[stageIndex]
                                                              .sub_stage_list[
                                                              subStageIndex
                                                            ].field_json[
                                                              fieldIndex
                                                            ].type !==
                                                            "file_field"
                                                          }
                                                          control={
                                                            <Checkbox
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                event.target
                                                                  .checked &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type !==
                                                                  "dropdown"
                                                                  ? formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.expiry_date`,
                                                                      true
                                                                    )
                                                                  : formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.expiry_date`,
                                                                      false
                                                                    );
                                                                setIsDirty(
                                                                  true
                                                                );
                                                                selectedRecord
                                                                  ?.student_flows?.[
                                                                  index
                                                                ]?.stages?.[
                                                                  stageIndex
                                                                ]
                                                                  ?.sub_stage_list?.[
                                                                  subStageIndex
                                                                ]?.field_json?.[
                                                                  fieldIndex
                                                                ]
                                                                  ?.expiry_date ===
                                                                  false &&
                                                                  checkDataChange(
                                                                    event.target
                                                                      .checked,
                                                                    index
                                                                  );
                                                              }}
                                                              checked={
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].expiry_date &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type ===
                                                                  "file_field"
                                                                  ? true
                                                                  : false
                                                              }
                                                            ></Checkbox>
                                                          }
                                                          label={t("Exp Date")}
                                                        />
                                                      </div>
                                                    </Tooltip>
                                                    {/* )} */}
                                                  </Grid>
                                                  <Grid item sm={1.8}>
                                                    <FormControlLabel
                                                      sx={{
                                                        pt: 1,
                                                      }}
                                                      disabled={
                                                        formik.values
                                                          ?.student_flows[index]
                                                          ?.stages[stageIndex]
                                                          ?.sub_stage_list[
                                                          subStageIndex
                                                        ]?.field_json[
                                                          fieldIndex
                                                        ]?.type !== "file_field"
                                                      }
                                                      control={
                                                        <Checkbox
                                                          onChange={(event) => {
                                                            event.target
                                                              .checked &&
                                                            formik.values
                                                              .student_flows[
                                                              index
                                                            ].stages[stageIndex]
                                                              .sub_stage_list[
                                                              subStageIndex
                                                            ].field_json[
                                                              fieldIndex
                                                            ].type !==
                                                              "dropdown"
                                                              ? formik.setFieldValue(
                                                                  `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.validity_check`,
                                                                  true
                                                                )
                                                              : formik.setFieldValue(
                                                                  `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.validity_check`,
                                                                  false
                                                                );
                                                            setIsDirty(true);
                                                          }}
                                                          checked={
                                                            formik.values
                                                              .student_flows[
                                                              index
                                                            ].stages[stageIndex]
                                                              .sub_stage_list[
                                                              subStageIndex
                                                            ].field_json[
                                                              fieldIndex
                                                            ].validity_check &&
                                                            formik.values
                                                              .student_flows[
                                                              index
                                                            ].stages[stageIndex]
                                                              .sub_stage_list[
                                                              subStageIndex
                                                            ].field_json[
                                                              fieldIndex
                                                            ].type ===
                                                              "file_field"
                                                              ? true
                                                              : false
                                                          }
                                                        ></Checkbox>
                                                      }
                                                      id={`validity_check${fieldIndex}`}
                                                      label={t(
                                                        "Validity Check"
                                                      )}
                                                      name={`validity_check`}
                                                    />

                                                    <FormHelperText
                                                      style={{
                                                        color: "red",
                                                      }}
                                                    >
                                                      {formik.touched
                                                        .student_flows?.[index]
                                                        ?.stages?.[stageIndex]
                                                        ?.sub_stage_list?.[
                                                        subStageIndex
                                                      ]?.field_json?.[
                                                        fieldIndex
                                                      ]?.validity_check &&
                                                        formik.errors
                                                          .student_flows?.[
                                                          index
                                                        ]?.stages?.[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ]?.field_json?.[
                                                          fieldIndex
                                                        ]?.validity_check &&
                                                        formik.errors
                                                          .student_flows[index]
                                                          .stages[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ].field_json[fieldIndex]
                                                          .validity_check}
                                                    </FormHelperText>
                                                    {/* )} */}
                                                  </Grid>
                                                </>
                                              ) : null}
                                              {formik.values.student_flows[
                                                index
                                              ].stages[stageIndex]
                                                .sub_stage_list[subStageIndex]
                                                .field_json[fieldIndex].type ===
                                              "dropdown" ? (
                                                <Choices
                                                  choices={
                                                    formik.values.student_flows[
                                                      index
                                                    ].stages[stageIndex]
                                                      .sub_stage_list[
                                                      subStageIndex
                                                    ].field_json[fieldIndex]
                                                      .choices
                                                  }
                                                  index={index}
                                                  fieldIndex={fieldIndex}
                                                  stageIndex={stageIndex}
                                                  subSectionIndex={
                                                    subStageIndex
                                                  }
                                                  formik={formik}
                                                  flows={flowType}
                                                  handleFormChange={
                                                    handleFormChange
                                                  }
                                                  addFields={addFields}
                                                  checkDataChange={
                                                    checkDataChange
                                                  }
                                                  existingFlag={existingFlag}
                                                  selectedRecord={
                                                    selectedRecord
                                                  }
                                                />
                                              ) : null}
                                              {formik.values.student_flows[
                                                index
                                              ].stages[stageIndex]
                                                .sub_stage_list[subStageIndex]
                                                .field_json[fieldIndex].type ===
                                              "task" ? (
                                                <Grid item sm={2}>
                                                  <Tooltip
                                                    title={t("Completion Date")}
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <div>
                                                      <FormControlLabel
                                                        sx={{ pt: 1 }}
                                                        control={
                                                          <>
                                                            <Checkbox
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                event.target
                                                                  .checked &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type !==
                                                                  "dropdown"
                                                                  ? formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.completion_date`,
                                                                      true
                                                                    )
                                                                  : formik.setFieldValue(
                                                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json.${fieldIndex}.completion_date`,
                                                                      false
                                                                    );
                                                                setIsDirty(
                                                                  true
                                                                );

                                                                selectedRecord
                                                                  ?.student_flows?.[
                                                                  index
                                                                ]?.stages?.[
                                                                  stageIndex
                                                                ]
                                                                  ?.sub_stage_list?.[
                                                                  subStageIndex
                                                                ]?.field_json?.[
                                                                  fieldIndex
                                                                ]
                                                                  ?.completion_date ===
                                                                  false &&
                                                                  checkDataChange(
                                                                    event.target
                                                                      .checked,
                                                                    index
                                                                  );
                                                              }}
                                                              checked={
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ]
                                                                  .completion_date &&
                                                                formik.values
                                                                  .student_flows[
                                                                  index
                                                                ].stages[
                                                                  stageIndex
                                                                ]
                                                                  .sub_stage_list[
                                                                  subStageIndex
                                                                ].field_json[
                                                                  fieldIndex
                                                                ].type ===
                                                                  "task"
                                                                  ? true
                                                                  : false
                                                              }
                                                            ></Checkbox>
                                                          </>
                                                        }
                                                        label={t(
                                                          `Completion Date`
                                                        )}
                                                      />
                                                    </div>
                                                  </Tooltip>
                                                </Grid>
                                              ) : null}
                                              <Grid
                                                item
                                                sm={0.2}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginLeft:
                                                    formik.values.student_flows[
                                                      index
                                                    ]?.stages[stageIndex]
                                                      ?.sub_stage_list[
                                                      subStageIndex
                                                    ]?.field_json[fieldIndex]
                                                      ?.type === "file_field"
                                                      ? "-30px"
                                                      : 0,
                                                }}
                                              >
                                                {formik.values.student_flows[
                                                  index
                                                ].stages[stageIndex]
                                                  .sub_stage_list[subStageIndex]
                                                  .field_json?.length > 1 && (
                                                  <Tooltip
                                                    title={
                                                      checkGoalCountIsDisabled(
                                                        index,
                                                        selectedRecord
                                                          ?.student_flows?.[
                                                          index
                                                        ]?.stages?.[stageIndex]
                                                          ?.sub_stage_list?.[
                                                          subStageIndex
                                                        ]?.field_json?.[
                                                          fieldIndex
                                                        ]?.field_name
                                                      )
                                                        ? t(
                                                            "Delete button is disabled because Flow is mapped with Students"
                                                          )
                                                        : ""
                                                    }
                                                  >
                                                    <span>
                                                      <IconButton
                                                        onClick={() => {
                                                          setIndex(index);
                                                          setStageIndex(
                                                            stageIndex
                                                          );
                                                          setSubsectionIndex(
                                                            subStageIndex
                                                          );
                                                          setFieldIndex(
                                                            fieldIndex
                                                          );
                                                          handleFieldDailog();
                                                          setIsDirty(true);
                                                        }}
                                                        size={"small"}
                                                        style={{
                                                          opacity:
                                                            checkGoalCountIsDisabled(
                                                              index,
                                                              selectedRecord
                                                                ?.student_flows?.[
                                                                index
                                                              ]?.stages?.[
                                                                stageIndex
                                                              ]
                                                                ?.sub_stage_list?.[
                                                                subStageIndex
                                                              ]?.field_json?.[
                                                                fieldIndex
                                                              ]?.field_name
                                                            )
                                                              ? 0.5
                                                              : 1,
                                                        }}
                                                        disabled={checkGoalCountIsDisabled(
                                                          index,
                                                          selectedRecord
                                                            ?.student_flows?.[
                                                            index
                                                          ]?.stages?.[
                                                            stageIndex
                                                          ]?.sub_stage_list?.[
                                                            subStageIndex
                                                          ]?.field_json?.[
                                                            fieldIndex
                                                          ]?.field_name
                                                        )}
                                                      >
                                                        <Delete
                                                          fontSize="small"
                                                          color="error"
                                                        />
                                                      </IconButton>
                                                    </span>
                                                  </Tooltip>
                                                )}
                                              </Grid>
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                      <Grid item sm={12}>
                                        <Grid
                                          container
                                          spacing={2}
                                          justifyContent="flex-end"
                                        >
                                          <Grid
                                            item
                                            xs={3}
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <BtnLink
                                              text={t("Add New Field")}
                                              id="addMoreFieldBtn"
                                              onClick={() => {
                                                setIsDirty(true);
                                                setDataChangeCount(
                                                  (prev) => prev + 1
                                                );
                                                formik.setFieldValue(
                                                  `student_flows.${index}.stages.${stageIndex}.sub_stage_list.${subStageIndex}.field_json`,
                                                  [
                                                    ...sub_stage["field_json"],
                                                    {
                                                      type: "",
                                                      field_name: "",
                                                      field_guid: getGUID(),
                                                      validity_check: false,
                                                      submission_date: false,
                                                      start_date: false,
                                                      expiry_date: false,
                                                      choices: [],
                                                      task_check: true,
                                                      completion_date: false,
                                                      section_name:
                                                        stage["stage_name"] ===
                                                        "Documents"
                                                          ? sectionOptions[0]
                                                              .value
                                                          : "",
                                                    },
                                                  ]
                                                );
                                                setDataChange(true);
                                                formik.setFieldValue(
                                                  `student_flows.${index}.data_change`,
                                                  true
                                                );
                                              }}
                                              style={{
                                                backgroundColor: colors.addBtn,
                                                color: colors.white,
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Content>
                                </NoShadaowWrapper>
                              )
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="flex-end"
                              style={{
                                paddingBottom: "20px",
                                paddingRight: "24px",
                              }}
                            >
                              <Grid
                                item
                                sm={3}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <BtnLink
                                  text={t("Add New Section")}
                                  id="addMoreSubsectionBtn"
                                  onClick={() => {
                                    setIsDirty(true);
                                    formik.setFieldValue(
                                      `student_flows.${index}.stages.${stageIndex}.sub_stage_list`,
                                      [
                                        ...stage["sub_stage_list"],
                                        {
                                          sub_stage_name: "",
                                          order_by: 0,
                                          sub_stage_guid: getGUID(),
                                          field_json: [
                                            {
                                              type: "",
                                              field_name: "",
                                              field_guid: getGUID(),
                                              validity_check: false,

                                              submission_date: false,
                                              start_date: false,
                                              expiry_date: false,
                                              choices: [],
                                              task_check: true,
                                              completion_date: false,
                                            },
                                          ],
                                          section_name:
                                            stage["stage_name"] === "Documents"
                                              ? sectionOptions[0].value
                                              : "",
                                        },
                                      ]
                                    );

                                    updateSubStageObject(index, stageIndex);
                                    setDataChangeCount((prev) => prev + 1);
                                    setDataChange(true);
                                    formik.setFieldValue(
                                      `student_flows.${index}.data_change`,
                                      true
                                    );
                                  }}
                                  style={{
                                    backgroundColor: colors.addBtn,
                                    color: colors.white,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : null
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {formik.values.student_flows?.[0]?.stages[0]?.sub_stage_list?.[0]
            ?.field_json?.[0]?.field_name ? (
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    maxWidth: "100%",
                    background: "aliceblue",
                  }}
                >
                  <RoundTabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {formik.values.student_flows.map((flow, index) => (
                      <Tab
                        key={`flow_tab${index}`}
                        label={flow.name}
                        style={{ padding: "12px" }}
                      />
                    ))}
                  </RoundTabs>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  padding: "12px",
                  background: colors.white,
                  borderRadius: tabIndex ? "12px" : "0px 12px 12px 12px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                {formik.values.student_flows.map((flow, index) => (
                  <Grid
                    container
                    spacing={2}
                    style={{ display: tabIndex === index ? "block" : "none" }}
                  >
                    <Grid item xs={12} sm={4}>
                      <InfoLabel
                        label={t("Default Flow")}
                        info={flow.default ? t("Yes") : t("No")}
                      />
                    </Grid>
                    {flow.stages.map((stage, stageIndex) => (
                      <Grid item xs={12} sm={12} key={`stage${stageIndex}`}>
                        <NoShadaowWrapper>
                          <Content component={"div"}>
                            <Grid container>
                              <Grid item xs={12} sm={12}>
                                <InfoLabel
                                  label={t("Stage Name")}
                                  info={stage.stage_name}
                                />
                              </Grid>
                              {stage.sub_stage_list?.map(
                                (sub_stage, subStageIndex) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    key={`sub_stage${subStageIndex}`}
                                  >
                                    <Grid item xs={12} sm={12}>
                                      <NoShadaowWrapper
                                        style={{ padding: "12px" }}
                                      >
                                        <Grid container>
                                          <Grid item xs={12} sm={2}>
                                            <InfoLabel
                                              label={t("Section Name")}
                                              info={sub_stage.sub_stage_name}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={2}>
                                            <InfoLabel
                                              label={t("Tab Name")}
                                              info={sub_stage.section_name}
                                            />
                                          </Grid>
                                        </Grid>
                                        <NoShadaowWrapper>
                                          <Content component={"div"}>
                                            {sub_stage.field_json.map(
                                              (field, fieldIndex) =>
                                                field.field_name &&
                                                field.type && (
                                                  <Grid container>
                                                    <Grid item xs={12} sm={2}>
                                                      <InfoLabel
                                                        label={t("Field Type")}
                                                        info={
                                                          fieldTypeList.find(
                                                            (option) =>
                                                              option.value ===
                                                              field.type
                                                          )?.label || ""
                                                        }
                                                      />
                                                    </Grid>

                                                    <Grid item xs={12} sm={2.5}>
                                                      <InfoLabel
                                                        label={t("Field Name")}
                                                        info={field.field_name}
                                                      />
                                                    </Grid>
                                                    {field.type ===
                                                      "file_field" && (
                                                      <>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2}
                                                        >
                                                          <InfoLabel
                                                            label={t(
                                                              "Sub Date"
                                                            )}
                                                            info={
                                                              field.submission_date
                                                                ? t("Yes")
                                                                : t("No")
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={2}
                                                        >
                                                          <InfoLabel
                                                            label={t(
                                                              "Issue Date"
                                                            )}
                                                            info={
                                                              field.start_date
                                                                ? t("Yes")
                                                                : t("No")
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={1.5}
                                                        >
                                                          <InfoLabel
                                                            label={t(
                                                              "Expiry Date"
                                                            )}
                                                            info={
                                                              field.expiry_date
                                                                ? t("Yes")
                                                                : t("No")
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          xs={12}
                                                          sm={1.5}
                                                        >
                                                          <InfoLabel
                                                            label={t(
                                                              "Validity Check"
                                                            )}
                                                            info={
                                                              field.validity_check
                                                                ? t("Yes")
                                                                : t("No")
                                                            }
                                                          />
                                                        </Grid>
                                                      </>
                                                    )}
                                                    {field.type === "task" && (
                                                      <Grid item xs={12} sm={2}>
                                                        <InfoLabel
                                                          label={t(
                                                            "Completion Date"
                                                          )}
                                                          info={
                                                            field.completion_date
                                                              ? t("Yes")
                                                              : t("No")
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                  </Grid>
                                                )
                                            )}
                                          </Content>
                                        </NoShadaowWrapper>
                                      </NoShadaowWrapper>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Content>
                        </NoShadaowWrapper>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <p>
              {t("No Goal Flow Found.")}{" "}
              <Button onClick={() => setOneFlow(true)}>{t("Add Flow")}</Button>
            </p>
          )}
        </>
      )}
      <Dialog
        open={open}
        onClose={handleNo}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!formik.values.student_flows[tabIndex]?.default && (
          <DialogTitle id="alert-dialog-title">{t("Delete Flow")}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formik.values.student_flows[tabIndex]?.default
              ? t(
                  "You are trying to delete a default flow, please make another flow as default first."
                )
              : t("Are you sure you want to delete this Flow?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!formik.values.student_flows[tabIndex]?.default && (
            <Button
              onClick={() => {
                handleYes();
              }}
            >
              {t("Yes")}
            </Button>
          )}
          <Button onClick={handleNo} autoFocus>
            {formik.values.student_flows[tabIndex]?.default ? t("Ok") : t("No")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openField}
        onClose={handleFieldNo}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Delete Field")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Are you sure you want to delete this Field?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleFieldYes();
            }}
          >
            {t("Yes")}
          </Button>
          <Button onClick={handleFieldNo} autoFocus>
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStage}
        onClose={handleStageNo}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Delete Stage")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Are you sure you want to delete this Stage?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleStageYes();
            }}
          >
            {t("Yes")}
          </Button>
          <Button onClick={handleStageNo} autoFocus>
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={opensubStage}
        onClose={handlesubStageNo}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Delete Sub Stage")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Are you sure you want to delete this Sub Stage?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handlesubStageYes();
            }}
          >
            {t("Yes")}
          </Button>
          <Button onClick={handlesubStageNo} autoFocus>
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Students;
