import React, { createContext, useContext, useEffect, useState } from "react";

// Create an interface context
export const InterfaceContext = createContext(undefined);

// InterfaceContextAPI component for managing interface preferences
export const InterfaceContextAPI = ({ children }) => {
  const page = document.documentElement;
  const browserTheme = window.matchMedia("(prefers-color-scheme: dark)");
  const persisted = JSON.parse(localStorage.getItem("preferences") || "{}");

  // State variables for interface preferences
  const [isDarkMode, setIsDarkMode] = useState(
    persisted ? persisted.isDarkMode : browserTheme.matches
  );
  const [isContrastMode, setIsContrastMode] = useState(
    persisted.isContrastMode || false
  );
  const [fontScale, setFontScale] = useState(persisted.fontScale || 1);
  const [direction, setDirection] = useState(persisted.direction || "ltr");
  const [isLayoutEditable, setIsLayoutEditable] = useState(false);

  // Function to stop CSS transition for smoother updates
  const stopTransition = () => {
    page.classList.add("no-transition");
    setTimeout(() => page.classList.remove("no-transition"), 100);
  };

  // Function to save user preferences to local storage
  const savePreferences = () => {
    localStorage.setItem(
      "preferences",
      JSON.stringify({
        isDarkMode,
        isContrastMode,
        fontScale,
        direction,
      })
    );
  };

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    stopTransition();
  };

  // Function to toggle contrast mode
  const toggleContrastMode = () => {
    setIsContrastMode(!isContrastMode);
    page.classList.toggle("contrast");
  };

  // Function to change font scale
  const changeFontScale = (scale) => {
    setFontScale(scale);
    stopTransition();
  };

  // Function to toggle text direction (ltr/rtl)
  const toggleDirection = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    page.setAttribute("dir", direction);
  };

  // Function to toggle layout editability
  const toggleLayoutEditable = () => {
    setIsLayoutEditable(!isLayoutEditable);
  };

  // Effect to apply interface preferences and handle window events
  useEffect(() => {
    // Apply font scale and device pixel ratio to CSS variables
    page.style.setProperty("--font-scale", fontScale);
    page.dataset.ratio = `${window.devicePixelRatio}`;

    // Save user preferences to local storage
    savePreferences();

    // Add event listeners for window resize and dark theme changes
    window.addEventListener("resize", () => setIsLayoutEditable(false));
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        if (event.matches) {
          setIsDarkMode(true);
        } else {
          setIsDarkMode(false);
        }
        stopTransition();
        savePreferences();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDarkMode,
    isContrastMode,
    fontScale,
    direction,
    window.devicePixelRatio,
  ]);

  // Provide interface context values to child components
  return (
    <InterfaceContext.Provider
      value={{
        isDarkMode,
        isContrastMode,
        fontScale,
        direction,
        toggleDarkMode,
        toggleContrastMode,
        changeFontScale,
        toggleDirection,
        isLayoutEditable,
        toggleLayoutEditable,
        setIsLayoutEditable,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  );
};

// Custom hook for accessing interface context values
export const useInterfaceContext = () => useContext(InterfaceContext);
