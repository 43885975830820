import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import { Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

import InfoLabel from "../../../components/UI/InfoLabel";
import { Content, Wrapper, Heading } from "../../../components/style/style";
import { colors } from "../../../constants/colors";
import { toTitleCase } from "../../../utils/utility";
import { dateFormFormat } from "../../../utils/dates";
import useAuth from "../../../hooks/useAuth";

const Print = ({ open, onClose, printData, schoolList, goalsList }) => {
  const [data, setData] = useState({});
  const [printSection, setPrintSection] = useState({
    general: true,
    contact_information: true,
    finance: true,
    registration_certificate: true,
    health_insurance_card: true,
  });
  const [edit, setEdit] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  let componentRef = useRef();
  const { user } = useAuth();

  useEffect(() => {
    setData(printData);
    setPrintSection({
      general: true,
      contact_information: true,
      finance: true,
      registration_certificate: true,
      health_insurance_card: true,
    });
  }, [printData, open]);

  const handleClose = () => {
    onClose();
  };

  const statusOptions = [
    { label: "Sprachkurs", value: 1 },
    { label: "Ausbildung", value: 2 },
    { label: "Anerkennung", value: 3 },
    { label: "Verpflichtet", value: 4 },
    { label: "Archiv", value: 5 },
  ];
  const checkboxChange = (tabs) => {
    const section = { ...printSection };
    // section[`${tab}`] = !printSection[`${tab}`];
    tabs.forEach((tab) => {
      section[`${tab}`] = !printSection[`${tab}`];
    });
    setPrintSection(section);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle>Select Sections to Print</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: colors.red,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Wrapper>
          <Content component="div">
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.general}
                      onChange={() => checkboxChange(["general"])}
                    />
                  }
                  label="General"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.contact_information}
                      onChange={() => checkboxChange(["contact_information"])}
                    />
                  }
                  label="Contact Information"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.finance}
                      onChange={() => checkboxChange(["finance"])}
                    />
                  }
                  label="Finance"
                />
              </Grid>
            </Grid>
          </Content>
        </Wrapper>
        <div style={{ display: "none" }}>
          <Content component="div" ref={(el) => (componentRef = el)}>
            <center>
              <Heading>
                {`Student (${toTitleCase(data.first_name)} ${toTitleCase(
                  data.last_name
                )})`}
                <br />
                {`Company - ${toTitleCase(user.company_name)}`}
                <br />
                {user.entity_type === "school"
                  ? `School - ${toTitleCase(user.entity_name)}`
                  : user.entity_type === "foreign_institute"
                  ? `Foreign Institute - ${toTitleCase(user.entity_name)}`
                  : `Hiring Agency - ${toTitleCase(user.entity_name)}`}
              </Heading>
            </center>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Wrapper style={{ padding: "12px" }}>
                  <Heading>General</Heading>
                  <Grid container spacing={2}>
                    {data.first_name && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"First Name"}
                          info={data.first_name}
                        />
                      </Grid>
                    )}

                    {data.last_name && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel label={"Last Name"} info={data.last_name} />
                      </Grid>
                    )}

                    {goalsList.length &&
                      data.goal_id &&
                      printSection.general && (
                        <Grid
                          item
                          xs={4}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <InfoLabel
                            label={"Goal"}
                            info={
                              goalsList?.find(
                                (option) => option.value === data.goal_id
                              )?.label || goalsList[0]?.label
                            }
                          />
                        </Grid>
                      )}

                    {data.gender && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel label={"Gender"} info={data.gender} />
                      </Grid>
                    )}

                    {data.dob && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Date of Birth"}
                          info={dateFormFormat(data.dob)}
                        />
                      </Grid>
                    )}

                    {data.city && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Place of Birth"}
                          info={data.place_of_birth}
                        />
                      </Grid>
                    )}

                    {data.citizenship && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Citizenship"}
                          info={data.citizenship}
                        />
                      </Grid>
                    )}

                    {data.former_citizenship && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Former Citizenship"}
                          info={data.former_citizenship}
                        />
                      </Grid>
                    )}

                    {data.date_of_change && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Date of Change"}
                          info={dateFormFormat(data.date_of_change)}
                        />
                      </Grid>
                    )}

                    {/* {data.status && statusOptions && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Status"}
                          info={
                            statusOptions.find(
                              (option) => option.value === data.status
                            ).label || statusOptions[0].label
                          }
                        />
                      </Grid>
                    )} */}

                    {schoolList.length &&
                    data.school_id &&
                    printSection.general ? (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"School"}
                          info={
                            schoolList.find(
                              (option) => option.value === data.school_id
                            )?.label || schoolList[0]?.label
                          }
                        />
                      </Grid>
                    ) : null}

                    {data.current_employer && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Current Employer"}
                          info={data.current_employer}
                        />
                      </Grid>
                    )}

                    {data.situation && printSection.finance && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"Family Status"}
                          info={data.situation}
                        />
                      </Grid>
                    )}

                    {data.religion && printSection.finance && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel label={"Religion"} info={data.religion} />
                      </Grid>
                    )}

                    {(data.mobile || data.email) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Contact</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {data.mobile && (
                                <Grid item xs={6} sm={6}>
                                  <InfoLabel
                                    label={"Mobile"}
                                    info={data.mobile}
                                  />
                                </Grid>
                              )}
                              {data.email && (
                                <Grid item xs={6} sm={6}>
                                  <InfoLabel
                                    label={"Email"}
                                    info={data.email}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.address_line_1 ||
                      data.address_line_2 ||
                      data.postal_code ||
                      data.city ||
                      data.state ||
                      data.country) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Current Address</Heading>
                          <Content component={"div"}>
                            <Grid container>
                              {data.address_line_1 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 1"}
                                    info={data.address_line_1}
                                  />
                                </Grid>
                              )}

                              {data.address_line_2 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 2"}
                                    info={data.address_line_2}
                                  />
                                </Grid>
                              )}

                              {data.postal_code && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Postal Code"}
                                    info={data.postal_code}
                                  />
                                </Grid>
                              )}

                              {data.city && (
                                <Grid item sm={4}>
                                  <InfoLabel label={"City"} info={data.city} />
                                </Grid>
                              )}

                              {data.state && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"State"}
                                    info={data.state}
                                  />
                                </Grid>
                              )}

                              {data.country && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Country"}
                                    info={data.country}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.os_address_line_1 ||
                      data.os_address_line_2 ||
                      data.os_postal_code ||
                      data.os_city ||
                      data.os_state ||
                      data.os_country) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Home Country Address</Heading>
                          <Content component={"div"}>
                            <Grid container>
                              {data.os_address_line_1 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 1"}
                                    info={data.os_address_line_1}
                                  />
                                </Grid>
                              )}

                              {data.os_address_line_2 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 2"}
                                    info={data.os_address_line_2}
                                  />
                                </Grid>
                              )}

                              {data.os_postal_code && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Postal Code"}
                                    info={data.os_postal_code}
                                  />
                                </Grid>
                              )}

                              {data.os_city && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"City"}
                                    info={data.os_city}
                                  />
                                </Grid>
                              )}

                              {data.os_state && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"State"}
                                    info={data.os_state}
                                  />
                                </Grid>
                              )}

                              {data.os_country && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Country"}
                                    info={data.os_country}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.em_address_line_1 ||
                      data.em_address_line_2 ||
                      data.em_postal_code ||
                      data.em_city ||
                      data.em_state ||
                      data.em_country) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Employer Address</Heading>
                          <Content component={"div"}>
                            <Grid container>
                              {data.em_address_line_1 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 1"}
                                    info={data.em_address_line_1}
                                  />
                                </Grid>
                              )}

                              {data.em_address_line_2 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 2"}
                                    info={data.em_address_line_2}
                                  />
                                </Grid>
                              )}

                              {data.em_postal_code && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Postal Code"}
                                    info={data.em_postal_code}
                                  />
                                </Grid>
                              )}

                              {data.em_city && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"City"}
                                    info={data.em_city}
                                  />
                                </Grid>
                              )}

                              {data.em_state && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"State"}
                                    info={data.em_state}
                                  />
                                </Grid>
                              )}

                              {data.em_country && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Country"}
                                    info={data.em_country}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.bank_account_number ||
                      data.bank_swift_code ||
                      data.local_bank_code) &&
                    printSection.finance ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Foreign Bank Information</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {data.bank_account_number && (
                                <Grid item xs={4}>
                                  <InfoLabel
                                    label={"Bank Account Number"}
                                    info={data.bank_account_number}
                                  />
                                </Grid>
                              )}

                              {data.bank_swift_code && (
                                <Grid item xs={4}>
                                  <InfoLabel
                                    label={"Bank SWIFTCODE"}
                                    info={data.bank_swift_code}
                                  />
                                </Grid>
                              )}

                              {data.local_bank_code && (
                                <Grid item xs={4}>
                                  <InfoLabel
                                    label={"Local Bank Code"}
                                    info={data.local_bank_code}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.account_owner || data.iban_number) &&
                    printSection.finance ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Bank Information</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {data.account_owner && (
                                <Grid item xs={6}>
                                  <InfoLabel
                                    label={"Account Owner"}
                                    info={data.account_owner}
                                  />
                                </Grid>
                              )}

                              {data.iban_number && (
                                <Grid item xs={6}>
                                  <InfoLabel
                                    label={"IBAN Number"}
                                    info={data.iban_number}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.social_security_number ||
                      data.foreign_social_security_number) &&
                    printSection.finance ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Social Security Data</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {data.social_security_number && (
                                <Grid item xs={6}>
                                  <InfoLabel
                                    label={"Social Security Number"}
                                    info={data.social_security_number}
                                  />
                                </Grid>
                              )}

                              {data.foreign_social_security_number && (
                                <Grid item xs={6}>
                                  <InfoLabel
                                    label={"Foreign Social Security Number"}
                                    info={data.foreign_social_security_number}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {data.health_insurance_provider && printSection.finance ? (
                      <Grid item xs={6} sm={6}>
                        <Wrapper>
                          <Heading>Health Insurance</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {data.health_insurance_provider && (
                                <Grid item xs={12}>
                                  <InfoLabel
                                    label={"Health Insurance Provider"}
                                    info={data.health_insurance_provider}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}
                  </Grid>
                </Wrapper>
              </Grid>
            </Grid>
          </Content>
        </div>
      </DialogContent>
      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Button
              onClick={() => setEdit(false)}
              variant="contained"
              startIcon={<PrintIcon />}
              disabled={
                !printSection.general &&
                !printSection.finance &&
                !printSection.contact_information
              }
            >
              Print
            </Button>
          )}
          content={() => componentRef}
          documentTitle={`${toTitleCase(data.first_name)} ${toTitleCase(
            data.last_name
          )} (Student).pdf`}
          onBeforeGetContent={() => setEdit(false)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Print;
