import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Grid,
  InputAdornment,
  Divider,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  IconButton,
  Modal,
  Backdrop,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";
import useAuth from "../../../../hooks/useAuth";
import useNotistack from "../../../../hooks/useNotistack";
import { toTitleCase } from "../../../../utils/utility";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import FileField from "../../../../components/UI/FileField";
import {
  applicantStatus,
  masterReference,
} from "../../../../constants/masterData";
import { getCompaniesListForUser } from "../../../../services/users";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import DateField from "../../../../components/UI/DateField";
import { Heading } from "../../../../components/style/style";
import { colors } from "../../../../constants/colors";
import {
  getEntityTypeList,
  getEntitiesList,
} from "../../../../services/master";
import { getGoalFlow } from "../../../../services/schools";
import { isValidFileSize, isValidFileType } from "../../../../utils/utility";
import {
  downloadS3Object,
  handleDecimalInput,
  handleKeys,
} from "../../../../utils/common";
import { dateFormFormat } from "../../../../utils/dates";
import { useRecordSelection } from "../../../../hooks/useRecordSelection";

// Define options for status and gender dropdowns
const statusOptions = [
  { label: "Sprachkurs", value: "Sprachkurs" },
  { label: "Ausbildung/Anerkennung", value: "Ausbildung" },
  { label: "Verpflichtet", value: "Verpflichtet" },
  { label: "Archiv", value: "Archiv" },
];

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const StyledFileField = styled(FileField)`
  input[type="file"] {
    width: 110px;
    color: transparent;
  }
`;
const StyledInputAdornment = styled(InputAdornment)((props) => ({
  width: "80%",
}));

const StyledCloseButton = styled(Close)(() => ({
  position: "absolute",
  right: -8,
  top: -8,
  backgroundColor: colors.light_gray,
  color: colors.absolute_red,
  borderRadius: "50px",
  cursor: "pointer",
}));

const PreviewIframe = styled("iframe")(() => ({
  height: "100%",
  width: "100%",
  border: 0,
  backgroundColor: colors.white,
}));

const StyledDownloadIcon = styled(DownloadIcon)((props) => ({
  color: colors.primary,
}));

const HeadTypography = styled(Typography)((props) => ({
  fontWeight: "600 !important",
}));

// General component receives 'formik' as a prop
const General = ({
  formik,
  handleFormChange,
  entitiesList,
  entitiesNameList,
  goalsList,
}) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { selectedRecord } = useRecordSelection();

  const { user } = useAuth();
  const { notify } = useNotistack();
  const [companyList, setCompanyList] = useState([]);
  const [goalFlowList, setGoalFlowList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorIndex, setErrorIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [documentSrc, setdocumentSrc] = useState(false);

  const getFileName = (url) => {
    if (url) {
      if (typeof url === "string") {
        let name = url.split("/").at(-1);

        return (name ?? "").length > 20
          ? (name ?? "").substring(0, 20) + "..."
          : name ?? "";
      } else if (typeof url === "object") {
        let name = url.name;

        return (name ?? "").length > 20
          ? (name ?? "").substring(0, 20) + "..."
          : name ?? "";
      }
    }
  };

  // --------------check if the url of website is valid http------
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  const handleOpen = (url) => {
    setdocumentSrc(url);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDownload = (url) => {
    if (url) {
      downloadS3Object(url);
    }
  };

  useEffect(() => {
    if (user.code.toLowerCase() === masterReference.SUPER_ADMIN) {
      getCompaniesListForUser().then((company) => {
        company.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setCompanyList(company.data);
        formik.setFieldValue("company_id", company.data[0]?.value);
      });
    }
  }, [user]);

  useEffect(() => {
    if (formik.values.goal_id > 0) {
      let requestParams = {
        goal_id: formik.values.goal_id,
        userType: "applicant",
      };
      getGoalFlow(requestParams)
        .then((res) => {
          if (res.isOk && res.data.length > 0) {
            setGoalFlowList(res.data);
          } else {
            setGoalFlowList([]);
            formik.setFieldValue("goal_flow_guid", null);
            if (
              user.code !== masterReference.HR &&
              user.code !== masterReference.FOREIGN_INSTITUTE_MANAGER &&
              user.code !== masterReference.SCHOOL_MANAGER
            ) {
              notify("Flow not found for this Goal.", "error");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formik.values.goal_id]);

  const validCodes = [
    masterReference.HR.toLowerCase(),
    masterReference.AGENCY_USER.toLowerCase(),
  ];

  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Wrapper style={{ padding: "12px" }}>
            {isEditable ? (
              <Content component={"div"}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputField
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      value={formik.values.first_name}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.first_name
                          ? formik.errors?.first_name
                          : ""
                      }
                      error={
                        formik.touched?.first_name &&
                        Boolean(formik.errors?.first_name)
                      }
                      required
                      width="97%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      value={formik.values.last_name}
                      onChange={handleFormChange}
                      required
                      helperText={
                        formik.touched?.last_name
                          ? formik.errors?.last_name
                          : ""
                      }
                      error={
                        formik.touched?.last_name &&
                        Boolean(formik.errors?.last_name)
                      }
                      width="97%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="qualification"
                      name="qualification"
                      label="Qualification"
                      value={formik.values.qualification}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.qualification
                          ? formik.errors?.qualification
                          : ""
                      }
                      error={
                        formik.touched?.qualification &&
                        Boolean(formik.errors?.qualification)
                      }
                      width="97%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="experience"
                      name="experience"
                      label="Experience"
                      value={formik.values.experience}
                      onKeyPress={handleDecimalInput}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.experience
                          ? formik.errors?.experience
                          : ""
                      }
                      error={
                        formik.touched?.experience &&
                        Boolean(formik.errors?.experience)
                      }
                      width="97%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  {user.code === masterReference.SUPER_ADMIN && (
                    <Grid item xs={12} sm={4}>
                      <SelectDropdown
                        id={"company_id"}
                        name={"company_id"}
                        options={companyList}
                        label="company"
                        onChange={(_, selected) => {
                          setIsDirty(true);
                          formik.setFieldValue(
                            "company_id",
                            selected?.value || ""
                          );
                        }}
                        value={
                          companyList.find(
                            (option) =>
                              option.value === formik.values.company_id
                          ) || ""
                        }
                        helperText={
                          formik.touched?.company_id
                            ? formik.errors?.company_id
                            : ""
                        }
                        error={
                          formik.touched?.company_id &&
                          Boolean(formik.errors?.company_id)
                        }
                        required
                      />
                    </Grid>
                  )}
                  {validCodes.includes(user.code.toLowerCase()) && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <SelectDropdown
                          id={"entity_type"}
                          name={"entity_type"}
                          options={entitiesList}
                          label="Entity Type"
                          width="97%"
                          onChange={(_, selected) => {
                            setIsDirty(true);
                            formik.setFieldValue(
                              "entity_type",
                              selected?.value || ""
                            );
                            formik.setFieldValue("entity_id", "");
                            formik.setFieldValue("goal_id", "");
                          }}
                          value={
                            entitiesList.find(
                              (option) =>
                                option.value === formik.values.entity_type
                            ) || ""
                          }
                          helperText={
                            formik.touched?.entity_type
                              ? formik.errors?.entity_type
                              : ""
                          }
                          error={
                            formik.touched?.entity_type &&
                            Boolean(formik.errors?.entity_type)
                          }
                          required
                          disabled={
                            selectedRecord?.status === applicantStatus.APPROVED
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SelectDropdown
                          id={"entity_id"}
                          name={"entity_id"}
                          options={entitiesNameList}
                          label="Entity"
                          width="97%"
                          onChange={(_, selected) => {
                            setIsDirty(true);
                            formik.setFieldValue(
                              "entity_id",
                              selected?.value || ""
                            );
                            formik.setFieldValue("goal_id", "");
                          }}
                          value={
                            entitiesNameList.find(
                              (option) =>
                                option.value === formik.values.entity_id
                            ) || ""
                          }
                          helperText={
                            formik.touched?.entity_id
                              ? formik.errors?.entity_id
                              : ""
                          }
                          error={
                            formik.touched?.entity_id &&
                            Boolean(formik.errors?.entity_id)
                          }
                          required
                          disabled={
                            selectedRecord?.status === applicantStatus.APPROVED
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={4}>
                    <SelectDropdown
                      id={"goal_id"}
                      name={"goal_id"}
                      options={goalsList}
                      width="97%"
                      label="Goal"
                      onChange={(_, selected) => {
                        setIsDirty(true);
                        formik.setFieldValue("goal_id", selected?.value || "");
                      }}
                      value={
                        goalsList.find(
                          (option) => option.value === formik.values.goal_id
                        ) || ""
                      }
                      helperText={
                        formik.touched.goal_id ? formik.errors.goal_id : ""
                      }
                      error={
                        formik.touched.goal_id && Boolean(formik.errors.goal_id)
                      }
                      required
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  {formik.values.goal_id > 0 && (
                    <Grid item xs={4}>
                      <SelectDropdown
                        id={"goal_flow_guid"}
                        name={"goal_flow_guid"}
                        options={goalFlowList}
                        width="100%"
                        label="Goal Flow"
                        onChange={(_, selected) => {
                          setIsDirty(true);

                          formik.setFieldValue(
                            "goal_flow_guid",
                            selected?.value || ""
                          );
                        }}
                        value={
                          goalFlowList.find(
                            (option) =>
                              option.value === formik.values.goal_flow_guid
                          ) || ""
                        }
                        helperText={
                          formik.touched.goal_flow_guid
                            ? formik.errors.goal_flow_guid
                            : ""
                        }
                        error={
                          (formik.touched.goal_flow_guid &&
                            Boolean(formik.errors.goal_flow_guid)) ||
                          Boolean(goalFlowList.length <= 0)
                        }
                        required
                        disabled={
                          selectedRecord?.status === applicantStatus.APPROVED
                        }
                      />
                      {(user.code === masterReference.HR ||
                        user.code ===
                          masterReference.FOREIGN_INSTITUTE_MANAGER ||
                        user.code === masterReference.SCHOOL_MANAGER) &&
                        goalFlowList.length <= 0 && (
                          <React.Fragment>
                            <Typography
                              style={{
                                color: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              onClick={() => {
                                let goalIndexId = goalsList.findIndex(
                                  (option) =>
                                    option.value === formik.values.goal_id
                                );

                                window.location.href =
                                  user.code === masterReference.HR
                                    ? `${window.location.origin}/${user.custom_url}/settings/flows/${goalIndexId}/entity_id=${formik.values.entity_id}&entity_type=${formik.values.entity_type}/`
                                    : `${window.location.origin}/${user.company_url}/${user.custom_url}/settings/flows/${goalIndexId}/`;
                              }}
                            >
                              Click To Add Goal Flow
                            </Typography>
                          </React.Fragment>
                        )}
                    </Grid>
                  )}
                </Grid>
              </Content>
            ) : (
              <Content component={"div"}>
                <Grid container spacing={2}>
                  {formik.values.first_name && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"First Name"}
                        info={formik.values.first_name}
                      />
                    </Grid>
                  )}

                  {formik.values.last_name && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Last Name"}
                        info={formik.values.last_name}
                      />
                    </Grid>
                  )}

                  {formik.values.qualification && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Qualification"}
                        info={formik.values.qualification}
                      />
                    </Grid>
                  )}

                  {formik.values.experience && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Experience"}
                        info={formik.values.experience}
                      />
                    </Grid>
                  )}

                  {validCodes.includes(user.code.toLowerCase()) &&
                    formik.values.entity_type && (
                      <Grid item xs={3}>
                        <InfoLabel
                          label={"Entity Type"}
                          info={formik.values.entity_type}
                        />
                      </Grid>
                    )}

                  {validCodes.includes(user.code.toLowerCase()) &&
                    formik.values.entity_id &&
                    entitiesNameList && (
                      <Grid item xs={3}>
                        <InfoLabel
                          label={"Entity"}
                          info={
                            entitiesNameList.find(
                              (option) =>
                                option.value === formik.values.entity_id
                            )?.label
                          }
                        />
                      </Grid>
                    )}

                  {formik.values.goal_id && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Goal"}
                        info={
                          goalsList.find(
                            (option) => option.value === formik.values.goal_id
                          )?.label || goalsList[0]?.label
                        }
                      />
                    </Grid>
                  )}

                  {formik.values.goal_flow_guid && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Goal Flow"}
                        info={
                          goalFlowList.find(
                            (option) => option.value === formik.values.flow_id
                          )?.label || goalFlowList[0]?.label
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Content>
            )}
          </Wrapper>
        </Grid>
      </Grid>

      {formik.values.flow_stage_list?.length > 0 &&
        formik.values.flow_stage_list.map((ele, idx) => {
          const generalFields = formik.values.flow_stage_list?.filter(
            (item) => item.section_name === "general"
          );
          return (
            generalFields.length > 0 &&
            ele.task_count !== ele.field_json.length &&
            ele.section_name === "general" && (
              <Grid container key={idx}>
                <Grid item sm={12} xs={12}>
                  <Wrapper>
                    {/* Show Documents for Contact Information Section */}
                    <Heading>{toTitleCase(ele.sub_stage_name)}</Heading>
                    <Content component={"div"} style={{ padding: "12px" }}>
                      <Grid container spacing={2}>
                        {ele.field_json.map((obj, indexNum) => {
                          return isEditable ? (
                            <>
                              {obj.type === "text_field" ? (
                                <Grid item xs={6} sm={4}>
                                  <InputField
                                    label={toTitleCase(obj.field_name).trim()}
                                    variant="outlined"
                                    name={`value`}
                                    width="100%"
                                    value={obj.value}
                                    onChange={(event) => {
                                      setIsDirty(true);
                                      let fieldIndex =
                                        formik.values.flow_stage_list[
                                          idx
                                        ].field_json.findIndex(
                                          (temp) =>
                                            temp["field_name"] ===
                                            obj.field_name
                                        );

                                      formik.setFieldValue(
                                        `flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                        event.target.value.replace(/^\s+/g, "")
                                      );
                                    }}
                                  />
                                </Grid>
                              ) : obj.type === "dropdown" ? (
                                <Grid item xs={6} sm={4}>
                                  <SelectDropdown
                                    label={toTitleCase(obj.field_name).trim()}
                                    width="100%"
                                    name={`value`}
                                    options={obj.choices}
                                    disableClearable
                                    value={
                                      obj.choices.find(
                                        (option) => option === obj.value
                                      ) || ""
                                    }
                                    onChange={(_, selected) => {
                                      setIsDirty(true);
                                      let fieldIndex =
                                        formik.values.flow_stage_list[
                                          idx
                                        ].field_json.findIndex(
                                          (temp) =>
                                            temp["field_name"] ===
                                            obj.field_name
                                        );
                                      formik.setFieldValue(
                                        `flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                        selected
                                      );
                                    }}
                                  />
                                </Grid>
                              ) : (
                                obj.type === "date_field" && (
                                  <>
                                    <Grid item xs={6} sm={4}>
                                      <DateField
                                        label={toTitleCase(
                                          obj.field_name
                                        ).trim()}
                                        name={`value`}
                                        id={`value`}
                                        width="100%"
                                        // maxDate={dayjs()}
                                        value={
                                          obj.value ? dayjs(obj.value) : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          let fieldIndex =
                                            formik.values.flow_stage_list[
                                              idx
                                            ].field_json.findIndex(
                                              (temp) =>
                                                temp["field_name"] ===
                                                obj.field_name
                                            );
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                            date
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {obj.type !== "file_field" && (
                                <Grid item xs={3}>
                                  <InfoLabel
                                    label={toTitleCase(obj.field_name)}
                                    info={obj.value}
                                  />
                                </Grid>
                              )}
                            </>
                          );
                        })}
                        <Grid item xs={12} sm={12}></Grid>

                        {ele.field_json?.some(
                          (obj) => obj.type === "file_field"
                        ) &&
                          !isEditable && (
                            <>
                              <Grid item xs={12} sm={12}>
                                <Grid container spacing={4}>
                                  <Grid item xs={3}>
                                    <InfoLabel label={`Files`} />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Grid
                                  container
                                  alignItems="center"
                                  rowSpacing={1}
                                  columnSpacing={5}
                                >
                                  <Grid item sm={4}>
                                    <HeadTypography>Document</HeadTypography>
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    <HeadTypography>Submission</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Issue</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Expiry</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Valid</HeadTypography>
                                  </Grid>

                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Actions</HeadTypography>
                                  </Grid>

                                  {/* <Grid item sm={1} align="center">
                                  <HeadTypography>Download</HeadTypography>
                                </Grid>

                                <Grid item sm={1} align="center">
                                  <HeadTypography>View</HeadTypography>
                                </Grid> */}
                                  <Grid item sm={12}>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}

                        {ele.field_json.map((obj, indexNum) => {
                          return isEditable
                            ? obj.type === "file_field" && (
                                <>
                                  <Grid item xs={6} sm={3.5}>
                                    <StyledFileField
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border:
                                            obj.value?.length <= 0
                                              ? `2px solid ${colors.error}`
                                              : "",
                                        },
                                      }}
                                      label={toTitleCase(obj.field_name).trim()}
                                      name={`value`}
                                      width="100%"
                                      onChange={(file) => {
                                        if (file) {
                                          if (
                                            !isValidFileType(file, [
                                              "jpeg",
                                              "jpg",
                                              "png",
                                              "pdf",
                                            ])
                                          ) {
                                            formik.setFieldValue(
                                              `flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              null
                                            );

                                            setErrorMessage(
                                              "File type should be jpeg, jpg, png or pdf."
                                            );
                                            setErrorIndex(indexNum);
                                            return;
                                          } else if (
                                            !isValidFileSize(
                                              file,
                                              5 * 1024 * 1024
                                            )
                                          ) {
                                            formik.setFieldValue(
                                              `flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              null
                                            );

                                            setErrorMessage(
                                              "File size should be less than 5 MB."
                                            );
                                            setErrorIndex(indexNum);
                                            return;
                                          } else {
                                            setIsDirty(true);
                                            formik.setFieldValue(
                                              `flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              file
                                            );

                                            setErrorMessage(""); // Clear error message if file is valid
                                            setErrorIndex(0);
                                          }
                                        }
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <StyledInputAdornment position="start">
                                            {getFileName(obj.value)}
                                          </StyledInputAdornment>
                                        ),
                                      }}
                                      error={
                                        Boolean(errorMessage) &&
                                        errorIndex === indexNum
                                      }
                                    />
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.submission_date ? (
                                      <DateField
                                        label="Submission"
                                        id={`submission_date_value`}
                                        name={`submission_date_value`}
                                        width="100%"
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.submission_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        maxDate={dayjs()}
                                        value={
                                          obj.submission_date_value
                                            ? dayjs(obj.submission_date_value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${indexNum}.submission_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Submission
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.start_date ? (
                                      <DateField
                                        label="Issue"
                                        id={`start_date_value`}
                                        name={`start_date_value`}
                                        width="100%"
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.start_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        maxDate={dayjs()}
                                        value={
                                          obj.start_date_value
                                            ? dayjs(obj.start_date_value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${indexNum}.start_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Issue
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.expiry_date ? (
                                      <DateField
                                        label="Expiry"
                                        id={`expiry_date_value`}
                                        name={`expiry_date_value`}
                                        width="100%"
                                        value={
                                          obj.expiry_date_value
                                            ? dayjs(obj.expiry_date_value)
                                            : null
                                        }
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.expiry_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${indexNum}.expiry_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Expiry
                                      </p>
                                    )}
                                  </Grid>

                                  <Grid item sm={1.5} align="center">
                                    {obj.value && obj.validity_check ? (
                                      <>
                                        <FormControlLabel
                                          label={`Validity`}
                                          style={{ marginTop: "10px" }}
                                          control={
                                            <Checkbox
                                              onChange={(event) => {
                                                event.target.checked
                                                  ? formik.setFieldValue(
                                                      `flow_stage_list.${idx}.field_json.${indexNum}.validity_check_value`,
                                                      true
                                                    )
                                                  : formik.setFieldValue(
                                                      `flow_stage_list.${idx}.field_json.${indexNum}.validity_check_value`,
                                                      false
                                                    );

                                                setIsDirty(true);
                                              }}
                                              checked={
                                                obj.validity_check_value &&
                                                obj.value
                                                  ? true
                                                  : false
                                              }
                                              // style={{
                                              //   border:
                                              //     !obj.validity_check_value &&
                                              //     "1px solid red",
                                              // }}
                                            ></Checkbox>
                                          }
                                        ></FormControlLabel>
                                      </>
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Validity
                                      </p>
                                    )}
                                  </Grid>
                                  {/* <Grid item sm={1} align="center">
                                    <IconButton
                                      onClick={() =>
                                        isValidHttpUrl(obj.value)
                                          ? handleDownload(obj.value)
                                          : ""
                                      }
                                      size="small"
                                      style={{ marginTop: "13px" }}
                                    >
                                      <StyledDownloadIcon
                                        opacity={
                                          obj.value &&
                                          obj.type === "file_field" &&
                                          obj.validity_check &&
                                          obj.validity_check_value
                                            ? 1
                                            : 0.5
                                        }
                                      />
                                    </IconButton>
                                  </Grid>
                                  <Grid item sm={1} align="center">
                                    <IconButton
                                      aria-label="icon"
                                      onClick={() =>
                                        obj.value && handleOpen(obj.value)
                                      }
                                      style={{
                                        marginTop: "13px",
                                        fontSize: 17,
                                        marginRight: "20px",
                                      }}
                                    >
                                      <VisibilityIcon
                                        fontSize="small"
                                        style={{
                                          opacity: isValidHttpUrl(obj.value)
                                            ? 1
                                            : 0.5,
                                        }}
                                      />
                                   
                                    </IconButton>
                                  </Grid> */}
                                  <Grid
                                    item
                                    sm={1}
                                    style={{ marginTop: "13px" }}
                                  >
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleDownload(obj.value)
                                        }
                                        size="small"
                                      >
                                        <StyledDownloadIcon
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        aria-label="icon"
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleOpen(obj.value)
                                        }
                                        style={{
                                          position: "absolute",
                                          marginTop: "-1px",
                                        }}
                                      >
                                        <VisibilityIcon
                                          fontSize="small"
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item sm={6}>
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {errorIndex === indexNum
                                        ? errorMessage
                                        : ""}
                                    </p>
                                  </Grid>
                                  <Grid item sm={12}>
                                    <Divider />
                                  </Grid>
                                </>
                              )
                            : obj.type === "file_field" && (
                                <Grid item xs={12} sm={12}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    rowSpacing={1}
                                    columnSpacing={5}
                                  >
                                    <React.Fragment key={indexNum}>
                                      <Grid item sm={4}>
                                        {obj.type === "file_field" ? (
                                          <Typography value={obj.field_name}>
                                            {obj.field_name}
                                          </Typography>
                                        ) : (
                                          <Typography value={obj.value}>
                                            {obj.field_name}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item sm={2} align="center">
                                        {obj.submission_date ? (
                                          typeof obj.submission_date_value ===
                                            "string" &&
                                          obj.submission_date_value.length <=
                                            0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(
                                              obj.submission_date_value
                                            )
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.start_date ? (
                                          typeof obj.start_date_value ===
                                            "string" &&
                                          obj.start_date_value.length <= 0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(obj.start_date_value)
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {
                                          // formik.values[`${ele}_value`] &&
                                          obj.expiry_date ? (
                                            typeof obj.expiry_date_value ===
                                              "string" &&
                                            obj.expiry_date_value.length <=
                                              0 ? (
                                              "Select date"
                                            ) : (
                                              dateFormFormat(
                                                obj.expiry_date_value
                                              )
                                            )
                                          ) : (
                                            <p>NA</p>
                                          )
                                        }
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.value && obj.validity_check ? (
                                          obj.validity_check_value ? (
                                            "Valid"
                                          ) : (
                                            "Invalid"
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>

                                      {/* <Grid item sm={1} align="center">
                                      {obj.type === "file_field" ? (
                                        <IconButton
                                          onClick={() =>
                                            isValidHttpUrl(obj.value)
                                              ? handleDownload(obj.value)
                                              : ""
                                          }
                                          size="small"
                                        >
                                          <StyledDownloadIcon
                                            opacity={
                                              obj.value &&
                                              obj.type === "file_field" &&
                                              obj.validity_check &&
                                              obj.validity_check_value
                                                ? 1
                                                : 0.5
                                            }
                                          />
                                        </IconButton>
                                      ) : (
                                        <p>NA</p>
                                      )}
                                    </Grid>

                                    <Grid item sm={1} align="center">
                                      {obj.type === "file_field" ? (
                                        <IconButton
                                          aria-label="icon"
                                          onClick={() =>
                                            obj.value && handleOpen(obj.value)
                                          }
                                        >
                                          <VisibilityIcon
                                            fontSize="small"
                                            style={{
                                              opacity: isValidHttpUrl(obj.value)
                                                ? 1
                                                : 0.5,
                                            }}
                                          />
                                        </IconButton>
                                      ) : (
                                        <p>NA</p>
                                      )}
                                    </Grid> */}
                                      <Grid item sm={1.5}>
                                        {obj.type === "file_field" && (
                                          <IconButton
                                            onClick={() =>
                                              isValidHttpUrl(obj.value) &&
                                              handleDownload(obj.value)
                                            }
                                            size="small"
                                            style={{ marginLeft: "30px" }}
                                          >
                                            <StyledDownloadIcon
                                              style={{
                                                opacity: isValidHttpUrl(
                                                  obj.value
                                                )
                                                  ? 1
                                                  : 0.5,
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                        {obj.type === "file_field" && (
                                          <IconButton
                                            aria-label="icon"
                                            onClick={() =>
                                              isValidHttpUrl(obj.value) &&
                                              handleOpen(obj.value)
                                            }
                                            style={{
                                              position: "absolute",
                                              marginTop: "-1px",
                                            }}
                                          >
                                            <VisibilityIcon
                                              fontSize="small"
                                              style={{
                                                opacity: isValidHttpUrl(
                                                  obj.value
                                                )
                                                  ? 1
                                                  : 0.5,
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  </Grid>
                                </Grid>
                              );
                        })}
                      </Grid>
                    </Content>
                  </Wrapper>
                </Grid>
              </Grid>
            )
          );
        })}

      <Backdrop onClick={handleClose} open={open}>
        <Modal
          onClose={handleClose}
          open={open}
          sx={{
            width: "90%",
            height: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: colors.white,
            boxShadow: 24,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <StyledCloseButton
              fontSize="large"
              onClick={handleClose}
              color="error"
            />
            <PreviewIframe
              id="i_frame"
              src={documentSrc}
              title="document"
            ></PreviewIframe>
          </div>
        </Modal>
      </Backdrop>
    </>
  );
};

export default General;
