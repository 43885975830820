import { Delete } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { styled } from "styled-components";
import InputField from "../../../../components/UI/InputField";
import { Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import { applicantStatus } from "../../../../constants/masterData";
import { useRecordSelection } from "../../../../hooks/useRecordSelection";

const StyledGrid = styled(Grid)`
  padding: 12px;
  background: #cde5f9;
  border-radius: 6px;
`;

// General component receives 'formik' as a prop
const Reports = ({ formik, handleFormChange }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { selectedRecord } = useRecordSelection();

  const addFields = () => {
    let oldFields = [...formik.values.reports];
    oldFields.push({
      id: 0,
      comment: "",
    });
    formik.setFieldValue(`reports`, oldFields);
  };

  const removeFields = (index, id) => {
    let oldFields = [...formik.values.reports];
    let oldDeleteReportData = [...formik.values.delete_report_data];
    if (id > 0) {
      oldDeleteReportData.push(id);
      formik.setFieldValue(`delete_report_data`, oldDeleteReportData);
    }
    oldFields.splice(index, 1);
    formik.setFieldValue(`reports`, oldFields);
  };

  const handleCommentSubmit = (values) => {
    let oldFields = [...formik.values.reports];
    oldFields.push({
      id: 0,
      comment: values.comment,
    });
    formik.setFieldValue(`reports`, oldFields);
    setIsDirty(true);
    commentFormik.resetForm();
  };

  const validateSchema = Yup.object().shape({
    comment: Yup.string().required("REQUIRED"),
  });

  const commentFormik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: validateSchema,
    onSubmit: handleCommentSubmit,
  });

  return (
    <Grid container>
      <Grid item sm={12} xs={12}>
        {isEditable ? (
          <Wrapper style={{ padding: "12px" }}>
            <Container>
              <Grid container spacing={2}>
                <Grid item sm={11}>
                  <InputField
                    width="100%"
                    label="Comment"
                    required
                    value={commentFormik.values.comment}
                    onChange={(event) => {
                      commentFormik.setFieldValue(
                        "comment",
                        event.target.value.replace(/^\s+/g, "")
                      );
                    }}
                    error={
                      commentFormik.touched.comment &&
                      Boolean(commentFormik.errors.comment)
                    }
                    helperText={
                      commentFormik.touched.comment &&
                      commentFormik.errors.comment
                    }
                    disabled={
                      selectedRecord?.status === applicantStatus.APPROVED
                    }
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    variant="contained"
                    // color="success"
                    type="button"
                    sx={{ mt: "12px", background: "#15a731" }}
                    onClick={commentFormik.handleSubmit}
                    disabled={
                      selectedRecord?.status === applicantStatus.APPROVED
                    }
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: "12px",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                {formik.values.reports.map((report, index) => (
                  <Grid item sm={12} key={index}>
                    <Grid container>
                      <Grid item sm={11.5}>
                        <InputField
                          width="100%"
                          label={"Comment " + (index + 1)}
                          value={report?.comment}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `reports.${index}.comment`,
                              event.target.value.replace(/^\s+/g, "")
                            );
                            setIsDirty(true);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={0.5}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          onClick={() => removeFields(index, report.id)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Wrapper>
        ) : formik.values.reports.length > 0 &&
          formik.values.reports[0].comment !== "" ? (
          <Wrapper style={{ padding: "12px" }}>
            <Container>
              <Grid
                container
                spacing={2}
                style={{
                  padding: "12px",
                  display: "flex",
                  flexDirection: "column-reverse",
                  marginLeft: "-10px",
                }}
              >
                {formik.values.reports.map((report, index) => (
                  <StyledGrid item sm={12} key={index} sx={{ mt: 2 }}>
                    <Typography>{report.comment}</Typography>
                  </StyledGrid>
                ))}
              </Grid>
            </Container>
          </Wrapper>
        ) : (
          <Wrapper style={{ padding: "12px" }}>
            <Container>
              <Typography sx={{ mt: 1 }}>No comments.</Typography>
            </Container>
          </Wrapper>
        )}
      </Grid>
    </Grid>
  );
};

export default Reports;
