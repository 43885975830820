import { useState } from "react";
import { FilterBarWrapper } from "./style";
import { Autocomplete, Grid, TextField } from "@mui/material";
import SearchSelect from "../../components/UI/SearchSelect";
import { useEffect } from "react";
import styled from "styled-components";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Wrapper = styled.div`
  margin-bottom: 4px;
`;

const FilterBar = (props) => {
  const [selectedCompany, setSelectedCompany] = useState(props.companyId);

  const handleCompanyChange = (_, selected) => {
    setSelectedCompany(selected.value);
    props.onChange(selected.value);
  };

  //  useEffect(() => {
  //    setSelectedCompany(selectedCompany);
  //    props.onChange(selectedCompany);
  //   }, [props.companyList]);

  return (
    <FilterBarWrapper>
      <Grid item xs={12} sm={11}>
        <Autocomplete
          style={{ marginBottom: "25px", width: "100%" }}
          variant={"basic"}
          id="company_id"
          name="company_id"
          disableClearable
          fullWidth
          options={props.companyList}
          onChange={handleCompanyChange}
          value={
            props.companyList.find(
              (option) => option.value === parseInt(selectedCompany)
            ) || ""
          }
          label=""
          renderInput={(params) => (
            <TextField {...params} label={""} variant="standard" />
          )}
        />
      </Grid>
    </FilterBarWrapper>
  );
};

// export const MobileFilterBar = (props) => {
//   const [selectedCompany, setSelectedCompany] = useState(props.companyId);

//   const handleCompanyChange = (_, selected) => {
//     setSelectedCompany(selected.value);
//     props.onChange(selected.value);
//   };

//   return (
//     <Wrapper>
//       <SearchSelect
//         id="company_id"
//         name="company_id"
//         variant={props.variant ? props.variant : 'basic'}
//         label=""
//         options={props.companyList}
//         onChange={handleCompanyChange}
//         value={
//           props.companyList.find((option) => option.value === selectedCompany) ||
//           ''
//         }
//       />
//     </Wrapper>
//   );
// };

export default FilterBar;
