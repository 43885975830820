import { Grid } from "@mui/material";
import styled from "styled-components";
import ShrinkLabelInput from "../../components/UI/ShrinkLabelInput";

export const CompanyGrid = styled(Grid)`
  && {
    width: 75%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const LabeledCompanyInput = styled(ShrinkLabelInput)`
  width: 100%;
`;

export const GridItem = styled(Grid)`
  && {
    padding-top: 0px !important;
  }
`;
