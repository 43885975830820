import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import useNotistack from "../../hooks/useNotistack";
import Btn1 from "../../components/UI/Btn1";
import LabeledFormInput from "../../components/UI/LabeledFormInput";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import clsx from "clsx";
import styled from "styled-components";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { useSnackbar } from 'notistack';
import { LinkWrapper, StyledForm, InputWrapper, TextFieldTitle } from "./style";
import {
  IconButton,
  InputAdornment,
  css,
  TextField,
  Grid,
} from "@mui/material";
import { getLoginForCompany } from "../../services/auth";
import { Header, CompanyNotFoundHeader } from "./Header";
import { colors } from "../../styles/vars";

const StyledTextField = styled(TextField)`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const InputError = styled("div")`
  color: #900;
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
`;

// const LinkWrapper = styled.div`
//   text-align: right;
//   margin: 10px 0px;
// `;

// const StyledForm = styled.form`
//   width: 70%;
//   padding: 20px;
//   display: flex;
//   ${flex.col}
// `;

// const InputWrapper = styled.div`
//   margin: 10px 0px;
// `;

// const PasswordInputWrapper = styled.div`
//   margin: 10px 0px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-item: flex-start;
//   position: relative;
// `;
// const IconWrapper = styled.div`
//   position: absolute;
//   right: 10px;
//   top: 37px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;

const RootWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: radial-gradient(#653d84, #332042);
  position: absolute;
  display: flex;
`;
const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 100vh;
  background-size: cover;
  background-repeat: none;
`;
const ContentWrapper = styled.div`
  width: 70%;
  height: 80%;
  position: absolute;
  transform: translate(20%, 15%);
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 4px 22px -8px #0004;
  display: flex;
  overflow: hidden;
`;

const loginSchema = yup.object({
  email: yup.string().trim().email("Email must be valid").required(),
  password: yup.string().required(),
});

export default function LoginForm({
  isLoading,
  setIsLoading,
  contentError,
  companyName,
  paramValues,
}) {
  const { notify } = useNotistack();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    // authenticationError,
    setAuthenticationError,
    companyLoginWithEmailPassword,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const res = await companyLoginWithEmailPassword(
          paramValues.url,
          values.email,
          values.password
        );
        if (res.isOk) {
          setAuthenticationError("");
        } else {
          notify(res.error[0], "error");
        }
      } catch (e) {
        notify("Email or Password is incorrect 2", "error");
        setAuthenticationError("Email or Password is incorrect 2");
      } finally {
        setIsLoading(false);
      }
      // if (values.email === "test@wecasoft.com" && values.password === "dev") {
      //   setAuthenticated("1");
      // } else {
      //   setAuthenticated("0");
      // }
    },
  });

  // useEffect(() => {
  //   if (authenticationError)
  //     enqueueSnackbar(authenticationError, {
  //       variant: 'error'
  //     });
  // }, [authenticationError, enqueueSnackbar]);

  return (
    <>
      {contentError?.length > 1 ? (
        <RootWrapper>
          <ContentWrapper>
            <CompanyNotFoundHeader>{contentError}</CompanyNotFoundHeader>
          </ContentWrapper>
        </RootWrapper>
      ) : (
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldTitle style={{ textAlign: "left", fontWeight: 600 }}>
                {"Username"}
              </TextFieldTitle>
              <InputWrapper>
                <StyledTextField
                  size="small"
                  id="email"
                  title={"Username"}
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                />

                {formik.touched.email && formik.errors.email && (
                  <InputError>{formik.errors.email}</InputError>
                )}
              </InputWrapper>
            </Grid>
            <Grid item xs={12}>
              <TextFieldTitle style={{ textAlign: "left", fontWeight: 600 }}>
                {"Password"}
              </TextFieldTitle>
              <InputWrapper>
                <StyledTextField
                  size="small"
                  id="password"
                  title={"Password"}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  style={{ backgroundColor: "white" }}
                />
                {formik.touched.password && formik.errors.password && (
                  <InputError>
                    {formik.errors.password.replace("password", "Password")}
                  </InputError>
                )}
              </InputWrapper>
            </Grid>
          </Grid>
          <LinkWrapper>
            <Link
              to={
                paramValues.companyURL
                  ? `/${paramValues.companyURL}/${
                      paramValues.url.split("/login")[0]
                    }/forgot-password`
                  : `/${paramValues.url.split("/login")[0]}/forgot-password`
              }
            >
              Forgot Password?
            </Link>
          </LinkWrapper>
          <div
            style={{
              marginTop: "50px",
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Btn1
              color={colors.peach}
              className={clsx(isLoading && "disabled")}
              disabled={isLoading || !formik.isValid}
              type="submit"
              text={!isLoading ? "LOGIN" : "Please wait..."}
            />
          </div>
        </StyledForm>
      )}
    </>
  );
}
