import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Grid, Typography, Modal, Backdrop, Box, Tooltip } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ArrowBack, Close } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import styled from "styled-components";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../../constants/colors";
import { StyledTab, TabItem, a11yProps } from "../../../components/TabBar";
import useNotistack from "../../../hooks/useNotistack";
import SearchSelect from "../../../components/UI/SearchSelect";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import {
  extractPlaceHolder,
  generateFile,
  getTemplateList,
  updateExtractPlaceHolder,
  updateGeneratedFile,
} from "../../../services/file";
import LabeledFormInput from "../../../components/UI/LabeledFormInput";
import { toTitleCase } from "../../../utils/utility";
import { downloadS3Object } from "../../../utils/common";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import {
  Content,
  Heading,
  Wrapper,
  NoShadaowWrapper,
} from "../../../components/style/style";

const StyledDownloadIcon = styled(DownloadIcon)((props) => ({
  color: colors.primary,
}));

const StyledCloseButton = styled(Close)(() => ({
  position: "absolute",
  right: -8,
  top: -8,
  backgroundColor: colors.light_gray,
  color: colors.absolute_red,
  borderRadius: "50px",
  cursor: "pointer",
}));

const BackButton = styled.div`
  cursor: pointer;
  margin-top: 5px;
`;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.Typography,
  marginTop: 20,
  padding: 10,
  textAlign: "center",
  color: "#fff",
}));

const PreviewIframe = styled("iframe")(() => ({
  height: "100%",
  width: "100%",
  border: 0,
  backgroundColor: colors.white,
}));

const Generate = ({ requestId, entity_id, entity_type }) => {
  const { notify } = useNotistack();
  const {
    isDirty,
    setIsDirty,
    generateRecord,
    setGenerateRecord,
    setDisableGenerate,
    setRequestId,
  } = useCustomFormSubmit();
  const [isLoading, setIsLoading] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [documentSrc, setdocumentSrc] = useState(false);
  const [documentType, setdocumentType] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [generatedTemplatesList, setGeneratedTemplatesList] = useState([]);
  const [docPath, setDocPath] = useState("");
  const [newPlaceholder, setNewPlaceholder] = useState("");
  const [updatePlaceHolder, setUpdatePlaceHolder] = useState("");
  const [editTemplate, setEditTemplate] = useState(false);
  const [editTemplateIndex, setEditTemplateIndex] = useState(0);
  const [templateId, setTemplateId] = useState(0);
  // State to manage the active tab
  const [activeTab, setActiveTab] = useState(0);

  const handleClosePlaceholder = () => {
    setEditTemplateIndex(0);
    setEditTemplate(false);
    setNewPlaceholder([]);
    setUpdatePlaceHolder([]);
  };

  // Function to handle tab change
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    handleClosePlaceholder();
    setDocPath("");
    generateFormik.setFieldValue("template_id", "");
  };

  const validateSchema = Yup.object().shape({
    template_id: Yup.number().required("REQUIRED"),
  });
  const updateValidateSchema = Yup.object().shape({});

  //setup state variable for new Schema, basing it on the original schema
  const [newValidationSchema, setNewValidationSchema] =
    useState(validateSchema);
  const [newUpdateValidationSchema, setNewUpdateValidationSchema] =
    useState(updateValidateSchema);

  const handleOpen = (url, type) => {
    setdocumentType(type);
    setdocumentSrc(url);
    setOpenFile(true);
  };

  const handleDownload = (url) => {
    if (url) {
      downloadS3Object(url);
    }
  };

  const handleSubmit = async (values) => {
    console.log(values);
    setIsLoading(true);

    let placeHolderObj = {};

    newPlaceholder.forEach((placeHolder, index) => {
      placeHolderObj[Object.keys(placeHolder)[0]] =
        values[Object.keys(placeHolder)[0]];
    });

    let submittingData = {
      document_type: "teacher_contract",
      place_holder: placeHolderObj,
      user_id: requestId,
      doc_url: docPath,
      template_id: templateId,
    };

    let requestParams = {
      data: submittingData,
    };
    console.log(requestParams);
    const res = await generateFile(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setGeneratedTemplatesList(
        res.data?.update_user_template_data?.generated_templates?.template_path
      );
      window.open(res.data?.generate_presigned_url);
      setActiveTab(1);
      notify("Generated Document Downloaded", "success");
    } else {
      console.log("error>>>", res.error);
      for (const [key, value] of Object.entries(res.error)) {
        notify(`${key}: ${value}`, "error");
      }
    }
  };

  // Initialize generateformik with form configuration
  const generateFormik = useFormik({
    initialValues: {
      template_id: "",
    },
    validationSchema: newValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleUpdateSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);

    let placeHolderObj = {};
    let docPlaceHolderObj = {};
    // create placeholder with old existing and new updated values

    updatePlaceHolder.forEach((placeHolder, index) => {
      placeHolderObj[Object.keys(placeHolder)[0]] =
        values[Object.keys(placeHolder)[0]];
      docPlaceHolderObj[
        generatedTemplatesList[editTemplateIndex].placeholders[
          Object.keys(placeHolder)[0]
        ]
      ] = values[Object.keys(placeHolder)[0]];
    });

    let submittingData = {
      user_template_id: templateId,
      doc_template_id: editTemplateIndex,
      document_type: "teacher_contract",
      user_id: requestId,
      place_holder: placeHolderObj,
      file_place_holder: docPlaceHolderObj,
      doc_url: docPath,
    };

    let requestParams = {
      data: submittingData,
    };
    console.log(requestParams);
    const res = await updateGeneratedFile(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setGeneratedTemplatesList(
        res.data?.update_user_template_data?.generated_templates?.template_path
      );
      handleClosePlaceholder();
      notify("Generated Document Updated", "success");
    } else {
      console.log("error>>>", res.error);
      for (const [key, value] of Object.entries(res.error)) {
        notify(`${key}: ${value}`, "error");
      }
    }
  };

  const updateGenerateFormik = useFormik({
    initialValues: {
      template_id: "",
    },
    validationSchema: newUpdateValidationSchema,
    onSubmit: handleUpdateSubmit,
  });

  const handleClose = () => {
    setGenerateRecord(false);
    setNewPlaceholder("");
    setUpdatePlaceHolder("");
  };

  const handleFileClose = () => {
    setOpenFile(false);
  };

  const openEditForm = (value, index) => {
    let requestParams = {
      userTemplateId: templateId,
      docType: "teacher_contract",
      userId: requestId,
      selectedTemplateId: index,
    };
    let newValSchema = updateValidateSchema;
    let newData = {};
    updateExtractPlaceHolder(requestParams)
      .then((res) => {
        if (res.data?.length > 0) {
          setUpdatePlaceHolder(res.data);

          res.data.forEach((data, index) => {
            // push dynamic field key value pair to array
            newData[Object.keys(data)[0]] =
              res.data[index][Object.keys(data)[0]];

            // push dynamic field name to new validation schema
            newValSchema = newValSchema.concat(
              Yup.object().shape({
                [Object.keys(data)[0]]: Yup.string().required("REQUIRED"),
              })
            );
          });
          // set new dynamic fields to existing initialValues
          updateGenerateFormik.setValues(newData);
          // set new dynamic field validation to exisiting validation
          setNewUpdateValidationSchema(newValSchema);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    if (index === editTemplateIndex) {
      setEditTemplate(value);
    } else {
      setEditTemplate(true);
    }
    setEditTemplateIndex(index);
  };

  useEffect(() => {
    setTemplateList([]);

    let requestParams = {
      entityId: entity_id,
      entityType: entity_type,
      templateId: 0,
      documentType: "teacher_contract",
      userId: requestId,
    };

    getTemplateList(requestParams)
      .then((res) => {
        if (res.isOk) {
          res.data?.templates?.template_path.forEach(
            (template, templateIndex) => {
              setTemplateList((prev) => [
                ...prev,
                {
                  label: template.name,
                  value: templateIndex,
                  path: template.path,
                },
              ]);
            }
          );
          setGeneratedTemplatesList(
            res.data?.generated_templates?.template_path
          );
          setTemplateId(res.data?.templates?.id);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [generateRecord]);
  useEffect(() => {
    if (docPath && isDirty) {
      let requestParams = {
        url: docPath,
        documentType: "teacher_contract",
        userId: requestId,
      };
      let newValSchema = validateSchema;
      extractPlaceHolder(requestParams)
        .then((res) => {
          if (res.data?.length > 0) {
            let newData = generateFormik.values;
            setNewPlaceholder(res.data);
            res.data.forEach((data, index) => {
              // push dynamic field key value pair to array
              newData[Object.keys(data)[0]] =
                res.data[index][Object.keys(data)[0]];

              // push dynamic field name to new validation schema
              newValSchema = newValSchema.concat(
                Yup.object().shape({
                  [Object.keys(data)[0]]: Yup.string().required("REQUIRED"),
                })
              );
            });

            // set new dynamic fields to existing initialValues
            generateFormik.setValues(newData);
            // set new dynamic field validation to exisiting validation
            setNewValidationSchema(newValSchema);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [docPath]);

  return (
    <>
      <OverlayLoader show={isLoading}>
        <Grid container spacing={2} style={{ display: "block" }}>
          <Grid item xs={12} sm={12}>
            <Wrapper>
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid item xs={12} sm={12}>
                  <NoShadaowWrapper style={{ padding: "12px" }}>
                    <Heading
                      style={{
                        background: "aliceblue",
                        borderBottom: "2px solid #0b439629",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <StyledTab
                          value={activeTab}
                          onChange={handleTabChange}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: colors.secondary,
                            },
                          }}
                        >
                          <TabItem label={"New"} {...a11yProps(0)} />

                          <TabItem label={"Existing"} {...a11yProps(1)} />
                        </StyledTab>
                      </div>
                    </Heading>
                    <Content component={"div"} style={{ padding: "12px" }}>
                      <Grid container>
                        <Grid item sm={12}>
                          <>
                            {/* New Tab Panel */}

                            <div
                              style={{
                                display: activeTab === 0 ? "" : "none",
                              }}
                              id="simple-tabpanel-0"
                              aria-labelledby="simple-tab-0"
                            >
                              <form onSubmit={generateFormik.handleSubmit}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <SearchSelect
                                      id={"template_id"}
                                      name={"template_id"}
                                      label="Contract"
                                      required
                                      options={templateList}
                                      onChange={(_, selected) => {
                                        setIsDirty(true);
                                        generateFormik.setFieldValue(
                                          "template_id",
                                          selected?.value
                                        );

                                        setDocPath(selected?.path);
                                      }}
                                      value={
                                        templateList.find(
                                          (option) =>
                                            option.value ===
                                            generateFormik.values.template_id
                                        ) || ""
                                      }
                                      helperText={
                                        generateFormik.touched.template_id
                                          ? generateFormik.errors.template_id
                                          : ""
                                      }
                                      error={
                                        generateFormik.touched.template_id &&
                                        Boolean(
                                          generateFormik.errors.template_id
                                        )
                                      }
                                    />

                                    <br />

                                    {newPlaceholder?.length > 0 && (
                                      <>
                                        <Typography component="div">
                                          Placeholder Form
                                        </Typography>
                                        <Grid container spacing={2}>
                                          {newPlaceholder?.length > 0 &&
                                            newPlaceholder.map(
                                              (extractPlaceHolder, index) => {
                                                return (
                                                  <Grid item xs={6}>
                                                    <Tooltip
                                                      title={
                                                        extractPlaceHolder[
                                                          "existing_value"
                                                        ]
                                                          ? "To enable this field , edit Teacher and then Generate"
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <LabeledFormInput
                                                          id={Object.keys(
                                                            extractPlaceHolder
                                                          )[0]
                                                            .toLowerCase()
                                                            .replace("_", " ")}
                                                          required
                                                          title={toTitleCase(
                                                            Object.keys(
                                                              extractPlaceHolder
                                                            )[0].replace(
                                                              "_",
                                                              " "
                                                            )
                                                          )}
                                                          placeholder={toTitleCase(
                                                            Object.keys(
                                                              extractPlaceHolder
                                                            )[0].replace(
                                                              "_",
                                                              " "
                                                            )
                                                          )}
                                                          name={
                                                            Object.keys(
                                                              extractPlaceHolder
                                                            )[0]
                                                          }
                                                          size="small"
                                                          disabled={
                                                            extractPlaceHolder[
                                                              "existing_value"
                                                            ]
                                                          }
                                                          value={
                                                            generateFormik
                                                              .values[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ]
                                                          }
                                                          onChange={(event) => {
                                                            generateFormik.setFieldValue(
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0],
                                                              event.target.value.replace(
                                                                /^\s+/g,
                                                                ""
                                                              )
                                                            );

                                                            setIsDirty(true);
                                                          }}
                                                          helperText={
                                                            generateFormik
                                                              .touched?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ] &&
                                                            generateFormik
                                                              .errors?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ]
                                                          }
                                                          error={
                                                            generateFormik
                                                              .touched?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ] &&
                                                            generateFormik
                                                              .errors?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ]
                                                          }
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  </Grid>
                                                );
                                              }
                                            )}
                                        </Grid>

                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: "25px",
                                            gap: 10,
                                          }}
                                        >
                                          <Button
                                            onClick={handleClose}
                                            type="button"
                                            color="error"
                                            variant="outlined"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            variant="contained"
                                            type="submit"
                                          >
                                            Generate
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </form>
                            </div>

                            {/* Existing Tab Panel */}

                            <div
                              style={{
                                display: activeTab === 1 ? "" : "none",
                              }}
                              id="simple-tabpanel-0"
                              aria-labelledby="simple-tab-0"
                            >
                              {updatePlaceHolder?.length > 0 ? (
                                <>
                                  <Typography style={{ marginTop: 10 }}>
                                    <BackButton
                                      onClick={handleClosePlaceholder}
                                    >
                                      <ArrowBack />
                                    </BackButton>
                                  </Typography>
                                  <form
                                    onSubmit={updateGenerateFormik.handleSubmit}
                                  >
                                    <Box
                                      sx={{
                                        marginTop: 2,
                                      }}
                                    >
                                      <div style={{ margin: 10 }}>
                                        <Grid container spacing={2}>
                                          {updatePlaceHolder?.length > 0 &&
                                            updatePlaceHolder.map(
                                              (extractPlaceHolder, index) => {
                                                return (
                                                  <Grid item xs={6}>
                                                    <Tooltip
                                                      title={
                                                        extractPlaceHolder[
                                                          "existing_value"
                                                        ]
                                                          ? "To enable this field , edit Teacher and then Generate"
                                                          : ""
                                                      }
                                                    >
                                                      <span>
                                                        <LabeledFormInput
                                                          id={Object.keys(
                                                            extractPlaceHolder
                                                          )[0]
                                                            .toLowerCase()
                                                            .replace("_", " ")}
                                                          required
                                                          title={toTitleCase(
                                                            Object.keys(
                                                              extractPlaceHolder
                                                            )[0].replace(
                                                              "_",
                                                              " "
                                                            )
                                                          )}
                                                          placeholder={toTitleCase(
                                                            Object.keys(
                                                              extractPlaceHolder
                                                            )[0].replace(
                                                              "_",
                                                              " "
                                                            )
                                                          )}
                                                          name={
                                                            Object.keys(
                                                              extractPlaceHolder
                                                            )[0]
                                                          }
                                                          size="small"
                                                          disabled={
                                                            extractPlaceHolder[
                                                              "existing_value"
                                                            ]
                                                          }
                                                          value={
                                                            updateGenerateFormik
                                                              .values[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ]
                                                          }
                                                          onChange={(event) => {
                                                            updateGenerateFormik.setFieldValue(
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0],
                                                              event.target.value.replace(
                                                                /^\s+/g,
                                                                ""
                                                              )
                                                            );

                                                            setIsDirty(true);
                                                          }}
                                                          helperText={
                                                            updateGenerateFormik
                                                              .touched?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ] &&
                                                            updateGenerateFormik
                                                              .errors?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ]
                                                          }
                                                          error={
                                                            updateGenerateFormik
                                                              .touched?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ] &&
                                                            updateGenerateFormik
                                                              .errors?.[
                                                              Object.keys(
                                                                extractPlaceHolder
                                                              )[0]
                                                            ]
                                                          }
                                                        />
                                                      </span>
                                                    </Tooltip>
                                                  </Grid>
                                                );
                                              }
                                            )}
                                        </Grid>

                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: "25px",
                                            gap: 10,
                                          }}
                                        >
                                          <Button
                                            onClick={() => {
                                              handleClosePlaceholder();
                                            }}
                                            type="button"
                                            color="error"
                                            variant="outlined"
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            variant="contained"
                                            type="submit"
                                          >
                                            Update
                                          </Button>
                                        </div>
                                      </div>
                                    </Box>
                                  </form>
                                </>
                              ) : generatedTemplatesList?.length > 0 ? (
                                <TableContainer
                                  component={Paper}
                                  style={{ marginTop: 10 }}
                                >
                                  <Table
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>File</TableCell>
                                        <TableCell>Created</TableCell>
                                        <TableCell>View </TableCell>
                                        <TableCell>Edit </TableCell>
                                        <TableCell>Download</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {generatedTemplatesList?.map(
                                        (template, index) => (
                                          <TableRow
                                            key={index}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <p title={template?.name}>
                                                {template?.name}
                                              </p>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              <p style={{ color: "green" }}>
                                                {new Date(
                                                  template?.created_at
                                                ).getMonth() +
                                                  1 +
                                                  "/" +
                                                  new Date(
                                                    template?.created_at
                                                  ).getDate() +
                                                  "/" +
                                                  new Date(
                                                    template?.created_at
                                                  ).getFullYear()}
                                              </p>
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="icon"
                                                onClick={() =>
                                                  template?.path &&
                                                  template?.type &&
                                                  handleOpen(
                                                    template?.path,
                                                    template?.type
                                                  )
                                                }
                                              >
                                                <VisibilityIcon fontSize="small" />
                                              </IconButton>
                                            </TableCell>
                                            <TableCell>
                                              <IconButton
                                                aria-label="icon"
                                                onClick={() => {
                                                  openEditForm(
                                                    !editTemplate,
                                                    generatedTemplatesList?.length -
                                                      1 -
                                                      index
                                                  );
                                                  setDocPath(template?.path);
                                                }}
                                              >
                                                <ModeEditIcon fontSize="small" />
                                              </IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                              <IconButton
                                                onClick={() =>
                                                  handleDownload(template?.path)
                                                }
                                                size="small"
                                              >
                                                <StyledDownloadIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                <Item>
                                  <Typography>No Templates to show.</Typography>
                                </Item>
                              )}
                            </div>
                          </>
                        </Grid>
                      </Grid>
                    </Content>
                  </NoShadaowWrapper>
                </Grid>
              </Content>
            </Wrapper>
          </Grid>
        </Grid>
        {/* -----------------------------------View File popup--------------------- */}
        <Backdrop onClick={handleFileClose} open={openFile}>
          <Modal
            onClose={handleFileClose}
            open={openFile}
            sx={{
              width: "90%",
              height: "90%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: colors.white,
              boxShadow: 24,
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <StyledCloseButton
                fontSize="large"
                onClick={handleFileClose}
                color="error"
              />
              {documentSrc ? (
                documentType == "pdf" ? (
                  <>
                    <DocViewer
                      prefetchMethod="GET"
                      documents={[
                        {
                          uri: `${documentSrc}`,
                          fileType: documentType,
                        },
                      ]}
                      pluginRenderers={DocViewerRenderers}
                    />
                  </>
                ) : (
                  <PreviewIframe
                    id="i_frame"
                    src={`https://docs.google.com/viewer?url=${documentSrc}&embedded=true`}
                    title="document"
                  ></PreviewIframe>
                )
              ) : null}
            </div>
          </Modal>
        </Backdrop>
      </OverlayLoader>
    </>
  );
};

export default Generate;
