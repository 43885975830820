import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { styled } from "styled-components";
import { colors } from "../../../constants/colors";

// Create a styled Button component with custom styles
const StyledBtn = styled(Button)`
  && {
    text-transform: none;
    color: ${({ selected }) => (selected ? colors.white : colors.primary)};
    background: ${({ selected }) => (selected ? colors.secondary : "inherit")};
  }
`;

// Define a functional component for the Btn
const Btn = ({ id, variant = "text", text = "", ...restProps }) => {
  return (
    <Stack spacing={2} direction="row">
      <StyledBtn id={id} variant={variant} {...restProps} size={"small"}>
        {text}
      </StyledBtn>
    </Stack>
  );
};

// Define propTypes to specify the expected types for props
Btn.propTypes = {
  id: PropTypes.string.isRequired, // 'id' is required
};

export default Btn;
