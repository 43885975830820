import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

// import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import { Grid, Typography, IconButton } from "@mui/material";
import { Table, Checkbox } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { CalendarIcon } from "@mui/x-date-pickers";
import dayjs from "dayjs";
// Local Library Imports
// import General from "./General";
import useAuth from "../../../hooks/useAuth";
import { isIterable } from "../../../utils/common";
import { colors } from "../../../constants/colors";
import { toTitleCase } from "../../../utils/utility";
import useNotistack from "../../../hooks/useNotistack";
import { Wrapper } from "../../../components/style/style";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import AddReport from "../Report/addReport";
import { getStudentAttendanceDetails } from "../../../services/schools";
import Calendar from "./General/calendar";
import { getEntitiesList } from "../../../services/master";
import { entitiesList } from "../../../constants/masterData";
import { masterReference } from "../../../constants/masterData";
import { dateFormFormat } from "../../../utils/dates";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.white,
    color: colors.darkBlue,
    fontWeight: 800,

    whiteSpace: "nowrap",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    padding: "0px 3px 0px 3px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const Title = styled("div")(({ theme }) => ({
  color: `${colors.primary}`,
  display: "inline-flex",
  alignItems: "center",
  width: "100%",
  padding: 10,

  borderBottom: `2px solid ${colors.border}`,
  background: `${colors.background}`,
}));

const IconWrapper = styled("div")(({ theme }) => ({
  padding: "3px",
}));

const Course = () => {
  const { notify } = useNotistack();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();

  const [open, setOpen] = useState(false);
  const [schoolId, setSchoolId] = useState(0);
  const [openReport, setOpenReport] = useState(false);

  const [reportContent, setReportContent] = useState();
  const [reportIndex, setReportIndex] = useState(0);
  // const openReport = Boolean(anchorElReport);

  const [currentLessonName, setCurrentLessonName] = useState("");
  const [courseId, setCourseId] = useState(0);
  const [timetableId, setTimeTableId] = useState(0);
  const [attendanceDate, setAttendanceDate] = useState("");
  const [entitiesNameList, setEntitiesNameList] = useState(null);
  const [entityId, setEntityId] = useState(null);
  const [entityType, setEntityType] = useState(entitiesList[0].value);
  const [startDateRange, setStartDateRange] = useState("");
  const [endDateRange, setEndDateRange] = useState("");

  // --------------------Function to get all dates between given range , here week range----------------

  const initialValues = {
    lesson_time_table_id: 0,
    student_list: [
      {
        id: 0,
        student_id: "",
        is_present: false,
        is_excused: false,
        report: "",
        attendance_date: dayjs().format("YYYY-MM-DD"),
      },
    ],
    lessons: [
      {
        lesson_id: 1,
        lesson_name: "",
        start_time: "",
        end_time: "",
        entity_id: 1,
        lesson_time_table: [
          {
            id: 0,
            room: "",
            course_id: 1,
            subject: "",
            day_name: "",
            day_date: "",
            teacher_id: 0,
            is_default: false,
            copy_to_weekdays: false,
          },
        ],
      },
    ],
    start_date: "",
    end_date: "",
  };

  const [data, setData] = useState(initialValues);

  const handleCloseReport = () => setOpenReport(false);

  const showStudentAttendanceDetail = (requestParams) => {
    getStudentAttendanceDetails(requestParams)
      .then((res) => {
        if (res.isOk) {
          setCurrentLessonName(res.data.lesson_name);
          setCourseId(res.data.course_id);
          setSchoolId(res.data.entity_id);
          // setSelectedRecord(res.data);
          // setNoOfPage(Math.ceil(res.total/pageLimit));
          // if (!requestId) {

          setRequestId(res.data?.course_id);
          setSelectedRecord(res.data);
          if (res.data.student_list.length > 0) {
            setEditable(true);
          }

          // }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]);
      });
  };

  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  useEffect(() => {
    if (user.code === masterReference.HR && entityType) {
      getEntitiesList({ entity_type: entityType }).then((res) => {
        res.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setEntitiesNameList(res.data);
        setEntityId(res.data[0].id);
      });
    }
  }, [entitiesList]);

  // list api call to get lessons list
  useEffect(() => {
    let requestParams = {
      lesson_time_table_id: timetableId ? timetableId : 1,
      attendance_date: attendanceDate || "",
      start_date: startDateRange,
      end_date: endDateRange,
      entity_type: user.code === masterReference.HR ? entityType : null,
      entity_id: user.code === masterReference.HR ? entityId : null,
    };

    showStudentAttendanceDetail(requestParams);
  }, [
    requestId,
    timetableId,
    attendanceDate,
    startDateRange,
    endDateRange,
    entityId,
  ]);

  useEffect(() => {
    setIsLoading(true);
    let selectedData = {
      lesson_time_table_id: 0,
      student_list: [
        {
          id: 0,
          student_id: "",
          is_present: false,
          is_excused: false,
          report: "",
          attendance_date: dayjs().format("YYYY-MM-DD"),
        },
      ],
      lessons: [
        {
          lesson_id: 1,
          lesson_name: "",
          start_time: "",
          end_time: "",
          entity_id: 1,
          lesson_time_table: [
            {
              id: 0,
              room: "",
              course_id: 1,
              subject: "",
              day_name: "",
              day_date: "",
              teacher_id: 0,
              is_default: false,
              copy_to_weekdays: false,
            },
          ],
        },
      ],
      start_date: "",
      end_date: "",
    };

    if (selectedRecord && selectedRecord?.attendent_student_list?.length > 0) {
      selectedData.lesson_time_table_id = selectedRecord.lesson_time_table_id;
      selectedData.student_list = selectedRecord.attendent_student_list.map(
        (student, studentIndex) => ({
          ...student,
          id: student.id,
          student_name: selectedRecord.student_list?.[studentIndex]?.name || "",
          student_id: student.student_id,
          is_present: student.is_present,
          is_excused: student.is_excused,
          report: student.report,
          attendance_date: student.attendance_date,
        })
      );

      setData(selectedData);
    } else {
      if (selectedRecord?.student_list?.length > 0) {
        selectedData.lesson_time_table_id = selectedRecord.lesson_time_table_id;
        selectedData.student_list = selectedRecord?.student_list?.map(
          (student, studentIndex) => ({
            ...student,
            id: 0,
            // student_name: selectedRecord.student_list.find(
            //   (s) => s.id === student.student_id
            // )?.name,
            student_name:
              selectedRecord.student_list?.[studentIndex]?.name || "",
            student_id: student.id,
            is_present: false,
            is_excused: false,
            report: "",
            attendance_date:
              // formik.values[`student_list.${index}.attendance_date`],
              dayjs().format("YYYY-MM-DD"),
          })
        );

        setData(selectedData);
      } else {
        formik.resetForm();
      }
    }
    setIsLoading(false);
  }, [selectedRecord]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let submittingData = {};
    submittingData["lesson_time_table_id"] = values.lesson_time_table_id;
    submittingData["student_list"] = values.student_list.map((lesson) => ({
      ...lesson,
      attendance_date: dayjs(lesson.attendance_date).format("YYYY-MM-DD"),
      is_excused: lesson.is_excused ? 1 : 0,
      is_present: lesson.is_present ? 1 : 0,
    }));

    let requestParams = { id: requestId, data: submittingData };
    console.log(requestParams);
    // return;
    formSubmitAPI(requestParams).then(async function (res) {
      setIsLoading(false);
      if (res.isOk) {
        setIsDirty(false);
        // setEditable(false);
        await setRequestId(null);
        setRequestId(requestId ? requestParams.id : res.data.course_id);
        setSelectedIndex(requestId ? requestId : res.data.course_id);
        notify("Information Updated", "success");
      } else {
        for (const [key, value] of Object.entries(res.error)) {
          if (typeof value === "object" && isIterable(value)) {
            value.forEach((obj) => {
              for (const key2 in obj) {
                if (obj.hasOwnProperty(key2)) {
                  const value2 = obj[key2];
                  for (let i in value2) {
                    notify(`${key2}: ${value2[i]}`, "error");
                  }
                }
              }
            });
          } else {
            notify(`${key}: ${value}`, "error");
          }
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: data,
    // validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  const OnChangeReport = (e) =>
    formik.setFieldValue(`student_list.${reportIndex}.report`, e);
  return (
    <>
      {/* <OverlayLoader show={isLoading} /> */}

      {/* ----------------------Start of Report Popup-------- */}

      <AddReport
        open={openReport}
        handleClose={handleCloseReport}
        value={reportContent}
        reportChange={OnChangeReport}
        setIsDirty={setIsDirty}
      />

      {/* ---------------End of Report Popup-------------- */}

      {/* ----------------Title section with Lesson and Calendar , Course Filters---------------- */}
      <Title>
        {
          <IconWrapper>
            <MenuBookIcon />
          </IconWrapper>
        }
        <Typography component={"p"} variant="body1">
          &nbsp;
          {attendanceDate
            ? `${currentLessonName} (${dateFormFormat(attendanceDate)})`
            : currentLessonName &&
              ![
                masterReference.SCHOOL_MANAGER,
                masterReference.FOREIGN_INSTITUTE_MANAGER,
                masterReference.HR,
              ].includes(user.code) &&
              `Ongoing Lesson (${dayjs().format(
                "DD.MM.YYYY"
              )} ${currentLessonName})`}
        </Typography>
        &nbsp;
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginLeft: "auto",
          }}
        >
          <CalendarIcon onClick={() => setOpen(true)} />
          <Typography component={"p"} variant="body1">
            &nbsp; Calendar
          </Typography>
          &nbsp;
        </div>
      </Title>

      {/* -----------Student Attendance Table Section------------------ */}
      <OverlayLoader show={isLoading}>
        <form onSubmit={formik.handleSubmit}>
          {formik.values.student_list.length > 0 &&
          formik.values.student_list[0].student_id > 0 ? (
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Wrapper
                  style={{
                    padding: "24px",
                    width: "75%",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table" align="center">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell width="55%">Student</StyledTableCell>
                        <StyledTableCell width="10%" align="center">
                          Present
                        </StyledTableCell>
                        <StyledTableCell width="10%" align="center">
                          Excused
                        </StyledTableCell>
                        <StyledTableCell width="25%" align="center">
                          Reports
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.student_list.map((student, index) => {
                        return (
                          <StyledTableRow key={index} aria-label="sticky table">
                            <StyledTableCell
                              aria-label="sticky table"
                              width="55%"
                            >
                              <Typography variant="body">
                                {toTitleCase(student.student_name)}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              width="10%"
                              aria-label="sticky table"
                            >
                              <Checkbox
                                name={`is_present`}
                                checked={student.is_present}
                                disabled={!isEditable}
                                onChange={(event) => {
                                  setIsDirty(true);
                                  if (event.target.checked) {
                                    formik.setFieldValue(
                                      `student_list.${index}.is_present`,
                                      true
                                    );
                                    formik.setFieldValue(
                                      `student_list.${index}.is_excused`,
                                      false
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      `student_list.${index}.is_present`,
                                      false
                                    );
                                  }
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              width="10%"
                              aria-label="sticky table"
                            >
                              <Checkbox
                                name={`is_excused`}
                                checked={student.is_excused}
                                disabled={!isEditable}
                                onChange={(event) => {
                                  setIsDirty(true);
                                  if (event.target.checked) {
                                    formik.setFieldValue(
                                      `student_list.${index}.is_excused`,
                                      true
                                    );
                                    formik.setFieldValue(
                                      `student_list.${index}.is_present`,
                                      false
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      `student_list.${index}.is_excused`,
                                      false
                                    );
                                  }
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              width="25%"
                              aria-label="sticky table"
                            >
                              {/* {student?.report ? (
                                <>
                                  <Typography component={"p"} variant="body1">
                                    {formik.values.student_list?.[index]?.report
                                      .length > 80
                                      ? formik.values.student_list?.[
                                          index
                                        ]?.report.substring(0, 80)
                                      : formik.values.student_list?.[index]
                                          ?.report}
                                    {isEditable ? (
                                      <Link
                                        onClick={() => {
                                          setReportContent(student?.report);
                                          setReportIndex(index);
                                          setOpenReport(true);
                                        }}
                                      >
                                        {`...Read more`}
                                      </Link>
                                    ) : (
                                      "..."
                                    )}
                                  </Typography>
                                </>
                              ) : ( */}
                              <IconButton
                                variant="outlined"
                                onClick={() => {
                                  setReportContent(student?.report);
                                  setReportIndex(index);
                                  setOpenReport(true);
                                }}
                                disabled={!isEditable}
                              >
                                <EditIcon
                                  color={!isEditable ? "disabled" : "primary"}
                                />
                              </IconButton>
                              {/* )} */}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Wrapper>
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10%",
                // fontSize: 30,
              }}
            >
              <p style={{ fontSize: 30, color: colors.darkBlue }}>
                No Ongoing Lesson{" "}
              </p>
            </div>
          )}
        </form>

        {/* {(courseId || user.code === masterReference.SCHOOL_MANAGER) && ( */}
        <Calendar
          formik={formik}
          data={data}
          setData={setData}
          open={open}
          setOpen={setOpen}
          schoolId={schoolId}
          courseId={courseId}
          setEntityId={setEntityId}
          setEntityType={setEntityType}
          entitiesNameList={entitiesNameList}
          entitiesList={entitiesList}
          setCourseId={setCourseId}
          setTimeTableId={setTimeTableId}
          setAttendanceDate={setAttendanceDate}
          setStartDateRange={setStartDateRange}
          setEndDateRange={setEndDateRange}
        />
        {/* )} */}
      </OverlayLoader>
    </>
  );
};

export default Course;
