import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/vars";

const StyledHeader = styled.h1`
  color: ${colors.dark};
`;

export const CompanyNotFoundHeader = styled.h1`
  color: ${colors.dark};
  justify-content: center;
  margin: auto;
  width: 50%;
`;

export const Header = styled.h1`
  color: ${colors.peach};
  justify-content: center;
  margin: auto;
  width: 100%;
`;
