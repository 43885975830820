// styling
import styled from "styled-components";
import { colors } from "../../../styles/vars";

// components
import CircularProgress from "@mui/material/CircularProgress";

const Container = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const OverlayLoader = ({ show, children }) => {
  return (
    <Container>
      <Overlay show={show}>
        <CircularProgress
          variant="indeterminate"
          size={100}
          thickness={2}
          value={100}
          aria-busy={true}
          sx={{
            color: colors.blue,
          }}
        />
      </Overlay>
        {children}
    </Container>
  );
};

export default OverlayLoader;
