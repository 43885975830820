import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "styled-components";
import { Wrap } from "../../style/style";
import dayjs from "dayjs";
import { TextField } from "@mui/material";

// Create a styled DatePicker component with custom styles
const StyledDatePicker = styled(DatePicker)`
  width: ${({ width }) => (width ? width : "90%")};
`;

// Define a functional component for DateField
export default function DateField({
  label = "",
  width = "",
  border = "",
  required = false,
  helperText, // Add helperText prop
  error, // Add error prop
  ...restProps
}) {
  // Function to format the date using dayjs library
  const formatDate = (date) => {
    return dayjs(date).format("DD.MM.YYYY");
  };

  // Generate the title for the dropdown
  const title = `${label}${required ? "*" : ""}`;
  const errorMsg = helperText !== "REQUIRED" ? helperText : "";

  return (
    <Wrap
      noValidate
      autoComplete="off"
      style={{ border: border ? border : "" }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          label={title}
          slotProps={{
            textField: { size: "small" },
          }}
          format="DD.MM.YYYY"
          width={width}
          renderInput={(params) => (
            <TextField
              helperText={errorMsg} // Use the helperText prop here
              error={error} // Use the error prop here
              {...params}
              value={
                params.inputProps.value
                  ? formatDate(params.inputProps.value)
                  : null
              }
            />
          )}
          {...restProps}
        />
      </LocalizationProvider>
    </Wrap>
  );
}
