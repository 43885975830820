import React, { useState, useEffect } from "react";
import {
    Checkbox,
    FormControlLabel,
} from "@mui/material";

const SameAsCurrentCheckbox = ({
    formik,
    handleFormChange, }) => {
    const values = formik.values;
    const setFieldValue = formik.setFieldValue;

    useEffect(() => {
        const isOsAddressChanged =
            values.os_address_line_1 !== values.address_line_1 ||
            values.os_address_line_2 !== values.address_line_2 ||
            values.os_city !== values.city ||
            values.os_state !== values.state ||
            values.os_postal_code !== values.postal_code ||
            values.os_country !== values.country;

        if (isOsAddressChanged) {
            setFieldValue('autofill_home_country_address', false);
        }
    }, [
        values.os_address_line_1,
        values.os_address_line_2,
        values.os_city,
        values.os_state,
        values.os_postal_code,
        values.os_country,
        values.address_line_1,
        values.address_line_2,
        values.city,
        values.state,
        values.postal_code,
        values.country,
        setFieldValue,
    ]);

    const handleCheckboxChange = (isChecked) => {
        if (isChecked) {
            setFieldValue('os_address_line_1', values.address_line_1);
            setFieldValue('os_address_line_2', values.address_line_2);
            setFieldValue('os_city', values.city);
            setFieldValue('os_state', values.state);
            setFieldValue('os_postal_code', values.postal_code);
            setFieldValue('os_country', values.country);
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    value={values.autofill_home_country_address}
                    checked={values.autofill_home_country_address ? true : false}
                    style={{ padding: '0px 9px' }}
                    onChange={(event) => {
                        console.log(event.target.checked);
                        setFieldValue(
                            "autofill_home_country_address",
                            event.target.checked ? true : false
                        );
                        handleCheckboxChange(event.target.checked);
                        handleFormChange(event);
                    }}
                />
            }
            label="Same As Current Address"
        />
    );
};

export default SameAsCurrentCheckbox;