import styled from "styled-components";

export const App = styled.div`
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  // height: 100vh;
`;

export const Heading = styled.h2`
  font-size: 32px;
  font-weight: 500;
`;

export const ImageContainer = styled.div`
  width: 80%;
  // height: 30%;
  margin-top: 30px;
  margin-left: 30px;
  // margin-right: auto;
  // display: flex;
  // float: auto;
  // align-items: center;
  // justify-content: center;
  border: 2px dashed rgba(0, 0, 0, 0.3);
`;

export const UploadContainer = styled.div`
  position: relative;
  // width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;

  p {
    font-size: 18px;
    margin: 4px;
    font-weight: 500;
  }
`;

export const BtnWrapper = styled.div`
  margin: 10px 5px;
`;

export const Item = styled.div`
  background-color: #fff;
  padding: 5;
  text-align: center;
  color: gray;
`;
