import React from "react";
import { Grid } from "@mui/material";

import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import {
  Content,
  Heading,
  Row,
  Wrapper,
} from "../../../../components/style/style";

const options = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Kids", value: "kids" },
];

const religion_options = [
  { label: "Hindu", value: "hindu" },
  { label: "Christian", value: "christian" },
  { label: "Muslim", value: "muslim" },
];

const Finance = ({ formik, handleFormChange }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  return (
    <Grid container>
      <Grid item sm={12}>
        {isEditable ? (
          <Wrapper>
            <Content component={"div"} style={{ padding: "12px" }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <SelectDropdown
                    id={"situation"}
                    name={"situation"}
                    options={options}
                    width="98%"
                    label="Family Status"
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      formik.setFieldValue("situation", selected?.value || "");
                    }}
                    value={options.find(
                      (option) => option.value === formik.values.situation
                    )}
                  />
                </Grid>
                <Grid item sm={6}>
                  <SelectDropdown
                    id={"religion"}
                    name={"religion"}
                    width="98%"
                    options={religion_options}
                    label="Religion"
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      formik.setFieldValue("religion", selected?.value || "");
                    }}
                    value={religion_options.find(
                      (option) => option.value === formik.values.religion
                    )}
                  />
                </Grid>
              </Grid>
            </Content>
          </Wrapper>
        ) : (
          <>
            {formik.values.situation || formik.values.religion ? (
              <Wrapper style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  {formik.values.situation && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Family Status"}
                        info={formik.values.situation}
                      />
                    </Grid>
                  )}
                  {formik.values.religion && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Religion"}
                        info={formik.values.religion}
                      />
                    </Grid>
                  )}
                </Grid>
              </Wrapper>
            ) : null}
          </>
        )}
      </Grid>
      <Grid
        item
        sm={
          !isEditable &&
          !formik.values.account_owner &&
          !formik.values.iban_number
            ? 12
            : 6
        }
      >
        {isEditable ? (
          <Wrapper>
            <Heading>Social Security Data</Heading>
            <Content component={"div"} style={{ padding: "12px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    id="social_security_number"
                    name="social_security_number"
                    label="Social Security Number"
                    width={"98%"}
                    value={formik.values.social_security_number}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    id="foreign_social_security_number"
                    name="foreign_social_security_number"
                    label="Foreign Social Security Number"
                    width={"98%"}
                    value={formik.values.foreign_social_security_number}
                    onChange={handleFormChange}
                  />
                </Grid>
              </Grid>
            </Content>
          </Wrapper>
        ) : (
          <>
            {formik.values.social_security_number ||
            formik.values.foreign_social_security_number ? (
              <Wrapper>
                <Heading>Social Security Data</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    {formik.values.social_security_number && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Social Security Number"}
                          info={formik.values.social_security_number}
                        />
                      </Grid>
                    )}

                    {formik.values.foreign_social_security_number && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Foreign Social Security Number"}
                          info={formik.values.foreign_social_security_number}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Content>
              </Wrapper>
            ) : null}
          </>
        )}
      </Grid>
      <Grid
        item
        sm={
          !isEditable &&
          !formik.values.social_security_number &&
          !formik.values.foreign_social_security_number
            ? 12
            : 6
        }
      >
        {isEditable ? (
          <Wrapper>
            <Heading>Bank Information</Heading>
            <Content component={"div"} style={{ padding: "12px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    id="account_owner"
                    name="account_owner"
                    label="Account Owner"
                    width={"98%"}
                    value={formik.values.account_owner}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    id="iban_number"
                    name="iban_number"
                    label="IBAN Number"
                    width={"98%"}
                    value={formik.values.iban_number}
                    onChange={handleFormChange}
                  />
                </Grid>
              </Grid>
            </Content>
          </Wrapper>
        ) : (
          <>
            {formik.values.account_owner || formik.values.iban_number ? (
              <Wrapper>
                <Heading>Bank Information</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    {formik.values.account_owner && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Account Owner"}
                          info={formik.values.account_owner}
                        />
                      </Grid>
                    )}

                    {formik.values.iban_number && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"IBAN Number"}
                          info={formik.values.iban_number}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Content>
              </Wrapper>
            ) : null}
          </>
        )}
      </Grid>
      <Grid
        item
        sm={!isEditable && !formik.values.health_insurance_provider ? 12 : 6}
      >
        {isEditable ? (
          <Wrapper>
            <Heading>Foreign Bank Information</Heading>
            <Content component={"div"} style={{ padding: "12px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    id="bank_account_number"
                    name="bank_account_number"
                    label="Bank Account Number"
                    width={"98%"}
                    value={formik.values.bank_account_number}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    id="bank_swift_code"
                    name="bank_swift_code"
                    label="Bank SWIFTCODE"
                    width={"98%"}
                    value={formik.values.bank_swift_code}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    id="local_bank_code"
                    name="local_bank_code"
                    label="Local Bank Code"
                    width={"98%"}
                    value={formik.values.local_bank_code}
                    onChange={handleFormChange}
                  />
                </Grid>
              </Grid>
            </Content>
          </Wrapper>
        ) : (
          <>
            {formik.values.bank_account_number ||
            formik.values.bank_swift_code ||
            formik.values.local_bank_code ? (
              <Wrapper>
                <Heading>Foreign Bank Information</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    {formik.values.bank_account_number && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Bank Account Number"}
                          info={formik.values.bank_account_number}
                        />
                      </Grid>
                    )}

                    {formik.values.bank_swift_code && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Bank SWIFTCODE"}
                          info={formik.values.bank_swift_code}
                        />
                      </Grid>
                    )}

                    {formik.values.local_bank_code && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Local Bank Code"}
                          info={formik.values.local_bank_code}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Content>
              </Wrapper>
            ) : null}
          </>
        )}
      </Grid>

      <Grid
        item
        sm={
          !isEditable &&
          !formik.values.bank_account_number &&
          !formik.values.bank_swift_code &&
          !formik.values.local_bank_code
            ? 12
            : 6
        }
      >
        {isEditable ? (
          <Wrapper>
            <Heading>Health Insurance</Heading>
            <Content component={"div"} style={{ padding: "12px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    id="health_insurance_provider"
                    name="health_insurance_provider"
                    label="Health Insurance Provider"
                    width={"98%"}
                    value={formik.values.health_insurance_provider}
                    onChange={handleFormChange}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Content>
          </Wrapper>
        ) : (
          <>
            {formik.values.health_insurance_provider ? (
              <Wrapper>
                <Heading>Health Insurance</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    {formik.values.health_insurance_provider && (
                      <Grid item xs={6}>
                        <InfoLabel
                          label={"Health Insurance Provider"}
                          info={formik.values.health_insurance_provider}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Content>
              </Wrapper>
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Finance;
