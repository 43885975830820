import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { flex } from "../../../styles/vars";

const Wrapper = styled.div`
  ${flex.col};
  width: 100%;
  margin-top: 20px;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxDropdown = ({
  id,
  label,
  value,
  required,
  options,
  error,
  ...restProps
}) => {
  const title = `${label} ${required ? "*" : ""}`;
  return (
    <Wrapper>
      <Autocomplete
        id={id}
        multiple
        options={options}
        value={value}
        size="small"
        error={error}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.lesson_name}
            </li>
          )}
        {...restProps}
        renderInput={(params) => (
          <TextField {...params} label={title} variant="outlined" error={error} />
        )}
      />
    </Wrapper>
  );
};

CheckboxDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  // error: PropTypes.bool,
  error: PropTypes.string,
};

export default CheckboxDropdown;
