import styled from 'styled-components';
import { Select } from '@mui/material';

export const FilterBarWrapper = styled.div`
  border-radius: 3px;
  align-items: center;
  margin-bottom: 10px;
`;

export const FilterWrapper = styled.div`
  margin-left: 0px;
`;

export const SelectDropdown = styled(Select)`
  .MuiPaper-root {
    margin-top: 8px;
    margin-left: -16px;
    min-width: 200px;
  }
`;
