import { protectedApi } from "../api";
import { env } from "../config/env";
import { formatApiResponse } from "../utils/helpers";

// Get a list of schools based on page and limit.
export const getSchoolsList = async (requestParams) => {
  const { page, limit, q, company_id } = requestParams;
  console.log("getSchoolsList.params", requestParams);
  try {
    const param = `?limit=${limit}&page=${page}&company_id=${company_id}&sort=${
      typeof q.sort === "undefined" ? "" : q.sort
    }&order=${typeof q.order === "undefined" ? 1 : q.order}`;
    const response = await protectedApi.get("/schools/" + param);
    console.log("getSchoolsList.response", response);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getSchoolsList.error", e);
    return formatApiResponse(e);
  }
};

// Add a new school detail.
export const addSchoolDetail = async (schoolData) => {
  console.log("addSchoolDetail.params", schoolData);
  try {
    const response = await protectedApi.post("/schools/", schoolData);
    console.log("addSchoolDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("addSchoolDetail.error", e);
    return formatApiResponse(e);
  }
};

// Get details of a specific school by its ID.
export const getSchoolsDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getSchoolsDetail.params", requestParams);
  try {
    const response = await protectedApi.get(`/schools/${id}`);
    console.log("getSchoolsDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getSchoolsDetail.error", e);
    return formatApiResponse(e);
  }
};

// Edit the details of an existing school.
export const editSchoolDetail = async (requestParams) => {
  const { id, data } = requestParams;

  console.log("editSchoolDetail.params", requestParams);
  try {
    const response = await protectedApi.put(`/schools/${id}`, data);
    console.log("editSchoolDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("editSchoolDetail.error", e);
    return formatApiResponse(e);
  }
};

export const getStudentsList = async (requestParams) => {
  const { page, limit, q, company_id, filterQuery } = requestParams;
  try {
    let param;
    if (!company_id || !company_id || company_id === 0)
      param = `?limit=${limit}&page=${page}&q=${q}${filterQuery}`;
    else
      param = `?limit=${limit}&page=${page}&company_id=${company_id}&q=${q}${filterQuery}`;
    console.log("getStudentsList.params", param);
    const response = await protectedApi.get("/schools/students/" + param);
    console.log("getStudentsList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getStudentsList.error", e);
    return formatApiResponse(e);
  }
};

export const getStudentsDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getStudentsDetail.params", requestParams);
  try {
    const response = await protectedApi.get(`/schools/students/${id}`);
    console.log("getStudentsDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getStudentsDetail.error", e);
    return formatApiResponse(e);
  }
};

// Add a new student detail.
export const addStudentDetail = async (requestParams) => {
  const { data } = requestParams;
  console.log("addStudentDetail.params", requestParams);
  try {
    const response = await protectedApi.post("/schools/students/", data);
    console.log("addStudentDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("addStudentDetail.error", e);
    return formatApiResponse(e);
  }
};

export const editStudentDetail = async (requestParams) => {
  const { id, data } = requestParams;

  console.log("editStudentDetail.params", requestParams);
  try {
    const response = await protectedApi.put(`/schools/students/${id}`, data);
    console.log("editStudentDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("editStudentDetail.error", e);
    return formatApiResponse(e);
  }
};

// Get a list of schools based on page and limit.
export const getSchoolDropdownList = async (company_id = 0) => {
  try {
    let response;
    console.log("getSchoolDropdownList.param", company_id);
    // if(!company_id ||company_id === 0)
    //   response = await protectedApi.get('/schools/list');
    // else
    response = await protectedApi.get(`/schools/list?company_id=${company_id}`);
    console.log("getSchoolDropdownList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getSchoolDropdownList.error", e);
    return formatApiResponse(e);
  }
};

// Get a list of foreign institute based on page and limit.
export const getForeignInstituteDropdownList = async (company_id = 0) => {
  try {
    let response;
    console.log("getForeignInstituteDropdownList.param", company_id);
    // if(!company_id ||company_id === 0)
    //   response = await protectedApi.get('/foreign institute/list');
    // else
    response = await protectedApi.get(
      `/foreign-institutes/list?company_id=${company_id}`
    );
    console.log("getForeignInstituteDropdownList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getForeignInstituteDropdownList.error", e);
    return formatApiResponse(e);
  }
};

// Function to get a list of examiners with pagination
export const getExaminersList = async (requestParams) => {
  const { page, limit, q, company_id, filterQuery } = requestParams;
  console.log("getExaminersList.params", requestParams);
  try {
    // Construct the query parameter string
    let param;
    if (!company_id || company_id === 0)
      param = `?limit=${limit}&page=${page}&q=${q}${filterQuery}`;
    else
      param = `?limit=${limit}&page=${page}&company_id=${company_id}&q=${q}${filterQuery}`;

    // Make a GET request to the examiners endpoint with pagination parameters
    const response = await protectedApi.get("/schools/examiners/" + param);
    console.log("getExaminersList.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getSchoolsList.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get details of a specific Examiners by ID
export const getExaminersDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getExaminersDetail.params", requestParams);
  try {
    // Make a GET request to retrieve Examiners details by ID
    const response = await protectedApi.get(`/schools/examiners/${id}`);
    console.log("getExaminersDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getExaminersDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to add a new Examiner detail
export const addExaminerDetail = async (requestParams) => {
  const { data } = requestParams;
  console.log("addExaminerDetail.params", requestParams);
  try {
    // Make a POST request to create a new Examiner with the provided data
    const response = await protectedApi.post("/schools/examiners/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("addExaminerDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("addExaminerDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to edit/update Examiner details by ID
export const editExaminerDetail = async (requestParams) => {
  const { id, data } = requestParams;

  console.log("editExaminerDetail.params", requestParams);
  try {
    // Make a PUT request to update Examiner details by ID with the provided data
    const response = await protectedApi.put(`/schools/examiners/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("editExaminerDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("editExaminerDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Get a list of courses based on page and limit.
export const getCoursesList = async (requestParams) => {
  const { page, limit, school_id, q, filterQuery } = requestParams;
  try {
    let param = `?limit=${limit}&page=${page}&q=${q}${filterQuery}`;
    console.log("getCoursesList.params", param);
    const response = await protectedApi.get("/courses/" + param);
    console.log("getCoursesList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getCoursesList.error", e);
    return formatApiResponse(e);
  }
};

// Get a list of courses for dropdown
export const getDropdownCoursesList = async (
  school_id,
  entity_type,
  entity_id,
  teacher_id = 0
) => {
  try {
    let param = `?school_id=${school_id}`;
    if (teacher_id) {
      param += `&teacher_id=${teacher_id}`;
    }
    if (entity_type && entity_id) {
      param += `&entity_type=${entity_type}&entity_id=${entity_id}`;
    }
    console.log("getDropdownCoursesList.params", param);
    const response = await protectedApi.get("/courses/list" + param);
    console.log("getDropdownCoursesList.response", response);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getDropdownCoursesList.error", e);
    return formatApiResponse(e);
  }
};

// Add a new course detail.
export const addCourseDetail = async (requestParams) => {
  const { id, data } = requestParams;
  console.log("addCourseDetail.params", requestParams);
  try {
    const response = await protectedApi.post("/courses/", data);
    console.log("addCourseDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("addCourseDetail.error", e);
    return formatApiResponse(e);
  }
};

// Function to get details of a specific Courses by ID
export const getCoursesDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getCoursesDetail.params", requestParams);
  try {
    // Make a GET request to retrieve Courses details by ID
    const response = await protectedApi.get(`/courses/${id}`);
    console.log("getCoursesDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getCoursesDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to edit/update Course details by ID
export const editCourseDetail = async (requestParams) => {
  const { id, data } = requestParams;

  console.log("editCourseDetail.params", requestParams);
  try {
    // Make a PUT request to update Course details by ID with the provided data
    const response = await protectedApi.put(`/courses/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("editCourseDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("editCourseDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Get a list of lesson based on page and limit.
export const getLessonList = async (requestParams) => {
  const { page, limit, q } = requestParams;
  console.log("getLessonList.params", requestParams);
  try {
    let param = `?limit=${limit}&page=${page}&q=${q}`;
    const response = await protectedApi.get("/settings/time-table/" + param);
    console.log("getLessonList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getLessonList.error", e);
    return formatApiResponse(e);
  }
};

// Function to edit/update lesson details by ID
export const editLessonDetail = async (requestParams) => {
  const { data } = requestParams;

  console.log("editLessonDetail.params", requestParams);
  try {
    // Make a PUT request to update Course details by ID with the provided data
    const response = await protectedApi.put(`/settings/time-table/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("editLessonDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("editLessonDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Get a list of examiner type based on page and limit.
export const getExaminerTypeList = async () => {
  console.log("getExaminerTypeList.params");
  try {
    const response = await protectedApi.get("/courses/examiner-type");
    console.log("getExaminerTypeList.response", response);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getExaminerTypeList.error", e);
    return formatApiResponse(e);
  }
};

export const getSchoolExaminerTypeList = async (school_id) => {
  console.log("getExaminerTypeList.params");
  try {
    let param = `?school_id=${school_id}`;
    const response = await protectedApi.get("/courses/examiner-type" + param);
    console.log("getSchoolExaminerTypeList.response", response);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getSchoolExaminerTypeList.error", e);
    return formatApiResponse(e);
  }
};

// Get a list of examiner name based on page and limit.
export const getExaminerNameList = async () => {
  console.log("getExaminerNameList.params");
  try {
    const response = await protectedApi.get("/courses/examiner-name");
    console.log("getExaminerNameList.response", response);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getExaminerNameList.error", e);
    return formatApiResponse(e);
  }
};

// Add a new institute detail.
export const addInstituteDetail = async (requestParams) => {
  const { id, data } = requestParams;
  console.log("addInstituteDetail.params", requestParams);
  try {
    const response = await protectedApi.post("/management/", data);
    console.log("addInstituteDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("addInstituteDetail.error", e);
    return formatApiResponse(e);
  }
};

// Function to get details of a specific Institutes by ID
export const getInstitutesDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getInstitutesDetail.params", requestParams);
  try {
    // Make a GET request to retrieve Institutes details by ID
    const response = await protectedApi.get(`/management/${id}`);
    console.log("getInstitutesDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getInstitutesDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get details of a specific applicant flow by course id
export const getApplicantFlowDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getApplicantFlowDetail.params", requestParams);
  try {
    // Make a GET request to retrieve applicant flow details by course id
    const response = await protectedApi.get(`/settings/flow/${id}`);
    console.log("getApplicantFlowDetail.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getApplicantFlowDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get details of a specific applicant flow by course id
export const saveApplicantFlowDetail = async (requestParams) => {
  const { id, data } = requestParams;

  console.log("saveApplicantFlowDetail.params", requestParams);
  try {
    // Make a GET request to retrieve applicant flow details by course id
    const response = await protectedApi.post(`/settings/flow/${id}`, data);
    console.log("saveApplicantFlowDetail.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("saveApplicantFlowDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get goals
export const getGoals = async (requestParams) => {
  const { entity_type, entity_id } = requestParams;
  console.log("getGoals.params", requestParams);
  try {
    // Make a GET request to retrieve goals by school id
    const response = await protectedApi.get(
      `/schools/goals/list?entity_type=${entity_type}&entity_id=${entity_id}`
    );
    console.log("getGoals.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getGoals.error", e);
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get goals list
export const getGoalsList = async (requestParams) => {
  const { page, limit, q, filterQuery } = requestParams;
  console.log("getGoalsList.params", requestParams);
  try {
    let param = `?limit=${limit}&page=${page}&q=${q}${filterQuery}`;

    // Make a GET request to retrieve goals list
    const response = await protectedApi.get(`/schools/goals` + param);
    console.log("getGoalsList.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getGoalsList.error", e);
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get goals list
export const getChoiceExists = async (requestParams) => {
  const { id, stage, value } = requestParams;
  console.log("getChoiceExists.params", requestParams);
  try {
    // Make a GET request to check if data exiss for the selected choice
    const response = await protectedApi.get(
      `/settings/flow/${id}/validate/goal-flow-option?stage_guid=${stage}&value=${value}`
    );
    console.log("getChoiceExists.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getChoiceExists.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Get student attendance
export const getStudentAttendanceDetails = async (requestParams) => {
  const {
    lesson_time_table_id,
    start_date,
    end_date,
    attendance_date,
    entity_id,
    entity_type,
  } = requestParams;
  console.log("getStudentAttendance.params", requestParams);
  let url = "";
  try {
    if (lesson_time_table_id && start_date && end_date && attendance_date) {
      url = `lesson_time_table_id=${lesson_time_table_id}&attendance_date=${attendance_date}&start_date=${start_date}&end_date=${end_date}`;
      if (entity_id && entity_type) {
        url = url + `&entity_id=${entity_id}&entity_type=${entity_type}`;
      }
    } else {
      url = `ongoing=1`;
    }
    const response = await protectedApi.get(
      `/schools/students/attendance?${url}`
    );
    console.log("getStudentAttendance.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getStudentAttendance.error", e);
    return formatApiResponse(e);
  }
};

// Save student attendance
export const updateStudentAttendanceDetails = async (requestParams) => {
  const { data } = requestParams;
  console.log("updateStudentAttendanceDetails.params", requestParams);
  try {
    const response = await protectedApi.post(
      `/schools/students/attendance`,
      data
    );
    console.log("updateStudentAttendance.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("updateStudentAttendance.error", e);
    return formatApiResponse(e);
  }
};

// Get a list of courses based on page and limit.
export const getTeacherCourseList = async (requestParams) => {
  const { teacher_id, entity_type, entity_id } = requestParams;
  console.log("getCoursesList.params", requestParams);
  try {
    let param = `?teacher_id=${teacher_id}`;
    if (entity_type && entity_id) {
      param = `?teacher_id=${teacher_id}&entity_type=${entity_type}&entity_id=${entity_id}`;
    }
    const response = await protectedApi.get("/courses/teachers" + param);
    console.log("getTeacherCourseList .response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getTeacherCourseList .error", e);
    return formatApiResponse(e);
  }
};

export const transferStudent = async (data) => {
  console.log("transferStudent.params", data);
  try {
    // Make a POST to transfer student
    const response = await protectedApi.post(
      `/schools/students/transfer`,
      data
    );
    console.log("transferStudent.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("transferStudent.error", e);
    return formatApiResponse(e);
  }
};

// --------------------Get Flow Name Listfor Selected Goal--------------
export const getGoalFlow = async (requestParams) => {
  const { goal_id, userType } = requestParams;
  console.log("getGoalFlow.params", requestParams);
  try {
    // Make a GET request to retrieve goals by school id
    const response = await protectedApi.get(
      `/schools/goals/${goal_id}/flow?user_type=${userType}`
    );
    console.log("getGoalFlow.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getGoalFlow.error", e);
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Api for get document color status list
export const getDocumentColorStatusList = async (requestParams = "") => {
  try {
    let param = "";
    const response = await protectedApi.get(
      "/settings/document/status-color" + param
    );
    console.log("getDocumentColorStatusList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getDocumentColorStatusList.error", e);
    return formatApiResponse(e);
  }
};

// Api for save document color setting
export const editDocumentColorStatusList = async (requestParams) => {
  const { data } = requestParams;

  console.log("editDocumentColorStatusList.params", requestParams);
  try {
    // Make a PUT request to update Course details by ID with the provided data
    const response = await protectedApi.post(
      `/settings/document/status-color`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("editDocumentColorStatusList.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("editDocumentColorStatusList.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Get a list of goals based on page and limit.
export const getGoalList = async (requestParams) => {
  const { page, limit, q, entity_id, entity_type } = requestParams;
  console.log("getGoalList.params", requestParams);
  try {
    let param = `?limit=${limit}&page=${page}&q=${q}&entity_id=${entity_id}&entity_type=${entity_type}`;
    const response = await protectedApi.get("/settings/goals/add/" + param);
    console.log("getGoalList.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getGoalList.error", e);
    return formatApiResponse(e);
  }
};

// Function to edit/update gpals details
export const editGoalDetail = async (requestParams) => {
  const { data } = requestParams;

  console.log("editGoalDetail.params", requestParams);
  try {
    // Make a PUT request to update Course details by ID with the provided data
    const response = await protectedApi.post(`/settings/goals/add/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("editGoalDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("editGoalDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};
