import { protectedApi } from "../api";
import { formatApiResponse } from "../utils/helpers";

export const getCompaniesList = async (page, limit, sorting) => {
  console.log("getCompaniesList.params");
  try {
    // Construct the query string for the API request.
    let param = `?limit=${limit}&page=${page}&sort=${sorting.sort}&order=${sorting.order}`;
    const response = await protectedApi.get("/companies" + param);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getCompaniesList.error", e);
    return formatApiResponse(e);
  }
};

export const addCompanyApi = async (companyData) => {
  const endpoint = `/companies/`;
  console.log(companyData);
  try {
    const response = await protectedApi.post(endpoint, companyData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export async function getIndividualCompanyApi(companyId) {
  console.log("getIndividualCompanyApi.params", companyId);
  try {
    const res = await protectedApi.get(`/companies/${companyId}`);
    return formatApiResponse(res);
  } catch (e) {
    console.error("getIndividualCompanyApi.catch", e);
    return formatApiResponse(e);
  }
}

export const editCompanyApi = async (id, companyData) => {
  console.log(companyData);
  const endpoint = `/companies/${id}`;
  try {
    const response = await protectedApi.put(endpoint, companyData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};
