import clsx from "clsx";
import * as yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputError } from "../../components/UI/InputError";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Btn1 from "../../components/UI/Btn1";
import useNotistack from "../../hooks/useNotistack";
import { breakpoints, flex } from "../../styles/vars";
import { AuthContext } from "../../contexts/authContext";
import LabeledFormInput from "../../components/UI/LabeledFormInput";
import { resetPasswordAPI, editChangePasswordApi } from "../../services/auth";
import { passwordValidation } from "../../utils/common";

const StyledForm = styled.form`
  width: 100%;
  padding: 20px;
  display: flex;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 50%;
  }
`;

const InputWrapper = styled.div`
  margin: 10px 0px;
`;

const BtnWrapper = styled.div`
  margin: 10px 0px;
`;

const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("REQUIRED")
    .test("password-check", function (value) {
      return (
        passwordValidation(value).length === 0 ||
        this.createError({ message: passwordValidation(value) })
      );
    }),
  confirm_password: yup
    .string()
    .required("REQUIRED")
    .oneOf([yup.ref("password"), null], "Password did not match"),
});

export default function ResetPasswordForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { uid, token } = useParams();
  const { notify } = useNotistack();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const handleClickPassword = () => setShowPassword((show) => !show);
  const handleClickConfPassword = () => setShowConfPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, action) => {
      setIsLoading(false);
      try {
        const keys = ["new_password"];
        const cleanedValues = keys.reduce((acc, key) => {
          acc[key] = values["password"];
          return acc;
        }, {});
        let res;
        if (props.add_new_password) {
          res = await editChangePasswordApi(cleanedValues);
          if (res.isOk) {
            setIsAuthenticated(true);
          }
        } else {
          res = await resetPasswordAPI(values.password, uid, token);
        }
        if (res.isOk) {
          let company_url = res.data.company_url;
          notify("Password changed successfully", "success");
          action.resetForm();
          if (company_url) {
            navigate(`/${company_url}/login`);
          } else {
            navigate(`/login`);
          }

          // if (props.add_new_password === 'true') {
          //   navigate('/clinics');
          // } else {
          //   navigate('/login');
          // }
        } else {
          for (const [key, value] of Object.entries(res.error)) {
            notify(`${key}: ${value}`, "error");
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <LabeledFormInput
          title="New Password"
          placeholder="New Password"
          type={showPassword ? "text" : "password"}
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={formik.errors.password}
          helperText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </InputWrapper>

      <InputWrapper>
        <LabeledFormInput
          title="Confirm New Password"
          placeholder="Confirm New Password"
          type={showConfPassword ? "text" : "password"}
          name="confirm_password"
          onChange={formik.handleChange}
          value={formik.values.confirm_password}
          error={formik.errors.confirm_password}
          helperText={
            formik.touched.confirm_password && formik.errors.confirm_password
              ? formik.errors.confirm_password
              : null
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickConfPassword}
                  edge="end"
                >
                  {showConfPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </InputWrapper>
      <BtnWrapper>
        <Btn1
          className={clsx(isLoading && "disabled")}
          disabled={isLoading}
          type="submit"
          text={!isLoading ? "Submit" : "Please wait..."}
        />
      </BtnWrapper>
    </StyledForm>
  );
}
