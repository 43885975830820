import { create } from "zustand";
import localforage from "localforage";

// Custom hook for changing layout
export const useLayoutChange = create((set) => ({
  isAdminConsole: true,
  // Function to set boolean value of if layout is admin layout
  setIsAdminConsole: (boolVal) => {
    set({ isAdminConsole: boolVal });
    localforage.setItem("isAdminConsole", boolVal);
  },
}));

localforage.getItem("isAdminConsole").then((storedValue) => {
  if (storedValue !== null) {
    useLayoutChange.setState({ isAdminConsole: storedValue });
  }
});
