import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import Print from "./print";
import General from "./General";
import Finance from "./Finance";
import Transfer from "./transfer";
import Generate from "./generate";
import StudentDocuments from "./Documents";
import ApplicantDocuments from "../Applicant/Documents";
import StudentCockpit from "./Cockpit";
import Details from "./ContactInformation";
import useAuth from "../../../hooks/useAuth";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import ErrorTabItem from "../../../components/UI/ErrorTabItem";
import { masterReference } from "../../../constants/masterData";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import {
  getDropdownCoursesList,
  getGoals,
  getSchoolDropdownList,
  getForeignInstituteDropdownList,
} from "../../../services/schools";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { convertJSONToFormData, toTitleCase } from "../../../utils/utility";
import { validateAddress, isAddressSame } from "../../../utils/common";

const Student = () => {
  const { user } = useAuth();
  const [stageNameList, setStageNameList] = useState([]);
  const [studentStageNameList, setStudentStageNameList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();

  // Get various form submission-related data from a custom hook
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    printRecord,
    setPrintRecord,
    setDisableTransfer,
    generateRecord,
    setGenerateRecord,
    setDisableGenerate,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  // Define initial form values
  const initialValues = {
    first_name: "",
    last_name: "",
    gender: "male",
    place_of_birth: "",
    dob: null,
    date_of_change: null,
    citizenship: "",
    former_citizenship: "",
    // status: 1,
    email_id: "",
    email: "",
    mobile_id: "",
    mobile: "",
    school_id: "",
    entity_type: "",
    entity_id: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    employer_address: "",
    current_employer: "",
    os_address_line_1: "",
    os_address_line_2: "",
    os_postal_code: "",
    os_city: "",
    os_state: "",
    os_country: "",
    em_address_line_1: "",
    em_address_line_2: "",
    em_postal_code: "",
    em_city: "",
    em_state: "",
    em_country: "",
    situation: "",
    religion: "",
    social_security_number: "",
    health_insurance_provider: "",
    bank_account_number: "",
    account_owner: "",
    bank_swift_code: "",
    local_bank_code: "",
    foreign_social_security_number: "",
    iban_number: "",
    company_id: 0,
    status_student: "new_applicant",
    goal_id: null,
    goal_flow_guid: null,
    student_goal_flow_guid: null,
    course_id: "",
    course_name: "",
    course_start_time: "",
    course_end_time: "",
    is_access_to_hr: 0,
    autofill_home_country_address: false,
    autofill_home_employer_address: false,
  };

  const validateSchema =
    user.code.toLowerCase() === masterReference.HR
      ? Yup.object().shape({
          first_name: Yup.string().required("REQUIRED"),
          last_name: Yup.string().required("REQUIRED"),
          email: Yup.string().email("Enter a valid Email"),
          dob: Yup.date()
            .nullable()
            .max(new Date(), "Date of Birth cannot be in the future"),
          place_of_birth: Yup.string(),
          address_line_1: Yup.string(),
          address_line_2: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          postal_code: Yup.string(),

          // school_id: Yup.string().required("REQUIRED"),
          goal_id: Yup.string().required("REQUIRED"),
          student_goal_flow_guid: Yup.string().required("REQUIRED"),
          course_id: Yup.string(),
          entity_type: Yup.string().required("REQUIRED"),
          entity_id: Yup.string().required("REQUIRED"),
          // status: Yup.string().required("REQUIRED"),
        })
      : Yup.object()
          .shape({
            first_name: Yup.string().required("REQUIRED"),
            last_name: Yup.string().required("REQUIRED"),
            email: Yup.string().email("Enter a valid Email"),
            dob: Yup.date()
              .nullable("REQUIRED")
              .max(new Date(), "Date of Birth cannot be in the future"),
            place_of_birth: Yup.string(),
            address_line_1: Yup.string(),
            address_line_2: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            postal_code: Yup.string(),
            country: Yup.string(),
            os_address_line_1: Yup.string(),
            os_address_line_2: Yup.string(),
            os_city: Yup.string(),
            os_state: Yup.string(),
            os_postal_code: Yup.string(),
            os_country: Yup.string(),
            em_address_line_1: Yup.string(),
            em_address_line_2: Yup.string(),
            em_city: Yup.string(),
            em_state: Yup.string(),
            em_postal_code: Yup.string(),
            em_country: Yup.string(),

            goal_id: Yup.string().required("REQUIRED"),
            student_goal_flow_guid: Yup.string().required("REQUIRED"),
            course_id: Yup.string().required("REQUIRED"),
            // status: Yup.string().required("REQUIRED"),
          })
          .test(
            "conditional-validation",
            "Invalid address fields",
            validateAddress
          );

  const [data, setData] = useState(initialValues);
  const [printData, setPrintData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [schoolList, setSchoolList] = useState([]);
  const [goalsList, setGoalsList] = useState([]);
  const [continueFlag, setContinueFlag] = useState({});
  const [continueStudentFlag, setContinueStudentFlag] = useState({});
  const [continueDisableFlag, setContinueDisableFlag] = useState(false);
  const [firstContinueRender, setFirstContinueRender] = useState(false);
  const [otherStages, setOtherStages] = useState([]);
  const [otherStudentStages, setOtherStudentStages] = useState([]);
  // to show notify message
  const { notify } = useNotistack();

  // setting default values
  useEffect(() => {
    setData(initialValues);
  }, []);

  // setting selected student data
  useEffect(() => {
    if (selectedRecord && selectedRecord.first_name) {
      setFirstContinueRender(false);
      // -------------get student documents from DB---------------
      let requestParams = {
        id: selectedRecord.id,
      };
      let selectedData = {
        applicant_id: selectedRecord.applicant_id,
        first_name: selectedRecord.first_name,
        last_name: selectedRecord.last_name,
        gender: selectedRecord.gender,
        place_of_birth: selectedRecord.place_of_birth,
        dob: selectedRecord.dob ? dayjs(selectedRecord.dob) : null,
        date_of_change: selectedRecord.date_of_change
          ? dayjs(selectedRecord.date_of_change)
          : null,
        citizenship: selectedRecord.citizenship,
        former_citizenship: selectedRecord.former_citizenship,
        // status: selectedRecord.student_status_id,
        id_number: selectedRecord.id_number,
        student_id: selectedRecord.student_id,
        company_id: selectedRecord?.company_id,
        course_id:
          selectedRecord?.course_id > 0 ? selectedRecord?.course_id : "",
        course_name: selectedRecord?.course_name,
        course_start_time: dayjs(selectedRecord?.course_start_time),
        course_end_time: dayjs(selectedRecord?.course_end_time),
        school_id: selectedRecord.school_id,
        is_access_to_hr: selectedRecord.is_access_to_hr,
        email_id: 0,
        email: "",
        mobile_id: 0,
        mobile: "",
        entity_id: selectedRecord.entity_id,
        entity_type: selectedRecord.entity_type,
        address_id: 0,
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        os_address_id: 0,
        os_address_line_1: "",
        os_address_line_2: "",
        os_postal_code: "",
        os_city: "",
        os_state: "",
        os_country: "",
        em_address_id: 0,
        em_address_line_1: "",
        em_address_line_2: "",
        em_postal_code: "",
        em_city: "",
        em_state: "",
        em_country: "",
        status_student: "",
        status: selectedRecord.status,
        goal_id: selectedRecord.goal_id,
        goal_flow_guid: selectedRecord.goal_flow_guid,
        student_goal_flow_guid: selectedRecord.student_goal_flow_guid,
        current_employer: selectedRecord.current_employer,
        situation: selectedRecord.situation,
        religion: selectedRecord.religion,
        social_security_number: selectedRecord.social_security_number,
        health_insurance_provider: selectedRecord.health_insurance_provider,
        bank_account_number: selectedRecord.bank_account_number,
        account_owner: selectedRecord.account_owner,
        bank_swift_code: selectedRecord.bank_swift_code,
        local_bank_code: selectedRecord.local_bank_code,
        foreign_social_security_number:
          selectedRecord.foreign_social_security_number,
        iban_number: selectedRecord.iban_number,
        autofill_home_country_address: false,
        autofill_home_employer_address: false,
      };

      //------------------------------------------Student Cockpit Documents-----------------------------------
      let student_stage_name_list = [];
      let student_stage_label_list = [];

      selectedData["student_flow_stage_list"] =
        selectedRecord?.student_flow_stage_list;
      let student_stageName = "";
      let student_count = 0;
      // --------------------set dynamic student document details--------------------
      for (var ele in selectedData["student_flow_stage_list"]) {
        // ----------------------------set stageFlagIndex sub stage wise-----------------

        if (
          student_stageName ===
          selectedData["student_flow_stage_list"][ele]["stage_name"]
        ) {
          student_count = student_count + 1;
        } else {
          student_stageName =
            selectedData["student_flow_stage_list"][ele]["stage_name"];
          student_count = 0;
        }
        selectedData["student_flow_stage_list"][ele]["stage_flag_index"] =
          student_count;

        var field_json =
          selectedData["student_flow_stage_list"][ele]["field_json"];

        let student_task_count = 0;

        for (var fields of field_json) {
          var field_name = fields["field_guid"].toLowerCase();

          if (fields["type"] === "task") {
            student_task_count = student_task_count + 1;
          }
          selectedData["student_flow_stage_list"][ele]["task_count"] =
            student_task_count;

          fields["submission_date_value"] = fields["submission_date_value"]
            ? dayjs(fields["submission_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["start_date_value"] = fields["start_date_value"]
            ? dayjs(fields["start_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["expiry_date_value"] = fields["expiry_date_value"]
            ? dayjs(fields["expiry_date_value"]).format("YYYY-MM-DD")
            : "";

          fields["completion_date_value"] = fields["completion_date_value"]
            ? dayjs(fields["completion_date_value"]).format("YYYY-MM-DD")
            : "";

          // -----------append all stages in list-------------------
          var stage_name =
            selectedData["student_flow_stage_list"][ele]["stage_guid"];
          student_stage_name_list.push(`${stage_name}_${field_name}`);
        }
        // -----------append all stages in list-------------------
        var stage_name =
          selectedData["student_flow_stage_list"][ele]["stage_guid"];
        // .replace(" ", "_");
        if (!student_stage_name_list.includes(stage_name)) {
          student_stage_name_list.push(`${stage_name}`);
        }
        var stage_label =
          selectedData["student_flow_stage_list"][ele]["stage_name"];
        if (!student_stage_label_list.includes(stage_label)) {
          student_stage_label_list.push(
            selectedData["student_flow_stage_list"][ele]["stage_name"]
          );
        }
      }

      selectedData["student_stageLabelList"] = student_stage_label_list;
      setStudentStageNameList(student_stage_name_list);

      // set stage name flag
      let student_stagesFlagList = {};
      student_stage_name_list.forEach((ele, index) => {
        selectedData[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`] =
          selectedRecord?.[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`];

        student_stagesFlagList[
          `${ele.split("_")[0].replaceAll(" ", "_")}_flag`
        ] = selectedData[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`];
      });

      selectedData["student_stageFlagList"] = student_stagesFlagList;

      // ----------------------------------------Applicant Cockpit Documents----------------------------------

      let stage_name_list = [];
      let stage_label_list = [];
      selectedData["flow_stage_list"] = selectedRecord?.flow_stage_list;

      let stageName = "";
      let count = 0;
      // --------------------set dynamic student document details--------------------
      for (var ele in selectedData["flow_stage_list"]) {
        // ----------------------------set stageFlagIndex sub stage wise-----------------

        if (stageName === selectedData["flow_stage_list"][ele]["stage_name"]) {
          count = count + 1;
        } else {
          stageName = selectedData["flow_stage_list"][ele]["stage_name"];
          count = 0;
        }
        selectedData["flow_stage_list"][ele]["stage_flag_index"] = count;

        var field_json = selectedData["flow_stage_list"][ele]["field_json"];

        let task_count = 0;

        for (var fields of field_json) {
          var field_name = fields["field_guid"].toLowerCase();

          // -------------------check if type is task---------------------

          if (fields["type"] === "task") {
            task_count = task_count + 1;
          }
          selectedData["flow_stage_list"][ele]["task_count"] = task_count;

          fields["submission_date_value"] = fields["submission_date_value"]
            ? dayjs(fields["submission_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["start_date_value"] = fields["start_date_value"]
            ? dayjs(fields["start_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["expiry_date_value"] = fields["expiry_date_value"]
            ? dayjs(fields["expiry_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["completion_date_value"] = fields["completion_date_value"]
            ? dayjs(fields["completion_date_value"]).format("YYYY-MM-DD")
            : "";

          // -----------append all stages in list-------------------
          var stage_name = selectedData["flow_stage_list"][ele]["stage_guid"];
          stage_name_list.push(`${stage_name}_${field_name}`);
        }
        // -----------append all stages in list-------------------
        var stage_name = selectedData["flow_stage_list"][ele]["stage_guid"];
        // .replace(" ", "_");
        if (!stage_name_list.includes(stage_name)) {
          stage_name_list.push(`${stage_name}`);
        }
        var stage_label = selectedData["flow_stage_list"][ele]["stage_name"];
        if (!stage_label_list.includes(stage_label)) {
          stage_label_list.push(
            selectedData["flow_stage_list"][ele]["stage_name"]
          );
        }
      }

      selectedData["stageLabelList"] = stage_label_list;
      setStageNameList(stage_name_list);

      // set stage name flag
      let stagesFlagList = {};
      stage_name_list.forEach((ele, index) => {
        selectedData[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`] =
          selectedRecord?.[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`];

        stagesFlagList[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`] =
          selectedData[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`];
      });

      selectedData["stageFlagList"] = stagesFlagList;

      if (selectedRecord?.contact) {
        selectedRecord.contact.forEach((contact) => {
          if (contact.contact_type === "email") {
            selectedData.email_id = contact?.contact_id;
            selectedData.email = contact?.value;
          } else if (contact.contact_type === "phone") {
            selectedData.mobile_id = contact?.contact_id;
            selectedData.mobile = contact?.value;
          }
        });
      }
      let currentAddress = {};
      let homeAddress = {};
      let employerAddress = {};
      selectedRecord.address.forEach((address) => {
        if (address.address_type === "current") {
          currentAddress = address;
          selectedData.address_id = address.address_id;
          selectedData.address_line_1 = address.address_line_1;
          selectedData.address_line_2 = address.address_line_2;
          selectedData.postal_code = address.postal_code;
          selectedData.state = address.state;
          selectedData.city = address.city;
          selectedData.country = address.country;
        } else if (address.address_type === "overseas") {
          homeAddress = address;

          selectedData.os_address_id = address.address_id;
          selectedData.os_address_line_1 = address.address_line_1;
          selectedData.os_address_line_2 = address.address_line_2;
          selectedData.os_postal_code = address.postal_code;
          selectedData.os_state = address.state;
          selectedData.os_city = address.city;
          selectedData.os_country = address.country;
        } else {
          employerAddress = address;

          selectedData.em_address_id = address.address_id;
          selectedData.em_address_line_1 = address.address_line_1;
          selectedData.em_address_line_2 = address.address_line_2;
          selectedData.em_postal_code = address.postal_code;
          selectedData.em_state = address.state;
          selectedData.em_city = address.city;
          selectedData.em_country = address.country;
        }
      });
      if (currentAddress && homeAddress) {
        let isHomeAddressSame = isAddressSame(currentAddress, homeAddress);
        if (isHomeAddressSame) {
          selectedData.autofill_home_country_address = true;
        }
      }
      if (currentAddress && employerAddress) {
        let isEmployerAddressSame = isAddressSame(
          currentAddress,
          employerAddress
        );
        if (isEmployerAddressSame) {
          selectedData.autofill_home_employer_address = true;
        }
      }

      setDisableTransfer(selectedRecord.is_transferred);
      setDisableGenerate(false);
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER ||
        user.code === masterReference.HR
      ) {
        setEditable(true);
      }

      setPrintData(selectedData);
      setContinueFlag({});
      setContinueStudentFlag({});
    } else {
      // Reset the form when no selectedRecord is available
      formik.resetForm();
    }
  }, [selectedRecord]);

  useEffect(() => {
    // Update formik values when data changes
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    console.log("value----------------------------------------------", values);

    setIsLoading(true);

    let submittingData = {
      applicant_id: values.applicant_id,
      first_name: values.first_name,
      last_name: values.last_name,
      gender: values.gender,
      place_of_birth: values.place_of_birth,
      citizenship: values.citizenship ? values.citizenship : "",
      former_citizenship: values.former_citizenship
        ? values.former_citizenship
        : "",
      situation: values.situation ? values.situation : "",
      religion: values.religion ? values.religion : "",
      employer_address: values.employer_address,
      current_employer: values.current_employer ? values.current_employer : "",
      // student_status_id: values.status,
      hr_number: values.hr_number,
      dob: values.dob ? values.dob.format("YYYY-MM-DD") : null,
      date_of_change: values.date_of_change
        ? values.date_of_change.format("YYYY-MM-DD")
        : null,
      entity_type: values.entity_type,
      entity_id: values.entity_id,
      course_id: values.course_id ? values.course_id : "",
      school_id: values.school_id,
      status: values.status,
      goal_id: values.goal_id,
      goal_flow_guid: values.goal_flow_guid,
      student_goal_flow_guid: values.student_goal_flow_guid,
      status_student: values.status_student,
      social_security_number: values.social_security_number
        ? values.social_security_number
        : "",
      health_insurance_provider: values.health_insurance_provider
        ? values.health_insurance_provider
        : "",
      bank_account_number: values.bank_account_number,
      account_owner: values.account_owner,
      bank_swift_code: values.bank_swift_code,
      local_bank_code: values.local_bank_code,
      foreign_social_security_number: values.foreign_social_security_number,
      iban_number: values.iban_number,
      is_access_to_hr: values.is_access_to_hr,
      address: [],
      contact: [
        {
          contact_id: values.email_id || 0,
          contact_type: "email",
          value: values.email,
        },
        {
          contact_id: values.mobile_id || 0,
          contact_type: "phone",
          value: values.mobile,
        },
      ],
    };

    // ----------------------------get applicant cockpit documents data ------------------
    values["flow_stage_list"]?.map((ele, index) => {
      let stageName = ele.stage_guid;
      let sectionName = ele.sub_stage_guid;
      // ---------extract sub section name and field name----------------

      ele.field_json.map((item, eleIndex) => {
        if (item.submission_date_value) {
          item.submission_date_value =
            typeof item.submission_date_value === "string"
              ? item.submission_date_value
              : item.submission_date_value.format("YYYY-MM-DD");
        }
        if (item.start_date_value) {
          item.start_date_value =
            typeof item.start_date_value === "string"
              ? item.start_date_value
              : item.start_date_value.format("YYYY-MM-DD");
        }
        if (item.expiry_date_value) {
          item.expiry_date_value =
            typeof item.expiry_date_value === "string"
              ? item.expiry_date_value
              : item.expiry_date_value.format("YYYY-MM-DD");
        }
        if (item.type === "date_field") {
          item.value =
            typeof item.value === "string" && item.value?.length > 0
              ? item.value
              : typeof item.value === "object"
              ? item.value.format("YYYY-MM-DD")
              : null;
        }
        if (item.completion_date_value) {
          item.completion_date_value =
            typeof item.completion_date_value === "string"
              ? item.completion_date_value
              : item.completion_date_value.format("YYYY-MM-DD");
        }

        // --------------set dynamic documents stage values -------------

        let fieldName = item.field_guid.toLowerCase();

        submittingData[`${stageName}_${sectionName}_${fieldName}`] = item.value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_type`] =
          item.type;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_document_color`
        ] = item.document_color;
        submittingData[`${stageName}_${sectionName}_${fieldName}_notes`] =
          item.notes;
        submittingData[`${stageName}_${sectionName}_${fieldName}_expiry_date`] =
          item.expiry_date_value;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_submission_date`
        ] = item.submission_date_value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_start_date`] =
          item.start_date_value;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_completion_date`
        ] = item.completion_date_value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_task_check`] =
          item.task_check_value ? item.task_check_value : 0;

        submittingData[
          `${stageName}_${sectionName}_${fieldName}_validity_check`
        ] = item.validity_check_value ? item.validity_check_value : 0;
      });
    });

    // ----------------------------get student cockpit documents data -----------
    values["student_flow_stage_list"]?.map((ele, index) => {
      let stageName = ele.stage_guid;
      let sectionName = ele.sub_stage_guid;
      // ---------extract sub section name and field name----------------

      ele.field_json.map((item, eleIndex) => {
        if (item.submission_date_value) {
          item.submission_date_value =
            typeof item.submission_date_value === "string"
              ? item.submission_date_value
              : item.submission_date_value.format("YYYY-MM-DD");
        }
        if (item.start_date_value) {
          item.start_date_value =
            typeof item.start_date_value === "string"
              ? item.start_date_value
              : item.start_date_value.format("YYYY-MM-DD");
        }
        if (item.expiry_date_value) {
          item.expiry_date_value =
            typeof item.expiry_date_value === "string"
              ? item.expiry_date_value
              : item.expiry_date_value.format("YYYY-MM-DD");
        }
        if (item.type === "date_field") {
          item.value =
            typeof item.value === "string" && item.value?.length > 0
              ? item.value
              : typeof item.value === "object"
              ? item.value.format("YYYY-MM-DD")
              : null;
        }

        if (item.completion_date_value) {
          item.completion_date_value =
            typeof item.completion_date_value === "string"
              ? item.completion_date_value
              : item.completion_date_value.format("YYYY-MM-DD");
        }

        // --------------set dynamic documents stage values -------------

        let fieldName = item.field_guid.toLowerCase();

        submittingData[`${stageName}_${sectionName}_${fieldName}`] = item.value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_type`] =
          item.type;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_document_color`
        ] = item.document_color;
        submittingData[`${stageName}_${sectionName}_${fieldName}_notes`] =
          item.notes;
        submittingData[`${stageName}_${sectionName}_${fieldName}_expiry_date`] =
          item.expiry_date_value;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_submission_date`
        ] = item.submission_date_value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_start_date`] =
          item.start_date_value;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_completion_date`
        ] = item.completion_date_value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_task_check`] =
          item.task_check_value ? item.task_check_value : 0;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_validity_check`
        ] = item.validity_check_value ? item.validity_check_value : 0;
      });
    });
    if (
      values.address_id ||
      values.address_line_1 ||
      values.address_line_2 ||
      values.postal_code ||
      values.city ||
      values.state ||
      values.country
    ) {
      submittingData["address"].push({
        address_id: values.address_id,
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        address_type: "current",
        postal_code: values.postal_code,
        city: values.city,
        state: values.state,
        country: values.country,
      });
    }
    if (
      values.os_address_id ||
      values.os_address_line_1 ||
      values.os_address_line_2 ||
      values.os_postal_code ||
      values.os_city ||
      values.os_state ||
      values.os_country
    ) {
      submittingData["address"].push({
        address_id: values.os_address_id || 0,
        address_line_1: values.os_address_line_1,
        address_line_2: values.os_address_line_2,
        address_type: "overseas",
        postal_code: values.os_postal_code,
        city: values.os_city,
        state: values.os_state,
        country: values.os_country,
      });
    }

    if (
      values.em_address_id ||
      values.em_address_line_1 ||
      values.em_address_line_2 ||
      values.em_postal_code ||
      values.em_city ||
      values.em_state ||
      values.em_country
    ) {
      submittingData["address"].push({
        address_id: values.em_address_id || 0,
        address_line_1: values.em_address_line_1,
        address_line_2: values.em_address_line_2,
        address_type: "employer",
        postal_code: values.em_postal_code,
        city: values.em_city,
        state: values.em_state,
        country: values.em_country,
      });
    }

    console.log("====================================");
    console.log("submittingData:::", submittingData);
    console.log("====================================");
    let requestParams = {
      id: requestId,
      data: convertJSONToFormData(submittingData),
    };
    // return;
    // Submit the form data using the formSubmitAPI function
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setEditable(true);
      await setRequestId(null);
      console.log("====================================");
      console.log("form submiitted successfully");
      console.log("====================================");
      setRequestId(requestId ? requestParams.id : res.data.student_id);
      setSelectedIndex(requestId ? requestId : res.data.student_id);

      if (requestId) {
        notify("Student Information Updated", "success");
      } else {
        notify("Student Added", "success");
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object") {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  // Initialize formik with form configuration
  const formik = useFormik({
    initialValues: data,
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // setFormSubmitRequested(false);
    }
  }, [
    isFormSubmitRequested,
    isEditable,
    formik.handleSubmit,
    setFormSubmitRequested,
  ]);

  // to get School list from list api
  useEffect(() => {
    if (
      user.code === masterReference.HR ||
      user.code === masterReference.SUPER_ADMIN
    ) {
      if (formik.values.entity_type === masterReference.SCHOOL) {
        getSchoolDropdownList(formik.values.company_id)
          .then((res) => {
            if (res.isOk) {
              res.data.forEach((ele) => {
                ele.label = toTitleCase(ele.label);
              });
              setSchoolList(res.data);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else if (
        formik.values.entity_type === masterReference.FOREIGN_INSTITUTE
      ) {
        getForeignInstituteDropdownList(formik.values.company_id)
          .then((res) => {
            if (res.isOk) {
              res.data.forEach((ele) => {
                ele.label = toTitleCase(ele.label);
              });
              setSchoolList(res.data);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        setSchoolList([]);
      }
    }
  }, [formik.values.company_id, formik.values.entity_type]);

  // to get School goals list from list api
  useEffect(() => {
    let requestParams = {
      entity_type: formik.values.entity_type,
      entity_id: formik.values.entity_id,
    };
    if (
      (user.code === masterReference.HR && formik.values.entity_id) ||
      user.code !== masterReference.HR
    ) {
      getGoals(requestParams)
        .then((res) => {
          if (res.isOk) {
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            setGoalsList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    getDropdownCoursesList(
      formik.values.school_id,
      formik.values.entity_type,
      formik.values.entity_id
    )
      .then((res) => {
        if (res.isOk) {
          console.log(res.data);
          res.data.forEach((ele) => {
            ele.label = toTitleCase(ele.label);
          });
          setCourseList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formik.values.entity_id, isEditable]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
    setIsDirty(true);
  };

  const handlePrintClose = () => {
    setPrintRecord(false);
  };

  const checkDisabled = (stageName, flagObj) => {
    let continueFlagObj = flagObj
      ? { ...continueFlag, ...flagObj }
      : { ...continueFlag };

    formik.setFieldValue("stageFlagList", continueFlagObj);
    continueFlagObj[`${stageName}_flag`]?.forEach((ele, index) => {
      if (ele?.length > 1 && ele?.some((element) => element === false)) {
        return true;
      } else if (ele?.length === 1 && ele?.[0] === false) {
        return true;
      } else if (ele[index] === false) {
        return true;
      } else {
        return false;
      }
    });
  };

  const checkStudentDisabled = (stageName, flagObj) => {
    let continueFlagObj = flagObj
      ? { ...continueStudentFlag, ...flagObj }
      : { ...continueStudentFlag };

    formik.setFieldValue("student_stageFlagList", continueFlagObj);
    continueFlagObj[`${stageName}_flag`]?.forEach((ele, index) => {
      if (ele?.length > 1 && ele?.some((element) => element === false)) {
        return true;
      } else if (ele?.length === 1 && ele?.[0] === false) {
        return true;
      } else if (ele[index] === false) {
        return true;
      } else {
        return false;
      }
    });
  };

  // --------------------------------Other Applicant Cockpit Stages --------------------------
  useEffect(() => {
    setOtherStages([]);
    formik.values.stageLabelList?.forEach((stages, index) => {
      stages = toTitleCase(stages);
      setContinueFlag(formik.values["stageFlagList"]); // API DOCUMENTS FLAG AND STAGE 2 FLAG
      let stageGuid = Object.keys(formik.values.stageFlagList)[index]?.split(
        "_"
      )[0];
      if (stages.toLowerCase() !== "documents") {
        {
          setOtherStages((prevStages) => [
            ...prevStages,
            {
              label: stages,
              description: (
                <ApplicantDocuments
                  formik={formik}
                  user={user}
                  isCockpit={true}
                  setContinueFlag={setContinueFlag}
                  setContinueDisableFlag={setContinueDisableFlag}
                  continueDisableFlag={continueDisableFlag}
                  checkDisabled={checkDisabled}
                  stageName={stageGuid}
                />
              ),
            },
          ]);
        }
      }
    });
  }, [formik.values.flow_stage_list, formik.values.stageFlagList]);

  // ----------------------------------------Other Student Cockpit Stages------------------------
  useEffect(() => {
    setOtherStudentStages([]);
    formik.values.student_stageLabelList?.forEach((stages, index) => {
      stages = toTitleCase(stages);
      setContinueStudentFlag(formik.values["student_stageFlagList"]); // API DOCUMENTS FLAG AND STAGE 2 FLAG
      let stageGuid = Object.keys(formik.values.student_stageFlagList)[
        index
      ]?.split("_")[0];
      if (stages.toLowerCase() !== "documents") {
        {
          setOtherStudentStages((prevStages) => [
            ...prevStages,
            {
              label: stages,
              description: (
                <StudentDocuments
                  formik={formik}
                  user={user}
                  isCockpit={true}
                  setContinueFlag={setContinueStudentFlag}
                  setContinueDisableFlag={setContinueDisableFlag}
                  continueDisableFlag={continueDisableFlag}
                  checkDisabled={checkStudentDisabled}
                  stageName={stageGuid}
                />
              ),
            },
          ]);
        }
      }
    });
  }, [
    formik.values.student_flow_stage_list,
    formik.values.student_stageFlagList,
  ]);

  return (
    <>
      <Print
        open={printRecord}
        onClose={handlePrintClose}
        printData={printData}
        schoolList={schoolList}
        goalsList={goalsList}
      />
      <Transfer
        company_id={formik.values.company_id}
        requestId={requestId}
        school_id={formik.values.school_id}
        entity_type={formik.values.entity_type}
      />

      <OverlayLoader show={isLoading}>
        {generateRecord ? (
          <div
            style={{
              padding: "24px",
            }}
            id="simple-tabpanel-2"
            aria-labelledby="simple-tab-2"
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setGenerateRecord(false);
                  setDisableGenerate(false);
                }}
              >
                Show Other Tabs
              </Button>
            </div>
            <br />
            <Generate
              requestId={requestId}
              entity_id={formik.values.entity_id}
              entity_type={formik.values.entity_type}
            />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <StyledTab
              value={activeTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              {/* Define tabs for navigation */}
              {selectedRecord && selectedRecord.first_name && (
                <TabItem label={"Cockpit"} {...a11yProps(0)} />
              )}
              <ErrorTabItem
                label={"General"}
                {...a11yProps(1)}
                error={
                  (formik.touched.first_name &&
                    Boolean(formik.errors.first_name)) ||
                  (formik.touched.last_name &&
                    Boolean(formik.errors.last_name)) ||
                  (formik.touched.dob && Boolean(formik.errors.dob)) ||
                  (formik.touched.gender && Boolean(formik.errors.gender)) ||
                  (formik.touched.goal_id && Boolean(formik.errors.goal_id)) ||
                  (formik.touched.course_id && Boolean(formik.errors.course_id))
                }
              />
              {selectedRecord && selectedRecord.first_name && (
                <TabItem label={"Documents"} {...a11yProps(2)} />
              )}
              <ErrorTabItem
                label={"Contact Information"}
                {...a11yProps(3)}
                error={
                  (formik.touched.email && Boolean(formik.errors.email)) ||
                  (formik.touched.address_line_1 &&
                    Boolean(formik.errors.address_line_1)) ||
                  (formik.touched.postal_code &&
                    Boolean(formik.errors.postal_code)) ||
                  (formik.touched.country && Boolean(formik.errors.country)) ||
                  (formik.touched.city && Boolean(formik.errors.city)) ||
                  (formik.touched.state && Boolean(formik.errors.state)) ||
                  (formik.touched.country && Boolean(formik.errors.country)) ||
                  (formik.touched.os_address_line_1 &&
                    Boolean(formik.errors.os_address_line_1)) ||
                  (formik.touched.os_postal_code &&
                    Boolean(formik.errors.os_postal_code)) ||
                  (formik.touched.os_country &&
                    Boolean(formik.errors.os_country)) ||
                  (formik.touched.os_city && Boolean(formik.errors.os_city)) ||
                  (formik.touched.os_state &&
                    Boolean(formik.errors.os_state)) ||
                  (formik.touched.os_country &&
                    Boolean(formik.errors.os_country)) ||
                  (formik.touched.em_address_line_1 &&
                    Boolean(formik.errors.em_address_line_1)) ||
                  (formik.touched.em_postal_code &&
                    Boolean(formik.errors.em_postal_code)) ||
                  (formik.touched.em_country &&
                    Boolean(formik.errors.em_country)) ||
                  (formik.touched.em_city && Boolean(formik.errors.em_city)) ||
                  (formik.touched.em_state &&
                    Boolean(formik.errors.em_state)) ||
                  (formik.touched.em_country &&
                    Boolean(formik.errors.em_country))
                }
              />
              <TabItem label={"Finance"} {...a11yProps(4)} />
            </StyledTab>

            {/* Cockpit Tab Panel */}
            {selectedRecord && selectedRecord.first_name && (
              <div
                style={{
                  // padding: "0px 24px",
                  display: activeTab === 0 ? "" : "none",
                }}
                id="simple-tabpanel-0"
                aria-labelledby="simple-tab-0"
              >
                {/* Render the StudentCockpit component with formik prop */}
                <StudentCockpit
                  formik={formik}
                  handleFormChange={handleFormChange}
                  checkDisabled={checkDisabled}
                  checkStudentDisabled={checkStudentDisabled}
                  setContinueFlag={setContinueFlag}
                  setContinueStudentFlag={setContinueStudentFlag}
                  continueDisableFlag={continueDisableFlag}
                  setContinueDisableFlag={setContinueDisableFlag}
                  firstContinueRender={firstContinueRender}
                  setFirstContinueRender={setFirstContinueRender}
                  otherStudentStages={otherStudentStages}
                  otherStages={otherStages}
                />
              </div>
            )}

            {/* Render tab content based on activeTab */}
            <div
              style={{
                padding: "24px",
                display:
                  activeTab ===
                  (selectedRecord && selectedRecord.first_name ? 1 : 0)
                    ? ""
                    : "none",
              }}
              id="simple-tabpanel-0"
              aria-labelledby="simple-tab-0"
            >
              <General
                formik={formik}
                handleFormChange={handleFormChange}
                schoolList={schoolList}
                goalsList={goalsList}
                courseList={courseList}
              />
            </div>
            {selectedRecord && selectedRecord.first_name && (
              <div
                style={{
                  padding: "14px",
                  display:
                    activeTab ===
                    (selectedRecord && selectedRecord.first_name ? 2 : 1)
                      ? ""
                      : "none",
                }}
                id="simple-tabpanel-1"
                aria-labelledby="simple-tab-1"
              >
                {formik.values.stageFlagList && (
                  <StudentDocuments
                    formik={formik}
                    checkDisabled={checkDisabled}
                    checkStudentDisabled={checkStudentDisabled}
                    setContinueDisableFlag={setContinueDisableFlag}
                    stageName={
                      Object.keys(
                        formik.values.student_stageFlagList
                      )?.[0]?.split("_")[0]
                    }
                  />
                )}
              </div>
            )}
            <div
              style={{
                padding: "24px",
                display:
                  activeTab ===
                  (selectedRecord && selectedRecord.first_name ? 3 : 1)
                    ? ""
                    : "none",
              }}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              <Details formik={formik} handleFormChange={handleFormChange} />
            </div>

            <div
              style={{
                padding: "24px",
                display:
                  activeTab ===
                  (selectedRecord && selectedRecord.first_name ? 4 : 2)
                    ? ""
                    : "none",
              }}
              id="simple-tabpanel-2"
              aria-labelledby="simple-tab-2"
            >
              <Finance formik={formik} handleFormChange={handleFormChange} />
            </div>
          </form>
        )}
      </OverlayLoader>
    </>
  );
};

export default Student;
