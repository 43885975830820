import * as Yup from "yup";
import { getS3SignedUrl } from "../services/master";

/**
 * Handles keypress events to allow only numeric digits, plus (+), and minus (-) characters.
 */
export const handleKeys = (event) => {
  try {
    // Check if the charCode is a numeric digit (48-57), plus (+), or minus (-)
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 43 || // Plus (+)
      event.charCode === 45 // Minus (-)
    ) {
      return true; // Allow the input
    } else {
      event.preventDefault(); // Prevent input for other characters
    }
  } catch (error) {
    console.error("Error in handleKeys:", error);
  }
};

/**
 * Handles keypress events to allow only numeric digits , dot(.) and comma(,) characters.
 */
export const handleDecimalInput = (event) => {
  try {
    // Check if the charCode is a numeric digit (48-57), dot(.) or comma(,)
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46 || // Dot (.)
      event.charCode === 44 // Comma (,)
    ) {
      return true; // Allow the input
    } else {
      event.preventDefault(); // Prevent input for other characters
    }
  } catch (error) {
    console.error("Error in handleDecimalInput:", error);
  }
};

/**
 * Formats a given number as a US phone number.
 */
export const formatNumberInUs = (number) => {
  try {
    if (number) {
      // Remove all non-digit characters
      const cleanedNumber = number.replace(/\D/g, "");

      // Check if the cleanedNumber matches the expected format
      if (/^\+?1(\d{3})(\d{3})(\d{4})$/.test(cleanedNumber)) {
        // Format the number as +1 (XXX) XXX-XXXX
        return cleanedNumber.replace(
          /^(\+?1)(\d{3})(\d{3})(\d{4})$/,
          "+$1 ($2) $3-$4"
        );
      }

      // Return the original input if it doesn't match the expected format
      return number;
    }
  } catch (error) {
    console.error("Error in formatNumberInUs:", error);
  }
};

/**
 * Determine whether the given `input` is iterable.
 */
export const isIterable = (input) => {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === "function";
};

/**
 * Download S3 Object
 */
export const downloadS3Object = (url) => {
  const urlArray = url.split("/");
  const fileName = urlArray.at(-1);
  getS3SignedUrl(url).then((res) => {
    if (res.isOk && res.data) {
      let signedURL = res.data.presigned_url;
      if (signedURL) {
        fetch(signedURL)
          .then((response) => response.blob())
          .then((blob) => {
            const aTag = document.createElement("a");
            aTag.href = window.URL.createObjectURL(new Blob([blob]));
            aTag.setAttribute("download", fileName);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();
          });
      }
    }
  });
};

// Define password validation function

export const passwordValidation = (value) => {
  const errors = [];

  const uppercaseRegex = /^(?=.*[A-Z])/;
  const lowercaseRegex = /^(?=.*[a-z])/;
  const digitRegex = /^(?=.*\d)/;
  const specialCharRegex = /^(?=.*[!@#$%^&*])/;
  const lengthRegex = /^.{8,20}$/;

  if (!uppercaseRegex.test(value)) {
    errors.push("Password must contain at least one uppercase letter");
  } else if (!lowercaseRegex.test(value)) {
    errors.push("Password must contain at least one lowercase letter");
  } else if (!digitRegex.test(value)) {
    errors.push("Password must contain at least one number");
  } else if (!specialCharRegex.test(value)) {
    errors.push("Password must contain at least one special character");
  } else if (!lengthRegex.test(value)) {
    errors.push("Password must be between 8 and 20 characters long");
  }

  return errors;
};

// Define the common address validation function
const validateAddressFields = (prefix = "") => ({
  [`${prefix}address_line_1`]: Yup.string().required("REQUIRED"),
  [`${prefix}address_line_2`]: Yup.string(),
  [`${prefix}city`]: Yup.string().required("REQUIRED"),
  [`${prefix}state`]: Yup.string().required("REQUIRED"),
  [`${prefix}postal_code`]: Yup.string().required("REQUIRED"),
  [`${prefix}country`]: Yup.string().required("REQUIRED"),
});

/*-- Common function for validate address field
if any field has value then all field will required
*/
export const validateAddress = (fields) => {
  const {
    address_line_1,
    address_line_2,
    city,
    state,
    postal_code,
    country,
    os_address_line_1,
    os_address_line_2,
    os_city,
    os_state,
    os_postal_code,
    os_country,
    em_address_line_1,
    em_address_line_2,
    em_city,
    em_state,
    em_postal_code,
    em_country,
  } = fields || {};

  const anyFieldHasValue =
    address_line_1 || city || state || postal_code || country || address_line_2;
  const overseasFieldHasValue =
    os_address_line_1 ||
    os_city ||
    os_state ||
    os_postal_code ||
    os_country ||
    os_address_line_2;
  const employerFieldHasValue =
    em_address_line_1 ||
    em_city ||
    em_state ||
    em_postal_code ||
    em_country ||
    em_address_line_2;

  let schema = Yup.object().shape({});

  if (anyFieldHasValue) {
    schema = schema.concat(Yup.object().shape(validateAddressFields()));
  }
  if (overseasFieldHasValue) {
    schema = schema.concat(Yup.object().shape(validateAddressFields("os_")));
  }
  if (employerFieldHasValue) {
    schema = schema.concat(Yup.object().shape(validateAddressFields("em_")));
  }

  if (anyFieldHasValue || overseasFieldHasValue || employerFieldHasValue) {
    return schema.validateSync(fields, { abortEarly: false });
  }

  return true;
};

// common function for check both address are same or not
export const isAddressSame = (firstAddress, secondAddress) => {
  return (
    firstAddress.address_line_1 === secondAddress.address_line_1 &&
    firstAddress.address_line_2 === secondAddress.address_line_2 &&
    firstAddress.postal_code === secondAddress.postal_code &&
    firstAddress.state === secondAddress.state &&
    firstAddress.city === secondAddress.city &&
    firstAddress.country === secondAddress.country
  );
};
