import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

// Local Library Imports
import General from "./General";
import { isIterable } from "../../../utils/common";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import { convertJSONToFormData } from "../../../utils/utility";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { getDocumentColorStatusList } from "../../../services/schools";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";

const Title = styled("div")(({ theme }) => ({
  color: `${colors.primary}`,
  display: "inline-flex",
  alignItems: "center",
  width: "100%",
  padding: 10,

  borderBottom: `2px solid ${colors.border}`,
  background: `${colors.background}`,
}));

const IconWrapper = styled("div")(({ theme }) => ({
  padding: "3px",
}));

const ColorSettings = () => {
  const { t } = useTranslation();
  const { notify } = useNotistack();
  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const initialValues = {
    status: [
      {
        id: 0,
        color_name: "",
        status_name: "",
        color_code: "",
        applicant_count: 0,
        student_count: 0,
      },
    ],
    deleted_status: [],
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const filterOpen = Boolean(anchorEl);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();

  useEffect(() => {
    setEditable(true);
  }, []);

  // list api call to get lessons list
  useEffect(() => {
    getDocumentColorStatusList()
      .then((res) => {
        if (res.isOk) {
          // setNoOfPage(Math.ceil(res.total/pageLimit));
          if (!requestId) {
            setRequestId(res.data[0]?.id);
            setSelectedRecord(res.data);
            if (res.data.length) {
              setEditable(false);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]);
      });
  }, [requestId, searchQuery]);

  useEffect(() => {
    let selectedData = { status: [], deleted_status: [] };
    if (selectedRecord && selectedRecord.length > 0) {
      selectedData.status = selectedRecord;
      setData(selectedData);
      setEditable(true);
    } else {
      formik.resetForm();
    }
  }, [selectedRecord, requestId]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const validationSchema = Yup.object().shape({
    status: Yup.array().of(
      Yup.object().shape({
        color_name: Yup.string()
          .required("REQUIRED")
          .test(
            "unique-color_name",
            "Color name must be unique",
            function (value, index) {
              console.log("..", formik.values.status);
              const existingData = formik.values.status.filter(
                (item) =>
                  item.color_name?.toLowerCase() === value?.toLowerCase()
              );
              if (existingData.length > 1) {
                return false;
              }
              return true;
            }
          ),
        status_name: Yup.string()
          .required("REQUIRED")
          .test(
            "unique-status_name",
            "Status name must be unique",
            function (value, index) {
              const existingData = formik.values.status.filter(
                (item) =>
                  item.status_name?.toLowerCase() === value?.toLowerCase()
              );
              if (existingData.length > 1) {
                return false;
              }
              return true;
            }
          ),
      })
    ),
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    // await setRequestId(null);
    let submittingData = {
      status: values.status.map((color) => {
        color.id = color.id;
        color.color_name = color.color_name;
        color.status_name = color.status_name;
        color.color_code = color.color_code;
        return color;
      }),
      deleted_status: values.deleted_status,
    };

    let requestParams = { data: convertJSONToFormData(submittingData) };
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      await setRequestId(null);
      setRequestId(requestId);
      setSelectedIndex(requestId);
      setFormSubmitRequested(false);
      notify(t("Document Status Updated"), "success");
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object" && isIterable(value)) {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Title>
        <IconWrapper>
          <MenuBookIcon />
        </IconWrapper>
        <Typography component={"p"} variant="body1">
          &nbsp; {t("Document Color Settings")}
        </Typography>
      </Title>
      <OverlayLoader show={isLoading}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ padding: "24px", display: "flex", justifyContent: "center" }}
        >
          <General formik={formik} />
        </form>
      </OverlayLoader>
    </>
  );
};

export default ColorSettings;
