import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Grid,
  IconButton,
  Modal,
  Backdrop,
  InputAdornment,
  Divider,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import styled from "styled-components";
import { getCountryList } from "../../../../services/users";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import FileField from "../../../../components/UI/FileField";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../../hooks/useRecordSelection";
import { Content, Heading, Wrapper } from "../../../../components/style/style";
import DateField from "../../../../components/UI/DateField";
import {
  toTitleCase,
  isValidFileSize,
  isValidFileType,
} from "../../../../utils/utility";
import { colors } from "../../../../constants/colors";
import { downloadS3Object, handleKeys } from "../../../../utils/common";
import SameAsCurrentCheckbox from "../../../../components/UI/SameAsCurrentAddressForHome";
import { dateFormFormat } from "../../../../utils/dates";
import { applicantStatus } from "../../../../constants/masterData";

const StyledFileField = styled(FileField)`
  input[type="file"] {
    width: 110px;
    color: transparent;
  }
`;
const StyledInputAdornment = styled(InputAdornment)((props) => ({
  width: "80%",
}));

const StyledCloseButton = styled(Close)(() => ({
  position: "absolute",
  right: -8,
  top: -8,
  backgroundColor: colors.light_gray,
  color: colors.absolute_red,
  borderRadius: "50px",
  cursor: "pointer",
}));

const PreviewIframe = styled("iframe")(() => ({
  height: "100%",
  width: "100%",
  border: 0,
  backgroundColor: colors.white,
}));

const StyledDownloadIcon = styled(DownloadIcon)((props) => ({
  color: colors.primary,
}));

const HeadTypography = styled(Typography)((props) => ({
  fontWeight: "600 !important",
}));

// Contact Information component receives 'formik' as a prop
const ContactInfo = ({ formik, handleFormChange }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { selectedRecord } = useRecordSelection();
  const [countryList, setCountryList] = useState([]);
  const [open, setOpen] = useState(false);
  const [documentSrc, setdocumentSrc] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorIndex, setErrorIndex] = useState(0);

  const getFileName = (url) => {
    if (url) {
      if (typeof url === "string") {
        let name = url.split("/").at(-1);

        return (name ?? "").length > 20
          ? (name ?? "").substring(0, 20) + "..."
          : name ?? "";
      } else if (typeof url === "object") {
        let name = url.name;

        return (name ?? "").length > 20
          ? (name ?? "").substring(0, 20) + "..."
          : name ?? "";
      }
    }
  };

  // --------------check if the url of website is valid http------
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  const handleOpen = (url) => {
    setdocumentSrc(url);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDownload = (url) => {
    if (url) {
      downloadS3Object(url);
    }
  };

  // to get data from country list api
  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.isOk) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Wrapper>
            <Heading>Contact Details</Heading>
            {isEditable ? (
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      id="mobile"
                      label="Mobile"
                      // type="number"
                      onKeyPress={handleKeys}
                      name="mobile"
                      value={formik.values.mobile}
                      onChange={handleFormChange}
                      width="97%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      id="email"
                      label="Email"
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={handleFormChange}
                      required
                      helperText={
                        formik.touched?.email ? formik.errors?.email : ""
                      }
                      error={
                        formik.touched?.email && Boolean(formik.errors?.email)
                      }
                      width="97%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                </Grid>
              </Content>
            ) : (
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  {formik.values.mobile && (
                    <Grid item xs={12} sm={6}>
                      <InfoLabel label={"Mobile"} info={formik.values.mobile} />
                    </Grid>
                  )}
                  {formik.values.email && (
                    <Grid item xs={12} sm={6}>
                      <InfoLabel label={"Email"} info={formik.values.email} />
                    </Grid>
                  )}
                </Grid>
              </Content>
            )}
          </Wrapper>
        </Grid>
      </Grid>
      <Grid container>
        {/* Address Section */}
        <Grid
          item
          sm={!isEditable && formik.values.os_address_id <= 0 ? 12 : 6}
        >
          <Wrapper>
            <Heading>Current Address*</Heading>
            <Content component={"div"} style={{ padding: "12px" }}>
              {isEditable ? (
                <Grid container>
                  {/* Hidden input for address_id */}
                  <input
                    type="hidden"
                    name="address_id"
                    value={formik.values.address_id}
                    onChange={formik.handleChange}
                    disabled={
                      selectedRecord?.status === applicantStatus.APPROVED
                    }
                  />
                  <Grid item sm={12}>
                    <InputField
                      id="address_line_1"
                      name="address_line_1"
                      label="Address Line 1"
                      value={formik.values.address_line_1}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.address_line_1
                          ? formik.errors?.address_line_1
                          : ""
                      }
                      error={
                        formik.touched?.address_line_1 &&
                        Boolean(formik.errors?.address_line_1)
                      }
                      required
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <InputField
                      id="address_line_2"
                      name="address_line_2"
                      label="Address Line 2"
                      value={formik.values.address_line_2}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.address_line_2
                          ? formik.errors?.address_line_2
                          : ""
                      }
                      error={
                        formik.touched?.address_line_2 &&
                        Boolean(formik.errors?.address_line_2)
                      }
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      id="city"
                      name="city"
                      label="City"
                      value={formik.values.city}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.city ? formik.errors?.city : ""
                      }
                      error={
                        formik.touched?.city && Boolean(formik.errors?.city)
                      }
                      required
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      id="state"
                      name="state"
                      label="State"
                      value={formik.values.state}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.state ? formik.errors?.state : ""
                      }
                      error={
                        formik.touched?.state && Boolean(formik.errors?.state)
                      }
                      required
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <SelectDropdown
                      id={"country"}
                      name={"country"}
                      options={countryList}
                      label="Country"
                      onChange={(_, selected) => {
                        setIsDirty(true);
                        formik.setFieldValue("country", selected?.value || "");
                      }}
                      value={
                        countryList.find(
                          (option) => option.label === formik.values.country
                        ) || ""
                      }
                      helperText={
                        formik.touched?.country ? formik.errors?.country : ""
                      }
                      error={
                        formik.touched?.country &&
                        Boolean(formik.errors?.country)
                      }
                      required
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      id="postal_code"
                      type="number"
                      name="postal_code"
                      label="Postal Code"
                      value={formik.values.postal_code}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched?.postal_code
                          ? formik.errors?.postal_code
                          : ""
                      }
                      error={
                        formik.touched?.postal_code &&
                        Boolean(formik.errors?.postal_code)
                      }
                      required
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  {formik.values.address_line_1 && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Address Line 1"}
                        info={formik.values.address_line_1}
                      />
                    </Grid>
                  )}

                  {formik.values.address_line_2 && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Address Line 2"}
                        info={formik.values.address_line_2}
                      />
                    </Grid>
                  )}

                  {formik.values.city && (
                    <Grid item sm={6}>
                      <InfoLabel label={"City"} info={formik.values.city} />
                    </Grid>
                  )}

                  {formik.values.state && (
                    <Grid item sm={6}>
                      <InfoLabel label={"State"} info={formik.values.state} />
                    </Grid>
                  )}

                  {formik.values.country && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Country"}
                        info={formik.values.country}
                      />
                    </Grid>
                  )}

                  {formik.values.postal_code && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Postal Code"}
                        info={formik.values.postal_code}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Content>
          </Wrapper>
        </Grid>

        {/* Address Overseas Section */}
        <Grid item sm={!isEditable && !formik.values.address_id ? 12 : 6}>
          {isEditable ? (
            <Wrapper>
              <Grid item sm={12}>
                <Heading
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Home Country Address
                  <SameAsCurrentCheckbox
                    formik={formik}
                    handleFormChange={handleFormChange}
                  />
                </Heading>
              </Grid>
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container>
                  {/* Hidden input for os_address_id */}
                  <input
                    type="hidden"
                    name="os_address_id"
                    value={formik.values.os_address_id}
                    onChange={formik.handleChange}
                    disabled={
                      selectedRecord?.status === applicantStatus.APPROVED
                    }
                  />
                  <Grid item sm={12}>
                    <InputField
                      id="os_address_line_1"
                      name="os_address_line_1"
                      label="Address Line 1"
                      value={formik.values.os_address_line_1}
                      onChange={handleFormChange}
                      width="98%"
                      helperText={
                        formik.touched.os_address_line_1
                          ? formik.errors.os_address_line_1
                          : ""
                      }
                      error={
                        formik.touched.os_address_line_1 &&
                        Boolean(formik.errors.os_address_line_1)
                      }
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <InputField
                      id="os_address_line_2"
                      name="os_address_line_2"
                      label="Address Line 2"
                      value={formik.values.os_address_line_2}
                      onChange={handleFormChange}
                      width="98%"
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      id="os_city"
                      name="os_city"
                      label="City"
                      value={formik.values.os_city}
                      onChange={handleFormChange}
                      width="98%"
                      helperText={
                        formik.touched.os_city ? formik.errors.os_city : ""
                      }
                      error={
                        formik.touched.os_city && Boolean(formik.errors.os_city)
                      }
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      id="os_state"
                      name="os_state"
                      label="State"
                      width="98%"
                      value={formik.values.os_state}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched.os_state ? formik.errors.os_state : ""
                      }
                      error={
                        formik.touched.os_state &&
                        Boolean(formik.errors.os_state)
                      }
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <SelectDropdown
                      id={"os_country"}
                      name={"os_country"}
                      options={countryList}
                      label="Country"
                      width="98%"
                      onChange={(_, selected) => {
                        setIsDirty(true);
                        formik.setFieldValue(
                          "os_country",
                          selected?.value || ""
                        );
                      }}
                      value={
                        countryList.find(
                          (option) => option.value === formik.values.os_country
                        ) || ""
                      }
                      helperText={
                        formik.touched.os_country
                          ? formik.errors.os_country
                          : ""
                      }
                      error={
                        formik.touched.os_country &&
                        Boolean(formik.errors.os_country)
                      }
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      id="os_postal_code"
                      type="number"
                      name="os_postal_code"
                      label="Postal Code"
                      width="98%"
                      value={formik.values.os_postal_code}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched.os_postal_code
                          ? formik.errors.os_postal_code
                          : ""
                      }
                      error={
                        formik.touched.os_postal_code &&
                        Boolean(formik.errors.os_postal_code)
                      }
                      disabled={
                        selectedRecord?.status === applicantStatus.APPROVED
                      }
                    />
                  </Grid>
                </Grid>
              </Content>
            </Wrapper>
          ) : (
            <>
              {formik.values.os_address_line_1 ||
              formik.values.os_address_line_2 ||
              formik.values.os_city ||
              formik.values.os_state ||
              formik.values.os_country ||
              formik.values.os_country ||
              formik.values.os_postal_code ? (
                <Wrapper>
                  <Heading>Home Country Address</Heading>
                  <Content component={"div"} style={{ padding: "12px" }}>
                    <Grid container>
                      {formik.values.os_address_line_1 && (
                        <Grid item sm={6}>
                          <InfoLabel
                            label={"Address Line 1"}
                            info={formik.values.os_address_line_1}
                          />
                        </Grid>
                      )}

                      {formik.values.os_address_line_2 && (
                        <Grid item sm={6}>
                          <InfoLabel
                            label={"Address Line 2"}
                            info={formik.values.os_address_line_2}
                          />
                        </Grid>
                      )}

                      {formik.values.os_city && (
                        <Grid item sm={6}>
                          <InfoLabel
                            label={"City"}
                            info={formik.values.os_city}
                          />
                        </Grid>
                      )}

                      {formik.values.os_state && (
                        <Grid item sm={6}>
                          <InfoLabel
                            label={"State"}
                            info={formik.values.os_state}
                          />
                        </Grid>
                      )}

                      {formik.values.os_country && (
                        <Grid item sm={6}>
                          <InfoLabel
                            label={"Country"}
                            info={formik.values.os_country}
                          />
                        </Grid>
                      )}

                      {formik.values.os_postal_code && (
                        <Grid item sm={6}>
                          <InfoLabel
                            label={"Postal Code"}
                            info={formik.values.os_postal_code}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Content>
                </Wrapper>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>

      {formik.values.flow_stage_list?.length > 0 &&
        formik.values.flow_stage_list.map((ele, idx) => {
          const generalFields = formik.values.flow_stage_list?.filter(
            (item) => item.section_name === "contact_information"
          );
          return (
            generalFields.length > 0 &&
            ele.task_count !== ele.field_json.length &&
            ele.section_name === "contact_information" && (
              <Grid container key={idx}>
                <Grid item sm={12} xs={12}>
                  <Wrapper>
                    {/* Show Documents for Contact Information Section */}
                    <Heading>{toTitleCase(ele.sub_stage_name)}</Heading>
                    <Content component={"div"} style={{ padding: "12px" }}>
                      <Grid container spacing={2}>
                        {ele.field_json.map((obj, indexNum) => {
                          return isEditable ? (
                            obj.type === "text_field" ? (
                              <Grid item xs={6} sm={4}>
                                <InputField
                                  label={toTitleCase(obj.field_name).trim()}
                                  variant="outlined"
                                  name={`value`}
                                  width="100%"
                                  value={obj.value}
                                  onChange={(event) => {
                                    setIsDirty(true);
                                    let fieldIndex =
                                      formik.values.flow_stage_list[
                                        idx
                                      ].field_json.findIndex(
                                        (temp) =>
                                          temp["field_name"] === obj.field_name
                                      );
                                    formik.setFieldValue(
                                      `flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                      event.target.value.replace(/^\s+/g, "")
                                    );
                                  }}
                                />
                              </Grid>
                            ) : obj.type === "dropdown" ? (
                              <Grid item xs={6} sm={4}>
                                <SelectDropdown
                                  label={toTitleCase(obj.field_name).trim()}
                                  width="100%"
                                  name={`value`}
                                  options={obj.choices}
                                  disableClearable
                                  value={
                                    obj.choices.find(
                                      (option) => option === obj.value
                                    ) || ""
                                  }
                                  onChange={(_, selected) => {
                                    setIsDirty(true);
                                    let fieldIndex =
                                      formik.values.flow_stage_list[
                                        idx
                                      ].field_json.findIndex(
                                        (temp) =>
                                          temp["field_name"] === obj.field_name
                                      );

                                    formik.setFieldValue(
                                      `flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                      selected
                                    );
                                  }}
                                />
                              </Grid>
                            ) : (
                              obj.type === "date_field" && (
                                <>
                                  <Grid item xs={6} sm={4}>
                                    <DateField
                                      label={toTitleCase(obj.field_name).trim()}
                                      name={`value`}
                                      id={`value`}
                                      width="100%"
                                      // maxDate={dayjs()}
                                      value={
                                        obj.value ? dayjs(obj.value) : null
                                      }
                                      onChange={(date) => {
                                        setIsDirty(true);
                                        let fieldIndex =
                                          formik.values.flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        formik.setFieldValue(
                                          `flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                          date
                                        );
                                      }}
                                    />
                                  </Grid>
                                </>
                              )
                            )
                          ) : (
                            <>
                              {obj.type !== "file_field" && (
                                <Grid item xs={3}>
                                  <InfoLabel
                                    label={toTitleCase(obj.field_name)}
                                    info={obj.value}
                                  />
                                </Grid>
                              )}
                            </>
                          );
                        })}
                        <Grid item xs={12} sm={12}></Grid>
                        {ele.field_json?.some(
                          (obj) => obj.type === "file_field"
                        ) &&
                          !isEditable && (
                            <>
                              <Grid item xs={12} sm={12}>
                                <Grid container spacing={4}>
                                  <Grid item xs={3}>
                                    <InfoLabel label={`Files`} />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Grid
                                  container
                                  alignItems="center"
                                  rowSpacing={1}
                                  columnSpacing={5}
                                >
                                  <Grid item sm={4}>
                                    <HeadTypography>Document</HeadTypography>
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    <HeadTypography>Submission</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Issue</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Expiry</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Valid</HeadTypography>
                                  </Grid>

                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Actions</HeadTypography>
                                  </Grid>
                                  <Grid item sm={12}>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}

                        {ele.field_json.map((obj, indexNum) => {
                          return isEditable
                            ? obj.type === "file_field" && (
                                <>
                                  <Grid item xs={6} sm={3.5}>
                                    <StyledFileField
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border:
                                            obj.value?.length <= 0
                                              ? `2px solid ${colors.error}`
                                              : "",
                                        },
                                      }}
                                      label={toTitleCase(obj.field_name).trim()}
                                      name={`value`}
                                      width="100%"
                                      onChange={(file) => {
                                        if (file) {
                                          if (
                                            !isValidFileType(file, [
                                              "jpeg",
                                              "jpg",
                                              "png",
                                              "pdf",
                                            ])
                                          ) {
                                            formik.setFieldValue(
                                              `flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              null
                                            );

                                            setErrorMessage(
                                              "File type should be jpeg, jpg, png or pdf."
                                            );
                                            setErrorIndex(indexNum);
                                            return;
                                          } else if (
                                            !isValidFileSize(
                                              file,
                                              5 * 1024 * 1024
                                            )
                                          ) {
                                            formik.setFieldValue(
                                              `flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              null
                                            );

                                            setErrorMessage(
                                              "File size should be less than 5 MB."
                                            );
                                            setErrorIndex(indexNum);
                                            return;
                                          } else {
                                            setIsDirty(true);
                                            formik.setFieldValue(
                                              `flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              file
                                            );

                                            setErrorMessage(""); // Clear error message if file is valid
                                            setErrorIndex(0);
                                          }
                                        }
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <StyledInputAdornment position="start">
                                            {getFileName(obj.value)}
                                          </StyledInputAdornment>
                                        ),
                                      }}
                                      error={
                                        Boolean(errorMessage) &&
                                        errorIndex === indexNum
                                      }
                                    />
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.submission_date ? (
                                      <DateField
                                        label="Submission"
                                        id={`submission_date_value`}
                                        name={`submission_date_value`}
                                        width="100%"
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.submission_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        maxDate={dayjs()}
                                        value={
                                          obj.submission_date_value
                                            ? dayjs(obj.submission_date_value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${indexNum}.submission_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Submission
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.start_date ? (
                                      <DateField
                                        label="Issue Date"
                                        id={`start_date_value`}
                                        name={`start_date_value`}
                                        width="100%"
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.start_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        maxDate={dayjs()}
                                        value={
                                          obj.start_date_value
                                            ? dayjs(obj.start_date_value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${indexNum}.start_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Issue
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.expiry_date ? (
                                      <DateField
                                        label="Expiry"
                                        id={`expiry_date_value`}
                                        name={`expiry_date_value`}
                                        width="100%"
                                        value={
                                          obj.expiry_date_value
                                            ? dayjs(obj.expiry_date_value)
                                            : null
                                        }
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.expiry_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `flow_stage_list.${idx}.field_json.${indexNum}.expiry_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Expiry
                                      </p>
                                    )}
                                  </Grid>

                                  <Grid item sm={1.5} align="center">
                                    {obj.value && obj.validity_check ? (
                                      <>
                                        <FormControlLabel
                                          label={`Validity`}
                                          style={{ marginTop: "10px" }}
                                          control={
                                            <Checkbox
                                              onChange={(event) => {
                                                event.target.checked
                                                  ? formik.setFieldValue(
                                                      `flow_stage_list.${idx}.field_json.${indexNum}.validity_check_value`,
                                                      true
                                                    )
                                                  : formik.setFieldValue(
                                                      `flow_stage_list.${idx}.field_json.${indexNum}.validity_check_value`,
                                                      false
                                                    );

                                                setIsDirty(true);
                                              }}
                                              checked={
                                                obj.validity_check_value &&
                                                obj.value
                                                  ? true
                                                  : false
                                              }
                                              // style={{
                                              //   border:
                                              //     !obj.validity_check_value &&
                                              //     "1px solid red",
                                              // }}
                                            ></Checkbox>
                                          }
                                        ></FormControlLabel>
                                      </>
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Validity
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    sm={1}
                                    style={{ marginTop: "13px" }}
                                  >
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleDownload(obj.value)
                                        }
                                        size="small"
                                      >
                                        <StyledDownloadIcon
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        aria-label="icon"
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleOpen(obj.value)
                                        }
                                        style={{
                                          position: "absolute",
                                          marginTop: "-1px",
                                        }}
                                      >
                                        <VisibilityIcon
                                          fontSize="small"
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item sm={6}>
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {errorIndex === indexNum
                                        ? errorMessage
                                        : ""}
                                    </p>
                                  </Grid>
                                  <Grid item sm={12}>
                                    <Divider />
                                  </Grid>
                                </>
                              )
                            : obj.type === "file_field" && (
                                <Grid item xs={12} sm={12}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    rowSpacing={1}
                                    columnSpacing={5}
                                  >
                                    <React.Fragment key={indexNum}>
                                      <Grid item sm={4}>
                                        {obj.type === "file_field" ? (
                                          <Typography value={obj.field_name}>
                                            {obj.field_name}
                                          </Typography>
                                        ) : (
                                          <Typography value={obj.value}>
                                            {obj.field_name}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item sm={2} align="center">
                                        {obj.submission_date ? (
                                          typeof obj.submission_date_value ===
                                            "string" &&
                                          obj.submission_date_value.length <=
                                            0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(
                                              obj.submission_date_value
                                            )
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.start_date ? (
                                          typeof obj.start_date_value ===
                                            "string" &&
                                          obj.start_date_value.length <= 0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(obj.start_date_value)
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.expiry_date ? (
                                          typeof obj.expiry_date_value ===
                                            "string" &&
                                          obj.expiry_date_value.length <= 0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(
                                              obj.expiry_date_value
                                            )
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.value && obj.validity_check ? (
                                          obj.validity_check_value ? (
                                            "Valid"
                                          ) : (
                                            "Invalid"
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>

                                      <Grid item sm={1.5}>
                                        {obj.type === "file_field" && (
                                          <IconButton
                                            onClick={() =>
                                              isValidHttpUrl(obj.value) &&
                                              handleDownload(obj.value)
                                            }
                                            size="small"
                                            style={{ marginLeft: "30px" }}
                                          >
                                            <StyledDownloadIcon
                                              style={{
                                                opacity: isValidHttpUrl(
                                                  obj.value
                                                )
                                                  ? 1
                                                  : 0.5,
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                        {obj.type === "file_field" && (
                                          <IconButton
                                            aria-label="icon"
                                            onClick={() =>
                                              isValidHttpUrl(obj.value) &&
                                              handleOpen(obj.value)
                                            }
                                            style={{
                                              position: "absolute",
                                              marginTop: "-1px",
                                            }}
                                          >
                                            <VisibilityIcon
                                              fontSize="small"
                                              style={{
                                                opacity: isValidHttpUrl(
                                                  obj.value
                                                )
                                                  ? 1
                                                  : 0.5,
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  </Grid>
                                </Grid>
                              );
                        })}
                      </Grid>
                    </Content>
                  </Wrapper>
                </Grid>
              </Grid>
            )
          );
        })}

      <Backdrop onClick={handleClose} open={open}>
        <Modal
          onClose={handleClose}
          open={open}
          sx={{
            width: "90%",
            height: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: colors.white,
            boxShadow: 24,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <StyledCloseButton
              fontSize="large"
              onClick={handleClose}
              color="error"
            />
            <PreviewIframe
              id="i_frame"
              src={documentSrc}
              title="document"
            ></PreviewIframe>
          </div>
        </Modal>
      </Backdrop>
    </>
  );
};

export default ContactInfo;
