import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import RadioFieldSet from "../RadioFieldSet";
import { toCapitalize } from "../../../utils/utility";
import { Wrap } from "../../style/style";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Create a styled component for the RadioGroup
const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
//  radio button custom ui
const RadioBtnGroup = ({ id = "", name = "", options = [], required = false, ...restProps }) => {
  const title = `${toCapitalize(name)}${required ? "*" : ""}`;
  return (
    <Wrap noValidate autoComplete="off">
      <RadioFieldSet title={title}>
        <StyledRadioGroup
          aria-labelledby={id + "-label"}
          name={name}
          {...restProps}
        >
          {options.map((option, index) => (
            <FormControlLabel
              value={option.value}
              key={index}
              control={<Radio />}
              label={option.label}
              size="small"
            />
          ))}
        </StyledRadioGroup>
      </RadioFieldSet>
    </Wrap>
  );
};

RadioBtnGroup.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RadioBtnGroup;
