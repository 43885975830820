import * as React from "react";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { colors } from "../../../constants/colors";

const CloseButton = ({ onClick, size="small", fontSize="medium", ...restProps }) => {
  return (
    <IconButton
        aria-label="icon"
        size={size}
        onClick={onClick}
        sx={{
            "&:hover": {
                backgroundColor:
                colors.lightRed,
            },
        }}
      {...restProps}
    >
        <Close fontSize={fontSize} sx={{ color: colors.red }} />
    </IconButton>
  );
};

export default CloseButton;
