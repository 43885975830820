import moment from "moment-timezone";
import dayjs from "dayjs";
export const getYearDaysArray = () => {
  try {
    const year = moment().year();
    const days = [];
    const totalDays =
      moment()
        .year(moment().year())
        .endOf("year")
        .diff(moment().year(moment().year()).startOf("year"), "days") + 1;
    for (let i = 1; i <= totalDays; i++) {
      let date = moment().year(year).dayOfYear(i);
      days.push({
        date: date,
        long: date.format("dddd, MMMM DD"),
        short: date.format("DD/MM/YYYY"),
      });
    }
    return days;
  } catch (error) {
    console.error("Error in getYearDaysArray:", error);
    return [];
  }
};

export const getMonthArray = () => {
  try {
    const year = moment().year();
    const months = [];
    for (let i = 1; i <= 12; i++) {
      let month = moment()
        .year(year)
        .month(i - 1);
      months.push({
        month: month,
        formatted: month.format("MMMM, YYYY"),
      });
    }
    return months;
  } catch (error) {
    console.error("Error in getMonthArray:", error);
    return [];
  }
};

export const getWeekArray = () => {
  try {
    const year = moment().year();
    const weeks = [];
    const totalWeeks =
      moment()
        .year(moment().year())
        .endOf("year")
        .diff(moment().year(moment().year()).startOf("year"), "weeks") + 1;
    for (let i = 1; i <= totalWeeks; i++) {
      let week = moment().year(year).week(i);

      weeks.push({
        week: i,
        startShort: week.startOf("week").format("MMM, DD"),
        endShort: week.endOf("week").format("MMM, DD"),
        startLong: week.startOf("week").format("MMMM, DD"),
        endLong: week.endOf("week").format("MMMM, DD"),
      });
    }
    return weeks;
  } catch (error) {
    console.error("Error in getWeekArray:", error);
    return [];
  }
};

// this format made for API use
export const APIdateFormat = (date) => {
  try {
    return date.format("YYYY-MM-DD");
  } catch (error) {
    console.error("Error in APIdateFormat:", error);
    return "";
  }
};

// this is for frontend view
export const dateTimeFormat = (str, timezone) => {
  try {
    if (timezone) {
      return moment
        .tz(str, "YYYY-MM-DDTHH:mm:ssZ", timezone)
        .format("MM-DD-YYYY (HH:mm)");
    } else {
      return moment(str).format("MM-DD-YYYY (HH:mm)");
    }
  } catch (error) {
    console.error("Error in dateTimeFormat :", error);
    return "";
  }
};

export const dateFormat = (str, timezone) => {
  try {
    if (timezone) {
      return moment
        .tz(str, "YYYY-MM-DDTHH:mm:ssZ", timezone)
        .format("MM-DD-YYYY");
    } else {
      return moment(str).format("MM-DD-YYYY");
    }
  } catch (error) {
    console.error("Error in dateFormat:", error);
    return "";
  }
};

export const smallDateFormat = (str, timezone) => {
  try {
    if (timezone) {
      return moment
        .tz(str, "YYYY-MM-DDTHH:mm:ssZ", timezone)
        .format("MM-DD-YY");
    } else {
      return moment(str).format("MM-DD-YY");
    }
  } catch (error) {
    console.error("Error in smallDateFormat:", error);
    return "";
  }
};

export const timeFormat = (str, timezone) => {
  try {
    if (timezone) {
      return moment.tz(str, "YYYY-MM-DDTHH:mm:ssZ", timezone).format("hh:mm A");
    } else {
      return moment(str).format("hh:mm A");
    }
  } catch (error) {
    console.error("Error in timeFormat:", error);
    return "";
  }
};

export const dateTimeFormFormat = (str, timezone) => {
  try {
    if (timezone) {
      return moment
        .tz(str, "YYYY-MM-DDTHH:mm:ssZ", timezone)
        .format("YYYY-MM-DDTHH:mm");
    } else {
      return moment(str).format("YYYY-MM-DDTHH:mm");
    }
  } catch (error) {
    console.error("Error in dateTimeFormFormat:", error);
    return "";
  }
};

// export const dateFormFormat = (str, timezone) => {
//   try {
//     if (timezone) {
//       return moment
//         .tz(str, "YYYY-MM-DDTHH:mm:ssZ", timezone)
//         .format("DD.MM.YYYY");
//     } else {
//       return moment(str).format("DD.MM.YYYY");
//     }
//   } catch (error) {
//     console.error("Error in dateFormFormat:", error);
//     return "";
//   }
// };

export const dateFormFormat = (str) => {
  try {
    return dayjs(str).format("DD.MM.YYYY");
  } catch (error) {
    console.error("Error in dateFormFormat:", error);
    return "";
  }
};

export const notesDateFormat = (dateString) => {
  return moment(dateString).format("DD.MM.YYYY, HH:mm");
};
