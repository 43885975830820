import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {
  IconButton,
  MenuItem,
  Menu,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
// Local Library Imports
import General from "./General";
import { isIterable } from "../../../utils/common";
import useNotistack from "../../../hooks/useNotistack";
import { getGoalList } from "../../../services/schools";
import { convertJSONToFormData, toTitleCase } from "../../../utils/utility";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { masterReference } from "../../../constants/masterData";
import useAuth from "../../../hooks/useAuth";
import { colors } from "../../../constants/colors";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import { entitiesList } from "../../../constants/masterData";
import { getEntitiesList } from "../../../services/master";

const Title = styled("div")(({ theme }) => ({
  color: `${colors.primary}`,
  display: "inline-flex",
  alignItems: "center",
  width: "100%",
  padding: 10,

  borderBottom: `2px solid ${colors.border}`,
  background: `${colors.background}`,
}));

const IconWrapper = styled("div")(({ theme }) => ({
  padding: "3px",
}));

const Goals = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { notify } = useNotistack();
  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const initialValues = {
    goals: [
      {
        id: 0,
        goal_name: "goal 1",
      },
    ],
    delete_goals: [],
  };

  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [entitiesNameList, setEntitiesNameList] = useState([]);
  const [entityId, setEntityId] = useState(0);
  const [entityType, setEntityType] = useState(entitiesList[0].value);
  const [query, setQuery] = useState("");
  const [filtersValue, setFiltersValue] = useState({});

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyBtn = (filtersValueArgs) => {
    let tempQueue = "";
    let filtersData;
    if (
      filtersValueArgs?.[Object.keys(filtersValueArgs)[0]] !== "onClick" &&
      filtersValueArgs?.[Object.keys(filtersValueArgs)[0]] &&
      filtersValueArgs?.[Object.keys(filtersValueArgs)[0]].length > 0
    ) {
      filtersData = filtersValueArgs;
    } else {
      filtersData = filtersValue;
    }
    for (const key in filtersData) {
      let temp = `&${key}=`;
      if (Array.isArray(filtersData[key])) {
        temp += filtersData[key].join(",");
      } else {
        temp += filtersData[key];
      }
      tempQueue += temp;
    }

    // setQuery(tempQueue);
    handleFilterClose();
  };

  const handleClear = () => {
    setQuery("");
    setFiltersValue("");
    handleFilterClose();
  };

  let endTime = dayjs().startOf("day");
  const validateSchema = Yup.object().shape({
    goals: Yup.array().of(
      Yup.object().shape({
        goal_name: Yup.string().required("REQUIRED"),
      })
    ),
  });

  useEffect(() => {
    if (user.code === masterReference.HR && entityType) {
      getEntitiesList({ entity_type: entityType }).then((res) => {
        res.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setEntitiesNameList(res.data);
        setEntityId(res.data[0].value);
      });
    }
  }, [entityType]);

  // list api call to get goals list
  useEffect(() => {
    let requestParams = {
      page: 1,
      limit: 20,
      q: "",
    };
    if (user.code === masterReference.HR && entityType) {
      requestParams = {
        page: 1,
        limit: 20,
        q: "",
        entity_type: entityType,
        entity_id: entityId || entitiesNameList[0]?.value,
      };
    }

    getGoalList(requestParams)
      .then((res) => {
        if (res.isOk) {
          setData({ goals: res.data, delete_goals: [] });
          // setNoOfPage(Math.ceil(res.total/pageLimit));
          if (!requestId) {
            setRequestId(res.data[0]?.id);

            setSelectedRecord(res.data);
            if (res.data.length) {
              setEditable(false);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]);
      });
  }, [requestId, entityType, entityId, query]);

  useEffect(() => {
    let selectedData = {
      goals: [
        {
          id: 0,
          goal_name: "goal 1",
        },
      ],
      delete_goals: [],
    };
    if (selectedRecord && selectedRecord.length > 0) {
      selectedData.goals = selectedRecord.map((goal) => {
        return goal;
      });
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER ||
        user.code === masterReference.HR
      ) {
        setEditable(true);
        setFormSubmitRequested(false);
      }
    } else {
      setData(initialValues);
      formik.resetForm();
    }
  }, [selectedRecord, requestId]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    console.log("values.....", values);
    setIsLoading(true);
    let submittingData =
      user.code === masterReference.HR
        ? {
            entity_id: entityId,
            entity_type: entityType,
            goals: values.goals,
            delete_goals: values.delete_goals,
          }
        : {
            goals: values.goals,
            delete_goals: values.delete_goals,
          };
    console.log(submittingData);
    let requestParams = { data: convertJSONToFormData(submittingData) };
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      await setRequestId(null);
      setRequestId(requestId);
      setSelectedIndex(requestId);

      if (requestId) {
        notify(t("Goals Information Updated"), "success");
      } else {
        notify(t("Goal Added"), "success");
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object" && isIterable(value)) {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  return (
    <>
      {/* ----------------Title section with Lesson and Calendar , Course Filters---------------- */}
      <Title>
        {
          <IconWrapper>
            <MenuBookIcon />
          </IconWrapper>
        }
        <Typography component={"p"} variant="body1">
          &nbsp; {t("Goals")}
        </Typography>
        &nbsp;
        {user.code === masterReference.HR && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              // marginLeft: "auto",
            }}
          >
            <Tooltip title="Filter">
              <IconButton
                onClick={handleFilterClick}
                size="small"
                aria-controls={open ? "Filter" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <FilterAltIcon color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Title>
      <Menu
        id="account-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "300px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" } }}>
          <SelectDropdown
            id={"entity_type"}
            name={"entity_type"}
            options={entitiesList}
            label={t("Entity Type")}
            width="100%"
            onChange={(_, selected) => {
              setEntityType(selected.value);
            }}
            value={
              entitiesList.find((option) => option.value === entityType) ||
              entitiesList[0] ||
              ""
            }
            disableClearable
          />
        </MenuItem>
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" } }}>
          <SelectDropdown
            id={"entity_id"}
            name={"entity_id"}
            options={entitiesNameList}
            label={t("Entity")}
            width="100%"
            onChange={(_, selected) => {
              setEntityId(selected.value);
            }}
            value={
              entitiesNameList.find((option) => option.value === entityId) ||
              entitiesNameList[0] ||
              ""
            }
            disableClearable
          />
        </MenuItem>
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" } }}>
          <Grid container justifyContent="space-between" sx={{ marginTop: 1 }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClear();
              }}
            >
              {t("Clear")}
            </Button>
            <Button
              variant="filled"
              style={{
                backgroundColor: "#5D87FF",
                color: colors.white,
                marginLeft: 15,
              }}
              onClick={handleApplyBtn}
            >
              {t("Apply")}
            </Button>
          </Grid>
        </MenuItem>
      </Menu>
      <OverlayLoader show={isLoading}>
        <form onSubmit={formik.handleSubmit} style={{ padding: "24px" }}>
          <General formik={formik} />
        </form>
      </OverlayLoader>
    </>
  );
};

export default Goals;
