import React from "react";

import useAuth from "../../hooks/useAuth";
import { handleKeys } from "../../utils/common";
import WidgetsLoader from "../../components/WidgetsLoader";
import ShrinkSearchSelect from "../../components/UI/ShrinkSearchSelect";
import { masterReference } from "../../constants/masterData";
import { CompanyGrid, GridItem, LabeledCompanyInput } from "./style";
import { toTitleCase } from "../../utils/utility";

const AddHiringAgencyForm = ({
  formik,
  isLoading,
  countryList,
  companyOptions,
}) => {
  const { user } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
  };

  return (
    <>
      {isLoading ? (
        <WidgetsLoader />
      ) : (
        <CompanyGrid container spacing={2}>
          <GridItem
            item
            sm={user.code === masterReference.SUPER_ADMIN ? 6 : 12}
            xs={12}
          >
            <LabeledCompanyInput
              id="name"
              name="name"
              title="Name"
              placeholder="Name"
              required
              value={toTitleCase(formik.values.name)}
              onChange={handleChange}
              helperText={formik.touched?.name ? formik.errors?.name : ""}
              error={formik.touched.name && formik.errors.name}
            />
            {/* {formik.touched.name && formik.errors.name && (
                <InputError>{formik.errors.name}</InputError>
              )} */}
          </GridItem>
          {user.code === masterReference.SUPER_ADMIN && (
            <GridItem item xs={12} sm={6}>
              <ShrinkSearchSelect
                required
                id="company_id"
                name="company_id"
                label="Company"
                options={companyOptions}
                onChange={(_, selected) => {
                  formik.setFieldValue("company_id", selected?.value || "");
                }}
                value={
                  companyOptions.find(
                    (option) => option.value === formik.values.company_id
                  ) ||
                  companyOptions[0] ||
                  ""
                }
                helperText={
                  formik.touched?.company_id ? formik.errors?.company_id : ""
                }
                error={formik.touched.company_id && formik.errors.company_id}
              />
              {/* {formik.touched.company_id && formik.errors.company_id && (
                <InputError>
                  {formik.errors.company_id.replace("company_id", "Company")}
                </InputError>
              )} */}
            </GridItem>
          )}
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="phone_no"
              name="phone_no"
              title="Phone Number"
              // required
              // placeholder="+1(000)-000-00-00"
              onKeyPress={handleKeys}
              onChange={handleChange}
              value={formik.values.phone_no}
              helperText={
                formik.touched?.phone_no ? formik.errors?.phone_no : ""
              }
              error={formik.touched.phone_no && formik.errors.phone_no}
            />
            {/* {formik.touched.phone_no && formik.errors.phone_no && (
                <InputError>
                  {formik.errors.phone_no.replace("phone_no", "phone")}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="email"
              name="email"
              title="Email Address"
              placeholder="example@domain.com"
              required
              onChange={handleChange}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              value={formik.values.email}
              helperText={formik.touched?.email ? formik.errors?.email : ""}
              error={formik.touched.email && formik.errors.email}
            />
            {/* {formik.touched.email && formik.errors.email && (
                <InputError>{formik.errors.email}</InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="address_line_1"
              name="address_line_1"
              title="Address Line 1"
              required
              onChange={handleChange}
              value={formik.values.address_line_1}
              helperText={
                formik.touched?.address_line_1
                  ? formik.errors?.address_line_1
                  : ""
              }
              error={
                formik.touched.address_line_1 && formik.errors.address_line_1
              }
            />
            {/* {formik.touched.address_line_1 && formik.errors.address_line_1 && (
                <InputError>
                  {formik.errors.address_line_1.replace("address_line_1", "Address Line 1")}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="address_line_2"
              name="address_line_2"
              title="Address Line 2"
              onChange={handleChange}
              value={formik.values.address_line_2}
              helperText={
                formik.touched?.address_line_2
                  ? formik.errors?.address_line_2
                  : ""
              }
              error={
                formik.touched.address_line_2 && formik.errors.address_line_2
              }
            />
            {/* {formik.touched.address_line_2 &&
                formik.errors.address_line_2 && (
                  <InputError>
                    {formik.errors.address_line_2.replace(
                      "address_line_2",
                      "Address Line 2"
                    )}
                  </InputError>
                )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="postal_code"
              type="number"
              name="postal_code"
              title="Postal Code"
              placeholder="Postal Code"
              required
              onChange={handleChange}
              value={formik.values.postal_code}
              helperText={
                formik.touched?.postal_code ? formik.errors?.postal_code : ""
              }
              error={formik.touched.postal_code && formik.errors.postal_code}
            />
            {/* {formik.touched.postal_code && formik.errors.postal_code && (
                <InputError>
                  {formik.errors.postal_code.replace(
                    "postal_code",
                    "postal code"
                  )}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="city"
              name="city"
              title="City"
              placeholder="City"
              required
              onChange={handleChange}
              value={formik.values.city}
              helperText={formik.touched?.city ? formik.errors?.city : ""}
              error={formik.touched.city && formik.errors.city}
            />
            {/* {formik.touched.city && formik.errors.city && (
                <InputError>{formik.errors.city}</InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <LabeledCompanyInput
              id="state"
              name="state"
              title="State"
              placeholder="state"
              required
              onChange={handleChange}
              value={formik.values.state}
              helperText={formik.touched?.state ? formik.errors?.state : ""}
              error={formik.touched.state && formik.errors.state}
            />
            {/* {formik.touched.state && formik.errors.state && (
                <InputError>{formik.errors.state}</InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <ShrinkSearchSelect
              id="country"
              name="country"
              options={countryList}
              label="Country"
              placeholder="country"
              required
              onChange={(_, selected) => {
                formik.setFieldValue("country", selected?.value || "");
              }}
              value={
                countryList.find(
                  (option) => option.value === formik.values.country
                ) || ""
              }
              helperText={formik.touched?.country ? formik.errors?.country : ""}
              error={formik.touched.country && formik.errors.country}
            />
            {/* {formik.touched.country && formik.errors.country && (
                <InputError>{formik.errors.country}</InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={12}></GridItem>
        </CompanyGrid>
      )}
    </>
  );
};

export default AddHiringAgencyForm;
