import { ArrowBack } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../styles/vars";

const NavigationWrapper = styled.div`
  display: flex;
  z-index: 1;
  // margin-bottom: 16px;
`;
const BackButton = styled.div`
  cursor: pointer;
  margin-right: 5px;
`;

const BreadCrumb = (props) => {
  // Hook declaration
  const location = useLocation();
  const length = props.match.length - 1;

  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <>
      {/* show breadcrum if device is not mobile */}
      {!props.isMobile ? (
        <NavigationWrapper>
          {props.match.map((data, index) => (
            <>
              <Link
                key={data.pathname + "_" + index}
                to={data.pathname}
                style={
                  data.pathname === location.pathname || data.pathname === ""
                    ? {
                        color: colors.black,
                        paddingRight: "5px",
                        pointerEvents: "none",
                      }
                    : {
                        color: colors.primary,
                        paddingRight: "5px",
                      }
                }
              >
                {data.title}
              </Link>
              {length !== index ? <KeyboardArrowRightIcon /> : null}
            </>
          ))}
        </NavigationWrapper>
      ) : (
        // show back button if device is mobile
        <BackButton onClick={handleGoBack}>
          <ArrowBack />
        </BackButton>
      )}
    </>
  );
};

export default BreadCrumb;
