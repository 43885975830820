import React from "react";
// styling
import styled from "styled-components";
// import { flex, textSizes, colors } from '@styles/vars';

// utils
import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  margin-top: 20px;
`;

const InputError = styled.div`
  color: #900;
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const formatError = (message) => {
  return message.length > 0 ? message[0].toUpperCase() + message.slice(1) : "";
};

const ShrinkLabelInput = ({
  title,
  type = "text",
  placeholder,
  value = "",
  isTouched = false,
  helperText,
  error,
  customInput,
  disabled = false,
  required,
  max,
  ...restProps
}) => {
  const label = `${title} ${required ? "*" : ""}`;
  const errorMsg = helperText !== "REQUIRED" ? helperText : "";
  return (
    <Wrapper>
      <TextField
        fullWidth={true}
        label={label}
        variant="outlined"
        value={value}
        size="small"
        disabled={disabled}
        type={type}
        helperText={errorMsg}
        error={error}
        inputProps={{
          max: max,
        }}
        {...restProps}
      />
      {/* {error.length > 0 && <InputError>{formatError(error)}</InputError>} */}
    </Wrapper>
  );
};

ShrinkLabelInput.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "number",
    "datetime-local",
    "date",
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  customInput: PropTypes.element,
  required: PropTypes.bool,
};

export default ShrinkLabelInput;
