import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { getCountryList } from "../../../../services/users";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import {
  Content,
  Heading,
  Wrapper,
  Row,
} from "../../../../components/style/style";

// Employer Detail component receives 'formik' as a prop
const EmployerDetail = ({ formik, handleFormChange }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [countryList, setCountryList] = useState([]);

  // to get data from country list api
  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.isOk) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Grid container>
      <Grid item sm={12}>
        {isEditable ? (
          <Wrapper style={{ padding: "12px" }}>
            <Grid container>
              <Grid item sm={6}>
                <InputField
                  id="employer"
                  name="employer"
                  label="Employer Name"
                  value={formik.values.employer}
                  onChange={handleFormChange}
                  width="97%"
                  helperText={
                    formik.touched.employer ? formik.errors.employer : ""
                  }
                  error={
                    formik.touched.employer && Boolean(formik.errors.employer)
                  }
                  required
                />
              </Grid>
            </Grid>
          </Wrapper>
        ) : (
          <>
            {formik.values.employer && (
              <Wrapper style={{ padding: "12px" }}>
                <Grid container>
                  <Grid item sm={6}>
                    <Content component={"div"}>
                      <Row>
                        <Grid item sm={4}>
                          <InfoLabel
                            label={"Employer Name"}
                            info={formik.values.employer}
                          />
                        </Grid>
                      </Row>
                    </Content>
                  </Grid>
                </Grid>
              </Wrapper>
            )}
          </>
        )}
      </Grid>
      {/* Address Section */}
      <Grid item sm={12}>
        <Wrapper>
          <Heading>Employer Address</Heading>
          <Content component={"div"} style={{ padding: "12px" }}>
            {isEditable ? (
              <Grid container>
                {/* Hidden input for address_id */}
                <input
                  type="hidden"
                  name="address_id"
                  value={formik.values.address_id}
                  onChange={formik.handleChange}
                />
                <Grid item sm={6}>
                  <InputField
                    id="address_line_1"
                    name="address_line_1"
                    label="Address Line 1"
                    value={formik.values.address_line_1}
                    onChange={handleFormChange}
                    helperText={
                      formik.touched.address_line_1
                        ? formik.errors.address_line_1
                        : ""
                    }
                    error={
                      formik.touched.address_line_1 &&
                      Boolean(formik.errors.address_line_1)
                    }
                    required
                    width="97%"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    id="address_line_2"
                    name="address_line_2"
                    label="Address Line 2"
                    value={formik.values.address_line_2}
                    onChange={handleFormChange}
                    helperText={
                      formik.touched.address_line_2
                        ? formik.errors.address_line_2
                        : ""
                    }
                    error={
                      formik.touched.address_line_2 &&
                      Boolean(formik.errors.address_line_2)
                    }
                    width="97%"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    id="city"
                    name="city"
                    label="City"
                    value={formik.values.city}
                    onChange={handleFormChange}
                    helperText={formik.touched.city ? formik.errors.city : ""}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    required
                    width="97%"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    id="state"
                    name="state"
                    label="State"
                    value={formik.values.state}
                    onChange={handleFormChange}
                    helperText={formik.touched.state ? formik.errors.state : ""}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    required
                    width="97%"
                  />
                </Grid>
                <Grid item sm={6}>
                  <SelectDropdown
                    id={"country"}
                    name={"country"}
                    options={countryList}
                    label="Country"
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      if (selected != null)
                        formik.setFieldValue("country", selected.value);
                    }}
                    value={
                      countryList.find(
                        (option) => option.label === formik.values.country
                      ) || ""
                    }
                    helperText={
                      formik.touched.country ? formik.errors.country : ""
                    }
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    required
                    width="97%"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputField
                    id="postal_code"
                    type="number"
                    name="postal_code"
                    label="Postal Code"
                    value={formik.values.postal_code}
                    onChange={handleFormChange}
                    helperText={
                      formik.touched.postal_code
                        ? formik.errors.postal_code
                        : ""
                    }
                    error={
                      formik.touched.postal_code &&
                      Boolean(formik.errors.postal_code)
                    }
                    required
                    width="97%"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                {formik.values.address_line_1 && (
                  <Grid item sm={6}>
                    <InfoLabel
                      label={"Address Line 1"}
                      info={formik.values.address_line_1}
                    />
                  </Grid>
                )}

                {formik.values.address_line_2 && (
                  <Grid item sm={6}>
                    <InfoLabel
                      label={"Address Line 2"}
                      info={formik.values.address_line_2}
                    />
                  </Grid>
                )}

                {formik.values.city && (
                  <Grid item sm={6}>
                    <InfoLabel label={"City"} info={formik.values.city} />
                  </Grid>
                )}

                {formik.values.state && (
                  <Grid item sm={6}>
                    <InfoLabel label={"State"} info={formik.values.state} />
                  </Grid>
                )}

                {formik.values.country && (
                  <Grid item sm={6}>
                    <InfoLabel label={"Country"} info={formik.values.country} />
                  </Grid>
                )}

                {formik.values.postal_code && (
                  <Grid item sm={6}>
                    <InfoLabel
                      label={"Postal Code"}
                      info={formik.values.postal_code}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Content>
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default EmployerDetail;
