import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { breakpoints, colors } from "../../styles/vars";
import ForgotPasswordForm from "./Form";
import ChangeLanguage from "../../components/UI/ChangeLanguage";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${breakpoints.laptop} {
    width: 50%;
  }
`;

const StyledHeader = styled.h1`
  color: ${colors.dark};
`;

const LanguageContainer = styled.div`
  position: absolute;
  left: 95%;
  bottom: 92%;
`;

export default function ForgotPasswordFormContainer() {
  const { t } = useTranslation();
  return (
    <Container>
      <LanguageContainer>
        <ChangeLanguage />
      </LanguageContainer>
      <StyledHeader>{t("Forgot Password")}</StyledHeader>
      <ForgotPasswordForm />
    </Container>
  );
}
