import { protectedApi } from "../api";
import { env } from "../config/env";
import { formatApiResponse } from "../utils/helpers";

// Function to get Lesson List of a course by ID
export const getLessonTimeTableDetail = async (requestParams) => {
  const { id, start_date, end_date, teacher_id, lesson_id } = requestParams;
  console.log("getLessonTimeTableDetail.params", requestParams);
  try {
    let url;
    if (lesson_id && teacher_id) {
      url = `?start_date=${start_date}&end_date=${end_date}&lesson_id=${lesson_id}&teacher_id=${teacher_id}&module=documentation`;
    } else if (teacher_id) {
      url = `?start_date=${start_date}&end_date=${end_date}&teacher_id=${teacher_id}&module=documentation`;
    } else {
      url = `?start_date=${start_date}&end_date=${end_date}`;
    }
    // Make a GET request to retrieve Lesson List of a course by ID
    const response = await protectedApi.get(
      `/planning/course/time-table/${id}${url}`
    );
    console.log("getLessonTimeTableDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getLessonTimeTableDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const addLessonTimeTable = async (requestParams) => {
  const { id } = requestParams;
  const { data } = requestParams;
  console.log("addLessonTimeTable.requestParams", requestParams);
  try {
    // Make a GET request to retrieve Lesson List of a course by ID
    if (id) {
      const response = await protectedApi.post(
        `/planning/course/time-table/${id}`,
        data
      );
      console.log("addLessonTimeTable.response", response);

      // Format and return the API response
      return formatApiResponse(response);
    }
  } catch (e) {
    console.error("addLessonTimeTable.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const setLessonAsDefault = async (requestParams) => {
  const { id } = requestParams;

  console.log("setLessonAsDefault.requestParams", requestParams);
  try {
    // Make a GET request to retrieve Lesson List of a course by ID
    if (id) {
      const response = await protectedApi.get(
        `/planning/course/time-table/${id}/default`
      );
      console.log("setLessonAsDefault.response", response);

      // Format and return the API response
      return formatApiResponse(response);
    }
  } catch (e) {
    console.error("setLessonAsDefault.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const getTeacherListDropdown = async (requestParams) => {
  const { school_id, entity_type, entity_id } = requestParams;

  console.log("getTeacherListDropdown.requestParams", requestParams);
  try {
    let param = `?school_id=${school_id}`;
    if (entity_type !== null && entity_id !== null) {
      param += `&entity_type=${entity_type}&entity_id=${entity_id}`;
    }
    // Make a GET request to retrieve Lesson List of a course by ID
    const response = await protectedApi.get(`/teachers/list${param}`);
    console.log("getTeacherListDropdown.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getTeacherListDropdown.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const checkLessonTeacher = async (requestParams) => {
  const {
    course_id,
    teacher_id,
    day_name,
    start_time,
    end_time,
    start_date,
    end_date,
  } = requestParams;

  console.log("checkLessonTeacher.requestParams", requestParams);
  try {
    // Make a GET request to retrieve Lesson List of a course by ID
    if (teacher_id) {
      const response = await protectedApi.get(
        `/planning/course/time-table/${course_id}/${teacher_id}/check_lesson_teacher?day_name=${day_name}&start_time=${start_time}&end_time=${end_time}&start_date=${start_date}&end_date=${end_date}`
      );
      console.log("checkLessonTeacher.response", response);

      // Format and return the API response
      return formatApiResponse(response);
    }
  } catch (e) {
    console.error("checkLessonTeacher.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};
