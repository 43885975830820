import React, { useState, useEffect } from "react";

import "./App.css";
import useAuth from "./hooks/useAuth";
import AppLayout from "./layouts/AppLayout";
import { SnackbarProvider } from "notistack";
import AuthLayout from "./layouts/AuthLayout";
import SuperAdminLayout from "./layouts/SuperAdminLayout";
import { masterReference } from "./constants/masterData";

// styles
import GlobalStyles from "./styles/global";
import "react-grid-layout/css/styles.css";
import "./fonts/icomoon/style.css";
// import 'swiper/css';
// import 'swiper/css/effect-fade';

// utils
import { ThemeProvider, StyleSheetManager } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
// import { preventDefault } from './utils/helpers';
// import rtlPlugin from "stylis-plugin-rtl";

// contexts
import { SidebarContextAPI } from "./contexts/sidebarContext";

// hooks
import { useInterfaceContext } from "./contexts/interfaceContext";
import { useDispatch } from "react-redux";
import { useLayoutChange } from "./hooks/useLayoutChange";

// actions
import { saveToLocalStorage } from "./store/features/layout";

function App() {
  const page = document.documentElement;
  const { isAuthenticated, user } = useAuth();
  const { isAdminConsole } = useLayoutChange();
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  // alert(isAuthenticated)
  // const { isDarkMode, isContrastMode, direction } = useInterfaceContext();
  // const theme = createTheme({
  //   direction: direction
  // });

  // useDispatch()(saveToLocalStorage());

  // useEffect(() => {
  //   page.setAttribute('dir', direction);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [direction]);

  // useEffect(() => {
  //   isContrastMode && page.classList.add('contrast');
  //   preventDefault();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const [authenticated, setAuthenticated] = useState(
  //   localStorage.getItem("authenticated") || "0"
  // );

  // useEffect(() => {
  //   localStorage.setItem("authenticated", authenticated);
  // }, [authenticated]);

  return (
    <div
      className="App"
      style={{ height: "100vh", width: "-webkit-fill-available" }}
    >
      {/* <MuiThemeProvider theme={theme}> */}
      <ThemeProvider theme={{ theme: "light" }}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
        >
          {/* <SidebarContextAPI> */}
          <GlobalStyles />
          {/* <StyleSheetManager
                stylisPlugins={ [rtlPlugin]}
              > */}
          {isAuthenticated && user ? (
            isAdminConsole &&
            (user?.code?.toLowerCase() === masterReference.SUPER_ADMIN ||
              user?.code?.toLowerCase() === masterReference.ADMIN) ? (
              <SuperAdminLayout />
            ) : (
              <AppLayout />
            )
          ) : (
            <AuthLayout />
          )}
          {/* </StyleSheetManager>  */}
          {/* </SidebarContextAPI> */}
        </SnackbarProvider>
      </ThemeProvider>
      {/* </MuiThemeProvider> */}
    </div>
  );
}

export default App;
