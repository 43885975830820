// styled components
import { Content, Widgets, Wrapper } from "./style";

// components
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import Grid from "../../layout/Grid";
import Panel from "../../layout/Panel";

// utils
import PropTypes from "prop-types";

// hooks

import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useMobileDetect from "use-mobile-detect-hook";
import useAuth from "../../hooks/useAuth";
import useWindowSize from "../../hooks/useWindowSize";

const Page = ({
  title,
  children,
  mobileHideTopBar = false,
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const pageRef = useRef(null);
  const { user } = useAuth();
  const device = useMobileDetect();
  // const { width } = useWindowSize();
  // const isTablet = width > 767.98;

  // get the current location from the router
  const { pathname } = useLocation();
  // detect if the current location is dashboard
  // const isDashboard = pathname.includes('home');
  // modify the path to get corresponding layout from the store
  const layoutKey = pathname.replace("/", "");
  const layouts = useSelector((state) => state["layout"].layout);
  const current = layouts[layoutKey];

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollTop = 0;
    }
  }, [pathname]);

  return (
    <>
      <Content ref={pageRef} mobileHideTopBar={mobileHideTopBar}>
        <Helmet>
          <title>{`WeCareSoft | ${title}`}</title>
        </Helmet>
        <Widgets>
          {current ? (
            <Grid id={layoutKey} layouts={current} desktop={device.isDesktop()}>
              {children}
            </Grid>
          ) : (
            children
          )}
        </Widgets>
      </Content>
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasBadge: PropTypes.bool,
  hasTitle: PropTypes.bool,
  qty: PropTypes.number,
};

export default Page;
