import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { IconButton, MenuItem, Menu, Grid, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  downloadSampleTemplateFile,
  getFileDetail,
} from "../../../services/file";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import useNotistack from "../../../hooks/useNotistack";
import useAuth from "../../../hooks/useAuth";
import { isIterable } from "../../../utils/common";
import { colors } from "../../../constants/colors";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import Student from "./Student";
import Applicant from "./Applicant";
import Teacher from "./Teacher";
import { getEntitiesList } from "../../../services/master";
import { entitiesList } from "../../../constants/masterData";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import { masterReference } from "../../../constants/masterData";
import { toTitleCase } from "../../../utils/utility";

const Title = styled("div")(({ theme }) => ({
  color: `${colors.primary}`,
  display: "inline-flex",
  alignItems: "center",
  width: "100%",
  padding: 10,

  borderBottom: `2px solid ${colors.border}`,
  background: `${colors.background}`,
}));

const Documents = () => {
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();
  const { user } = useAuth();
  const { notify } = useNotistack();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [entitiesNameList, setEntitiesNameList] = useState([]);
  const [entityId, setEntityId] = useState(0);
  const [entityType, setEntityType] = useState("");
  const [companyValue, setCompanyValue] = useState(0);

  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState();
  const [filtersValue, setFiltersValue] = useState({});

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyBtn = (filtersValueArgs) => {
    let tempQueue = "";
    let filtersData;
    if (
      filtersValueArgs?.[Object.keys(filtersValueArgs)[0]] !== "onClick" &&
      filtersValueArgs?.[Object.keys(filtersValueArgs)[0]] &&
      filtersValueArgs?.[Object.keys(filtersValueArgs)[0]].length > 0
    ) {
      filtersData = filtersValueArgs;
    } else {
      filtersData = filtersValue;
    }
    for (const key in filtersData) {
      let temp = `&${key}=`;
      if (Array.isArray(filtersData[key])) {
        temp += filtersData[key].join(",");
      } else {
        temp += filtersData[key];
      }
      tempQueue += temp;
    }

    // setQuery(tempQueue);
    handleFilterClose();
  };

  const handleClear = () => {
    setQuery("");
    setFiltersValue("");
    handleFilterClose();
  };

  const downloadSampleTemplate = () => {
    downloadSampleTemplateFile()
      .then((res) => {
        if (res.isOk) {
          if (res.data?.presigned_url) {
            window.open(res.data?.presigned_url);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]);
      });
  };

  // Get the isEditable flag from a custom hook
  const {
    isDirty,
    isEditable,
    setEditable,
    formSubmitAPI,
    setIsDirty,
    requestId,
    setRequestId,
    isFormSubmitRequested,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const initialValues = {
    student_contract: [],
    student_certificate: [],
    applicant_bill: [],
    teacher_contract: [],
    student_contract_delete: [],
    student_certificate_delete: [],
    applicant_bill_delete: [],
    teacher_contract_delete: [],
  };

  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (
      user.code !== masterReference.HR &&
      user.code !== masterReference.ACCOUNTANT
    ) {
      let requestParams = {
        entityType: "",
        entityId: 0,
      };

      getFileDetail(requestParams)
        .then((res) => {
          if (res.isOk) {
            setRequestId(res.data?.id);

            setSelectedRecord(res.data);
            if (
              res.data.student_contract?.length > 0 ||
              res.data.student_certificate?.length > 0 ||
              res.data.applicant_bill?.length > 0 ||
              res.data.teacher_contract?.length > 0
            ) {
              setEditable(true);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setData([]);
        });
    }
  }, [requestId]);

  useEffect(() => {
    let selectedData = {
      student_contract: [],
      student_certificate: [],
      applicant_bill: [],
      teacher_contract: [],
      student_contract_delete: [],
      student_certificate_delete: [],
      applicant_bill_delete: [],
      teacher_contract_delete: [],
    };
    if (selectedRecord) {
      selectedData["student_contract"] = selectedRecord?.student_contract || [];
      selectedData["student_certificate"] =
        selectedRecord?.student_certificate || [];
      selectedData["applicant_bill"] = selectedRecord?.applicant_bill || [];
      selectedData["teacher_contract"] = selectedRecord?.teacher_contract || [];

      setData(selectedData);
    } else {
      formik.resetForm();
    }
  }, [selectedRecord]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);

    const formData = new FormData();
    formData.append("id", JSON.stringify(requestId ? requestId : 0));
    formData.append("entity_id", JSON.stringify(entityId ? entityId : 0));
    formData.append("entity_type", entityType ? entityType : "");

    values?.student_contract?.map((file, index) => {
      // if (!(file instanceof File)) {
      //   // file = new File([file.name], file.name, {
      //   //   type: file.type,
      //   // });
      //   // values.student_contract.splice(index, 1);
      //   // pass;
      // }
      if (file instanceof File) {
        formData.append("student_contract", file);
      }
    });
    values?.student_certificate?.map((file, index) => {
      if (file instanceof File) {
        formData.append("student_certificate", file);
      }
    });
    values?.applicant_bill?.map((file, index) => {
      if (file instanceof File) {
        formData.append("applicant_bill", file);
      }
    });
    values?.teacher_contract?.map((file, index) => {
      if (file instanceof File) {
        formData.append("teacher_contract", file);
      }
    });

    formData.append(
      "student_contract_delete",
      values.student_contract_delete
        ? JSON.stringify(values.student_contract_delete)
        : JSON.stringify([])
    );
    formData.append(
      "student_certificate_delete",
      values.student_certificate_delete
        ? JSON.stringify(values.student_certificate_delete)
        : JSON.stringify([])
    );
    formData.append(
      "applicant_bill_delete",
      values.applicant_bill_delete
        ? JSON.stringify(values.applicant_bill_delete)
        : JSON.stringify([])
    );
    formData.append(
      "teacher_contract_delete",
      values.teacher_contract_delete
        ? JSON.stringify(values.teacher_contract_delete)
        : JSON.stringify([])
    );

    let requestParams = {
      data: formData,
    };

    // return;
    formSubmitAPI(requestParams).then(async function (res) {
      setIsLoading(false);

      if (res.status === 201) {
        setIsDirty(false);
        await setRequestId(null);
        setRequestId(requestId);
        setSelectedIndex(requestId);
        notify("Document Updated", "success");
        // window.location.reload();
        setFormSubmitRequested(false);
      } else {
        for (const [key, value] of Object.entries(res.error)) {
          if (typeof value === "object" && isIterable(value)) {
            value.forEach((obj) => {
              for (const key2 in obj) {
                if (obj.hasOwnProperty(key2)) {
                  const value2 = obj[key2];
                  for (let i in value2) {
                    notify(`${key2}: ${value2[i]}`, "error");
                  }
                }
              }
            });
          } else {
            notify(`${key}: ${value}`, "error");
          }
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: data,
    // validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
    }
  }, [
    isEditable,
    isFormSubmitRequested,
    formik.handleSubmit,
    setFormSubmitRequested,
  ]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    if (
      user.code === masterReference.HR ||
      user.code === masterReference.ACCOUNTANT
    ) {
      setEntityType(entitiesList[0].value);
    }
  }, []);

  useEffect(() => {
    if (entityType) {
      getEntitiesList({ entity_type: entityType }).then((res) => {
        res.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setEntitiesNameList(res.data);
        setEntityId(res.data[0]["value"]);
      });
    }
  }, [entityType]);

  useEffect(() => {
    if (
      (user.code === masterReference.HR ||
        user.code === masterReference.ACCOUNTANT) &&
      entityId > 0 &&
      entityType?.length > 0
    ) {
      let requestParams = {
        entityType: entityType,
        entityId: entityId,
      };
      console.log(111, requestParams);
      getFileDetail(requestParams)
        .then((res) => {
          if (res.isOk) {
            setRequestId(res.data?.id);
            setData(res.data);
            setSelectedRecord(res.data);
            if (
              res.data.student_contract?.length > 0 ||
              res.data.student_certificate?.length > 0 ||
              res.data.applicant_bill?.length > 0 ||
              res.data.teacher_contract?.length > 0
            ) {
              setEditable(true);
            }
            setFormSubmitRequested(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setData([]);
        });
    }
  }, [query, entityType, entityId]);

  return (
    <>
      {/* ----------------Title section with Lesson and Calendar , Course Filters---------------- */}
      <Title>
        {/* {
          <IconWrapper>
            <InsertDriveFileIcon />
          </IconWrapper>
        }
        <Typography component={"p"} variant="body1">
          File
        </Typography> */}
        {(user.code === masterReference.HR ||
          user.code === masterReference.ACCOUNTANT) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              // marginLeft: "auto",
            }}
          >
            <Tooltip title="Filter">
              <IconButton
                onClick={handleFilterClick}
                size="small"
                // sx={{ ml: 2 }}
                aria-controls={open ? "Filter" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <FilterAltIcon color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        )}

        <Button
          onClick={() => {
            downloadSampleTemplate();
          }}
        >
          View Sample Templates
        </Button>
      </Title>
      <Menu
        id="account-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "300px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" } }}>
          <SelectDropdown
            id={"entity_type"}
            name={"entity_type"}
            options={entitiesList}
            label="Entity Type"
            width="100%"
            onChange={(_, selected) => {
              setEntityType(selected.value);
            }}
            value={
              entitiesList.find((option) => option.value === entityType) ||
              entitiesList[0] ||
              ""
            }
            disableClearable
          />
        </MenuItem>
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" } }}>
          <SelectDropdown
            id={"entity_id"}
            name={"entity_id"}
            options={entitiesNameList}
            label="Entity"
            width="100%"
            onChange={(_, selected) => {
              setEntityId(selected.value);
            }}
            value={
              entitiesNameList.find((option) => option.value === entityId) ||
              entitiesNameList[0] ||
              ""
            }
            disableClearable
          />
        </MenuItem>
        <MenuItem sx={{ "&:hover": { backgroundColor: "inherit" } }}>
          <Grid container justifyContent="space-between" sx={{ marginTop: 1 }}>
            <Button
              variant="outlined"
              onClick={() => {
                handleClear();
              }}
            >
              Clear
            </Button>
            <Button
              variant="filled"
              style={{
                backgroundColor: "#5D87FF",
                color: colors.white,
                marginLeft: 15,
              }}
              onClick={handleApplyBtn}
            >
              Apply
            </Button>
          </Grid>
        </MenuItem>
      </Menu>

      <OverlayLoader show={isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <StyledTab
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.secondary,
            },
          }}
        >
          {user.code !== masterReference.ACCOUNTANT && (
            <TabItem label={"Students"} {...a11yProps(0)} />
          )}
          {user.code !== masterReference.ACCOUNTANT && (
            <TabItem label={"Applicants"} {...a11yProps(1)} />
          )}
          <TabItem
            label={"Teachers"}
            {...a11yProps(user.code !== masterReference.ACCOUNTANT ? 2 : 0)}
          />
        </StyledTab>
        {/* Student Tab Panel */}

        {selectedRecord && data && user.code !== masterReference.ACCOUNTANT && (
          <div
            style={{
              padding: "24px",
              display: activeTab === 0 ? "" : "none",
            }}
            id="simple-tabpanel-0"
            aria-labelledby="simple-tab-0"
          >
            {/* Render the ApplicantCockpit component with formik prop */}

            <Student
              formik={formik}
              isFormSubmitRequested={isFormSubmitRequested}
            />
          </div>
        )}

        {/* Applicant Tab Panel */}
        {user.code !== masterReference.ACCOUNTANT && (
          <div
            style={{
              padding: "24px",
              display: activeTab === 1 ? "" : "none",
            }}
            id="simple-tabpanel-1"
            aria-labelledby="simple-tab-1"
          >
            <Applicant
              formik={formik}
              isFormSubmitRequested={isFormSubmitRequested}
            />
          </div>
        )}
        {/* Teacher Tab Panel */}
        <div
          style={{
            padding: "24px",
            display:
              activeTab === (user.code !== masterReference.ACCOUNTANT ? 2 : 0)
                ? ""
                : "none",
          }}
          id="simple-tabpanel-1"
          aria-labelledby="simple-tab-1"
        >
          <Teacher
            formik={formik}
            isFormSubmitRequested={isFormSubmitRequested}
          />
        </div>
      </form>
    </>
  );
};

export default Documents;
