import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import styled from "styled-components";
import { Wrapper } from "../../components/style/style";
import { breakpoints, flex } from "../../styles/vars";
import FlowStepper from "./FlowStepper";
import Documents from "./Documents";

const StyledDiv = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 50%;
  }
`;

// General component receives 'formik' as a prop
const FlowStepperForm = () => {
  return (
    <>
      <StyledDiv>
        <Wrapper
          style={{
            paddingTop: "25px",
            paddingRight: "50px",
            paddingLeft: "50px",
          }}
        >
          <Container>
            <FlowStepper
              steps={[
                {
                  label: "Course",
                  description: <Documents stageName={"course"} />,
                },
                {
                  label: "Goal Flow",
                  description: <Documents stageName={"flow"} />,
                },
              ]}
            />
          </Container>
        </Wrapper>
      </StyledDiv>
    </>
  );
};

export default FlowStepperForm;
