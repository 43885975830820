import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { colors } from '../../styles/vars';

const MobileAddButton = ({ onClick, Icon = AddIcon, ...rest }) => {
  return (
    <Fab
      sx={{
        position: 'fixed',
        bottom: '10vh',
        right: '2vw',
        // TODO: Add dark theme support
        color: colors.white,
        backgroundColor: '#EE741F'
      }}
      onClick={onClick}
      aria-label="add"
      {...rest}
    >
      <Icon />
    </Fab>
  );
};

export default MobileAddButton;
