import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { styled } from "styled-components";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../constants/colors";
import SelectDropdown from "../../components/UI/SelectDropdown";
import { useRecordSelection } from "../../hooks/useRecordSelection";

const Wrapper = styled.div`
  border: 2px solid ${colors.border};
  margin: 5px;
`;

const Heading = styled(Typography)`
  && {
    margin: 0 4px;
    border-bottom: 2px solid ${colors.border};
    padding: 2px 4px;
    color: ${colors.primary};
    background-color: ${colors.background};
  }
`;

const Content = styled(Typography)`
  && {
    margin: 10px;
    color: ${colors.primary};
  }
  padding-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const options = [
  { label: "Albert James", value: 0 },
  { label: "Carry Wills", value: 1 },
  { label: "Addam Smith", value: 2 },
];

const InvoiceTab = () => {
  const initialValues = {
    dd10: 0,
    dd7: 0,
    dd6: 0,
    dd5: 0,
    dd4: 0,
    dd3: 0,
    dd2: 0,
    dd1: 0,
    chk1: false,
    chk2: false,
    chk3: false,
    chk4: false,
    chk5: false,
    txt1: ``,
  };

  const [data, setData] = useState(initialValues);

  // Custom hook declaration
  const { selectedRecord } = useRecordSelection();

  useEffect(() => {
    setData(initialValues);
  }, []);

  useEffect(() => {
    setData(selectedRecord);
  }, [selectedRecord]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = (values) => {
    // Handle form submission with the selected value
    console.log(values.selectedValue);
  };

  const formik = useFormik({
    initialValues: data,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item sm={12}>
          {/* <Row style={{ marginTop: "5px" }}>
          <Typography style={{ fontWeight: "bold" }}>
            Angenben zuz Benrehcung der Hausbesucbs:
          </Typography>
        </Row> */}
        </Grid>
        <Grid item sm={6}>
          <Wrapper>
            <Heading>Verwaltung</Heading>
            <Content component={"div"}>
              <Row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formik.values.chk1}
                      onChange={formik.handleChange}
                      name="chk1"
                    />
                  }
                  label="Schwerpunsag"
                />
              </Row>
              <Row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formik.values.chk2}
                      onChange={formik.handleChange}
                      name="chk2"
                    />
                  }
                  label="Zuzahlungsbehit won:"
                />
                <SelectDropdown
                  id={"dd1"}
                  name={"dd1"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd1", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd1
                  )}
                  style={{ width: "120px" }}
                />
                <p style={{ marginLeft: "5px" }}>bis: </p>
                <SelectDropdown
                  id={"dd2"}
                  name={"dd2"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd2", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd2
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
              <Row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formik.values.chk3}
                      onChange={formik.handleChange}
                      name="chk3"
                    />
                  }
                  label="Pllegvertrag abgeeshleseen am:"
                />
                <SelectDropdown
                  id={"dd3"}
                  name={"dd3"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd3", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd3
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
              <Row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formik.values.chk4}
                      onChange={formik.handleChange}
                      name="chk4"
                    />
                  }
                  label="Pllegvertrag abgeeshleseen am:"
                />
                <SelectDropdown
                  id={"dd4"}
                  name={"dd4"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd4", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd4
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
              <Row>
                <Typography
                  style={{
                    fontWeight: "bold",
                    margin: "10px 0px 10px 26px",
                  }}
                >
                  Pllegegradantrag ertassen:
                </Typography>
              </Row>
              <Row>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={formik.values.chk5}
                      onChange={formik.handleChange}
                      name="chk5"
                    />
                  }
                  label="Patientenmverfgung ilgit wor seit:"
                />
                <SelectDropdown
                  id={"dd5"}
                  name={"dd5"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd5", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd5
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
            </Content>
          </Wrapper>
        </Grid>
        <Grid item sm={6}>
          <Wrapper>
            <Heading>Abrechnung</Heading>
            <Content component={"div"}>
              <Row>
                <div style={{ marginTop: "5px" }}>
                  Pllegegradantrag ertassen:
                </div>
                <SelectDropdown
                  id={"dd6"}
                  name={"dd6"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd6", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd6
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
              <Row>
                <div style={{ marginTop: "5px" }}>Wohniage:</div>
                <SelectDropdown
                  id={"dd7"}
                  name={"dd7"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd7", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd7
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <Typography style={{ fontWeight: "bold" }}>
                  Angenben zuz Benrehcung der Hausbesucbs:
                </Typography>
              </Row>
              <Row style={{ marginTop: "5px" }}>
                <TextField
                  fullWidth
                  id="outlined-textarea"
                  value={formik.values.txt1}
                  onChange={formik.handleChange}
                  name="txt1"
                  label=""
                  multiline
                  minRows={4}
                  size={"small"}
                />
              </Row>
              <Row>
                <div style={{ marginTop: "5px" }}>Dabitorenkanto:</div>
                <SelectDropdown
                  id={"dd10"}
                  name={"dd10"}
                  options={options}
                  onChange={(_, selected) => {
                    formik.setFieldValue("dd10", selected.value);
                  }}
                  value={options.find(
                    (option) => option.value === formik.values.dd10
                  )}
                  style={{ width: "120px" }}
                />
              </Row>
            </Content>
          </Wrapper>
        </Grid>
      </Grid>
    </form>
  );
};

export default InvoiceTab;
