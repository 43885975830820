export const flex = {
  col: `
        display: flex;
        flex-direction: column;
      `,
  center: `
       align-items: center;
       justify-content: center;
      `,
  between: `
       align-items: center;
       justify-content: space-between;
      `,
  end: `
        align-items: center;
        justify-content: flex-end;
      `,
};

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;