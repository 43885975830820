import React, { useState, useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Grid,
  IconButton,
  Modal,
  Backdrop,
  InputAdornment,
  Divider,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import useAuth from "../../../../hooks/useAuth";
import useNotistack from "../../../../hooks/useNotistack";
import InfoLabel from "../../../../components/UI/InfoLabel";
import DateField from "../../../../components/UI/DateField";
import InputField from "../../../../components/UI/InputField";
import FileField from "../../../../components/UI/FileField";
import { masterReference } from "../../../../constants/masterData";
import RadioBtnGroup from "../../../../components/UI/RadioBtnGroup";
import { getCompaniesListForUser } from "../../../../services/users";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import {
  getDropdownCoursesList,
  getGoalFlow,
} from "../../../../services/schools";
import { entitiesList } from "../../../../constants/masterData";
import { Heading } from "../../../../components/style/style";
import { toTitleCase } from "../../../../utils/utility";
import { colors } from "../../../../constants/colors";
import { isValidFileSize, isValidFileType } from "../../../../utils/utility";
import { downloadS3Object } from "../../../../utils/common";
import { dateFormFormat } from "../../../../utils/dates";

const StyledFileField = styled(FileField)`
  input[type="file"] {
    width: 110px;
    color: transparent;
  }
`;
const StyledInputAdornment = styled(InputAdornment)((props) => ({
  width: "80%",
}));

const StyledCloseButton = styled(Close)(() => ({
  position: "absolute",
  right: -8,
  top: -8,
  backgroundColor: colors.light_gray,
  color: colors.absolute_red,
  borderRadius: "50px",
  cursor: "pointer",
}));

const PreviewIframe = styled("iframe")(() => ({
  height: "100%",
  width: "100%",
  border: 0,
  backgroundColor: colors.white,
}));

const StyledDownloadIcon = styled(DownloadIcon)((props) => ({
  color: colors.primary,
}));

const HeadTypography = styled(Typography)((props) => ({
  fontWeight: "600 !important",
}));

const statusOptions = [
  { label: "Sprachkurs", value: 1 },
  { label: "Ausbildung", value: 2 },
  { label: "Anerkennung", value: 3 },
  { label: "Verpflichtet", value: 4 },
  { label: "Archiv", value: 5 },
];

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const General = ({
  formik,
  handleFormChange,
  schoolList,
  goalsList,
  courseList,
}) => {
  console.log(formik.values);
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  const { user } = useAuth();
  const { notify } = useNotistack();
  const [companyList, setCompanyList] = useState([]);
  const [goalFlowList, setGoalFlowList] = useState([]);
  const [open, setOpen] = useState(false);
  const [documentSrc, setdocumentSrc] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorIndex, setErrorIndex] = useState(0);

  const getFileName = (url) => {
    if (url) {
      if (typeof url === "string") {
        let name = url.split("/").at(-1);

        return (name ?? "").length > 20
          ? (name ?? "").substring(0, 20) + "..."
          : name ?? "";
      } else if (typeof url === "object") {
        let name = url.name;

        return (name ?? "").length > 20
          ? (name ?? "").substring(0, 20) + "..."
          : name ?? "";
      }
    }
  };

  // --------------check if the url of website is valid http------
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  const handleOpen = (url) => {
    setdocumentSrc(url);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleDownload = (url) => {
    if (url) {
      downloadS3Object(url);
    }
  };

  useEffect(() => {
    if (user.code.toLowerCase() === masterReference.SUPER_ADMIN) {
      getCompaniesListForUser().then((company) => {
        setCompanyList(company.data);
        formik.setFieldValue("company_id", company.data[0]?.value);
      });
    }
  }, [user]);

  useEffect(() => {
    if (formik.values.goal_id > 0) {
      let requestParams = {
        goal_id: formik.values.goal_id,
        userType: "student",
      };
      getGoalFlow(requestParams)
        .then((res) => {
          if (res.isOk && res.data.length > 0) {
            setGoalFlowList(res.data);
          } else {
            setGoalFlowList([]);
            formik.setFieldValue("student_goal_flow_guid", null);
            if (
              user.code !== masterReference.HR &&
              user.code !== masterReference.FOREIGN_INSTITUTE_MANAGER &&
              user.code !== masterReference.SCHOOL_MANAGER
            ) {
              notify("Flow not found for this Goal.", "error");
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formik.values.goal_id]);

  return (
    <>
      <Wrapper>
        <Content component="div" style={{ padding: "12px" }}>
          {isEditable ? (
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputField
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      value={formik.values.first_name}
                      onChange={handleFormChange}
                      helperText={
                        formik.touched.first_name
                          ? formik.errors.first_name
                          : ""
                      }
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      required
                      width="98%"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      value={formik.values.last_name}
                      onChange={handleFormChange}
                      width="98%"
                      helperText={
                        formik.touched.last_name ? formik.errors.last_name : ""
                      }
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      required
                    />
                  </Grid>
                  {user.code.toLowerCase() === masterReference.HR && (
                    <Grid item xs={4}>
                      {entitiesList && (
                        <SelectDropdown
                          id={"entity_type"}
                          name={"entity_type"}
                          options={entitiesList}
                          width="98%"
                          label="Entity Type"
                          onChange={(_, selected) => {
                            setIsDirty(true);
                            formik.setFieldValue(
                              "entity_type",
                              selected?.value || ""
                            );
                            formik.setFieldValue("school_id", "");
                            formik.setFieldValue("entity_id", "");
                          }}
                          value={
                            entitiesList.find(
                              (option) =>
                                option.value === formik.values.entity_type
                            ) || ""
                          }
                          helperText={
                            formik.touched.entity_type
                              ? formik.errors.entity_type
                              : ""
                          }
                          error={
                            formik.touched.entity_type &&
                            Boolean(formik.errors.entity_type)
                          }
                          required
                        />
                      )}
                    </Grid>
                  )}
                  {user.code.toLowerCase() === masterReference.HR && (
                    <Grid item xs={4}>
                      {schoolList && (
                        <SelectDropdown
                          id={"school_id"}
                          name={"school_id"}
                          options={schoolList}
                          width="98%"
                          label={
                            formik.values.entity_type === "foreign_institute"
                              ? "Foreign Institute"
                              : "School"
                          }
                          onChange={(_, selected) => {
                            setIsDirty(true);
                            formik.setFieldValue(
                              "school_id",
                              selected?.value || ""
                            );
                            formik.setFieldValue(
                              "entity_id",
                              selected?.value || ""
                            );
                          }}
                          value={
                            schoolList.find(
                              (option) =>
                                option.value === formik.values.entity_id
                            ) || ""
                          }
                          helperText={
                            formik.touched.entity_id
                              ? formik.errors.entity_id
                              : ""
                          }
                          error={
                            formik.touched.entity_id &&
                            Boolean(formik.errors.entity_id)
                          }
                          required
                        />
                      )}
                    </Grid>
                  )}

                  <Grid item xs={4}>
                    <SelectDropdown
                      id={"goal_id"}
                      name={"goal_id"}
                      options={goalsList}
                      width="98%"
                      label="Goal"
                      onChange={(_, selected) => {
                        setIsDirty(true);
                        formik.setFieldValue("goal_id", selected?.value || "");
                      }}
                      value={
                        goalsList.find(
                          (option) => option.value === formik.values.goal_id
                        ) || ""
                      }
                      helperText={
                        formik.touched.goal_id ? formik.errors.goal_id : ""
                      }
                      error={
                        formik.touched.goal_id && Boolean(formik.errors.goal_id)
                      }
                      required
                    />
                  </Grid>
                  {formik.values.goal_id > 0 && (
                    <Grid item xs={4}>
                      <SelectDropdown
                        id={"student_goal_flow_guid"}
                        name={"student_goal_flow_guid"}
                        options={goalFlowList}
                        width="98%"
                        label="Goal Flow"
                        onChange={(_, selected) => {
                          setIsDirty(true);

                          formik.setFieldValue(
                            "student_goal_flow_guid",
                            selected?.value || ""
                          );
                        }}
                        value={
                          goalFlowList.find(
                            (option) =>
                              option.value ===
                              formik.values.student_goal_flow_guid
                          ) || ""
                        }
                        helperText={
                          formik.touched.student_goal_flow_guid
                            ? formik.errors.student_goal_flow_guid
                            : ""
                        }
                        error={
                          (formik.touched.student_goal_flow_guid &&
                            Boolean(formik.errors.student_goal_flow_guid)) ||
                          Boolean(goalFlowList.length <= 0)
                        }
                        required
                      />
                      {goalFlowList.length <= 0 && (
                        <React.Fragment>
                          <Typography
                            style={{
                              color: "red",
                              marginTop: "20px",
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              let goalIndexId = goalsList.findIndex(
                                (option) =>
                                  option.value === formik.values.goal_id
                              );

                              window.location.href =
                                user.code === masterReference.HR
                                  ? `${window.location.origin}/${user.custom_url}/settings/flows/${goalIndexId}/entity_id=${formik.values.entity_id}&entity_type=${formik.values.entity_type}/`
                                  : `${window.location.origin}/${user.company_url}/${user.custom_url}/settings/flows/${goalIndexId}/`;
                            }}
                          >
                            Click To Add Goal Flow
                          </Typography>
                        </React.Fragment>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <SelectDropdown
                      id={"course_id"}
                      name={"course_id"}
                      options={courseList}
                      label="Course"
                      width="98%"
                      onChange={(_, selected) => {
                        setIsDirty(true);

                        formik.setFieldValue(
                          "course_id",
                          selected?.value || ""
                        );
                      }}
                      value={
                        courseList.find(
                          (option) => option.value === formik.values.course_id
                        ) || ""
                      }
                      helperText={
                        formik.touched.course_id ? formik.errors.course_id : ""
                      }
                      error={
                        formik.touched.course_id &&
                        Boolean(formik.errors.course_id)
                      }
                      required={
                        user.code.toLowerCase() === masterReference.HR
                          ? false
                          : true
                      }
                    />
                  </Grid>
                  {/* 
                  <Grid item xs={4}>
                    <SelectDropdown
                      id={"gender"}
                      label="Gender"
                      name={"gender"}
                      options={genderOptions}
                      onChange={(_, selected) => {
                        setIsDirty(true);
                        formik.setFieldValue("gender", selected?.value || "");
                      }}
                      value={
                        genderOptions.find(
                          (option) => option.value === formik.values.gender
                        ) || ""
                      }
                      helperText={
                        formik.touched.gender ? formik.errors.gender : ""
                      }
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      width="98%"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateField
                      label="Date of Birth"
                      id="dob"
                      name="dob"
                      maxDate={dayjs()}
                      value={formik.values.dob}
                      onChange={(date) => {
                        setIsDirty(true);
                        formik.setFieldValue("dob", date);
                      }}
                      helperText={formik.touched.dob ? formik.errors.dob : ""}
                      error={formik.touched.dob && Boolean(formik.errors.dob)}
                      width="98%"
                    />
                  </Grid> */}

                  <Grid item xs={4}>
                    <InputField
                      id="place_of_birth"
                      name="place_of_birth"
                      label="Place of Birth"
                      value={formik.values.place_of_birth}
                      onChange={handleFormChange}
                      width="98%"
                      helperText={
                        formik.touched.place_of_birth
                          ? formik.errors.place_of_birth
                          : ""
                      }
                      error={
                        formik.touched.place_of_birth &&
                        Boolean(formik.errors.place_of_birth)
                      }
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputField
                      id="citizenship"
                      name="citizenship"
                      label="Citizenship"
                      value={formik.values.citizenship}
                      onChange={handleFormChange}
                      width="98%"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="former_citizenship"
                      name="former_citizenship"
                      label="Former Citizenship"
                      value={formik.values.former_citizenship}
                      onChange={handleFormChange}
                      width="98%"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateField
                      label="Date of Change"
                      id="date_of_change"
                      name="date_of_change"
                      maxDate={dayjs()}
                      value={formik.values.date_of_change}
                      onChange={(date) => {
                        setIsDirty(true);
                        formik.setFieldValue("date_of_change", date);
                      }}
                      helperText={
                        formik.touched.date_of_change
                          ? formik.errors.date_of_change
                          : ""
                      }
                      error={
                        formik.touched.date_of_change &&
                        Boolean(formik.errors.date_of_change)
                      }
                      width="98%"
                    />
                  </Grid>

                  {user.code === masterReference.SUPER_ADMIN && (
                    <Grid item xs={4}>
                      <SelectDropdown
                        id={"company_id"}
                        name={"company_id"}
                        options={companyList}
                        label="company"
                        onChange={(_, selected) => {
                          setIsDirty(true);
                          formik.setFieldValue("school_id", "");
                          formik.setFieldValue(
                            "company_id",
                            selected?.value || ""
                          );
                        }}
                        value={
                          companyList.find(
                            (option) =>
                              option.value === formik.values.company_id
                          ) || companyList[0]
                        }
                        helperText={
                          formik.touched?.company_id
                            ? formik.errors?.company_id
                            : ""
                        }
                        error={
                          formik.touched?.company_id &&
                          Boolean(formik.errors?.company_id)
                        }
                        width="98%"
                      />
                    </Grid>
                  )}
                  <Grid item sm={4} xs={6}>
                    <InputField
                      id="current_employer"
                      label="Current Employer"
                      name="current_employer"
                      width="98%"
                      value={formik.values.current_employer}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  {(user.code.toLowerCase() ===
                    masterReference.SCHOOL_MANAGER ||
                    user.code.toLowerCase() ===
                      masterReference.FOREIGN_INSTITUTE_MANAGER) && (
                    <Grid item sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={formik.values.is_access_to_hr}
                            checked={
                              formik.values.is_access_to_hr ? true : false
                            }
                            onChange={(event) => {
                              setIsDirty(true);
                              formik.setFieldValue(
                                "is_access_to_hr",
                                event.target.checked ? true : false
                              );
                            }}
                          />
                        }
                        label="Access to HR"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* <Grid
                item
                xs={12}
                sm={2.5}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={8}>
                    <RadioBtnGroup
                      id="status"
                      name="status"
                      options={statusOptions}
                      defaultValue={formik.values.status}
                      onChange={formik.handleChange}
                      size="small"
                      helperText={
                        formik.touched.status ? formik.errors.status : ""
                      }
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  {formik.values.first_name && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"First Name"}
                        info={formik.values.first_name}
                      />
                    </Grid>
                  )}

                  {formik.values.last_name && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Last Name"}
                        info={formik.values.last_name}
                      />
                    </Grid>
                  )}
                  {user.code.toLowerCase() === masterReference.HR &&
                    formik.values.entity_type && (
                      <Grid item xs={3}>
                        {entitiesList && (
                          <InfoLabel
                            label={"Entity Type"}
                            info={
                              entitiesList.find(
                                (option) =>
                                  option.value === formik.values.entitiesList
                              )?.label || entitiesList[0]?.label
                            }
                          />
                        )}
                      </Grid>
                    )}

                  {user.code.toLowerCase() === masterReference.HR &&
                    formik.values.entity_id && (
                      <Grid item xs={3}>
                        {schoolList && (
                          <InfoLabel
                            label={
                              formik.values.entity_type === "foreign_institute"
                                ? "Foreign Institute"
                                : "School"
                            }
                            info={
                              schoolList.find(
                                (option) =>
                                  option.value === formik.values.entity_id
                              )?.label || schoolList[0]?.label
                            }
                          />
                        )}
                      </Grid>
                    )}

                  {formik.values.goal_id && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Goal"}
                        info={
                          goalsList.find(
                            (option) => option.value === formik.values.goal_id
                          )?.label || goalsList[0]?.label
                        }
                      />
                    </Grid>
                  )}
                  {formik.values.student_goal_flow_guid && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Goal Flow"}
                        info={
                          goalFlowList.find(
                            (option) => option.value === formik.values.flow_id
                          )?.label || goalFlowList[0]?.label
                        }
                      />
                    </Grid>
                  )}
                  {formik.values.course_id && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Course"}
                        info={
                          courseList.find(
                            (option) => option.value == formik.values.course_id
                          )?.label || courseList[0]?.label
                        }
                      />
                    </Grid>
                  )}
                  {/* {formik.values.gender && (
                    <Grid item xs={3}>
                      <InfoLabel label={"Gender"} info={formik.values.gender} />
                    </Grid>
                  )}

                  {formik.values.dob && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Date of Birth"}
                        info={formik.values.dob.format("YYYY-MM-DD")}
                      />
                    </Grid>
                  )} */}

                  {formik.values.place_of_birth && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Place of Birth"}
                        info={formik.values.place_of_birth}
                      />
                    </Grid>
                  )}

                  {formik.values.citizenship && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Citizenship"}
                        info={formik.values.citizenship}
                      />
                    </Grid>
                  )}

                  {formik.values.former_citizenship && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Former Citizenship"}
                        info={formik.values.former_citizenship}
                      />
                    </Grid>
                  )}

                  {formik.values.date_of_change && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Date of Change"}
                        info={dateFormFormat(formik.values.date_of_change)}
                      />
                    </Grid>
                  )}
                  {/* {formik.values.is_access_to_hr && */}
                  {(user.code.toLowerCase() ===
                    masterReference.SCHOOL_MANAGER ||
                    user.code.toLowerCase() ===
                      masterReference.FOREIGN_INSTITUTE_MANAGER) && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"Access to HR"}
                        info={formik.values.is_access_to_hr ? "Yes" : "No"}
                      />
                    </Grid>
                  )}
                  {/* )} */}

                  {/* {formik.values.status_student && (
                    <Grid item xs={3}>
                      {statusOptions && (
                        <InfoLabel
                          label={"Status"}
                          info={
                            statusOptions.find(
                              (option) =>
                                option.value === formik.values.status_student
                            )?.label || statusOptions[0].label
                          }
                        />
                      )}
                    </Grid>
                  )} */}

                  {formik.values.current_employer && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Current Employer"}
                        info={formik.values.current_employer}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Content>
      </Wrapper>

      {formik.values.student_flow_stage_list?.length > 0 &&
        formik.values.student_flow_stage_list.map((ele, idx) => {
          const generalFields = formik.values.student_flow_stage_list?.filter(
            (item) => item.section_name === "general"
          );
          return (
            generalFields.length > 0 &&
            ele.task_count !== ele.field_json.length &&
            ele.section_name === "general" && (
              <Grid container key={idx}>
                <Grid item sm={12} xs={12}>
                  <Wrapper>
                    {/* Show Documents for Contact Information Section */}
                    <Heading>{toTitleCase(ele.sub_stage_name)}</Heading>
                    <Content component={"div"} style={{ padding: "12px" }}>
                      <Grid container spacing={4}>
                        {ele.field_json.map((obj, indexNum) => {
                          return isEditable ? (
                            obj.type === "text_field" ? (
                              <Grid item xs={6} sm={4}>
                                <InputField
                                  label={toTitleCase(obj.field_name).trim()}
                                  variant="outlined"
                                  name={`value`}
                                  width="97%"
                                  value={obj.value}
                                  onChange={(event) => {
                                    setIsDirty(true);
                                    let fieldIndex =
                                      formik.values.student_flow_stage_list[
                                        idx
                                      ].field_json.findIndex(
                                        (temp) =>
                                          temp["field_name"] === obj.field_name
                                      );
                                    formik.setFieldValue(
                                      `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                      event.target.value.replace(/^\s+/g, "")
                                    );
                                  }}
                                />
                              </Grid>
                            ) : obj.type === "dropdown" ? (
                              <Grid item xs={6} sm={4}>
                                <SelectDropdown
                                  label={toTitleCase(obj.field_name).trim()}
                                  width="97%"
                                  name={`value`}
                                  options={obj.choices}
                                  disableClearable
                                  value={
                                    obj.choices.find(
                                      (option) => option === obj.value
                                    ) || ""
                                  }
                                  onChange={(_, selected) => {
                                    setIsDirty(true);
                                    let fieldIndex =
                                      formik.values.student_flow_stage_list[
                                        idx
                                      ].field_json.findIndex(
                                        (temp) =>
                                          temp["field_name"] === obj.field_name
                                      );
                                    formik.setFieldValue(
                                      `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                      selected
                                    );
                                  }}
                                />
                              </Grid>
                            ) : (
                              obj.type === "date_field" && (
                                <>
                                  <Grid item xs={6} sm={4}>
                                    <DateField
                                      label={toTitleCase(obj.field_name).trim()}
                                      name={`value`}
                                      id={`value`}
                                      width="97%"
                                      // maxDate={dayjs()}
                                      value={
                                        obj.value ? dayjs(obj.value) : null
                                      }
                                      onChange={(date) => {
                                        setIsDirty(true);
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                          date
                                        );
                                      }}
                                    />
                                  </Grid>
                                </>
                              )
                            )
                          ) : (
                            <>
                              {obj.type !== "file_field" && (
                                <Grid item xs={3}>
                                  <InfoLabel
                                    label={toTitleCase(obj.field_name)}
                                    info={obj.value}
                                  />
                                </Grid>
                              )}
                            </>
                          );
                        })}
                        <Grid item xs={12} sm={12}></Grid>

                        {ele.field_json?.some(
                          (obj) => obj.type === "file_field"
                        ) &&
                          !isEditable && (
                            <>
                              <Grid item xs={12} sm={12}>
                                <Grid container spacing={4}>
                                  <Grid
                                    item
                                    xs={3}
                                    // style={{ marginLeft: "15px" }}
                                  >
                                    <InfoLabel label={`Files`} />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Grid
                                  container
                                  alignItems="center"
                                  rowSpacing={1}
                                  columnSpacing={5}
                                >
                                  <Grid item sm={4}>
                                    <HeadTypography
                                    // style={{ marginLeft: "20px" }}
                                    >
                                      Document
                                    </HeadTypography>
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    <HeadTypography>Submission</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Start</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Expiry</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Valid</HeadTypography>
                                  </Grid>
                                  <Grid item sm={1.5} align="center">
                                    <HeadTypography>Actions</HeadTypography>
                                  </Grid>
                                  <Grid item sm={12}>
                                    <Divider />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}

                        {ele.field_json.map((obj, indexNum) => {
                          return isEditable
                            ? obj.type === "file_field" && (
                                <>
                                  <Grid item xs={6} sm={3.5}>
                                    <StyledFileField
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border:
                                            obj.value?.length <= 0
                                              ? `2px solid ${colors.error}`
                                              : "",
                                        },
                                      }}
                                      label={toTitleCase(obj.field_name).trim()}
                                      name={`value`}
                                      width="100%"
                                      onChange={(file) => {
                                        if (file) {
                                          if (
                                            !isValidFileType(file, [
                                              "jpeg",
                                              "jpg",
                                              "png",
                                              "pdf",
                                            ])
                                          ) {
                                            formik.setFieldValue(
                                              `student_flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              null
                                            );

                                            setErrorMessage(
                                              "File type should be jpeg, jpg, png or pdf."
                                            );
                                            setErrorIndex(indexNum);
                                            return;
                                          } else if (
                                            !isValidFileSize(
                                              file,
                                              5 * 1024 * 1024
                                            )
                                          ) {
                                            formik.setFieldValue(
                                              `student_flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              null
                                            );

                                            setErrorMessage(
                                              "File size should be less than 5 MB."
                                            );
                                            setErrorIndex(indexNum);
                                            return;
                                          } else {
                                            setIsDirty(true);
                                            formik.setFieldValue(
                                              `student_flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                              file
                                            );

                                            setErrorMessage(""); // Clear error message if file is valid
                                            setErrorIndex(0);
                                          }
                                        }
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <StyledInputAdornment position="start">
                                            {getFileName(obj.value)}
                                          </StyledInputAdornment>
                                        ),
                                      }}
                                      error={
                                        Boolean(errorMessage) &&
                                        errorIndex === indexNum
                                      }
                                    />
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.submission_date ? (
                                      <DateField
                                        label="Submission"
                                        id={`submission_date_value`}
                                        name={`submission_date_value`}
                                        width="100%"
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.submission_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        maxDate={dayjs()}
                                        value={
                                          obj.submission_date_value
                                            ? dayjs(obj.submission_date_value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `student_flow_stage_list.${idx}.field_json.${indexNum}.submission_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Submission
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.start_date ? (
                                      <DateField
                                        label="Issue Date"
                                        id={`start_date_value`}
                                        name={`start_date_value`}
                                        width="100%"
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.start_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        maxDate={dayjs()}
                                        value={
                                          obj.start_date_value
                                            ? dayjs(obj.start_date_value)
                                            : null
                                        }
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `student_flow_stage_list.${idx}.field_json.${indexNum}.start_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Issue
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid item sm={2} align="center">
                                    {obj.expiry_date ? (
                                      <DateField
                                        label="Expiry"
                                        id={`expiry_date_value`}
                                        name={`expiry_date_value`}
                                        width="100%"
                                        value={
                                          obj.expiry_date_value
                                            ? dayjs(obj.expiry_date_value)
                                            : null
                                        }
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.expiry_date_value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        onChange={(date) => {
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `student_flow_stage_list.${idx}.field_json.${indexNum}.expiry_date_value`,
                                            date
                                          );
                                        }}
                                      />
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Expiry
                                      </p>
                                    )}
                                  </Grid>

                                  <Grid item sm={1.5} align="center">
                                    {obj.value && obj.validity_check ? (
                                      <>
                                        <FormControlLabel
                                          label={`Validity`}
                                          style={{ marginTop: "10px" }}
                                          control={
                                            <Checkbox
                                              onChange={(event) => {
                                                event.target.checked
                                                  ? formik.setFieldValue(
                                                      `student_flow_stage_list.${idx}.field_json.${indexNum}.validity_check_value`,
                                                      true
                                                    )
                                                  : formik.setFieldValue(
                                                      `student_flow_stage_list.${idx}.field_json.${indexNum}.validity_check_value`,
                                                      false
                                                    );

                                                setIsDirty(true);
                                              }}
                                              checked={
                                                obj.validity_check_value &&
                                                obj.value
                                                  ? true
                                                  : false
                                              }
                                              // style={{
                                              //   border:
                                              //     !obj.validity_check_value &&
                                              //     "1px solid red",
                                              // }}
                                            ></Checkbox>
                                          }
                                        ></FormControlLabel>
                                      </>
                                    ) : (
                                      <p style={{ marginTop: "20px" }}>
                                        NA - Validity
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    sm={1}
                                    style={{ marginTop: "13px" }}
                                  >
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleDownload(obj.value)
                                        }
                                        size="small"
                                      >
                                        <StyledDownloadIcon
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        aria-label="icon"
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleOpen(obj.value)
                                        }
                                        style={{
                                          position: "absolute",
                                          marginTop: "-1px",
                                        }}
                                      >
                                        <VisibilityIcon
                                          fontSize="small"
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item sm={6}>
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {errorIndex === indexNum
                                        ? errorMessage
                                        : ""}
                                    </p>
                                  </Grid>
                                  <Grid item sm={12}>
                                    <Divider />
                                  </Grid>
                                </>
                              )
                            : obj.type === "file_field" && (
                                <Grid item xs={12} sm={12}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    rowSpacing={1}
                                    columnSpacing={5}
                                  >
                                    <React.Fragment key={indexNum}>
                                      <Grid item sm={4}>
                                        {obj.type === "file_field" ? (
                                          <Typography
                                            value={obj.field_name}
                                            // style={{ marginLeft: "20px" }}
                                          >
                                            {obj.field_name}
                                          </Typography>
                                        ) : (
                                          <Typography value={obj.value}>
                                            {obj.field_name}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item sm={2} align="center">
                                        {obj.submission_date ? (
                                          typeof obj.submission_date_value ===
                                            "string" &&
                                          obj.submission_date_value.length <=
                                            0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(
                                              obj.submission_date_value
                                            )
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.start_date ? (
                                          typeof obj.start_date_value ===
                                            "string" &&
                                          obj.start_date_value.length <= 0 ? (
                                            "Select date"
                                          ) : (
                                            dateFormFormat(obj.start_date_value)
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {
                                          // formik.values[`${ele}_value`] &&
                                          obj.expiry_date ? (
                                            typeof obj.expiry_date_value ===
                                              "string" &&
                                            obj.expiry_date_value.length <=
                                              0 ? (
                                              "Select date"
                                            ) : (
                                              dateFormFormat(
                                                obj.expiry_date_value
                                              )
                                            )
                                          ) : (
                                            <p>NA</p>
                                          )
                                        }
                                      </Grid>
                                      <Grid item sm={1.5} align="center">
                                        {obj.value && obj.validity_check ? (
                                          obj.validity_check_value ? (
                                            "Valid"
                                          ) : (
                                            "Invalid"
                                          )
                                        ) : (
                                          <p>NA</p>
                                        )}
                                      </Grid>

                                      <Grid item sm={1.5}>
                                        {obj.type === "file_field" && (
                                          <IconButton
                                            onClick={() =>
                                              isValidHttpUrl(obj.value) &&
                                              handleDownload(obj.value)
                                            }
                                            size="small"
                                            style={{ marginLeft: "30px" }}
                                          >
                                            <StyledDownloadIcon
                                              style={{
                                                opacity: isValidHttpUrl(
                                                  obj.value
                                                )
                                                  ? 1
                                                  : 0.5,
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                        {obj.type === "file_field" && (
                                          <IconButton
                                            aria-label="icon"
                                            onClick={() =>
                                              isValidHttpUrl(obj.value) &&
                                              handleOpen(obj.value)
                                            }
                                            style={{
                                              position: "absolute",
                                              marginTop: "-1px",
                                            }}
                                          >
                                            <VisibilityIcon
                                              fontSize="small"
                                              style={{
                                                opacity: isValidHttpUrl(
                                                  obj.value
                                                )
                                                  ? 1
                                                  : 0.5,
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  </Grid>
                                </Grid>
                              );
                        })}
                      </Grid>
                    </Content>
                  </Wrapper>
                </Grid>
              </Grid>
            )
          );
        })}
      <Backdrop onClick={handleClose} open={open}>
        <Modal
          onClose={handleClose}
          open={open}
          sx={{
            width: "90%",
            height: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: colors.white,
            boxShadow: 24,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <StyledCloseButton
              fontSize="large"
              onClick={handleClose}
              color="error"
            />
            <PreviewIframe
              id="i_frame"
              src={documentSrc}
              title="document"
            ></PreviewIframe>
          </div>
        </Modal>
      </Backdrop>
    </>
  );
};

export default General;
