import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import dayjs from "dayjs";

import { colors } from "../../../../constants/colors";
import BtnLink from "../../../../components/UI/BtnLink";
import TimePickerInput from "../../../../components/UI/TimePicker";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";

const General = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeGoal = (index, id) => {
    let deleted_data = formik.values.delete_goals;
    if (id) {
      setIsDirty(true);
      deleted_data.push(id);
      formik.setFieldValue("delete_goals", deleted_data);
    }
    let oldGoalsData = [...formik.values.goals];
    oldGoalsData.splice(index, 1);
    formik.setFieldValue(`goals`, oldGoalsData);
  };

  return (
    <>
      <Wrapper>
        <Content style={{ padding: "12px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {isEditable ? (
                <Grid container spacing={2}>
                  {formik.values.goals.map((goal, index) => (
                    <>
                      <Grid
                        item
                        xs={3}
                        justifyContent="center"
                        alignItems="center"
                        style={{ display: "flex" }}
                      >
                        <TextField
                          id={`goal_name${index}`}
                          name={`goal_name`}
                          value={goal.goal_name}
                          onChange={(event) => {
                            setIsDirty(true);
                            formik.setFieldValue(
                              `goals.${index}.goal_name`,
                              event.target.value.trim()
                            );
                            setIsDirty(true);
                          }}
                          variant="outlined"
                          size="small"
                          helperText={
                            formik.errors?.goals && formik.touched.goals
                              ? formik.errors?.goals[index]?.goal_name
                              : ""
                          }
                          error={
                            formik.errors?.goals
                              ? Boolean(
                                  formik.errors?.goals[index]?.goal_name
                                ) && formik.touched.goals
                              : false
                          }
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        alignItems="center"
                        style={{ display: "flex" }}
                      >
                        {formik.values.goals.length > 1 && (
                          <IconButton
                            size={"small"}
                            style={{ backgroundColor: colors.red }}
                            onClick={() =>
                              goal.goal_count
                                ? handleClickOpen()
                                : removeGoal(index, goal.id)
                            }
                          >
                            <DeleteIcon style={{ color: colors.white }} />
                          </IconButton>
                        )}
                      </Grid>
                    </>
                  ))}
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 5 }}
                    justifyContent="flex-end"
                  >
                    <div style={{ padding: "2%" }}>
                      <BtnLink
                        text={t("Add More Goal")}
                        id="addMoreBtn"
                        onClick={() => {
                          formik.setFieldValue(`goals`, [
                            ...formik.values.goals,
                            {
                              id: 0,
                              goal_name: "",
                            },
                          ]);
                        }}
                        style={{
                          backgroundColor: colors.addBtn,
                          color: colors.white,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2} style={{ margin: 20 }}>
                  {formik.values.goals.map((goal, index) => (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <p>{goal.goal_name}</p>
                        </Grid>
                      </Grid>
                      <Divider
                        orientation="horizontal"
                        variant="inset"
                        style={{ margin: 7 }}
                      />
                    </>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Content>
      </Wrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            {t(
              `You are trying to delete a goal which is mapped to an applicant or a student. Please remove the mapping for this goal and try again later.`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("Ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default General;
