import React, { Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { lazy } from "react";
import Login from "../../pages/Login";
import ForgotPassword from "../../pages/ForgotPassword";
import AddPassword from "../../pages/AddPassword";
import ResetPassword from "../../pages/ResetPassword";
import CompanyLogin from "../../pages/CompanyLogin";
import ActivateUser from "../../pages/ActivateUser";

const AuthLayout = () => {
  return (
    <>
      {/* <Router> */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/:url/login" element={<CompanyLogin />} />
          <Route path="/:companyURL/:url/login" element={<CompanyLogin />} />
          <Route path="/add-password" element={<AddPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/:url/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/:companyURL/:url/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/password-reset/:uid/:token/"
            element={<ResetPassword />}
          />
          <Route path="/password-reset" element={<ResetPassword />} />
          {/* <Route path="/404" element={<PageNotFound />} /> */}
          <Route
            path={`/:url`}
            element={
              <Navigate
                to={`/${window.location.pathname.split("/")[1]}/login`}
                replace
              />
            }
          />
          <Route
            path={"/users/activate/:uidb64/:token"}
            element={<ActivateUser sideNavDisplay={false} />}
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Suspense>
      {/* </Router> */}
    </>
  );
};

export default AuthLayout;
