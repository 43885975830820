// import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";

export default function FlowStepper({ steps }) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsObj, setStepsObj] = useState(steps);
  const [continueDisableFlag, setContinueDisableFlag] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();

  const stepperFlagArray = [
    user?.step?.course_count,
    user?.step?.goal_flow_count,
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    // if goal flow has been added show add course step by default
    // if (user?.step?.goal_flow_count > 0 && user?.step?.course_count <= 0) {
    //   setActiveStep(1);
    // }
    // if (user?.step?.goal_flow_count <= 0) {
    //   setContinueDisableFlag(true);
    // }
    if (user?.step?.course_count > 0 && user?.step?.goal_flow_count <= 0) {
      setActiveStep(1);
    }
    if (user?.step?.course_count > 0 && user?.step?.goal_flow_count > 0) {
      setActiveStep(stepsObj.length + 1);
    }
    console.log(stepsObj.length);
    if (user?.step?.course_count <= 0 || user?.step?.goal_flow_count <= 0) {
      setContinueDisableFlag(true);
    }
  }, []);
  return (
    <>
      <Box>
        <Typography>
          <p
            style={{
              fontWeight: 600,
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            {t("Setup Steps For Institute")}
          </p>
        </Typography>
        <br />
        <Typography>
          <b>{t("Note")}:</b> {t("At least 1 Course and 1 Goal Flow need to be added in order to start creating applicants/students as a manager")}.
        </Typography>
        <br />
        <Stepper activeStep={activeStep} orientation="vertical">
          {stepsObj.map((step, index) => {
            const labelProps = {};
            stepperFlagArray?.forEach((ele, idx) => {
              if (ele <= 0 && index === idx) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    {t("Complete the following steps")}
                  </Typography>
                );

                labelProps.error = true;
              }
            });

            // ---------calculate if any field of a stage is missing

            return (
              <Step key={t(step.label)}>
                <StepLabel
                  // {...labelProps}
                  sx={{
                    ".MuiStepIcon-root": {
                      color: labelProps.error ? "red" : "#1976d2",
                    },
                    ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                      {
                        color: labelProps.error ? "red" : "#1976d2",
                      },
                  }}
                >
                  {t(step.label)}
                </StepLabel>
                <StepContent>
                  {step.description}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {/* {index !== stepsObj.length - 1 && ( */}
                      <Button
                        variant="contained"
                        onClick={() => handleNext()}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={continueDisableFlag}
                      >
                        {t("Continue")}
                      </Button>
                      {/* )} */}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        <br />
        {activeStep === stepsObj.length + 1 && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>{t("All steps are completed")} !</Typography>
            {/* <Button onClick={() => handleReset()} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button> */}
            <center>
              <Box sx={{ mt: 10 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.location.href = `/${user.company_url}/${user.custom_url}/data/applicants`;
                    }}
                    sx={{ mt: 1, mr: 1 }}
                    disabled={continueDisableFlag}
                  >
                    {t("Continue")}
                  </Button>
                </div>
              </Box>
            </center>
          </Paper>
        )}
      </Box>
    </>
  );
}
