import React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Close, LensTwoTone } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import NotesIcon from "@mui/icons-material/Notes";
import {
  Grid,
  Backdrop,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Box,
  InputLabel,
  Tooltip,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CardContent from "@mui/material/CardContent";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useNotistack from "../../../../hooks/useNotistack";
import useAuth from "../../../../hooks/useAuth";
import { masterReference } from "../../../../constants/masterData";
import { useRecordSelection } from "../../../../hooks/useRecordSelection";
import {
  colors,
  flex,
  textSizes,
  fonts,
  breakpoints,
} from "../../../../styles/vars";
import { downloadS3Object } from "../../../../utils/common";
import {
  toTitleCase,
  isValidFileSize,
  isValidFileType,
} from "../../../../utils/utility";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { Content, Heading } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import FileField from "../../../../components/UI/FileField";
import DateField from "../../../../components/UI/DateField";
import InputField from "../../../../components/UI/InputField";
import ModalWindow from "../../../../components/UI/ModalWindow";
import LabeledFormInput from "../../../../components/UI/LabeledFormInput";
import { dateFormFormat, notesDateFormat } from "../../../../utils/dates";
import { ModalContent } from "../../../../components/UI/ModalWindow";
import { getDocumentColorStatusList } from "../../../../services/schools";

export const Wrapper = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const styledProps = {
  marginTop: "12px !important",
  width: "100%",
};

export const CustomModalContent = styled(ModalContent)`
  gap: 0px 0px;
  padding: 0px;
  position: relative;
  max-height: 490px;
  height: auto;

  .button-wrapper {
    display: flex;
    gap: 0px 20px;
    justify-content: space-between;
    margin-top: 16px;
  }

  .block {
    &:first-of-type {
      width: 100%;
    }

    ${flex.col};
    gap: 8px;

    .label {
      font-size: ${textSizes["12"]};
      color: ${colors.gray};
      font-family: ${fonts.accent};
    }

    .value {
      display: inline-flex;
      gap: 4px;
      font-size: ${textSizes["14"]};
    }
  }

  ${breakpoints.tablet} {
    min-width: 750px;
    min-height: 250px;
    margin: 0 auto;
  }

  ${breakpoints.mobileL} {
    min-width: 750px;
    min-height: 250px;
    margin: 0 auto;
  }
`;

const Message = styled.span`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  max-width: 750px;
  color: ${colors.dark};
  background-color: ${colors.lightSkyBlue};
`;

const Timer = styled.sub`
  margin-top: 10px;
  text-align: right;
  color: #2d6da0;
  display: flex;
  width: fit-content;
  height: fit-content;
  float: right;
`;

const Header = styled.div`
  width: 100%;
  padding: 12px 10px 5px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  background: ${colors.lightSkyBlue};
`;
const NameContainer = styled.div`
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
`;
const CloseButtonContainer = styled.div`
  margin: 0px;
  color: ${colors.red};
  cursor: pointer;
`;
const NotesBoxContainer = styled.div`
  // margin: '25px 0px';
  // overflow: 'auto !important';
  margin: 10px 0px;
  max-height: 340px;
  scrollbar-width: thin;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const StyledCloseButton = styled(Close)(() => ({
  position: "absolute",
  right: -8,
  top: -8,
  backgroundColor: colors.light_gray,
  color: colors.absolute_red,
  borderRadius: "50px",
  cursor: "pointer",
}));

const PreviewIframe = styled("iframe")(() => ({
  height: "100%",
  width: "100%",
  border: 0,
  backgroundColor: colors.white,
}));

const HeadTypography = styled(Typography)((props) => ({
  fontWeight: "600 !important",
}));

const StyledDownloadIcon = styled(DownloadIcon)((props) => ({
  color: colors.primary,
}));

const StyledInputAdornment = styled(InputAdornment)((props) => ({
  width: "80%",
}));

// Contact Information component receives 'formik' as a prop
const Documents = ({
  formik,
  setContinueFlag,
  setContinueDisableFlag,
  continueDisableFlag,
  checkDisabled,
  stageName,
  isCockpit = false,
}) => {
  const StyledFileField = styled(FileField)`
    input[type="file"] {
      width: ${isCockpit ? "110px" : "120px"};
      margin-left: -20px;
      color: transparent;
    }
  `;

  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [open, setOpen] = useState(false);
  const [documentSrc, setdocumentSrc] = useState(false);
  const [selectedFlowIndex, setSelectedFlowIndex] = useState(0);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(0);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorIndex, setErrorIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [documentStatus, setDocumentStatus] = useState([]);
  const [notes, setNotes] = useState([]);
  const [noteValue, setNoteValue] = useState("");
  const { notify } = useNotistack();
  const [isOneTask, setIsOneTask] = useState(false);

  const saveNotes = () => {
    if (noteValue.length > 0) {
      addNotes();
    }
  };
  const addNotes = () => {
    if (!noteValue) {
      notify("Empty note cannot be Added", "error");
    } else {
      formik.setFieldValue(
        `student_flow_stage_list.${selectedFlowIndex}.field_json.${selectedFieldIndex}.notes`,
        [
          {
            message: noteValue,
            created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          },
          ...formik.values.student_flow_stage_list[selectedFlowIndex][
            "field_json"
          ][selectedFieldIndex]["notes"],
        ]
      );
      setNotes((prevNotes) => [
        {
          message: noteValue,
          created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        },
        ...prevNotes,
      ]);
      setNoteValue([]);
    }
  };
  const handleNoteClose = () => {
    setIsVisible(false);

    // reset note value for that field
    setNotes([]);

    if (!noteValue) {
      formik.setFieldValue(
        `student_flow_stage_list.${selectedFlowIndex}.field_json.${selectedFieldIndex}.notes`,
        selectedRecord.student_flow_stage_list[selectedFlowIndex].field_json[
          selectedFieldIndex
        ].notes
      );
    }
    setNoteValue([]);
  };

  const handleOpen = (url) => {
    setdocumentSrc(url);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleDownload = (url) => {
    if (url) {
      downloadS3Object(url);
    }
  };

  const getFileName = (url) => {
    if (url) {
      let charLength = isCockpit ? 15 : 10;
      if (typeof url === "string") {
        let name = url.split("/").at(-1);

        return (name ?? "").length > charLength
          ? (name ?? "").substring(0, charLength) + "..."
          : name ?? "";
      } else if (typeof url === "object") {
        let name = url.name;

        return (name ?? "").length > charLength
          ? (name ?? "").substring(0, charLength) + "..."
          : name ?? "";
      }
    }
  };

  // --------------check if the url of website is valid http------
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return true;
  }

  const onChangeDocuments = (
    obj,
    stage_name,
    fieldName,
    selectedValue,
    idx,
    fieldIndex
  ) => {
    let flag = "";
    let res = "";
    let selectedField = selectedValue;

    let fieldValue = obj.value;
    let fieldType = obj.type;
    let fieldExpiryDate = obj.expiry_date_value;
    let fieldSubmissionDate = obj.submission_date_value;
    let fieldStartDate = obj.start_date_value;
    let fieldCompletionDate = obj.completion_date_value;
    let fieldTaskCheck = obj.task_check_value;
    let isExpiryDate = obj.expiry_date;
    let isSubmssionDate = obj.submission_date;
    let isStartDate = obj.start_date;
    let isCompletionDate = obj.completion_date;

    let fieldStageFlag =
      formik.values["student_stageFlagList"][`${stage_name}_flag`];

    if (fieldName === "value") {
      fieldValue = selectedField;
    } else if (fieldName === "expiry_date_value") {
      fieldExpiryDate = selectedField;
    } else if (fieldName === "submission_date_value") {
      fieldSubmissionDate = selectedField;
    } else if (fieldName === "start_date_value") {
      fieldStartDate = selectedField;
    } else if (fieldName === "completion_date_value") {
      fieldCompletionDate = selectedField;
    } else if (fieldName === "task_check_value") {
      fieldTaskCheck = selectedField;
    }

    if (fieldType === "date_field") {
      if (
        dateFormFormat(fieldValue) &&
        dateFormFormat(fieldValue) !== "Invalid Date"
      ) {
        flag = true;
      } else {
        flag = false;
      }
    } else if (fieldType === "file_field") {
      if (
        (fieldValue.size
          ? fieldValue.size > 0
          : false || fieldValue.length
          ? fieldValue.length > 0
          : false) &&
        (isExpiryDate
          ? dateFormFormat(fieldExpiryDate) !== "Invalid Date"
            ? true
            : false
          : true) &&
        (isSubmssionDate
          ? dateFormFormat(fieldSubmissionDate) !== "Invalid Date"
            ? true
            : false
          : true) &&
        (isStartDate
          ? dateFormFormat(fieldStartDate) !== "Invalid Date"
            ? true
            : false
          : true) &&
        (isCompletionDate
          ? dateFormFormat(fieldCompletionDate) !== "Invalid Date"
            ? true
            : false
          : true)
      ) {
        flag = true;
      } else {
        flag = false;
      }
    } else if (fieldType === "task") {
      if (
        Boolean(fieldTaskCheck) &&
        (isCompletionDate
          ? dateFormFormat(fieldCompletionDate) !== "Invalid Date"
            ? true
            : false
          : true)
      ) {
        flag = true;
      } else {
        flag = false;
      }
    } else {
      if (fieldValue && fieldValue.length > 0) {
        flag = true;
      } else {
        flag = false;
      }
    }

    res = fieldStageFlag?.map((flag_ele, flagIndex) => {
      return flag_ele?.map((ele, index) => {
        if (idx === flagIndex && fieldIndex === index) {
          if (flag) {
            return true;
          } else {
            return false;
          }
        } else {
          return ele;
        }
      });
    });

    let flagObj = {};

    if (stageName && setContinueFlag) {
      setContinueFlag((prevData) => ({
        ...prevData,

        [`${stage_name}_flag`]: res,
      }));

      flagObj[`${stage_name}_flag`] = res;
    }

    setContinueDisableFlag(checkDisabled(`${stage_name}`, flagObj));
  };

  useEffect(() => {
    getDocumentColorStatusList()
      .then((res) => {
        if (res.isOk) {
          res.data.forEach((item) => {
            item["code"] = item["color_code"];
            item["value"] = item["color_name"];
          });
          setDocumentStatus(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    let filteredStageList = formik.values.student_flow_stage_list.filter(
      (item, index) => item.stage_guid === stageName
    );

    if (filteredStageList?.[0]?.task_count > 0) {
      setIsOneTask(true);
    } else {
      setIsOneTask(false);
    }
  }, [formik.values.student_flow_stage_list]);

  return (
    <>
      {selectedRecord?.[`${stageName}_flag`] || !isCockpit ? (
        <Wrapper style={{ padding: "12px" }}>
          <Content component={"div"}>
            {isOneTask && isCockpit && (
              <>
                <Heading>Checklist Task</Heading>
                <br />
                {/* ----------------------------Checklist Task Table------------------ */}

                <Grid
                  container
                  alignItems="center"
                  rowSpacing={1}
                  columnSpacing={5}
                >
                  {/* <Grid item sm={0.8}>
                  <HeadTypography align="center">Status</HeadTypography>
                </Grid> */}
                  <Grid item sm={isCockpit ? 4.2 : 3}>
                    <HeadTypography>Task</HeadTypography>
                  </Grid>
                  <Grid item sm={0.8}>
                    <HeadTypography align="center">Notes</HeadTypography>
                  </Grid>
                  <Grid item sm={isCockpit ? 2 : 1.8}>
                    <HeadTypography align="center">Completion</HeadTypography>
                  </Grid>

                  <Grid item sm={12}>
                    <Divider />
                  </Grid>
                  {isEditable ? (
                    <>
                      {formik.values.student_flow_stage_list?.length > 0 &&
                        formik.values.student_flow_stage_list?.map((ele, idx) =>
                          ele.field_json
                            .filter((item, index) =>
                              stageName && stageName !== "All"
                                ? ele.stage_guid === stageName
                                : ele.stage_guid !== stageName
                            )
                            .filter((item) => item.type === "task")
                            .map((obj, indexNum) => {
                              return (
                                (!isCockpit || isCockpit) && (
                                  <React.Fragment key={indexNum}>
                                    {/* <Grid item sm={0.8}></Grid> */}
                                    <Grid item sm={isCockpit ? 4.2 : 3}>
                                      <FormControlLabel
                                        sx={{ pt: 1 }}
                                        control={
                                          <Checkbox
                                            onChange={(event) => {
                                              setIsDirty(true);
                                              let fieldIndex =
                                                formik.values.student_flow_stage_list[
                                                  idx
                                                ].field_json.findIndex(
                                                  (temp) =>
                                                    temp["field_name"] ===
                                                    obj.field_name
                                                );

                                              event.target.checked
                                                ? formik.setFieldValue(
                                                    `student_flow_stage_list.${idx}.field_json.${fieldIndex}.task_check_value`,
                                                    true
                                                  )
                                                : formik.setFieldValue(
                                                    `student_flow_stage_list.${idx}.field_json.${fieldIndex}.task_check_value`,
                                                    false
                                                  );
                                              onChangeDocuments(
                                                obj,

                                                ele.stage_guid,
                                                "task_check_value",
                                                event.target.checked,
                                                ele.stage_flag_index,
                                                fieldIndex
                                              );
                                            }}
                                            checked={
                                              formik.values
                                                .student_flow_stage_list[idx]
                                                .field_json[
                                                formik.values.student_flow_stage_list[
                                                  idx
                                                ].field_json.findIndex(
                                                  (temp) =>
                                                    temp["field_name"] ===
                                                    obj.field_name
                                                )
                                              ].task_check_value
                                                ? true
                                                : false
                                            }
                                            style={{
                                              border:
                                                !obj.task_check_value &&
                                                "1px solid red",
                                            }}
                                          ></Checkbox>
                                        }
                                        label={toTitleCase(
                                          obj.field_name
                                        ).trim()}
                                      />

                                      {/* <CockpitTextfield
                                      name={`value`}
                                      label={toTitleCase(obj.field_name).trim()}
                                      value={obj.value}
                                      onTextChange={(targetValue) => {
                                        setIsDirty(true);
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${indexNum}.value`,
                                          targetValue.replace(/^\s+/g, "")
                                        );

                                        onChangeDocuments(
                                          obj,

                                          ele.stage_guid,
                                          "value",
                                          targetValue,
                                          ele.stage_flag_index,
                                          indexNum
                                        );
                                      }}
                                    /> */}
                                    </Grid>
                                    <Grid
                                      item
                                      sm={isCockpit ? 0.8 : 0.5}
                                      align="center"
                                    >
                                      {" "}
                                      <Typography
                                        onClick={() => {
                                          setIsVisible(true);
                                          setNotes(obj.notes);
                                          setSelectedFlowIndex(idx);

                                          setSelectedFieldIndex(
                                            formik.values.student_flow_stage_list[
                                              idx
                                            ].field_json.findIndex(
                                              (temp) =>
                                                temp["field_name"] ===
                                                obj.field_name
                                            )
                                          );
                                        }}
                                      >
                                        <IconButton
                                          shape="round"
                                          size="small"
                                          label="Notes"
                                          style={{
                                            padding: "0px  !important",

                                            color: colors.white,
                                            backgroundColor: colors.yellow,
                                          }}
                                        >
                                          <NotesIcon />
                                        </IconButton>
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      sm={isCockpit ? 2.1 : 2}
                                      align="center"
                                    >
                                      {obj.completion_date ? (
                                        <DateField
                                          id={`completion_date_value`}
                                          name={`completion_date_value`}
                                          width="100%"
                                          value={
                                            obj.completion_date_value
                                              ? dayjs(obj.completion_date_value)
                                              : null
                                          }
                                          sx={{
                                            "& .MuiOutlinedInput-notchedOutline":
                                              {
                                                border:
                                                  !obj.completion_date_value
                                                    ? `2px solid ${colors.error}`
                                                    : "",
                                              },
                                          }}
                                          onChange={(date) => {
                                            let fieldIndex =
                                              formik.values.student_flow_stage_list[
                                                idx
                                              ].field_json.findIndex(
                                                (temp) =>
                                                  temp["field_name"] ===
                                                  obj.field_name
                                              );
                                            setIsDirty(true);
                                            formik.setFieldValue(
                                              `student_flow_stage_list.${idx}.field_json.${fieldIndex}.completion_date_value`,
                                              date
                                            );
                                            onChangeDocuments(
                                              obj,

                                              ele.stage_guid,
                                              "completion_date_value",
                                              date,
                                              ele.stage_flag_index,
                                              fieldIndex
                                            );
                                          }}
                                        />
                                      ) : (
                                        <p>NA</p>
                                      )}
                                    </Grid>

                                    <Grid item sm={0.85}></Grid>
                                    <Grid item sm={4}>
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {errorIndex ===
                                        formik.values.student_flow_stage_list[
                                          idx
                                        ].field_json.findIndex(
                                          (temp) =>
                                            temp["field_name"] ===
                                            obj.field_name
                                        )
                                          ? errorMessage
                                          : ""}
                                      </p>
                                    </Grid>
                                    <Grid item sm={12}>
                                      <Divider />
                                    </Grid>
                                  </React.Fragment>
                                )
                              );
                            })
                        )}
                    </>
                  ) : (
                    <>
                      {formik.values.student_flow_stage_list?.length > 0 &&
                        formik.values.student_flow_stage_list?.map((ele, idx) =>
                          ele.field_json
                            .filter((item, index) =>
                              stageName !== "All"
                                ? ele.stage_guid === stageName
                                : ele.stage_guid !== stageName
                            )
                            .filter((item) => item.type === "task")
                            .map((obj, indexNum) => {
                              return (
                                (!isCockpit || isCockpit) && (
                                  <React.Fragment key={indexNum}>
                                    <Grid
                                      item
                                      sm={isCockpit ? 3.4 : 3}
                                      align="center"
                                    >
                                      <Typography value={obj.value}>
                                        {obj.field_name}
                                      </Typography>
                                    </Grid>
                                    <Grid item sm={0.8}>
                                      <Typography
                                        onClick={() => {
                                          setIsVisible(true);
                                          setNotes(obj.notes);
                                          setSelectedFlowIndex(idx);
                                          setSelectedFieldIndex(
                                            formik.values.student_flow_stage_list[
                                              idx
                                            ].field_json.findIndex(
                                              (temp) =>
                                                temp["field_name"] ===
                                                obj.field_name
                                            )
                                          );
                                        }}
                                      >
                                        <IconButton
                                          shape="round"
                                          size="small"
                                          label="Notes"
                                          style={{
                                            padding: "0px  !important",

                                            marginLeft: "10px",
                                            color: colors.white,
                                            backgroundColor: colors.yellow,
                                          }}
                                        >
                                          <NotesIcon fontSize="small" />
                                        </IconButton>
                                      </Typography>
                                      {/* )} */}
                                    </Grid>
                                    <Grid
                                      item
                                      sm={isCockpit ? 2 : 1.8}
                                      align="center"
                                    >
                                      {obj.completion_date ? (
                                        typeof obj.completion_date_value ===
                                          "string" &&
                                        obj.completion_date_value.length <=
                                          0 ? (
                                          "Select date"
                                        ) : (
                                          dateFormFormat(
                                            obj.completion_date_value
                                          )
                                        )
                                      ) : (
                                        <p>NA</p>
                                      )}
                                    </Grid>

                                    <Grid item sm={12}>
                                      <Divider />
                                    </Grid>
                                  </React.Fragment>
                                )
                              );
                            })
                        )}
                    </>
                  )}
                </Grid>
                <br />
              </>
            )}
            {isCockpit && <Heading>Document Fields</Heading>}
            <br />
            <Grid
              container
              alignItems="center"
              rowSpacing={1}
              columnSpacing={5}
            >
              <Grid item sm={0.8}>
                <HeadTypography align="center">Status</HeadTypography>
              </Grid>
              <Grid item sm={isCockpit ? 3.4 : 3}>
                <HeadTypography align="center">Document</HeadTypography>
              </Grid>
              <Grid item sm={0.8}>
                <HeadTypography align="center">Notes</HeadTypography>
              </Grid>
              <Grid item sm={isCockpit ? 2 : 1.8}>
                <HeadTypography align="center">Submission</HeadTypography>
              </Grid>
              <Grid item sm={isCockpit ? 2 : 1.8}>
                <HeadTypography align="center">Issue</HeadTypography>
              </Grid>
              <Grid item sm={isCockpit ? 2 : 1.8}>
                <HeadTypography align="center">Expiry</HeadTypography>
              </Grid>
              <Grid item sm={1}>
                <HeadTypography align="center">Valid</HeadTypography>
              </Grid>
              {!isCockpit && (
                <Grid item sm={0.7} align="center">
                  <HeadTypography>Actions</HeadTypography>
                </Grid>
              )}
              {/* {!isCockpit && (
                <Grid item sm={1} align="center">
                  <HeadTypography>Download</HeadTypography>
                </Grid>
              )}
              {!isCockpit && (
                <Grid item sm={1} align="center">
                  <HeadTypography>View</HeadTypography>
                </Grid>
              )} */}
              <Grid item sm={12}>
                <Divider />
              </Grid>
              {isEditable ? (
                <>
                  {formik.values.student_flow_stage_list?.length > 0 &&
                    formik.values.student_flow_stage_list?.map((ele, idx) =>
                      ele.field_json
                        .filter((item, index) =>
                          stageName && stageName !== "All"
                            ? ele.stage_guid === stageName
                            : ele.stage_guid !== stageName
                        )
                        .filter((item) => item.type !== "task")
                        .map((obj, indexNum) => {
                          {
                            /* (!isCockpit ||(isCockpit && !formik.values.Documents_flag_array[indexNum])) && ( */
                          }
                          return (
                            (!isCockpit || isCockpit) && (
                              <React.Fragment key={indexNum}>
                                <Grid item sm={0.8}>
                                  <center>
                                    {obj.type === "file_field" && (
                                      <Tooltip
                                        title={
                                          documentStatus.filter(
                                            (item) =>
                                              item.color_code ===
                                              obj.document_color
                                          )?.[0]?.status_name ||
                                          documentStatus.filter(
                                            (item) =>
                                              item.color_code === "#d3d3d3"
                                          )?.[0]?.status_name
                                        }
                                      >
                                        <FormControl>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="document_color"
                                            variant="standard"
                                            value={obj.document_color}
                                            label="Status"
                                            onChange={(event) => {
                                              let fieldIndex =
                                                formik.values.student_flow_stage_list[
                                                  idx
                                                ].field_json.findIndex(
                                                  (temp) =>
                                                    temp["field_name"] ===
                                                    obj.field_name
                                                );
                                              setIsDirty(true);
                                              formik.setFieldValue(
                                                `student_flow_stage_list.${idx}.field_json.${fieldIndex}.document_color`,
                                                event?.target.value || "#d3d3d3"
                                              );
                                            }}
                                            style={{
                                              height: "50%",
                                              marginTop: "5px",
                                              marginRight: "15px",
                                            }}
                                          >
                                            {documentStatus?.map((item) => {
                                              return (
                                                <MenuItem
                                                  value={item.color_code}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        marginTop: "5px",
                                                        marginLeft: "5px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        backgroundColor:
                                                          item.color_code,
                                                        width: 12,
                                                        height: 12,
                                                        borderRadius: "50%",
                                                      }}
                                                    />
                                                  </div>
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Tooltip>
                                    )}
                                  </center>
                                </Grid>
                                <Grid item sm={isCockpit ? 3.4 : 3}>
                                  {obj.type === "file_field" ? (
                                    <>
                                      <StyledFileField
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border:
                                                obj.value?.length <= 0
                                                  ? `2px solid ${colors.error}`
                                                  : "",
                                            },
                                        }}
                                        label={toTitleCase(
                                          obj.field_name
                                        ).trim()}
                                        name={`value`}
                                        width="100%"
                                        onChange={(file) => {
                                          let fieldIndex =
                                            formik.values.student_flow_stage_list[
                                              idx
                                            ].field_json.findIndex(
                                              (temp) =>
                                                temp["field_name"] ===
                                                obj.field_name
                                            );
                                          if (file) {
                                            if (
                                              !isValidFileType(file, [
                                                "jpeg",
                                                "jpg",
                                                "png",
                                                "pdf",
                                              ])
                                            ) {
                                              formik.setFieldValue(
                                                `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                                null
                                              );

                                              setErrorMessage(
                                                "File type should be jpeg, jpg, png or pdf."
                                              );
                                              setErrorIndex(fieldIndex);
                                              return;
                                            } else if (
                                              !isValidFileSize(
                                                file,
                                                5 * 1024 * 1024
                                              )
                                            ) {
                                              formik.setFieldValue(
                                                `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                                null
                                              );

                                              setErrorMessage(
                                                "File size should be less than 5 MB."
                                              );
                                              setErrorIndex(fieldIndex);
                                              return;
                                            } else {
                                              setIsDirty(true);
                                              formik.setFieldValue(
                                                `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                                file
                                              );
                                              onChangeDocuments(
                                                obj,
                                                ele.stage_guid,
                                                "value",
                                                file,
                                                ele.stage_flag_index,
                                                fieldIndex
                                              );
                                              setErrorMessage(""); // Clear error message if file is valid
                                              setErrorIndex(0);
                                            }
                                          }
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <StyledInputAdornment position="start">
                                              {getFileName(obj.value)}
                                            </StyledInputAdornment>
                                          ),
                                        }}
                                        error={
                                          Boolean(errorMessage) &&
                                          errorIndex ===
                                            formik.values.student_flow_stage_list[
                                              idx
                                            ].field_json.findIndex(
                                              (temp) =>
                                                temp["field_name"] ===
                                                obj.field_name
                                            )
                                        }
                                        // helperText={
                                        //   errorIndex === indexNum
                                        //     ? errorMessage
                                        //     : ""
                                        // }
                                      />
                                    </>
                                  ) : obj.type === "text_field" ? (
                                    <InputField
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border:
                                            obj.value.length <= 0
                                              ? `2px solid ${colors.error}`
                                              : "",
                                        },
                                      }}
                                      label={toTitleCase(obj.field_name).trim()}
                                      variant="outlined"
                                      name={`value`}
                                      width="100%"
                                      value={obj.value}
                                      onChange={(event) => {
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        setIsDirty(true);
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                          event.target.value.replace(
                                            /^\s+/g,
                                            ""
                                          )
                                        );

                                        onChangeDocuments(
                                          obj,
                                          // `${ele.stage_name}`
                                          //   .trim()
                                          //   .replace(" ", "_"),
                                          ele.stage_guid,
                                          "value",
                                          event.target.value,
                                          ele.stage_flag_index,
                                          fieldIndex
                                        );
                                      }}
                                    />
                                  ) : obj.type === "dropdown" ? (
                                    <SelectDropdown
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border:
                                            obj.value.length <= 0
                                              ? `2px solid ${colors.error}`
                                              : "",
                                        },
                                      }}
                                      label={toTitleCase(obj.field_name).trim()}
                                      width="100%"
                                      name={`value`}
                                      options={obj.choices}
                                      disableClearable
                                      value={
                                        obj.choices.find(
                                          (option) => option === obj.value
                                        ) || ""
                                      }
                                      onChange={(_, selected) => {
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        setIsDirty(true);
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                          selected
                                        );

                                        onChangeDocuments(
                                          obj,
                                          // `${ele.stage_name}`
                                          //   .trim()
                                          //   .replace(" ", "_"),
                                          ele.stage_guid,
                                          "value",
                                          selected,
                                          ele.stage_flag_index,
                                          fieldIndex
                                        );
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <DateField
                                        sx={{
                                          "& .MuiOutlinedInput-notchedOutline":
                                            {
                                              border: !obj.value
                                                ? `2px solid ${colors.error}`
                                                : "",
                                            },
                                        }}
                                        label={toTitleCase(
                                          obj.field_name
                                        ).trim()}
                                        name={`value`}
                                        id={`value`}
                                        width="100%"
                                        // maxDate={dayjs()}
                                        value={
                                          obj.value ? dayjs(obj.value) : null
                                        }
                                        onChange={(date) => {
                                          let fieldIndex =
                                            formik.values.student_flow_stage_list[
                                              idx
                                            ].field_json.findIndex(
                                              (temp) =>
                                                temp["field_name"] ===
                                                obj.field_name
                                            );
                                          setIsDirty(true);
                                          formik.setFieldValue(
                                            `student_flow_stage_list.${idx}.field_json.${fieldIndex}.value`,
                                            date
                                          );
                                          onChangeDocuments(
                                            obj,
                                            // `${ele.stage_name}`

                                            //   .trim()
                                            //   .replaceAll(" ", "_"),
                                            ele.stage_guid,
                                            "value",
                                            date,
                                            ele.stage_flag_index,
                                            fieldIndex
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 0.8 : 0.5}
                                  align="center"
                                >
                                  {/* {obj.type === "file_field" && ( */}
                                  <Typography
                                    onClick={() => {
                                      setIsVisible(true);
                                      setNotes(obj.notes);
                                      setSelectedFlowIndex(idx);
                                      setSelectedFieldIndex(
                                        formik.values.student_flow_stage_list[
                                          idx
                                        ].field_json.findIndex(
                                          (temp) =>
                                            temp["field_name"] ===
                                            obj.field_name
                                        )
                                      );
                                    }}
                                  >
                                    <IconButton
                                      shape="round"
                                      size="small"
                                      label="Notes"
                                      style={{
                                        padding: "0px  !important",
                                        color: colors.white,
                                        backgroundColor: colors.yellow,
                                      }}
                                    >
                                      <NotesIcon fontSize="small" />
                                    </IconButton>
                                  </Typography>
                                  {/* )} */}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 2.1 : 2}
                                  align="center"
                                >
                                  {obj.submission_date ? (
                                    <DateField
                                      id={`submission_date_value`}
                                      name={`submission_date_value`}
                                      width="100%"
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: !obj.submission_date_value
                                            ? `2px solid ${colors.error}`
                                            : "",
                                        },
                                      }}
                                      maxDate={dayjs()}
                                      value={
                                        obj.submission_date_value
                                          ? dayjs(obj.submission_date_value)
                                          : null
                                      }
                                      onChange={(date) => {
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        setIsDirty(true);
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${fieldIndex}.submission_date_value`,
                                          date
                                        );
                                        onChangeDocuments(
                                          obj,
                                          // `${ele.stage_name}`

                                          //   .trim()
                                          //   .replaceAll(" ", "_"),
                                          ele.stage_guid,
                                          "submission_date_value",
                                          date,
                                          ele.stage_flag_index,
                                          fieldIndex
                                        );
                                      }}
                                    />
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 2.1 : 2}
                                  align="center"
                                >
                                  {obj.start_date ? (
                                    <DateField
                                      id={`start_date_value`}
                                      name={`start_date_value`}
                                      width="100%"
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: !obj.start_date_value
                                            ? `2px solid ${colors.error}`
                                            : "",
                                        },
                                      }}
                                      maxDate={dayjs()}
                                      value={
                                        obj.start_date_value
                                          ? dayjs(obj.start_date_value)
                                          : null
                                      }
                                      onChange={(date) => {
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        setIsDirty(true);
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${fieldIndex}.start_date_value`,
                                          date
                                        );
                                        onChangeDocuments(
                                          obj,
                                          ele.stage_guid,
                                          "start_date_value",
                                          date,
                                          ele.stage_flag_index,
                                          fieldIndex
                                        );
                                      }}
                                    />
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 2.1 : 2}
                                  align="center"
                                >
                                  {obj.expiry_date ? (
                                    // formik.values[`${ele}_expiry_date_value`] &&
                                    <DateField
                                      id={`expiry_date_value`}
                                      name={`expiry_date_value`}
                                      width="100%"
                                      // maxDate={dayjs()}
                                      value={
                                        obj.expiry_date_value
                                          ? dayjs(obj.expiry_date_value)
                                          : null
                                      }
                                      sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border: !obj.expiry_date_value
                                            ? `2px solid ${colors.error}`
                                            : "",
                                        },
                                      }}
                                      onChange={(date) => {
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        setIsDirty(true);
                                        formik.setFieldValue(
                                          `student_flow_stage_list.${idx}.field_json.${fieldIndex}.expiry_date_value`,
                                          date
                                        );
                                        onChangeDocuments(
                                          obj,
                                          // `${ele.stage_name}`

                                          //   .trim()
                                          //   .replaceAll(" ", "_"),
                                          ele.stage_guid,
                                          "expiry_date_value",
                                          date,
                                          ele.stage_flag_index,
                                          fieldIndex
                                        );
                                      }}
                                    />
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                <Grid item sm={isCockpit ? 0.7 : 0.6}>
                                  {obj.value && obj.validity_check ? (
                                    <Checkbox
                                      size="large"
                                      style={{ marginLeft: "-25px" }}
                                      onChange={(event) => {
                                        let fieldIndex =
                                          formik.values.student_flow_stage_list[
                                            idx
                                          ].field_json.findIndex(
                                            (temp) =>
                                              temp["field_name"] ===
                                              obj.field_name
                                          );
                                        event.target.checked
                                          ? formik.setFieldValue(
                                              `student_flow_stage_list.${idx}.field_json.${fieldIndex}.validity_check_value`,
                                              true
                                            )
                                          : formik.setFieldValue(
                                              `student_flow_stage_list.${idx}.field_json.${fieldIndex}.validity_check_value`,
                                              false
                                            );
                                        onChangeDocuments(
                                          obj,
                                          // `${ele.stage_name}`

                                          //   .trim()
                                          //   .replaceAll(" ", "_"),
                                          ele.stage_guid,
                                          "validity_check_value",
                                          event.target.checked,
                                          ele.stage_flag_index,
                                          fieldIndex
                                        );
                                        setIsDirty(true);
                                      }}
                                      checked={
                                        obj.validity_check_value && obj.value
                                          ? true
                                          : false
                                      }
                                    ></Checkbox>
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                {!isCockpit && (
                                  <Grid item sm={1}>
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleDownload(obj.value)
                                        }
                                        size="small"
                                      >
                                        <StyledDownloadIcon
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        aria-label="icon"
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleOpen(obj.value)
                                        }
                                        style={{
                                          position: "absolute",
                                          marginTop: "-1px",
                                        }}
                                      >
                                        <VisibilityIcon
                                          fontSize="small"
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                )}
                                <Grid item sm={0.85}></Grid>
                                <Grid item sm={4}>
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {errorIndex ===
                                    formik.values.student_flow_stage_list[
                                      idx
                                    ].field_json.findIndex(
                                      (temp) =>
                                        temp["field_name"] === obj.field_name
                                    )
                                      ? errorMessage
                                      : ""}
                                  </p>
                                </Grid>
                                <Grid item sm={12}>
                                  <Divider />
                                </Grid>
                              </React.Fragment>
                            )
                          );
                        })
                    )}
                </>
              ) : (
                <>
                  {formik.values.student_flow_stage_list?.length > 0 &&
                    formik.values.student_flow_stage_list?.map((ele, idx) =>
                      ele.field_json
                        .filter((item, index) =>
                          stageName !== "All"
                            ? ele.stage_guid === stageName
                            : ele.stage_guid !== stageName
                        )
                        .filter((item) => item.type !== "task")
                        .map((obj, indexNum) => {
                          {
                            /* (!isCockpit ||(isCockpit && obj.type === "file_field" && (!obj.value || !obj.validity_check_value)) || (isCockpit && !obj.value)) && ( */
                          }
                          {
                            /* (!isCockpit ||(isCockpit && !formik.values.Documents_flag_array[indexNum])) && ( */
                          }
                          return (
                            (!isCockpit || isCockpit) && (
                              <React.Fragment key={indexNum}>
                                <Grid item sm={0.8}>
                                  {obj.type === "file_field" && (
                                    <Tooltip
                                      title={
                                        documentStatus.filter(
                                          (item) =>
                                            item.color_code ===
                                            obj.document_color
                                        )?.[0]?.status_name
                                      }
                                    >
                                      <span>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginRight: "10px",
                                              display: "flex",
                                              alignItems: "center",
                                              backgroundColor:
                                                obj.document_color,
                                              width: 12,
                                              height: 12,
                                              borderRadius: "50%",
                                            }}
                                          />
                                        </div>
                                      </span>
                                    </Tooltip>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 3.4 : 3}
                                  align="center"
                                >
                                  {obj.type === "file_field" ? (
                                    <Typography value={obj.field_name}>
                                      {obj.field_name}
                                    </Typography>
                                  ) : (
                                    <Typography value={obj.value}>
                                      {obj.field_name}
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item sm={0.8}>
                                  {/* {obj.type === "file_field" && ( */}
                                  <Typography
                                    onClick={() => {
                                      setIsVisible(true);
                                      setNotes(obj.notes);
                                      setSelectedFlowIndex(idx);
                                      setSelectedFieldIndex(
                                        formik.values.student_flow_stage_list[
                                          idx
                                        ].field_json.findIndex(
                                          (temp) =>
                                            temp["field_name"] ===
                                            obj.field_name
                                        )
                                      );
                                    }}
                                  >
                                    <IconButton
                                      shape="round"
                                      size="small"
                                      label="Notes"
                                      style={{
                                        padding: "0px  !important",

                                        marginLeft: "10px",
                                        color: colors.white,
                                        backgroundColor: colors.yellow,
                                      }}
                                    >
                                      <NotesIcon fontSize="small" />
                                    </IconButton>
                                  </Typography>
                                  {/* )} */}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 2 : 1.8}
                                  align="center"
                                >
                                  {obj.submission_date ? (
                                    typeof obj.submission_date_value ===
                                      "string" &&
                                    obj.submission_date_value.length <= 0 ? (
                                      "Select date"
                                    ) : (
                                      dateFormFormat(obj.submission_date_value)
                                    )
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 2 : 1.8}
                                  align="center"
                                >
                                  {obj.start_date ? (
                                    typeof obj.start_date_value === "string" &&
                                    obj.start_date_value.length <= 0 ? (
                                      "Select date"
                                    ) : (
                                      dateFormFormat(obj.start_date_value)
                                    )
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 2 : 1.8}
                                  align="center"
                                >
                                  {obj.expiry_date ? (
                                    typeof obj.expiry_date_value === "string" &&
                                    obj.expiry_date_value.length <= 0 ? (
                                      "Select date"
                                    ) : (
                                      dateFormFormat(obj.expiry_date_value)
                                    )
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  sm={isCockpit ? 0.7 : 1}
                                  align="center"
                                >
                                  {obj.value && obj.validity_check ? (
                                    obj.validity_check_value ? (
                                      "Valid"
                                    ) : (
                                      "Invalid"
                                    )
                                  ) : (
                                    <p>NA</p>
                                  )}
                                </Grid>
                                {!isCockpit && (
                                  <Grid item sm={1}>
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleDownload(obj.value)
                                        }
                                        size="small"
                                      >
                                        <StyledDownloadIcon
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                    {obj.type === "file_field" && (
                                      <IconButton
                                        aria-label="icon"
                                        onClick={() =>
                                          isValidHttpUrl(obj.value) &&
                                          handleOpen(obj.value)
                                        }
                                        style={{
                                          position: "absolute",
                                          marginTop: "-1px",
                                        }}
                                      >
                                        <VisibilityIcon
                                          fontSize="small"
                                          style={{
                                            opacity: isValidHttpUrl(obj.value)
                                              ? 1
                                              : 0.5,
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </Grid>
                                )}

                                <Grid item sm={12}>
                                  <Divider />
                                </Grid>
                              </React.Fragment>
                            )
                          );
                        })
                    )}
                </>
              )}
            </Grid>
          </Content>
        </Wrapper>
      ) : (
        <p>All Documents Submitted for this Stage</p>
      )}
      <Backdrop onClick={handleClose} open={open}>
        <Modal
          onClose={handleClose}
          open={open}
          sx={{
            width: "90%",
            height: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: colors.white,
            boxShadow: 24,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <StyledCloseButton
              fontSize="large"
              onClick={handleClose}
              color="error"
            />
            <PreviewIframe
              id="i_frame"
              src={documentSrc}
              title="document"
            ></PreviewIframe>
          </div>
        </Modal>
      </Backdrop>

      {/* Notes modal for document notes */}

      <ModalWindow isVisible={isVisible} visibilityHandler={setIsVisible}>
        <CustomModalContent style={{ fontSize: "16px" }}>
          <Header>
            <NameContainer>Add Notes</NameContainer>
            <NameContainer></NameContainer>
            <CloseButtonContainer onClick={handleNoteClose}>
              <CloseOutlinedIcon />
            </CloseButtonContainer>
          </Header>
          <CardContent
            sx={{
              padding: "14px",
              paddingBottom: "0px !important",
              height: "auto",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              position: "relative",
            }}
            // style={{ marginTop: notes.length === 0 ? 60 : 0 }}
          >
            {user.code === masterReference.SCHOOL_TEACHER ||
            user.code === masterReference.FOREIGN_INSTITUTE_TEACHER ? null : (
              <div style={{ display: "flex", paddingBottom: "10px" }}>
                <LabeledFormInput
                  id="note"
                  name="note"
                  placeholder="Notes*"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  onChange={(event) => {
                    setIsDirty(true);
                    setNoteValue(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      saveNotes();
                    }
                  }}
                  value={noteValue}
                  styledProps={styledProps}
                  style={{
                    width: "100% !important",
                  }}
                  required
                />

                <Button
                  variant="contained"
                  color="success"
                  style={{
                    minWidth: "0px",
                    marginTop: "18px",
                    marginLeft: "6px",
                    fontSize: "14px",
                    height: "26px",
                    width: "60px",
                    textTransform: "none",
                    color: colors.white,
                    background: colors.blue,
                    borderRadius: "6px",
                  }}
                  onClick={() => saveNotes()}
                >
                  Add
                </Button>
              </div>
            )}

            {notes.length !== 0 ? (
              <NotesBoxContainer>
                {notes.map((note, index) => (
                  <Message key={index}>
                    <div>
                      {note.message}
                      <Timer>{notesDateFormat(note.created_at)}</Timer>
                    </div>
                  </Message>
                ))}
              </NotesBoxContainer>
            ) : (
              <NotesBoxContainer></NotesBoxContainer>
            )}
          </CardContent>
        </CustomModalContent>
      </ModalWindow>
    </>
  );
};

export default Documents;
