import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Divider from '@mui/material/Divider';
import dayjs from "dayjs";

import { colors } from "../../../../constants/colors";
import BtnLink from "../../../../components/UI/BtnLink";
import TimePickerInput from "../../../../components/UI/TimePicker";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";

const General = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  const removeLesson = (index, id) => {
    let deleted_data = formik.values.deleted_lessons;
    if (id) {
      setIsDirty(true);
      deleted_data.push(id);
      formik.setFieldValue('deleted_lessons', deleted_data);
    }
    let oldExamData = [...formik.values.lessons];
    oldExamData.splice(index, 1);
    formik.setFieldValue(`lessons`, oldExamData);
  };

  return (
    <Wrapper>
      <Content style={{ padding: "12px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {isEditable ? (
              <Grid container spacing={2}>
                <Grid item xs={3}><center><strong>Lesson Name</strong></center></Grid>
                <Grid item xs={4}><center><strong>Start Time</strong></center></Grid>
                <Grid item xs={4} style={{ paddingLeft: 40 }}><strong>End Time</strong></Grid>
                <Grid item xs={1}>{formik.values.lessons.length > 1 && <strong>Delete</strong>}</Grid>
                {formik.values.lessons.map((lesson, index) =>
                  <>
                    <Grid item xs={3} justifyContent="center" alignItems="center" style={{ display: 'flex' }}>
                      <TextField
                        id={`lesson_name${index}`}
                        name={`lesson_name`}
                        value={lesson.lesson_name}
                        onChange={(event) => {
                          setIsDirty(true);
                          formik.setFieldValue(
                            `lessons.${index}.lesson_name`,
                            event.target.value.trim()
                          );
                          setIsDirty(true);
                        }}
                        variant="outlined"
                        size="small"
                        helperText={formik.errors?.lessons && formik.touched.lessons ? formik.errors?.lessons[index]?.lesson_name : ""}
                        error={formik.errors?.lessons ? Boolean(formik.errors?.lessons[index]?.lesson_name) && formik.touched.lessons : false}
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <center>
                        <TimePickerInput
                          name='start_time'
                          id='start_time'
                          value={lesson.start_time}
                          onChange={(e) => { setIsDirty(true); formik.setFieldValue(`lessons.${index}.start_time`, e) }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": { border: 'none' },
                            width: '40%',
                          }}
                          helperText={formik.errors?.lessons && formik.touched.lessons ? formik.errors?.lessons[index]?.start_time : ""}
                          error={formik.errors?.lessons ? Boolean(formik.errors?.lessons[index]?.start_time) && formik.touched.lessons : false}
                        />
                      </center>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: 0 }}>
                      <TimePickerInput
                        name='end_time'
                        id='end_time'
                        value={lesson.end_time}
                        onChange={(e) => { setIsDirty(true); formik.setFieldValue(`lessons.${index}.end_time`, e) }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": { border: 'none' },
                          width: '40%',
                        }}
                        helperText={formik.errors?.lessons && formik.touched.lessons ? formik.errors?.lessons[index]?.end_time : ""}
                        error={formik.errors?.lessons ? Boolean(formik.errors?.lessons[index]?.end_time) && formik.touched.lessons : false}
                      />
                    </Grid>
                    <Grid item xs={1} alignItems="center" style={{ display: 'flex' }}>
                      {formik.values.lessons.length > 1 &&
                        <IconButton size={"small"} style={{ backgroundColor: colors.red }} onClick={() => removeLesson(index, lesson.id)}>
                          <DeleteIcon style={{ color: colors.white }} />
                        </IconButton>
                      }
                    </Grid>
                  </>
                )}
                <Grid container spacing={2} sx={{marginTop: 5}} justifyContent="flex-end">
                  <div style={{ padding: "2%" }}>
                    <BtnLink
                      text="Add More Lesson"
                      id="addMoreBtn"
                      onClick={() => {
                        formik.setFieldValue(
                          `lessons`,
                          [
                            ...formik.values.lessons,
                            {
                              id: 0,
                              lesson_name: `Lesson ${formik.values.lessons.length + 1}`,
                              start_time: '',
                              end_time: '',
                            },
                          ]
                        );
                      }}
                      style={{ backgroundColor: colors.addBtn, color: colors.white }}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} style={{ margin: 20 }}>
                <Grid container spacing={2}>
                  <Grid item xs={2}><strong>Lesson Name</strong></Grid>
                  <Grid item xs={2}><strong>Start Time</strong></Grid>
                  <Grid item xs={2} style={{ paddingLeft: 0 }}><strong>End Time</strong></Grid>
                </Grid>
                <Divider orientation="horizontal" variant="inset" style={{ margin: 12 }} />
                {formik.values.lessons.map((lesson, index) =>
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <p>{lesson.lesson_name}</p>
                      </Grid>
                      {lesson.start_time &&
                        <Grid item xs={2}>
                          <p>{dayjs(lesson.start_time, "HH:mm:ss").format("hh:mm a")}</p>
                        </Grid>
                      }
                      {lesson.end_time &&
                        <Grid item xs={2} style={{ paddingLeft: 0 }}>
                          <p>{dayjs(lesson.end_time, "HH:mm:ss").format("hh:mm a")}</p>
                        </Grid>
                      }
                    </Grid>
                    <Divider orientation="horizontal" variant="inset" style={{ margin: 7 }} />
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Content>
    </Wrapper>
  );
};

export default General;
