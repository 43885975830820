import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/images/login-background.jpg";
import FormContainer from "../widgets/ResetPassword";

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 100vh;
  background-size: cover;
  background-repeat: none;
  background-image: url(${backgroundImage});
`;

export default function ResetPassword() {
  return (
    <RootWrapper>
      {/* <ImageContainer /> */}
      <FormContainer />
    </RootWrapper>
  );
}
