import { colors, flex, textSizes, breakpoints, fonts } from '../../styles/vars';
import styled from 'styled-components';
// import { ModalContent } from '@components/ModalWindow';

// export const StatusModalContent = styled(ModalContent)`
//   flex-wrap: wrap;
//   gap: 16px 30px;
//   position: relative;

//   .button-wrapper {
//     display: flex;
//     gap: 0px 20px;
//     justify-content: space-between;
//     margin-top: 16px;
//   }

//   .block {
//     &:first-of-type {
//       width: 100%;
//     }

//     ${flex.col};
//     gap: 8px;

//     .label {
//       font-size: ${textSizes['12']};
//       color: ${colors.gray};
//       font-family: ${fonts.accent};
//     }

//     .value {
//       display: inline-flex;
//       gap: 4px;
//       font-size: ${textSizes['14']};
//     }
//   }

//   ${breakpoints.tablet} {
//     max-width: 450px;
//     margin: 0 auto;
//   }
// `;
export const NoWrap = styled.span`
  white-space: nowrap;
`;
