import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, TextField, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { colors } from "../../../../constants/colors";
import BtnLink from "../../../../components/UI/BtnLink";
import DateField from "../../../../components/UI/DateField";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import { dateFormFormat } from "../../../../utils/dates";

const ImportantDates = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { t } = useTranslation();

  function CustomTextField(props) {
    const { trulyAnError, ...other } = props;
    return React.createElement(TextField, {
      ...other,
      error: trulyAnError || props.error,
    });
  }

  const addMoreHandler = () => {
    formik.setFieldValue("important_dates", [
      ...formik.values.important_dates,
      {
        id: 0,
        start_date: null,
        end_date: null,
        description: "",
      },
    ]);
  };

  const importantDateDelete = (index) => {
    const deleted_data = formik.values.delete_important_dates;
    if (formik.values.important_dates[index].id !== 0) {
      deleted_data.push(formik.values.important_dates[index].id);
      formik.setFieldValue("delete_important_dates", deleted_data);
    }
    let oldImportantDates = [...formik.values.important_dates];
    oldImportantDates.splice(index, 1);
    formik.setFieldValue(`important_dates`, oldImportantDates);
    setIsDirty(true);
  };

  return (
    <>
      {isEditable ? (
        <Wrapper style={{ padding: "12px" }}>
          <Content component={"div"}>
            <Grid container>
              {formik.values.important_dates.map((item, index) => (
                <Grid container spacing={2} key={`dates${index}`}>
                  {/* Render editable form fields when isEditable is true */}
                  <Grid item xs={12} sm={3}>
                    <DateField
                      label={t("Start Date")}
                      value={item.start_date}
                      name={`start_date`}
                      onChange={(date) => {
                        formik.setFieldValue(
                          `important_dates.${index}.start_date`,
                          date
                        );
                        setIsDirty(true);
                      }}
                      shouldDisableDate={(date) =>
                        date <= dayjs().subtract(1, "day") &&
                        item.start_date !== date
                      }
                      // slots={{
                      //   textField: CustomTextField,
                      // }}
                      slotProps={{
                        textField: {
                          // trulyAnError:
                          //   formik.errors?.important_dates &&
                          //   formik.touched.important_dates
                          //     ? formik.errors?.important_dates[index]
                          //         ?.start_date
                          //     : false,
                          error:
                            formik.errors?.important_dates &&
                            formik.touched.important_dates
                              ? Boolean(
                                  formik.errors?.important_dates?.[index]
                                    ?.start_date
                                )
                              : false,
                          helperText:
                            formik.errors?.important_dates &&
                            formik.touched.important_dates
                              ? t(formik.errors?.important_dates[index]
                                  ?.start_date)
                              : "",
                          size: "small",
                        },
                      }}
                      width="97%"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DateField
                      label={t("End Date")}
                      value={item.end_date}
                      name={`end_date`}
                      onChange={(date) => {
                        formik.setFieldValue(
                          `important_dates.${index}.end_date`,
                          date
                        );
                        setIsDirty(true);
                      }}
                      shouldDisableDate={(date) =>
                        date <= dayjs().subtract(1, "day") &&
                        item.end_date !== date
                      }
                      // slots={{
                      //   textField: CustomTextField,
                      // }}
                      slotProps={{
                        textField: {
                          error:
                            formik.errors?.important_dates &&
                            formik.touched.important_dates
                              ? Boolean(
                                  formik.errors?.important_dates?.[index]
                                    ?.end_date
                                )
                              : false,
                          helperText:
                            formik.errors?.important_dates &&
                            formik.touched.important_dates
                              ? t(formik.errors?.important_dates[index]?.end_date)
                              : "",
                          size: "small",
                        },
                      }}
                      width="97%"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={formik.values.important_dates.length > 1 ? 5.3 : 5.3}
                  >
                    <InputField
                      id={`description${index}`}
                      label={t("Description")}
                      name={`description`}
                      value={item.description}
                      onChange={(event) => {
                        formik.setFieldValue(
                          `important_dates.${index}.description`,
                          event.target.value.replace(/^\s+/g, "")
                        );
                        setIsDirty(true);
                      }}
                      helperText={
                        formik.errors?.important_dates &&
                        formik.touched.important_dates
                          ? formik.errors?.important_dates[index]?.description
                          : ""
                      }
                      error={
                        formik.errors?.important_dates
                          ? Boolean(
                              formik.errors?.important_dates[index]?.description
                            ) && formik.touched.important_dates
                          : false
                      }
                      width="97%"
                    />
                  </Grid>
                  {/* { formik.values.important_dates.length > 1 && */}
                  <Grid
                    item
                    xs={12}
                    sm={0.7}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <DeleteIcon
                      color="error"
                      style={{ cursor: "pointer" }}
                      onClick={() => importantDateDelete(index)}
                    />
                  </Grid>
                  {/* } */}
                </Grid>
              ))}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: "2%",
                    paddingRight: "12px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <BtnLink
                    text={t("More")}
                    id="addMoreBtn"
                    onClick={addMoreHandler}
                    startIcon={<AddIcon />}
                    style={{
                      backgroundColor: colors.addBtn,
                      color: colors.white,
                      minWidth: "90px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Content>
        </Wrapper>
      ) : (
        <>
          {/* Render InfoLabel components for displaying details when isEditable is false */}
          {formik.values.important_dates.map((item, index) => (
            <Wrapper style={{ padding: "12px" }}>
              <Content component={"div"}>
                <Grid container>
                  {item.start_date && (
                    <Grid item xs={12} sm={4}>
                      <InfoLabel
                        label={"Start Date"}
                        info={dateFormFormat(item.start_date)}
                      />
                    </Grid>
                  )}

                  {item.end_date && (
                    <Grid item xs={12} sm={4}>
                      <InfoLabel
                        label={"End Date"}
                        info={dateFormFormat(item.end_date)}
                      />
                    </Grid>
                  )}

                  {item.description && (
                    <Grid item xs={12} sm={4}>
                      <InfoLabel
                        label={"Description"}
                        info={
                          item.description.length > 80
                            ? item.description.substring(0, 80) + "..."
                            : item.description
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Content>
            </Wrapper>
          ))}
        </>
      )}
    </>
  );
};

export default ImportantDates;
