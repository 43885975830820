import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { breakpoints } from "../../styles/vars";
import LoginForm from "./Form";
import { Header } from "./Header";
import logo from "../../assets/images/logo.svg";
import { getLoginForCompany } from "../../services/auth";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-top: -5%;
  ${breakpoints.laptop} {
    width: 50%;
  }
`;

export default function LoginFormContainer() {
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [contentError, setContentError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const paramValues = useParams();

  useEffect(() => {
    setIsLoading(true);
    getLoginForCompany(paramValues.url)
      .then(async function (data) {
        if (data.isOk) {
          setCompanyLogo(data.data.logo);
          setCompanyName(data.data.name);
        } else {
          setContentError(data.error.message);
        }
      })
      .catch(function () {})
      .finally(function () {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container>
      <Grid>
        <Grid item xs={12} sm={12}>
          <img
            src={
              companyLogo.split("/").pop() &&
              companyLogo.split("/").pop() !== "None"
                ? companyLogo
                : logo
            }
            alt="We care"
            // height={companyLogo ? 125 : 150}

            height={125}
          />
        </Grid>
      </Grid>

      {/* <img src={logo} alt="We care" height={125} /> */}
      <br />
      <Header>Welcome to {companyName}</Header>
      <LoginForm
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        contentError={contentError}
        companyName={companyName}
        paramValues={paramValues}
      />
    </Container>
  );
}
