import Typography from "@mui/material/Typography";
import { colors } from "../../../styles/vars";

export const InputError = (props) => {
  return (
    <Typography
      variant="caption"
      display="block"
      gutterBottom
      color={colors.error}
      ml={1}
    >
      {props.children}
    </Typography>
  );
};
