import React from "react";
// styling
import { darken } from "polished";
import styled from "styled-components";
import { colors, flex, fonts, textSizes } from "../../../styles/vars";

// utils
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

const Button = styled.button`
  border-radius: 8px;
  background-color: ${(props) => props.color || colors.blue};
  color: ${(props) => props.textcolor || colors.white};
  font-size: ${textSizes["14"]};
  font-family: ${fonts.accent};
  height: 40px;
  width: 100%;
  display: flex;
  ${flex.center};
  gap: 8px;
  line-height: 1;
  transition: background-color var(--transition);
  border: ${(props) => (props.border ? `1px solid ${props.border}` : "none")};

  &:hover,
  &:focus {
    background-color: ${(props) => darken(0.1, props.color || colors.blue)};
  }

  &.success {
    background-color: ${colors.success};

    &:hover,
    &:focus {
      background-color: ${darken(0.1, colors.success)};
    }
  }

  &.error {
    background-color: ${colors.error};

    &:hover,
    &:focus {
      background-color: ${darken(0.1, colors.error)};
    }
  }

  &&.disabled {
    background-color: ${(props) => props.color || colors.blue};
    opacity: 0.6 !important;
    pointer-events: none;
  }
`;

const Btn1 = ({
  text,
  handler,
  type = "button",
  isVisible = true,
  className,
  icon,
  color = colors.blue,
  ...props
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Button
          as={motion.button}
          className={className ? className : ""}
          onClick={handler}
          type={type}
          initial={false}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          color={color}
          {...props}
        >
          {icon && <i className={`icon icon-${icon}`} />} {text}
        </Button>
      )}
    </AnimatePresence>
  );
};

Btn1.propTypes = {
  text: PropTypes.string.isRequired,
  handler: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
};

export default Btn1;
