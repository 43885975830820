import * as React from 'react';
import TextField from "@mui/material/TextField";
import { ClearIcon } from '@mui/x-date-pickers';
import { IconButton } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const TimePickerInput = ({
    name='',
    width='',
    helperText, // Add helperText prop
    error, // Add error prop
    ...restProps
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {border: 'none' },
                width: width ? width : "90%",
            }}
            slotProps={{ 
                field: { clearable: true },
                textField: {
                    helperText: helperText,
                    error: !!error,
                  },
            }}
            name={name}
          renderInput={(params) => (
            <TextField
              helperText={helperText} // Use the helperText prop here
              error={error} // Use the error prop here
              {...params}
              value={
                params.inputProps.value
                  ? params.inputProps.value
                  : null
              }
            />
          )}
            {...restProps}
        />
    </LocalizationProvider>
  );
}

export default TimePickerInput;