import { protectedApi } from "../api";
import { formatApiResponse } from "../utils/helpers";

// Function to get a list of applicants with pagination
export const getApplicantsList = async (requestParams) => {
  const { page, limit, q, company_id, filterQuery } =
    requestParams;
    try {
      // Construct the query parameter string
      let param;
      if ( !company_id ||company_id === 0)
        param = `?limit=${limit}&page=${page}&q=${q}${filterQuery}`;
      else
      param = `?limit=${limit}&page=${page}&company_id=${company_id}&q=${q}${filterQuery}`;
      console.log("getApplicantsList.params", param);

    // Make a GET request to the applicants endpoint with pagination parameters
    const response = await protectedApi.get("/applicants/" + param);
    console.log("getApplicantsList.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getSchoolsList.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to add a new applicant detail
export const addApplicantDetail = async (requestParams) => {
  const { data } = requestParams;
  console.log("addApplicantDetail.params", requestParams);
  try {
    // Make a POST request to create a new applicant with the provided data
    const response = await protectedApi.post("/applicants/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("addApplicantDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("addApplicantDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get details of a specific applicant by ID
export const getApplicantDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getApplicantDetail.params", requestParams);
  try {
    // Make a GET request to retrieve applicant details by ID
    const response = await protectedApi.get(`/applicants/${id}`);
    console.log("getApplicantDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getApplicantDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get applicant filter status
export const getApplicantFilterData = async () => {
  try {
    const response = await protectedApi.get(`/applicants/status`);
    console.log("getApplicantFilterData.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getApplicantFilterData.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to edit/update applicant details by ID
export const editApplicantDetail = async (requestParams) => {
  const { id, data } = requestParams;

  console.log("editApplicantDetail.params", requestParams);
  try {
    // Make a PUT request to update applicant details by ID with the provided data
    const response = await protectedApi.put(`/applicants/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("editApplicantDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("editApplicantDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};
