import { protectedApi } from "../api";
import { formatApiResponse } from "../utils/helpers";

export const getForeignInstitutesList = async (requestParams) => {
  const { page, limit, q, company_id } = requestParams;
  console.log("getForeignInstitutesList.params", requestParams);
  try {
    // Construct the query string for the API request.
    let param = `?company_id=${company_id}&limit=${limit}&page=${page}&sort=${q.sort}&order=${q.order}`;
    const response = await protectedApi.get("/foreign-institutes" + param);
    console.log("getForeignInstitutesList.response", response);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getForeignInstitutesList.error", e);
    return formatApiResponse(e);
  }
};

export const addForeignInstituteApi = async (hiringAgencyData) => {
  const endpoint = `/foreign-institutes/`;
  console.log(hiringAgencyData);
  try {
    const response = await protectedApi.post(endpoint, hiringAgencyData);
    console.log("addForeignInstituteApi.response", response);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export async function getIndividualForeignInstituteApi(requestParams) {
  const { id } = requestParams;
  console.log("getIndividualForeignInstituteApi.params", requestParams);
  try {
    const res = await protectedApi.get(
      `/foreign-institutes/${id}`
    );
    console.log("getIndividualForeignInstituteApi.response", res);
    return formatApiResponse(res);
  } catch (e) {
    console.error("getIndividualForeignInstituteApi.catch", e);
    return formatApiResponse(e);
  }
}

export const editForeignInstituteApi = async (requestParams) => {
  const { id, data } = requestParams;
  const endpoint = `/foreign-institutes/${id}`;
  try {
    const response = await protectedApi.put(endpoint, data);
    console.log("editForeignInstituteApi.response", response);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};
