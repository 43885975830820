import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton } from "@mui/material";

import { ReactComponent as UkFlag } from "../../../assets/icon/united-kingdom-flag-icon.svg";
import { ReactComponent as GermanFlag } from "../../../assets/icon/germany-flag-icon.svg";

const languages = [
  {
    name: "English",
    code: "en",
    icon: <UkFlag />,
  },
  {
    name: "German",
    code: "de",
    icon: <GermanFlag />,
  },
];

const ChangeLanguage = (props) => {
  const LANGUAGECODE = "languageCode";

  const { i18n, t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState();
  const [currentlanguageCode, setCurrentlanguageCode] = useState(i18n.language);

  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const languageClick = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentlanguageCode(lang);
    window.localStorage.setItem(LANGUAGECODE, lang);
    setAnchorEl(null);
  };

  useEffect(() => {
    const code = window.localStorage.getItem(LANGUAGECODE);
    if (
      code &&
      code !== i18n.language &&
      languages.find((l) => l.code === code)
    ) {
      i18n.changeLanguage(code);
      setCurrentlanguageCode(code);
    } else if (!code) {
      if (navigator.language === "en" || navigator.language === "en-US") {
        i18n.changeLanguage("en");
        setCurrentlanguageCode("en");
      } else {
        i18n.changeLanguage("de");
        setCurrentlanguageCode("de");
      }
    }
  }, [i18n]);

  return (
    <>
      <Tooltip title={t("Change Language")} arrow>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <span style={{ width: "25px" }}>
            {languages.find((l) => l.code === currentlanguageCode)?.icon}
          </span>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map((language, index) => (
          <MenuItem onClick={() => languageClick(language.code)} key={`change-language-${index}`}>
            <span style={{ marginRight: "10px", width: "20px" }}>
              {language.icon}
            </span>
            <span>{language.name}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ChangeLanguage;
