import React from 'react';
import { TableRow } from '@mui/material';
import { fonts } from '../../../styles/vars';

const AppTableRow = ({ children, ...rest }) => {
  return (
    <TableRow sx={{ fontFamily: fonts.body }} {...rest}>
      {children}
    </TableRow>
  );
};

export default AppTableRow;
