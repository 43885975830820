import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";

// Importing sub-components
import Print from "./print";
import ExaminerGeneral from "./General";
import useAuth from "../../../hooks/useAuth";
import EmployerDetail from "./EmployerDetail";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import ErrorTabItem from "../../../components/UI/ErrorTabItem";
import { convertJSONToFormData } from "../../../utils/utility";
import { masterReference } from "../../../constants/masterData";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { getSchoolDropdownList } from "../../../services/schools";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";
import { toTitleCase } from "../../../utils/utility";

const Examiner = () => {
  // Define initial form values
  const initialValues = {
    first_name: "",
    last_name: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    employer: "",
    dob: dayjs(),
    qualification: "",
    company_id: 0,
  };

  const validateSchema = Yup.object().shape({
    first_name: Yup.string()
      .max(50, "Last Name should not be more than 50 characters")
      .required("REQUIRED"),
    last_name: Yup.string()
      .max(50, "Last Name should not be more than 50 characters")
      .required("REQUIRED"),
    qualification: Yup.string().required("REQUIRED"),
    employer: Yup.string().required("REQUIRED"),
    dob: Yup.string().required("REQUIRED"),
    address_line_1: Yup.string().required("REQUIRED"),
    postal_code: Yup.string().required("REQUIRED"),
    state: Yup.string().required("REQUIRED"),
    city: Yup.string().required("REQUIRED"),
    country: Yup.string().required("REQUIRED"),
  });

  // State to hold form data
  const { user } = useAuth();
  const [printData, setPrintData] = useState({});
  const [data, setData] = useState(initialValues);
  const [schoolList, setSchoolList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //  for getting selected record from the list
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();

  // to show notify message
  const { notify } = useNotistack();

  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    printRecord,
    setPrintRecord,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  // setting updated recordvalue from the selectedRecord
  useEffect(() => {
    if (selectedRecord && selectedRecord.first_name) {
      let selectedData = {
        first_name: "",
        last_name: "",
        address_id: 0,
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        employer: "",
        dob: "",
        qualification: "",
        company_id: 0,
      };

      selectedData.first_name = selectedRecord?.first_name;
      selectedData.last_name = selectedRecord?.last_name;
      selectedRecord.address.forEach((address) => {
        selectedData.address_id = address?.address_id;
        selectedData.address_line_1 = address.address_line_1;
        selectedData.address_line_2 = address.address_line_2;
        selectedData.postal_code = address.postal_code;
        selectedData.state = address.state;
        selectedData.city = address.city;
        selectedData.country = address.country;
      });
      selectedData.employer = selectedRecord.employer;
      selectedData.dob = dayjs(selectedRecord.dob);
      selectedData.company_id = selectedRecord.company_id;
      selectedData.qualification = selectedRecord.qualification;

      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER
      ) {
        setEditable(true);
      }
      setPrintData(selectedData);
    } else {
      formik.resetForm();
    }
  }, [selectedRecord]);

  // Define a function to handle form submission
  const handleSubmit = async (values) => {
    setIsLoading(true);
    let submittingData = {
      first_name: values.first_name,
      last_name: values.last_name,
      dob: values.dob.format("YYYY-MM-DD"),
      qualification: values.qualification,
      entity_type: masterReference.EXAMINER,
      employer: values.employer,
      address: [
        {
          address_id: values.address_id || 0,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          address_type: "employer",
          postal_code: values.postal_code,
          city: values.city,
          state: values.state,
          country: values.country,
        },
      ],
    };
    console.log("====================================");
    console.log("submittingData:::", submittingData);
    console.log("====================================");
    let requestParams = {
      id: requestId,
      data: convertJSONToFormData(submittingData),
    };

    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setEditable(true);
      await setRequestId(null);
      setRequestId(requestId ? requestParams.id : res.data.examiner_id);
      setSelectedIndex(requestId ? requestId : res.data.examiner_id);
      if (requestId) {
        notify("Examiner Information Updated", "success");
      } else {
        notify("Examiner Added", "success");
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        notify(`${key}: ${value}`, "error");
      }
    }
  };

  // Initialize Formik with initial values and submission handler
  const formik = useFormik({
    initialValues: data,
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  // for updating the record with formik data
  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  // Effect to submit the form when isFormSubmitRequested is true
  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [
    isEditable,
    isFormSubmitRequested,
    formik.handleSubmit,
    setFormSubmitRequested,
  ]);

  // to get School list from list api
  useEffect(() => {
    if (
      user.code === masterReference.SUPER_ADMIN ||
      user.code === masterReference.ADMIN
    ) {
      getSchoolDropdownList(formik.values.company_id)
        .then((res) => {
          if (res.isOk) {
            console.log("school list", res.data);
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            setSchoolList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formik.values.company_id]);

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState(0);

  // Function to handle tab change
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
    setIsDirty(true);
  };

  const handlePrintClose = () => {
    setPrintRecord(false);
  };

  return (
    <>
      <Print
        open={printRecord}
        onClose={handlePrintClose}
        printData={printData}
        schoolList={schoolList}
      />
      <OverlayLoader show={isLoading}>
        {/* Form */}
        <form onSubmit={formik.handleSubmit}>
          <div>
            {/* StyledTab for tab navigation */}
            <StyledTab
              value={activeTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              <ErrorTabItem
                label={"General"}
                {...a11yProps(0)}
                error={
                  (formik.touched.first_name &&
                    Boolean(formik.errors.first_name)) ||
                  (formik.touched.last_name &&
                    Boolean(formik.errors.last_name)) ||
                  (formik.touched.dob && Boolean(formik.errors.dob)) ||
                  (formik.touched.qualification &&
                    Boolean(formik.errors.qualification))
                }
              />
              <ErrorTabItem
                label={"Employer Details"}
                {...a11yProps(1)}
                error={
                  (formik.touched.employer &&
                    Boolean(formik.errors.employer)) ||
                  (formik.touched.address_line_1 &&
                    Boolean(formik.errors.address_line_1)) ||
                  (formik.touched.postal_code &&
                    Boolean(formik.errors.postal_code)) ||
                  (formik.touched.city && Boolean(formik.errors.city)) ||
                  (formik.touched.state && Boolean(formik.errors.state)) ||
                  (formik.touched.country && Boolean(formik.errors.country))
                }
              />
            </StyledTab>

            {/* General Tab Panel */}
            <div
              style={{
                padding: "24px",
                display: activeTab === 0 ? "" : "none",
              }}
              id="simple-tabpanel-0"
              aria-labelledby="simple-tab-0"
            >
              {/* Render the ExaminerGeneral component with formik prop */}
              <ExaminerGeneral
                formik={formik}
                handleFormChange={handleFormChange}
                schoolList={schoolList}
              />
            </div>

            {/* Employer Details Tab Panel */}
            <div
              style={{
                padding: "24px",
                display: activeTab === 1 ? "" : "none",
              }}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              {/* Render the Examiner Employer Details component with formik prop */}
              <EmployerDetail
                formik={formik}
                handleFormChange={handleFormChange}
              />
            </div>
          </div>
        </form>
      </OverlayLoader>
    </>
  );
};

export default Examiner;
