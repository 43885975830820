import React from "react";
import Page from "../layout/Page";
import ForeignInstitutesList from "../widgets/ForeignInstitutesList";

export default function ForeignInstitutesPage() {
  return (
    <>
      <Page title="Foreign Institutes">
        <ForeignInstitutesList title="Foreign Institutes" />
      </Page>
    </>
  );
}
