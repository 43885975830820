import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Page from "../layout/Page";
import useNotistack from "../hooks/useNotistack";
import useWindowSize from "../hooks/useWindowSize";
import AddForeignInstituteUser from "../widgets/AddForeignInstituteUser";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";
import useAuth from "../hooks/useAuth";
import { roles } from "../constants/menu";

const StepWrapper = styled.div`
  && {
    width: 85%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

function AddForeignInstitute(props) {
  let pageTitle = "Add Foreign Institute";
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  const { user } = useAuth();
  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.role.trim().toLowerCase();
  const defaultUrl = userRole === roles.SUPER_ADMIN ? "" : `/${customUrl}`;

  return (
    <>
      <Page title={pageTitle} mobileHideTopBar={false} pageTitleCenter={true}>
        <BreadcrumWithTitle
          path={[
            { pathname: "/", title: "Home" },
            {
              pathname: user.custom_url
                ? `/${user.custom_url}/foreign-institutes`
                : "/foreign-institutes",
              title: "Foreign Institutes",
            },
            { pathname: "", title: "Add Foreign Institute" },
          ]}
          title={pageTitle}
        />
        <StyledDiv>
          <StepWrapper>
            <AddForeignInstituteUser />
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default AddForeignInstitute;
