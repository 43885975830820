import { MenuItem, Pagination, Select } from "@mui/material";
import React from "react";
import { fonts } from "../../styles/vars";
import {
  NumberOfRowsText,
  NumberOfRowsWrapper,
  PaginationContainer,
  Wrapper,
} from "./style";

const AppPagination = ({
  total,
  page,
  onPageChange,
  rowsPerPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
}) => {
  return (
    <Wrapper>
      <NumberOfRowsWrapper>
        <NumberOfRowsText>Rows Per Page</NumberOfRowsText>
        <Select
          value={rowsPerPage}
          onChange={(e) => onRowsPerPageChange(e, e.target.value)}
          sx={{ height: 24, border: "none", fontFamily: fonts.body }}
        >
          {rowsPerPageOptions.map((option, index) => (
            <MenuItem
              sx={{ fontFamily: fonts.body }}
              value={option}
              key={index}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </NumberOfRowsWrapper>
      <PaginationContainer>
        <Pagination
          siblingCount={0}
          boundaryCount={1}
          onChange={onPageChange}
          count={Math.ceil(total / rowsPerPage)}
          page={page}
          variant="outlined"
          shape="rounded"
          sx={{ fontFamily: fonts.body }}
        />
      </PaginationContainer>
        <div style={{ marginTop: "10px" }}>
          {rowsPerPage > total ? 
            `Showing ${total} records out of total ${total} records`
            :
            `Showing ${rowsPerPage} records out of total ${total} records`
          }
        </div>
    </Wrapper>
  );
};

export default AppPagination;
