import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import * as yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { InputError } from "../../components/UI/InputError";
import Btn1 from "../../components/UI/Btn1";
import useNotistack from "../../hooks/useNotistack";
import { breakpoints, flex } from "../../styles/vars";
import { AuthContext } from "../../contexts/authContext";
import LabeledFormInput from "../../components/UI/LabeledFormInput";
import { editChangePasswordApi } from "../../services/auth";
import { passwordValidation } from "../../utils/common";

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  ${flex.col}
`;
const BtnWrapper = styled.div`
  margin-top: 20px;
`;

const changePasswordSchema = yup.object({
  old_password: yup.string().required("This field is required"),
  new_password: yup
    .string()
    .required("This field is required")
    .test("password-check", function (value) {
      return (
        passwordValidation(value).length === 0 ||
        this.createError({ message: passwordValidation(value) })
      );
    }),
  confirm_password: yup
    .string()
    .required("This field is required")
    .oneOf([yup.ref("new_password"), null], "Password did not match"),
});

export default function ChangePasswordForm(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { uid, token } = useParams();
  const { notify } = useNotistack();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const handleOldPasswordClick = () => setShowOldPassword((show) => !show);
  const handleClickPassword = () => setShowPassword((show) => !show);
  const handleClickConfPassword = () => setShowConfPassword((show) => !show);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values, action) => {
      setIsLoading(false);
      try {
        const keys = ["old_password", "new_password"];
        const cleanedValues = keys.reduce((acc, key) => {
          acc[key] = values[key];
          return acc;
        }, {});
        let res;
        console.log(cleanedValues);
        res = await editChangePasswordApi(cleanedValues);
        if (res.isOk) {
          setIsAuthenticated(true);
          notify(t("Password changed successfully"), "success");
          action.resetForm();
        } else {
          for (const [key, value] of Object.entries(res.error)) {
            notify(`${key}: ${value}`, "error");
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={6}>
          <LabeledFormInput
            id="old_password"
            type={showOldPassword ? "text" : "password"}
            name="old_password"
            title={t("Old Password")}
            placeholder={t("Old Password")}
            required
            value={formik.values.old_password}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleOldPasswordClick}
                    edge="end"
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.old_password && formik.errors.old_password && (
            <InputError>
              {t(
                formik.errors.old_password.replace(
                  "old_password",
                  "Old Password"
                )
              )}
            </InputError>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={6}>
          <LabeledFormInput
            id="new_password"
            type={showPassword ? "text" : "password"}
            name="new_password"
            title={t("New Password")}
            placeholder={t("New Password")}
            required
            onChange={formik.handleChange}
            value={formik.values.new_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.new_password && formik.errors.new_password && (
            <InputError>
              {t(
                formik.errors.new_password.replace(
                  "new_password",
                  "New Password"
                )
              )}
            </InputError>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={6}>
          <LabeledFormInput
            id="confirm_password"
            type={showConfPassword ? "text" : "password"}
            name="confirm_password"
            title={t("Confirm New Password")}
            placeholder={t("Confirm New Password")}
            required
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfPassword}
                    edge="end"
                  >
                    {showConfPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.confirm_password &&
            formik.errors.confirm_password && (
              <InputError>
                {t(
                  formik.errors.confirm_password.replace(
                    "confirm_password",
                    "Confirm Password"
                  )
                )}
              </InputError>
            )}
        </Grid>
      </Grid>
      <BtnWrapper>
        <Btn1
          className={clsx(isLoading && "disabled")}
          disabled={isLoading}
          type="submit"
          text={t(!isLoading ? "Submit" : "Please wait...")}
        />
      </BtnWrapper>
    </StyledForm>
  );
}
