import { Grid } from "@mui/material";

import React, { useState, useEffect, useRef } from "react";

import {
  applicantStatus,
  masterReference,
} from "../../../../../constants/masterData";
import useAuth from "../../../../../hooks/useAuth";
import { useCustomFormSubmit } from "../../../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../../../hooks/useRecordSelection";
import { toTitleCase } from "../../../../../utils/utility";
import SelectDropdown from "../../../../../components/UI/SelectDropdown";

import InfoLabel from "../../../../../components/UI/InfoLabel";
import {
  getDropdownCoursesList,
  getSchoolDropdownList,
} from "../../../../../services/schools";

// Contact Information component receives 'formik' as a prop
const Course = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();
  const { user } = useAuth();
  const [schoolList, setSchoolList] = useState([]);
  const [courseList, setCourseList] = useState([]);

  // to get School list from list api
  useEffect(() => {
    if (user.code === masterReference.ADMIN) {
      getSchoolDropdownList()
        .then((res) => {
          if (res.isOk) {
            setSchoolList(res.data);
            console.log(res.data[0]?.value);
            formik.setFieldValue("school_id", res.data[0]?.value);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (
      user.code.toLowerCase() === masterReference.SCHOOL_MANAGER ||
      user.code.toLowerCase() === masterReference.FOREIGN_INSTITUTE_MANAGER ||
      user.code.toLowerCase() === masterReference.HR ||
      user.code.toLowerCase() === masterReference.AGENCY_USER
    ) {
      formik.setFieldValue("school_id", 0);
    }
  }, [user]);

  useEffect(() => {
    if (
      formik.values.school_id ||
      user.code.toLowerCase() === masterReference.HR ||
      user.code.toLowerCase() === masterReference.AGENCY_USER
    ) {
      getDropdownCoursesList(
        formik.values.school_id,
        formik.values.entity_type,
        formik.values.entity_id
      )
        .then((res) => {
          if (res.isOk) {
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            setCourseList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (
      formik.values.school_id ||
      user.code.toLowerCase() === masterReference.SCHOOL_MANAGER ||
      user.code.toLowerCase() === masterReference.FOREIGN_INSTITUTE_MANAGER
    ) {
      getDropdownCoursesList(formik.values.school_id)
        .then((res) => {
          if (res.isOk) {
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            setCourseList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formik.values.school_id, schoolList, user]);

  return (
    <>
      <Grid container spacing={2}>
        {isEditable ? (
          <>
            <>
              {user.code === masterReference.ADMIN && (
                <Grid item xs={12} sm={4}>
                  <SelectDropdown
                    id={"school_id"}
                    name={"school_id"}
                    options={schoolList}
                    disableClearable
                    width="100%"
                    label="School"
                    value={
                      schoolList.find(
                        (option) => option.value === formik.values.school_id
                      ) ||
                      schoolList[0] ||
                      ""
                    }
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      formik.setFieldValue("school_id", selected?.value);
                    }}
                  />
                </Grid>
              )}
            </>
            <Grid item xs={12} sm={4}>
              <SelectDropdown
                id={"course_id"}
                name={"course_id"}
                options={courseList}
                label="Course"
                width="100%"
                onChange={(_, selected) => {
                  setIsDirty(true);

                  formik.setFieldValue("course_id", selected?.value);
                }}
                value={
                  courseList.find(
                    (option) => option.value == formik.values.course_id
                  ) || ""
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <InfoLabel
                label={"Course Name"}
                info={formik.values.course_name && formik.values.course_name}
              />
            </Grid>
            <Grid item xs={3}>
              <InfoLabel
                label={"Course Start Date"}
                info={
                  formik.values.course_name &&
                  formik.values.course_start_time &&
                  toTitleCase(
                    formik.values.course_start_time.format("DD.MM.YYYY")
                  )
                }
              />
            </Grid>
            <Grid item xs={3}>
              <InfoLabel
                label={"Course End Date"}
                info={
                  formik.values.course_name &&
                  formik.values.course_end_time &&
                  toTitleCase(
                    formik.values.course_end_time.format("DD.MM.YYYY")
                  )
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Course;
