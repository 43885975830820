import React, { useState, useEffect } from "react";
import { Grid, Button, IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { handleKeys } from "../../utils/common";
import { getCountryList } from "../../services/users";
import CancelButtonWidget from "../CancelButtonWidget";
import SubmitButtonWidget from "../SubmitButtonWidget";
import WidgetsLoader from "../../components/WidgetsLoader";
import ShrinkSearchSelect from "../../components/UI/ShrinkSearchSelect";
import { CompanyGrid, GridItem, LabeledCompanyInput } from "./style";
import { checkEmailApi } from "../../services/auth";
import { emailRegex } from "../../constants/vars";
import { masterReference } from "../../constants/masterData";
import useNotistack from "../../hooks/useNotistack";
import { colors } from "../../styles/vars";

const CompanyForm = ({
  initialValues = {
    name: "",
    email: "",
    phone_no: "",
    address_line_1: "",
    address_line_2: "",
    state: "",
    city: "",
    postal_code: "",
    country: "",
    address_type: "",
    address_id: null,
    email_id: null,
    mobile_id: null,
    logo: "",
  },
  onSubmit,
  isMobile,
  isLoading,
  companyId,
}) => {
  const addCompanyValidationSchema = yup.object({
    name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(value, masterReference.COMPANY, companyId, 0)
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
    // phone_no: yup.string().required(),
    address_line_1: yup.string().required("REQUIRED"),
    city: yup.string().required("REQUIRED"),
    state: yup.string().required("REQUIRED"),
    postal_code: yup.string().required("REQUIRED"),
    country: yup.string().required("REQUIRED"),
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: addCompanyValidationSchema,
    onSubmit,
  });

  const { notify } = useNotistack();
  const [countryList, setCountryList] = useState([]);
  const [logoPath, setLogoPath] = useState("");

  // to get data from country list api
  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.isOk) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleClose = () => {
    if (initialValues.logo === formik.values.logo) {
      formik.setFieldValue("delete_logo", formik.values.logo);
    }

    formik.setFieldValue("logo", "");
    setLogoPath("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
  };

  // Function to handle drag and drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Function to handle dropped files
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    formik.setFieldValue("logo", file);
  };

  const handleFileUpload = (files) => {
    let allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/webp",
      "image/svg+xml",
    ];
    if (allowedFileTypes.includes(files.type)) {
      previewLogo(files);
      formik.setFieldValue("logo", files);
    } else {
      formik.setFieldValue("logo", "");
      notify("Please upload a PNG,JPEG,WEBP or SVG file.", "error");
      setLogoPath("");
    }
  };
  function previewLogo(files) {
    if (files) {
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = function (event) {
        var image = new Image();
        image.src = event.target.result;

        image.onload = function () {
          if (image.width > 500 || image.height > 500) {
            formik.setFieldValue("logo", "");
            notify("logo must be 500 x 500 px or less", "error");
            setLogoPath("");
          } else {
            setLogoPath(reader.result);
          }
        };
      };
    } else {
      setLogoPath("");
    }
  }

  return (
    <>
      {isLoading ? (
        <WidgetsLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <GridItem item xs={12} sm={12}>
              <LabeledCompanyInput
                id="name"
                name="name"
                title="Company Name"
                placeholder="Name"
                required
                value={formik.values.name}
                onChange={handleChange}
                helperText={formik.touched?.name ? formik.errors?.name : ""}
                error={formik.touched.name && formik.errors.name}
              />
              {/* {formik.touched.name && formik.errors.name && (
                <InputError>{formik.errors.name}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="phone_no"
                name="phone_no"
                title="Phone Number"
                // required
                // placeholder="+1(000)-000-00-00"
                onKeyPress={handleKeys}
                onChange={handleChange}
                value={formik.values.phone_no}
                helperText={
                  formik.touched?.phone_no ? formik.errors?.phone_no : ""
                }
                error={formik.touched.phone_no && formik.errors.phone_no}
              />
              {/* {formik.touched.phone_no && formik.errors.phone_no && (
                <InputError>
                  {formik.errors.phone_no.replace("phone_no", "phone")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="email"
                name="email"
                title="Email Address"
                placeholder="example@domain.com"
                required
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onKeyUp={formik.handleBlur}
                helperText={formik.touched?.email ? formik.errors?.email : ""}
                error={formik.touched.email && formik.errors.email}
              />
              {/* {formik.touched.email && formik.errors.email && (
                <InputError>{formik.errors.email}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="address_line_1"
                name="address_line_1"
                title="Address Line 1"
                placeholder="123"
                required
                onChange={handleChange}
                value={formik.values.address_line_1}
                helperText={
                  formik.touched?.address_line_1
                    ? formik.errors?.address_line_1
                    : ""
                }
                error={
                  formik.touched.address_line_1 && formik.errors.address_line_1
                }
              />
              {/* {formik.touched.address_line_1 && formik.errors.address_line_1 && (
                <InputError>
                  {formik.errors.address_line_1.replace("address_line_1", "Address Line 1")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="address_line_2"
                name="address_line_2"
                title="Address Line 2"
                onChange={handleChange}
                value={formik.values.address_line_2}
                helperText={
                  formik.touched?.address_line_2
                    ? formik.errors?.address_line_2
                    : ""
                }
                error={
                  formik.touched.address_line_2 && formik.errors.address_line_2
                }
              />
              {/* {formik.touched.address_line_2 && formik.errors.address_line_2 && (
                <InputError>{formik.errors.address_line_2}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="postal_code"
                type="number"
                name="postal_code"
                title="Postal Code"
                placeholder="Postal Code"
                required
                onChange={handleChange}
                value={formik.values.postal_code}
                helperText={
                  formik.touched?.postal_code ? formik.errors?.postal_code : ""
                }
                error={formik.touched.postal_code && formik.errors.postal_code}
              />
              {/* {formik.touched.postal_code && formik.errors.postal_code && (
                <InputError>
                  {formik.errors.postal_code.replace(
                    "postal_code",
                    "postal code"
                  )}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="city"
                name="city"
                title="City"
                placeholder="City"
                required
                onChange={handleChange}
                value={formik.values.city}
                helperText={formik.touched?.city ? formik.errors?.city : ""}
                error={formik.touched.city && formik.errors.city}
              />
              {/* {formik.touched.city && formik.errors.city && (
                <InputError>{formik.errors.city}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="state"
                name="state"
                title="State"
                placeholder="state"
                required
                onChange={handleChange}
                value={formik.values.state}
                helperText={formik.touched?.state ? formik.errors?.state : ""}
                error={formik.touched.state && formik.errors.state}
              />
              {/* {formik.touched.state && formik.errors.state && (
                <InputError>{formik.errors.state}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <ShrinkSearchSelect
                id="country"
                name="country"
                options={countryList}
                label="Country"
                placeholder="country"
                required
                onChange={(_, selected) => {
                  formik.setFieldValue("country", selected?.value || "");
                }}
                value={
                  countryList.find(
                    (option) => option.value === formik.values.country
                  ) || ""
                }
                helperText={
                  formik.touched?.country ? formik.errors?.country : ""
                }
                error={formik.touched.country && formik.errors.country}
              />
              {/* {formik.touched.country && formik.errors.country && (
                <InputError>{formik.errors.country}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={6}>
              <div
                style={{
                  border: "2px dashed #aaa",
                  // padding: "20px",
                  padding: "15px",
                  margin: "15px 0",
                  textAlign: "center",
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                {/* <p>{"Drag and drop a file here or click to select a file"}</p> */}

                <input
                  id="logo"
                  name="logo"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(event) => {
                    handleFileUpload(event.currentTarget.files[0]);
                  }}
                />

                <label htmlFor="logo">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    style={{ width: "250px" }}
                  >
                    {"Upload Logo"}
                  </Button>
                </label>

                {/* {formik.values.logo && (
                  <div>
                    <p style={{ color: "#1976d2" }}>
               
                      {typeof formik.values.logo === "object"
                        ? formik.values.logo.name
                        : formik.values.logo?.split("/")?.pop()}
                    </p>
                  </div>
                )} */}
                {formik.touched.logo && formik.errors.logo && (
                  <div style={{ color: "#d32f2f", fontSize: "small" }}>
                    {formik.errors.logo}
                  </div>
                )}
              </div>
            </GridItem>
            {(logoPath || formik.values.logo) && (
              <GridItem item xs={12} sm={2}>
                <br />

                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    color: colors.red,
                  }}
                >
                  <img
                    src={logoPath ? logoPath : formik.values.logo}
                    height={125}
                    alt="logo"
                  ></img>
                  {logoPath || formik.values.logo ? (
                    <CloseIcon
                      style={{
                        position: "absolute",
                        right: "-25px",
                        top: "-6px",
                      }}
                    />
                  ) : (
                    <null />
                  )}
                </IconButton>
              </GridItem>
            )}

            <GridItem item xs={12} sm={12}></GridItem>
            {!isMobile ? (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "3px",
                  }}
                >
                  <Grid item xs={6} sm={2}>
                    <CancelButtonWidget />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <SubmitButtonWidget />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <SubmitButtonWidget />
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </>
  );
};

export default CompanyForm;
