import React from "react";
import styled from "styled-components";
import FlowStepperForm from "../widgets/FlowStepperForm";

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 70vh;
`;

export default function FlowCourseSteps() {
  return (
    <>
      <RootWrapper>
        <FlowStepperForm />
      </RootWrapper>
    </>
  );
}
