import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import { useNavigate } from "react-router-dom";
import StepLabel from "@mui/material/StepLabel";

import UserForm from "./userForm";
import useAuth from "../../hooks/useAuth";
import AddSchoolForm from "./AddSchoolForm";
import { emailRegex } from "../../constants/vars";
import { checkEmailApi } from "../../services/auth";
import useNotistack from "../../hooks/useNotistack";
import { getCountryList } from "../../services/users";
import useWindowSize from "../../hooks/useWindowSize";
import { addSchoolDetail } from "../../services/schools";
import { convertJSONToFormData } from "../../utils/utility";
import { masterReference } from "../../constants/masterData";
import { getCompaniesListForUser } from "../../services/users";

const AddSchoolUser = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const { width } = useWindowSize();
  const [formData, setFormData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.code.trim().toLowerCase();
  const defaultUrl =
    userRole === masterReference.SUPER_ADMIN ? "" : `/${customUrl}`;

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uniqueValues = [];
  const schoolInitialValues = {
    name: "",
    company_id: null,
    email: "",
    phone_no: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    address_id: null,
    address_type: "",
    email_id: null,
    mobile_id: null,
    goals: [{ id: 0, goal_name: "" }],
    delete_goals: [],
  };

  const SchoolValidationSchema = yup.object({
    name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(
                value,
                masterReference.SCHOOL,
                0,
                SchoolFormik.values.company_id
              )
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
    // phone_no: yup.string().required(),
    address_line_1: yup.string().required("REQUIRED"),
    city: yup.string().required("REQUIRED"),
    state: yup.string().required("REQUIRED"),
    postal_code: yup.string().required("REQUIRED"),
    country: yup.string().required("REQUIRED"),
    goals: yup.array().of(
      yup.object().shape({
        goal_name: yup
          .string()
          .required("REQUIRED")
          .test("is-unique", "Goal already present", function (value) {
            const lowercaseValue = value.toLowerCase();
            if (
              uniqueValues.some(
                (existingValue) =>
                  existingValue.toLowerCase() === lowercaseValue
              )
            ) {
              return false;
            } else {
              uniqueValues.push(value);
              return true;
            }
          }),
      })
    ),
  });

  const SchoolFormik = useFormik({
    initialValues: schoolInitialValues,
    validateOnChange: true,
    validationSchema: SchoolValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setFormData((prevState) => ({
        ...prevState,
        name: values.name,
        company_id: values.company_id || 0,
        address: [
          {
            address_id: values.address_id || 0,
            address_line_1: values.address_line_1,
            address_line_2: values.address_line_2,
            address_type: "current",
            postal_code: values.postal_code,
            city: values.city,
            state: values.state,
            country: values.country,
          },
        ],
        contact: [
          {
            contact_id: values.email_id || 0,
            contact_type: "email",
            value: values.email,
          },
          {
            contact_id: values.mobile_id || 0,
            contact_type: "phone",
            value: values.phone_no.replace("-", "").replace(/\s+/g, ""),
          },
        ],
        goals: values.goals.filter((goal) => goal.goal_name.trim() !== ""),
        delete_goals: values.delete_goals,
      }));
      setActiveStep(1);
      setIsLoading(false);
    },
  });

  // to get data from country list api
  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.isOk) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // get company list data
    getCompaniesListForUser()
      .then((res) => {
        if (res.isOk) {
          setCompanyOptions(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (!schoolInitialValues.company_id) {
      SchoolFormik.setFieldValue("company_id", companyOptions[0]?.value);
    }
  }, [companyOptions]);

  const userInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
  };

  const UserValidationSchema = yup.object({
    first_name: yup.string().required("REQUIRED"),
    last_name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(value, masterReference.USER, 0, 0, 0, "school")
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
  });

  const userFormik = useFormik({
    initialValues: userInitialValues,
    validateOnChange: true,
    validationSchema: UserValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      let submittingData = {
        ...formData,
        user: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone_no: values.phone_no,
        },
      };
      console.log("submittingData", submittingData);
      // submittingData = convertJSONToFormData(submittingData);
      let res = await addSchoolDetail(submittingData);
      if (res.status === 201) {
        resetForm();
        notify("School Infomation Added.", "success");
        navigate("/schools");
      } else {
        for (const [key, value] of Object.entries(res.error)) {
          if (typeof value === "object") {
            value.forEach((obj) => {
              for (const key2 in obj) {
                if (obj.hasOwnProperty(key2)) {
                  const value2 = obj[key2];
                  for (let i in value2) {
                    notify(`${key2}: ${value2[i]}`, "error");
                  }
                }
              }
            });
          } else {
            notify(`${key}: ${value}`, "error");
          }
        }
      }
      setIsLoading(false);
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} style={{ marginBottom: "20px" }}>
        <Step>
          <StepLabel>{"School Details"}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{"Manager Details"}</StepLabel>
        </Step>
      </Stepper>
      <form
        onSubmit={
          activeStep === 0 ? SchoolFormik.handleSubmit : userFormik.handleSubmit
        }
      >
        {activeStep === 0 ? (
          <AddSchoolForm
            isLoading={isLoading}
            formik={SchoolFormik}
            countryList={countryList}
            companyOptions={companyOptions}
          />
        ) : (
          <UserForm
            isLoading={isLoading}
            isMobile={isMobile}
            formik={userFormik}
          />
        )}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            type="button"
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit" variant="contained">
            {activeStep === 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddSchoolUser;
