import { create } from "zustand";

// Custom hook for managing selected index and selected record
export const useRecordSelection = create((set) => ({
  selectedIndex: 0, // Initialize selected index
  selectedRecord: {}, // Initialize selected record

  // Function to set the selected index
  setSelectedIndex: (index) => set({ selectedIndex: index }),

  // Function to set the selected record
  setSelectedRecord: (record) => set({ selectedRecord: record }),
}));
