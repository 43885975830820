import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import useAuth from "../../../../../hooks/useAuth";
import { useCustomFormSubmit } from "../../../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../../../hooks/useRecordSelection";
import SelectDropdown from "../../../../../components/UI/SelectDropdown";
import { getGoalFlow } from "../../../../../services/schools";
import useNotistack from "../../../../../hooks/useNotistack";
import { toTitleCase } from "../../../../../utils/utility";
import { applicantStatus } from "../../../../../constants/masterData";

// Contact Information component receives 'formik' as a prop
const Status = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [studentGoalFlowList, setStudentGoalFlowList] = useState([]);
  const [studentGoalFlow, setStudentGoalFlow] = useState("");
  const { selectedRecord } = useRecordSelection();
  const { user } = useAuth();
  const { notify } = useNotistack();

  const getGoalFlowDetails = (requestParams) => {
    getGoalFlow(requestParams)
      .then((res) => {
        if (res.isOk && res.data.length > 0) {
          console.log(res.data);
          res.data.forEach((ele) => {
            ele.label = toTitleCase(ele.label);
          });
          setStudentGoalFlowList(res.data);
        } else {
          setStudentGoalFlowList([]);
          formik.setFieldValue("student_goal_flow_guid", null);
          notify("Student Flow not found for this Goal.", "error");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log("status", formik.values.status);
    if (formik.values.status === "approved") {
      let requestParams = {
        goal_id: formik.values.goal_id,
        userType: "student",
      };
      console.log(requestParams);
      getGoalFlowDetails(requestParams);
    }
  }, []);

  const handleRadioChange = (event) => {
    setIsDirty(true);
    formik.setFieldValue("status", event.target.value);

    if (event.target.value === applicantStatus.APPROVED) {
      let requestParams = {
        goal_id: formik.values.goal_id,
        userType: "student",
      };
      getGoalFlowDetails(requestParams);
    } else {
      setStudentGoalFlowList([]);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <>
          <Grid item>
            <RadioGroup
              defaultValue={formik.values.status}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="approved"
                control={<Radio />}
                label="Approve"
                disabled={!isEditable}
              />

              <FormControlLabel
                value="rejected"
                control={<Radio />}
                label="Reject"
                disabled={!isEditable}
              />
              <FormControlLabel
                value="archived"
                control={<Radio />}
                label="Archive"
                disabled={!isEditable}
              />
            </RadioGroup>
          </Grid>
          {studentGoalFlowList.length > 0 && (
            <Grid item xs={4}>
              <SelectDropdown
                id={"student_goal_flow_guid"}
                name={"student_goal_flow_guid"}
                options={studentGoalFlowList}
                width="100%"
                label="Student Goal Flow"
                onChange={(_, selected) => {
                  setIsDirty(true);
                  formik.setFieldValue(
                    "student_goal_flow_guid",
                    selected.value
                  );
                  setStudentGoalFlow(selected.value);
                }}
                value={
                  studentGoalFlowList.find(
                    (option) => option.value === studentGoalFlow
                  ) ||
                  studentGoalFlowList.find(
                    (option) =>
                      option.value === formik.values.student_goal_flow_guid
                  ) ||
                  ""
                }
                helperText={
                  formik.touched.student_goal_flow_guid
                    ? formik.errors.student_goal_flow_guid
                    : ""
                }
                error={
                  formik.touched.student_goal_flow_guid &&
                  Boolean(formik.errors.student_goal_flow_guid)
                }
              />
            </Grid>
          )}
        </>
      </Grid>
    </>
  );
};

export default Status;
