import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/vars";

const StyledHeader = styled.h1`
  color: ${colors.peach};
`;

export default function Header({ children }) {
  return <StyledHeader>{children}</StyledHeader>;
}
