import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { useSearchParams } from "react-router-dom";
import {
  IconButton,
  Skeleton,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getCompaniesList } from "../../services/companies";
import {
  AppTable,
  AppTableBody,
  AppTableRow,
  AppTableCell,
  AppTableHead,
} from "../../components/UI/Table";
import { NoWrap } from "./style";
import { colors } from "../../styles/vars";
import useWindowSize from "../../hooks/useWindowSize";
import BreadcrumWithTitle from "../BreadcrumWithTitle";
import AppPagination from "../../components/Pagination";
import AddButtonWidget from "../../widgets/AddButtonWidget";
import { rowsPerPageOptions } from "../../constants/options";
import { StyledIconBtn } from "../../components/UI/StyledIconBtn";
import CustomizedSwitches from "../CustomSwitch";
import { updateEntityStatus } from "../../services/master";
import useNotistack from "../../hooks/useNotistack";
import { masterReference } from "../../constants/masterData";
import { ContentTitle } from "../../layout/Page/style";

const columns = [
  {
    name: "Name",
    field: "name",
    align: "left",
    width: "20%",
  },
  // {
  //   name: "Address",
  //   field: "address",
  //   align: "left",
  //   width: "30%",
  // },
  {
    name: "Contact",
    field: "contact",
    align: "left",
    width: "20%",
  },
  {
    name: "URL",
    field: "url",
    align: "left",
    width: "33%",
  },
  {
    name: "Status",
    field: "status",
    align: "left",
    width: "10%",
  },
  {
    name: "Edit",
    align: "left",
    width: "10%",
  },
];

const CompaniesList = (props) => {
  //  for getting value from the search paramter
  let [searchParams, setSearchParams] = useSearchParams();

  const params = {
    sort: searchParams.get("sort") || "",
    order: searchParams.get("order") || 1,
    page: searchParams.get("page") ? parseInt(searchParams.get("page")) : 1,
    limit: searchParams.get("limit") || rowsPerPageOptions[0],
  };

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(params.page);
  const [open, setOpen] = useState(false);
  const [statusState, setStatusState] = useState("");
  const [entityId, setEntityId] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const { notify } = useNotistack();
  // const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [rowsPerPage, setRowsPerPage] = useState(params.limit);
  const navigate = useNavigate();
  const [selectedRowStatus, setSelectedRowStatus] = useState({
    clinic_id: 0,
    current_clinic_status: false,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const { notify } = useNotistack();
  // const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  // const { user } = useAuth();

  //sorting
  const [sorting, setSorting] = useState({
    sort: params.sort,
    order: params.order,
    // abort: 'View/Edit'
  });
  const renderSortIcon = (name) => {
    if (sorting.sort === name) {
      if (sorting.order) {
        return (
          <IconButton>
            <NorthIcon fontSize="small" color="primary" />
          </IconButton>
        );
      } else {
        return (
          <IconButton>
            <SouthIcon fontSize="small" color="secondary" />
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, rows) => {
    // setRowsPerPage(parseInt(event.target.value, rowsPerPageOptions[0]));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleOpen = (entity_id, state) => {
    setStatusState(state);
    setEntityId(entity_id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const fetchClinics = useCallback(
    (page, rowsPerPage, sorting, shouldRetainPreviousRows = false) => {
      setIsLoading(true);
      getCompaniesList(page, rowsPerPage, sorting)
        .then(async function (data) {
          console.log(data);
          if (data.isOk) {
            setRows((rows) => {
              if (shouldRetainPreviousRows && rows.length > 0) {
                return [...rows, ...data.data];
              }
              return data.data;
            });
            setTotalRows(data.total);
            setIsLast(data.isLast);
          } else {
            // enqueueSnackbar('Something went wrong fetching clinics', {
            //   variant: 'error'
            // });
          }
        })
        .catch(function () {
          // enqueueSnackbar('Something went wrong fetching clinics', {
          //   variant: 'error'
          // });
        })
        .finally(function () {
          setIsLoading(false);
        });
    },
    []
    // [enqueueSnackbar]
  );

  useEffect(() => {
    if (page >= 0 && rowsPerPage > 0 && !isMobile && width !== 0) {
      fetchClinics(page, rowsPerPage, sorting);
    }
  }, [page, rowsPerPage, sorting, fetchClinics, isMobile, width]);

  // Update the query parameters when the state changes
  useEffect(() => {
    // Update the 'page' query parameter with the updated value
    if (page) {
      searchParams.set("page", page);
    } else {
      searchParams.delete("page");
    }

    // Update the 'limit' query parameter with the updated value
    if (rowsPerPage) {
      searchParams.set("limit", rowsPerPage);
    } else {
      searchParams.delete("limit");
    }

    // Update the 'sort' and 'order' query parameter with the updated value
    if (sorting) {
      searchParams.set("sort", sorting.sort);
      searchParams.set("order", sorting.order);
    } else {
      searchParams.delete("sort");
      searchParams.delete("order");
    }

    // Update the search params object new values
    setSearchParams(searchParams);
  }, [page, rowsPerPage, sorting, searchParams, setSearchParams]);

  const handleSortingClick = (name = "") => {
    if (name) {
      setSorting((prevSorting) => {
        return {
          sort: name,
          order: prevSorting.sort === name ? (prevSorting.order ? 0 : 1) : 1,
          // abort: prevSorting.abort
        };
      });
    }
  };
  const handleToggleStatus = (id, value) => {
    setSelectedRowStatus({ clinic_id: id, current_clinic_status: value });
    setShowConfirmationModal(true);
  };

  const copyToClipboardFallback = (custom_url) => {
    // Create a temporary input element
    const input = document.createElement("input");

    // Set its value to the text you want to copy
    input.value = `${window.location.origin}/${custom_url}/login`;

    // Append it to the body
    document.body.appendChild(input);

    // Select the text inside the input
    input.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(input);

    console.log("URL copied to clipboard by fallback method");
  };

  const copyToClipboard = async (custom_url) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/${custom_url}/login`
        );
        notify("URL copied to clipboard.", "success");
        console.log("URL copied to clipboard");
      } catch (err) {
        console.error(
          "Failed to copy URL to clipboard using Clipboard API",
          err
        );
        // Fallback to the alternative method
        copyToClipboardFallback(custom_url);
      }
    } else {
      // Fallback to the alternative method if Clipboard API is not supported
      copyToClipboardFallback(custom_url);
    }
  };

  const handleStatusChange = async (active_status) => {
    if (
      (active_status === "disable" && inputValue === "disable") ||
      active_status === "enable"
    ) {
      try {
        let res = await updateEntityStatus(
          masterReference.COMPANY,
          entityId,
          active_status === "disable" ? 0 : 1
        );
        if (res.isOk) {
          fetchClinics(page, rowsPerPage, sorting);
        }
      } catch (error) {
        console.error(error);
      }
      handleClose();
    } else {
      notify("Please type disable.", "error");
    }
  };

  // const handleStatusChange = async () => {
  //   const res = await editIndividualClinicStatusApi(selectedRowStatus);
  //   // if (res.status === 200) {
  //   //   const updateRowData = rows.map((row) => {
  //   //     if (row.id === selectedRowStatus.clinic_id) {
  //   //       return {
  //   //         ...row,
  //   //         is_active: Number(!selectedRowStatus.current_clinic_status)
  //   //       };
  //   //     }
  //   //     return row;
  //   //   });
  //   //   setShowConfirmationModal(false);
  //   //   setRows(updateRowData);
  //   //   // notify(res.message, 'success');
  //   // } else {
  //   //   for (const [key, value] of Object.entries(res.error)) {
  //   //     notify(`${key}: ${value}`, 'error');
  //   //   }
  //   // }
  // };

  // for mobile view
  // if (isMobile) {
  //   return (
  //     <MobileView
  //       isLoading={isLoading}
  //       isLast={isLast}
  //       page={page}
  //       setPage={setPage}
  //       clinics={rows}
  //       sorting={sorting}
  //       rowsPerPage={rowsPerPage}
  //       // fetchClinics={fetchClinics}
  //       // setRows={setRows}
  //       handleToggleStatus={handleToggleStatus}
  //       showConfirmationModal={showConfirmationModal}
  //       setShowConfirmationModal={setShowConfirmationModal}
  //       // handleStatusChange={handleStatusChange}
  //       selectedRowStatus={selectedRowStatus}
  //       setSelectedRowStatus={setSelectedRowStatus}
  //     />
  //   );
  // }

  return (
    <>
      <Grid container spacing={2} alignItems={"self-start"}>
        <Grid item xs={12} sm={12}>
          <BreadcrumWithTitle title={props.title} marginBottom={"10px"}>
            <AddButtonWidget text="Add Company" url="/companies/add" />
          </BreadcrumWithTitle>
        </Grid>
      </Grid>
      <AppTable>
        <AppTableHead>
          <AppTableRow>
            {columns.map((column, index) => {
              return (
                <AppTableCell
                  width={column.width || "auto"}
                  align={column.align}
                  key={index}
                  onClick={() => handleSortingClick(column.field)}
                >
                  <NoWrap>
                    {column.name}
                    {renderSortIcon(column.field)}
                  </NoWrap>
                </AppTableCell>
              );
            })}
          </AppTableRow>
        </AppTableHead>
        <AppTableBody>
          {isLoading &&
            Array.from({ length: rowsPerPage }, function (_, i) {
              return (
                <AppTableRow key={i}>
                  {Array.from({ length: columns.length }, function (_, i) {
                    return (
                      <AppTableCell key={i} align={columns[i].align}>
                        <Skeleton />
                      </AppTableCell>
                    );
                  })}
                </AppTableRow>
              );
            })}
          {!isLoading &&
            rows.length > 0 &&
            rows.map((row, index) => (
              <AppTableRow key={row.id}>
                <AppTableCell align="left">
                  <Typography>
                    {row.name && row.name.length > 40
                      ? row.name.substring(0, 40) + "..."
                      : row.name}
                  </Typography>
                </AppTableCell>
                {/* <AppTableCell align="left">
                  {row.address.map((val, index) => (
                    <Typography>
                      <>{val.address_line_1 ? val.address_line_1 + ", " : null}</>
                      <>
                        {val.address_line_2 ? val.address_line_2 + ", " : null}
                      </>
                      <>{val.postal_code ? val.postal_code + ", " : null}</>
                      <>{val.city ? val.city + ", " : null}</>
                      <>{val.state ? val.state + ", " : null}</>
                      <>{val.country ? val.country : null}</>
                    </Typography>
                  ))}
                </AppTableCell> */}
                <AppTableCell align="left">
                  {row.contact.map((contact_val, index) =>
                    contact_val.contact_type === "email" ? (
                      <a
                        href={`mailto:${contact_val.value}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography>{contact_val.value}</Typography>
                      </a>
                    ) : contact_val.contact_type === "phone" ? (
                      <a
                        href={`tel:${contact_val.value}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography>{contact_val.value}</Typography>
                      </a>
                    ) : (
                      []
                    )
                  )}
                </AppTableCell>
                <AppTableCell align="left">
                  {row.custom_url ? (
                    <Typography
                      title={`${window.location.origin}/${row.custom_url}/login`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {`${window.location.origin}/${
                        row.custom_url && row.custom_url.length > 15
                          ? row.custom_url.substring(0, 15) + "..."
                          : row.custom_url
                      }/login`}
                      <Button onClick={() => copyToClipboard(row.custom_url)}>
                        <ContentCopyIcon />
                      </Button>
                    </Typography>
                  ) : null}
                </AppTableCell>
                <AppTableCell align="left">
                  <CustomizedSwitches
                    inputProps={{ "aria-label": "toggle Enabled" }}
                    name="is_enabled"
                    checked={Boolean(row.is_enabled)}
                    onChange={() =>
                      handleOpen(row.id, row.is_enabled ? "disable" : "enable")
                    }
                  />
                </AppTableCell>
                <AppTableCell align="left">
                  {/* <IconBtn
                    color="primary"
                    background={colors.lightSkyBlue}
                    onClick={() =>
                      navigate(
                        user.role.toLowerCase() === roles.SUPER_ADMIN
                          ? `/clinics/${row.id}/edit`
                          : '/clinics/edit'
                      )
                    }
                  >
                    <EastIcon />
                  </IconBtn> */}
                  <StyledIconBtn
                    shape="round"
                    size="small"
                    textcolor={colors.white}
                    background={colors.purple}
                    onClick={() =>
                      navigate(
                        // user.role.toLowerCase() === roles.SUPER_ADMIN
                        // ?
                        `/companies/${row.id}/edit`
                        // : '/clinics/edit'
                      )
                    }
                  >
                    <EditIcon fontSize="small" />
                  </StyledIconBtn>
                </AppTableCell>
              </AppTableRow>
            ))}
        </AppTableBody>
      </AppTable>
      <AppPagination
        rowsPerPageOptions={rowsPerPageOptions}
        total={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ pb: 1 }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black" }}
          >
            {statusState === "disable" ? (
              <>
                Confirm company disable by typing{" "}
                <span style={{ color: "red" }}>disable</span> and <br />
                clicking 'Disable' button.
              </>
            ) : (
              "Are you sure you want to enable this company?"
            )}
          </DialogContentText>
          {statusState === "disable" && (
            <TextField
              value={inputValue}
              sx={{ mt: 1 }}
              size="small"
              autoFocus
              fullWidth
              placeholder="disable"
              onChange={(e) => setInputValue(e.target.value)}
              error={inputValue !== "disable"} // Set error prop if value is not "disable"
              // helperText={inputValue !== "disable" ? "Input must be 'disable'" : ""} // Display error message
              InputProps={{
                // Style input border based on error condition
                style: {
                  borderColor: inputValue !== "disable" ? "red" : undefined,
                },
              }}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-end", padding: "20px 24px" }}
        >
          {statusState === "disable" ? (
            <>
              <Button
                variant="outlined"
                color="error"
                style={{ padding: "8px 20px" }}
                // onClick={approved ? handleUnApproved : handleApproved}
                onClick={(e) => handleStatusChange(statusState)}
              >
                {"Disable"}
              </Button>
              <Button
                variant="contained"
                style={{ padding: "8px 20px" }}
                color="primary"
                onClick={handleClose}
              >
                {"Cancel"}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="success"
                // onClick={approved ? handleUnApproved : handleApproved}
                onClick={(e) => handleStatusChange(statusState)}
              >
                {"Enable"}
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose}>
                {"Cancel"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* <ModalWindow
        isVisible={showConfirmationModal}
        visibilityHandler={setShowConfirmationModal}
      >
        <StatusModalContent>
          <Typography>
            {`${
              selectedRowStatus.current_clinic_status
                ? 'Are you sure you want to disable this clinic? If you do, they will not be able to access this portal.'
                : 'Are you sure you want to enable this clinic? If you do, they will be able to access this portal.'
            }`}
          </Typography>
          <div className="button-wrapper">
            <Btn1
              text="Yes"
              type="submit"
              handler={handleStatusChange}
              color={
                selectedRowStatus.current_clinic_status
                  ? colors.error
                  : colors.success
              }
            />
            <Btn1
              text="No"
              type="button"
              textcolor={colors.dark}
              color={colors.bleachWhite}
              handler={() => {
                setShowConfirmationModal(false);
              }}
            />
          </div>
        </StatusModalContent>
      </ModalWindow> */}
    </>
  );
};

export default CompaniesList;
