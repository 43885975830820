import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ChangePasswordFormContainer from "../widgets/ChangePasswordForm";
import Page from "../layout/Page";
import BreadCrumb from "../widgets/BreadCrumbs";
import useAuth from "../hooks/useAuth";
import { Grid } from "@mui/material";
import { useLayoutChange } from "../hooks/useLayoutChange";
import { ContentTitle } from "../layout/Page/style";
import { masterReference } from "../constants/masterData";

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 100vh;
`;

export default function ChangePassword() {
  const { user } = useAuth();
  const { isAdminConsole } = useLayoutChange();
  const { t } = useTranslation();
  const pageTitle = t("Change Password");
  return (
    <div style={{ background: "aliceblue", width: "100%", height: "100%" }}>
      <Page title={t("Change Password")} pageTitleCenter={true}>
        <Grid container alignItems={"self-start"}>
          <Grid item xs={4}>
            {(user.code === masterReference.SUPER_ADMIN ||
              user.code === masterReference.ADMIN) &&
            isAdminConsole ? (
              <BreadCrumb
                match={[
                  { pathname: "/", title: "Home" },
                  { pathname: "", title: t("Change Password") },
                ]}
              />
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <ContentTitle style={{ justifyContent: "center" }}>
              {pageTitle}
            </ContentTitle>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <ChangePasswordFormContainer user={user} />
      </Page>
    </div>
  );
}
