import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router";
import { dimensions } from "../../styles/vars";
import MobileAddButton from "./MobileAddButton";
import { AddButton, Wrapper } from "./style";

const AddButtonWidget = (props) => {
  // Hook declaration
  const navigate = useNavigate();
  // to get device type
  const isLaptop = useMediaQuery(`(min-width: ${dimensions.laptop})`);

  // function to navigate
  const goToAddFormPage = () => {
    navigate(props.url);
  };

  return (
    <>
      {isLaptop && (
        <Wrapper>
          <AddButton
            text={props.text}
            handler={goToAddFormPage}
            color={"#EE741F"}
          />
        </Wrapper>
      )}
      {!isLaptop && <MobileAddButton onClick={goToAddFormPage} />}
    </>
  );
};

AddButtonWidget.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default AddButtonWidget;
