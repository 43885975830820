import React from "react";
import Page from "../layout/Page";
import styled from "styled-components";
import useAuth from "../hooks/useAuth";
import UserProfileForm from "../widgets/UserProfileForm";
import { useLayoutChange } from "../hooks/useLayoutChange";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";
import { masterReference } from "../constants/masterData";

const StepWrapper = styled.div`
  && {
    width: 50%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

function UserProfile(props) {
  let pageTitle = "User Profile";
  const { user } = useAuth();
  const { isAdminConsole } = useLayoutChange();

  return (
    <>
      <Page title={pageTitle} mobileHideTopBar={false}>
        <BreadcrumWithTitle
          path={
            isAdminConsole && (user.code === masterReference.ADMIN || user.code === masterReference.SUPER_ADMIN)
              ? [
                  { pathname: "/", title: "Home" },
                  { pathname: "", title: "User Profile" },
                ]
              : null
          }
          title={pageTitle}
          marginBottom={"10px"}
        />
        <StyledDiv>
          <StepWrapper>
            <UserProfileForm />
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default UserProfile;
