import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import TableCell from "@mui/material/TableCell";

import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Table } from "@mui/material";
import dayjs from "dayjs";
import CalendarFilter from "./calendarFilter";
import useAuth from "../../../../hooks/useAuth";
import { colors } from "../../../../constants/colors";
import InfoLabel from "../../../../components/UI/InfoLabel";
import DateRangePicker from "../../../../widgets/DateRangePicker";
import {
  getTeacherListDropdown,
  getLessonTimeTableDetail,
} from "../../../../services/planning";
import { masterReference } from "../../../../constants/masterData";

const Calendar = ({
  data,
  setData,
  formik,
  open,
  setOpen,
  schoolId,
  courseId,
  setEntityId,
  setEntityType,
  entitiesNameList,
  entitiesList,
  setCourseId,
  setAttendanceDate,
  setTimeTableId,
  setStartDateRange,
  setEndDateRange,
}) => {
  const Timeformat = (time) => {
    const formattedTime = time.split(":").slice(0, 2).join(":");
    return formattedTime;
  };
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [LessonList, setLessonList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [calendarFilterParam, setCalendarFilterParam] = useState({});

  const filterOpen = Boolean(anchorEl);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ------------------set week calender widget----------------
  const weekFormat = "YYYY-MM-DD";
  const weekStartEndFormat = (value) => {
    return `${dayjs(value)
      .startOf("week")
      .add(1, "day")
      .format(weekFormat)} ~ ${dayjs(value)
      .endOf("week")
      .add(1, "day")
      .format(weekFormat)}`;
  };

  const [selectedWeek, setSelectedWeek] = useState(weekStartEndFormat);

  const handleWeekChange = (weekNumber) => {
    setSelectedWeek(weekStartEndFormat(weekNumber));
  };
  // --------------------Function to get all dates between given range , here week range----------------

  function getDatesInRange(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const loadCurrentWeekCalendarData = (
    start_date,
    end_date,
    teacher_id = 0,
    lesson_id = 0
  ) => {
    let requestParams = {
      id: courseId ? courseId : 0,
      start_date: start_date,
      end_date: end_date,
      teacher_id: teacher_id,
      lesson_id: lesson_id,
    };
    let allDatesList = getDatesInRange(start_date, end_date);
    getLessonTimeTableDetail(requestParams).then((res) => {
      if (res.isOk && res.data) {
        if (res.data && res.data.lessons) {
          let selectedData = data;
          selectedData["start_date"] = res.data?.start_date
            ? res.data?.start_date
            : dayjs().startOf("week").add(1, "day").format("YYYY-MM-DD");
          selectedData["end_date"] = res.data?.end_date
            ? res.data?.end_date
            : dayjs().endOf("week").add(1, "day").format("YYYY-MM-DD");
          selectedData["is_default"] = res.data?.is_default
            ? Boolean(parseInt(res.data?.is_default))
            : false;
          selectedData["set_as_default"] = false;
          selectedData["lessons"] = res.data.lessons;
          res.data.lessons.forEach((lessonItem, lessonIndex) => {
            if (lessonItem?.lesson_time_table.length > 0) {
              lessonItem?.lesson_time_table?.forEach((obj, index) => {
                const lessonValues = {
                  [`id`]: obj.id,
                  [`lesson_id`]: lessonItem.lesson_id,
                  [`subject`]: obj.subject,
                  [`day_date`]:
                    obj.day_date ||
                    dayjs(allDatesList[index]).format("YYYY-MM-DD"),
                  [`day_name`]:
                    obj.day_name ||
                    weekdays[
                      allDatesList[index]?.getDay() !== 0
                        ? allDatesList[index]?.getDay() - 1
                        : 6
                    ],
                  [`teacher_id`]: obj.teacher_id,
                  [`room`]: obj.room,
                  [`copy_to_weekdays`]: false,
                };
                selectedData["lessons"][lessonIndex]["lesson_time_table"][
                  index
                ] = lessonValues;
              });
            } else {
              allDatesList.forEach((date, index) => {
                const lessonValues = {
                  id: 0,
                  room: "",
                  course_id: 1,
                  subject: "",
                  day_name:
                    weekdays[date.getDay() !== 0 ? date.getDay() - 1 : 6],
                  day_date: dayjs(date).format("YYYY-MM-DD"),
                  teacher_id: 0,
                  is_default: false,
                  copy_to_weekdays: false,
                };
                selectedData["lessons"][lessonIndex]["lesson_time_table"][
                  index
                ] = lessonValues;
              });
            }
          });
          setData(selectedData);
          setLessonList(selectedData);
        } else {
          formik.resetForm();
        }
        formik.setValues(data);
      }
    });
  };

  useEffect(() => {
    loadCurrentWeekCalendarData(
      selectedWeek.split("~")[0].trim(),
      selectedWeek.split("~")[1].trim(),
      user.code === masterReference.SCHOOL_TEACHER ||
        user.code === masterReference.FOREIGN_INSTITUTE_TEACHER
        ? user.id
        : calendarFilterParam.teacher_id,
      calendarFilterParam.lessons_id
    );
  }, [selectedWeek]);

  useEffect(() => {
    if (calendarFilterParam) {
      loadCurrentWeekCalendarData(
        selectedWeek.split("~")[0].trim(),
        selectedWeek.split("~")[1].trim(),
        user.code === masterReference.SCHOOL_TEACHER ||
          user.code === masterReference.FOREIGN_INSTITUTE_TEACHER
          ? user.id
          : calendarFilterParam.teacher_id,
        calendarFilterParam.lessons_id
      );
    }
  }, [calendarFilterParam, selectedWeek, open]);

  useEffect(() => {
    let requestParams = {
      school_id: schoolId,
    };
    if (user.code === masterReference.HR) {
      requestParams = {
        school_id: formik.values.lessons?.[0]?.entity_id,
        entity_id: formik.values.lessons?.[0]?.entity_id,
        entity_type: formik.values.lessons?.[0]?.entity_type,
      };
    }
    getTeacherListDropdown(requestParams).then((res) => {
      if (res?.isOk && res?.data) {
        setTeacherList(res.data);
      }
    });
  }, [formik.values.lessons]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"lg"}
      minHeight={"500px"}
      fullWidth={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            height: "70%",
          },
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: colors.red,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid
              item
              xs={5}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <DateRangePicker
                selectedWeek={selectedWeek}
                onWeekChange={handleWeekChange}
                weekStartEndFormat={weekStartEndFormat}
              />
            </Grid>
            <Grid
              item
              xs={5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography variant="h5" style={{ marginLeft: "15%" }}>
                Timetable
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip title="Filter">
                <IconButton
                  onClick={handleFilterClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "Filter" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <FilterAltIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={"12.5"}></TableCell>

                {weekdays.map((day, index) => (
                  <TableCell key={index} align="center" width={"12.5"}>
                    <strong>{day}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {LessonList.lessons?.map((lessonItem, lessonIndex) => (
                <TableRow key={lessonIndex}>
                  <TableCell width={"12.5"}>
                    <div style={{ width: "98px" }}>
                      <Typography>{lessonItem.lesson_name}</Typography>
                      <Typography fontSize={15}>{`(${Timeformat(
                        lessonItem.start_time
                      )}-${Timeformat(lessonItem.end_time)})`}</Typography>
                    </div>
                  </TableCell>
                  {lessonItem?.lesson_time_table?.map((date, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      width={"12.5"}
                      onClick={() => {
                        if (
                          formik.values.lessons?.[lessonIndex]
                            ?.lesson_time_table?.[index]?.subject
                        ) {
                          setOpen(false);
                          setStartDateRange(formik.values.start_date);
                          setEndDateRange(formik.values.end_date);
                          setAttendanceDate(
                            formik.values.lessons?.[lessonIndex]
                              ?.lesson_time_table?.[index]?.day_date
                          );
                          setTimeTableId(
                            formik.values.lessons?.[lessonIndex]
                              ?.lesson_time_table?.[index]?.id
                          );
                        }
                      }}
                      sx={{
                        cursor: date.subject ? "pointer" : "auto",
                        "&:hover": {
                          backgroundColor: date.subject
                            ? colors.background
                            : "",
                        },
                      }}
                    >
                      <div>
                        <Typography style={{ textAlign: "center" }}>
                          <InfoLabel
                            info={
                              parseInt(calendarFilterParam.teacher_id) ===
                                parseInt(
                                  formik.values.lessons?.[lessonIndex]
                                    ?.lesson_time_table?.[index]?.teacher_id
                                ) &&
                              formik.values.lessons?.[lessonIndex]
                                ?.lesson_time_table?.[index]?.subject
                            }
                          />
                        </Typography>
                        <Typography style={{ textAlign: "center" }}>
                          <InfoLabel
                            info={
                              parseInt(calendarFilterParam.teacher_id) ===
                                parseInt(
                                  formik.values.lessons?.[lessonIndex]
                                    ?.lesson_time_table?.[index]?.teacher_id
                                ) &&
                              teacherList?.find(function (option, idx) {
                                return (
                                  option.value ===
                                  parseInt(
                                    formik.values.lessons?.[lessonIndex]
                                      ?.lesson_time_table?.[index]?.teacher_id
                                  )
                                );
                              })?.label
                            }
                          />
                        </Typography>
                        <Typography style={{ textAlign: "center" }}>
                          <InfoLabel
                            info={
                              parseInt(calendarFilterParam.teacher_id) ===
                                parseInt(
                                  formik.values.lessons?.[lessonIndex]
                                    ?.lesson_time_table?.[index]?.teacher_id
                                ) &&
                              formik.values.lessons?.[lessonIndex]
                                ?.lesson_time_table?.[index]?.room
                            }
                          />
                        </Typography>
                      </div>
                    </TableCell>
                  ))}
                  {Array.from(
                    { length: 7 - lessonItem?.lesson_time_table.length },
                    (_, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        width={"12.5"}
                      ></TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <CalendarFilter
            setCourseId={setCourseId}
            schoolId={schoolId}
            anchorEl={anchorEl}
            open={filterOpen}
            setEntityId={setEntityId}
            setEntityType={setEntityType}
            entitiesNameList={entitiesNameList}
            entitiesList={entitiesList}
            handleClose={handleFilterClose}
            setCalendarFilterParam={setCalendarFilterParam}
            selectedWeek={selectedWeek}
          />
        </>
      </DialogContent>
    </Dialog>
  );
};

export default Calendar;
