import React from "react";
import Page from "../layout/Page";
import styled from "styled-components";
import AddCompanyForm from "../widgets/AddCompany";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";

const StepWrapper = styled.div`
  && {
    width: 85%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

function AddCompany(props) {
  let pageTitle = "Add Company";

  return (
    <>
      <Page title={pageTitle} mobileHideTopBar={false} pageTitleCenter={true}>
        <BreadcrumWithTitle
          path={[
            { pathname: "/companies", title: "Companies" },
            { pathname: "/companies/add", title: "Add Company" },
          ]}
          title={pageTitle}
        />
        <StyledDiv>
          <StepWrapper>
            <AddCompanyForm />
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default AddCompany;
