import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";

// Importing sub-components
import Print from "./print";
import Generate from "./generate";
import ApplicantGeneral from "./General";
import ApplicantDocuments from "./Documents";
import ApplicantContactInfo from "./ContactInfo";
import ApplicantCockpit from "./Cockpit";
import useAuth from "../../../hooks/useAuth";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import ErrorTabItem from "../../../components/UI/ErrorTabItem";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { convertJSONToFormData, toTitleCase } from "../../../utils/utility";
import { getEntityTypeList, getEntitiesList } from "../../../services/master";
import {
  applicantStatus,
  entitiesList,
  masterContact,
  masterReference,
} from "../../../constants/masterData";
import Reports from "./Reports";
import { getGoals } from "../../../services/schools";
import { getApplicantDetail } from "../../../services/applicants";
import { validateAddress, isAddressSame } from "../../../utils/common";

const Applicant = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [stageNameList, setStageNameList] = useState([]);

  const validCodes = [
    masterReference.HR.toLowerCase(),
    masterReference.AGENCY_USER.toLowerCase(),
  ];

  // Define initial form values
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address_id: 0,
    address_line_1: "",
    address_line_2: "",
    is_overseas: false,
    postal_code: "",
    city: "",
    state: "",
    country: "",
    os_address_id: 0,
    os_address_line_1: "",
    os_address_line_2: "",
    os_is_overseas: true,
    os_postal_code: "",
    os_city: "",
    os_state: "",
    os_country: "",
    employer_address: "",
    current_employer: "",
    entity_type: "",
    entity_id: 0,
    company_id: 0,
    school_id: 0,
    course_id: 0,
    course_name: "",
    course_start_time: "",
    course_end_time: "",
    reports: [],
    delete_report_data: [],
    status: "new_applicant",
    goal_id: null,
    goal_flow_guid: null,
    student_goal_flow_guid: null,
    qualification: "",
    experience: "",
    autofill_home_country_address: false,
    role: validCodes.includes(user.code.toLowerCase()),
  };

  const validateSchema =
    //  validCodes.includes(user.code.toLowerCase())
    //   ?
    Yup.object()
      .shape({
        first_name: Yup.string().required("REQUIRED"),
        last_name: Yup.string().required("REQUIRED"),
        role: Yup.boolean(),
        entity_id: Yup.string().when("role", (role, schema) => {
          if (Boolean(role?.[0]) === true) {
            return Yup.string().required("REQUIRED");
          } else {
            return schema;
          }
        }),
        entity_type: Yup.string().when("role", (role, schema) => {
          if (Boolean(role?.[0]) === true) {
            return Yup.string().required("REQUIRED");
          } else {
            return schema;
          }
        }),
        email: Yup.string().email("Enter a valid Email").required("REQUIRED"),
        address_line_1: Yup.string().required("REQUIRED"),
        postal_code: Yup.string().required("REQUIRED"),
        city: Yup.string().required("REQUIRED"),
        state: Yup.string().required("REQUIRED"),
        country: Yup.string().required("REQUIRED"),
        os_address_line_1: Yup.string(),
        os_address_line_2: Yup.string(),
        os_city: Yup.string(),
        os_state: Yup.string(),
        os_postal_code: Yup.string(),
        os_country: Yup.string(),

        goal_id: Yup.string().required("REQUIRED"),
        goal_flow_guid: Yup.string().required("REQUIRED"),
        qualification: Yup.string().required("REQUIRED"),
        // student_goal_flow_guid: Yup.string().test(
        //   "customValidation",
        //   "Select a flow",
        //   (value, validationContext) => {
        //     const errors = [];
        //     if (!value && validationContext.parent.status === "approved") {
        //       errors.push(
        //         validationContext.createError({
        //           path: validationContext.path,
        //           message: "Student Flow is required",
        //         })
        //       );
        //     }

        //     if (errors.length > 0) {
        //       notify("Student Flow is required", "error");

        //       throw new Yup.ValidationError(errors);
        //     }

        //     return true;
        //   }
        // ),
      })

      .test(
        "conditional-validation",
        "Invalid address fields",
        validateAddress
      );

  // State to hold form data
  const [printData, setPrintData] = useState({});
  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [entitiesNameList, setEntitiesNameList] = useState([]);
  const [goalsList, setGoalsList] = useState([]);

  //  for getting selected record from the list
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();
  const [continueFlag, setContinueFlag] = useState({});
  const [continueDisableFlag, setContinueDisableFlag] = useState(false);
  const [firstContinueRender, setFirstContinueRender] = useState(false);
  const [otherStages, setOtherStages] = useState([]);

  // to show notify message
  const { notify } = useNotistack();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    handleClose();
    navigate(
      `/${user.company_url}/${user.custom_url}/data/students?selected=first`
    );
  };

  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    printRecord,
    setPrintRecord,
    generateRecord,
    setGenerateRecord,
    setDisableGenerate,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  // setting updated recordvalue from the selectedRecord
  useEffect(() => {
    if (selectedRecord && selectedRecord.first_name) {
      setFirstContinueRender(false);
      // -------------get applicant documents from DB---------------
      let requestParams = {
        id: selectedRecord.id,
      };
      let selectedData = {
        first_name: "",
        last_name: "",
        email: "",
        mobile_id: "",
        mobile: "",
        address_id: 0,
        address_line_1: "",
        address_line_2: "",
        postal_code: "",
        city: "",
        state: "",
        country: "",
        os_address_id: 0,
        os_address_line_1: "",
        os_address_line_2: "",
        os_postal_code: "",
        os_city: "",
        os_state: "",
        os_country: "",
        employer_address: "",
        current_employer: "",
        entity_type: "",
        entity_id: 0,
        company_id: 0,
        school_id: 0,
        course_id: 0,

        course_name: "",
        course_start_time: "",
        course_end_time: "",
        reports: [],
        delete_report_data: [],
        status: "",
        goal_id: 0,
        goal_flow_guid: 0,
        student_goal_flow_guid: 0,
        qualification: "",
        experience: "",
        autofill_home_country_address: false,
      };

      let stage_name_list = [];
      let stage_label_list = [];

      selectedData["flow_stage_list"] = selectedRecord?.flow_stage_list;
      let stageName = "";
      let count = 0;
      // --------------------set dynamic applicant document details--------------------
      for (var ele in selectedData["flow_stage_list"]) {
        // ----------------------------set stageFlagIndex sub stage wise-----------------

        if (stageName === selectedData["flow_stage_list"][ele]["stage_name"]) {
          count = count + 1;
        } else {
          stageName = selectedData["flow_stage_list"][ele]["stage_name"];
          count = 0;
        }
        selectedData["flow_stage_list"][ele]["stage_flag_index"] = count;

        var field_json = selectedData["flow_stage_list"][ele]["field_json"];
        let task_count = 0;
        for (var fields of field_json) {
          var field_name = fields["field_guid"].toLowerCase();

          // -------------------check if type is task---------------------

          if (fields["type"] === "task") {
            task_count = task_count + 1;
          }
          selectedData["flow_stage_list"][ele]["task_count"] = task_count;

          fields["submission_date_value"] = fields["submission_date_value"]
            ? dayjs(fields["submission_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["start_date_value"] = fields["start_date_value"]
            ? dayjs(fields["start_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["expiry_date_value"] = fields["expiry_date_value"]
            ? dayjs(fields["expiry_date_value"]).format("YYYY-MM-DD")
            : "";
          fields["completion_date_value"] = fields["completion_date_value"]
            ? dayjs(fields["completion_date_value"]).format("YYYY-MM-DD")
            : "";
        }

        // -----------append all stages in list-------------------
        var stage_name = selectedData["flow_stage_list"][ele]["stage_guid"];
        // .replace(" ", "_");
        if (!stage_name_list.includes(stage_name)) {
          stage_name_list.push(`${stage_name}`);
        }
        var stage_label = selectedData["flow_stage_list"][ele]["stage_name"];
        if (!stage_label_list.includes(stage_label)) {
          stage_label_list.push(
            selectedData["flow_stage_list"][ele]["stage_name"]
          );
        }
      }

      selectedData["stageLabelList"] = stage_label_list;

      setStageNameList(stage_name_list);
      let stagesFlagList = {};
      // set stage name flag

      stage_name_list.forEach((ele, index) => {
        selectedData[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`] =
          selectedRecord?.[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`];

        stagesFlagList[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`] =
          selectedData[`${ele.split("_")[0].replaceAll(" ", "_")}_flag`];
      });

      selectedData["stageFlagList"] = stagesFlagList;

      // -----------unique stage flags- ----------
      // let jsonObject = stagesFlagList.map(JSON.stringify);
      // let uniqueSet = new Set(jsonObject);
      // let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      selectedData.first_name = selectedRecord?.first_name;

      selectedData.goal_id = selectedRecord.goal_id;
      selectedData.goal_flow_guid = selectedRecord?.goal_flow_guid;
      selectedData.student_goal_flow_guid =
        selectedRecord?.student_goal_flow_guid?.[0]?.student_goal_flow_guid;
      selectedData.last_name = selectedRecord?.last_name;
      selectedData.status = selectedRecord?.status;
      if (selectedRecord?.reports && selectedRecord.reports.length > 0) {
        selectedRecord.reports.forEach((report) => {
          selectedData.reports.push({
            id: report?.id,
            comment: report?.comment,
          });
        });
      }
      if (selectedRecord?.contact) {
        selectedRecord.contact.forEach((contact) => {
          if (contact.contact_type === "email") {
            selectedData.email_id = contact?.contact_id;
            selectedData.email = contact?.value;
          } else if (contact.contact_type === "phone") {
            selectedData.mobile_id = contact?.contact_id;
            selectedData.mobile = contact?.value;
          }
        });
      }
      if (
        selectedRecord?.course_detail &&
        Object.keys(selectedRecord?.course_detail)?.length > 0
      ) {
        selectedData.course_id = selectedRecord?.course_detail?.id;
        selectedData.school_id = selectedRecord?.course_detail?.school_id;
        selectedData.course_name = selectedRecord?.course_detail?.name;
        selectedData.course_start_time = dayjs(
          selectedRecord?.course_detail.start_time
        );
        selectedData.course_end_time = dayjs(
          selectedRecord?.course_detail.end_time
        );
      }
      let currentAddress = {};
      let homeAddress = {};
      selectedRecord.address.forEach((address) => {
        if (address.address_type === "current") {
          currentAddress = address;

          selectedData.address_id = address.address_id;
          selectedData.address_line_1 = address.address_line_1;
          selectedData.address_line_2 = address.address_line_2;
          selectedData.postal_code = address.postal_code;
          selectedData.state = address.state;
          selectedData.city = address.city;
          selectedData.country = address.country;
        } else {
          homeAddress = address;

          selectedData.os_address_id = address.address_id;
          selectedData.os_address_line_1 = address.address_line_1;
          selectedData.os_address_line_2 = address.address_line_2;
          selectedData.os_postal_code = address.postal_code;
          selectedData.os_state = address.state;
          selectedData.os_city = address.city;
          selectedData.os_country = address.country;
        }
      });
      if (currentAddress && homeAddress) {
        let isHomeAddressSame = isAddressSame(currentAddress, homeAddress);
        if (isHomeAddressSame) {
          selectedData.autofill_home_country_address = true;
        }
      }
      selectedData.employer_address = selectedRecord.employer_address;
      selectedData.current_employer = selectedRecord.current_employer;
      selectedData.entity_type = selectedRecord.entity_type;
      selectedData.entity_id = selectedRecord.entity_id;
      selectedData.company_id = selectedRecord.company_id;
      selectedData.qualification = selectedRecord.qualification;
      selectedData.experience = selectedRecord.experience;

      // -----------unique stage flags- ----------
      // let jsonObject = stagesFlagList.map(JSON.stringify);
      // let uniqueSet = new Set(jsonObject);
      // let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      setDisableGenerate(false);
      console.log(selectedData);
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER ||
        user.code === masterReference.HR ||
        user.code === masterReference.AGENCY_USER
      ) {
        setEditable(true);
      }
      setPrintData(selectedData);

      setContinueFlag({});
    } else {
      formik.resetForm();
    }
  }, [selectedRecord]);

  // Define a function to handle form submission
  const handleSubmit = async (values) => {
    console.log("value----------------------------------------------", values);

    if (
      values.status === applicantStatus.APPROVED &&
      !values.student_goal_flow_guid
    ) {
      notify("Student Flow is required", "error");
      return;
    }
    setIsLoading(true);
    let submittingData = {
      first_name: values.first_name,
      last_name: values.last_name,
      gender: values.gender,
      dob: values.dob || "2023-09-07",
      place_of_birth: "india",
      citizenship: values.citizenship,
      situation: values.situation,
      religion: values.religion,
      health_insurance_provider: values.health_insurance_provider,
      iban: values.iban,
      tax_id: values.tax_id,
      social_security_number: values.social_security_number,
      qualification: values.qualification,
      work_contract: values.work_contract,
      entity_type: values.entity_type,
      entity_id: values.entity_id,
      course_id: values.course_id,
      reports: values.reports.filter((report) => report.comment.trim() !== ""),
      delete_report_data: values.delete_report_data,
      status: values.status,
      goal_id: values.goal_id,
      goal_flow_guid: values.goal_flow_guid,
      student_goal_flow_guid: values.student_goal_flow_guid,
      experience: values.experience,
      address: [
        {
          address_id: values.address_id,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          address_type: "current",
          postal_code: values.postal_code,
          city: values.city,
          state: values.state,
          country: values.country,
        },
      ],
      contact: [
        {
          contact_id: values.email_id || 0,
          contact_type: masterContact.EMAIL,
          value: values.email,
        },
        {
          contact_id: values.mobile_id || 0,
          contact_type: masterContact.PHONE,
          value: values.mobile.replace("-", "").replace(/\s+/g, ""),
        },
      ],
    };

    values["flow_stage_list"]?.map((ele, index) => {
      // let stageName = ele.stage_name.replaceAll(" ", "_");
      // let sectionName = ele.sub_stage_name.replaceAll(" ", "_");

      let stageName = ele.stage_guid;
      let sectionName = ele.sub_stage_guid;

      // ---------extract sub section name and field name----------------

      ele.field_json.map((item, eleIndex) => {
        if (item.submission_date_value) {
          item.submission_date_value =
            typeof item.submission_date_value === "string"
              ? item.submission_date_value
              : item.submission_date_value.format("YYYY-MM-DD");
        }
        if (item.start_date_value) {
          item.start_date_value =
            typeof item.start_date_value === "string"
              ? item.start_date_value
              : item.start_date_value.format("YYYY-MM-DD");
        }
        if (item.expiry_date_value) {
          item.expiry_date_value =
            typeof item.expiry_date_value === "string"
              ? item.expiry_date_value
              : item.expiry_date_value.format("YYYY-MM-DD");
        }
        if (item.completion_date_value) {
          item.completion_date_value =
            typeof item.completion_date_value === "string"
              ? item.completion_date_value
              : item.completion_date_value.format("YYYY-MM-DD");
        }
        if (item.type === "date_field") {
          item.value =
            typeof item.value === "string" && item.value?.length > 0
              ? item.value
              : typeof item.value === "object"
              ? item.value.format("YYYY-MM-DD")
              : null;
        }

        // --------------set dynamic documents stage values -------------

        // let fieldName = item.field_name.replaceAll(" ", "_").toLowerCase();
        let fieldName = item.field_guid;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_document_color`
        ] = item.document_color;
        submittingData[`${stageName}_${sectionName}_${fieldName}_notes`] =
          item.notes;
        submittingData[`${stageName}_${sectionName}_${fieldName}`] = item.value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_type`] =
          item.type;
        submittingData[`${stageName}_${sectionName}_${fieldName}_expiry_date`] =
          item.expiry_date_value;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_submission_date`
        ] = item.submission_date_value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_start_date`] =
          item.start_date_value;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_completion_date`
        ] = item.completion_date_value;
        submittingData[`${stageName}_${sectionName}_${fieldName}_task_check`] =
          item.task_check_value ? item.task_check_value : 0;
        submittingData[
          `${stageName}_${sectionName}_${fieldName}_validity_check`
        ] = item.validity_check_value ? item.validity_check_value : 0;
      });

      // ele.field_json.map((item, eleIndex) => {
      //   if (item.submission_date_value) {
      //     item.submission_date_value =
      //       typeof item.submission_date_value === "string"
      //         ? item.submission_date_value
      //         : item.submission_date_value.format("YYYY-MM-DD");
      //   }
      //   if (item.expiry_date_value) {
      //     item.expiry_date_value =
      //       typeof item.expiry_date_value === "string"
      //         ? item.expiry_date_value
      //         : item.expiry_date_value.format("YYYY-MM-DD");
      //   }
      //   if (item.type === "date_field") {
      //     item.value =
      //       typeof item.value === "string" && item.value?.length > 0
      //         ? item.value
      //         : typeof item.value === "object"
      //         ? item.value.format("YYYY-MM-DD")
      //         : null;
      //   }

      //   // --------------set dynamic documents stage values -------------

      //   let fieldName = item.field_name.replace(" ", "_").toLowerCase();

      //   submittingData[`${stageName}_${fieldName}`] = item.value;
      //   submittingData[`${stageName}_${fieldName}_type`] = item.type;
      //   submittingData[`${stageName}_${fieldName}_expiry_date`] =
      //     item.expiry_date_value;
      //   submittingData[`${stageName}_${fieldName}_submission_date`] =
      //     item.submission_date_value;

      //   submittingData[`${stageName}_${fieldName}_validity_check`] =
      //     item.validity_check_value ? item.validity_check_value : 0;
      // });
    });

    if (
      values.os_address_id ||
      values.os_address_line_1 ||
      values.os_address_line_2 ||
      values.os_postal_code ||
      values.os_city ||
      values.os_state ||
      values.os_country
    ) {
      submittingData["address"].push({
        address_id: values.os_address_id,
        address_line_1: values.os_address_line_1,
        address_line_2: values.os_address_line_2,
        address_type: "overseas",
        postal_code: values.os_postal_code,
        city: values.os_city,
        state: values.os_state,
        country: values.os_country,
      });
    }

    if (user.code === masterReference.SUPER_ADMIN) {
      submittingData["company_id"] = values.company_id;
    }

    console.log("====================================");
    console.log("submittingData:::", submittingData);
    console.log("====================================");
    let requestParams = {
      id: requestId,
      data: convertJSONToFormData(submittingData),
    };
    // return;

    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setEditable(true);
      await setRequestId(null);
      setRequestId(requestId ? requestParams.id : res.data.applicant_id);
      setSelectedIndex(requestId ? requestId : res.data.applicant_id);

      if (requestId) {
        notify("Applicant Information Updated", "success");
      } else {
        notify("Applicant Added", "success");
      }

      if (values.status === "approved" && values.course_id > 0) {
        handleClickOpen();
      }
    } else {
      console.log("res.error>>", res.error);
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object") {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  // Initialize Formik with initial values and submission handler
  const formik = useFormik({
    initialValues: data,

    validationSchema: validateSchema,

    onSubmit: handleSubmit,
  });

  // for updating the record with formik data
  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  // Effect to submit the form when isFormSubmitRequested is true
  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [
    isEditable,
    isFormSubmitRequested,
    formik.handleSubmit,
    setFormSubmitRequested,
  ]);

  // to get data from entity name list api
  useEffect(() => {
    if (
      validCodes.includes(user.code.toLowerCase()) &&
      formik.values.entity_type
    ) {
      let requestParams = {
        entity_type: formik.values.entity_type,
        company_id: formik.values.company_id,
      };
      getEntitiesList(requestParams)
        .then((res) => {
          if (res.isOk) {
            // Convert options to have labels in uppercase
            const capitalizeEntitesName = res.data.map((option) => ({
              ...option,
              label: toTitleCase(option.label),
            }));
            setEntitiesNameList(capitalizeEntitesName);
          }
        })
        .catch((error) => {
          console.log("error>>>>>>>>>>");
          console.error("Error fetching data:", error);
        });
    }
  }, [formik.values.entity_type, formik.values.company_id]);

  // to get School goals list from list api
  useEffect(() => {
    let requestParams = {
      entity_type: formik.values.entity_type,
      entity_id: formik.values.entity_id,
    };
    if (
      (validCodes.includes(user.code.toLowerCase()) &&
        formik.values.entity_id &&
        formik.values.entity_type) ||
      !validCodes.includes(user.code.toLowerCase())
    ) {
      getGoals(requestParams)
        .then((res) => {
          if (res.isOk) {
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            setGoalsList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [
    formik.values.entity_id,
    formik.values.entity_type,
    formik.values.company_id,
  ]);

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState(0);

  // Function to handle tab change
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
    setIsDirty(true);
  };

  const handlePrintClose = () => {
    setPrintRecord(false);
  };

  const checkDisabled = (stageName, flagObj) => {
    let continueFlagObj = flagObj
      ? { ...continueFlag, ...flagObj }
      : { ...continueFlag };

    formik.setFieldValue("stageFlagList", continueFlagObj);

    continueFlagObj[`${stageName}_flag`]?.forEach((ele, index) => {
      if (ele?.length > 1 && ele?.some((element) => element === false)) {
        return true;
      } else if (ele?.length === 1 && ele?.[0] === false) {
        return true;
      } else if (ele[index] === false) {
        return true;
      } else {
        return false;
      }
    });
  };

  useEffect(() => {
    setOtherStages([]);
    formik.values.stageLabelList?.forEach((stages, index) => {
      stages = toTitleCase(stages);
      setContinueFlag(formik.values["stageFlagList"]); // API DOCUMENTS FLAG AND STAGE 2 FLAG
      let stageGuid = Object.keys(formik.values.stageFlagList)[index]?.split(
        "_"
      )[0];
      if (stages.toLowerCase() !== "documents") {
        {
          setOtherStages((prevStages) => [
            ...prevStages,
            {
              label: stages,
              description: (
                <ApplicantDocuments
                  formik={formik}
                  user={user}
                  isCockpit={true}
                  isStudent={false}
                  setContinueFlag={setContinueFlag}
                  setContinueDisableFlag={setContinueDisableFlag}
                  continueDisableFlag={continueDisableFlag}
                  checkDisabled={checkDisabled}
                  stageName={stageGuid}
                />
              ),
            },
          ]);
        }
      }
    });
  }, [formik.values.flow_stage_list, formik.values.stageFlagList]);

  return (
    <>
      <Print
        open={printRecord}
        onClose={handlePrintClose}
        printData={printData}
        entitiesNameList={entitiesNameList}
        goalsList={goalsList}
      />

      <OverlayLoader show={isLoading}>
        {/* Form */}
        {generateRecord ? (
          <div
            style={{
              padding: "24px",
            }}
            id="simple-tabpanel-2"
            aria-labelledby="simple-tab-2"
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setGenerateRecord(false);
                  setDisableGenerate(false);
                }}
              >
                Show Other Tabs
              </Button>
            </div>
            <br />
            <Generate
              requestId={requestId}
              entity_id={formik.values.entity_id}
              entity_type={formik.values.entity_type}
            />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div>
              {/* StyledTab for tab navigation */}
              <StyledTab
                value={activeTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colors.secondary,
                  },
                }}
              >
                {selectedRecord && selectedRecord.first_name && (
                  <TabItem label={"Cockpit"} {...a11yProps(0)} />
                )}
                <ErrorTabItem
                  label={"General"}
                  {...a11yProps(1)}
                  error={
                    (formik.touched.first_name &&
                      Boolean(formik.errors.first_name)) ||
                    (formik.touched.last_name &&
                      Boolean(formik.errors.last_name)) ||
                    (formik.touched.goal_id &&
                      Boolean(formik.errors.goal_id)) ||
                    (formik.touched.course_id &&
                      Boolean(formik.errors.course_id)) ||
                    (formik.touched.goal_flow_guid &&
                      formik.errors.goal_flow_guid) ||
                    (formik.touched.entity_type &&
                      Boolean(formik.errors.entity_type)) ||
                    (formik.touched.entity_id &&
                      Boolean(formik.errors.entity_id)) ||
                    (formik.touched.qualification &&
                      Boolean(formik.errors.qualification))
                  }
                />
                <ErrorTabItem
                  label={"Contact Information"}
                  {...a11yProps(2)}
                  error={
                    (formik.touched.email && Boolean(formik.errors.email)) ||
                    (formik.touched.address_line_1 &&
                      Boolean(formik.errors.address_line_1)) ||
                    (formik.touched.postal_code &&
                      Boolean(formik.errors.postal_code)) ||
                    (formik.touched.country &&
                      Boolean(formik.errors.country)) ||
                    (formik.touched.city && Boolean(formik.errors.city)) ||
                    (formik.touched.state && Boolean(formik.errors.state)) ||
                    (formik.touched.country &&
                      Boolean(formik.errors.country)) ||
                    (formik.touched.os_address_line_1 &&
                      Boolean(formik.errors.os_address_line_1)) ||
                    (formik.touched.os_postal_code &&
                      Boolean(formik.errors.os_postal_code)) ||
                    (formik.touched.os_country &&
                      Boolean(formik.errors.os_country)) ||
                    (formik.touched.os_city &&
                      Boolean(formik.errors.os_city)) ||
                    (formik.touched.os_state &&
                      Boolean(formik.errors.os_state)) ||
                    (formik.touched.os_country &&
                      Boolean(formik.errors.os_country))
                  }
                />
                {selectedRecord && selectedRecord.first_name && (
                  <TabItem label={"Documents"} {...a11yProps(3)} />
                )}
                <TabItem label={"Reports"} {...a11yProps(4)} />
              </StyledTab>

              {/* Cockpit Tab Panel */}
              {selectedRecord && selectedRecord.first_name && (
                <div
                  style={{
                    // padding: "24px",
                    display: activeTab === 0 ? "" : "none",
                  }}
                  id="simple-tabpanel-0"
                  aria-labelledby="simple-tab-0"
                >
                  {/* Render the ApplicantCockpit component with formik prop */}
                  <ApplicantCockpit
                    formik={formik}
                    handleFormChange={handleFormChange}
                    checkDisabled={checkDisabled}
                    setContinueFlag={setContinueFlag}
                    continueDisableFlag={continueDisableFlag}
                    setContinueDisableFlag={setContinueDisableFlag}
                    firstContinueRender={firstContinueRender}
                    setFirstContinueRender={setFirstContinueRender}
                    otherStages={otherStages}
                  />
                </div>
              )}

              {/* General Tab Panel */}
              <div
                style={{
                  padding: "24px",
                  display:
                    activeTab ===
                    (selectedRecord && selectedRecord.first_name ? 1 : 0)
                      ? ""
                      : "none",
                }}
                id="simple-tabpanel-0"
                aria-labelledby="simple-tab-0"
              >
                {/* Render the ApplicantGeneral component with formik prop */}
                <ApplicantGeneral
                  formik={formik}
                  handleFormChange={handleFormChange}
                  entitiesList={entitiesList}
                  entitiesNameList={entitiesNameList}
                  goalsList={goalsList}
                />
              </div>

              {/* Contact Information Tab Panel */}
              <div
                style={{
                  padding: "24px",
                  display:
                    activeTab ===
                    (selectedRecord && selectedRecord.first_name ? 2 : 1)
                      ? ""
                      : "none",
                }}
                id="simple-tabpanel-1"
                aria-labelledby="simple-tab-1"
              >
                {/* Render the ApplicantDetails component with formik prop */}
                <ApplicantContactInfo
                  formik={formik}
                  handleFormChange={handleFormChange}
                />
              </div>
              {/* Documents Tab Panel */}
              {selectedRecord && selectedRecord.first_name && (
                <div
                  style={{
                    padding: "14px",
                    display:
                      activeTab ===
                      (selectedRecord && selectedRecord.first_name ? 3 : 2)
                        ? ""
                        : "none",
                  }}
                  id="simple-tabpanel-2"
                  aria-labelledby="simple-tab-2"
                >
                  {/* Render the ApplicantDocuments component with formik prop */}
                  {formik.values.stageFlagList && (
                    <ApplicantDocuments
                      formik={formik}
                      checkDisabled={checkDisabled}
                      setContinueDisableFlag={setContinueDisableFlag}
                      stageName={
                        Object.keys(formik.values.stageFlagList)?.[0]?.split(
                          "_"
                        )[0]
                      }
                    />
                  )}
                </div>
              )}
              {/* Reports Tab Panel */}
              <div
                style={{
                  padding: "24px",
                  display:
                    activeTab ===
                    (selectedRecord && selectedRecord.first_name ? 4 : 2)
                      ? ""
                      : "none",
                }}
                id="simple-tabpanel-2"
                aria-labelledby="simple-tab-2"
              >
                {/* Render the Reports component with formik prop */}
                <Reports formik={formik} handleFormChange={handleFormChange} />
              </div>
            </div>
          </form>
        )}
      </OverlayLoader>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Applicant Approved as Student</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: colors.red,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            This applicant is approved and thus will appear as a student hereby.
            <br />
            {/* This record will no longer appear in the applicant list , it will be
            visible only as Student in the student list. <br /> */}
            On clicking <b>Ok</b> you will be redirected to students sub module.
          </DialogContentText>
        </DialogContent>

        <DialogActions
        // sx={{ mr: "auto", ml: "auto" }}
        >
          <Button onClick={handleContinue} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Applicant;
