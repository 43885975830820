import React from "react";
import Page from "../layout/Page";
import UsersList from "../widgets/UsersList";


export default function UsersListPage() {
  return (
    <>
      <Page title="Users">
        <UsersList title="Users"/>
      </Page>
    </>
  );
}
