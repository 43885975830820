import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { Grid, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";

import useAuth from "../../../../hooks/useAuth";
import { colors } from "../../../../constants/colors";
import { getEntitiesList } from "../../../../services/master";
import { masterReference } from "../../../../constants/masterData";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { getTeacherListDropdown } from "../../../../services/planning";
import CheckboxDropdown from "../../../../components/UI/CheckboxDropdown";
import {
  getTeacherCourseList,
  getDropdownCoursesList,
} from "../../../../services/schools";
import { toTitleCase } from "../../../../utils/utility";
const CalendarFilter = ({
  anchorEl,
  open,
  schoolId,
  setCourseId,
  handleClose,
  setEntityId,
  setEntityType,
  entitiesNameList,
  entitiesList,
  setCalendarFilterParam,
  selectedWeek,
}) => {
  const { user } = useAuth();
  const [LessonList, setLessonList] = useState([]);
  const [CourseList, setCourseList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);

  useEffect(() => {
    if (
      user.code === masterReference.SCHOOL_TEACHER ||
      user.code === masterReference.FOREIGN_INSTITUTE_TEACHER
    ) {
      setCalendarFilterParam({
        teacher_id: user.id,
      });
    }
  }, [user, selectedWeek]);

  const handleSubmit = async (values) => {
    let lessons = values.lessons.map((item) => item.id);
    let param = {
      teacher_id: values.teacher,
      course_id: values.course,
      lessons_id: lessons.join(","),
      entity_type: user.code === masterReference.HR ? values.entity_type : null,
      entity_id: user.code === masterReference.HR ? values.entity_id : null,
    };
    if (user.code === masterReference.HR) {
      setEntityId(values.entity_id);
    }
    setCourseId(values.course);
    setCalendarFilterParam(param);
    handleClose();
  };

  const validateSchema = Yup.object().shape({
    teacher: Yup.number().required("REQUIRED"),
    course: Yup.number().required("REQUIRED"),
    lessons: Yup.array().min(1).required("REQUIRED"),
    entity_type:
      user.code === masterReference.HR
        ? Yup.string().required("REQUIRED")
        : Yup.string(),
    entity_id:
      user.code === masterReference.HR
        ? Yup.string().required("REQUIRED")
        : Yup.string(),
  });

  const filterFormik = useFormik({
    initialValues: {
      teacher:
        user.code === masterReference.SCHOOL_TEACHER ||
        user.code === masterReference.FOREIGN_INSTITUTE_TEACHER
          ? user.id
          : "",
      course: "",
      lessons: [],
      entity_type: entitiesList[0].value,
      entity_id: 0,
    },
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    let requestParams = {
      school_id: schoolId,
      entity_type:
        user.code === masterReference.HR
          ? filterFormik.values.entity_type
          : null,
      entity_id:
        user.code === masterReference.HR ? filterFormik.values.entity_id : null,
    };
    getTeacherListDropdown(requestParams).then((res) => {
      if (res?.isOk && res?.data) {
        res.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setTeacherList(res.data);
      }
    });
  }, [schoolId, filterFormik.values.entity_id]);

  useEffect(() => {
    if (user.code === masterReference.HR && filterFormik.values.entity_type) {
      setEntityType(filterFormik.values.entity_type);
    }
  }, [filterFormik.values.entity_type]);

  useEffect(() => {
    if (filterFormik.values.teacher) {
      getDropdownCoursesList(
        schoolId,
        user.code === masterReference.HR
          ? filterFormik.values.entity_type
          : null,
        user.code === masterReference.HR ? filterFormik.values.entity_id : null,
        filterFormik.values.teacher
      ).then((res) => {
        if (res?.isOk && res?.data) {
          res.data.forEach((ele) => {
            ele.label = toTitleCase(ele.label);
          });
          setCourseList(res.data);

          if (res.data.length >= 1) {
            filterFormik.setFieldValue("course", res.data?.[0]?.value);
          }
        }
      });
    }
  }, [filterFormik.values.teacher]);

  useEffect(() => {
    if (filterFormik.values.teacher) {
      let filerRequestParams = {
        teacher_id: filterFormik.values.teacher,
        entity_type:
          user.code === masterReference.HR
            ? filterFormik.values.entity_type
            : null,
        entity_id:
          user.code === masterReference.HR
            ? filterFormik.values.entity_id
            : null,
      };
      getTeacherCourseList(filerRequestParams).then((res) => {
        if (res?.isOk && res?.data) {
          res.data.forEach((ele) => {
            ele.label = toTitleCase(ele.label);
          });
          setLessonList(res.data);
        }
      });
    } else {
      setLessonList([]);
    }
  }, [filterFormik.values.teacher]);

  useEffect(() => {
    filterFormik.setFieldValue("lessons", [
      LessonList.filter((item) => item.course_id === filterFormik.values.course)
        .length === 1
        ? LessonList.filter(
            (item) => item.course_id === filterFormik.values.course
          )?.[0]
        : [],
    ]);
  }, [filterFormik.values.course, LessonList]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "250px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <form onSubmit={filterFormik.handleSubmit}>
          {user.code === masterReference.HR && (
            <MenuItem>
              <SelectDropdown
                id={"Entity_type"}
                name={"Entity_type"}
                options={entitiesList}
                width="100%"
                required
                label="Entity Type"
                // getOptionLabel={(option) => option.lesson_name}
                value={
                  entitiesList.length
                    ? entitiesList.find(
                        (option) =>
                          option.value === filterFormik.values.entity_type
                      )
                    : ""
                }
                onChange={(_, selected) => {
                  filterFormik.setFieldValue("entity_type", selected?.value);
                  filterFormik.setFieldValue("entity_id", 0);
                  filterFormik.setFieldValue("teacher", "");
                  filterFormik.setFieldValue("course", "");
                  filterFormik.setFieldValue("lessons", []);
                }}
                helperText={
                  filterFormik.touched?.entity_type
                    ? filterFormik.errors?.entity_type
                    : ""
                }
                error={
                  filterFormik.touched?.entity_type &&
                  Boolean(filterFormik.errors?.entity_type)
                }
              />
            </MenuItem>
          )}
          {user.code === masterReference.HR && (
            <MenuItem>
              <SelectDropdown
                id={"Entity_id"}
                name={"Entity_id"}
                options={entitiesNameList}
                required
                width="100%"
                label="Entity"
                // getOptionLabel={(option) => option.lesson_name}
                value={
                  entitiesNameList.length
                    ? entitiesNameList.find(
                        (option) =>
                          option.value === filterFormik.values.entity_id
                      )
                    : ""
                }
                onChange={(_, selected) => {
                  filterFormik.setFieldValue("entity_id", selected?.value);
                  filterFormik.setFieldValue("teacher", "");
                  filterFormik.setFieldValue("course", "");
                  filterFormik.setFieldValue("lessons", []);
                }}
                helperText={
                  filterFormik.touched?.entity_id
                    ? filterFormik.errors?.entity_id
                    : ""
                }
                error={
                  filterFormik.touched?.entity_id &&
                  Boolean(filterFormik.errors?.entity_id)
                }
              />
            </MenuItem>
          )}
          {user.code !== masterReference.SCHOOL_TEACHER &&
            user.code !== masterReference.FOREIGN_INSTITUTE_TEACHER && (
              <MenuItem>
                <SelectDropdown
                  id={"teacher_id"}
                  name={"teacher_id"}
                  options={teacherList}
                  width="100%"
                  label="Teachers"
                  // getOptionLabel={(option) => option.lesson_name}
                  value={
                    teacherList.length
                      ? teacherList.find(
                          (option) =>
                            option.value === filterFormik.values.teacher
                        )
                      : ""
                  }
                  onChange={(_, selected) => {
                    filterFormik.setFieldValue("teacher", selected?.value);
                    filterFormik.setFieldValue("course", "");
                    filterFormik.setFieldValue("lessons", []);
                  }}
                  helperText={
                    filterFormik.touched?.teacher
                      ? filterFormik.errors?.teacher
                      : ""
                  }
                  error={
                    filterFormik.touched?.teacher &&
                    Boolean(filterFormik.errors?.teacher)
                  }
                />
              </MenuItem>
            )}
          <MenuItem>
            <SelectDropdown
              id={"course_id"}
              name={"course_id"}
              options={CourseList}
              width="100%"
              label="Courses"
              required
              value={
                CourseList.length
                  ? CourseList.find(
                      (option) => option.value === filterFormik.values.course
                    ) || CourseList?.[0]?.label
                  : ""
              }
              onChange={(_, selected) => {
                filterFormik.setFieldValue("course", selected?.value);
                filterFormik.setFieldValue("lessons", []);
              }}
              helperText={
                filterFormik.touched?.course ? filterFormik.errors?.course : ""
              }
              error={
                filterFormik.touched?.course &&
                Boolean(filterFormik.errors?.course)
              }
            />
          </MenuItem>
          {teacherList && teacherList.length ? (
            <MenuItem>
              <CheckboxDropdown
                name={`lesson_id`}
                options={LessonList.filter(
                  (item) => item.course_id === filterFormik.values.course
                )}
                label="Lessons"
                getOptionLabel={(option) => option.lesson_name}
                // groupBy={(option) => option.course_name}
                value={filterFormik.values.lessons || []}
                onChange={(_, selected) => {
                  filterFormik.setFieldValue("lessons", selected);
                }}
                helperText={
                  filterFormik.touched?.lessons
                    ? filterFormik.errors?.lessons
                    : ""
                }
                error={
                  filterFormik.touched?.lessons &&
                  Boolean(filterFormik.errors?.lessons)
                }
              />
            </MenuItem>
          ) : null}
          <Divider />
          <MenuItem>
            <Grid container justifyContent="flex-end" sx={{ marginTop: 1 }}>
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  filterFormik.resetForm();
                  setCalendarFilterParam({});
                  handleClose();
                }}
              >
                Clear
              </Button>
              <Button
                variant="filled"
                style={{
                  backgroundColor: "#5D87FF",
                  color: colors.white,
                  marginLeft: 15,
                }}
                type="submit"
              >
                Apply
              </Button>
            </Grid>
          </MenuItem>
        </form>
      </Menu>
    </>
  );
};

export default CalendarFilter;
