import { create } from "zustand";

// Custom hook for managing form submission state
export const useCustomFormSubmit = create((set) => ({
  isEditable: true, // Initialize iseditable value to true (for add )
  requestId: null, // Initialize request ID
  isFormSubmitRequested: false, // Initialize form submit request state
  formSubmitAPI: null, // Initialize form submit API reference
  isDirty: false,
  printRecord: false,
  transferRecord: false,
  disableTransfer: true,
  generateRecord: false,
  disableGenerate: true,

  // function to print selected record information
  setPrintRecord: (value) => set({ printRecord: value }),

  //  to transfer the selected record
  setTransferRecord: (value) => set({ transferRecord: value }),

  //  to disable transfer button
  setDisableTransfer: (value) => set({ disableTransfer: value }),

  //  to generate document for  the selected record
  setGenerateRecord: (value) => set({ generateRecord: value }),

  //  to disable generate button
  setDisableGenerate: (value) => set({ disableGenerate: value }),

  setIsDirty: (value) => set({ isDirty: value }),

  // Function to set the editable value
  setEditable: (value) => set({ isEditable: value }),

  // Function to set the request ID
  setRequestId: (value) => {
    // Conditionally set isEditable to true when requestId is null
    if (!value) {
      set({ requestId: value, isEditable: true });
    } else {
      set({ requestId: value });
    }
  },

  // Function to set the form submit request state
  setFormSubmitRequested: (value) => set({ isFormSubmitRequested: value }),

  // Function to set the form submit API reference
  setFormSubmitAPI: (api) => set({ formSubmitAPI: api }),
}));
