import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useCustomFormSubmit } from "../../../../../hooks/useCustomFormSubmit";
import { applicantStatus } from "../../../../../constants/masterData";

export default function ApplicantVerticalLinearStepper({
  formik,
  steps,
  setContinueDisableFlag,
  continueDisableFlag,
  isStudent,
  checkDisabled,
  otherStages,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [stepsObj, setStepsObj] = useState(steps);
  const { isDirty } = useCustomFormSubmit();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    let nextStageLabel = "";

    stepsObj.forEach((ele, idx) => {
      if (idx === activeStep + 1) {
        nextStageLabel =
          formik.values?.stageFlagList?.[
            `${Object.keys(formik.values?.stageFlagList)[idx]}`
          ];
      }
    });

    setContinueDisableFlag(
      checkDisabled(nextStageLabel, formik.values["stageFlagList"])
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    let prevStageLabel = "";
    stepsObj.forEach((ele, idx) => {
      if (idx === activeStep - 1) {
        prevStageLabel =
          formik.values?.stageFlagList?.[
            `${Object.keys(formik.values?.stageFlagList)[idx]}`
          ];
      }
    });

    setContinueDisableFlag(
      checkDisabled(prevStageLabel, formik.values["stageFlagList"])
    );
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    let firstFlag = Object.keys(formik.values.stageFlagList)?.[0];

    let docFlag = formik.values.stageFlagList[firstFlag]?.[0][0];

    // ----------------set flag for continue button---------------------

    formik.values.flow_stage_list?.forEach((item) => {
      formik.values.stageFlagList[`${item.stage_guid}_flag`]?.forEach((ele) => {
        ele.forEach((flagVal, flagIndex) => {
          docFlag = flagVal && docFlag;
        });
      });
    });

    if (docFlag === false) {
      formik.setFieldValue("status", "data_collection");
    }

    if (docFlag && !formik.values.course_id) {
      formik.setFieldValue("status", "select_course");
      setActiveStep(stepsObj.length - 1);
    }
    if (
      formik.values.course_id &&
      formik.values.status != applicantStatus.APPROVED &&
      formik.values.status != applicantStatus.REJECTED &&
      formik.values.status != applicantStatus.ARCHIVED
    ) {
      formik.setFieldValue("status", "interviewee");
      setActiveStep(stepsObj.length - 1);
    }
    if (isStudent) {
      formik.setFieldValue("status", "approved");
    }
  }, [formik.values.course_id]);

  useEffect(() => {
    otherStages.forEach((stages, index) => {
      steps.splice(index + 1, 0, stages); //push dynamic stages after document stage
    });
    setStepsObj(steps);
  }, [otherStages]);

  return (
    <>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {stepsObj.map((step, index) => {
            const labelProps = {};

            formik.values?.stageFlagList?.[
              `${Object.keys(formik.values?.stageFlagList)[index]}`
            ]?.forEach((ele, idx) => {
              if (ele?.some((element) => element === false)) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Add the Missing Highlighted Documents
                  </Typography>
                );

                labelProps.error = true;
              }
            });

            /* ---------calculate if any field of a stage is missing */

            return (
              <Step key={step.label}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
                <StepContent>
                  {step.description}
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {index !== 0 && (
                        <Button
                          onClick={() => handleBack()}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      )}
                      {index !== stepsObj.length - 1 && (
                        <Button
                          variant="contained"
                          onClick={() => handleNext()}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Continue
                        </Button>
                      )}
                    </div>
                    {isDirty && (
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        To save details, please click Save.
                      </Typography>
                    )}
                  </Box>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === stepsObj.length + 1 && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={() => handleReset()} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
}
