import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isIterable } from "../../../utils/common";
import useNotistack from "../../../hooks/useNotistack";
import useAuth from "../../../hooks/useAuth";
import ErrorTabItem from "../../../components/UI/ErrorTabItem";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { StyledTab, TabItem, a11yProps } from "../../../components/TabBar";
import { colors } from "../../../constants/colors";
import Applicant from "./applicant";
import { masterReference } from "../../../constants/masterData";
import Students from "./students";

const Flows = () => {
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [flowType, setFlowType] = useState("applicant_flows");

  const [applicantStageNames, setApplicantStageNames] = useState([]);
  const [applicantSubStageNames, setApplicantSubStageNames] = useState([]);

  const [studentStageNames, setStudentStageNames] = useState([]);
  const [studentSubStageNames, setStudentSubStageNames] = useState([]);

  const { notify } = useNotistack();
  const { user } = useAuth();
  const { t } = useTranslation();
  const paramValues = useParams();

  const fieldTypeList = [
    { label: t("File Field"), value: "file_field" },
    { label: t("Date Field"), value: "date_field" },
    { label: t("Dropdown"), value: "dropdown" },
    { label: t("Text Field"), value: "text_field" },
    { label: t("Task"), value: "task" },
  ];

  const sectionOptions = [
    { label: t("Documents"), value: "documents" },
    { label: t("General"), value: "general" },
    { label: t("Contact Information"), value: "contact_information" },
  ];

  // -------------get GUID of 12 Aplhanumeric charcters------------------------
  const getGUID = () => {
    return Array.from(Array(12), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");
  };

  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const initialValues = {
    applicant_flows: [
      {
        name: "Flow 1",
        flow_guid: getGUID(),
        default: true,
        stages: [
          {
            stage_name: "Documents",
            stage_guid: getGUID(),
            sub_stage_list: [
              {
                id: 0,
                sub_stage_name: "",
                order_by: 0,
                sub_stage_guid: getGUID(),
                field_json: [
                  {
                    type: fieldTypeList[0].value,
                    field_name: "",
                    field_guid: getGUID(),
                    validity_check: false,
                    submission_date: false,
                    start_date: false,
                    expiry_date: false,
                    choices: [],
                    task_check: true,
                    completion_date: false,
                  },
                ],
                section_name: sectionOptions[0].value,
              },
            ],
          },
        ],
        data_change: false,
      },
    ],
    student_flows: [
      {
        name: "Flow 1",
        flow_guid: getGUID(),
        default: true,
        stages: [
          {
            stage_name: "Documents",
            stage_guid: getGUID(),
            sub_stage_list: [
              {
                id: 0,
                sub_stage_name: "",
                order_by: 0,
                sub_stage_guid: getGUID(),
                field_json: [
                  {
                    type: fieldTypeList[0].value,
                    field_name: "",
                    field_guid: getGUID(),
                    validity_check: false,
                    submission_date: false,
                    start_date: false,
                    expiry_date: false,
                    choices: [],
                  },
                ],
                section_name: sectionOptions[0].value,
              },
            ],
          },
        ],
        data_change: false,
      },
    ],
    delete_flow: [],
  };

  const validationSchema = Yup.object()
    .shape({
      applicant_flows: Yup.array().of(
        Yup.object()
          .shape({
            stages: Yup.array().of(
              Yup.object().shape({
                sub_stage_list: Yup.array().of(
                  Yup.object().shape({
                    field_json: Yup.array().of(
                      Yup.object().shape({
                        type: Yup.string().required("REQUIRED"),
                        field_name: Yup.string().required("REQUIRED"),

                        // validity_check: Yup.boolean().test(
                        //   "customValidation",
                        //   "Validity Check is required",
                        //   (value, context) => {
                        //     if (context.parent.type === "file_field") {
                        //       return validateBox(value, context.parent.type);
                        //     }
                        //     return true;
                        //   }
                        // ),
                        choices: Yup.array()
                          .of(Yup.string().required("REQUIRED"))
                          .test(
                            "is-unique",
                            "Choices must be unique",
                            function (value) {
                              const seen = new Set();
                              const errors = [];
                              if (value) {
                                value.forEach((choice, index) => {
                                  const lowercaseChoice = choice?.toLowerCase();
                                  if (seen.has(lowercaseChoice)) {
                                    errors.push(
                                      this.createError({
                                        path: `${this.path}[${index}]`,
                                        message: "Choice already present",
                                      })
                                    );
                                  }
                                  seen.add(lowercaseChoice);
                                });
                              }
                              if (errors.length > 0) {
                                throw new Yup.ValidationError(errors);
                              }
                              return true;
                            }
                          ),
                      })
                    ),
                    sub_stage_name: Yup.string().required("REQUIRED"),
                    section_name: Yup.string().required("REQUIRED"),
                  })
                ),
                stage_name: Yup.string().required("REQUIRED"),
              })
            ),
          })
          .test(
            "is-unique-stage-name",
            "Stage names must be unique",
            function (value) {
              const stageNames = new Set();
              const errors = [];
              if (value && value.stages) {
                value.stages.forEach((stage, index) => {
                  if (stageNames.has(stage.stage_name)) {
                    errors.push(
                      this.createError({
                        path: `${this.path}.stages[${index}].stage_name`,
                        message: "Stage name already exists",
                      })
                    );
                  }
                  stageNames.add(stage.stage_name);
                });
              }
              if (errors.length > 0) {
                throw new Yup.ValidationError(errors);
              }
              return true;
            }
          )
          .test(
            "is-unique-sub-stage-name",
            "Sub-stage names must be unique",
            function (value) {
              const subStageNames = new Set();
              const errors = [];

              if (value && value.stages) {
                value.stages.forEach((stage, index) => {
                  stage.sub_stage_list.forEach((subStage, subStageindex) => {
                    if (subStageNames.has(subStage.sub_stage_name)) {
                      errors.push(
                        this.createError({
                          path: `${this.path}.stages[${index}].sub_stage_list[${subStageindex}].sub_stage_name`,
                          message: "Section name already exists",
                        })
                      );
                    }
                    subStageNames.add(subStage.sub_stage_name);
                  });
                });
              }
              if (errors.length > 0) {
                throw new Yup.ValidationError(errors);
              }
              return true;
            }
          )
      ),
      student_flows: Yup.array().of(
        Yup.object()
          .shape({
            stages: Yup.array().of(
              Yup.object().shape({
                sub_stage_list: Yup.array().of(
                  Yup.object().shape({
                    field_json: Yup.array().of(
                      Yup.object().shape({
                        type: Yup.string().required("REQUIRED"),
                        field_name: Yup.string().required("REQUIRED"),
                        choices: Yup.array()
                          .of(Yup.string().required("REQUIRED"))
                          .test(
                            "is-unique",
                            "Choices must be unique",
                            function (value) {
                              const seen = new Set();
                              const errors = [];
                              if (value) {
                                value.forEach((choice, index) => {
                                  const lowercaseChoice = choice?.toLowerCase();
                                  if (seen.has(lowercaseChoice)) {
                                    errors.push(
                                      this.createError({
                                        path: `${this.path}[${index}]`,
                                        message: "Choice already present",
                                      })
                                    );
                                  }
                                  seen.add(lowercaseChoice);
                                });
                              }
                              if (errors.length > 0) {
                                throw new Yup.ValidationError(errors);
                              }
                              return true;
                            }
                          ),
                      })
                    ),
                    sub_stage_name: Yup.string().required("REQUIRED"),
                    section_name: Yup.string().required("REQUIRED"),
                  })
                ),
                stage_name: Yup.string().required("REQUIRED"),
              })
            ),
          })
          .test(
            "is-unique-stage-name",
            "Stage names must be unique",
            function (value) {
              const stageNames = new Set();
              const errors = [];
              if (value && value.stages) {
                value.stages.forEach((stage, index) => {
                  if (stageNames.has(stage.stage_name)) {
                    errors.push(
                      this.createError({
                        path: `${this.path}.stages[${index}].stage_name`,
                        message: "Stage name already exists",
                      })
                    );
                  }
                  stageNames.add(stage.stage_name);
                });
              }
              if (errors.length > 0) {
                throw new Yup.ValidationError(errors);
              }
              return true;
            }
          )
          .test(
            "is-unique-sub-stage-name",
            "Sub-stage names must be unique",
            function (value) {
              const subStageNames = new Set();
              const errors = [];

              if (value && value.stages) {
                value.stages.forEach((stage, index) => {
                  stage.sub_stage_list.forEach((subStage, subStageindex) => {
                    if (subStageNames.has(subStage.sub_stage_name)) {
                      errors.push(
                        this.createError({
                          path: `${this.path}.stages[${index}].sub_stage_list[${subStageindex}].sub_stage_name`,
                          message: "Section name already exists",
                        })
                      );
                    }
                    subStageNames.add(subStage.sub_stage_name);
                  });
                });
              }
              if (errors.length > 0) {
                throw new Yup.ValidationError(errors);
              }
              return true;
            }
          )
      ),
    })
    .test("is-unique-flow-name", "Flow names must be unique", function (value) {
      const applicantFlowNames = new Set();
      const studentFlowNames = new Set();
      const errors = [];

      if (value && value.applicant_flows) {
        value.applicant_flows.forEach((flow, index) => {
          if (applicantFlowNames.has(flow.name)) {
            errors.push(
              this.createError({
                path: `applicant_flows[${index}].name`,
                message: "Flow name already exists",
              })
            );
          }
          applicantFlowNames.add(flow.name);
        });
      }

      if (value && value.student_flows) {
        value.student_flows.forEach((flow, index) => {
          if (studentFlowNames.has(flow.name)) {
            errors.push(
              this.createError({
                path: `student_flows[${index}].name`,
                message: "Flow name already exists",
              })
            );
          }
          studentFlowNames.add(flow.name);
        });
      }

      if (errors.length > 0) {
        throw new Yup.ValidationError(errors);
      }
      return true;
    });

  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex } = useRecordSelection();

  useEffect(() => {
    if (paramValues.id > 0) {
      setRequestId(paramValues.id);
      setSelectedIndex(paramValues.id);
    }

    let selectedData = {
      applicant_flows: [
        {
          name: "Flow 1",
          flow_guid: getGUID(),
          default: true,
          stages: [
            {
              stage_name: "Documents",
              stage_guid: getGUID(),
              sub_stage_list: [
                {
                  id: 0,
                  sub_stage_name: "",
                  order_by: 0,
                  sub_stage_guid: getGUID(),
                  field_json: [
                    {
                      type: fieldTypeList[0].value,
                      field_name: "",
                      field_guid: getGUID(),
                      validity_check: false,
                      submission_date: false,
                      start_date: false,
                      expiry_date: false,
                      choices: [],
                      task_check: true,
                      completion_date: false,
                    },
                  ],
                  section_name: sectionOptions[0].value,
                },
              ],
            },
          ],
          data_change: false,
        },
      ],
      student_flows: [
        {
          name: "Flow 1",
          flow_guid: getGUID(),
          default: true,
          stages: [
            {
              stage_name: "Documents",
              stage_guid: getGUID(),
              sub_stage_list: [
                {
                  id: 0,
                  sub_stage_name: "",
                  order_by: 0,
                  sub_stage_guid: getGUID(),
                  field_json: [
                    {
                      type: fieldTypeList[0].value,
                      field_name: "",
                      field_guid: getGUID(),
                      validity_check: false,
                      submission_date: false,
                      start_date: false,
                      expiry_date: false,
                      choices: [],
                      task_check: true,
                      completion_date: false,
                    },
                  ],
                  section_name: sectionOptions[0].value,
                },
              ],
            },
          ],
          data_change: false,
        },
      ],
      delete_flow: [],
    };
    console.warn("selected data  ", selectedData);
    console.warn("selected REcord  ", selectedRecord);
    if (selectedRecord) {
      if (selectedRecord?.student_flows?.length) {
        // ------------------set Stages Name --------------------
        selectedRecord?.student_flows?.forEach((flows, flowIndex) => {
          flows.stages.forEach((stage, stageIndex) => {
            let subStageArr = [];
            setStudentStageNames((prev) => [...prev, stage.stage_name]);

            // ------------------set sub Stages Name --------------------
            stage.sub_stage_list.forEach((subStage, subStageIndex) => {
              subStageArr.push(subStage.sub_stage_name);
            });
            setStudentSubStageNames((prev) => [
              ...prev,
              {
                [stage.stage_name]: [...subStageArr],
              },
            ]);
          });
        });
        selectedData.student_flows = selectedRecord.student_flows.map(
          (flow) => ({
            ...flow,
            name: flow.name,
            flow_guid: flow.flow_guid || getGUID(),
            default: flow.default,
            data_change: flow.data_change || false,
            stages: flow.stages.map((stage) => ({
              ...stage,
              stage_name: stage.stage_name,
              stage_guid: stage.stage_guid || getGUID(),
              sub_stage_list: (stage.sub_stage_list || []).map(
                (sub_section) => ({
                  ...sub_section,
                  id: sub_section.id,
                  name: sub_section.name,
                  order_by: sub_section.order_by || 0,
                  sub_stage_guid: sub_section.sub_stage_guid || getGUID(),
                  field_json: sub_section.field_json.map((field) => ({
                    ...field,
                    field_name: field.field_name,
                    field_guid: field.field_guid || getGUID(),
                    type: field.type || fieldTypeList[0].value,
                    validity_check: field.validity_check,
                    submission_date: field.submission_date,
                    start_date: field.start_date,
                    expiry_date: field.expiry_date,
                    choices: field.choices || [],
                    task_check: field.task_check,
                    completion_date: field.completion_date,
                  })),
                  section_name:
                    sub_section.section_name || sectionOptions[0].value,
                })
              ),
            })),
          })
        );
      }
      if (selectedRecord?.applicant_flows?.length) {
        // ------------------set Stages Name --------------------
        selectedRecord?.applicant_flows?.forEach((flows, flowIndex) => {
          flows.stages.forEach((stage, stageIndex) => {
            let subStageArr = [];
            setApplicantStageNames((prev) => [...prev, stage.stage_name]);

            // ------------------set sub Stages Name --------------------
            stage.sub_stage_list.forEach((subStage, subStageIndex) => {
              subStageArr.push(subStage.sub_stage_name);
            });
            setApplicantSubStageNames((prev) => [
              ...prev,
              {
                [stage.stage_name]: [...subStageArr],
              },
            ]);
          });
        });
        selectedData.applicant_flows = selectedRecord.applicant_flows.map(
          (flow) => ({
            ...flow,
            name: flow.name,
            flow_guid: flow.flow_guid || getGUID(),
            default: flow.default,
            data_change: flow.data_change || false,
            stages: flow.stages.map((stage) => ({
              ...stage,
              stage_name: stage.stage_name,
              stage_guid: stage.stage_guid || getGUID(),
              sub_stage_list: (stage.sub_stage_list || []).map(
                (sub_section) => ({
                  ...sub_section,
                  id: sub_section.id,
                  name: sub_section.name,
                  order_by: sub_section.order_by || 0,
                  sub_stage_guid: sub_section.sub_stage_guid || getGUID(),
                  field_json: sub_section.field_json.map((field) => ({
                    ...field,
                    field_name: field.field_name,
                    field_guid: field.field_guid || getGUID(),
                    type: field.type || fieldTypeList[0].value,
                    validity_check: field.validity_check,
                    submission_date: field.submission_date,
                    start_date: field.start_date,
                    expiry_date: field.expiry_date,
                    choices: field.choices || [],
                    task_check: field.task_check,
                    completion_date: field.completion_date,
                  })),
                  section: sub_section.section_name || sectionOptions[0].value,
                })
              ),
            })),
          })
        );
      }
      selectedData.goal_id = selectedRecord?.goal_id;
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER ||
        user.code === masterReference.HR
      ) {
        setEditable(true);
      }
    } else {
      setData(initialValues);
      formik.resetForm();
    }
  }, [selectedRecord, requestId]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    //--------------------calculate sub stage order for applicants --------------------

    values.applicant_flows.forEach((flow, flowIndex) => {
      let subStageNumber = 0;
      flow.stages.forEach((stage, stageIndex) => {
        stage.sub_stage_list.forEach((subStage, subStageIndex) => {
          subStageNumber = subStageNumber + 1;

          values.applicant_flows[flowIndex].stages[stageIndex].sub_stage_list[
            subStageIndex
          ].order_by = subStageNumber;
        });
      });
    });

    //--------------------calculate sub stage order for students --------------------

    values.student_flows.forEach((flow, flowIndex) => {
      let subStageNumber = 0;
      flow.stages.forEach((stage, stageIndex) => {
        stage.sub_stage_list.forEach((subStage, subStageIndex) => {
          subStageNumber = subStageNumber + 1;

          values.student_flows[flowIndex].stages[stageIndex].sub_stage_list[
            subStageIndex
          ].order_by = subStageNumber;
        });
      });
    });

    let submittingData = {
      applicant_flows: values.applicant_flows,
      student_flows: values.student_flows,
      delete_flow: values.delete_flow,
    };

    console.log("====================================");
    console.log("submittingData:::", submittingData);
    console.log("====================================");
    console.log("request id  ", requestId);
    let requestParams = {
      id: requestId,
      data: submittingData,
    };
    // return;
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      await setRequestId(null);
      setRequestId(requestId);
      setSelectedIndex(requestId);

      if (requestId) {
        notify(t("Flow Updated"), "success");
      } else {
        notify(t("Flow Added"), "success");
      }
      if (user?.step?.goal_flow_count <= 0) {
        window.location.href = `/${user.company_url}/${user.custom_url}/steps`;
      }

      if (
        window.location.pathname.includes("flows/") &&
        parseInt(window.location.pathname.split("/").slice(-2, -1)) > 0
      ) {
        window.location.href = `/${user.company_url}/${user.custom_url}/data/applicants`;
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object" && isIterable(value)) {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleTabChange = (event, newTab) => {
    if (
      event.target.innerText === "Applicants" ||
      event.target.innerText === "Bewerber"
    ) {
      setFlowType("applicant_flows");
    } else {
      setFlowType("student_flows");
    }
    setActiveTab(newTab);
  };

  const reduceTab = async (index) => {
    if (index === tabIndex && index !== 0) {
      setTabIndex(index - 1);
    }
  };

  useEffect(() => {
    if (formik.values.flows?.length <= tabIndex) {
      setTabIndex(formik.values.flows?.length - 1);
    }
  }, [tabIndex, formik.values.flows?.length]);

  const removeFlow = (index) => {
    let deleted_data = formik.values.delete_flow;
    formik.values.flows[index]["stages"].forEach((stage) => {
      if (stage.id !== 0) {
        deleted_data.push(stage.id);
        formik.setFieldValue("delete_flow", deleted_data);
      }
    });
    let oldFlowData = [...formik.values.flows];
    oldFlowData.splice(index, 1);
    formik.setFieldValue(`flows`, oldFlowData);
  };

  const handleClose = () => setOpen(false);
  const handleYes = () => {
    setOpen(false);
    reduceTab(deleteIndex).then(() => removeFlow(deleteIndex));
  };

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <OverlayLoader show={isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <StyledTab
          value={activeTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.secondary,
            },
          }}
        >
          <ErrorTabItem
            label={t("Applicants")}
            {...a11yProps(0)}
            error={
              formik.errors.applicant_flows &&
              Boolean(formik.touched.applicant_flows)
            }
          />
          <ErrorTabItem
            label={t("Students")}
            {...a11yProps(1)}
            error={
              formik.errors.student_flows &&
              Boolean(formik.touched.student_flows)
            }
          />
        </StyledTab>
        {/* Cockpit Tab Panel */}
        {selectedRecord && data && (
          <div
            style={{
              padding: "24px",
              display: activeTab === 0 ? "" : "none",
            }}
            id="simple-tabpanel-0"
            aria-labelledby="simple-tab-0"
          >
            {/* Render the ApplicantCockpit component with formik prop */}
            <Applicant
              formik={formik}
              fieldTypeList={fieldTypeList}
              sectionOptions={sectionOptions}
              handleClose={handleClose}
              flowType={flowType}
              isFormSubmitRequested={isFormSubmitRequested}
              setFormSubmitRequested={setFormSubmitRequested}
              stageNames={applicantStageNames}
              setStageNames={setApplicantStageNames}
              subStageNames={applicantSubStageNames}
              setSubStageNames={setApplicantSubStageNames}
            />
          </div>
        )}

        {/* General Tab Panel */}
        <div
          style={{
            padding: "24px",
            display: activeTab === 1 ? "" : "none",
          }}
          id="simple-tabpanel-1"
          aria-labelledby="simple-tab-1"
        >
          <Students
            formik={formik}
            fieldTypeList={fieldTypeList}
            sectionOptions={sectionOptions}
            flowType={flowType}
            handleClose={handleClose}
            stageNames={studentStageNames}
            setStageNames={setStudentStageNames}
            subStageNames={studentSubStageNames}
            setSubStageNames={setStudentStageNames}
          />
        </div>
      </form>
    </>
  );
};

export default Flows;
