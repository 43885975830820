import React from "react";
import { useNavigate } from "react-router-dom";
import Page from "../layout/Page";
import styled from "styled-components";
import { addUserApi } from "../services/users";
import UserForm from "../widgets/UserForm";
import { useState } from "react";
import useNotistack from "../hooks/useNotistack";
import useWindowSize from "../hooks/useWindowSize";
import useAuth from "../hooks/useAuth";
import { masterReference } from "../constants/masterData";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";

const StepWrapper = styled.div`
  && {
    width: 55%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
  }
`;

function AddUserForm(props) {
  let pageTitle = "Add User";
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const { width } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  const { user } = useAuth();
  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.code.trim().toLowerCase();
  const defaultUrl =
    userRole === masterReference.SUPER_ADMIN ? "" : `/${customUrl}`;

  return (
    <>
      <Page title={pageTitle} pageTitleCenter={true}>
        <BreadcrumWithTitle
          path={
            user.code === masterReference.SUPER_ADMIN
              ? [
                { pathname: "/", title: "Home" },
                { pathname: user.custom_url ? `/${user.custom_url}/users` : "/users", title: "Users" },
                { pathname: "", title: "Add User" },
              ]
              : [
                { pathname: "/", title: "Users" },
                { pathname: "", title: "Add User" },
              ]
          }
          title={pageTitle}
          marginBottom={'10px'}
        />
        <StyledDiv>
          <StepWrapper>
            <UserForm
              isMobile={isMobile}
              isEditing={false}
              isLoading={isLoading}
              userId={0}
              onSubmit={async (values, { resetForm }) => {
                setIsLoading(true);
                const keys = [
                  "role_id",
                  "entity_id",
                  "entity_type",
                  "first_name",
                  "last_name",
                  "email",
                  "phone_no",
                  "gender",
                  "dob",
                  "place_of_birth",
                  "citizenship",
                  "situation",
                  "religion",
                  "health_insurance_provider",
                  "iban",
                  "tax_id",
                  "social_security_number",
                  "qualification",
                  "work_contract",
                  "company_id",
                ];
                const data = keys.reduce((acc, key) => {
                  acc[key] = values[key];
                  return acc;
                }, {});
                data["first_name"] = data.first_name.trim();
                data["last_name"] = data.last_name.trim();
                data["email"] = data.email.trim();
                data["dob"] = data.dob ? data.dob : null;
                let requestParams = {
                  data: data,
                };
                let res = await addUserApi(requestParams);
                if (res.isOk) {
                  resetForm();
                  navigate(`${defaultUrl}/users`);
                  notify("User Infomation Added.", "success");
                } else {
                  for (const [key, value] of Object.entries(res.error)) {
                    if (typeof value === "object") {
                      value.forEach((obj) => {
                        for (const key2 in obj) {
                          if (obj.hasOwnProperty(key2)) {
                            const value2 = obj[key2];
                            for (let i in value2) {
                              notify(`${key2}: ${value2[i]}`, "error");
                            }
                          }
                        }
                      });
                    } else {
                      notify(`${key}: ${value}`, "error");
                    }
                  }
                }
                setIsLoading(false);
              }}
            />
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default AddUserForm;
