import { protectedApi } from "../api";
import { env } from "../config/env";
import { formatApiResponse } from "../utils/helpers";

// Function to get Fil
export const getFileDetail = async (requestParams) => {
  let { entityId, entityType } = requestParams;
  console.log("getFileDetail.params", requestParams);

  try {
    // Construct the query parameter string
    let url;
    if (entityType && entityId) {
      url = `/file/?entity_type=${entityType}&entity_id=${entityId}`;
    } else {
      url = `/file/`;
    }

    // Make a GET request to retrieve file detail for user
    const response = await protectedApi.get(url);
    console.log("getFileDetail.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getFileDetail.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const saveFileDetail = async (requestParams) => {
  const { data } = requestParams;
  console.log("saveFileData.requestParams", requestParams);

  try {
    const response = await protectedApi.post(`/file/`, data, {});
    console.log("saveFileData.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("saveFileData.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const getTemplateList = async (requestParams) => {
  let { entityId, entityType, templateId, documentType, userId } =
    requestParams;

  console.log("getTemplateList.params", requestParams);
  try {
    // Make a POST to generate student
    const response = await protectedApi.get(
      `/file/user/generate-document?entity_id=${entityId}&entity_type=${entityType}&template_id=${templateId}&document_type=${documentType}&user_id=${userId}`
    );
    console.log("getTemplateList.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getTemplateList.error", e);
    return formatApiResponse(e);
  }
};

export const extractPlaceHolder = async (requestParams) => {
  let { url, documentType, userId } = requestParams;

  console.log("extractPlaceHolder.params", requestParams);
  try {
    // Make a POST to generate student
    const response = await protectedApi.get(
      `/file/extract/place-holder?doc_url=${url}&document_type=${documentType}&user_id=${userId}`
    );
    console.log("extractPlaceHolder.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("extractPlaceHolder.error", e);
    return formatApiResponse(e);
  }
};

export const generateFile = async (requestParams) => {
  let { data } = requestParams;

  console.log("generateFile.params", requestParams);
  try {
    // Make a POST to generate student
    const response = await protectedApi.post(
      `/file/user/generate-document`,
      data
    );
    console.log("generateFile.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("generateFile.error", e);
    return formatApiResponse(e);
  }
};

export const updateGeneratedFile = async (requestParams) => {
  let { data } = requestParams;

  console.log("updateGeneratedFile.params", requestParams);
  try {
    // Make a POST to generate student
    const response = await protectedApi.put(
      `/file/user/generate-document`,
      data
    );
    console.log("updateGeneratedFile.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("updateGeneratedFile.error", e);
    return formatApiResponse(e);
  }
};

export const updateExtractPlaceHolder = async (requestParams) => {
  let { userTemplateId, docType, userId, selectedTemplateId } = requestParams;

  console.log("updateExtractPlaceHolder.params", requestParams);
  try {
    // Make a POST to generate student
    const response = await protectedApi.get(
      `/file/extract/place-holder?user_template_id=${userTemplateId}&document_type=${docType}&user_id=${userId}&selected_template_id=${selectedTemplateId}`
    );
    console.log("updateExtractPlaceHolder.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("updateExtractPlaceHolder.error", e);
    return formatApiResponse(e);
  }
};

export const downloadSampleTemplateFile = async () => {
  try {
    // Make a POST to generate student
    const response = await protectedApi.get(`/file/download/sample-template`);
    console.log("downloadSampleTemplateFile.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("downloadSampleTemplateFile.error", e);
    return formatApiResponse(e);
  }
};

export const getPlaceHolderList = async (requestParams) => {
  let { documentType } = requestParams;

  console.log("getPlaceHolderList.params", requestParams);
  try {
    // Make a POST to generate student
    const response = await protectedApi.get(
      `/file/place-holder/list?document_type=${documentType}`
    );
    console.log("getPlaceHolderList.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getPlaceHolderList.error", e);
    return formatApiResponse(e);
  }
};
