import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { textSizes } from "../../styles/vars.js";
import { colors } from "../../constants/colors.js";
import { styled } from "styled-components";

export const StyledTab = styled(Tabs)`
  && {
    background-color: aliceblue;
    color: ${colors.primary};
  },
  & .MuiButtonBase-root {
    font-size: ${textSizes['16']};
  },
  & .Mui-selected {
    // background: #6182b424;
    color: ${colors.secondary} !important;
  }
`;

export const TabItem = styled(Tab)`
  && {
    color: ${colors.primary};
    text-transform: inherit;

    &&.Mui-selected {
      color: ${colors.primary};
    }
  }
`;

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
