import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import styled from "styled-components";

import BreadCrumb from "../BreadCrumbs";
// import { ContentTitle } from "../../layout/Page/style";

const ContentTitle = styled.h1`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 1.5rem;
`;

function BreadcrumWithTitle({ title, path, marginBottom = 0, children }) {
  return (
    <Grid
      container
      alignItems={"self-start"}
      style={{ marginBottom: marginBottom }}
    >
      <Grid item xs={4}>
        {path && path.length > 0 && <BreadCrumb match={path} />}
      </Grid>
      <Grid item xs={4}>
        <ContentTitle style={{ justifyContent: "center" }}>
          {title}
        </ContentTitle>
      </Grid>
      <Grid item xs={4}>
        {children}
      </Grid>
    </Grid>
  );
}

// Define propTypes to specify the expected types for props
BreadcrumWithTitle.propTypes = {
  title: PropTypes.string.isRequired, // 'title' is required
  path: PropTypes.array.isRequired, // 'path' is required
};

export default BreadcrumWithTitle;
