import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  IconButton,
  Skeleton,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router";
import AppPagination from "../../components/Pagination";
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableHead,
  AppTableRow,
} from "../../components/UI/Table";
import {
  getCompaniesListForUser,
  getUserRolesListApi,
  getUsersList,
} from "../../services/users";
import { colors } from "../../styles/vars";
import { NoWrap, ChipWrapper } from "./style";
// import useNotistack from '@hooks/useNotistack';
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
// import { useSnackbar } from 'notistack';
import useWindowSize from "../../hooks/useWindowSize";
import AddButtonWidget from "../AddButtonWidget";
// import { formatNumberInUs } from '@utils/common';
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useSearchParams } from "react-router-dom";
import { StyledIconBtn } from "../../components/UI/StyledIconBtn";
import { rowsPerPageOptions } from "../../constants/options";
import { masterReference } from "../../constants/masterData";
import useAuth from "../../hooks/useAuth";
import FilterBar from "../FilterBar";
import FilterMenu from "./filter";
import BreadcrumWithTitle from "../BreadcrumWithTitle";
import { ContentTitle } from "../../layout/Page/style";
import CustomizedSwitches from "../CustomSwitch";
import { updateEntityStatus } from "../../services/master";
import useNotistack from "../../hooks/useNotistack";
import { toTitleCase } from "../../utils/utility";

const columns = [
  {
    name: "Name",
    field: "name",
    align: "left",
    width: "20%",
  },
  // {
  //   name: "Email",
  //   field: "email",
  //   align: "left",
  //   width: "20%",
  // },
  {
    name: "Contact",
    field: "contact",
    align: "left",
    width: "30%",
  },
  {
    name: "Role",
    field: "role",
    align: "left",
    width: "20%",
  },
  // {
  //   name: "Date of Birth",
  //   field: "dob",
  //   align: "left",
  //   width: "15%",
  // },
  // {
  //   name: "Gender",
  //   field: "gender",
  //   align: "left",
  //   width: "10%",
  // },
  {
    name: "Status",
    field: "status",
    align: "left",
    width: "10%",
  },
  {
    name: "Edit",
    align: "left",
    width: "10%",
  },
];

const UsersList = (props) => {
  const { user } = useAuth();
  //  for getting value from the search paramter
  const [companyList, setCompanyList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();
  const params = {
    sort: searchParams.get("sort") || "",
    order: searchParams.get("order") || 1,
    page: searchParams.get("page") ? parseInt(searchParams.get("page")) : 1,
    limit: searchParams.get("limit") || rowsPerPageOptions[0],
    role: searchParams.get("role")?.split(",") || [],
    company: searchParams.get("company") || "",
  };

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(params.page);
  const [companyId, setcompanyId] = useState(
    user?.company_id || params.company || 0
  );
  const [selectedValues, setSelectedValues] = useState(params.role);
  const [open, setOpen] = useState(false);
  const [statusState, setStatusState] = useState("");
  const [entityId, setEntityId] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const { notify } = useNotistack();
  // const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [rowsPerPage, setRowsPerPage] = useState(params.limit);
  const navigate = useNavigate();
  const [selectedRowStatus, setSelectedRowStatus] = useState({
    clinic_id: 0,
    current_clinic_status: false,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const { notify } = useNotistack();
  // const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.code.trim().toLowerCase();
  const defaultUrl =
    userRole === masterReference.SUPER_ADMIN ? "" : `/${customUrl}`;

  //sorting
  const [sorting, setSorting] = useState({
    sort: params.sort,
    order: params.order,
    // abort: 'View/Edit'
  });
  const renderSortIcon = (name) => {
    if (sorting.sort === name) {
      if (sorting.order) {
        return (
          <IconButton>
            <NorthIcon fontSize="small" color="primary" />
          </IconButton>
        );
      } else {
        return (
          <IconButton>
            <SouthIcon fontSize="small" color="secondary" />
          </IconButton>
        );
      }
    } else {
      return null;
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, rows) => {
    // setRowsPerPage(parseInt(event.target.value, rowsPerPageOptions[0]));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (user.code.toLowerCase() === masterReference.SUPER_ADMIN) {
      getCompaniesListForUser().then((company) => {
        let updated_company_list = [
          { value: 0, label: "All Companies" },
          ...company.data,
        ];
        setCompanyList(updated_company_list);
      });
    }
  }, [user]);

  useEffect(() => {
    getUserRolesListApi().then((res) => {
      console.log(res.data);
      setRoleList(res.data);
    });
  }, []);

  const handleCompanyChange = (companyId) => {
    if (companyId !== 0) {
      setcompanyId(companyId);
    } else {
      setcompanyId(0);
    }
  };

  const handleOpen = (entity_id, state) => {
    setStatusState(state);
    setEntityId(entity_id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  function fetchUserList() {
    setIsLoading(true);
    let requestParams = {
      page: page,
      limit: rowsPerPage,
      company_id: companyId,
      entity_type: "company",
      role: selectedValues,
      sorting: sorting,
    };
    getUsersList(requestParams)
      .then(async function (res) {
        if (res.isOk) {
          setRows(res.data);
          setTotalRows(res.total);
          setIsLast(res.isLast);
        } else {
        }
      })
      .catch(function () {
        // enqueueSnackbar('Something went wrong fetching clinics', {
        //   variant: 'error'
        // });
      })
      .finally(function () {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (page >= 0 && rowsPerPage > 0) {
      fetchUserList();
    }
  }, [selectedValues, companyId, page, rowsPerPage, sorting]);

  // Update the query parameters when the state changes
  useEffect(() => {
    // Update the 'page' query parameter with the updated value
    if (page) {
      searchParams.set("page", page);
    } else {
      searchParams.delete("page");
    }

    // Update the 'limit' query parameter with the updated value
    if (rowsPerPage) {
      searchParams.set("limit", rowsPerPage);
    } else {
      searchParams.delete("limit");
    }

    // Update the 'sort' and 'order' query parameter with the updated value
    if (sorting) {
      searchParams.set("sort", sorting.sort);
      searchParams.set("order", sorting.order);
    } else {
      searchParams.delete("sort");
      searchParams.delete("order");
    }

    if (selectedValues.length > 0) {
      searchParams.set("role", selectedValues.join(","));
    } else {
      searchParams.delete("role");
    }

    if (companyId) {
      searchParams.set("company", companyId);
    } else {
      searchParams.delete("company");
    }

    // Update the search params object new values
    setSearchParams(searchParams);
  }, [
    page,
    rowsPerPage,
    sorting,
    selectedValues,
    companyId,
    searchParams,
    setSearchParams,
  ]);

  const handleSortingClick = (name = "") => {
    if (name) {
      setSorting((prevSorting) => {
        return {
          sort: name,
          order: prevSorting.sort === name ? (prevSorting.order ? 0 : 1) : 1,
          // abort: prevSorting.abort
        };
      });
    }
  };
  const handleToggleStatus = (id, value) => {
    setSelectedRowStatus({ clinic_id: id, current_clinic_status: value });
    setShowConfirmationModal(true);
  };

  const handleDropdownChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };

  const handleStatusChange = async (active_status) => {
    if (
      (active_status === "disable" && inputValue === "disable") ||
      active_status === "enable"
    ) {
      try {
        let res = await updateEntityStatus(
          masterReference.USER,
          entityId,
          active_status === "disable" ? 0 : 1
        );
        if (res.isOk) {
          fetchUserList();
        }
      } catch (error) {
        console.error(error);
      }
      handleClose();
    } else {
      notify("Please type disable.", "error");
    }
  };

  const handleDelete = (value) => {
    let oldValues = [...selectedValues];
    const index = oldValues.indexOf(value);
    oldValues.splice(index, 1);
    setSelectedValues(oldValues);
  };

  return (
    <>
      {" "}
      <Grid container alignItems={"self-start"}>
        <Grid item xs={12} sm={12} style={{ display: "flex" }}>
          <BreadcrumWithTitle
            path={
              user.code === masterReference.SUPER_ADMIN
                ? [
                    { title: "Home", pathname: "/" },
                    {
                      title: "Users",
                      pathname: user.custom_url
                        ? `/${user.custom_url}/users`
                        : "/users",
                    },
                  ]
                : null
            }
            title={props.title}
            marginBottom={"10px"}
          >
            <AddButtonWidget text="Add User" url={`${defaultUrl}/users/add`} />
          </BreadcrumWithTitle>
        </Grid>
        <Grid item xs={12} sm={12} style={{ display: "flex" }}>
          <FilterMenu
            roleList={roleList}
            companyList={companyList}
            roleSelectedValues={selectedValues}
            onCompanyChange={handleCompanyChange}
            companyId={companyId ? companyId : 0}
            onRoleChange={handleDropdownChange}
          />
          <ChipWrapper>
            {companyId &&
            user.code.toLowerCase() === masterReference.SUPER_ADMIN ? (
              <Chip
                label={
                  companyList.find(
                    (company) => company.value == parseInt(companyId)
                  )?.label
                }
                key={companyId}
                onDelete={() => setcompanyId(0)}
                style={{
                  color: colors.dark_blue,
                  background: "#fff",
                  border: "1px solid #006bb3",
                  fontWeight: "bold",
                }}
                deleteIcon={
                  <CancelRoundedIcon style={{ color: colors.dark_blue }} />
                }
              />
            ) : null}
            {roleList &&
              roleList
                .filter((role) =>
                  selectedValues.map(String).includes(String(role.value))
                )
                .map((role) => (
                  <Chip
                    label={role.label}
                    key={role.label}
                    onDelete={() => handleDelete(role.value)}
                    style={{
                      color: "#4dabab",
                      background: "#fff",
                      border: "1px solid #006bb3",
                      fontWeight: "bold",
                    }}
                    deleteIcon={
                      <CancelRoundedIcon style={{ color: "#4dabab" }} />
                    }
                  />
                ))}
          </ChipWrapper>
        </Grid>
      </Grid>
      <AppTable>
        <AppTableHead>
          <AppTableRow>
            {columns.map((column, index) => {
              return (
                <AppTableCell
                  width={column.width || "auto"}
                  align={column.align}
                  key={index}
                  onClick={() => handleSortingClick(column.field)}
                >
                  <NoWrap>
                    {column.name}
                    {renderSortIcon(column.field)}
                  </NoWrap>
                </AppTableCell>
              );
            })}
          </AppTableRow>
        </AppTableHead>
        <AppTableBody>
          {isLoading &&
            Array.from({ length: rowsPerPage }, function (_, i) {
              return (
                <AppTableRow key={i}>
                  {Array.from({ length: columns.length }, function (_, i) {
                    return (
                      <AppTableCell key={i} align={columns[i].align}>
                        <Skeleton />
                      </AppTableCell>
                    );
                  })}
                </AppTableRow>
              );
            })}
          {!isLoading &&
            rows.length > 0 &&
            rows.map((row, index) => (
              <AppTableRow key={row.id}>
                <AppTableCell align="left">
                  <Typography>
                    {toTitleCase(
                      row.name && row.name.length > 40
                        ? row.name.substring(0, 40) + "..."
                        : row.name
                    )}
                  </Typography>
                </AppTableCell>
                <AppTableCell align="left">
                  <a
                    href={`mailto:${row.email}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography>{row.email}</Typography>
                  </a>
                  <a
                    href={`tel:${row.phone_no}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography>{row.phone_no}</Typography>
                  </a>
                </AppTableCell>
                {/* <AppTableCell align="left">
                  <a
                    href={`tel:${row.phone_no}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography>{row.phone_no}</Typography>
                  </a>
                </AppTableCell> */}
                <AppTableCell align="left">
                  <Typography>{row.role}</Typography>
                </AppTableCell>
                {/* <AppTableCell align="left">
                  <Typography>{dayjs(row.dob).format("DD-MM-YYYY")}</Typography>
                </AppTableCell>
                <AppTableCell align="left">
                  <Typography>{row.gender}</Typography>
                </AppTableCell> */}
                <AppTableCell align="left">
                  <CustomizedSwitches
                    inputProps={{ "aria-label": "toggle Enabled" }}
                    name="is_enabled"
                    checked={Boolean(row.is_active)}
                    onChange={() =>
                      handleOpen(row.id, row.is_active ? "disable" : "enable")
                    }
                  />
                </AppTableCell>
                <AppTableCell align="left">
                  {/* <IconBtn
                    color="primary"
                    background={colors.lightSkyBlue}
                    onClick={() =>
                      navigate(
                        user.role.toLowerCase() === roles.SUPER_ADMIN
                          ? `/clinics/${row.id}/edit`
                          : '/clinics/edit'
                      )
                    }
                  >
                    <EastIcon />
                  </IconBtn> */}
                  <StyledIconBtn
                    shape="round"
                    size="small"
                    textcolor={colors.white}
                    background={colors.purple}
                    onClick={() =>
                      userRole === "super_admin"
                        ? navigate(`/users/${row.id}/edit`)
                        : navigate(`/${customUrl}/users/${row.id}/edit`)
                    }
                  >
                    <EditIcon fontSize="small" />
                  </StyledIconBtn>
                </AppTableCell>
              </AppTableRow>
            ))}
        </AppTableBody>
      </AppTable>
      <AppPagination
        rowsPerPageOptions={rowsPerPageOptions}
        total={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ pb: 1 }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black" }}
          >
            {statusState === "disable" ? (
              <>
                Confirm user disable by typing{" "}
                <span style={{ color: "red" }}>disable</span> and <br />
                clicking 'Disable' button.
              </>
            ) : (
              "Are you sure you want to enable this user?"
            )}
          </DialogContentText>
          {statusState === "disable" && (
            <TextField
              value={inputValue}
              sx={{ mt: 1 }}
              size="small"
              autoFocus
              fullWidth
              placeholder="disable"
              onChange={(e) => setInputValue(e.target.value)}
              error={inputValue !== "disable"} // Set error prop if value is not "disable"
              // helperText={
              //   inputValue !== "disable" ? "Input must be 'disable'" : ""
              // } // Display error message
              InputProps={{
                // Style input border based on error condition
                style: {
                  borderColor: inputValue !== "disable" ? "red" : undefined,
                },
              }}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "flex-end", padding: "20px 24px" }}
        >
          {statusState === "disable" ? (
            <>
              <Button
                variant="outlined"
                color="error"
                style={{ padding: "8px 20px" }}
                // onClick={approved ? handleUnApproved : handleApproved}
                onClick={(e) => handleStatusChange(statusState)}
              >
                {"Disable"}
              </Button>
              <Button
                variant="contained"
                style={{ padding: "8px 20px" }}
                color="primary"
                onClick={handleClose}
              >
                {"Cancel"}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="success"
                // onClick={approved ? handleUnApproved : handleApproved}
                onClick={(e) => handleStatusChange(statusState)}
              >
                {"Enable"}
              </Button>
              <Button variant="outlined" color="error" onClick={handleClose}>
                {"Cancel"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {/* <ModalWindow
        isVisible={showConfirmationModal}
        visibilityHandler={setShowConfirmationModal}
      >
        <StatusModalContent>
          <Typography>
            {`${
              selectedRowStatus.current_clinic_status
                ? 'Are you sure you want to disable this clinic? If you do, they will not be able to access this portal.'
                : 'Are you sure you want to enable this clinic? If you do, they will be able to access this portal.'
            }`}
          </Typography>
          <div className="button-wrapper">
            <Btn1
              text="Yes"
              type="submit"
              handler={handleStatusChange}
              color={
                selectedRowStatus.current_clinic_status
                  ? colors.error
                  : colors.success
              }
            />
            <Btn1
              text="No"
              type="button"
              textcolor={colors.dark}
              color={colors.bleachWhite}
              handler={() => {
                setShowConfirmationModal(false);
              }}
            />
          </div>
        </StatusModalContent>
      </ModalWindow> */}
    </>
  );
};

export default UsersList;
