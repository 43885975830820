import React from "react";
import styled from "styled-components";

import Page from "../layout/Page";
import useAuth from "../hooks/useAuth";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";
import AddHiringAgencyUser from "../widgets/AddHiringAgencyUser";

const StepWrapper = styled.div`
  && {
    width: 85%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
  }
`;

function AddHiringAgency(props) {
  const { user } = useAuth();
  let pageTitle = "Add Hiring Agency";

  return (
    <>
      <Page title={pageTitle} pageTitleCenter={true}>
        <BreadcrumWithTitle
          path={[
            { pathname: "/", title: "Home" },
            { pathname: user.custom_url ? `/${user.custom_url}/hiring-agencies` : "/hiring-agencies", title: "Hiring Agencies" },
            { pathname: "", title: "Add Hiring Agency" },
          ]}
          title={pageTitle}
        />
        <StyledDiv>
          <StepWrapper>
            <AddHiringAgencyUser />
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default AddHiringAgency;
