import styled from 'styled-components';
import { breakpoints, flex, fonts, textSizes } from '../../styles/vars';

export const Wrapper = styled.div`
  margin-bottom: ${textSizes[10]};
  margin-top: ${textSizes[10]};
  font-family: ${fonts.body};
  width: 100%;
  display: flex;
  ${flex.col}
  gap: ${textSizes[16]};
  ${breakpoints.laptop} {
    ${flex.end}
    align-items: flex-end;
  }
`;

export const NumberOfRowsWrapper = styled.div`
  font-family: ${fonts.body};
  font-size: ${textSizes[14]};
`;

export const NumberOfRowsText = styled.span`
  margin-right: ${textSizes[16]};
`;

export const PaginationContainer = styled.div`
  display: flex;
  ${flex.center}
  ${breakpoints.laptop} {
    display: block;
  }
`;
