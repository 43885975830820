import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Wrap } from "../../style/style";

// Create a styled component based on the Autocomplete component
const StyledDropdown = styled(Autocomplete)`
  width: ${(props) => props.width || "90%"};
`;

// Define a functional component for the SelectDropdown
const SelectDropdown = ({
  id,
  label = "",
  options,
  value,
  width = "",
  border = "",
  required = false,
  helperText, // Add helperText prop
  error, // Add error prop
  ...restProps
}) => {
  // Generate the title for the dropdown
  const title = `${label}${required ? "*" : ""}`;
  const errorMsg = helperText !== "REQUIRED" ? helperText : "";

  return (
    // Wrap the component in a custom Wrap component (assuming it's styled)
    <Wrap>
      <StyledDropdown
        id={id}
        options={options}
        value={value}
        size="small"
        width={width}
        // isOptionEqualToValue={(option, newOption) =>
        //   option.value === newOption.value
        // }
        {...restProps}
        style={{
          border: border ? border : "",
        }}
        renderInput={(params) => (
          // Render the TextField with the calculated title
          <TextField
            {...params}
            label={title}
            variant="outlined"
            helperText={errorMsg} // Use the helperText prop here
            error={error} // Use the error prop here
          />
        )}
      />
    </Wrap>
  );
};

// Define propTypes to specify the expected types for props
SelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  width: PropTypes.string,
  required: PropTypes.bool,
};

// Define defaultProps to provide default values for some props
SelectDropdown.defaultProps = {
  label: "",
  width: "",
  required: false,
};

export default SelectDropdown;
