import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import { Content } from "../../../components/style/style";
import { masterReference } from "../../../constants/masterData";
import SelectDropdown from "../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import {
  getGoals,
  getSchoolDropdownList,
  transferStudent,
  getForeignInstituteDropdownList,
} from "../../../services/schools";
import { toTitleCase } from "../../../utils/utility";

const Transfer = ({ company_id, requestId, school_id, entity_type }) => {
  const { notify } = useNotistack();
  const { setIsDirty, transferRecord, setTransferRecord, setDisableTransfer } =
    useCustomFormSubmit();

  const [goalsList, setGoalsList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);

  const handleSubmit = (values) => {
    console.log(values);
    setTransferRecord(false);
    let submittingData = {
      entity_type: entity_type,
      entity_id: values.school_id,
      goal_id: values.goal_id,
      student_id: values.student_id,
    };
    transferStudent(submittingData)
      .then((res) => {
        if (res.isOk) {
          setIsDirty(false);
          setDisableTransfer(true);
          notify("Student Transferred successfully", "success");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const validateSchema = Yup.object().shape({
    school_id: Yup.string().required("REQUIRED"),
    goal_id: Yup.string().required("REQUIRED"),
    student_id: Yup.number().required("REQUIRED"),
  });

  // Initialize transferFormik with form configuration
  const transferFormik = useFormik({
    initialValues: {
      school_id: "",
      goal_id: "",
      student_id: "",
    },
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  const handleTransferClose = () => {
    setTransferRecord(false);
    transferFormik.resetForm();
  };

  useEffect(() => {
    if (entity_type === masterReference.SCHOOL) {
      getSchoolDropdownList(company_id)
        .then((res) => {
          if (res.isOk) {
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            const list = res.data.filter((item) => item.value !== school_id);
            setSchoolList(list);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (entity_type === masterReference.FOREIGN_INSTITUTE) {
      getForeignInstituteDropdownList(company_id)
        .then((res) => {
          if (res.isOk) {
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });

            const list = res.data.filter((item) => item.value !== school_id);
            setSchoolList(list);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setSchoolList([]);
    }
  }, [company_id]);

  useEffect(() => {
    if (transferFormik.values.school_id) {
      const entity_id = transferFormik.values.school_id;
      getGoals({ entity_type, entity_id }).then((res) => {
        if (res.isOk) {
          res.data.forEach((ele) => {
            ele.label = toTitleCase(ele.label);
          });
          setGoalsList(res.data);
        }
      });
    }
  }, [transferFormik.values.school_id]);

  useEffect(() => {
    if (requestId) {
      transferFormik.setFieldValue("student_id", requestId);
    }
  }, [requestId]);

  return (
    <Dialog
      open={transferRecord}
      onClose={handleTransferClose}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle>Transfer Student to Another School</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleTransferClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: colors.red,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form onSubmit={transferFormik.handleSubmit}>
          <Content component="div">
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} sm={12}>
                {schoolList && (
                  <SelectDropdown
                    id={"school_id"}
                    name={"school_id"}
                    options={schoolList}
                    width="100%"
                    label={
                      entity_type === "foreign_institute"
                        ? "Foreign Institute"
                        : "School"
                    }
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      transferFormik.setFieldValue(
                        "school_id",
                        selected?.value || ""
                      );
                    }}
                    value={
                      schoolList.find(
                        (option) =>
                          option.value === transferFormik.values.school_id
                      ) || ""
                    }
                    helperText={
                      transferFormik.touched.school_id
                        ? transferFormik.errors.school_id
                        : ""
                    }
                    error={
                      transferFormik.touched.school_id &&
                      Boolean(transferFormik.errors.school_id)
                    }
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {schoolList && (
                  <SelectDropdown
                    id={"goal_id"}
                    name={"goal_id"}
                    options={goalsList}
                    width="100%"
                    label="Goals"
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      transferFormik.setFieldValue(
                        "goal_id",
                        selected?.value || ""
                      );
                    }}
                    value={
                      goalsList.find(
                        (option) =>
                          option.value === transferFormik.values.goal_id
                      ) || ""
                    }
                    helperText={
                      transferFormik.touched.goal_id
                        ? transferFormik.errors.goal_id
                        : ""
                    }
                    error={
                      transferFormik.touched.goal_id &&
                      Boolean(transferFormik.errors.goal_id)
                    }
                    required
                  />
                )}
              </Grid>
            </Grid>
          </Content>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
            <Button
              onClick={handleTransferClose}
              type="button"
              color="error"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Transfer
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Transfer;
