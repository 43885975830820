import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  Tooltip,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { textSizes } from "../../../../styles/vars";
import useNotistack from "../../../../hooks/useNotistack";
import useAuth from "../../../../hooks/useAuth";
import InfoLabel from "../../../../components/UI/InfoLabel";
import LabeledFormInput from "../../../../components/UI/LabeledFormInput";
import { Content, Wrapper } from "../../../../components/style/style";
import SearchSelect from "../../../../components/UI/SearchSelect";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import DateRangePicker from "../../../../widgets/DateRangePicker";
import BtnLink from "../../../../components/UI/BtnLink";
import { colors } from "../../../../constants/colors";
import {
  checkLessonTeacher,
  getLessonTimeTableDetail,
  setLessonAsDefault,
} from "../../../../services/planning";
import { getTeacherListDropdown } from "../../../../services/planning";
import WidgetsLoader from "../../../../components/WidgetsLoader";
import { masterReference } from "../../../../constants/masterData";
import { dateFormFormat } from "../../../../utils/dates";

export const TableGrid = styled(Grid)`
  && {
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const Timeformat = (time) => {
  const formattedTime = time.split(":").slice(0, 2).join(":");
  return formattedTime;
};

const Timetable = ({ formik, LessonList, setLessonList, data, setData }) => {
  // Get the isEditable flag from a custom hook
  const {
    isDirty,
    isEditable,
    setIsDirty,
    requestId,
    isFormSubmitRequested,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const initialValues = {
    subject: "",
    teacher_id: 0,
    lesson_index: 0,
    lesson_day_index: 0,
    room: "",
    copy_to_weekdays: false,
  };

  // ------------------set week calender widget to current week ----------------
  const weekFormat = "YYYY-MM-DD";
  const weekStartEndFormat = (value) => {
    return `${dayjs(value)
      .startOf("week")
      .add(1, "day")
      .format(weekFormat)} ~ ${dayjs(value)
      .endOf("week")
      .add(1, "day")
      .format(weekFormat)}`;
  };
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(weekStartEndFormat);
  const [open, setOpen] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [weekData, setWeekData] = useState(false);
  const { user } = useAuth();
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [defaultLessonExists, setDefaultLessonExists] = useState(false);
  const [selectedLessonIndex, setSelectedLessonIndex] = useState(false);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [datesList, setDatesList] = useState([]);
  const [sameTeacherFlag, setSameTeacherFlag] = useState(false);

  const [isDataExists, setIsDataExists] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    let selectedLessonIndex = values.lesson_index;
    let selectedWeekIndex = values.lesson_day_index;
    let copy_to_weekdays = values.copy_to_weekdays;

    if (copy_to_weekdays) {
      for (let i = 0; i < 5; i++) {
        let requestParams = {
          course_id: requestId,
          teacher_id: values.teacher_id,
          day_name:
            formik.values.lessons?.[selectedLessonIndex]?.lesson_time_table?.[i]
              ?.day_name,
          start_time: formik.values.lessons?.[selectedLessonIndex]?.start_time,
          end_time: formik.values.lessons?.[selectedLessonIndex]?.end_time,
          start_date: formik.values.start_date,
          end_date: formik.values.end_date,
        };
        checkLessonTeacher(requestParams).then((res) => {
          if (res?.isOk && res?.data) {
            let flag =
              res.data.isTeacherinAnotherLesson[0].teacher_in_other_lesson;
            if (flag) {
              formik.setFieldValue(
                `lessons.${selectedLessonIndex}.lesson_time_table.${i}.teacher_id`,
                0
              );
            } else {
              formik.setFieldValue(
                `lessons.${selectedLessonIndex}.lesson_time_table.${i}.teacher_id`,
                values.teacher_id
              );
              formik.setFieldValue(
                `lessons.${selectedLessonIndex}.lesson_time_table.${i}.subject`,
                values.subject
              );

              formik.setFieldValue(
                `lessons.${selectedLessonIndex}.lesson_time_table.${i}.room`,
                values.room
              );
            }
            setSameTeacherFlag(flag);
          }
        });
      }
    } else {
      formik.setFieldValue(
        `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}.subject`,
        values.subject
      );

      formik.setFieldValue(
        `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}.teacher_id`,
        values.teacher_id
      );

      formik.setFieldValue(
        `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}.room`,
        values.room
      );
    }

    // ---------------------Update formik for selected lesson and day from nested form ----------------

    // let start_range = formik.values.start_date;
    // let end_range = formik.values.end_date;

    // let allDatesList = getDatesInRange(start_range, end_range);

    // const lessonValues = {
    //   id:
    //     formik.values.lessons[selectedLessonIndex].lesson_time_table[
    //       selectedWeekIndex
    //     ]["id"] || 0,
    //   lesson_id: formik.values.lessons[selectedLessonIndex]["lesson_id"],
    //   subject: values.subject || "",
    //   day_date:
    //     formik.values.lessons[selectedLessonIndex].lesson_time_table[
    //       selectedWeekIndex
    //     ]["day_date"] ||
    //     dayjs(allDatesList[selectedWeekIndex]).format("YYYY-MM-DD"),
    //   day_name:
    //     formik.values.lessons[selectedLessonIndex].lesson_time_table[
    //       selectedWeekIndex
    //     ]["day_name"] ||
    //     weekdays[
    //       allDatesList[selectedWeekIndex]?.getDay() !== 0
    //         ? allDatesList[selectedWeekIndex]?.getDay() - 1
    //         : 6
    //     ],
    //   teacher_id: values.teacher_id || 0,
    //   room: values.room || "",
    //   copy_to_weekdays: false,
    // };

    // formik.setFieldValue(
    //   `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}`,
    //   lessonValues
    // );

    // if (popupFormik.values.copy_to_weekdays) {
    //   setSameForAllWeek();
    // }

    handleCloseTable();
  };

  useEffect(() => {
    if (sameTeacherFlag) {
      notify(
        "Teacher not updated as teacher is already selected for another course",
        "error"
      );
    }
  }, [sameTeacherFlag]);
  // --------------------Validate teacher for lesson based on subject value and teacher selected ----------------
  const validateTeacher = (value, type) => {
    if (type) {
      if (parseInt(value) === 0) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required(),

    teacher_id: Yup.string().test(
      "customValidation",
      "This field is Required",
      (value, context) => {
        if (context.parent.subject) {
          return validateTeacher(value, context.parent.subject);
        }
        return true;
      }
    ),
  });

  const popupFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      popupFormik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, popupFormik.handleSubmit, setFormSubmitRequested]);

  // -----------------------Function to handle leave / stay on dialog box click----------------
  const handleClose = () => setOpen(false);
  const handleLeave = () => {
    setOpen(false);
    setIsDirty(false);

    navigate(redirectUrl);
    setWeekRange(weekData);
  };

  // -----------------------Function to set start and end date in formik according to week selected from widget---------------
  const setWeekRange = (weekNumber) => {
    weekNumber = weekNumber ? weekNumber : dayjs();

    setSelectedWeek(weekStartEndFormat(weekNumber));

    let currentWeekRange = weekStartEndFormat(weekNumber);

    let start_date = `${currentWeekRange.split("~")[0].trim()}`;
    let end_date = `${currentWeekRange.split("~")[1].trim()}`;
    formik.setFieldValue("start_date", start_date);
    formik.setFieldValue("end_date", end_date);

    //------call get Lesson API when changed week range----------
    let requestParams = {
      id: requestId,
      start_date: start_date,
      end_date: end_date,
    };

    let allDatesList = getDatesInRange(start_date, end_date);

    getLessonTimeTableDetail(requestParams).then((res) => {
      if (res.isOk && res.data) {
        if (res.data && res.data.lessons) {
          const selectedData = {};
          selectedData["start_date"] = res.data?.start_date
            ? res.data?.start_date
            : dayjs().startOf("week").add(1, "day").format("YYYY-MM-DD");
          selectedData["end_date"] = res.data?.end_date
            ? res.data?.end_date
            : dayjs().endOf("week").add(1, "day").format("YYYY-MM-DD");
          selectedData["is_default"] = res.data?.is_default
            ? Boolean(parseInt(res.data?.is_default))
            : false;
          selectedData["set_as_default"] = false;
          selectedData["entity_id"] = res.data?.lessons?.[0]?.entity_id;
          selectedData["entity_type"] = res.data?.lessons?.[0]?.entity_type;
          selectedData["lessons"] = res.data.lessons;

          res.data.lessons.forEach((lessonItem, lessonIndex) => {
            if (lessonItem?.lesson_time_table.length > 0) {
              lessonItem?.lesson_time_table?.forEach((obj, index) => {
                const lessonValues = {
                  [`id`]: obj.id,
                  [`lesson_id`]: lessonItem.lesson_id,
                  [`subject`]: obj.subject,
                  [`day_date`]:
                    obj.day_date ||
                    // dayjs(allDatesList[index]).format("YYYY-MM-DD"),
                    dateFormFormat(allDatesList[index]),
                  [`day_name`]:
                    obj.day_name ||
                    weekdays[
                      allDatesList[index]?.getDay() !== 0
                        ? allDatesList[index]?.getDay() - 1
                        : 6
                    ],
                  [`teacher_id`]: obj.teacher_id,
                  [`room`]: obj.room,
                  [`copy_to_weekdays`]: false,
                };

                selectedData["lessons"][lessonIndex]["lesson_time_table"][
                  index
                ] = lessonValues;
              });
            } else {
              allDatesList.forEach((date, index) => {
                const lessonValues = {
                  id: 0,
                  room: "",
                  course_id: 1,
                  subject: "",
                  day_name:
                    weekdays[date.getDay() !== 0 ? date.getDay() - 1 : 6],
                  day_date:
                    // dayjs(date).format("YYYY-MM-DD"),
                    dateFormFormat(date),
                  teacher_id: 0,
                  is_default: false,
                  copy_to_weekdays: false,
                };
                selectedData["lessons"][lessonIndex]["lesson_time_table"][
                  index
                ] = lessonValues;
              });
            }
          });

          setData(selectedData);

          setLessonList(selectedData);
        } else {
          popupFormik.resetForm();
        }

        formik.setValues(data);
      }
    });
  };

  // ------------------On week change check if any data was changed , if so show dialog box-----------------
  const handleWeekChange = (weekNumber) => {
    setWeekData(weekNumber);
    if (isDirty) {
      setOpen(true);
    } else {
      setWeekRange(weekNumber);
    }
  };

  // --------------------------------Common function to set data for selected week as default week data----------------
  const setLessonData = () => {
    let requestParams = {
      id: requestId,
    };

    setLessonAsDefault(requestParams).then((res) => {
      if (res?.data?.is_default) {
        setDefaultLessonExists(true);
      } else {
        setDefaultLessonExists(false);
      }

      if (res?.isOk && res?.data && formik.values) {
        let allDatesList = getDatesInRange(
          formik.values.start_date,
          formik.values.end_date
        );

        const selectedData = {};
        selectedData["start_date"] = formik.values.start_date;
        selectedData["end_date"] = formik.values.end_date;
        selectedData["is_default"] = formik.values.is_default;
        selectedData["set_as_default"] = true;
        selectedData["entity_id"] = res.data?.lessons?.[0]?.entity_id;
        selectedData["entity_type"] = res.data?.lessons?.[0]?.entity_type;
        selectedData["lessons"] = res.data.lessons;

        res.data.lessons?.forEach((lessonItem, lessonIndex) => {
          let time_table = Object.values(lessonItem?.lesson_time_table);

          time_table.forEach((obj, index) => {
            const lessonValues = {
              [`id`]: 0,
              [`lesson_id`]: lessonItem.lesson_id,
              [`subject`]: obj.subject,
              [`day_date`]:
                // dayjs(allDatesList[index]).format("YYYY-MM-DD"),
                dateFormFormat(allDatesList[index]),
              [`day_name`]:
                weekdays[
                  allDatesList[index]?.getDay() !== 0
                    ? allDatesList[index]?.getDay() - 1
                    : 6
                ],
              [`teacher_id`]: obj.teacher_id,
              [`room`]: obj.room,
              [`copy_to_weekdays`]: false,
            };

            selectedData["lessons"][lessonIndex]["lesson_time_table"][index] =
              lessonValues;
          });
        });

        setData(selectedData);

        formik.setValues(selectedData);
      }
    });
  };

  // ----------------Function to call on set As Default-------------
  const setAsDefault = (checkedVal) => {
    if (checkedVal) {
      //------call get Lesson API when changed week range----------
      setLessonData();
    } else {
      formik.setValues({});
    }
  };

  // ------------------------Dialog Popup for Edit Lesson Functions ------------------

  const handleCloseTable = () => {
    popupFormik.resetForm();
    setOpenTable(false);
  };
  //-----------------------Function for  "Edit" text click---------------------------
  const handleTablePopup = (lessonIndex, index) => {
    setIsLoading(true);
    let lesson_data = {
      subject:
        formik.values?.lessons?.[lessonIndex]?.lesson_time_table?.[index]
          ?.subject,
      teacher_id:
        formik.values?.lessons?.[lessonIndex]?.lesson_time_table?.[index]
          ?.teacher_id,
      room: formik.values?.lessons?.[lessonIndex]?.lesson_time_table?.[index]
        ?.room,
      lesson_index: lessonIndex,
      lesson_day_index: index,
      copy_to_weekdays: false,
    };
    popupFormik.setValues(lesson_data);
    // ------------------set default value in popup if editing existing data (edit) else show current user entered value (add)---------------
    // popupFormik.setFieldValue(
    //   "subject",
    //   formik.values?.lessons?.[lessonIndex]?.lesson_time_table?.[index]?.subject
    // );

    // popupFormik.setFieldValue(
    //   "teacher_id",
    //   formik.values?.lessons?.[lessonIndex]?.lesson_time_table?.[index]
    //     ?.teacher_id
    // );

    // popupFormik.setFieldValue(
    //   "room",
    //   formik.values?.lessons?.[lessonIndex]?.lesson_time_table?.[index]?.room
    // );

    setOpenTable(true);
    setSelectedLessonIndex(lessonIndex);
    setSelectedWeekIndex(index);

    setIsLoading(false);
  };

  // --------------on Clear button call this function -------------------
  const clearIndividualLessonData = () => {
    setIsDirty(true);
    // --------------------clear subject ,teacher and room value for lesson and day that was edited in popup---------------
    popupFormik.setFieldValue("subject", "");
    popupFormik.setFieldValue("teacher_id", "");
    popupFormik.setFieldValue("room", "");
    popupFormik.setFieldValue("copy_to_weekdays", false);

    // ---------------------clear for selcted day and lesson from main form---------------
    formik.setFieldValue(
      `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}.room`,
      ""
    );

    formik.setFieldValue(
      `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}.subject`,
      ""
    );
    formik.setFieldValue(
      `lessons.${selectedLessonIndex}.lesson_time_table.${selectedWeekIndex}.teacher_id`,
      0
    );

    handleCloseTable();
  };

  const setSameForAllWeek = () => {
    formik.values.lessons.forEach((lessonItem, lessonIndex) => {
      lessonItem.lesson_time_table.forEach((obj, index) => {
        if (lessonIndex === selectedLessonIndex && index < 5) {
          const lessonValues = {
            id: lessonItem.lesson_time_table[index]["id"] || 0,
            lesson_id: lessonItem["lesson_id"],
            subject:
              lessonItem.lesson_time_table[selectedWeekIndex].subject ||
              popupFormik.values.subject,

            day_date: lessonItem.lesson_time_table[index]["day_date"],

            day_name: lessonItem.lesson_time_table[index]["day_name"],

            teacher_id:
              lessonItem.lesson_time_table[selectedWeekIndex].teacher_id ||
              popupFormik.values.teacher_id,

            room:
              lessonItem.lesson_time_table[selectedWeekIndex].room ||
              popupFormik.values.room,
          };

          formik.setFieldValue(
            `lessons.${lessonIndex}.lesson_time_table.${index}`,
            lessonValues
          );
        }
      });
    });
  };
  // --------------------Function to get all dates between given range , here week range----------------

  function getDatesInRange(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  useEffect(() => {
    // ---------------Set flag based on if atleast one week is made default or not---------------
    let requestParams = {
      id: requestId,
    };

    // setSelectedWeek(weekStartEndFormat());
    setLessonAsDefault(requestParams).then((res) => {
      if (res?.data?.is_default) {
        setDefaultLessonExists(true);
      } else {
        setDefaultLessonExists(false);
      }
    });
    let requestParam = {
      school_id: requestId,
    };

    if (user.code === masterReference.HR) {
      requestParam = {
        school_id: requestId,
        entity_id: formik.values.entity_id,
        entity_type: formik.values.entity_type,
      };
    }
    getTeacherListDropdown(requestParam).then((res) => {
      if (res?.isOk && res?.data) {
        setTeacherList(res.data);
      }
    });
  }, [formik.values.lessons]);

  useEffect(() => {
    let start_range = selectedWeek.split("~")[0];
    let end_range = selectedWeek.split("~")[1];

    let allDatesList = getDatesInRange(start_range, end_range);

    setDatesList(allDatesList);
  }, [selectedWeek]);

  // --------------------- check if atleast one day data is added for any lesson ---------------
  useEffect(() => {
    setIsLoading(false);

    var dataExistsFlag = "";
    formik.values?.lessons?.forEach((lessonItem, lessonIndex) => {
      lessonItem?.lesson_time_table?.forEach((timeTable, tableIndex) => {
        if (timeTable.subject) {
          dataExistsFlag = dataExistsFlag || true;
        } else {
          dataExistsFlag = dataExistsFlag || false;
        }
      });
    });

    setIsDataExists(dataExistsFlag);

    // ----------------------------on clear if the data cleared is the only data entered for timetable , uncheck is default--------------

    if (!dataExistsFlag) {
      formik.setFieldValue("is_default", false);
    }
  }, [formik.values.lessons]);

  const onChangeTeacher = (value) => {
    let requestParams = {
      course_id: requestId,
      teacher_id: value,
      day_name:
        formik.values.lessons?.[selectedLessonIndex]?.lesson_time_table?.[
          selectedWeekIndex
        ]?.day_name,
      start_time: formik.values.lessons?.[selectedLessonIndex]?.start_time,
      end_time: formik.values.lessons?.[selectedLessonIndex]?.end_time,
      start_date: formik.values.start_date,
      end_date: formik.values.end_date,
    };
    checkLessonTeacher(requestParams).then((res) => {
      if (res?.isOk && res?.data) {
        let flag = res.data.isTeacherinAnotherLesson[0].teacher_in_other_lesson;

        if (flag) {
          notify("Teacher selected for another course", "error");
          popupFormik.setFieldValue("teacher_id", 0);
        } else {
          popupFormik.setFieldValue("teacher_id", value);
          popupFormik.setFieldError("teacher_id", "");
        }
      }
    });
  };

  return (
    <>
      <div align="center">
        <DateRangePicker
          selectedWeek={selectedWeek}
          onWeekChange={handleWeekChange}
          weekStartEndFormat={weekStartEndFormat}
        />
      </div>
      <Wrapper style={{ padding: 12 }}>
        <Content>
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      isEditable === false || isDataExists === false
                        ? true
                        : false
                    }
                    checked={formik.values.is_default}
                    onChange={(event) => {
                      setIsDirty(true);
                      event.target.checked
                        ? formik.setFieldValue("is_default", true)
                        : formik.setFieldValue("is_default", false);
                    }}
                  />
                }
                label="Set To Default"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      isEditable === false ||
                      defaultLessonExists === false ||
                      (defaultLessonExists && formik.values.is_default)
                        ? true
                        : false
                    }
                    checked={formik.values.set_as_default}
                    onChange={(event) => {
                      setIsDirty(true);

                      formik.setFieldValue(
                        "set_as_default",
                        event.target.checked
                      );

                      setAsDefault(event.target.checked);
                    }}
                  />
                }
                label="Copy Default Data"
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TableContainer>
                <Grid container>
                  <Grid item style={{ width: "100%" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Lesson</strong>
                          </TableCell>
                          {datesList.map((date, index) => (
                            <TableCell key={index} align="center">
                              <strong>
                                {
                                  weekdays[
                                    date.getDay() !== 0 ? date.getDay() - 1 : 6
                                  ]
                                }
                              </strong>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {LessonList.lessons?.map((lessonItem, lessonIndex) => (
                          <TableRow key={lessonIndex}>
                            <TableCell>
                              <div style={{ width: "98px" }}>
                                <Typography>
                                  {lessonItem.lesson_name}
                                </Typography>
                                {lessonItem.start_time &&
                                lessonItem.end_time ? (
                                  <Typography fontSize={15}>{`(${Timeformat(
                                    lessonItem.start_time
                                  )}-${Timeformat(
                                    lessonItem.end_time
                                  )})`}</Typography>
                                ) : null}
                              </div>
                            </TableCell>
                            {lessonItem.lesson_name &&
                              lessonItem?.lesson_time_table?.map(
                                (date, index) => (
                                  <>
                                    <TableCell key={index} align="center">
                                      <div>
                                        <Typography
                                          style={{ textAlign: "center" }}
                                        >
                                          <InfoLabel
                                            info={
                                              formik.values.lessons?.[
                                                lessonIndex
                                              ]?.lesson_time_table?.[index]
                                                ?.subject
                                            }
                                          />
                                        </Typography>
                                        <Typography
                                          style={{ textAlign: "center" }}
                                        >
                                          <InfoLabel
                                            info={
                                              teacherList.find(function (
                                                option,
                                                idx
                                              ) {
                                                return (
                                                  option.value ===
                                                  parseInt(
                                                    formik.values.lessons?.[
                                                      lessonIndex
                                                    ]?.lesson_time_table?.[
                                                      index
                                                    ]?.teacher_id
                                                  )
                                                );
                                              })?.label
                                            }
                                          />
                                        </Typography>
                                        <Typography
                                          style={{ textAlign: "center" }}
                                        >
                                          <InfoLabel
                                            info={
                                              formik.values.lessons?.[
                                                lessonIndex
                                              ]?.lesson_time_table?.[index]
                                                ?.room
                                            }
                                          />
                                        </Typography>

                                        {isEditable && (
                                          <Tooltip
                                            title={
                                              user.code ===
                                              masterReference.SCHOOL_TEACHER
                                                ? selectedWeek
                                                    .split("~")[0]
                                                    .trim() <
                                                  dayjs()
                                                    .startOf("week")
                                                    .add(1, "day")
                                                    .format(weekFormat)
                                                  ? "Past records cannot be edited"
                                                  : ""
                                                : ""
                                            }
                                          >
                                            <span>
                                              <Typography
                                                variant="p"
                                                color="blue"
                                                style={{
                                                  opacity:
                                                    user.code ===
                                                    masterReference.SCHOOL_TEACHER
                                                      ? selectedWeek
                                                          .split("~")[0]
                                                          .trim() <
                                                        dayjs()
                                                          .startOf("week")
                                                          .add(1, "day")
                                                          .format(weekFormat)
                                                        ? 0.5
                                                        : 1
                                                      : 1,
                                                }}
                                                onClick={() =>
                                                  handleTablePopup(
                                                    lessonIndex,
                                                    index
                                                  )
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  disabled={
                                                    user.code ===
                                                    masterReference.SCHOOL_TEACHER
                                                      ? selectedWeek
                                                          .split("~")[0]
                                                          .trim() <
                                                        dayjs()
                                                          .startOf("week")
                                                          .add(1, "day")
                                                          .format(weekFormat)
                                                        ? true
                                                        : false
                                                      : false
                                                  }
                                                >
                                                  Edit
                                                </button>
                                              </Typography>
                                            </span>
                                          </Tooltip>
                                        )}
                                      </div>
                                    </TableCell>
                                  </>
                                )
                              )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </TableContainer>
            </Grid>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                color: colors.primary,
                fontWeight: "bold",
                fontSize: textSizes["22"],
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "7px" }}
              >
                <WarningAmberIcon color="error" />
                Unsaved Changes
              </span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                sx={{
                  color: colors.primary,
                  padding: "20px 0px",
                  fontSize: textSizes["18"],
                }}
              >
                Your changes are not saved. Do you really want to leave ?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ paddingRight: "20px", paddingBottom: "20px" }}>
              <Button
                onClick={handleLeave}
                variant="outlined"
                color="error"
                style={{ padding: "8px 20px" }}
              >
                Leave
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                style={{ padding: "8px 20px" }}
              >
                Stay
              </Button>
            </DialogActions>
          </Dialog>
          {/* -------------------------Start Timetable Popup---------------- */}
          <Dialog
            open={openTable}
            onClose={handleCloseTable}
            width="auto"
            maxWidth={"xs"}
            maxHeight="500px"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {isLoading ? (
              <WidgetsLoader />
            ) : (
              <>
                <DialogTitle id="alert-dialog-title">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: "inline-flex",

                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <FormControl fullWidth color="success">
                      <Typography style={{ textAlign: "center", fontSize: 18 }}>
                        <InfoLabel
                          info={`${formik.values.lessons?.[selectedLessonIndex]?.lesson_name}   Date:- ${formik.values.lessons?.[selectedLessonIndex]?.lesson_time_table?.[selectedWeekIndex]?.day_date}`}
                        />
                      </Typography>
                    </FormControl>
                  </Grid>

                  <IconButton
                    aria-label="close"
                    onClick={handleCloseTable}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: colors.red,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  <form onSubmit={popupFormik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <LabeledFormInput
                          id="outlined-multiline-static"
                          label="Subject*"
                          name="subject"
                          disabled={!isEditable}
                          value={popupFormik.values.subject}
                          onChange={(event) => {
                            popupFormik.setFieldValue(
                              "subject",
                              event.target.value
                            );
                          }}
                          helperText={
                            popupFormik.touched.subject &&
                            popupFormik.errors.subject
                          }
                          error={
                            popupFormik.touched.subject &&
                            popupFormik.errors.subject
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <SearchSelect
                          style={{ marginTop: "0 !important" }}
                          name={`teacher_id`}
                          options={teacherList}
                          label="Teacher"
                          required
                          disabled={!isEditable}
                          value={
                            teacherList?.find(
                              (option) =>
                                option.value ===
                                parseInt(popupFormik.values.teacher_id)
                            ) || ""
                          }
                          onChange={(_, selected) => {
                            setIsDirty(true);
                            onChangeTeacher(selected?.value);
                          }}
                          helperText={
                            popupFormik.touched.teacher_id &&
                            popupFormik.errors.teacher_id
                          }
                          error={
                            popupFormik.touched.teacher_id &&
                            popupFormik.errors.teacher_id
                          }
                        />
                        {popupFormik.errors.teacher_id && (
                          <div
                            style={{ color: "red", marginTop: 10 }}
                            id="feedback"
                          >
                            {popupFormik.errors.teacher_id}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <LabeledFormInput
                          id="outlined-multiline-static"
                          label="Room"
                          disabled={!isEditable}
                          value={popupFormik.values.room}
                          onChange={(event) => {
                            setIsDirty(true);

                            popupFormik.setFieldValue(
                              "room",
                              event.target.value
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={
                                popupFormik.values.subject &&
                                popupFormik.values.teacher_id
                                  ? false
                                  : true
                              }
                              onChange={(event) => {
                                setIsDirty(true);

                                popupFormik.setFieldValue(
                                  "copy_to_weekdays",
                                  event.target.checked
                                );
                              }}
                            />
                          }
                          label="Copy To Weekdays"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"flex-end"}
                      style={{ marginTop: 5 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BtnLink
                          text="Clear"
                          id="addMoreFieldBtn"
                          onClick={() => {
                            clearIndividualLessonData();
                          }}
                          style={{
                            backgroundColor: colors.white,
                            color: colors.black,
                            height: "40px",
                            width: "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BtnLink
                          text="Add"
                          id="addMoreFieldBtn"
                          type="submit"
                          style={{
                            backgroundColor: colors.addBtn,
                            color: colors.white,
                            height: "40px",
                            width: "100px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
              </>
            )}
          </Dialog>
          {/* -------------------------End Timetable Popup------------------- */}
        </Content>
      </Wrapper>
    </>
  );
};

export default Timetable;
