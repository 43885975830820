import React from 'react';
import { Paper, Table } from '@mui/material';
import { dark, fonts, light } from '../../../styles/vars';
import styled from 'styled-components';
import theme from 'styled-theming';

const TableBackgroundComponent = styled.table`
  background-color:${light.widgetBg};
  color: ${light.text};
  border-radius: 10px;
`;

const AppTable = ({ children, ...rest }) => {
  return (
    <Paper sx={{ overflowX: 'auto', width: '100%' }}>
      <Table
        sx={{ fontFamily: fonts.body }}
        component={TableBackgroundComponent}
        {...rest}
      >
        {children}
      </Table>
    </Paper>
  );
};

export default AppTable;
