import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import styled from "styled-components";

import { handleKeys } from "../../utils/common";
import { toTitleCase } from "../../utils/utility";
import { dateFormFormat } from "../../utils/dates";
import WidgetsLoader from "../../components/WidgetsLoader";
import ShrinkSearchSelect from "../../components/UI/ShrinkSearchSelect";
import ShrinkLabelInput from "../../components/UI/ShrinkLabelInput";

export const LabeledCompanyInput = styled(ShrinkLabelInput)`
  width: 100%;
`;

export const GridItem = styled(Grid)`
  && {
    padding-top: 0px !important;
  }
`;

const UserForm = ({ formik, isLoading }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
  };

  return (
    <>
      {isLoading ? (
        <WidgetsLoader />
      ) : (
        <Grid container spacing={2}>
          <GridItem item xs={12} sm={6}>
            <ShrinkLabelInput
              id="first_name"
              name="first_name"
              title="First Name"
              placeholder="First Name"
              required
              value={toTitleCase(formik.values.first_name)}
              onChange={handleChange}
              helperText={
                formik.touched?.first_name ? formik.errors?.first_name : ""
              }
              error={formik.touched.first_name && formik.errors.first_name}
            />
            {/* {formik.touched.first_name && formik.errors.first_name && (
                <InputError>
                  {formik.errors.first_name.replace("first_name", "First Name")}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <ShrinkLabelInput
              id="last_name"
              name="last_name"
              title="Last Name"
              placeholder="Last Name"
              required
              value={toTitleCase(formik.values.last_name)}
              onChange={handleChange}
              helperText={
                formik.touched?.last_name ? formik.errors?.last_name : ""
              }
              error={formik.touched.last_name && formik.errors.last_name}
            />
            {/* {formik.touched.last_name && formik.errors.last_name && (
                <InputError>
                  {formik.errors.last_name.replace("last_name", "Last Name")}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <ShrinkLabelInput
              id="email"
              name="email"
              title="Email Address"
              required
              placeholder="example@domain.com"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              value={formik.values.email}
              helperText={formik.touched?.email ? formik.errors?.email : ""}
              error={formik.touched.email && formik.errors.email}
            />
            {/* {formik.touched.email && formik.errors.email && (
                <InputError>
                  {formik.errors.email.replace("email", "Email")}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <ShrinkLabelInput
              id="phone_no"
              name="phone_no"
              title="Phone Number"
              // placeholder="+1(000)-000-00-00"
              placeholder="9XXXXXXXXX"
              // required
              onKeyPress={handleKeys}
              onChange={handleChange}
              value={formik.values.phone_no}
              helperText={
                formik.touched?.phone_no ? formik.errors?.phone_no : ""
              }
              error={formik.touched.phone_no && formik.errors.phone_no}
            />
            {/* {formik.touched.phone_no && formik.errors.phone_no && (
                <InputError>
                  {formik.errors.phone_no.replace("phone_no", "phone")}
                </InputError>
              )} */}
          </GridItem>
          <GridItem item xs={12} sm={12}></GridItem>
        </Grid>
      )}
    </>
  );
};

export default UserForm;
