import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import useAuth from "../../../../hooks/useAuth";
import DateField from "../../../../components/UI/DateField";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import { masterReference } from "../../../../constants/masterData";
import { getCompaniesListForUser } from "../../../../services/users";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { toTitleCase } from "../../../../utils/utility";

// Define options for status and gender
const statusOptions = [
  { label: "Sprachkurs", value: "Sprachkurs" },
  { label: "Ausbildung/Anerkennung", value: "Ausbildung" },
  { label: "Verpflichtet", value: "Verpflichtet" },
  { label: "Archiv", value: "Archiv" },
];

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const General = ({
  formik,
  handleFormChange,
  EntityTypeList,
  EntitiesNameList,
}) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  const { user } = useAuth();
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (user.code.toLowerCase() === masterReference.SUPER_ADMIN) {
      getCompaniesListForUser().then((company) => {
        company.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setCompanyList(company.data);
        formik.setFieldValue("company_id", company.data[0]?.value);
      });
    }
  }, [user]);

  return (
    <Wrapper>
      <Content style={{ padding: "12px" }}>
        <>
          {isEditable ? (
            <Grid container>
              {/* Render editable form fields when isEditable is true */}
              <Grid item xs={12} sm={12}>
                {user.code === masterReference.SUPER_ADMIN ||
                user.code === masterReference.HR ||
                user.code === masterReference.ACCOUNTANT ? (
                  <Grid container spacing={2}>
                    {user.code === masterReference.SUPER_ADMIN && (
                      <Grid item xs={12} sm={4}>
                        <SelectDropdown
                          id={"company_id"}
                          name={"company_id"}
                          options={companyList}
                          label="company"
                          onChange={(_, selected) => {
                            setIsDirty(true);
                            formik.setFieldValue("entity_id", "");
                            formik.setFieldValue(
                              "company_id",
                              selected?.value || ""
                            );
                          }}
                          value={
                            companyList.find(
                              (option) =>
                                option.value === formik.values.company_id
                            ) || ""
                          }
                          helperText={
                            formik.touched?.company_id
                              ? formik.errors?.company_id
                              : ""
                          }
                          error={
                            formik.touched?.company_id &&
                            Boolean(formik.errors?.company_id)
                          }
                          required
                          width="97%"
                        />
                      </Grid>
                    )}
                    {(user.code === masterReference.HR ||
                      user.code === masterReference.ACCOUNTANT) && (
                      <>
                        <Grid item xs={4}>
                          <SelectDropdown
                            id={"entity_type"}
                            name={"entity_type"}
                            options={EntityTypeList}
                            width="97%"
                            label="Entity Type"
                            onChange={(_, selected) => {
                              setIsDirty(true);
                              formik.setFieldValue(
                                "entity_type",
                                selected?.value || ""
                              );
                            }}
                            value={
                              EntityTypeList.find(
                                (option) =>
                                  option.value === formik.values.entity_type
                              ) || ""
                            }
                            helperText={
                              formik.touched?.entity_type
                                ? formik.errors?.entity_type
                                : ""
                            }
                            error={
                              formik.touched?.entity_type &&
                              Boolean(formik.errors?.entity_type)
                            }
                            required
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <SelectDropdown
                            id={"entity_id"}
                            name={"entity_id"}
                            options={EntitiesNameList}
                            width="97%"
                            label="Entity Name"
                            onChange={(_, selected) => {
                              setIsDirty(true);
                              formik.setFieldValue(
                                "entity_id",
                                selected?.value || ""
                              );
                            }}
                            value={
                              EntitiesNameList.find(
                                (option) =>
                                  option.value === formik.values.entity_id
                              ) || ""
                            }
                            helperText={
                              formik.touched?.entity_id
                                ? formik.errors?.entity_id
                                : ""
                            }
                            error={
                              formik.touched?.entity_id &&
                              Boolean(formik.errors?.entity_id)
                            }
                            required
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : null}
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputField
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      value={formik.values.first_name}
                      onChange={handleFormChange}
                      width="97%"
                      helperText={
                        formik.touched?.first_name
                          ? formik.errors?.first_name
                          : ""
                      }
                      error={
                        formik.touched?.first_name &&
                        Boolean(formik.errors?.first_name)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      value={formik.values.last_name}
                      onChange={handleFormChange}
                      width="97%"
                      helperText={
                        formik.touched?.last_name
                          ? formik.errors?.last_name
                          : ""
                      }
                      error={
                        formik.touched?.last_name &&
                        Boolean(formik.errors?.last_name)
                      }
                      required
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <DateField
                      label="Date of Birth"
                      maxDate={dayjs()}
                      value={formik.values.dob}
                      name="dob"
                      onChange={(date) => {
                        setIsDirty(true);
                        formik.setFieldValue("dob", date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      width="97%"
                      helperText={formik.touched?.dob ? formik.errors?.dob : ""}
                      error={formik.touched?.dob && Boolean(formik.errors?.dob)}
                      required
                    />
                  </Grid> */}
                  {/* </Grid>
                <Grid container spacing={2}> */}
                  <Grid item xs={4}>
                    <InputField
                      id="email"
                      label="Email"
                      name="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onKeyUp={formik.handleBlur}
                      onChange={handleFormChange}
                      width="97%"
                      helperText={
                        formik.touched?.email ? formik.errors?.email : ""
                      }
                      error={
                        formik.touched?.email && Boolean(formik.errors?.email)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="citizenship"
                      name="citizenship"
                      value={formik.values.citizenship}
                      onChange={handleFormChange}
                      label="Citizenship"
                      width="97%"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      id="hr_number"
                      label="HR Number"
                      name="hr_number"
                      value={formik.values.hr_number}
                      onChange={handleFormChange}
                      width="97%"
                    />
                  </Grid>
                  {/* </Grid>
                <Grid container spacing={2}> */}
                  <Grid item xs={4}>
                    <InputField
                      id="place_of_birth"
                      label="Place of Birth"
                      name="place_of_birth"
                      value={formik.values.place_of_birth}
                      onChange={handleFormChange}
                      width="97%"
                      multiline
                      maxRows={4}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              {/* Render InfoLabel components for displaying details when isEditable is false */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  {formik.values.first_name && (
                    <Grid item xs={4}>
                      <InfoLabel
                        label={"First Name"}
                        info={formik.values.first_name}
                      />
                    </Grid>
                  )}

                  {formik.values.last_name && (
                    <Grid item xs={4}>
                      <InfoLabel
                        label={"Last Name"}
                        info={formik.values.last_name}
                      />
                    </Grid>
                  )}

                  {/* {formik.values.dob && (
                    <Grid item xs={4}>
                      <InfoLabel
                        label={"Date of Birth"}
                        info={formik.values.dob.format("YYYY-MM-DD")}
                      />
                    </Grid>
                  )} */}

                  {formik.values.email && (
                    <Grid item xs={4}>
                      <InfoLabel label={"Email"} info={formik.values.email} />
                    </Grid>
                  )}

                  {formik.values.citizenship && (
                    <Grid item xs={4}>
                      <InfoLabel
                        label={"Citizenship"}
                        info={formik.values.citizenship}
                      />
                    </Grid>
                  )}

                  {formik.values.hr_number && (
                    <Grid item xs={4}>
                      <InfoLabel
                        label={"HR Number"}
                        info={formik.values.hr_number}
                      />
                    </Grid>
                  )}

                  {formik.values.place_of_birth && (
                    <Grid item xs={4}>
                      <InfoLabel
                        label={"Place of Birth"}
                        info={formik.values.place_of_birth}
                      />
                    </Grid>
                  )}

                  {(user.code === masterReference.HR ||
                    user.code === masterReference.ACCOUNTANT) &&
                    formik.values.entity_type && (
                      <Grid item xs={4}>
                        <InfoLabel
                          label={"Entity Type"}
                          info={
                            EntityTypeList.find(
                              (option) =>
                                option.value === formik.values.entity_type
                            )?.label || EntityTypeList[0]?.label
                          }
                        />
                      </Grid>
                    )}

                  {(user.code === masterReference.HR ||
                    user.code === masterReference.ACCOUNTANT) &&
                    formik.values.entity_id && (
                      <Grid item xs={4}>
                        <InfoLabel
                          label={"Entity Name"}
                          info={
                            EntitiesNameList.find(
                              (option) =>
                                option.value === formik.values.entity_id
                            )?.label || EntitiesNameList[0]?.label
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </Content>
    </Wrapper>
  );
};

export default General;
