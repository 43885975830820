import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";

import useAuth from "../../../../hooks/useAuth";
import { Content, Wrapper } from "../../../../components/style/style";
import { colors } from "../../../../constants/colors";
import StudentVerticalLinearStepper from "./StudentStepper";
import ApplicantVerticalLinearStepper from "../../Applicant/Cockpit/ApplicantStepper";
import ApplicantDocuments from "../../Applicant/Documents";
import Course from "../../Applicant/Cockpit/Course";
import Status from "../../Applicant/Cockpit/Status";
import Documents from "../Documents";
import { getApplicantFilterData } from "../../../../services/applicants";
import { Heading } from "../../../../components/style/style";
// General component receives 'formik' as a prop
const Cockpit = ({
  formik,
  handleFormChange,

  checkDisabled,
  checkStudentDisabled,
  setContinueFlag,
  setContinueStudentFlag,
  continueDisableFlag,
  setContinueDisableFlag,
  otherStudentStages,
  otherStages,
}) => {
  const { user } = useAuth();
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    getApplicantFilterData().then((res) => {
      if (res.isOk && res.data) {
        setStatusList(res.data);
      }
    });
  }, [formik.values.status]);

  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          {/* <Typography
            flexDirection={"row-reverse"}
            color={colors.primarySelected}
            variant="subtitle1"
          >
            Status:&nbsp;
            {
              statusList?.find(
                (option) => option.value === formik.values.status_student
              )?.label
            }
          </Typography> */}
        </Grid>
        {formik.values.stageFlagList &&
        formik.values.flow_stage_list?.length > 0 ? (
          <>
            <Grid item sm={12} xs={12}>
              <br />
              <Heading>Applicant Cockpit Documents</Heading>
              <Wrapper style={{ padding: "12px" }}>
                <ApplicantVerticalLinearStepper
                  formik={formik}
                  continueDisableFlag={continueDisableFlag}
                  setContinueDisableFlag={setContinueDisableFlag}
                  checkDisabled={checkDisabled}
                  otherStages={otherStages}
                  steps={[
                    {
                      label: "Documents Collection",
                      description: (
                        <ApplicantDocuments
                          formik={formik}
                          user={user}
                          isCockpit={true}
                          isStudent={true}
                          setContinueFlag={setContinueFlag}
                          setContinueDisableFlag={setContinueDisableFlag}
                          continueDisableFlag={continueDisableFlag}
                          checkDisabled={checkDisabled}
                          stageName={
                            Object.keys(
                              formik.values.stageFlagList
                            )?.[0]?.split("_")[0]
                          }
                        />
                      ),
                    },

                    {
                      label: "Select Course",
                      description: <Course formik={formik} />,
                    },
                  ]}
                />
              </Wrapper>
            </Grid>
            <br />
          </>
        ) : null}

        {formik.values.student_stageFlagList &&
        formik.values.student_flow_stage_list?.length > 0 ? (
          <Grid item sm={12} xs={12}>
            <br />
            <Heading>Student Cockpit Documents</Heading>

            <Wrapper style={{ padding: "12px" }}>
              <StudentVerticalLinearStepper
                formik={formik}
                continueDisableFlag={continueDisableFlag}
                setContinueDisableFlag={setContinueDisableFlag}
                checkDisabled={checkStudentDisabled}
                otherStages={otherStudentStages}
                steps={[
                  {
                    label: "Documents Collection",
                    description: (
                      <Documents
                        formik={formik}
                        user={user}
                        isCockpit={true}
                        setContinueFlag={setContinueStudentFlag}
                        setContinueDisableFlag={setContinueDisableFlag}
                        continueDisableFlag={continueDisableFlag}
                        checkDisabled={checkStudentDisabled}
                        stageName={
                          Object.keys(
                            formik.values.student_stageFlagList
                          )?.[0]?.split("_")[0]
                        }
                      />
                    ),
                  },
                ]}
              />
            </Wrapper>
          </Grid>
        ) : (
          <p>Please add atleast one Student flow for Selected Goal .</p>
        )}
      </Grid>
    </>
  );
};

export default Cockpit;
