import styled from 'styled-components';
import theme from 'styled-theming';
import {
  colors,
  dark,
  breakpoints,
  effects,
  flex,
  fonts,
  light,
  textSizes
} from '../../styles/vars';

export const Search = styled.form`
  position: relative;
  flex-grow: 1;

  ${breakpoints.tablet} {
    margin-left: 26px;
    max-width: 540px;
  }

  ${breakpoints.laptopL} {
    margin-left: 0;
    width: 100%;
  }
`;

export const Label = styled.label`
  color: ${colors.gray};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 14px;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  padding: 10px 10px 10px 50px;
  font-size: ${textSizes['14']};
  background-color: ${light.highlight};
  transition: box-shadow var(--transition);

  &:hover {
    box-shadow: "0 0 0 2px #A2C0D4";
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

export const Header = styled.header`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${light.bodyBg};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: top 0.5s ease-in-out;
  gap: 16px;

  &.hidden {
    top: -200px;
  }

  &.sticky {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    top: 0;
  }

  button.square {
    background-color: ${light.widgetBg};

    &:hover,
    &:focus {
      background-color: ${colors.blue};
    }
  }

  ${breakpoints.tablet} {
    padding: 20px 30px;
    height: fit-content;
  }

  ${breakpoints.laptopL} {
    padding: 0;
    position: static;
    box-shadow: none !important;
  }

  ${breakpoints.desktop} {
    .logo-wrapper {
      width: 240px;
    }

    ${Search} {
      margin-left: 0;
    }
  }
`;

export const ActionWrapper = styled.div`
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  .info {
    ${flex.col}
    margin: -30px 20px 30px 0;
    position: relative;

    .position {
      font-family: ${fonts.accent};
      font-size: ${textSizes['10']};
      text-transform: uppercase;
    }

    .userInfo {
      flex-direction: column;
    }
  }

  .trigger {
    display: flex;
    align-items: center;
    color: ${colors.black};

    .userLogo {
      margin-right: 5px;
      border: 1px solid #2a61ff;
      border-radius: 50%;
      font-size: 19px;
      background-color: #5d87ff;
      color: #ffffff;
      vertical-align: middle;
      width: 2.2rem;
      height: 2.2rem;
      text-align: center;
      line-height: 2.2rem;
      font-weight: 600;
    }
  }

  ${breakpoints.laptopL} {
    margin-right: 20px;
  }

  ${breakpoints.desktop} {
    margin-right: 0;
  }
`;
