import React from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { breakpoints } from "../../styles/vars";
import LoginForm from "./Form";
import Header from "./Header";
import ChangeLanguage from "../../components/UI/ChangeLanguage";
import logo from "../../assets/images/logo.svg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-top: -5%;
  ${breakpoints.laptop} {
    width: 50%;
  }
`;
const LanguageContainer = styled.div`
  position: absolute;
  left: 95%;
  bottom: 92%;
`;

export default function LoginFormContainer() {
  const { t } = useTranslation();
  return (
    <Container>
      <LanguageContainer>
        <ChangeLanguage />
      </LanguageContainer>
      <Grid>
        <Grid item xs={12} sm={12}>
          <img src={logo} alt="We care" height={125} />
        </Grid>
      </Grid>
      {/* <img src={logo} alt="We care" height={125} /> */}
      <br />
      <Header>{t("Login")}</Header>
      <LoginForm />
    </Container>
  );
}
