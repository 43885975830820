import React, { useEffect, useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { Grid, TextField, IconButton } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Divider } from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { colors } from "../../../../constants/colors";
import BtnLink from "../../../../components/UI/BtnLink";
import DateField from "../../../../components/UI/DateField";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import { RoundTabs } from "../../../../components/UI/RoundTabs";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import {
  Content,
  Heading,
  Wrapper,
  NoShadaowWrapper,
} from "../../../../components/style/style";
import { dateFormFormat } from "../../../../utils/dates";

const ExamData = ({ formik, examinerNameList, examinerTypeList }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [open, setOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const handleYes = () => {
    setOpen(false);
    reduceTab(deleteIndex).then(() => removeExam(deleteIndex));
  };
  const examTypeList = [
    { label: "Oral", value: "oral" },
    { label: "Written", value: "written" },
    { label: "Practical", value: "practical" },
  ];

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleDailog = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const removeAttempt = (index, attemptIndex, id) => {
    let deleted_data = formik.values.delete_exam_data;
    if (id !== 0) {
      deleted_data.push(id);
      formik.setFieldValue("delete_exam_data", deleted_data);
    }
    [...formik.values.exam_data][index]["attempts"].splice(attemptIndex, 1);
    formik.setFieldValue(`exam_data.${index}`, formik.values.exam_data[index]);
  };

  const reduceTab = async (index) => {
    if (index === tabIndex && index !== 0) {
      setTabIndex(index - 1);
    }
  };

  useEffect(() => {
    if (formik.values.exam_data.length <= tabIndex) {
      setTabIndex(formik.values.exam_data.length - 1);
    }
  }, [tabIndex, formik.values.exam_data.length]);

  const removeExam = (index) => {
    let deleted_data = formik.values.delete_exam_data;
    deleted_data = deleted_data.concat(
      formik.values.exam_data[index].attempts
        .filter((obj) => obj.id !== 0)
        .map((obj) => obj.id)
    );
    formik.setFieldValue("delete_exam_data", deleted_data);
    let oldExamData = [...formik.values.exam_data];
    oldExamData.splice(index, 1);
    formik.setFieldValue(`exam_data`, oldExamData);
    setIsDirty(true);
  };

  function CustomTextField(props) {
    const { trulyAnError, ...other } = props;
    return React.createElement(TextField, {
      ...other,
      error: trulyAnError || props.error,
    });
  }

  return (
    <>
      {isEditable ? (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                maxWidth: "100%",
                background: "aliceblue",
              }}
            >
              <RoundTabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {formik.values.exam_data.map((item, index) => (
                  <Tab
                    key={`exam_tab${index}`}
                    label={`${t('Exam')} ${index + 1} `}
                    style={{
                      padding: "12px",
                      color: Boolean(
                        formik.touched.exam_data &&
                          formik.errors?.exam_data &&
                          formik.errors?.exam_data[index]
                      )
                        ? "red"
                        : "inherit",
                    }}
                    icon={
                      // formik.values.exam_data.length > 1 &&
                      Number(index) === Number(tabIndex) ? (
                        <CloseIcon
                          onClick={() => {
                            handleDailog(index);
                          }}
                          style={{
                            color: colors.red,
                            // padding: 0,
                            // marginRight: "7px",
                          }}
                        />
                      ) : null
                    }
                    iconPosition="end"
                  />
                ))}
                <IconButton
                  style={{ padding: 10, marginLeft: 6, fontSize: 13 }}
                  onClick={() => {
                    formik.setFieldValue("exam_data", [
                      ...formik.values.exam_data,
                      {
                        exam_name: "",
                        exam_type: "",
                        attempts: [
                          {
                            id: 0,
                            start_time: dayjs(),
                            end_time: dayjs().add(1, "day"),
                            examiner_1_type: "",
                            examiner_1: "",
                            examiner_2_type: "",
                            examiner_2: "",
                            examiner_3_type: "",
                            examiner_3: "",
                          },
                        ],
                      },
                    ]);
                    setTabIndex(formik.values.exam_data.length);
                  }}
                >
                  {t("Add Exam")} &nbsp;
                  <AddIcon style={{ color: colors.primarySelected }} />
                </IconButton>
                {/* <div
                  style={{ width: "100%", borderBottom: "2px solid #ccc" }}
                ></div> */}
              </RoundTabs>
            </Box>
          </Grid>
          {formik.values.exam_data.length > 0 && (
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                padding: "12px",
                background: colors.white,
                borderRadius: tabIndex ? "12px" : "0px 12px 12px 12px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <Grid container spacing={2}>
                {formik.values.exam_data.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={`exam_data${index}`}
                    style={{
                      display:
                        Number(tabIndex) === Number(index) ? "block" : "none",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectDropdown
                          id={`exam_type${index}`}
                          name={"exam_type"}
                          options={examTypeList}
                          label={t("Exam Type")}
                          onChange={(_, selected) => {
                            formik.setFieldValue(
                              `exam_data.${index}.exam_type`,
                              selected.value
                            );
                            setIsDirty(true);
                          }}
                          value={
                            examTypeList.find(
                              (option) => option.value === item.exam_type
                            ) || ""
                          }
                          helperText={
                            formik.errors?.exam_data && formik.touched.exam_data
                              ? formik.errors?.exam_data[index]?.exam_type
                              : ""
                          }
                          error={
                            formik.errors?.exam_data
                              ? Boolean(
                                  formik.errors?.exam_data[index]?.exam_type
                                ) && formik.touched.exam_data
                              : false
                          }
                          required
                          disableClearable
                          width="98%"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputField
                          id={`exam_name${index}`}
                          label={t("Exam Name")}
                          name={`exam_name`}
                          value={item.exam_name}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `exam_data.${index}.exam_name`,
                              event.target.value.replace(/^\s+/g, "")
                            );
                            setIsDirty(true);
                          }}
                          helperText={
                            formik.errors?.exam_data && formik.touched.exam_data
                              ? formik.errors?.exam_data[index]?.exam_name
                              : ""
                          }
                          error={
                            formik.errors?.exam_data
                              ? Boolean(
                                  formik.errors?.exam_data[index]?.exam_name
                                ) && formik.touched.exam_data
                              : false
                          }
                          required
                          width="98%"
                        />
                      </Grid>
                      {item.attempts.map((attempt, attemptIndex) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          key={`attempt${attemptIndex}`}
                        >
                          <NoShadaowWrapper>
                            <Heading
                              style={{
                                background: "aliceblue",
                                borderBottom: "2px solid #0b439629",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p>{t("Attempt")} {attemptIndex + 1}</p>
                                {formik.values.exam_data[index].attempts
                                  .length > 1 && (
                                  <IconButton
                                    onClick={() =>
                                      removeAttempt(
                                        index,
                                        attemptIndex,
                                        attempt.id
                                      )
                                    }
                                    size={"small"}
                                    style={{ backgroundColor: colors.red }}
                                  >
                                    <CloseIcon
                                      fontSize={"16px"}
                                      style={{ color: colors.white }}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            </Heading>
                            <Content
                              component={"div"}
                              style={{ padding: "12px" }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <DateField
                                    label={t("Start Date")}
                                    value={attempt.start_time}
                                    name={`start_time`}
                                    onChange={(date) => {
                                      formik.setFieldValue(
                                        `exam_data.${index}.attempts.${attemptIndex}.start_time`,
                                        date
                                      );
                                      setIsDirty(true);
                                    }}
                                    shouldDisableDate={(date) =>
                                      date <= dayjs().subtract(1, "day") &&
                                      attempt.start_time !== date
                                    }
                                    slots={{
                                      textField: CustomTextField,
                                    }}
                                    slotProps={{
                                      textField: {
                                        trulyAnError:
                                          formik.touched.exam_data &&
                                          formik.errors?.exam_data
                                            ? formik.errors?.exam_data[0]
                                              ? formik.errors?.exam_data[0][
                                                  "attempts"
                                                ]
                                                ? formik.errors?.exam_data[0][
                                                    "attempts"
                                                  ][0]["start_time"]
                                                : false
                                              : false
                                            : false,
                                        helperText:
                                          formik.touched.exam_data &&
                                          formik.errors?.exam_data
                                            ? formik.errors?.exam_data[0]
                                              ? formik.errors?.exam_data[0][
                                                  "attempts"
                                                ]
                                                ? t(formik.errors?.exam_data[0][
                                                    "attempts"
                                                  ][0]["start_time"])
                                                : ""
                                              : ""
                                            : "",
                                        size: "small",
                                      },
                                    }}
                                    required
                                    width="97%"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <DateField
                                    label={t("End Date")}
                                    value={dayjs(attempt.end_time)}
                                    name={"end_time"}
                                    onChange={(date) => {
                                      formik.setFieldValue(
                                        `exam_data.${index}.attempts.${attemptIndex}.end_time`,
                                        date
                                      );
                                      setIsDirty(true);
                                    }}
                                    shouldDisableDate={(date) =>
                                      date <= dayjs().subtract(1, "day") &&
                                      attempt.end_time !== date
                                    }
                                    slots={{
                                      textField: CustomTextField,
                                    }}
                                    slotProps={{
                                      textField: {
                                        trulyAnError:
                                          formik.touched.exam_data &&
                                          formik.errors?.exam_data
                                            ? formik.errors?.exam_data[0]
                                              ? formik.errors?.exam_data[0][
                                                  "attempts"
                                                ]
                                                ? formik.errors?.exam_data[0][
                                                    "attempts"
                                                  ][0]["end_time"]
                                                : false
                                              : false
                                            : false,
                                        helperText:
                                          formik.touched.exam_data &&
                                          formik.errors?.exam_data
                                            ? formik.errors?.exam_data[0]
                                              ? formik.errors?.exam_data[0][
                                                  "attempts"
                                                ]
                                                ? t(formik.errors?.exam_data[0][
                                                    "attempts"
                                                  ][0]["end_time"])
                                                : ""
                                              : ""
                                            : "",
                                        size: "small",
                                      },
                                    }}
                                    required
                                    width="97%"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectDropdown
                                    id={`e${index}a${attemptIndex}ExaminerType1`}
                                    name={"examiner_1_type"}
                                    options={examinerTypeList}
                                    label={`${t("Examiner")} 1 ${t("Qualification")}`}
                                    onChange={(_, selected) => {
                                      formik.setFieldValue(
                                        `exam_data[${index}].attempts[${attemptIndex}].examiner_1_type`,
                                        selected?.value
                                      );
                                      setIsDirty(true);
                                    }}
                                    value={
                                      examinerTypeList.find(
                                        (option) =>
                                          option.value ===
                                          attempt.examiner_1_type
                                      ) || ""
                                    }
                                    error={
                                      formik.touched.exam_data &&
                                      formik.errors?.exam_data
                                        ? formik.errors?.exam_data[0]
                                          ? formik.errors?.exam_data[0][
                                              "attempts"
                                            ]
                                            ? formik.errors?.exam_data[0][
                                                "attempts"
                                              ][0]["examiner_1_type"]
                                            : false
                                          : false
                                        : false
                                    }
                                    helperText={
                                      formik.touched.exam_data &&
                                      formik.errors?.exam_data
                                        ? formik.errors?.exam_data[0]
                                          ? formik.errors?.exam_data[0][
                                              "attempts"
                                            ]
                                            ? formik.errors?.exam_data[0][
                                                "attempts"
                                              ][0]["examiner_1_type"]
                                            : ""
                                          : ""
                                        : ""
                                    }
                                    width="97%"
                                    required
                                    disableClearable
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectDropdown
                                    id={`examiner_1`}
                                    name={"examiner_1"}
                                    options={
                                      examinerNameList[attempt.examiner_1_type]
                                        ? examinerNameList[
                                            attempt.examiner_1_type
                                          ]
                                        : []
                                    }
                                    label={`${t("Examiner")} 1`}
                                    onChange={(_, selected) => {
                                      formik.setFieldValue(
                                        `exam_data.${index}.attempts.${attemptIndex}.examiner_1`,
                                        selected?.value
                                      );
                                      setIsDirty(true);
                                    }}
                                    value={
                                      examinerNameList[attempt.examiner_1_type]
                                        ? examinerNameList[
                                            attempt.examiner_1_type
                                          ].find(
                                            (option) =>
                                              option.value ===
                                              attempt.examiner_1
                                          ) || ""
                                        : ""
                                    }
                                    error={
                                      formik.touched.exam_data &&
                                      formik.errors?.exam_data
                                        ? formik.errors?.exam_data[0]
                                          ? formik.errors?.exam_data[0][
                                              "attempts"
                                            ]
                                            ? formik.errors?.exam_data[0][
                                                "attempts"
                                              ][0]["examiner_1"]
                                            : false
                                          : false
                                        : false
                                    }
                                    helperText={
                                      formik.touched.exam_data &&
                                      formik.errors?.exam_data
                                        ? formik.errors?.exam_data[0]
                                          ? formik.errors?.exam_data[0][
                                              "attempts"
                                            ]
                                            ? formik.errors?.exam_data[0][
                                                "attempts"
                                              ][0]["examiner_1"]
                                            : ""
                                          : ""
                                        : ""
                                    }
                                    width="97%"
                                    required
                                    disableClearable
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectDropdown
                                    id={`e${index}a${attemptIndex}ExaminerType2`}
                                    name={"ExaminerType2"}
                                    options={examinerTypeList}
                                    label={`${t("Examiner")} 2 ${t("Qualification")}`}
                                    onChange={(_, selected) => {
                                      formik.setFieldValue(
                                        `exam_data[${index}].attempts[${attemptIndex}].examiner_2_type`,
                                        selected?.value
                                      );
                                      setIsDirty(true);
                                    }}
                                    value={
                                      examinerTypeList.find(
                                        (option) =>
                                          option.value ===
                                          attempt.examiner_2_type
                                      ) || ""
                                    }
                                    // helperText={
                                    //   formik.touched?.a1ExaminerType2
                                    //     ? formik.errors?.a1ExaminerType2
                                    //     : ""
                                    // }
                                    // error={
                                    //   formik.touched?.a1ExaminerType2 &&
                                    //   Boolean(formik.errors?.a1ExaminerType2)
                                    // }
                                    width="97%"
                                    disableClearable
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectDropdown
                                    id={`e${index}a${attemptIndex}Examiner2`}
                                    name={"examiner_2"}
                                    options={
                                      examinerNameList[attempt.examiner_2_type]
                                        ? examinerNameList[
                                            attempt.examiner_2_type
                                          ]
                                        : []
                                    }
                                    label={`${t("Examiner")} 2`}
                                    onChange={(_, selected) => {
                                      formik.setFieldValue(
                                        `exam_data.${index}.attempts.${attemptIndex}.examiner_2`,
                                        selected?.value
                                      );
                                      setIsDirty(true);
                                    }}
                                    value={
                                      examinerNameList[attempt.examiner_2_type]
                                        ? examinerNameList[
                                            attempt.examiner_2_type
                                          ].find(
                                            (option) =>
                                              option.value ===
                                              attempt.examiner_2
                                          ) || ""
                                        : ""
                                    }
                                    // helperText={
                                    //   formik.touched?.a1Examiner2
                                    //     ? formik.errors?.a1Examiner2
                                    //     : ""
                                    // }
                                    // error={
                                    //   formik.touched?.a1Examiner2 &&
                                    //   Boolean(formik.errors?.a1Examiner2)
                                    // }
                                    width="97%"
                                    disableClearable
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectDropdown
                                    id={`e${index}a${attemptIndex}ExaminerType3`}
                                    name={"ExaminerType3"}
                                    options={examinerTypeList}
                                    label={`${t("Examiner")} 3 ${t("Qualification")}`}
                                    onChange={(_, selected) => {
                                      formik.setFieldValue(
                                        `exam_data[${index}].attempts[${attemptIndex}].examiner_3_type`,
                                        selected?.value
                                      );
                                      setIsDirty(true);
                                    }}
                                    value={
                                      examinerTypeList.find(
                                        (option) =>
                                          option.value ===
                                          attempt.examiner_3_type
                                      ) || ""
                                    }
                                    // helperText={
                                    //   formik.touched?.a1ExaminerType3
                                    //     ? formik.errors?.a1ExaminerType3
                                    //     : ""
                                    // }
                                    // error={
                                    //   formik.touched?.a1ExaminerType3 &&
                                    //   Boolean(formik.errors?.a1ExaminerType3)
                                    // }
                                    width="97%"
                                    disableClearable
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectDropdown
                                    id={`e${index}a${attemptIndex}Examiner3`}
                                    name={"examiner_3"}
                                    options={
                                      examinerNameList[attempt.examiner_3_type]
                                        ? examinerNameList[
                                            attempt.examiner_3_type
                                          ]
                                        : []
                                    }
                                    label={`${t("Examiner")} 3`}
                                    onChange={(_, selected) => {
                                      formik.setFieldValue(
                                        `exam_data.${index}.attempts.${attemptIndex}.examiner_3`,
                                        selected?.value
                                      );
                                      setIsDirty(true);
                                    }}
                                    value={
                                      examinerNameList[attempt.examiner_3_type]
                                        ? examinerNameList[
                                            attempt.examiner_3_type
                                          ].find(
                                            (option) =>
                                              option.value ===
                                              attempt.examiner_3
                                          ) || ""
                                        : ""
                                    }
                                    // helperText={
                                    //   formik.touched?.a1Examiner3
                                    //     ? formik.errors?.a1Examiner3
                                    //     : ""
                                    // }
                                    // error={
                                    //   formik.touched?.a1Examiner3 &&
                                    //   Boolean(formik.errors?.a1Examiner3)
                                    // }
                                    width="97%"
                                    disableClearable
                                  />
                                </Grid>
                              </Grid>
                            </Content>
                          </NoShadaowWrapper>
                        </Grid>
                      ))}
                      {item.attempts.length < 2 && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "2%",
                          }}
                        >
                          <BtnLink
                            text={`${t("Second")} ${t("Attempt")}`}
                            id="addMoreBtn"
                            startIcon={<AddIcon />}
                            onClick={() => {
                              formik.setFieldValue(
                                `exam_data.${index}.attempts`,
                                [
                                  ...item["attempts"],
                                  {
                                    id: 0,
                                    start_time: dayjs(),
                                    end_time: dayjs().add(1, "day"),
                                    examiner_1_type: "",
                                    examiner_1: 0,
                                    examiner_2_type: "",
                                    examiner_2: 0,
                                    examiner_3_type: "",
                                    examiner_3: 0,
                                  },
                                ]
                              );
                            }}
                            style={{
                              backgroundColor: colors.addBtn,
                              color: colors.white,
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : formik.values.exam_data.length > 0 ? (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                maxWidth: "100%",
                background: "aliceblue",
              }}
            >
              <RoundTabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {formik.values.exam_data.map((item, index) => (
                  <Tab
                    key={`exam_tab${index}`}
                    label={`Exam ${index + 1} `}
                    style={{ paddingRight: "12px" }}
                  />
                ))}
              </RoundTabs>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              padding: "12px",
              background: colors.white,
              borderRadius: tabIndex ? "12px" : "0px 12px 12px 12px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          >
            <Grid container spacing={2}>
              {formik.values.exam_data.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  key={`exam_data${index}`}
                  style={{
                    display:
                      Number(tabIndex) === Number(index) ? "block" : "none",
                  }}
                >
                  <Grid container>
                    {item.exam_type && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel label={"Exam Type"} info={item.exam_type} />
                      </Grid>
                    )}
                    {item.exam_name && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel label={"Exam Name"} info={item.exam_name} />
                      </Grid>
                    )}
                    {item.attempts.map((attempt, attemptIndex) => (
                      <Grid item xs={12} sm={12} key={`attempt${attemptIndex}`}>
                        <NoShadaowWrapper>
                          <Heading
                            style={{
                              background: "aliceblue",
                              borderBottom: "2px solid #0b439629",
                            }}
                          >
                            <p>Attempt {attemptIndex + 1}</p>
                          </Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                <InfoLabel
                                  label={"Start Date"}
                                  info={dateFormFormat(attempt.start_time)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <InfoLabel
                                  label={"End Date"}
                                  info={dateFormFormat(attempt.end_time)}
                                />
                              </Grid>
                              {attempt.examiner_1 &&
                              examinerNameList[attempt.examiner_1_type] ? (
                                <Grid item xs={12} sm={4}>
                                  <InfoLabel
                                    label={"Examiner 1"}
                                    info={`${
                                      examinerNameList[
                                        attempt.examiner_1_type
                                      ].find(
                                        (option) =>
                                          option.value === attempt.examiner_1
                                      )?.label
                                    },
                                      ${
                                        examinerTypeList.find(
                                          (option) =>
                                            option.value ===
                                            attempt.examiner_1_type
                                        )?.label
                                      }`}
                                  />
                                </Grid>
                              ) : null}
                              {attempt.examiner_2 &&
                              examinerNameList[attempt.examiner_2_type] ? (
                                <Grid item xs={12} sm={4}>
                                  <InfoLabel
                                    label={"Examiner 2"}
                                    info={`${
                                      examinerNameList[
                                        attempt.examiner_2_type
                                      ].find(
                                        (option) =>
                                          option.value === attempt.examiner_2
                                      )?.label
                                    },
                                      ${
                                        examinerTypeList.find(
                                          (option) =>
                                            option.value ===
                                            attempt.examiner_2_type
                                        )?.label
                                      }`}
                                  />
                                </Grid>
                              ) : null}
                              {attempt.examiner_3 &&
                              examinerNameList[attempt.examiner_3_type] ? (
                                <Grid item xs={12} sm={4}>
                                  <InfoLabel
                                    label={"Examiner 3"}
                                    info={`${
                                      examinerNameList[
                                        attempt.examiner_3_type
                                      ].find(
                                        (option) =>
                                          option.value === attempt.examiner_3
                                      )?.label
                                    },
                                      ${
                                        examinerTypeList.find(
                                          (option) =>
                                            option.value ===
                                            attempt.examiner_3_type
                                        )?.label
                                      }`}
                                  />
                                </Grid>
                              ) : null}
                            </Grid>
                          </Content>
                        </NoShadaowWrapper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        "Exam data is not available"
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Delete Exam")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Are you sure you want to delete Exam")} {deleteIndex + 1} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ExamData;
