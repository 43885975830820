import React from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, IconButton, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";

import { colors } from "../../../../constants/colors";
import BtnLink from "../../../../components/UI/BtnLink";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import SelectDropdown from "../../../../components/UI/ColorSelectDropdown";

// Color options for the dropdown
const colorOptions = [
  { label: "Grey", value: "Grey", code: "#d3d3d3" },
  { label: "Red", value: "Red", code: "#b30000" },
  { label: "Green", value: "Green", code: "#007400" },
  { label: "Yellow", value: "Yellow", code: "#ffbf00" },
  { label: "Blue", value: "Blue", code: "#000074" },
  { label: "Black", value: "Black", code: "#000000" },
];

const General = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { t } = useTranslation();
  const removeLesson = (index, id) => {
    let deleted_data = formik.values.deleted_status;
    if (id) {
      setIsDirty(true);
      deleted_data.push(id);
      formik.setFieldValue("deleted_status", deleted_data);
    }
    let oldColorCodes = [...formik.values.status];
    oldColorCodes.splice(index, 1);
    formik.setFieldValue(`status`, oldColorCodes);
  };

  return (
    <Wrapper style={{ margin: "15px 65px" }}>
      <Content style={{ padding: "24px" }}>
        {isEditable ? (
          <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={6}>
                  <center>
                    <strong>{t("Status Name")}</strong>
                  </center>
                </Grid>
                <Grid item xs={5}>
                  <center>
                    <strong>{t("Color")}</strong>
                  </center>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {formik.values.status.length > 1 && (
                    <strong>{t("Delete")}</strong>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {formik.values.status.map((color, index) => (
                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    <TextField
                      id={`name${index}`}
                      name={`name`}
                      value={color.status_name}
                      fullWidth
                      onChange={(event) => {
                        setIsDirty(true);
                        formik.setFieldValue(
                          `status.${index}.status_name`,
                          event.target.value.trim()
                        );
                        setIsDirty(true);
                      }}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      helperText={t(
                        formik.errors?.status && formik.touched.status
                          ? formik.errors?.status[index]?.status_name
                          : ""
                      )}
                      error={
                        formik.errors?.status
                          ? Boolean(
                              formik.errors?.status[index]?.status_name
                            ) && formik.touched.status
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Tooltip
                      title={
                        color.applicant_count > 0 || color.student_count > 0
                          ? t(
                              "Color Status is disabled because Color is mapped with Applicants"
                            )
                          : ""
                      }
                    >
                      <span>
                        <center>
                          <SelectDropdown
                            id={`color${index}`}
                            name={"color"}
                            options={colorOptions}
                            onChange={(_, selected) => {
                              setIsDirty(true);
                              formik.setFieldValue(
                                `status.${index}.color_name`,
                                selected?.value || ""
                              );
                              formik.setFieldValue(
                                `status.${index}.color_code`,
                                selected?.code || ""
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              colorOptions.find(
                                (option) => option.value === color.color_name
                              ) || null
                            }
                            helperText={t(
                              formik.errors?.status && formik.touched.status
                                ? formik.errors?.status[index]?.color_name
                                : ""
                            )}
                            error={
                              formik.errors?.status
                                ? Boolean(
                                    formik.errors?.status[index]?.color_name
                                  ) && formik.touched.status
                                : false
                            }
                            disabled={
                              color.applicant_count > 0 ||
                              color.student_count > 0
                            }
                          />
                        </center>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    alignItems="center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Tooltip
                      title={
                        color.applicant_count > 0 || color.student_count > 0
                          ? t(
                              "Delete button is disabled because Color is mapped with Applicants"
                            )
                          : ""
                      }
                    >
                      <span>
                        {formik.values.status.length > 1 && (
                          <DeleteIcon
                            style={{
                              color: colors.red,
                              cursor: "pointer",

                              opacity:
                                color.applicant_count > 0 ||
                                color.student_count > 0
                                  ? 0.5
                                  : 1,
                            }}
                            onClick={() =>
                              color.applicant_count > 0 ||
                              color.student_count > 0
                                ? ""
                                : removeLesson(index, color.id)
                            }
                          />
                        )}
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex" }}
              justifyContent="flex-start"
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={11}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <BtnLink
                    text={t("Add More Status")}
                    id="addMoreBtn"
                    onClick={() => {
                      formik.setFieldValue(`status`, [
                        ...formik.values.status,
                        {
                          id: 0,
                          color_name: "",
                          status_name: "",
                          color_code: "",
                        },
                      ]);
                    }}
                    style={{
                      backgroundColor: colors.secondary,
                      color: colors.white,
                      marginRight: "6px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={2.5}>
                  <center>
                    <strong>{t("Status Name")}</strong>
                  </center>
                </Grid>
                <Grid item xs={2}>
                  <center>
                    <strong>{t("Color")}</strong>
                  </center>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {formik.values.status.map((color, index) => (
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "16px",
                  }}
                >
                  <Grid
                    item
                    xs={2.5}
                    justifyContent="center"
                    alignItems="center"
                    style={{ display: "flex" }}
                  >
                    <p>{color.status_name}</p>
                  </Grid>
                  <Grid item xs={2}>
                    <center>
                      <p>{color.color_name}</p>
                    </center>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Content>
    </Wrapper>
  );
};

export default General;
