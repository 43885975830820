import { IconButton } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../styles/vars";

export const StyledIconBtn = styled(IconButton)`
  && {
    color: ${(props) => props.textcolor} !important;
    background-color: ${(props) => props.background} !important;
  }

  &&:hover {
    background-color: ${(props) => props.background} !important;
    color: ${colors.light_gray} !important;
  }

  &&:focus {
    background-color: ${(props) => props.background} !important;
    color: ${colors.light_gray} !important;
  }
`;
