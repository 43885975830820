import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import CompaniesListPage from "../../pages/Companies";
import Sidebar from "../../layout/Sidebar";
import UsersListPage from "../../pages/Users";
import AddCompany from "../../pages/AddCompany";
import EditCompanyForm from "../../pages/EditCompany";
import AddUserForm from "../../pages/AddUser";
import EditUserForm from "../../pages/EditUser";
import CommonHeader from "../../widgets/CommonHeader";
import ForeignInstitutesPage from "../../pages/ForeignInstitutes";
import AddForeignInstitute from "../../pages/AddForeignInstitute";
import EditForeignInstitute from "../../pages/EditForeignInstitute";
import HiringAgenciesPage from "../../pages/HiringAgencies";
import AddHiringAgency from "../../pages/AddHiringAgency";
import EditHiringAgency from "../../pages/EditHiringAgency";
import useAuth from "../../hooks/useAuth";
import { masterReference } from "../../constants/masterData";
import SchoolsPage from "../../pages/Schools";
import AddSchool from "../../pages/AddSchool";
import EditSchool from "../../pages/EditSchool";
import UserProfile from "../../pages/UserProfile";
import ChangePassword from "../../pages/ChangePassword";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";


const SuperAdminLayout = () => {
  const drawerWidth = 220;
  const appRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    if (appRef.current) {
      appRef.current.scrollTo(0, 0);
    }
  }, []);

  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.code.trim().toLowerCase();
  const defaultUrl =
    userRole === masterReference.SUPER_ADMIN ? "" : `/${customUrl}`;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <CommonHeader console={'admin'} />
        {/* <Router> */}
        <Sidebar userRole="" drawerWidth={drawerWidth} open={open} setOpen={setOpen} />
        <div className="app" ref={appRef}>
          <div className="app_content">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/companies" element={<CompaniesListPage />} />
                <Route path="/companies/add" element={<AddCompany />} />
                <Route
                  path="/companies/:id/edit"
                  element={<EditCompanyForm />}
                />
                <Route
                  path={`${defaultUrl}/foreign-institutes`}
                  element={<ForeignInstitutesPage />}
                />
                <Route
                  path={`${defaultUrl}/foreign-institutes/add`}
                  element={<AddForeignInstitute />}
                />
                <Route
                  path={`${defaultUrl}/foreign-institutes/:id/edit`}
                  element={<EditForeignInstitute />}
                />
                <Route
                  path={`${defaultUrl}/hiring-agencies`}
                  element={<HiringAgenciesPage />}
                />
                <Route
                  path={`${defaultUrl}/hiring-agencies/add`}
                  element={<AddHiringAgency />}
                />
                <Route
                  path={`${defaultUrl}/hiring-agencies/:id/edit`}
                  element={<EditHiringAgency />}
                />
                <Route
                  path={`${defaultUrl}/schools`}
                  element={<SchoolsPage />}
                />
                <Route
                  path={`${defaultUrl}/schools/add`}
                  element={<AddSchool />}
                />
                <Route
                  path={`${defaultUrl}/schools/:id/edit`}
                  element={<EditSchool />}
                />
                <Route
                  path={`${defaultUrl}/users`}
                  element={<UsersListPage />}
                />
                <Route
                  path={`${defaultUrl}/users/add`}
                  element={<AddUserForm />}
                />
                <Route
                  path={`${defaultUrl}/users/:id/edit`}
                  element={<EditUserForm />}
                />
                <Route
                  path={`${defaultUrl}/user-profile`}
                  element={<UserProfile />}
                />
                <Route
                  path={`${defaultUrl}/change-password`}
                  element={<ChangePassword />}
                />
                <Route path={`/change-password`} element={<ChangePassword />} />
                {/* <Route path="/admins" element={<Login />} /> */}
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={
                        user.code.toLowerCase() === masterReference.SUPER_ADMIN
                          ? "/companies"
                          : `${defaultUrl}/users`
                      }
                      replace
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </div>
        {/* </Router> */}
      </Box>
    </>
  );
};

export default SuperAdminLayout;
