import { useState } from "react";
import { Link } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { logoutApi } from "../../../services/auth";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/authContext";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLayoutChange } from "../../../hooks/useLayoutChange";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import useAuth from "../../../hooks/useAuth";
import logo from "../../../assets/images/logo1.svg";
import localforage from "localforage";
import { Grid, IconButton } from "@mui/material";
import { masterReference } from "../../../constants/masterData";

const LogoImg = styled.img`
  object-fit: fill;
  width: 100px;
  height: 100%;
`;

const CurrentUser = (props) => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const { user } = useAuth();
  const { isAdminConsole, setIsAdminConsole } = useLayoutChange();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    try {
      let res = await logoutApi();
      if (res.isOk) {
        setIsAuthenticated(false);
        if (props.code !== "super_admin") {
          navigate(
            props.company_url === props.custom_url
              ? `/${props.custom_url}/login`
              : `/${props.company_url}/${props.custom_url}/login`
          );
        } else {
          navigate(`/login`);
        }
        try {
          setIsAdminConsole(true);
          // Clear all data in localForage
          await localforage.clear();
        } catch (error) {
          console.error("Error clearing data:", error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLayoutChange = async () => {
    try {
      if (isAdminConsole) {
        setIsAdminConsole(false);
      } else {
        setIsAdminConsole(true);
      }
      navigate(`/`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="info">
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              props.custom_url
                ? navigate(
                    props?.company_url === props?.custom_url
                      ? `/${props.custom_url}/change-password`
                      : `/${props.company_url}/${props.custom_url}/change-password`
                  )
                : navigate(`/change-password`);
              // setOpen(false);
            }}
          >
            <LockIcon fontSize="small" /> &nbsp; {t("Change Password")}
          </MenuItem>
          {user && user.code === "admin" ? (
            <MenuItem onClick={handleLayoutChange}>
              {isAdminConsole ? (
                <>
                  <DashboardIcon fontSize="small" />
                  &nbsp; {t("Main Console")}
                </>
              ) : (
                <>
                  <AdminPanelSettingsIcon fontSize="small" />
                  &nbsp; {t("Admin Console")}
                </>
              )}
            </MenuItem>
          ) : (
            <></>
          )}
          <MenuItem onClick={handleLogoutClick}>
            <LogoutIcon fontSize="small" />
            &nbsp; {t("Logout")}
          </MenuItem>
        </Menu>
      </div>
      <Grid container flexDirection={"row"} justifyContent={"flex-end"}>
        <Grid
          item
          sm={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            flexDirection: "column",
            textAlign: "center",
            paddingRight: "16px",
          }}
        >
          <div style={{ width: "fit-content" }}>
            <p style={{ fontWeight: "bold" }}>
              <Link
                onClick={() => {
                  navigate(
                    props?.company_url === props?.custom_url
                      ? `/${props.custom_url}/user-profile`
                      : `/${props.company_url}/${props.custom_url}/user-profile`
                  );
                }}
              >
                {user.first_name} {user.last_name}
              </Link>
            </p>
            <p>{t(user.role.trim())}</p>
          </div>
        </Grid>
        <Grid
          item
          sm={0.5}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <IconButton onClick={handleClick}>
            <KeyboardArrowDownIcon
              fontSize="medium"
              style={{ color: "#1976d2" }}
            />
          </IconButton>
        </Grid>
        {user.code !== masterReference.SUPER_ADMIN && (
          <Grid
            item
            sm={2}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <LogoImg src={logo} alt="We care" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CurrentUser;
