import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  dataJson,
  documentation_json,
  management_json,
  menu,
  planning_json,
  settings_json,
} from "../../constants/menu";
import NavBar from "../../widgets/NavBar";
import useAuth from "../../hooks/useAuth";
import UserProfile from "../../pages/UserProfile";
import CommonHeader from "../../widgets/CommonHeader";
import ChangePassword from "../../pages/ChangePassword";
import FlowCourseSteps from "../../pages/FlowCourseSteps";
import { masterReference } from "../../constants/masterData";

const AppLayout = () => {
  const { user } = useAuth();
  const companyURL = user.company_url?.trim();
  const customUrl = user.custom_url?.trim();
  const userRole = user.code?.trim()?.toLowerCase();
  const navbarDisableUrls = ['user-profile', 'change-password']
  const defaultRoute =
    user?.step?.goal_flow_count <= 0 || user?.step?.course_count <= 0
      ? "/steps/"
      : menu.find((item) => item.role.includes(userRole))?.route || "";

  // Function to render sub-routes for a given menu item
  const renderSubRoutes = (jsonList, defaultComponent = null) => {
    const subRoutes = jsonList
      .filter(({ readableRole }) => readableRole.includes(userRole))
      .map((subItem, subIndex) => (
        <Route
          path={`${subItem.route}`}
          element={subItem.component}
          key={subIndex}
        />
      ));

    if (defaultComponent) {
      subRoutes.unshift(
        <Route path="" element={defaultComponent} key="default" />
      );
    }

    return subRoutes;
  };


  // Function to check if the URL contains any of the strings from the disable urls
  const checkUrlContainsDisabledUrls = () => {
    const url = window.location.href
    return !navbarDisableUrls.some(str => url.includes(str));
  };
  

  return (
    <>
    <Box sx={{ display: "flex", flexDirection: "column", height: '100%' }}>
      <CssBaseline />
      <CommonHeader console={'main'} />
      <div style={{ paddingTop: "64px", height: '100%', background: 'aliceblue', display: 'flex', flexDirection: 'column' }}>
      {checkUrlContainsDisabledUrls() ? <NavBar /> : null}

      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {menu
            .filter(({ role }) => role.includes(userRole))
            .map((item, index) => (
              <Route
                path={
                  companyURL === customUrl
                    ? `/${customUrl}${item.route}`
                    : `/${companyURL}/${customUrl}${item.route}`
                }
                element={item.page}
                key={index}
              >
                {item.label === "Data" &&
                  renderSubRoutes(dataJson, dataJson[0].component)}
                {item.label === "Planning" &&
                  renderSubRoutes(planning_json, planning_json[0].component)}
                {item.label === "Settings" &&
                  renderSubRoutes(settings_json, settings_json[0].component)}
                {item.label === "Management" &&
                  renderSubRoutes(
                    management_json,
                    management_json[0].component
                  )}
                {item.label === "Documentation" &&
                  renderSubRoutes(
                    documentation_json,
                    documentation_json[0].component
                  )}
              </Route>
            ))}
          <Route
            path={
              companyURL === customUrl
                ? `${customUrl}/change-password/`
                : `${companyURL}/${customUrl}/change-password/`
            }
            element={<ChangePassword />}
          />
          <Route
            path={
              companyURL === customUrl
                ? `${customUrl}/user-profile/`
                : `${companyURL}/${customUrl}/user-profile/`
            }
            element={<UserProfile />}
          />
          {(user.code === masterReference.SCHOOL_MANAGER ||
            user.code === masterReference.FOREIGN_INSTITUTE_MANAGER) && (
            <Route
              path={
                companyURL === customUrl
                  ? `/${customUrl}/steps/`
                  : `/${companyURL}/${customUrl}/steps/`
              }
              element={<FlowCourseSteps />}
            />
          )}

          <Route
            path="*"
            element={
              <Navigate
                to={
                  companyURL === customUrl
                    ? `/${customUrl}${defaultRoute}`
                    : `/${companyURL}/${customUrl}${defaultRoute}`
                }
                replace
              />
            }
          />
        </Routes>
      </Suspense>
      </div>
    </Box>
    </>
  );
};

export default AppLayout;
