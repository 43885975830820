import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FormControlLabel, CardContent } from "@mui/material";

import { masterReference } from "../../constants/masterData";
import useAuth from "../../hooks/useAuth";
import { FilterButton } from "./style";
import FilterBar from "../FilterBar";
const {
  FilterBarWrapper,
  FilterWrapper,
  MenuHead,
  MUICard,
  MUMenu,
  MUICheckbox,
} = require("./style");

const FilterMenu = ({
  roleList,
  companyId,
  companyList,
  onRoleChange,
  onCompanyChange,
  roleSelectedValues,
}) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFollowUpChange = (event) => {
    let { value, checked } = event.target;
    value = parseInt(value);
    const selectedValues = [...roleSelectedValues];

    if (checked) {
      selectedValues.push(value);
    } else {
      const index = selectedValues.indexOf(value);
      if (index > -1) {
        selectedValues.splice(index, 1);
      }
    }

    onRoleChange(selectedValues);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FilterBarWrapper>
      <FilterWrapper>
        <FilterButton
          variant="outlined"
          onClick={handleClick}
          endIcon={<FilterAltIcon size="small" />}
        >
          Filter
        </FilterButton>
        <MUMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ top: "20px" }}
        >
          {user.code.toLowerCase() === masterReference.SUPER_ADMIN &&
            companyList && (
              <>
                <MenuHead>Company</MenuHead>
                {/* <MUICard> */}
                <div style={{ padding: "10px", paddingRight: "0px" }}>
                  <FilterBar
                    companyList={companyList}
                    onChange={onCompanyChange}
                    companyId={companyId}
                  />
                </div>
                {/* </MUICard> */}
              </>
            )}
          <MenuHead style={{ borderTop: "1px solid darkgrey" }}>Roles</MenuHead>
          <MUICard>
            <CardContent>
              {roleList.map((option, index) => (
                <FormControlLabel
                  key={option.label}
                  sx={{
                    "& .MuiFormControlLabel-label": { fontSize: "14px" },
                  }}
                  control={
                    <MUICheckbox
                      key={option.label}
                      checked={
                        roleSelectedValues.includes(option.value) ||
                        roleSelectedValues.includes(String(option.value))
                      }
                      value={option.value}
                      size="small"
                      onChange={handleFollowUpChange}
                    />
                  }
                  label={option.label}
                />
              ))}
            </CardContent>
          </MUICard>
        </MUMenu>
      </FilterWrapper>
    </FilterBarWrapper>
  );
};

export default FilterMenu;
