import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import InfoLabel from "../../../../components/UI/InfoLabel";
import DragDropWidget from "../../../../widgets/DragDropWidget";
import { colors } from "../../../../constants/colors";
import { getPlaceHolderList } from "../../../../services/file";
import { Wrapper, Content } from "../../../../components/style/style";

const Applicant = ({ formik }) => {
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [openPlaceholderPopup, setOpenPlaceholderPopup] = useState(false);
  const [placeholderList, setPlaceholderList] = useState([]);

  const handleClosePlaceholderPopup = () => {
    setOpenPlaceholderPopup(false);
  };

  useEffect(() => {
    let requestParams = {
      documentType: "applicant",
    };
    getPlaceHolderList(requestParams).then((res) => {
      if (res.data) {
        setPlaceholderList(res.data);
      }
    });
  }, []);

  return (
    <>
      <Button
        onClick={() => {
          setOpenPlaceholderPopup(true);
        }}
      >
        View Placeholder List
      </Button>
      {/* placeholder list popup view  */}

      <Dialog
        open={openPlaceholderPopup}
        onClose={handleClosePlaceholderPopup}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Applicant Placeholder List</DialogTitle>
        <DialogContent>
          Note : All placeholders should be written in square brackets [ ] .
        </DialogContent>
        
        <IconButton
          aria-label="close"
          onClick={handleClosePlaceholderPopup}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: colors.red,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Wrapper>
            <Content component="div">
              <Grid container style={{ padding: 10 }}>
                {placeholderList?.length > 0 &&
                  placeholderList?.map((placeHolder, index) => {
                    return (
                      <Grid item xs={12} sm={12}>
                        <InfoLabel
                          label={`${index + 1}. ${String(
                            placeHolder
                          ).toUpperCase()}`}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Content>
          </Wrapper>
        </DialogContent>
      </Dialog>
      <InfoLabel label="Bill" />
      <DragDropWidget
        name="applicant_bill"
        formik={formik}
        setIsDirty={setIsDirty}
      />
    </>
  );
};

export default Applicant;
