import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useNotistack from "../../hooks/useNotistack";
import Btn1 from "../../components/UI/Btn1";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import clsx from "clsx";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { LinkWrapper, StyledForm, InputWrapper, TextFieldTitle } from "./style";
import {
  IconButton,
  InputAdornment,
  TextField,
  css,
  Grid,
} from "@mui/material";
import { colors } from "../../styles/vars";

// Create a styled TextField component
const StyledTextField = styled(TextField)`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const InputError = styled("div")`
  color: #900;
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
`;

// const LinkWrapper = styled.div`
//   text-align: right;
//   margin: 10px 0px;
// `;

// const StyledForm = styled.form`
//   width: 70%;
//   padding: 20px;
//   display: flex;
//   ${flex.col}
// `;

// const InputWrapper = styled.div`
//   margin: 10px 0px;
// `;

// const PasswordInputWrapper = styled.div`
//   margin: 10px 0px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-item: flex-start;
//   position: relative;
// `;
// const IconWrapper = styled.div`
//   position: absolute;
//   right: 10px;
//   top: 37px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
// `;

const loginSchema = yup.object({
  email: yup.string().trim().email("Email must be valid").required(),
  password: yup.string().required(),
});

export default function LoginForm() {
  const { notify } = useNotistack();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const [authenticated, setAuthenticated] = useState(
  //   localStorage.getItem("authenticated") || "0"
  // );

  // useEffect(() => {
  //   localStorage.setItem("authenticated", authenticated);
  //   if (parseInt(authenticated)) {
  //     window.location.reload();
  //   }
  // }, [authenticated]);

  // const { enqueueSnackbar } = useSnackbar();

  const {
    // authenticationError,
    setAuthenticationError,
    loginWithEmailPassword,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const res = await loginWithEmailPassword(values.email, values.password);
        if (res.isOk) {
          setAuthenticationError("");
        } else {
          notify(t("Email or Password is incorrect"), "error");
          setAuthenticationError("Email or Password is incorrect");
        }
      } catch (e) {
        notify(t("Email or Password is incorrect"), "error");
        setAuthenticationError("Email or Password is incorrect");
      } finally {
        setIsLoading(false);
      }
      // if (values.email === "test@wecasoft.com" && values.password === "dev") {
      //   setAuthenticated("1");
      // } else {
      //   setAuthenticated("0");
      // }
    },
  });

  // useEffect(() => {
  //   if (authenticationError)
  //     enqueueSnackbar(authenticationError, {
  //       variant: 'error'
  //     });
  // }, [authenticationError, enqueueSnackbar]);

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      {/* <InputWrapper>
        <LabeledFormInput
          id="email"
          title="UserName"
          placeholder="UserName"
          name="email"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.errors.email}
        />
      </InputWrapper>

      <LabeledFormInput
        id="password"
        title="Password"
        placeholder="Password"
        type={showPassword ? "text" : "password"}
        name="password"
        required
        onChange={formik.handleChange}
        value={formik.values.password}
        error={formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldTitle style={{ textAlign: "left", fontWeight: 600 }}>
            {t("Username")}
          </TextFieldTitle>
          <InputWrapper>
            <StyledTextField
              size="small"
              id="email"
              title={t("Username")}
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
              fullWidth
              style={{ backgroundColor: "white" }}
            />

            {formik.touched.email && formik.errors.email && (
              <InputError>{t(formik.errors.email)}</InputError>
            )}
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextFieldTitle style={{ textAlign: "left", fontWeight: 600 }}>
            {t("Password")}
          </TextFieldTitle>
          <InputWrapper>
            <StyledTextField
              size="small"
              id="password"
              title={t("Password")}
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              style={{ backgroundColor: "white" }}
            />
            {formik.touched.password && formik.errors.password && (
              <InputError>
                {t(formik.errors.password.replace("password", "Password"))}
              </InputError>
            )}
          </InputWrapper>
        </Grid>
      </Grid>

      <LinkWrapper>
        <Link to="/forgot-password">{t("Forgot Password?")}</Link>
      </LinkWrapper>

      <div
        style={{
          marginTop: "50px",
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Btn1
          color={colors.peach}
          className={clsx(isLoading && "disabled")}
          disabled={isLoading || !formik.isValid}
          type="submit"
          text={t(!isLoading ? "Login" : "Please wait...")}
        />
      </div>
    </StyledForm>
  );
}
