import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { styled } from "styled-components";

import useAuth from "../../hooks/useAuth";
import { colors } from "../../styles/vars";
import logo from "../../assets/images/logo1.svg";
import CurrentUser from "../../layout/Panel/CurrentUser";
import { getLoginForCompany } from "../../services/auth";
import { masterReference } from "../../constants/masterData";
import ChangeLanguage from "../../components/UI/ChangeLanguage";

const LogoImg = styled.img`
  object-fit: fill;
  width: 130px;
  height: 100%;
`;

const ContentTitle = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 18px;
  color: ${colors.primary};
  font-size: 1.5rem;
  font-weight: 600;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  flex-grow: 1;
`;

const CommonHeader = (props) => {
  const { user } = useAuth();
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    getLoginForCompany(user.custom_url)
      .then(async function (data) {
        if (data.isOk) {
          setCompanyLogo(data.data.logo);
          setCompanyName(data.data.name);
        }
      })
      .catch(function () {});
  }, [user]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          height: "64px",
          backgroundColor: "aliceblue",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          // boxShadow: props.console === 'main' && 'none',
          // borderBottom: props.console === 'main' && '1px solid #E5E5E5',
        }}
      >
        {/* <HeaderWrapper> */}
        <Grid
          container
          alignItems="center"
          style={{ padding: "0 15px", height: "100%" }}
        >
          <Grid item xs={6}>
            <a
              href="/"
              style={{ display: "flex", width: "fit-content", height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "55px",
                  width:
                    user.code === masterReference.SUPER_ADMIN ||
                    (companyLogo &&
                      companyLogo.split("/").pop() &&
                      companyLogo.split("/").pop() !== "None")
                      ? "55px"
                      : "100%",
                  marginLeft: "10px",
                }}
              >
                {user.code === masterReference.SUPER_ADMIN ? (
                  <LogoImg src={logo} alt="We care" />
                ) : companyLogo &&
                  companyLogo.split("/").pop() &&
                  companyLogo.split("/").pop() !== "None" ? (
                  <LogoImg src={companyLogo} alt="We care" />
                ) : (
                  <ContentTitle>
                    {companyName.length > 40
                      ? companyName.substring(0, 40) + "..."
                      : companyName}
                  </ContentTitle>
                )}
              </div>
            </a>
          </Grid>
          <Grid item xs={6}>
            <Actions>
              <CurrentUser {...user} />
              <ChangeLanguage />
            </Actions>
          </Grid>
        </Grid>
        {/* </HeaderWrapper> */}
      </AppBar>
    </>
  );
};

export default CommonHeader;
