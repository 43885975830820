import React from "react"
import { IconButton } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Content from "./Content";

const drawerWidth = 220;

const ArrowButton = styled(IconButton)(({ theme }) => ({
  border: "1px solid #cfe5f7",
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  zIndex: (theme) => theme.zIndex.drawer + 1, 
  position: "fixed", 
  backgroundColor: "#fff",
  '&:hover': {
    backgroundColor: "#fff"
  }
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  paddingTop: "100px",
  marginRight: "-27px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Sidebar = (props) => {
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      open={props.open}
    >
      <DrawerHeader>
          <ArrowButton onClick={() => props.setOpen(!props.open)}>
            {props.open ? (
              <ChevronLeftIcon style={{ color: "black"}} />
            ) : (
              <MenuIcon style={{ color: "black"}} />
            )}
          </ArrowButton>
        </DrawerHeader>
      <Content open={props.open} />
    </Drawer>
  );
};

export default Sidebar;
