import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { flex } from "../../../styles/vars";

const Wrapper = styled.div`
  ${flex.col};
  margin-top: 20px;
`;

const ShrinkSearchSelect = ({
  id,
  label,
  value,
  required,
  options,
  error,
  ...restProps
}) => {
  const title = `${label} ${required ? "*" : ""}`;
  return (
    <Wrapper>
      <Autocomplete
        id={id}
        disableClearable
        options={options}
        value={value}
        size="small"
        error={error}
        {...restProps}
        sx={{
          '.MuiOutlinedInput-root': {
            padding: 0,
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label={title} variant="outlined" error={error} />
        )}
      />
    </Wrapper>
  );
};

ShrinkSearchSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  // error: PropTypes.bool,
  error: PropTypes.string,
};

export default ShrinkSearchSelect;
