import axios from "axios";
import { env } from "../config/env";
import { getAuthToken } from "../contexts/authContext";

export const api = axios.create({
  baseURL: env.apiURL,
});

export const protectedApi = axios.create({
  baseURL: env.apiURL,
});

protectedApi.interceptors.request.use((request) => {
  const token = getAuthToken();
  if (!token) {
    throw new Error("Token not found in the storage");
  }
  request.headers["Authorization"] = `Bearer ${token}`;
  return request;
});

protectedApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("interceptor.error", error);
    if (error && axios.isAxiosError(error)) {
      // check whether the response return 403 and the access-token expiry message which means the access-token is expired
      console.error("interceptor.error", error);
    }
    return Promise.reject(error);
  }
);
