import { useState } from "react";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import InputField from "../../../components/UI/InputField";
import BtnLink from "../../../components/UI/BtnLink";
import { colors } from "../../../constants/colors";
import { Content, Wrapper } from "../../../components/style/style";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { useEffect } from "react";
import { Fab } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { getChoiceExists } from "../../../services/schools";
// General component receives 'formik' as a prop

const Choices = ({
  formik,
  handleFormChange,
  choices,
  index,
  stageIndex,
  subSectionIndex,
  fieldIndex,
  addFields,
  flows,
  checkDataChange,
  selectedRecord,
  existingFlag,
}) => {
  console.log(existingFlag);
  console.warn("flows >>> ", flows);
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [open, setOpen] = React.useState(false);
  const [choiceAddCount, setChoiceAddCount] = useState(0);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removeFields = (idx) => {
    const requestParams = {
      id: formik.values.goal_id,
      stage: formik.values[flows][index].stages[stageIndex].stage_guid,
      value: choices[idx],
    };

    getChoiceExists(requestParams).then((res) => {
      if (res.isOk && res.data) {
        if (res.data.exist) {
          handleClickOpen();
        } else {
          let oldFields = [...choices];
          // let oldDeleteChoiceData = [...formik.values.delete_goals];
          // if (id > 0) {
          //   oldDeleteChoiceData.push(id);
          //   formik.setFieldValue(`delete_goals`, oldDeleteChoiceData);
          // }
          oldFields.splice(idx, 1);
          formik.setFieldValue(
            `${flows}.${index}.stages.${stageIndex}.sub_stage_list.${subSectionIndex}.field_json.${fieldIndex}.choices`,
            oldFields
          );
        }
      }
    });
    let count = choiceAddCount - 1;
    if (count <= 0) {
      console.log(existingFlag);
      formik.setFieldValue(
        `applicant_flows.${index}.data_change`,
        false || existingFlag
      );
    }
  };

  return (
    <Grid item xs={10} sm={5.5}>
      <Grid item sm={12} xs={6}>
        {isEditable && formik.values[flows].length > 0 ? (
          <>
            {/* <Wrapper>
              <Content component={"div"}> */}
            <Grid container>
              {choices?.map((obj, idx) => {
                // if (idx >= 1) {
                return (
                  <Grid
                    container
                    sm={4}
                    spacing={0}
                    key={idx}
                    alignContent="flex-start"
                  >
                    <Grid item sm={10}>
                      <InputField
                        width="100%"
                        label={t("Choice") + " " + (idx + 1)}
                        value={choices[idx]}
                        required
                        onChange={(event) => {
                          const updatedChoices = [...choices];
                          updatedChoices[idx] = event.target.value;
                          formik.setFieldValue(
                            `${flows}.${index}.stages.${stageIndex}.sub_stage_list.${subSectionIndex}.field_json.${fieldIndex}.choices`,
                            updatedChoices
                          );
                          setIsDirty(true);

                          checkDataChange(
                            choices[idx] !== event.target.value ? true : false,
                            index
                          );
                        }}
                        helperText={t(
                          formik.touched[flows]?.[index]?.stages?.[stageIndex]
                            ?.sub_stage_list?.[subSectionIndex]?.field_json?.[
                            fieldIndex
                          ]?.choices?.[idx] &&
                            formik.errors[flows]?.[index]?.stages?.[stageIndex]
                              ?.sub_stage_list?.[subSectionIndex]?.field_json?.[
                              fieldIndex
                            ]?.choices?.[idx] &&
                            formik.errors[flows][index].stages?.[stageIndex]
                              ?.sub_stage_list?.[subSectionIndex]?.field_json?.[
                              fieldIndex
                            ]?.choices?.[idx]
                        )}
                        error={Boolean(
                          formik.touched[flows]?.[index]?.stages?.[stageIndex]
                            ?.sub_stage_list?.[subSectionIndex]?.field_json?.[
                            fieldIndex
                          ]?.choices?.[idx] &&
                            formik.errors[flows]?.[index]?.stages?.[stageIndex]
                              ?.sub_stage_list?.[subSectionIndex]?.field_json?.[
                              fieldIndex
                            ]?.choices?.[idx] &&
                            formik.errors[flows][index].stages?.[stageIndex]
                              ?.sub_stage_list?.[subSectionIndex]?.field_json?.[
                              fieldIndex
                            ]?.choices?.[idx]
                        )}
                      />
                    </Grid>
                    {choices.length > 1 && (
                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            // goal.goal_count
                            //   ? handleClickOpen()
                            setIsDirty(true);
                            removeFields(idx);
                          }}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                );
                // }
              })}
              <Grid align="center" item sm={2}>
                <Fab
                  size="small"
                  sx={{
                    color: "white",
                    backgroundColor: "#4eb043",
                    bottom: "-1vh",
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#236c0b",
                    },
                  }}
                  onClick={() => {
                    addFields(
                      choices,
                      index,
                      stageIndex,
                      subSectionIndex,
                      fieldIndex
                    );
                    setChoiceAddCount((prev) => prev + 1);
                  }}
                  aria-label="add"
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>

            {/* </Content>
            </Wrapper> */}
          </>
        ) : (
          formik.values[flows]?.map((flow, idx) => {
            flow.stages?.map((ele) => {
              ele.field_json?.map((item) => {
                item.choices.length > 0 && (
                  <Wrapper>
                    <List dense={true}>
                      {item.choices.map((obj, index) => {
                        return (
                          <div key={index}>
                            <ListItem>
                              <ListItemText primary={obj} />
                            </ListItem>
                          </div>
                        );
                      })}
                    </List>
                  </Wrapper>
                );
              });
            });
          })
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            {t(
              `You are trying to delete a choice which is mapped to an applicant or a student. Please remove the mapping for this choice and try again later.`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("Ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Choices;
