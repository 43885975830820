import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import InputField from "../InputField";
import { colors } from "../../../styles/vars";

// Define a functional component for the TimesheetTextfield
const CockpitTextfield = ({ id, value, label, onTextChange, ...restProps }) => {
  const [textValues, setTextValues] = useState("");
  const handleTextfieldChange = debounce((targetValue) => {
    if (typeof onTextChange === "function" && textValues !== value) {
      onTextChange(targetValue);
    }
  }, 50); // Adjust the debounce timing as needed

  useEffect(() => {
    setTextValues(value);
  }, [value]);

  return (
    <InputField
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: value.length <= 0 ? `2px solid ${colors.error}` : "",
        },
      }}
      label={label}
      variant="outlined"
      name={`value`}
      width="100%"
      value={textValues}
      onChange={(e) => {
        setTextValues(e.target.value);
        handleTextfieldChange(e.target.value);
      }}
      {...restProps}
    />
  );
};

// Define propTypes to specify the expected types for props
CockpitTextfield.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default CockpitTextfield;
