import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { BtnGrid } from "../UserForm/style";
import { handleKeys } from "../../utils/common";
import { emailRegex } from "../../constants/vars";
import { checkEmailApi } from "../../services/auth";
import useNotistack from "../../hooks/useNotistack";
import SubmitButtonWidget from "../SubmitButtonWidget";
import CancelButtonWidget from "../CancelButtonWidget";
import WidgetsLoader from "../../components/WidgetsLoader";
import { convertJSONToFormData } from "../../utils/utility";
import { masterReference } from "../../constants/masterData";
import { updateloggedInUserDetailApi } from "../../services/auth";
import ShrinkLabelInput from "../../components/UI/ShrinkLabelInput";

const UserProfileForm = () => {
  const { user, bootstrapAuth } = useAuth();
  const navigate = useNavigate();
  const { notify } = useNotistack();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_no: user.phone_no,
  };

  const validationSchema = yup.object({
    first_name: yup.string().required("REQUIRED"),
    last_name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(
                value,
                masterReference.USER,
                user.entity_id,
                user.company_id,
                user.id,
                user.entity_type
              )
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      let submittingData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_no: values.phone_no,
      };
      submittingData = convertJSONToFormData(submittingData);
      await updateloggedInUserDetailApi(submittingData)
        .then((res) => {
          if (res.isOk) {
            notify("User Profile Updated.", "success");
            resetForm();
            navigate("/");
            bootstrapAuth();
          } else {
            for (const [key, value] of Object.entries(res.error)) {
              if (typeof value === "object") {
                value.forEach((obj) => {
                  for (const key2 in obj) {
                    if (obj.hasOwnProperty(key2)) {
                      const value2 = obj[key2];
                      for (let i in value2) {
                        notify(`${key2}: ${value2[i]}`, "error");
                      }
                    }
                  }
                });
              } else {
                notify(`${key}: ${value}`, "error");
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
  };

  return isLoading ? (
    <WidgetsLoader />
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ShrinkLabelInput
            id="first_name"
            name="first_name"
            label="First Name"
            required
            onChange={handleChange}
            value={formik.values.first_name}
            helperText={
              formik.touched?.first_name ? formik.errors?.first_name : ""
            }
            error={formik.touched.first_name && formik.errors.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShrinkLabelInput
            id="last_name"
            name="last_name"
            label="Last Name"
            required
            onChange={handleChange}
            value={formik.values.last_name}
            helperText={
              formik.touched?.last_name ? formik.errors?.last_name : ""
            }
            error={formik.touched.last_name && formik.errors.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShrinkLabelInput
            id="email"
            name="email"
            label="Email"
            required
            onChange={handleChange}
            value={formik.values.email}
            helperText={formik.touched?.email ? formik.errors?.email : ""}
            error={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShrinkLabelInput
            id="phone_no"
            name="phone_no"
            label="Phone number"
            required
            onKeyPress={handleKeys}
            onChange={handleChange}
            value={formik.values.phone_no}
            helperText={formik.touched?.phone_no ? formik.errors?.phone_no : ""}
            error={formik.touched.phone_no && formik.errors.phone_no}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <BtnGrid item xs={6} sm={2}>
              <CancelButtonWidget />
            </BtnGrid>
            <BtnGrid item xs={6} sm={2}>
              <SubmitButtonWidget />
            </BtnGrid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserProfileForm;
