import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { activateAccount } from "../services/auth";
import { toTitleCase } from "../utils/utility";

const SuccessMessageContainer = styled.div`
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PrimaryHeading = styled.h1`
  justify-content: center;
  color: #089b00;
`;
const SecondaryText = styled.p`
  margin: 10px;
  padding: 0px;
  font-size: 18px;
`;

// const Logo = styled.div`
//   width:100px;
//   height: 100px;
//   background-color: #ffffff;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
// `;

export default function ActivateUser(props) {
  const { uidb64, token } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    console.log("here", uidb64, token, searchParams.get("type"));
    activateAccount(uidb64, token, searchParams.get("type"))
      .then((res) => {
        if (res && res.data) {
          console.log("activate", res.data);
          setName(
            `${toTitleCase(res.data.first_name)} ${toTitleCase(
              res.data.last_name
            )}`
          );
          setMessage(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);
  return (
    <>
      <SuccessMessageContainer>
        {/* <Logo>
          <img src={logo} alt="Logo" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Logo> */}
        <PrimaryHeading>
          {`Welcome`} {` ${name}!`}
        </PrimaryHeading>
        <SecondaryText>{message}</SecondaryText>
      </SuccessMessageContainer>
    </>
  );
}
