import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";

import useAuth from "../../../../hooks/useAuth";

import { Content, Wrapper } from "../../../../components/style/style";

import ApplicantVerticalLinearStepper from "./ApplicantStepper";
import Documents from "../Documents";
import Course from "./Course";

import { colors } from "../../../../constants/colors";

import Status from "./Status";
import { getApplicantFilterData } from "../../../../services/applicants";
import { toTitleCase } from "../../../../utils/utility";

// General component receives 'formik' as a prop
const Cockpit = ({
  formik,
  handleFormChange,
  checkDisabled,
  setContinueFlag,
  continueDisableFlag,
  setContinueDisableFlag,
  otherStages,
}) => {
  const { user } = useAuth();
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    getApplicantFilterData().then((res) => {
      if (res.isOk && res.data) {
        setStatusList(res.data);
      }
    });
  }, [formik.values.status]);

  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Typography
            // flexDirection={"row-reverse"}
            color={colors.primarySelected}
            variant="subtitle1"
            style={{ marginTop: "10px", marginLeft: "25px" }}
          >
            Status:&nbsp;
            {toTitleCase(
              statusList?.find(
                (option) => option.value === formik.values.status
              )?.label
            )}
          </Typography>
        </Grid>
        {formik.values.stageFlagList &&
        formik.values.flow_stage_list?.length > 0 ? (
          <Grid item sm={12} xs={12}>
            <Wrapper style={{ padding: "12px" }}>
              <ApplicantVerticalLinearStepper
                formik={formik}
                continueDisableFlag={continueDisableFlag}
                setContinueDisableFlag={setContinueDisableFlag}
                checkDisabled={checkDisabled}
                otherStages={otherStages}
                steps={[
                  {
                    label: "Documents Collection",
                    description: (
                      <Documents
                        formik={formik}
                        user={user}
                        isCockpit={true}
                        // stageNameList={stageNameList.filter((stage_name) =>
                        //   stage_name.toLowerCase().includes("documents")
                        // )}
                        setContinueFlag={setContinueFlag}
                        setContinueDisableFlag={setContinueDisableFlag}
                        continueDisableFlag={continueDisableFlag}
                        checkDisabled={checkDisabled}
                        stageName={
                          Object.keys(formik.values.stageFlagList)?.[0]?.split(
                            "_"
                          )[0]
                        }
                      />
                    ),
                  },

                  {
                    label: "Select Course",
                    description: <Course formik={formik} />,
                  },
                  {
                    label: "Student Approval",
                    description: <Status formik={formik} />,
                  },
                ]}
              />
            </Wrapper>
          </Grid>
        ) : (
          <p>Please add atleast one flow for Selected Goal .</p>
        )}
      </Grid>
    </>
  );
};

export default Cockpit;
