import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../layout/Page";
import styled from "styled-components";
import UserForm from "../widgets/UserForm";
import useNotistack from "../hooks/useNotistack";
import { getUserDetail, updateUsersDetail } from "../services/users";
import useWindowSize from "../hooks/useWindowSize";
import WidgetsLoader from "../components/WidgetsLoader";
import useAuth from "../hooks/useAuth";
import { masterReference } from "../constants/masterData";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";

const StepWrapper = styled.div`
  && {
    width: 55%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
  }
`;

function EditUserForm(props) {
  let pageTitle = "Edit User";
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState([]);
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const { id } = useParams();
  const { width } = useWindowSize();

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  const { user } = useAuth();
  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.code.trim().toLowerCase();
  const defaultUrl =
    userRole === masterReference.SUPER_ADMIN ? "" : `/${customUrl}`;

  useEffect(() => {
    if (id) {
      let requestParams = {
        id: id,
      };
      setIsLoading(true);
      getUserDetail(requestParams)
        .then((res) => {
          if (res.isOk && res.data) {
            console.log(res.data);
            setInitialValues(res.data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <Page title={pageTitle} pageTitleCenter={true}>
        <BreadcrumWithTitle
          path={
            user.code === masterReference.SUPER_ADMIN
              ? [
                { pathname: "/", title: "Home" },
                { pathname: user.custom_url ? `/${user.custom_url}/users` : "/users", title: "Users" },
                { pathname: "", title: "Edit User" },
              ]
              : [
                { pathname: "/", title: "Users" },
                { pathname: "", title: "Edit User" },
              ]
          }
          title={pageTitle}
          marginBottom={'10px'}
        />
        <StyledDiv>
          <StepWrapper>
            {!isLoading && initialValues !== null ? (
              <UserForm
                isMobile={isMobile}
                isEditing={true}
                isLoading={isLoading}
                userId={id}
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                  setIsLoading(true);
                  const keys = [
                    "role_id",
                    "entity_id",
                    "entity_type",
                    "first_name",
                    "last_name",
                    "email",
                    "phone_no",
                    "gender",
                    "dob",
                    "place_of_birth",
                    "citizenship",
                    "situation",
                    "religion",
                    // "health_insurance_provider",
                    // "iban",
                    "tax_id",
                    // "social_security_number",
                    "qualification",
                    "work_contract",
                    "company_id",
                  ];
                  const data = keys.reduce((acc, key) => {
                    if (values[key] === null) acc[key] = "";
                    else acc[key] = values[key];
                    return acc;
                  }, {});
                  data["first_name"] = data.first_name.trim();
                  data["last_name"] = data.last_name.trim();
                  data["email"] = data.email.trim();
                  data["dob"] = data.dob ? data.dob : null;
                  let requestParams = {
                    id: id,
                    data: data,
                  };
                  let res = await updateUsersDetail(requestParams);
                  if (res.isOk) {
                    resetForm();
                    navigate(`${defaultUrl}/users`);
                    notify("User Infomation Updated.", "success");
                  } else {
                    for (const [key, value] of Object.entries(res.error)) {
                      if (typeof value === "object") {
                        value.forEach((obj) => {
                          for (const key2 in obj) {
                            if (obj.hasOwnProperty(key2)) {
                              const value2 = obj[key2];
                              for (let i in value2) {
                                notify(`${key2}: ${value2[i]}`, "error");
                              }
                            }
                          }
                        });
                      } else {
                        notify(`${key}: ${value}`, "error");
                      }
                    }
                  }
                  setIsLoading(false);
                }}
              />
            ) : (
              <WidgetsLoader />
            )}
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default EditUserForm;
