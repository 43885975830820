import React from "react";

import { lazy } from "react";
import PersonIcon from "@mui/icons-material/Person";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BadgeIcon from "@mui/icons-material/Badge";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import TableRowsIcon from "@mui/icons-material/TableRows";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SaveIcon from "@mui/icons-material/Save";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";

import ClearIcon from "@mui/icons-material/Clear";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalculateIcon from "@mui/icons-material/Calculate";
import BusinessIcon from "@mui/icons-material/Business";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SchoolIcon from "@mui/icons-material/School";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadingIcon from "@mui/icons-material/Downloading";

//  data json
import Student from "../sections/data/Student";
import Applicant from "../sections/data/Applicant";
import Teacher from "../sections/data/Teacher";
import School from "../sections/data/School";
import Examiner from "../sections/data/Examiner";

// file json
import Documents from "../sections/file/Documents";

//  documentation json
// import Course from "../sections/Course";
import Course from "../sections/documentation/Course";

// planning json
import PlanningStudent from "../sections/planning/Student";
import Timetable from "../sections/planning/Timetable";

// settings json
import Lessons from "../sections/settings/Lessons";
import Flows from "../sections/settings/Flows";
import ColorSettings from "../sections/settings/Document Color Setting";
import Goals from "../sections/settings/Goals";
// management json
import Institute from "../sections/Management/Institute";

// this need to be removed
import InvoiceTab from "../widgets/InvoiceTab";
import CompaniesList from "../widgets/CompaniesList";
import {
  getTeachersList,
  addTeachersDetail,
  updateTeachersDetail,
  getTeachersDetail,
} from "../services/users";
import {
  getStudentsList,
  getStudentsDetail,
  addStudentDetail,
  editStudentDetail,
  getExaminersList,
  getExaminersDetail,
  addExaminerDetail,
  editExaminerDetail,
  getCoursesList,
  getLessonList,
  editLessonDetail,
  addInstituteDetail,
  getInstitutesDetail,
  getApplicantFlowDetail,
  getGoalsList,
  saveApplicantFlowDetail,
  getStudentAttendanceDetails,
  updateStudentAttendanceDetails,
  getDocumentColorStatusList,
  editDocumentColorStatusList,
  getGoalList,
  editGoalDetail,
} from "../services/schools";
import {
  addApplicantDetail,
  editApplicantDetail,
  getApplicantDetail,
  getApplicantsList,
} from "../services/applicants";
import { masterReference } from "./masterData";
import {
  addLessonTimeTable,
  getLessonTimeTableDetail,
} from "../services/planning";
import {
  addForeignInstituteApi,
  editForeignInstituteApi,
  getForeignInstitutesList,
  getIndividualForeignInstituteApi,
} from "../services/foreignInstitutes";
import ForeignInstitutes from "../sections/data/Foreign Institutes";
import { getFileDetail, saveFileData, saveFileDetail } from "../services/file";

export const roles = {
  SUPER_ADMIN: "super admin",
  CLINIC_ADMIN: "admin",
};

// to navbar modules
const Management = lazy(() => import("../modules/Management"));
const Settings = lazy(() => import("../modules/Settings"));
const Data = lazy(() => import("../modules/Data"));
const File = lazy(() => import("../modules/File"));
const Planning = lazy(() => import("../modules/Planning"));
const Documentation = lazy(() => import("../modules/Documentation"));

export const menu = [
  {
    label: "Data",
    route: "/data",
    page: <Data />,
    role: [
      masterReference.SCHOOL_TEACHER,
      masterReference.SCHOOL_MANAGER,
      masterReference.ACCOUNTANT,
      masterReference.HR,
      masterReference.AGENCY_USER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
  },
  // {
  //   label: "File",
  //   route: "/file",
  //   page: <File />,
  //   role: [
  //     masterReference.SCHOOL_MANAGER,
  //     masterReference.ACCOUNTANT,
  //     masterReference.HR,
  //     masterReference.FOREIGN_INSTITUTE_MANAGER,
  //   ],
  // },
  {
    label: "Documentation",
    route: "/documentation",
    page: <Documentation />,
    role: [
      masterReference.SCHOOL_TEACHER,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
      masterReference.HR,
    ],
  },
  {
    label: "Planning",
    route: "/planning",
    page: <Planning />,
    role: [
      masterReference.SCHOOL_TEACHER,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
      masterReference.HR,
    ],
  },
  {
    label: "Management",
    route: "/management",
    page: <Management />,
    role: [
      masterReference.SCHOOL_TEACHER,
      masterReference.SCHOOL_MANAGER,
      masterReference.HR,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
  },
  {
    label: "Settings",
    route: "/settings",
    page: <Settings />,
    role: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.HR,
      masterReference.ACCOUNTANT,
      masterReference.ADMIN,
    ],
  },

  // { label: "Management", route: "/management", page: <Management /> },
];

export const superAdminMenu = [
  {
    name: "Companies",
    link: "/companies",
    icon: <BusinessIcon />,
    role: [masterReference.SUPER_ADMIN],
  },
  {
    name: "Users",
    link: "/users",
    icon: <PersonIcon />,
    role: [masterReference.SUPER_ADMIN, masterReference.ADMIN],
  },
  {
    name: "Foreign Institutes",
    link: "/foreign-institutes",
    icon: <ApartmentIcon />,
    role: [masterReference.SUPER_ADMIN, masterReference.ADMIN],
  },
  {
    name: "Hiring Agencies",
    link: "/hiring-agencies",
    icon: <BusinessCenterIcon />,
    role: [masterReference.SUPER_ADMIN, masterReference.ADMIN],
  },
  {
    name: "Schools",
    link: "/schools",
    icon: <AccountBalanceIcon />,
    role: [masterReference.SUPER_ADMIN, masterReference.ADMIN],
  },
];

export const dataJson = [
  // {
  //   text: "Schools",
  //   icon: <AccountBalanceIcon />,
  //   component: <School />,
  //   route: "schools",
  //   api: {
  //     list: getSchoolsList,
  //     post: addSchoolDetail,
  //     get: getSchoolsDetail,
  //     put: editSchoolDetail,
  //   },
  //   readableRole: [masterReference.ADMIN],
  //   writeableRole: [masterReference.ADMIN],
  //   tool_json: [
  //     { text: "New", icon: <InsertDriveFileIcon /> },
  //     { text: "Edit", icon: <ModeEditIcon /> },
  //     { text: "Save", icon: <SaveIcon /> },
  //     { text: "Print", icon: <PrintIcon /> },
  //   ],
  // },
  // {
  //   text: "Foreign Institutes",
  //   icon: <ApartmentIcon />,
  //   component: <ForeignInstitutes />,
  //   route: "foreign-institutes",
  //   api: {
  //     list: getForeignInstitutesList,
  //     post: addForeignInstituteApi,
  //     get: getIndividualForeignInstituteApi,
  //     put: editForeignInstituteApi,
  //   },
  //   readableRole: [
  //     masterReference.ADMIN,
  //     // masterReference.FOREIGN_INSTITUTE_MANAGER,
  //   ],
  //   writeableRole: [
  //     masterReference.ADMIN,
  //     // masterReference.FOREIGN_INSTITUTE_MANAGER,
  //   ],
  //   tool_json: [
  //     { text: "New", icon: <InsertDriveFileIcon /> },
  //     { text: "Edit", icon: <ModeEditIcon /> },
  //     { text: "Save", icon: <SaveIcon /> },
  //     { text: "Print", icon: <PrintIcon /> },
  //   ],
  // },
  {
    text: "Applicants",
    icon: <GroupAddIcon />,
    component: <Applicant />,
    route: "applicants",
    api: {
      list: getApplicantsList,
      post: addApplicantDetail,
      get: getApplicantDetail,
      put: editApplicantDetail,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.HR,
      masterReference.AGENCY_USER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.HR,
      masterReference.AGENCY_USER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    tool_json: [
      { text: "New", icon: <InsertDriveFileIcon /> },
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
      { text: "Print", icon: <PrintIcon /> },
      { text: "Generate", icon: <DownloadingIcon /> },
    ],
  },
  {
    text: "Students",
    icon: <ContactsIcon />,
    component: <Student />,
    route: "students",
    api: {
      list: getStudentsList,
      post: addStudentDetail,
      get: getStudentsDetail,
      put: editStudentDetail,
    },
    readableRole: [
      masterReference.HR,
      masterReference.SCHOOL_TEACHER,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
    writeableRole: [
      masterReference.HR,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    tool_json: [
      { text: "New", icon: <InsertDriveFileIcon /> },
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
      { text: "Print", icon: <PrintIcon /> },
      { text: "Transfer", icon: <MoveDownIcon /> },
      { text: "Generate", icon: <DownloadingIcon /> },
    ],
  },

  {
    text: "Teachers",
    icon: <SchoolIcon />,
    component: <Teacher />,
    route: "teachers",
    api: {
      list: getTeachersList,
      get: getTeachersDetail,
      put: updateTeachersDetail,
      post: addTeachersDetail,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.ACCOUNTANT,
      masterReference.SCHOOL_TEACHER,
      masterReference.HR,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
    writeableRole: [
      masterReference.HR,
      masterReference.ACCOUNTANT,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    tool_json: [
      { text: "New", icon: <InsertDriveFileIcon /> },
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
      { text: "Print", icon: <PrintIcon /> },
      { text: "Generate", icon: <DownloadingIcon /> },
    ],
  },
  {
    text: "Examiners",
    icon: <AssignmentIndIcon />,
    component: <Examiner />,
    route: "examiners",
    api: {
      list: getExaminersList,
      post: addExaminerDetail,
      get: getExaminersDetail,
      put: editExaminerDetail,
    },
    readableRole: [
      masterReference.SCHOOL_TEACHER,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    tool_json: [
      { text: "New", icon: <InsertDriveFileIcon /> },
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
      { text: "Print", icon: <PrintIcon /> },
    ],
  },
];

export const planning_json = [
  {
    text: "Timetable",
    icon: <FactCheckIcon />,
    component: <Timetable />,
    route: "timetable",
    api: {
      list: getCoursesList,
      post: addLessonTimeTable,
      get: getLessonTimeTableDetail,
      put: addLessonTimeTable,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.SCHOOL_TEACHER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
      masterReference.HR,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.SCHOOL_TEACHER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
    tool_json: [
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
];

export const fileJson = [
  {
    text: "Documents",
    icon: <InsertDriveFileIcon />,
    component: <Documents />,
    route: "documents",
    api: {
      list: getFileDetail,
      post: saveFileDetail,
      get: getFileDetail,
      put: saveFileDetail,
    },
    readableRole: [
      masterReference.HR,
      masterReference.ACCOUNTANT,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    writeableRole: [
      masterReference.HR,
      masterReference.ACCOUNTANT,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    showSidebar: false,
    tool_json: [
      // { text: "New", icon: <InsertDriveFileIcon /> },
      // { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
];

export const settings_json = [
  {
    text: "Lessons",
    icon: <MenuBookIcon />,
    component: <Lessons />,
    route: "lessons",
    api: {
      list: getLessonList,
      put: editLessonDetail,
      post: editLessonDetail,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    showSidebar: false,
    tool_json: [
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
  {
    text: "Goals",
    icon: <MenuBookIcon />,
    component: <Goals />,
    route: "goals",
    api: {
      list: getGoalList,
      put: editGoalDetail,
      post: editGoalDetail,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.HR,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.HR,
    ],
    showSidebar: false,
    tool_json: [
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },

  {
    text: "Flows",
    icon: <SettingsIcon />,
    component: <Flows />,
    route:
      window.location.pathname.includes("flows/") &&
      parseInt(window.location.pathname.split("/").slice(-2, -1)) > 0
        ? `flows/${parseInt(
            window.location.pathname.split("/").slice(-2, -1)
          )}/`
        : window.location.pathname.includes("flows/") &&
          window.location.pathname.includes("entity_id")
        ? `flows/${window.location.pathname.split("flows/")[1]}`
        : `flows/0/`,
    // `flows/${
    //   window.location.pathname.includes("flows/") &&
    //   parseInt(window.location.pathname.split("/").slice(-2, -1)) > 0
    //     ? parseInt(window.location.pathname.split("/").slice(-2, -1))
    //     : window.location.pathname.includes("flows/") &&
    //       window.location.pathname.includes("entity_id")
    //     ? window.location.pathname.split("flows/")[1]
    //     : 0
    // }/`,
    api: {
      list: getGoalsList,
      get: getApplicantFlowDetail,
      put: saveApplicantFlowDetail,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.HR,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.HR,
    ],
    tool_json: [
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
  // {
  //     text: "Flows",
  //     icon: <SettingsIcon />,
  //     component: <Flows />,
  //     route: "flows",
  //     api: {
  //       list: getGoalsList,
  //       get: getApplicantFlowDetail,
  //       put: saveApplicantFlowDetail,
  //     },
  //     readableRole: [
  //       masterReference.SCHOOL_MANAGER,
  //       masterReference.FOREIGN_INSTITUTE_MANAGER,
  //       masterReference.HR,
  //     ],
  //     writeableRole: [
  //       masterReference.SCHOOL_MANAGER,
  //       masterReference.FOREIGN_INSTITUTE_MANAGER,
  //       masterReference.HR,
  //     ],
  //     tool_json: [
  //       { text: "Edit", icon: <ModeEditIcon /> },
  //       { text: "Save", icon: <SaveIcon /> },
  //     ],
  //   },

  {
    text: "File",
    icon: <InsertDriveFileIcon />,
    component: <Documents />,
    route: "file",
    api: {
      list: getFileDetail,
      post: saveFileDetail,
      get: getFileDetail,
      put: saveFileDetail,
    },
    readableRole: [
      masterReference.HR,
      masterReference.ACCOUNTANT,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    writeableRole: [
      masterReference.HR,
      masterReference.ACCOUNTANT,
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    showSidebar: false,
    tool_json: [
      // { text: "New", icon: <InsertDriveFileIcon /> },
      // { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
  {
    text: "Document Settings",
    icon: <MenuBookIcon />,
    component: <ColorSettings />,
    route: "document-setting",
    api: {
      list: getDocumentColorStatusList,
      put: editDocumentColorStatusList,
      post: editDocumentColorStatusList,
      get: getDocumentColorStatusList,
    },
    readableRole: [masterReference.ADMIN, masterReference.HR],
    writeableRole: [masterReference.ADMIN, masterReference.HR],
    showSidebar: false,
    tool_json: [
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
];

export const management_json = [
  {
    text: "Course",
    icon: <MenuBookIcon />,
    component: <Institute />,
    route: "course",
    api: {
      list: getCoursesList,
      post: addInstituteDetail,
      get: getInstitutesDetail,
      put: addInstituteDetail,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.SCHOOL_TEACHER,
      masterReference.HR,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
    ],
    tool_json: [
      { text: "New", icon: <InsertDriveFileIcon /> },
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
      { text: "Print", icon: <PrintIcon /> },
    ],
  },
];

export const documentation_json = [
  {
    text: `Lesson`,
    icon: <MenuBookIcon />,
    component: <Course />,
    route: "course",
    api: {
      list: getStudentAttendanceDetails,
      post: updateStudentAttendanceDetails,
      get: getStudentAttendanceDetails,
      put: updateStudentAttendanceDetails,
    },
    readableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.SCHOOL_TEACHER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
      masterReference.HR,
    ],
    writeableRole: [
      masterReference.SCHOOL_MANAGER,
      masterReference.SCHOOL_TEACHER,
      masterReference.FOREIGN_INSTITUTE_MANAGER,
      masterReference.FOREIGN_INSTITUTE_TEACHER,
    ],
    showSidebar: false,
    tool_json: [
      // { text: "New", icon: <InsertDriveFileIcon /> },
      { text: "Edit", icon: <ModeEditIcon /> },
      { text: "Save", icon: <SaveIcon /> },
    ],
  },
];

export const tool_json = [
  { text: "New", icon: <InsertDriveFileIcon /> },
  { text: "Edit", icon: <ModeEditIcon /> },
  { text: "Save", icon: <SaveIcon /> },
  // { text: "Copy", icon: <FileCopyIcon /> },
  // { text: "Email", icon: <EmailIcon /> },
  { text: "Print", icon: <PrintIcon /> },
  // { text: "Clear", icon: <ClearIcon /> },
  // { icon: <InfoIcon /> },
];

export const tabs = [
  { id: "clientData", label: "Client Data", component: <InvoiceTab /> },
  { id: "details", label: "Details", component: <InvoiceTab /> },
  { id: "invoice", label: "Invoice", component: <InvoiceTab /> },
  { id: "emergencyCall", label: "Emergency Call", component: <InvoiceTab /> },
  { id: "info1", label: "Info 1", component: <InvoiceTab /> },
  { id: "info2", label: "Info 2", component: <InvoiceTab /> },
  { id: "info3", label: "Info 3", component: <InvoiceTab /> },
  { id: "communication", label: "Communication", component: <InvoiceTab /> },
  { id: "media", label: "Media", component: <InvoiceTab /> },
];
