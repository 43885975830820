import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { handleKeys } from "../../utils/common";
import { getCountryList } from "../../services/users";
import CancelButtonWidget from "../CancelButtonWidget";
import SubmitButtonWidget from "../SubmitButtonWidget";
import WidgetsLoader from "../../components/WidgetsLoader";
import { InputError } from "../../components/UI/InputError";
import ShrinkSearchSelect from "../../components/UI/ShrinkSearchSelect";
import { CompanyGrid, GridItem, LabeledCompanyInput } from "./style";
import { getCompaniesListForUser } from "../../services/users";
import { checkEmailApi } from "../../services/auth";
import { emailRegex } from "../../constants/vars";
import { masterReference } from "../../constants/masterData";
import Goals from "./goals";
import useAuth from "../../hooks/useAuth";

const SchoolForm = ({
  initialValues = {
    name: "",
    company_id: null,
    email: "",
    phone_no: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    address_id: null,
    address_type: "",
    email_id: null,
    mobile_id: null,
    goals: [{ id: 0, goal_name: "" }],
    delete_goals: [],
  },
  onSubmit,
  isMobile,
  isLoading,
  schoolId,
}) => {
  const uniqueValues = [];
  const addCompanyValidationSchema = yup.object({
    name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(
                value,
                masterReference.SCHOOL,
                schoolId,
                formik.values.company_id
              )
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
    // phone_no: yup.string().required(),
    address_line_1: yup.string().required("REQUIRED"),
    city: yup.string().required("REQUIRED"),
    state: yup.string().required("REQUIRED"),
    postal_code: yup.string().required("REQUIRED"),
    country: yup.string().required("REQUIRED"),
    goals: yup.array().of(
      yup.object().shape({
        goal_name: yup
          .string()
          .required("REQUIRED")
          .test("is-unique", "Goal already present", function (value) {
            const lowercaseValue = value.toLowerCase();
            if (
              uniqueValues.some(
                (existingValue) =>
                  existingValue.toLowerCase() === lowercaseValue
              )
            ) {
              return false;
            } else {
              uniqueValues.push(value);
              return true;
            }
          }),
      })
    ),
  });

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: addCompanyValidationSchema,
    onSubmit,
  });

  const [companyOptions, setCompanyOptions] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const { user } = useAuth();

  // to get data from country list api
  useEffect(() => {
    getCountryList()
      .then((res) => {
        if (res.isOk) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // get company list data
    getCompaniesListForUser()
      .then((res) => {
        if (res.isOk) {
          setCompanyOptions(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (!initialValues.company_id) {
      formik.setFieldValue("company_id", companyOptions[0]?.value);
    }
  }, [companyOptions]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
  };

  return (
    <>
      {isLoading ? (
        <WidgetsLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <CompanyGrid container spacing={2}>
            <GridItem
              item
              sm={user.code === masterReference.SUPER_ADMIN ? 6 : 12}
              xs={12}
            >
              <LabeledCompanyInput
                id="name"
                name="name"
                title="Name"
                placeholder="Name"
                required
                value={formik.values.name}
                onChange={handleChange}
                helperText={formik.touched?.name ? formik.errors?.name : ""}
                error={formik.touched.name && formik.errors.name}
              />
              {/* {formik.touched.name && formik.errors.name && (
                <InputError>{formik.errors.name}</InputError>
              )} */}
            </GridItem>
            {user.code === masterReference.SUPER_ADMIN && (
              <GridItem item xs={12} sm={6}>
                <ShrinkSearchSelect
                  required
                  id="company_id"
                  name="company_id"
                  label="Company"
                  options={companyOptions}
                  onChange={(_, selected) => {
                    formik.setFieldValue("company_id", selected?.value || "");
                  }}
                  value={
                    companyOptions.find(
                      (option) => option.value === formik.values.company_id
                    ) ||
                    companyOptions[0] ||
                    ""
                  }
                  disabled={schoolId ? true : false}
                  helperText={
                    formik.touched?.company_id ? formik.errors?.company_id : ""
                  }
                  error={formik.touched.company_id && formik.errors.company_id}
                />
                {/* {formik.touched.company_id && formik.errors.company_id && (
                <InputError>
                  {formik.errors.company_id.replace("company_id", "Company")}
                </InputError>
              )} */}
              </GridItem>
            )}
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="phone_no"
                name="phone_no"
                title="Phone Number"
                // required
                // placeholder="+1(000)-000-00-00"
                onKeyPress={handleKeys}
                onChange={handleChange}
                value={formik.values.phone_no}
                helperText={
                  formik.touched?.phone_no ? formik.errors?.phone_no : ""
                }
                error={formik.touched.phone_no && formik.errors.phone_no}
              />
              {/* {formik.touched.phone_no && formik.errors.phone_no && (
                <InputError>
                  {formik.errors.phone_no.replace("phone_no", "phone")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="email"
                name="email"
                title="Email Address"
                placeholder="example@domain.com"
                required
                onChange={handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched?.email ? formik.errors?.email : ""}
                error={formik.touched.email && formik.errors.email}
              />
              {/* {formik.touched.email && formik.errors.email && (
                <InputError>{formik.errors.email}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="address_line_1"
                name="address_line_1"
                title="Address Line 1"
                placeholder="123"
                required
                onChange={handleChange}
                value={formik.values.address_line_1}
                helperText={
                  formik.touched?.address_line_1
                    ? formik.errors?.address_line_1
                    : ""
                }
                error={
                  formik.touched.address_line_1 && formik.errors.address_line_1
                }
              />
              {/* {formik.touched.address_line_1 && formik.errors.address_line_1 && (
                <InputError>
                  {formik.errors.address_line_1.replace("address_line_1", "Address Line 1")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="address_line_2"
                name="address_line_2"
                title="Address Line 2"
                onChange={handleChange}
                value={formik.values.address_line_2}
                helperText={
                  formik.touched?.address_line_2
                    ? formik.errors?.address_line_2
                    : ""
                }
                error={
                  formik.touched.address_line_2 && formik.errors.address_line_2
                }
              />
              {/* {formik.touched.address_line_2 && formik.errors.address_line_2 && (
                <InputError>{formik.errors.address_line_2.replace("address_line_2", "Address Line 2")}</InputError>
              )} */}
            </GridItem>
            {/* <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="address_line_2"
                name="address_line_2"
                title="Landmark"
                placeholder="Near central park"
                // required
                onChange={handleChange}
                value={formik.values.address_line_2}
              />
              {formik.touched.address_line_2 &&
                formik.errors.address_line_2 && (
                  <InputError>
                    {formik.errors.address_line_2.replace(
                      "address_line_2",
                      "landmark"
                    )}
                  </InputError>
                )}
            </GridItem> */}
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="postal_code"
                type="number"
                name="postal_code"
                title="Postal Code"
                placeholder="Postal Code"
                required
                onChange={handleChange}
                value={formik.values.postal_code}
                helperText={
                  formik.touched?.postal_code ? formik.errors?.postal_code : ""
                }
                error={formik.touched.postal_code && formik.errors.postal_code}
              />
              {/* {formik.touched.postal_code && formik.errors.postal_code && (
                <InputError>
                  {formik.errors.postal_code.replace(
                    "postal_code",
                    "postal code"
                  )}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="city"
                name="city"
                title="City"
                placeholder="City"
                required
                onChange={handleChange}
                value={formik.values.city}
                helperText={formik.touched?.city ? formik.errors?.city : ""}
                error={formik.touched.city && formik.errors.city}
              />
              {/* {formik.touched.city && formik.errors.city && (
                <InputError>{formik.errors.city}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <LabeledCompanyInput
                id="state"
                name="state"
                title="State"
                placeholder="state"
                required
                onChange={handleChange}
                value={formik.values.state}
                helperText={formik.touched?.state ? formik.errors?.state : ""}
                error={formik.touched.state && formik.errors.state}
              />
              {/* {formik.touched.state && formik.errors.state && (
                <InputError>{formik.errors.state}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <ShrinkSearchSelect
                id="country"
                name="country"
                options={countryList}
                label="Country"
                placeholder="country"
                required
                onChange={(_, selected) => {
                  formik.setFieldValue("country", selected?.value || "");
                }}
                value={
                  countryList.find(
                    (option) => option.value === formik.values.country
                  ) || ""
                }
                helperText={
                  formik.touched?.country ? formik.errors?.country : ""
                }
                error={formik.touched.country && formik.errors.country}
              />
              {/* {formik.touched.country && formik.errors.country && (
                <InputError>{formik.errors.country}</InputError>
              )} */}
            </GridItem>
            {user.code === masterReference.ADMIN ||
            user.code === masterReference.SUPER_ADMIN ? (
              <GridItem item xs={12} sm={12}>
                <Goals formik={formik} />
              </GridItem>
            ) : null}
            {!isMobile ? (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "3px",
                  }}
                >
                  <Grid item xs={6} sm={2}>
                    <CancelButtonWidget />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <SubmitButtonWidget />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12}>
                <SubmitButtonWidget />
              </Grid>
            )}
          </CompanyGrid>
        </form>
      )}
    </>
  );
};

export default SchoolForm;
