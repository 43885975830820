import { colors, flex, textSizes, breakpoints, fonts } from "../../styles/vars";
import styled from "styled-components";
import { Button, Card, Checkbox, FormControlLabel, Menu } from '@mui/material';
// import { ModalContent } from '@components/ModalWindow';

// export const StatusModalContent = styled(ModalContent)`
//   flex-wrap: wrap;
//   gap: 16px 30px;
//   position: relative;

//   .button-wrapper {
//     display: flex;
//     gap: 0px 20px;
//     justify-content: space-between;
//     margin-top: 16px;
//   }

//   .block {
//     &:first-of-type {
//       width: 100%;
//     }

//     ${flex.col};
//     gap: 8px;

//     .label {
//       font-size: ${textSizes['12']};
//       color: ${colors.gray};
//       font-family: ${fonts.accent};
//     }

//     .value {
//       display: inline-flex;
//       gap: 4px;
//       font-size: ${textSizes['14']};
//     }
//   }

//   ${breakpoints.tablet} {
//     max-width: 450px;
//     margin: 0 auto;
//   }
// `;
export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const FilterButton = styled(Button)`
  && {
    margin-right: 2px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    background-color: ${colors.white};
    color: ${colors.green};
    border-color: ${colors.green};
  }

  &&:hover {
    background-color: ${colors.green};
    border-color: ${colors.green};
    color: ${colors.white};
  }
`;

export const FilterBarWrapper = styled.div`
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

export const FilterWrapper = styled.div`
  margin-left: 0px;
`;

export const MUMenu = styled(Menu)`
  && {
    .MuiMenu-list {
      padding: 0;
    }
  }
`;

export const MenuHead = styled.div`
    width: 250px !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px !important;
    font-size:14px;
    padding: 10px;
    font-weight: 600;
    border-bottom: 1px solid darkgrey;

    &:hover {
      color: ${colors.white} !important;
      background-color: #4DABAB !important;
    }
`;

export const MUICard = styled(Card)`
  width: 250px !important;
`;

export const MUICheckbox = styled(Checkbox)`
  &&:hover {
    background-color: transparent !important;
    color: ${colors.gray} !important;
  }
`;

export const ChipWrapper = styled.div`
  display: flex; 
  align-items: flex-start;
  width: 100%;
  padding: 8px 20px;
  gap: 8px;
  flex-wrap: wrap;
`;