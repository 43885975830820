import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/images/login-background.jpg";
import { breakpoints, colors, flex } from "../styles/vars";
import ResetPasswordForm from "../widgets/ResetPassword/Forms";

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-height: 100vh;
  background-size: cover;
  background-repeat: none;
  background-image: url(${backgroundImage});
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 50%;
  }
`;

const StyledHeader = styled.h1`
  color: ${colors.dark};
`;

export default function AddPassword() {
  return (
    <RootWrapper>
      <Container>
        <StyledHeader>Change Password</StyledHeader>
        <ResetPasswordForm add_new_password="true" />
      </Container>
    </RootWrapper>
  );
}
