import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { colors } from "../../styles/vars";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, ...props }) => ({
  width: 42,
  height: props.height || 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${props.translatex || "16px"})`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: colors.blue,
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: colors.white,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: props.circleheight || 22,
    height: props.circleheight || 22,
    backgroundColor: colors.white,
  },
  "& .MuiSwitch-track": {
    borderRadius: (props.height || 26) / 2,
    backgroundColor: "#ccc",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CustomizedSwitches = (props) => {
  return (
    <FormGroup>
      <IOSSwitch sx={{ m: 1 }} {...props} />
      <label htmlFor={props.name}>{props.label}</label>
    </FormGroup>
  );
};

export default CustomizedSwitches;
