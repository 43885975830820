import { Grid } from "@mui/material";
import styled from "styled-components";
import ShrinkLabelInput from "../../components/UI/ShrinkLabelInput";

export const BtnGrid = styled(Grid)`
  && {
    ${"" /* margin-top: 20px; */}
  }
`;

export const UserGrid = styled(Grid)`
  && {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const LabeledUserInput = styled(ShrinkLabelInput)`
  width: 100%;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    `}
`;

export const GridItem = styled(Grid)`
  && {
    padding-top: 0px !important;
  }
`;
