import React from "react";
import { Grid } from "@mui/material";

import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import {
  Content,
  Heading,
  Row,
  Wrapper,
} from "../../../../components/style/style";

const options = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Kids", value: "kids" },
];

const religion_options = [
  { label: "Hindu", value: "hindu" },
  { label: "Christian", value: "christian" },
  { label: "Muslim", value: "muslim" },
];

const Finance = ({ formik, handleFormChange }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  return (
    <>
      {isEditable ? (
        <Grid container>
          <Grid item sm={12}>
            <Wrapper style={{ padding: "12px" }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <SelectDropdown
                    id={"situation"}
                    name={"situation"}
                    options={options}
                    label="Family Status"
                    width="97%"
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      formik.setFieldValue("situation", selected?.value || "");
                    }}
                    value={
                      options.find(
                        (option) => option.value === formik.values.situation
                      ) || ""
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <SelectDropdown
                    id={"religion"}
                    name={"religion"}
                    options={religion_options}
                    label="Religion"
                    width="97%"
                    onChange={(_, selected) => {
                      setIsDirty(true);
                      formik.setFieldValue("religion", selected?.value || "");
                    }}
                    value={
                      religion_options.find(
                        (option) => option.value === formik.values.religion
                      ) || ""
                    }
                  />
                </Grid>
              </Grid>
            </Wrapper>
          </Grid>
          <Grid item sm={6}>
            <Wrapper>
              <Heading>Work Contract</Heading>
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      id="work_contract"
                      name="work_contract"
                      label="Hours Per Month"
                      width="98%"
                      value={formik.values.work_contract}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
              </Content>
            </Wrapper>
          </Grid>
          <Grid item sm={6}>
            <Wrapper>
              <Heading>Social Security Data</Heading>
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      id="social_security_number"
                      label="Social Security Number"
                      name="social_security_number"
                      width="97%"
                      value={formik.values.social_security_number}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
              </Content>
            </Wrapper>
          </Grid>
          <Grid item sm={6}>
            <Wrapper>
              <Heading>Bank Information</Heading>
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      id="account_owner"
                      label="Account Owner"
                      name="account_owner"
                      width="97%"
                      value={formik.values.account_owner}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      id="iban_number"
                      label="IBAN Number"
                      name="iban_number"
                      width="97%"
                      value={formik.values.iban_number}
                      onChange={handleFormChange}
                    />
                  </Grid>
                </Grid>
              </Content>
            </Wrapper>
          </Grid>
          <Grid item sm={6}>
            <Wrapper>
              <Heading>Health Insurance</Heading>
              <Content component={"div"} style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputField
                      id="health_insurance_provider"
                      label="Health Insurance Provider"
                      name="health_insurance_provider"
                      width="97%"
                      value={formik.values.health_insurance_provider}
                      onChange={handleFormChange}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Content>
            </Wrapper>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          {formik.values.situation && formik.values.religion && (
            <Grid item sm={12}>
              <Wrapper style={{ padding: "12px" }}>
                <Grid container spacing={2}>
                  {formik.values.situation && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Family Status"}
                        info={formik.values.situation}
                      />
                    </Grid>
                  )}
                  {formik.values.religion && (
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Religion"}
                        info={formik.values.religion}
                      />
                    </Grid>
                  )}
                </Grid>
              </Wrapper>
            </Grid>
          )}
          {formik.values.work_contract && (
            <Grid
              item
              sm={!isEditable && !formik.values.social_security_number ? 12 : 6}
            >
              <Wrapper>
                <Heading>Work Contract</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Hours Per Month"}
                        info={formik.values.work_contract}
                      />
                    </Grid>
                  </Grid>
                </Content>
              </Wrapper>
            </Grid>
          )}
          {formik.values.social_security_number && (
            <Grid
              item
              sm={!isEditable && !formik.values.work_contract ? 12 : 6}
            >
              <Wrapper>
                <Heading>Social Security Data</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <InfoLabel
                        label={"Social Security Number"}
                        info={formik.values.social_security_number}
                      />
                    </Grid>
                  </Grid>
                </Content>
              </Wrapper>
            </Grid>
          )}

          {formik.values.account_owner && formik.values.iban_number && (
            <Grid
              item
              sm={
                !isEditable && !formik.values.health_insurance_provider ? 12 : 6
              }
            >
              <Wrapper>
                <Heading>Bank Information</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    {formik.values.account_owner && (
                      <Grid item sm={6}>
                        <InfoLabel
                          label={"Account Owner"}
                          info={formik.values.account_owner}
                        />
                      </Grid>
                    )}
                    {formik.values.iban_number && (
                      <Grid item sm={6}>
                        <InfoLabel
                          label={"IBAN Number"}
                          info={formik.values.iban_number}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Content>
              </Wrapper>
            </Grid>
          )}
          {formik.values.health_insurance_provider && (
            <Grid
              item
              sm={
                !isEditable &&
                (!formik.values.account_owner || !formik.values.iban_number)
                  ? 12
                  : 6
              }
            >
              <Wrapper>
                <Heading>Health Insurance</Heading>
                <Content component={"div"} style={{ padding: "12px" }}>
                  <Grid container spacing={2}>
                    {formik.values.health_insurance_provider && (
                      <Grid item sm={6}>
                        <InfoLabel
                          label={"Health Insurance Provider"}
                          info={formik.values.health_insurance_provider}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Content>
              </Wrapper>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Finance;
