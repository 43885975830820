import React from "react";
import { Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Accordion from "react-bootstrap/Accordion";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { NavLink, useLocation } from "react-router-dom";

import { List } from "../style";
import useAuth from '../../../hooks/useAuth';
import { colors } from "../../../styles/vars";
import { superAdminMenu } from "../../../constants/menu";
import { masterReference } from "../../../constants/masterData";


const Content = (props) => {
  // const { toggleSidebar } = useSidebarContext();
  const { user } = useAuth();
  const location = useLocation();
  const activeStyle = { background: "#deeefb", color: colors.primary };
  var companyUrl = user.company_url;
  var customUrl = user.custom_url;
  customUrl = customUrl ? customUrl.trim() : "";
  const userRole = user.code.trim().toLowerCase();
  const defaultUrl =
    userRole === masterReference.SUPER_ADMIN
      ? ""
      : companyUrl === customUrl
      ? `/${customUrl}`
      : `/${companyUrl}/${customUrl}`;

  return (
    <List as={Accordion} style={{ paddingTop: '30px'}}>
      {superAdminMenu
        .filter(({ role }) => role.includes(user.code.trim().toLowerCase()))
        .map((item, index) => {
          if (item.link) {
            return (
              <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
                <Tooltip title={item.name} placement="right" arrow>
                  <NavLink to={`${defaultUrl}${item.link}`}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: props.open ? "initial" : "center",
                        px: 2.5,
                      }}
                      style={
                        location.pathname.includes(item.link)
                          ? activeStyle
                          : { color: "black" }
                      }
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: props.open ? 3 : "auto",
                          justifyContent: "center",
                          color: location.pathname.includes(item.link)
                            ? colors.primary
                            : "black",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontSize: "16px",
                        }}
                        sx={{ opacity: props.open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </NavLink>
                </Tooltip>
              </ListItem>
            );
          } else return null;
        })}
    </List>
  );
};

export default Content;
