import { protectedApi } from "../api";
import { formatApiResponse } from "../utils/helpers";

export const getHiringAgenciesList = async (
  companyId,
  page,
  limit,
  sorting
) => {
  console.log("getHiringAgenciesList.params");
  try {
    // Construct the query string for the API request.
    let param = `?company_id=${companyId}&limit=${limit}&page=${page}&sort=${sorting.sort}&order=${sorting.order}`;
    const response = await protectedApi.get("/hiring-agencies" + param);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getHiringAgenciesList.error", e);
    return formatApiResponse(e);
  }
};

export const addHiringAgencyApi = async (hiringAgencyData) => {
  const endpoint = `/hiring-agencies/`;
  console.log(hiringAgencyData);
  try {
    const response = await protectedApi.post(endpoint, hiringAgencyData);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export async function getIndividualHiringAgencyApi(hiringAgencyId) {
  console.log("getIndividualHiringAgencyApi.params", hiringAgencyId);
  try {
    const res = await protectedApi.get(
      `/hiring-agencies/${hiringAgencyId}`
    );
    return formatApiResponse(res);
  } catch (e) {
    console.error("getIndividualHiringAgencyApi.catch", e);
    return formatApiResponse(e);
  }
}

export const editHiringAgencyApi = async (id, hiringAgencyData) => {
  const endpoint = `/hiring-agencies/${id}`;
  try {
    const response = await protectedApi.put(endpoint, hiringAgencyData);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};
