import React from "react";
import styled from "styled-components";

import Page from "../layout/Page";
import useAuth from "../hooks/useAuth";
import AddSchoolUser from "../widgets/AddSchoolUser";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";

const StepWrapper = styled.div`
  && {
    width: 85%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

function AddSchool(props) {
  let pageTitle = "Add School";
  const { user } = useAuth();

  return (
    <>
      <Page title={pageTitle}>
        <BreadcrumWithTitle
          path={[
            { pathname: "/", title: "Home" },
            { pathname: user.custom_url ? `/${user.custom_url}/schools` : "/schools", title: "Schools" },
            { pathname: "", title: "Add School" },
          ]}
          title={pageTitle}
        />
        <StyledDiv>
          <StepWrapper>
            <AddSchoolUser />
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default AddSchool;
