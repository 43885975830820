import { Button, Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Content, Wrapper } from "../../../components/style/style";
import useAuth from "../../../hooks/useAuth";
import { colors } from "../../../constants/colors";
import { useTranslation } from "react-i18next";

const NameTypography = styled(Typography)((props) => ({
  color: props.value ? colors.green : colors.red,
  cursor: props.value ? "pointer" : "initial",
  textDecoration: props.value ? "underline" : "initial",
}));

// Contact Information component receives 'formik' as a prop
const Documents = ({ stageName }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      {user?.step?.goal_flow_count <= 0 || user?.step?.course_count <= 0 ? (
        <Wrapper>
          <Content component={"div"}>
            <Grid
              container
              alignItems="center"
              rowSpacing={1}
              columnSpacing={5}
            >
              <React.Fragment>
                <Grid item sm={12}>
                  {stageName === "flow" ? (
                    <NameTypography>
                      <Button
                        onClick={() => {
                          navigate(
                            `/${user.company_url}/${user.custom_url}/settings/flows/0/`
                          );
                        }}
                      >
                        {t("Add a new flow")}
                      </Button>
                    </NameTypography>
                  ) : (
                    <NameTypography>
                      <Button
                        onClick={() => {
                          navigate(
                            `/${user.company_url}/${user.custom_url}/management/course`
                          );
                        }}
                      >
                        {t("Add Course")}
                      </Button>
                    </NameTypography>
                  )}
                </Grid>
              </React.Fragment>
            </Grid>
          </Content>
        </Wrapper>
      ) : (
        <p>{t("This Stage is completed")}</p>
      )}
    </>
  );
};

export default Documents;
