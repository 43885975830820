import styled from "styled-components";
import Tabs from '@mui/material/Tabs';

export const RoundTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    // background: white;
    margin-top: 12px;
    border-radius: 8px 8px 0 0;
    min-height: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-transform: none;
    // :hover {
    //   background: pink;
    // }
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px, rgba(0, 0, 0, 0.35) 0px 5px 15px;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 55px 55px,rgba(0, 0, 0, 0.12) 0px 65px 4px,rgba(0, 0, 0, 0.12) 0px 4px 15px,rgba(0, 0, 0, 0.17) 0px 12px 13px,rgba(0, 0, 0, 0.09) 0px 5px 6px;
    z-index: 10;
  }

  .MuiButtonBase-root.MuiTab-root {
    // border-bottom: 2px solid #ccc;
    // padding-right: 0px;
    z-index: 10;

    // :hover {
    //   background-color: pink;
    // }
  }

  .MuiTabs-flexContainer {
    gap: 10px;
  }

  .MuiTabs-indicator {
    display: none;
  }
`;
