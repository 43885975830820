export const masterContact = {
  WHATSAPP: "whatsapp",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  PHONE: "phone",
  TWITTER: "twitter",
  WEBSITE: "website",
  YOUTUBE: "youtube",
  LINKEDIN: "linkedin",
  EMAIL: "email",
};

export const masterReference = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
  COMPANY: "company",
  HIRING_AGENCY: "hiring_agency",
  FOREIGN_INSTITUTE: "foreign_institute",
  SCHOOL: "school",
  STUDENT: "student",
  APPLICANT: "applicant",
  TEACHER: "teacher",
  SCHOOL_TEACHER: "school_teacher",
  SCHOOL_MANAGER: "school_manager",
  FOREIGN_INSTITUTE_MANAGER: "foreign_institute_manager",
  FOREIGN_INSTITUTE_TEACHER: "foreign_institute_teacher",
  EXAMINER: "examiner",
  ACCOUNTANT: "accountant",
  HR: "hr",
  AGENCY_USER: "agency_user",
};

export const entitiesList = [
  {
    label: "School",
    value: "school",
  },
  {
    label: "Foreign Institute",
    value: "foreign_institute",
  },
];

export const applicantStatus = {
  APPROVED: "approved",
  REJECTED: "rejected",
  ARCHIVED: "archived",
};
