import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Grid, TextField } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import InfoLabel from "../../../../components/UI/InfoLabel";
import DateField from "../../../../components/UI/DateField";
import InputField from "../../../../components/UI/InputField";
import { masterReference } from "../../../../constants/masterData";
import { getCompaniesListForUser } from "../../../../services/users";
import { Content, Wrapper } from "../../../../components/style/style";
import SelectDropdown from "../../../../components/UI/SelectDropdown";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import { toTitleCase } from "../../../../utils/utility";
import { dateFormFormat } from "../../../../utils/dates";

// Define options for status and gender dropdowns
const statusOptions = [
  { label: "Sprachkurs", value: "Sprachkurs" },
  { label: "Ausbildung/Anerkennung", value: "Ausbildung" },
  { label: "Verpflichtet", value: "Verpflichtet" },
  { label: "Archiv", value: "Archiv" },
];

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

// General component receives 'formik' as a prop
const General = ({ formik, handleFormChange, schoolList }) => {
  // Get the isEditable flag from a custom hook
  const { isEditable, setIsDirty } = useCustomFormSubmit();

  const { user } = useAuth();
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    if (user.code.toLowerCase() === masterReference.SUPER_ADMIN) {
      getCompaniesListForUser().then((company) => {
        company.data.forEach((ele) => {
          ele.label = toTitleCase(ele.label);
        });
        setCompanyList(company.data);
        formik.setFieldValue("company_id", company.data[0]?.value);
      });
    }
  }, [user]);

  return (
    <Grid container>
      <Grid item sm={12} xs={12}>
        <Wrapper style={{ padding: "12px" }}>
          {isEditable ? (
            <Content component={"div"}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <InputField
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    value={formik.values.first_name}
                    onChange={handleFormChange}
                    helperText={
                      formik.touched.first_name ? formik.errors.first_name : ""
                    }
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                    required
                    width="97%"
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputField
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    value={formik.values.last_name}
                    onChange={handleFormChange}
                    required
                    helperText={
                      formik.touched.last_name ? formik.errors.last_name : ""
                    }
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                    width="97%"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DateField
                    label="Date of Birth"
                    value={formik.values.dob}
                    name="dob"
                    maxDate={dayjs()}
                    onChange={(date) => {
                      setIsDirty(true);
                      formik.setFieldValue("dob", date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    width="97%"
                    helperText={formik.touched.dob ? formik.errors.dob : ""}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputField
                    id="qualification"
                    name="qualification"
                    label="Qualification"
                    value={formik.values.qualification}
                    onChange={handleFormChange}
                    required
                    helperText={
                      formik.touched.qualification
                        ? formik.errors.qualification
                        : ""
                    }
                    error={
                      formik.touched.qualification &&
                      Boolean(formik.errors.qualification)
                    }
                    width="97%"
                  />
                </Grid>
                {user.code === masterReference.SUPER_ADMIN && (
                  <Grid item xs={12} sm={4}>
                    <SelectDropdown
                      id={"company_id"}
                      name={"company_id"}
                      options={companyList}
                      label="company"
                      onChange={(_, selected) => {
                        setIsDirty(true);
                        formik.setFieldValue("school_id", "");
                        formik.setFieldValue(
                          "company_id",
                          selected?.value || ""
                        );
                      }}
                      value={
                        companyList.find(
                          (option) => option.value === formik.values.company_id
                        ) || ""
                      }
                      helperText={
                        formik.touched?.company_id
                          ? formik.errors?.company_id
                          : ""
                      }
                      error={
                        formik.touched?.company_id &&
                        Boolean(formik.errors?.company_id)
                      }
                      required
                      width="97%"
                    />
                  </Grid>
                )}
                {user.code === masterReference.SUPER_ADMIN ||
                  (user.code === masterReference.ADMIN && (
                    <Grid item xs={12} sm={4}>
                      <SelectDropdown
                        id={"school_id"}
                        name={"school_id"}
                        options={schoolList}
                        width="97%"
                        label="School"
                        onChange={(_, selected) => {
                          setIsDirty(true);
                          formik.setFieldValue(
                            "school_id",
                            selected?.value || ""
                          );
                        }}
                        value={
                          schoolList.find(
                            (option) => option.value === formik.values.school_id
                          ) || ""
                        }
                        helperText={
                          formik.touched.school_id
                            ? formik.errors.school_id
                            : ""
                        }
                        error={
                          formik.touched.school_id &&
                          Boolean(formik.errors.school_id)
                        }
                        required
                      />
                    </Grid>
                  ))}
              </Grid>
            </Content>
          ) : (
            <Content component={"div"}>
              <Grid container spacing={2}>
                {formik.values.first_name && (
                  <Grid item xs={3}>
                    <InfoLabel
                      label={"First Name"}
                      info={formik.values.first_name}
                    />
                  </Grid>
                )}

                {formik.values.last_name && (
                  <Grid item xs={3}>
                    <InfoLabel
                      label={"Last Name"}
                      info={formik.values.last_name}
                    />
                  </Grid>
                )}

                {formik.values.dob && (
                  <Grid item xs={3}>
                    <InfoLabel
                      label={"Date of Birth"}
                      info={dateFormFormat(formik.values.dob)}
                    />
                  </Grid>
                )}

                {formik.values.qualification && (
                  <Grid item xs={3}>
                    <InfoLabel
                      label={"Qualification"}
                      info={formik.values.qualification}
                    />
                  </Grid>
                )}

                {(user.code === masterReference.SUPER_ADMIN ||
                  user.code === masterReference.ADMIN) &&
                  formik.values.school_id && (
                    <Grid item xs={3}>
                      <InfoLabel
                        label={"School"}
                        info={
                          schoolList.find(
                            (option) => option.value === formik.values.school_id
                          )?.label || statusOptions[0]?.label
                        }
                      />
                    </Grid>
                  )}
              </Grid>
            </Content>
          )}
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default General;
