import React, { useRef, useEffect, useState } from "react";
import { styled } from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Box, Tab, Tabs } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useAuth from "../../hooks/useAuth";
import { menu } from "../../constants/menu.js";
import { textSizes } from "../../styles/vars.js";
import { colors } from "../../constants/colors.js";
import useWindowSize from "../../hooks/useWindowSize";
import { Actions } from "../../layout/Panel/style.js";
import CurrentUser from "../../layout/Panel/CurrentUser";
import { useCustomFormSubmit } from "../../hooks/useCustomFormSubmit";

const TabNav = styled(Tabs)`
  && {
    background-color: ${colors.nav};
    color: ${colors.white};
  }
`;

const TabItem = styled(Tab)`
  && {
    color: ${colors.secondary};

    &&.Mui-selected {
      color: ${colors.secondary};
    }
  }
`;

const NavBar = (props) => {
  // declare custom hook
  const { user } = useAuth();
  const headerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { isDirty, setIsDirty, setPrintRecord } = useCustomFormSubmit();

  const [open, setOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [posterIndx, setPosterIndx] = useState(false);
  const handleClose = () => setOpen(false);
  const handleLeave = () => {
    setOpen(false);
    setIsDirty(false);
    handleTabChange(posterIndx);
    navigate(redirectUrl);
  };

  const tab = menu.findIndex((item) => item.route === location.pathname);
  const [activeTab, setActiveTab] = useState(tab !== -1 ? tab : 0);
  const isMobile = width < 768;
  const isDesktop = width >= 1366;
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerRef.current.offsetHeight}px`
    );
  }, [width]);

  useEffect(() => {
    if (!isDirty) {
      const filteredMenu = menu.filter(({ role }) =>
        role.includes(user?.code?.trim()?.toLowerCase())
      );
      filteredMenu.map((item, index) => {
        if (location.pathname.includes(item.route)) setActiveTab(index);
      });
    }
  }, [location]);

  const handleNavClick = (redirectUrl, indx) => {
    console.log("nave", redirectUrl);
    if (isDirty) {
      setRedirectUrl(redirectUrl);
      setPosterIndx(indx);
      setOpen(true);
    } else {
      setPrintRecord(null);
      navigate(redirectUrl);
      handleTabChange(indx);
    }
  };

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          background: "white"
        }}
      >
        <TabNav
          value={activeTab}
          // onChange={handleTabChange}
          ref={headerRef}
          sx={{
            minHeight: "unset",
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.secondary,
            },
          }}
        >
          {window.location.pathname.split("/")[
            window.location.pathname.split("/").length - 2
          ] !== "steps" &&
            menu
              .filter(({ role }) =>
                role.includes(user?.code?.trim()?.toLowerCase())
              )
              .map((item, index) => (
                <TabItem
                  label={item.label}
                  component={Link}
                  to={
                    isDirty
                      ? null
                      : user.company_url === user.custom_url
                      ? `/${user.custom_url}${item.route}`
                      : `/${user.company_url}/${user.custom_url}${item.route}`
                  }
                  onClick={() =>
                    handleNavClick(
                      user.company_url === user.custom_url
                        ? `/${user.custom_url}${item.route}`
                        : `/${user.company_url}/${user.custom_url}${item.route}`,
                      index
                    )
                  }
                  key={index}
                  disabled={
                    user?.step?.goal_flow_count <= 0 ||
                    user?.step?.course_count <= 0
                      ? true
                      : false
                  }
                />
              ))}
        </TabNav>
        {/* <Actions style={{ background: colors.nav }}>
          <CurrentUser width={width} {...user} />
        </Actions> */}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: colors.primary,
            fontWeight: "bold",
            fontSize: textSizes["22"],
          }}
        >
          <span style={{ display: "flex", alignItems: "center", gap: "7px" }}>
            <WarningAmberIcon color="error" />
            Unsaved Changes
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: colors.primary,
              padding: "20px 0px",
              fontSize: textSizes["18"],
            }}
          >
            Your changes are not saved. Do you really want to leave ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: "20px", paddingBottom: "20px" }}>
          <Button
            onClick={handleLeave}
            variant="outlined"
            color="error"
            style={{ padding: "8px 20px" }}
          >
            Leave
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ padding: "8px 20px" }}
          >
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NavBar;
