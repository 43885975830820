import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/images/login-background.jpg";
import FormContainer from "../widgets/CompanyLoginForm";
import { colors } from "../constants/colors";

const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

  min-height: 100vh;

  background-repeat: none;
  background-color: ${colors.background};
`;
export default function Login() {
  return (
    <RootWrapper>
      <FormContainer />
    </RootWrapper>
  );
}
