import { protectedApi } from "../api";
import { formatApiResponse } from "../utils/helpers";

// Get a list of users with optional filters.
export const getUsersList = async (requestParams) => {
  const { page, limit, company_id, entity_type, role, sorting } = requestParams;
  console.log("getUsersList.params", requestParams);

  try {
    // Construct the query string for the API request.
    let param = `?limit=${limit}&page=${page}&company_id=${company_id}&entity_type=${entity_type}&role=${role}&sort=${sorting.sort}&order=${sorting.order}`;

    // Send a GET request to the 'users' endpoint with the constructed query string.
    const response = await protectedApi.get("/users/" + param);
    console.log("getUsersList.response", response);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("getUsersList.error", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get details of a specific user by its ID.
export const getUserDetail = async (requestParams) => {
  const { id } = requestParams;

  console.log("getUserDetail.params", requestParams);
  try {
    const response = await protectedApi.get(`/users/${id}`);
    console.log("getUsersDetail.response", response);

    return formatApiResponse(response);
  } catch (e) {
    console.error("getUsersDetail.error", e);
    return formatApiResponse(e);
  }
};

// Update an existing teacher's details.
export const updateUsersDetail = async (requestParams) => {
  const { id, data } = requestParams;
  console.log("updateUsersDetail.params", requestParams);

  try {
    // Send a PUT request to the 'users' endpoint with the provided data.
    const response = await protectedApi.put(`/users/${id}`, data);
    console.log("updateUsersDetail.response", response);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("updateUsersDetail.error", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get a list of teachers with optional filters.
export const getTeachersList = async (requestParams) => {
  const { page, limit, q, company_id, filterQuery } = requestParams;

  try {
    // Construct the query string for the API request.
    let param;
    if (!company_id || company_id === 0)
      param = `?limit=${limit}&page=${page}&q=${q}${filterQuery}`;
    else
      param = `?limit=${limit}&page=${page}&company_id=${company_id}&q=${q}${filterQuery}`;

    console.log("getTeachersList.params", param);
    // Send a GET request to the 'teachers' endpoint with the constructed query string.
    const response = await protectedApi.get("/teachers/" + param);
    console.log("getTeachersList.response", response);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("getTeachersList.error", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Add a new teacher's details.
export const addTeachersDetail = async (requestParams) => {
  const { id, data } = requestParams;
  console.log("addTeachersDetail.params", requestParams);

  try {
    // Send a POST request to the 'teachers' endpoint with the provided data.
    const response = await protectedApi.post("/teachers/", data);
    console.log("addTeachersDetail.response", response);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("addTeachersDetail.error", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Update an existing teacher's details.
export const updateTeachersDetail = async (requestParams) => {
  const { id, data } = requestParams;
  console.log("updateTeachersDetail.params", requestParams);

  try {
    // Send a PUT request to the 'teachers' endpoint with the provided data.
    const response = await protectedApi.put(`/teachers/${id}`, data);
    console.log("updateTeachersDetail.response", response);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("updateTeachersDetail.error", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get details of a specific teacher by their ID.
export const getTeachersDetail = async (requestParams) => {
  const { id } = requestParams;
  console.log("getTeachersDetail.params", requestParams);

  try {
    // Send a GET request to the 'teachers' endpoint with the teacher's ID.
    const response = await protectedApi.get(`/teachers/${id}`);
    console.log("getTeachersDetail.response", response);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("getTeachersDetail.error", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get the user's profile.
export async function getProfile() {
  try {
    // Send a GET request to the 'users/profile' endpoint to retrieve the user's profile.
    const res = await protectedApi.get("/users/profile");

    // Format and return the API response.
    return formatApiResponse(res);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("getProfileApi.catch", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
}

// Get the country list.
export async function getCountryList() {
  try {
    // Send a GET request Country List.
    const res = await protectedApi.get("/countries");
    console.log("getCountryList.response", res);

    // Format and return the API response.
    return formatApiResponse(res);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("getProfileApi.catch", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
}

export const getCompaniesListForUser = async () => {
  try {
    const response = await protectedApi.get("/companies/list");
    return formatApiResponse(response);
  } catch (e) {
    console.error("getCompaniesList.error", e);
    return formatApiResponse(e);
  }
};

export const getUserRolesListApi = async () => {
  try {
    const response = await protectedApi.get("/roles");
    return formatApiResponse(response);
  } catch (e) {
    console.error("getCompaniesList.error", e);
    return formatApiResponse(e);
  }
};

export const getEntityListApi = async (requestParams) => {
  const { role_id } = requestParams;
  try {
    const response = await protectedApi.get(`/entities/?role_id=${role_id}`);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getEntityListApi.error", e);
    return formatApiResponse(e);
  }
};

export const addUserApi = async (requestParams) => {
  const { data } = requestParams;
  const endpoint = `/users/`;
  console.log("addUserApi.params", requestParams);
  try {
    const response = await protectedApi.post(endpoint, data);
    return formatApiResponse(response);
  } catch (error) {
    // Handle any errors that occur during the API request and log them.
    console.error("addUserApi.error", error);
    return formatApiResponse(error);
  }
};

export const getEntityTypeListApi = async () => {
  try {
    const response = await protectedApi.get(`/entities-type`);
    return formatApiResponse(response);
  } catch (e) {
    console.error("getEntityTypeListApi.error", e);
    return formatApiResponse(e);
  }
};

export const getEntityNameListApi = async (entity_type) => {
  try {
    const response = await protectedApi.get(
      `/entities-type-name?entity_type=${entity_type}`
    );
    return formatApiResponse(response);
  } catch (e) {
    console.error("getEntityNameListApi.error", e);
    return formatApiResponse(e);
  }
};

export const getUserRolesBasedOnListApi = async (requestParams) => {
  const { entityType } = requestParams;
  console.log("getUserRolesBasedOnListApi.params", requestParams);
  try {
    const response = await protectedApi.get(
      `/entities-roles?entity_type=${entityType}`
    );
    return formatApiResponse(response);
  } catch (e) {
    console.error("getCompaniesList.error", e);
    return formatApiResponse(e);
  }
};

// check user flow filled or not
export async function checkUserFlow() {
  try {
    const res = await protectedApi.get("/users/check-required");

    // Format and return the API response.
    return formatApiResponse(res);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    console.error("checkUserFlow.catch", e);
    // Format and return an error response.
    return formatApiResponse(e);
  }
}
