import React from "react";
import styled from "styled-components";
import Btn from "../../../components/UI/Btn";
import { colors } from "../../../constants/colors";

const Wrapper = styled.div`
  margin: 0px 5px;
  // padding: 2px;
`;

const BtnWrapper = styled.div`
  text-align: center;
  border-radius: 5px;
  padding: 0 5px;
  color: ${({ selected }) => (selected ? colors.white : colors.primary)};
  background: ${({ selected }) => (selected ? colors.secondary : "inherit")};
  transition: transform 0.2s;

  &:hover,
  &:active {
    transform: ${({ selected }) => (!selected ? "scale(1.1)" : "inherit")};
  }
`;

const ToolItem = ({ icon, text, id, selected, ...restProps }) => {
  return (
    <Wrapper>
      <BtnWrapper selected={selected}>
        <Btn
          id={id}
          text={text}
          startIcon={icon}
          selected={selected}
          {...restProps}
        />
      </BtnWrapper>
    </Wrapper>
  );
};

export default ToolItem;
