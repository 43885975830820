import { formatApiResponse } from "../utils/helpers";
import { api, protectedApi } from "../api";
import { getRefreshToken } from "../contexts/authContext";

// Constants for access and refresh token keys in local storage
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

// Function to authenticate a user by email and password
export const loginApi = async (email, password) => {
  try {
    // Make a POST request to the login endpoint with email and password
    const res = await api.post("/login", { email, password });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to authenticate a company user by email and password
export const companyLoginApi = async (companyUrl, email, password) => {
  try {
    // Make a POST request to the login endpoint with email and password
    const res = await api.post(`/${companyUrl}/login`, { email, password });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to check company url is correct or not
export const getLoginForCompany = async (companyUrl) => {
  const endpoint = `/companies/check/${companyUrl}`;
  try {
    const response = await api.get(endpoint);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

// Function to log out the user
export const logoutApi = async () => {
  // Get the refresh token from local storage
  const refreshToken = {
    refresh: getRefreshToken(),
  };

  try {
    // Make a POST request to the logout endpoint with the refresh token
    const res = await protectedApi.post("/logout", refreshToken);

    // Clear the user's session or token from the client-side storage.
    if (res && res["status"] === 204) {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to request a password reset
export const forgotPasswordAPI = async (email, company_url) => {
  let endpoint;

  if (company_url && company_url.length > 0) {
    endpoint = `${company_url}/forgot-password`;
  } else {
    endpoint = `/forgot-password`;
  }

  try {
    // Make a POST request to the forgot password endpoint with the email
    const res = await api.post(endpoint, { email });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get details of the logged-in user
export const getloggedInUserDetailApi = async () => {
  try {
    // Make a GET request to retrieve the user's profile
    const res = await protectedApi.get("/users/profile", {});

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to update details of the logged-in user
export const updateloggedInUserDetailApi = async (data) => {
  try {
    // Make a put request to retrieve the user's profile
    const res = await protectedApi.put("/users/profile", data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to edit/update details of the logged-in user
export const editloggedInUserDetailApi = async (data) => {
  const endPoint = "/users/profile";

  try {
    // Make a PUT request to update the user's profile with the provided data
    const res = await protectedApi.put(endPoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to change the user's password
export const editChangePasswordApi = async (data) => {
  const endpoint = `/change-password`;

  try {
    // Make a PUT request to change the user's password with the provided data
    const res = await protectedApi.put(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to reset the user's password with a token
export const resetPasswordAPI = async (password, uidb64, token) => {
  const endpoint = "/set-password/";

  try {
    // Make a PATCH request to reset the user's password with the provided data
    const res = await api.put(endpoint, { password, uidb64, token });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to verify the user's password
export const verifyPasswordApi = async (data) => {
  const endpoint = `/verify-password/`;

  try {
    // Make a POST request to verify the user's password with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to send an OTP (One-Time Password) for verification
export const sendOtpApi = async (data) => {
  const endpoint = `/otp/send/`;

  try {
    // Make a POST request to send an OTP with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to verify an OTP (One-Time Password)
export const verifyOtpApi = async (data) => {
  console.log("verifyOtpApi.params", data);
  const endpoint = `/otp/verify/`;
  console.log("verifyotpApi.endpoint", endpoint);
  try {
    // Make a POST request to verify an OTP with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

// Function to resend an OTP (One-Time Password)
export const resendOtpApi = async (data) => {
  console.log("resendOtpApi.params", data);
  const endpoint = `/otp/resend/`;
  console.log("resendOtpApi.endpoint", endpoint);
  try {
    // Make a POST request to resend an OTP with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to update the user's email
export const updateEmailApi = async (data) => {
  console.log("updateEmailApi.params", data);
  const endpoint = `/users/update-email/`;
  console.log("updateEmailApi.endpoint", endpoint);
  try {
    // Make a PUT request to update the user's email with the provided data
    const res = await protectedApi.put(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to check if an email already exists
export const checkEmailApi = async (
  email,
  entityType,
  entityId,
  company_id = 0,
  user_id = "",
  user_type = ""
) => {
  const endpoint = `/check-email/?email=${email}&entity_type=${entityType}&entity_id=${entityId}&company_id=${company_id}&user_id=${user_id}&user_type=${user_type}`;
  console.log("checkEmailApirequest", endpoint);
  try {
    // Make a GET request to validate the email
    const res = await protectedApi.get(endpoint);
    console.log("checkEmailApiresponse", res);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// --------------Activate Account after Email Verification for New user

export const activateAccount = async (uidb64, token, type) => {
  try {
    const res = await api.get(
      `/users/activate/${uidb64}/${token}?type=${type}`
    );
    // Format and return the API response
    console.log(res);
    return res;
  } catch (e) {
    console.log(e);
    // Format and return the error response
    return formatApiResponse(e);
  }
};
