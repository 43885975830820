import React from "react";
import Typography from "@mui/material/Typography";
import { toTitleCase } from "../../../utils/utility";
import BtnLink from "../BtnLink";
import { downloadS3Object } from "../../../utils/common";
import { colors } from "../../../constants/colors";

const InfoLabel = ({ label, type, info = "" }) => {
  const openFileInNewTab = () => {
    const file = info;
    let fileURL = "";

    fileURL = file;
    // Open the file in a new tab
    window.open(fileURL, "_blank");
  };

  return (
    <div style={{ padding: "5px" }}>
      <Typography variant="h6" style={{ color: "#000000d6", fontWeight: 'bold', fontSize: '16px' }}>
        {label}
      </Typography>
      {type && type.toLowerCase() === "file" ? (
        <div style={{display: 'flex'}}>
        <BtnLink
          text={"View"}
          id={"view_" + label}
          onClick={openFileInNewTab}
          selected
          style={{
            color: colors.white,
            backgroundColor: colors.primarySelected
          }}
        />
        <BtnLink
          text={"Download"}
          id={"download_" + label}
          onClick={ () => downloadS3Object(info)}
          style={{
            marginLeft: 5,
            color: colors.white,
            backgroundColor: colors.suscess,
          }}
        />
        </div>
      ) : (
        <p style={{ fontSize: "16px" }}>
          {toTitleCase(info)}
        </p>
      )}
    </div>
  );
};

export default InfoLabel;
