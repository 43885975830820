import React from 'react';
import { TableBody } from '@mui/material';
import { fonts } from '../../../styles/vars';

const AppTableBody = ({ children, ...rest }) => {
  return (
    <TableBody sx={{ fontFamily: fonts.body }} {...rest}>
      {children}
    </TableBody>
  );
};

export default AppTableBody;
