// Function to convert string into titleCase
export const toCapitalize = (str) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (e) {
    return str;
  }
};

// Function to convert JSON data to FormData, automatically serializing keys with JSON data
export const convertJSONToFormData = (jsonData) => {
  try {
    const formData = new FormData();

    const appendToFormData = (prefix, obj) => {
      for (const key in obj) {
        const value = obj[key];
        const newKey = prefix ? `${prefix}[${key}]` : key;

        if (value !== undefined && value !== null) {
          if (value instanceof File) {
            // Handle File objects separately
            formData.append(newKey, value);
          } else if (
            Array.isArray(value) ||
            (typeof value === "object" && !Array.isArray(value))
          ) {
            // If the value is an array or an object (not an array), convert it to a JSON string
            formData.append(newKey, JSON.stringify(value));
          } else {
            formData.append(newKey, String(value));
          }
        }
      }
    };

    appendToFormData("", jsonData);

    return formData;
  } catch (error) {
    console.error("Error in convertJSONToFormData:", error);
    return null; // Return null in case of an error.
  }
};

// Function to convert string into titleCase
export const toTitleCase = (str) => {
  try {
    let convertedStr = str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");

    return convertedStr;
  } catch (e) {
    return str;
  }
};

export const filterJsonByRole = (data, userRole) => {
  try {
    return data.filter(({ role }) =>
      role.includes(userRole.trim().toLowerCase())
    );
  } catch (e) {
    console.log("====================================");
    console.log("e>>>>>>", e);
    console.log("====================================");
    return data;
  }
};

// Function to check if the file type is valid
export const isValidFileType = (file, allowedTypes) => {
  const fileType = file.name.split(".").pop().toLowerCase();
  return allowedTypes.includes(fileType);
};

// Function to check if the file size is valid
export const isValidFileSize = (file, maxSize) => {
  return file.size <= maxSize;
};
