import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

// Local Library Imports
import General from "./General";
import useAuth from "../../../hooks/useAuth";
import { isIterable } from "../../../utils/common";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { masterReference } from "../../../constants/masterData";
import dayjs from "dayjs";
import { dateFormFormat } from "../../../utils/dates";

const Timetable = () => {
  const { notify } = useNotistack();
  const { user } = useAuth();

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const initialValues = {
    lessons: [
      {
        lesson_id: 1,
        lesson_name: "",
        start_time: "",
        end_time: "",
        school_id: 1,
        entity_id: 0,
        entity_type: "",
        lesson_time_table: [
          {
            id: 0,
            room: "",
            course_id: 1,
            subject: "",
            day_name: "",
            day_date: "",
            teacher_id: 0,
            is_default: false,
            copy_to_weekdays: false,
          },
        ],
      },
    ],
    start_date: "",
    end_date: "",
    is_default: false,
    set_as_default: false,
  };

  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();
  const [activeTab, setActiveTab] = useState(0);
  const [LessonList, setLessonList] = useState(initialValues);

  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  // --------------------Function to get all dates between given range , here week range----------------

  function getDatesInRange(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  useEffect(() => {
    if (selectedRecord && selectedRecord.lessons) {
      const selectedData = {};
      selectedData["start_date"] = selectedRecord?.start_date
        ? selectedRecord?.start_date
        : dayjs().startOf("week").add(1, "day").format("YYYY-MM-DD");
      selectedData["end_date"] = selectedRecord?.end_date
        ? selectedRecord?.end_date
        : dayjs().endOf("week").add(1, "day").format("YYYY-MM-DD");
      selectedData["is_default"] = selectedRecord?.is_default
        ? Boolean(parseInt(selectedRecord?.is_default))
        : false;
      selectedData["set_as_default"] = false;
      selectedData["entity_id"] = selectedRecord?.lessons?.[0]?.entity_id;
      selectedData["entity_type"] = selectedRecord?.lessons?.[0]?.entity_type;

      selectedData["lessons"] = selectedRecord?.lessons || [];
      let start_range = selectedData["start_date"];
      let end_range = selectedData["end_date"];

      var allDatesList = getDatesInRange(start_range, end_range);

      if (selectedRecord?.lessons.length === 0) {
        const allDaysLesson = [];
        selectedRecord.lessons.forEach((lessonItem, lessonIndex) => {
          allDatesList.forEach((date, dateIndex) => {
            const lessonValues = {
              [`id`]: lessonItem.id,
              [`lesson_id`]: lessonItem.lesson_id,
              [`subject`]: lessonItem.subject,
              [`day_date`]:
                lessonItem.day_date ||
                // dayjs(date).format("YYYY-MM-DD")
                dateFormFormat(date),
              [`day_name`]:
                lessonItem.day_name ||
                weekdays[date.getDay() !== 0 ? date.getDay() - 1 : 6],
              [`teacher_id`]: lessonItem.teacher_id,
              [`room`]: lessonItem.room,
            };

            allDaysLesson.push(lessonValues);
          });
        });
        selectedData["lessons"] = allDaysLesson;
      } else if (selectedRecord?.lessons) {
        selectedRecord.lessons.forEach((lessonItem, lessonIndex) => {
          if (lessonItem.lesson_time_table.length === 0) {
            allDatesList.forEach((date, index) => {
              const lessonValues = {
                id: 0,
                room: "",
                course_id: 1,
                subject: "",
                day_name: weekdays[date.getDay() !== 0 ? date.getDay() - 1 : 6],
                day_date:
                  // dayjs(date).format("YYYY-MM-DD")
                  dateFormFormat(date),
                teacher_id: 0,
                is_default: false,
                copy_to_weekdays: false,
              };
              selectedData["lessons"][lessonIndex]["lesson_time_table"][index] =
                lessonValues;
            });
          }
        });
      }
      setData(selectedData);
      setLessonList(selectedRecord);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_TEACHER ||
        user.code === masterReference.SCHOOL_TEACHER
      ) {
        setEditable(true);
      }
    } else {
      formik.resetForm();
    }
  }, [selectedRecord]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);

    let start_range = values.start_date;
    let end_range = values.end_date;

    let allDatesList = getDatesInRange(start_range, end_range);
    const lessonData = LessonList?.lessons || [];

    const allDaysLesson = [];
    lessonData.forEach((lessonItem, lessonIndex) => {
      lessonItem.lesson_time_table.forEach((obj, index) => {
        const lessonValues = {
          id: values.lessons[lessonIndex].lesson_time_table[index]["id"] || 0,
          lesson_id: values.lessons[lessonIndex]["lesson_id"],
          subject:
            values.lessons[lessonIndex].lesson_time_table[index]["subject"] ||
            "",
          day_date:
            // dayjs(
            //   values.lessons[lessonIndex].lesson_time_table[index]["day_date"]
            // ).format("YYYY-MM-DD") ||

            dayjs(allDatesList[index]).format("YYYY-MM-DD"),
          day_name:
            values.lessons[lessonIndex].lesson_time_table[index]["day_name"] ||
            weekdays[
              allDatesList[index].getDay() !== 0
                ? allDatesList[index].getDay() - 1
                : 6
            ],
          teacher_id:
            values.lessons[lessonIndex].lesson_time_table[index][
              "teacher_id"
            ] || 0,
          room:
            values.lessons[lessonIndex].lesson_time_table[index]["room"] || "",
          copy_to_weekdays: false,
        };

        allDaysLesson.push(lessonValues);
      });
    });

    const formattedData = {
      lessons: allDaysLesson,
      deleted_lesson_ids: [],
      start_date: values.start_date,
      end_date: values.end_date,
      is_default: values.is_default ? 1 : 0,
    };

    let requestParams = { id: requestId, data: formattedData };
    console.log(formattedData);
    // return;
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);

    if (res.isOk) {
      setIsDirty(false);

      await setRequestId(null);
      setRequestId(requestId ? requestParams.id : res.data.course_id);
      setSelectedIndex(requestId ? requestId : res.data.course_id);

      if (requestId) {
        notify("Timetable Information Updated", "success");
      } else {
        notify("Timetable Added", "success");
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object" && isIterable(value)) {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: data,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleFormChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    setIsDirty(true);
  };

  return (
    <>
      <OverlayLoader show={isLoading}>
        <form onSubmit={formik.handleSubmit}>
          {/* <StyledTab
            value={activeTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.secondary,
              },
            }}
          >
            <TabItem label={"General"} {...a11yProps(0)} />
          </StyledTab> */}

          {selectedRecord.lessons && (
            <div
              style={{ padding: "24px", paddingTop: 0 }}
              id="simple-tabpanel-0"
              aria-labelledby="simple-tab-0"
            >
              <General
                formik={formik}
                handleFormChange={handleFormChange}
                LessonList={LessonList}
                setLessonList={setLessonList}
                data={data}
                setData={setData}
              />
            </div>
          )}
        </form>
      </OverlayLoader>
    </>
  );
};

export default Timetable;
