import { api, protectedApi } from "../api";
import { formatApiResponse } from "../utils/helpers";

// Function to get a list of applicants with pagination
export const getEntityTypeList = async () => {
  try {
    // Make a GET request to the applicants endpoint with pagination parameters
    const response = await protectedApi.get("/entities/");
    console.log("getEntityTypeList.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getEntityTypeList.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get a list of entity according to antity type and company id
export const getEntitiesList = async (requestParams) => {
  try {
    const { entity_type, company_id } = requestParams;
    console.log("getEntitiesList.params", requestParams);
    let param;
    if (!company_id ||company_id === 0) param = `?entity_type=${entity_type}`;
    else param = `?entity_type=${entity_type}&company_id=${company_id}`;
    // Make a GET request to the applicants endpoint with pagination parameters
    const response = await protectedApi.get("/entities-name" + param);
    console.log("getEntitiesList.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getEntitiesList.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const updateEntityStatus = async (entity_type, entity_id, status) => {
  console.log("updateEntityStatus.params", entity_type, entity_id, status);
  try {
    const response = await protectedApi.get(`/companies/entity/update-status?entity_type=${entity_type}&entity_id=${entity_id}&status=${status}`);
    console.log("updateEntityStatus.response", response);

    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("updateEntityStatus.error", e);

    // Format and return the error response
    return formatApiResponse(e);
  }
};
// Function to get AWS s3  signed url
export const getS3SignedUrl = async (url) => {
  try {
    console.log("getS3SignedUrl.params", url);
    let param = `?url=${url}`;
    const response = await protectedApi.get("/download" + param);
    console.log("getS3SignedUrl.response", response);
    // Format and return the API response
    return formatApiResponse(response);
  } catch (e) {
    console.error("getS3SignedUrl.error", e);
    // Format and return the error response
    return formatApiResponse(e);
  }
};
