import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { styled } from "styled-components";
import { Wrap } from "../../style/style";

// Create a styled TextField component
const StyledTextField = styled(TextField)``;

// Define a functional component for FileField
const FileField = ({
  id,
  label,
  name,
  onChange,
  required = false,
  variant = "outlined",
  width = "",
  border = "",
  helperText, // Add helperText prop
  error, // Add error prop
  ...restProps
}) => {
  // Generate the title for the field, indicating if it's required
  const title = `${label} ${required ? "*" : ""}`;
  const errorMsg = helperText !== "REQUIRED" ? helperText : "";

  // State to keep track of the selected file
  const [selectedFile, setSelectedFile] = useState(null);

  // Handle file input change and notify the parent component
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    onChange(event.target.files[0]); // Notify the parent component of the selected file
  };

  return (
    <Wrap noValidate autoComplete="off">
      <StyledTextField
        id={id || name}
        type="file"
        label={title}
        variant={variant}
        inputProps={{ accept: "image/png, image/jpeg, application/pdf" }}
        onChange={handleFileChange}
        InputLabelProps={{
          shrink: true, // This will make the label appear at the top
        }}
        sx={{
          "& input + fieldset": {
            borderColor: border ? "red" : "black",
            borderWidth: 1,
          },
        }}
        helperText={errorMsg} // Use the helperText prop here
        error={error} // Use the error prop here
        name={name}
        size="small"
        {...restProps}
        style={{
          width: `${width ? width : "90%"}`,
        }}
      />
    </Wrap>
  );
};

// Define propTypes to specify the expected types for props
FileField.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default FileField;
