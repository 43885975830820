import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import ShrinkLabelInput from "../../components/UI/ShrinkLabelInput";
import { InputError } from "../../components/UI/InputError";
import useAuth from "../../hooks/useAuth";
import WidgetsLoader from "../../components/WidgetsLoader";
import CancelButtonWidget from "../CancelButtonWidget";
import SubmitButtonWidget from "../SubmitButtonWidget";
import { BtnGrid, GridItem, UserGrid } from "./style";
import { checkEmailApi } from "../../services/auth";
import ShrinkSearchSelect from "../../components/UI/ShrinkSearchSelect";
import {
  getUserRolesListApi,
  getEntityListApi,
  getEntityTypeListApi,
  getEntityNameListApi,
  getUserRolesBasedOnListApi,
} from "../../services/users";
import { handleKeys } from "../../utils/common";
import { dateFormFormat } from "../../utils/dates";
import { emailRegex } from "../../constants/vars";
import { masterReference } from "../../constants/masterData";
import { toTitleCase } from "../../utils/utility";

const superAdminEntityTypeOptions = [
  {
    label: "Company",
    value: "Company",
  },
];
const superAdminEntityNameOptions = [
  {
    label: "Wecaresoft",
    value: 0,
  },
];

const UserForm = ({
  isEditing,
  isLoading,
  userId,
  initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    role_id: "",
    // gender: "",
    // dob: dayjs().format("YYYY-MM-DD"),
    place_of_birth: "",
    citizenship: "",
    situation: "",
    religion: "",
    // health_insurance_provider: "",
    // iban: "",
    tax_id: "",
    // social_security_number: "",
    work_contract: "",
    qualification: "",
    referred_by: "",
    entity_id: "",
    entity_type: "",
    company_id: "",
  },
  onSubmit,
  isMobile,
}) => {
  const { user } = useAuth();
  const [entityNameOptions, setEntityNameOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [entityTypeOptions, setEntityTypeOptions] = useState([]);
  const [superAdminId, setSuperAdminId] = useState(initialValues.role_id);

  const addUserValidationSchema = yup.object({
    first_name: yup.string().required("REQUIRED"),
    last_name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(
                value,
                masterReference.USER,
                this.parent.entity_id,
                entityNameOptions.find(
                  (option) =>
                    String(option.value) === String(this.parent.entity_id)
                )?.company_id ||
                  entityNameOptions[0]?.company_id ||
                  0,
                userId,
                this.parent.entity_type
              )
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
    // phone_no: yup.string().required("REQUIRED"),
    role_id: yup.string().required("REQUIRED"),
    entity_id: yup.string().required("REQUIRED"),
    entity_type: yup.string().required("REQUIRED"),
    // gender: yup.string().required("REQUIRED"),
    // dob: yup.string().required(),
    // place_of_birth: yup.string().required(),
    // citizenship: yup.string().required(),
    // situation: yup.string().required(),
    // religion: yup.string().required(),
    // health_insurance_provider: yup.string().required(),
    // iban: yup.string().required(),
    // tax_id: yup.string().required(),
    // social_security_number: yup.string().required(),
    // qualification: yup.string().required(),
    // work_contract: yup.string().required(),
  });

  // const genderOptions = [
  //   { label: "Male", value: "Male" },
  //   { label: "Female", value: "Female" },
  //   { label: "Others", value: "Others" },
  // ];

  // useEffect(() => {
  //   if (!initialValues.gender) {
  //     formik.setFieldValue("gender", genderOptions[0]?.value);
  //   }
  // }, []);

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema: addUserValidationSchema,
    onSubmit,
  });

  // -------------------------------------------NEW CODE----------------------------

  // super admin -  set role as super admin by default if no role selected
  // admin account - set role as admin , if no role selected.
  useEffect(() => {
    if (!initialValues.role_id) {
      formik.setFieldValue("role_id", roleOptions[0]?.value);
    }
  }, [roleOptions]);

  /**
    Add User - get roles as super admin and based on entity company  
  * Edit user - Check if entity id and type value exists then get roles based on it
   
   */

  useEffect(() => {
    // Edit user - when user id and entity details are avaialble
    if (userId > 0 && formik.values.entity_type && formik.values.entity_id) {
      getRolesBasedOnEntity(formik.values.entity_type);
    } else {
      // Add User - from Super Admin or Admin
      getUserRolesListApi().then((response) => {
        if (user.code === masterReference.SUPER_ADMIN) {
          const superAdminObj = response.data.find(
            (object) => object.label.trim() === "Super Admin"
          );
          setRoleOptions([superAdminObj]);
          setSuperAdminId(String(superAdminObj?.value));
        } else {
          getRolesBasedOnEntity("Company");
        }
      });
    }
  }, [user]);

  // useEffect(() => {
  //   if (String(formik.values.role_id) === superAdminId) {
  //     // setEntityTypeOptions(superAdminEntityTypeOptions);
  //     setEntityNameOptions(superAdminEntityNameOptions);
  //   }
  // }, [formik.values.role_id]);

  /* For Add User - role id is superadmin id so show role as Super Admin by default
  Edit user - if entity type doesnt exists show first value in entity type by default
  */
  useEffect(() => {
    if (formik.values.role_id === superAdminId && userId <= 0) {
      // setEntityTypeOptions(superAdminEntityTypeOptions);
      formik.setFieldValue("entity_type", superAdminEntityTypeOptions[0].value);
    } else {
      if (!initialValues.entity_type) {
        formik.setFieldValue("entity_type", entityTypeOptions[0]?.value);
      }
    }
  }, [entityTypeOptions, superAdminId]);

  /**
   * This useeffect will trigger if role is changed or entity name is changed
   *
   * Super Admin role id = 1 , Admin role id = 2 , all other user role id are more than 2
   * First we check for if role selected is role id 1 i.e Super Admin and if entity type is "company" , then we set entity id and company id for super admin user
   * If role id is more than 1 but belongs to company. That means role can be Admin , HR or Accountant ,then only set entity id
   * Else if role is not Super admin OR admin , then the role belongs to entity (school / institute / hiring agency) and must be set from
   * usestate entityNameOptions
   *
   */
  useEffect(() => {
    if (
      formik.values.role_id <= 1 &&
      formik.values.entity_type?.toLowerCase() === "company"
    ) {
      // setEntityNameOptions(superAdminEntityNameOptions);
      formik.setFieldValue("entity_id", superAdminEntityNameOptions[0].value);
      formik.setFieldValue("company_id", 1);
    } else if (
      formik.values.role_id > 1 &&
      formik.values.entity_type?.toLowerCase() === "company"
    ) {
      formik.setFieldValue(
        "entity_id",
        entityNameOptions[0]?.value || formik.values.entity_id
      );
      // formik.setFieldValue("company_id", entityNameOptions[0]?.company_id);
    } else {
      if (
        !initialValues.entity_id &&
        formik.values.entity_type?.toLowerCase() !== "company" &&
        formik.values.role_id > 1
      ) {
        formik.setFieldValue("entity_id", entityNameOptions[0]?.value);
        formik.setFieldValue("company_id", entityNameOptions[0]?.company_id);
      }
    }
  }, [entityNameOptions, formik.values.role_id]);

  // Common Function to get Role Based on Entity Type of a User
  const getRolesBasedOnEntity = (entity_type) => {
    let requestParams = {
      entityType: entity_type || entityTypeOptions[0]?.value,
      entityName: "",
    };
    getUserRolesBasedOnListApi(requestParams).then((res) => {
      if (res.isOk) {
        setRoleOptions(res.data);
      }
    });
  };

  // Common Function to get entity Name List based on entity Type selected for a User
  const getEntityNameList = (entity_type) => {
    getEntityNameListApi(entity_type).then((res) => {
      if (res.isOk) {
        res.data.entity_name_list.forEach((ele, index) => {
          ele.label = toTitleCase(ele.label);
        });
        if (
          entity_type.toLowerCase() === "company" &&
          user.code === masterReference.SUPER_ADMIN
        ) {
          setEntityNameOptions([
            ...superAdminEntityNameOptions,
            ...res.data.entity_name_list,
          ]);
        } else {
          setEntityNameOptions(res.data.entity_name_list);
        }
      }
    });
  };

  /* On page load OR role change - In user detail, GET list of entity type .
   Call  getEntityNameList function to 
   get entity name list based on entity type  */

  useEffect(() => {
    getEntityTypeListApi().then((res) => {
      if (res.isOk) {
        res.data.entity_type_list.forEach((ele, index) => {
          ele.label = toTitleCase(ele.label);
        });
        setEntityTypeOptions(res.data.entity_type_list);
        getEntityNameList(
          formik.values.entity_type || res.data.entity_type_list[0]?.value
        );
      }
    });
  }, [superAdminId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
  };

  return (
    <>
      {isLoading ? (
        <WidgetsLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <UserGrid container spacing={2}>
            <GridItem item xs={12} sm={4}>
              <ShrinkSearchSelect
                id="entity_type"
                name="entity_type"
                label="Entity Type"
                required
                options={entityTypeOptions}
                onChange={(_, selected) => {
                  formik.setFieldValue("entity_type", selected?.value || "");
                  setEntityNameOptions([]);
                  getEntityNameList(selected?.value || "");

                  if (
                    selected?.value.toLowerCase() === "company" &&
                    user.code === masterReference.SUPER_ADMIN
                  ) {
                    formik.setFieldValue("entity_id", "0");
                    setRoleOptions([
                      {
                        label: "Super Admin",
                        value: 1,
                      },
                    ]);
                  } else {
                    getRolesBasedOnEntity(selected?.value || "");
                  }
                }}
                value={
                  entityTypeOptions.find(
                    (option) =>
                      String(option.value) === String(formik.values.entity_type)
                  ) ||
                  entityTypeOptions[0] ||
                  null
                }
                disabled={userId ? true : false}
                helperText={
                  formik.touched?.entity_type ? formik.errors?.entity_type : ""
                }
                error={formik.touched.entity_type && formik.errors.entity_type}
              />
              {/* {formik.touched.entity_type && formik.errors.entity_type && (
                <InputError>{formik.errors.entity_type}</InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={4}>
              <ShrinkSearchSelect
                required
                id="entity_id"
                name="entity_id"
                label="Entity Name"
                options={entityNameOptions}
                onChange={(_, selected) => {
                  formik.setFieldValue("entity_id", selected?.value || "");

                  formik.setFieldValue("company_id", selected?.company_id || 0);

                  if (
                    (formik.values.entity_type
                      ? formik.values.entity_type.toLowerCase() === "company"
                      : entityTypeOptions[0]?.value.toLowerCase() ===
                        "company") &&
                    selected?.value === 0 &&
                    user.code === masterReference.SUPER_ADMIN
                  ) {
                    setRoleOptions([
                      {
                        label: "Super Admin",
                        value: 0,
                      },
                    ]);
                  } else if (
                    formik.values.entity_type
                      ? formik.values.entity_type.toLowerCase() === "company"
                      : entityTypeOptions[0]?.value.toLowerCase() === "company"
                  ) {
                    getRolesBasedOnEntity(formik.values.entity_type);
                  }
                }}
                value={
                  entityNameOptions.find(
                    (option) =>
                      String(option.value) === String(formik.values.entity_id)
                  ) ||
                  entityNameOptions[0] ||
                  null
                }
                disabled={userId ? true : false}
                helperText={
                  formik.touched?.entity_id ? formik.errors?.entity_id : ""
                }
                error={formik.touched.entity_id && formik.errors.entity_id}
              />
              {/* {formik.touched.entity_id && formik.errors.entity_id && (
                <InputError>
                  {formik.errors.entity_id.replace("entity_id", "Entity Name")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={4}>
              <ShrinkSearchSelect
                required
                id="role_id"
                name="role_id"
                label="Role"
                options={roleOptions}
                onChange={(_, selected) => {
                  formik.setFieldValue("role_id", selected?.value || "");
                }}
                value={
                  roleOptions.find(
                    (option) => option.value === formik.values.role_id
                  ) ||
                  roleOptions[0] ||
                  ""
                }
                helperText={
                  formik.touched?.role_id ? formik.errors?.role_id : ""
                }
                error={formik.touched.role_id && formik.errors.role_id}
              />
              {/* {formik.touched.role_id && formik.errors.role_id && (
                <InputError>
                  {formik.errors.role_id.replace("role_id", "Role")}
                </InputError>
              )} */}
            </GridItem>

            <GridItem item xs={12} sm={6}>
              <ShrinkLabelInput
                id="first_name"
                name="first_name"
                title="First Name"
                placeholder="First Name"
                required
                value={toTitleCase(formik.values.first_name)}
                onChange={handleChange}
                helperText={
                  formik.touched?.first_name ? formik.errors?.first_name : ""
                }
                error={formik.touched.first_name && formik.errors.first_name}
              />
              {/* {formik.touched.first_name && formik.errors.first_name && (
                <InputError>
                  {formik.errors.first_name.replace("first_name", "First Name")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <ShrinkLabelInput
                id="last_name"
                name="last_name"
                title="Last Name"
                placeholder="Last Name"
                required
                value={toTitleCase(formik.values.last_name)}
                onChange={handleChange}
                helperText={
                  formik.touched?.last_name ? formik.errors?.last_name : ""
                }
                error={formik.touched.last_name && formik.errors.last_name}
              />
              {/* {formik.touched.last_name && formik.errors.last_name && (
                <InputError>
                  {formik.errors.last_name.replace("last_name", "Last Name")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <ShrinkLabelInput
                id="email"
                name="email"
                title="Email Address"
                required
                placeholder="example@domain.com"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched?.email ? formik.errors?.email : ""}
                error={formik.touched.email && formik.errors.email}
              />
              {/* {formik.touched.email && formik.errors.email && (
                <InputError>
                  {formik.errors.email.replace("email", "Email")}
                </InputError>
              )} */}
            </GridItem>
            <GridItem item xs={12} sm={6}>
              <ShrinkLabelInput
                id="phone_no"
                name="phone_no"
                title="Phone Number"
                // placeholder="+1(000)-000-00-00"
                placeholder="9XXXXXXXXX"
                // required
                onKeyPress={handleKeys}
                onChange={handleChange}
                value={formik.values.phone_no}
                helperText={
                  formik.touched?.phone_no ? formik.errors?.phone_no : ""
                }
                error={formik.touched.phone_no && formik.errors.phone_no}
              />
              {/* {formik.touched.phone_no && formik.errors.phone_no && (
                <InputError>
                  {formik.errors.phone_no.replace("phone_no", "phone")}
                </InputError>
              )} */}
            </GridItem>
            {/* <GridItem item xs={12} sm={6}>
              <ShrinkSearchSelect
                required
                id="gender"
                name="gender"
                label="Gender"
                options={genderOptions}
                onChange={(_, selected) => {
                  formik.setFieldValue("gender", selected?.value || "");
                }}
                value={
                  genderOptions.find(
                    (option) => option.value === formik.values.gender
                  ) ||
                  genderOptions[0] ||
                  ""
                }
                helperText={formik.touched?.gender ? formik.errors?.gender : ""}
                error={formik.touched.gender && formik.errors.gender}
              />
              {formik.touched.gender && formik.errors.gender && (
                <InputError>{formik.errors.gender}</InputError>
              )}
            </GridItem> */}
            {/* <GridItem item xs={12} sm={6}>
              <ShrinkLabelInput
                id="dob"
                name="dob"
                title="Date of Birth"
                placeholder="Date of Birth"
                type="date"
                // required
                onKeyPress={handleKeys}
                onChange={handleChange}
                value={dateFormFormat(formik.values.dob)}
                helperText={formik.touched?.dob ? formik.errors?.dob : ""}
                error={formik.touched.dob && formik.errors.dob}
                max={dayjs().format("YYYY-MM-DD")}
              />
              {formik.touched.dob && formik.errors.dob && (
                <InputError>
                  {formik.errors.dob.replace("dob", "date of birth")}
                </InputError>
              )}
            </GridItem> */}
            {/* <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="place_of_birth"
                name="place_of_birth"
                title="Place of Birth"
                placeholder=""
               
                onChange={handleChange}
                value={formik.values.place_of_birth}
                helperText={
                  formik.touched?.place_of_birth
                    ? formik.errors?.place_of_birth
                    : ""
                }
                error={
                  formik.touched.place_of_birth && formik.errors.place_of_birth
                }
              />
         
            </GridItem>
            <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="citizenship"
                name="citizenship"
                title="Citizenship"
                placeholder="American"
             
                onChange={handleChange}
                value={formik.values.citizenship}
                helperText={
                  formik.touched?.citizenship ? formik.errors?.citizenship : ""
                }
                error={formik.touched.citizenship && formik.errors.citizenship}
              />
             
            </GridItem>
            <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="situation"
                name="situation"
                title="Family Status"
                placeholder="single"
               
                onChange={handleChange}
                value={formik.values.situation}
                helperText={
                  formik.touched?.situation ? formik.errors?.situation : ""
                }
                error={formik.touched.situation && formik.errors.situation}
              />
         
            </GridItem>
            <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="religion"
                name="religion"
                title="Religion"
                placeholder="Hindu"
              
                onChange={handleChange}
                value={formik.values.religion}
                helperText={
                  formik.touched?.religion ? formik.errors?.religion : ""
                }
                error={formik.touched.religion && formik.errors.religion}
              />
             
            </GridItem> */}
            {/* <GridItem item xs={12} sm={4}>
          <ShrinkLabelInput
            id="health_insurance_provider"
            name="health_insurance_provider"
            title="Health Insurance Provider"
            placeholder=""
            required
            onChange={handleChange}
            value={formik.values.health_insurance_provider}
          />
          {formik.touched.health_insurance_provider &&
            formik.errors.health_insurance_provider && (
              <InputError>
                {formik.errors.health_insurance_provider.replace(
                  "health_insurance_provider",
                  "health insurance provider"
                )}
              </InputError>
            )}
        </GridItem>
        <GridItem item xs={12} sm={4}>
          <ShrinkLabelInput
            id="iban"
            name="iban"
            title="IBAN"
            placeholder=""
            required
            onChange={handleChange}
            value={formik.values.iban}
          />
          {formik.touched.iban && formik.errors.iban && (
            <InputError>{formik.errors.iban}</InputError>
          )}
        </GridItem> */}
            {/* <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="tax_id"
                name="tax_id"
                title="Tax ID"
                placeholder=""
              
                onChange={handleChange}
                value={formik.values.tax_id}
                helperText={formik.touched?.tax_id ? formik.errors?.tax_id : ""}
                error={formik.touched.tax_id && formik.errors.tax_id}
              />
              
            </GridItem> */}
            {/* <GridItem item xs={12} sm={4}>
          <ShrinkLabelInput
            id="social_security_number"
            name="social_security_number"
            title="Social Security Number"
            placeholder=""
            required
            onChange={handleChange}
            value={formik.values.social_security_number}
          />
          {formik.touched.social_security_number &&
            formik.errors.social_security_number && (
              <InputError>
                {formik.errors.social_security_number.replace(
                  "social_security_number",
                  "social security number"
                )}
              </InputError>
            )}
        </GridItem> */}
            {/* <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="qualification"
                name="qualification"
                title="Qualification"
                placeholder=""
      
                onChange={handleChange}
                value={formik.values.qualification}
                helperText={
                  formik.touched?.qualification
                    ? formik.errors?.qualification
                    : ""
                }
                error={
                  formik.touched.qualification && formik.errors.qualification
                }
              />
    
            </GridItem> */}
            {/* <GridItem item xs={12} sm={4}>
              <ShrinkLabelInput
                id="work_contract"
                name="work_contract"
                title="Work Contract"
                placeholder=""
                
                onChange={handleChange}
                value={formik.values.work_contract}
                helperText={
                  formik.touched?.work_contract
                    ? formik.errors?.work_contract
                    : ""
                }
                error={
                  formik.touched.work_contract && formik.errors.work_contract
                }
              />
            
            </GridItem> */}
            {!isMobile ? (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <BtnGrid item xs={6} sm={2}>
                    <CancelButtonWidget />
                  </BtnGrid>
                  <BtnGrid item xs={6} sm={2}>
                    <SubmitButtonWidget />
                  </BtnGrid>
                </Grid>
              </>
            ) : (
              <BtnGrid item xs={12} sm={12}>
                <SubmitButtonWidget />
              </BtnGrid>
            )}
          </UserGrid>
        </form>
      )}
    </>
  );
};

export default UserForm;
