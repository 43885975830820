import React, { useState, useEffect } from "react";
import {
    Box,
    TextField,
    Dialog,
    DialogContent,
    Grid,
    FormControl,
  } from "@mui/material";
  import Button from '@mui/material/Button';
  import { styled } from "@mui/material/styles";
  import IconButton from "@mui/material/IconButton";
  import CloseIcon from "@mui/icons-material/Close";
  import SaveIcon from "@mui/icons-material/Save";
  import ModeEditIcon from "@mui/icons-material/ModeEdit";
  import ToolItem from "../../../widgets/ToolBar/ToolItem";
  import DialogTitle from "@mui/material/DialogTitle";

  import { colors } from "../../../constants/colors";


  const FlexWrapper = styled("div")(({ theme }) => ({
    background: `${colors.white}`,
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "1px",
    paddingTop: "5px",
    paddingBottom: "5px",
  }));


const AddReport = ({ open, handleClose, value, reportChange, setIsDirty }) => {
    const [reportValue, setReportValue] = useState(value);

    const handleSave = () => {
        setIsDirty(true);
        reportChange(reportValue);
        handleClose();
    }

    useEffect(() => {
        setReportValue(value);
    },[open])

    return (
        
      <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <p style={{ color: colors.secondary, paddingLeft: "12px" }}>{value ? "Edit Report" : "Add Report"}</p>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: colors.red,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, minWidth: "800px" },
              }}
              noValidate
              autoComplete="off"
            >
              <FormControl fullWidth color="success">
                <TextField
                  id="outlined-multiline-static"
                  label="Report"
                  placeholder="Type something..."
                  multiline
                  rows={10}
                  value={reportValue}
                  onChange={(event) => {
                    setReportValue(event.target.value);
                //     setReportContent(event.target.value);
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
            <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSave} >Save</Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogTitle />
    </Dialog>
    )
}

export default AddReport;