import React, { useState, useEffect } from "react";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Modal,
  Backdrop,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";
import { ImageContainer, UploadContainer } from "./style";
import Btn1 from "../../components/UI/Btn1";
import { Wrapper, Heading } from "../../components/style/style";
import ToolItem from "../ToolBar/ToolItem";
import CloseButton from "../../components/UI/CloseButton";
import { colors } from "../../constants/colors";

// ------------------set the uploaded documents extension types---------------
const fileTypes = ".doc, .docx, .pdf";

const StyledCloseButton = styled(Close)(() => ({
  position: "absolute",
  right: -8,
  top: -8,
  backgroundColor: colors.light_gray,
  color: colors.absolute_red,
  borderRadius: "50px",
  cursor: "pointer",
}));

const PreviewIframe = styled("iframe")(() => ({
  height: "100%",
  width: "100%",
  border: 0,
  backgroundColor: colors.white,
}));

const Content = styled.div`
  && {
    color: ${colors.primary};
  }
  padding-bottom: 5px;
`;

const DragDropWidget = ({ existingFiles, name, formik, setIsDirty }) => {
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [documentSrc, setdocumentSrc] = useState(false);
  const [documentType, setdocumentType] = useState("");

  const handleOpen = (url, type) => {
    setdocumentType(type);
    setdocumentSrc(url);
    setOpenFile(true);
  };

  const handleClose = () => setOpenFile(false);

  const handleDailog = (index) => {
    setDeleteIndex(index);
    setOpen(true);
  };

  const reduceTab = async (index) => {
    if (index === tabIndex && index !== 0) {
      setTabIndex(index - 1);
    }
  };

  const removeFile = (index) => {
    setIsDirty(true);

    // ---------if only one file , set tabfile eg:- student_contract blank after file delete -----------
    if (formik.values[name]?.length === 1) {
      formik.setFieldValue(`${name}`, []);
    }

    let deletedFileList = formik.values[`${name}_delete`] || [];
    let deleted_file = formik.values[name].splice(index, 1);
    deletedFileList.push(deleted_file[0].name);
    formik.setFieldValue(`${name}_delete`, deletedFileList);

    // -----------------if more than one existing file for any of the document generator , set spliced array ------------------
    if (formik.values[name].length > 1) {
      formik.setFieldValue(`${name}`, formik.values[name]);
    }
  };

  const handleYes = () => {
    setOpen(false);
    reduceTab(deleteIndex).then(() => removeFile(deleteIndex));
  };
  const handleNo = () => {
    setOpen(false);
  };

  const getFileName = (url) => {
    if (url) {
      if (typeof url === "string") {
        let name = url.split("/").at(-1);

        return name;
      } else if (typeof url === "object") {
        let name = url.name;
        return name;
      }
    }
  };

  //------------- function to add selected and drag dropped files in a list---------------

  const handleChange = (filesObj) => {
    setIsDirty(true);
    let fileArray = formik.values[name]?.length > 0 ? formik.values[name] : [];

    for (const files in filesObj) {
      if (typeof filesObj[files] === "object") {
        fileArray.push(filesObj[files]);
      }
    }
    formik.setFieldValue(name, fileArray);
  };

  return (
    <>
      {/* --------------------------custom drag drop--------------------- */}
      {/* <App> */}
      <Grid
        // justifyContent="center"
        container
      >
        <Grid item xs>
          <ImageContainer>
            <UploadContainer>
              <CloudUploadIcon style={{ fontSize: "80px" }} />
              <input
                type="file"
                style={{
                  display: "block",
                  border: "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                }}
                accept={fileTypes}
                multiple
                name={name}
                onChange={(e) => handleChange(e.target.files)}
              />
              <p>Drag & Drop Files here</p>
              <p>OR</p>

              <Btn1 text={"Browse Files"} style={{ width: "40%" }} />
              <br />
            </UploadContainer>
          </ImageContainer>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            {formik.values[name]?.length > 0
              ? formik.values[name]?.map((link, index) => {
                  return link ? (
                    <Grid item xs={6} md={4} key={`${name}${index}`}>
                      <Wrapper>
                        <Content
                          component={"div"}
                          style={{
                            padding: "13px",
                            marginTop: "30px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs>
                              <ToolItem
                                text={
                                  getFileName(link).split(".")[0].length > 15
                                    ? getFileName(link)
                                        .split(".")[0]
                                        .substring(0, 15) + "..."
                                    : getFileName(link).split(".")[0]
                                }
                                icon={
                                  <InsertDriveFileTwoToneIcon fontSize="large"></InsertDriveFileTwoToneIcon>
                                }
                                title={getFileName(link)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                aria-label="icon"
                                onClick={() =>
                                  link?.path &&
                                  link?.type &&
                                  handleOpen(link?.path, link.type)
                                }
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  style={{ opacity: link.path ? 1 : 0.5 }}
                                />
                              </IconButton>
                            </Grid>
                            <Grid item xs={2}>
                              <CloseButton
                                onClick={() => handleDailog(index)}
                              />
                            </Grid>
                          </Grid>
                        </Content>
                      </Wrapper>
                    </Grid>
                  ) : (
                    <null />
                  );
                  // </Box>
                })
              : ""}
          </Grid>
        </Grid>
      </Grid>

      {/* -----------------------------------View File popup--------------------- */}
      <Backdrop onClick={handleClose} open={openFile}>
        <Modal
          onClose={handleClose}
          open={openFile}
          sx={{
            width: "90%",
            height: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: colors.white,
            boxShadow: 24,
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <StyledCloseButton
              fontSize="large"
              onClick={handleClose}
              color="error"
            />
            {/* <PreviewIframe
              id="i_frame"
              src={
                documentType !== "pdf"
                  ? `https://docs.google.com/gview?url=${documentSrc}&embedded=true`
                  : documentSrc
              }
            
              title="document"
            ></PreviewIframe> */}
            <DocViewer
              prefetchMethod="GET"
              documents={[
                {
                  uri: documentSrc,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          </div>
        </Modal>
      </Backdrop>

      {/* -----------------------------Delete document popup----------------------------- */}

      <Dialog
        open={open}
        onClose={handleNo}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete File"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this file ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleYes();
            }}
          >
            Yes
          </Button>

          <Button onClick={handleNo} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* </App> */}
    </>
  );
};

export default DragDropWidget;
