import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Btn1 from "../../components/UI//Btn1";
import LabeledFormInput from "../../components/UI//LabeledFormInput";
import { breakpoints, flex } from "../../styles/vars";
import clsx from "clsx";
import { forgotPasswordAPI, getLoginForCompany } from "../../services/auth";
import useNotistack from "../../hooks/useNotistack";
import { useNavigate, useParams } from "react-router-dom";
import { masterReference } from "../../constants/masterData";

const StyledForm = styled.form`
  width: 90%;
  padding: 20px;
  display: flex;
  ${flex.col}
  ${breakpoints.laptop} {
    align-items: center;
    width: 70%;
  }
`;

const InputWrapper = styled.div`
  margin: 10px 0px;
  ${breakpoints.laptop} {
    width: 70%;
  }
`;

const BtnWrapper = styled.div`
  margin: 10px 0px;
  ${breakpoints.laptop} {
    width: 70%;
  }
`;

const RootWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: radial-gradient(#653d84, #332042);
  position: absolute;
  display: flex;
`;
export const CompanyNotFoundHeader = styled.h1`
  justify-content: center;
  margin: auto;
  width: 50%;
`;
const ContentWrapper = styled.div`
  width: 70%;
  height: 80%;
  position: absolute;
  transform: translate(20%, 15%);
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 4px 22px -8px #0004;
  display: flex;
  overflow: hidden;
`;

const forgotPasswordSchema = yup.object({
  email: yup.string().email("Email must be valid").required(),
});

export default function ForgotPasswordForm() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = useNotistack();
  const navigate = useNavigate();
  const paramValues = useParams();
  const [contentError, setContentError] = useState("");

  useEffect(() => {
    if (paramValues.url) {
      setIsLoading(true);
      getLoginForCompany(paramValues.url)
        .then(async function (data) {
          if (data.isOk) {
          } else {
            // alert("else");

            setContentError(data.error.message);
          }
        })
        .catch(function () {})
        .finally(function () {
          setIsLoading(false);
        });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, action) => {
      try {
        setIsLoading(true);
        let res = await forgotPasswordAPI(values.email, paramValues.url);
        if (res.isOk) {
          notify(res.detail, "success");
          if (paramValues.url && paramValues.url.length > 0) {
            navigate(`/${paramValues.url}/login`);
          } else {
            navigate("/login");
          }
          action.resetForm();
        } else {
          notify(res.error, "error");
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      {contentError?.length > 1 ? (
        <RootWrapper>
          <ContentWrapper>
            <CompanyNotFoundHeader>{contentError}</CompanyNotFoundHeader>
          </ContentWrapper>
        </RootWrapper>
      ) : (
        <StyledForm onSubmit={formik.handleSubmit}>
          <InputWrapper>
            <LabeledFormInput
              title={t("Email Id")}
              placeholder={t("Email Id")}
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
            />
          </InputWrapper>
          <BtnWrapper>
            <Btn1
              className={clsx(isLoading && "disabled")}
              disabled={isLoading || !formik.isValid}
              type="submit"
              text={t(!isLoading ? "Send Email" : "Please wait...")}
            />
          </BtnWrapper>
        </StyledForm>
      )}
    </>
  );
}
