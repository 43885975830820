import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Local Library Imports
import Print from "./print";
import ExamData from "./ExamData";
import General from "./General";
import useAuth from "../../../hooks/useAuth";
import ImportantDates from "./ImportantDates";
import { isIterable } from "../../../utils/common";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import {
  getExaminerTypeList,
  getExaminerNameList,
} from "../../../services/schools";
import ErrorTabItem from "../../../components/UI/ErrorTabItem";
import { masterReference } from "../../../constants/masterData";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { toTitleCase } from "../../../utils/utility";

const Institute = () => {
  const { notify } = useNotistack();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    printRecord,
    setPrintRecord,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const initialValues = {
    course: {
      id: 0,
      name: "",
      start_time: dayjs(),
      end_time: dayjs().add(1, "day"),
    },
    important_dates: [
      {
        id: 0,
        start_date: null,
        end_date: null,
        description: "",
      },
    ],
    exam_data: [
      // {
      //   exam_name: "",
      //   exam_type: "",
      //   attempts: [
      //     {
      //       id: 0,
      //       start_time: dayjs(),
      //       end_time: dayjs().add(1, "day"),
      //       examiner_1_type: "",
      //       examiner_1: "",
      //       examiner_2_type: "",
      //       examiner_2: "",
      //       examiner_3_type: "",
      //       examiner_3: "",
      //     },
      //   ],
      // },
    ],
    delete_important_dates: [],
    delete_exam_data: [],
  };

  const [printData, setPrintData] = useState({});
  const [data, setData] = useState(initialValues);
  const [examinerTypeList, setExaminerTypeList] = useState([]);
  const [examinerNameList, setExaminerNameList] = useState([
    { attempts: [{ examiner1: [], examiner2: [], examiner3: [] }] },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex } = useRecordSelection();

  const validateSchema = Yup.object().shape({
    course: Yup.object().shape({
      name: Yup.string().required("REQUIRED"),
      start_time: Yup.date()
        .required("Start Date is required")
        .test(
          "not-equal",
          "Start Date and End Date cannot be equal",
          function (value) {
            if (dayjs(value).isSame(dayjs(this.parent.end_time))) return false;
            return true;
          }
        )
        .test(
          "not-greater",
          "Start Date must be before End Date",
          function (value) {
            if (dayjs(value).isAfter(dayjs(this.parent.end_time))) return false;
            return true;
          }
        ),
      end_time: Yup.date()
        .required("End Date is required")
        .test(
          "not-equal",
          "Start Date and End Date cannot be equal",
          function (value) {
            if (dayjs(value).isSame(dayjs(this.parent.start_time)))
              return false;
            return true;
          }
        )
        .test(
          "not-lesser",
          "End Date must be after Start Date",
          function (value) {
            if (dayjs(this.parent.start_time).isAfter(dayjs(value)))
              return false;
            return true;
          }
        ),
    }),
    important_dates: Yup.array().of(
      Yup.object().shape({
        start_date: Yup.string()
          .nullable()
          .test("required-if-any", "REQUIRED", function (value) {
            const { end_date, description } = this.parent;
            if (!!end_date || !!description) {
              return !!value;
            }
            return true;
          })
          .test(
            "not-equal",
            "Start Date and End Date cannot be equal",
            function (value) {
              if (
                value &&
                this.parent.end_date &&
                dayjs(value).isSame(dayjs(this.parent.end_date))
              ) {
                return false;
              }
              return true;
            }
          )
          .test(
            "not-greater",
            "Start Date must be before End Date",
            function (value) {
              if (
                value &&
                this.parent.end_date &&
                dayjs(value).isAfter(dayjs(this.parent.end_date))
              ) {
                return false;
              }
              return true;
            }
          ),
        end_date: Yup.string()
          .nullable()
          .test("required-if-any", "REQUIRED", function (value) {
            const { start_date, description } = this.parent;
            if (!!start_date || !!description) {
              return !!value;
            }
            return true;
          })
          .test(
            "not-equal",
            "Start Date and End Date cannot be equal",
            function (value) {
              if (
                value &&
                this.parent.start_date &&
                dayjs(value).isSame(dayjs(this.parent.start_date))
              ) {
                return false;
              }
              return true;
            }
          )
          .test(
            "not-lesser",
            "End Date must be after Start Date",
            function (value) {
              if (
                value &&
                this.parent.start_date &&
                dayjs(this.parent.start_date).isAfter(dayjs(value))
              ) {
                return false;
              }
              return true;
            }
          ),
        description: Yup.string()
          .nullable()
          .test("required-if-any", "REQUIRED", function (value) {
            const { start_date, end_date } = this.parent;
            if (!!start_date || !!end_date) {
              return !!value;
            }
            return true;
          }),
      })
    ),
    exam_data: Yup.array().of(
      Yup.object().shape({
        exam_type: Yup.string().required("REQUIRED"),
        exam_name: Yup.string().required("REQUIRED"),
        attempts: Yup.array().of(
          Yup.object().shape({
            start_time: Yup.string()
              .required("Start Date is required")
              .test(
                "not-equal",
                "Start Date and End Date cannot be equal",
                function (value) {
                  if (dayjs(value).isSame(dayjs(this.parent.end_time)))
                    return false;
                  return true;
                }
              )
              .test(
                "not-greater",
                "Start Date must be before End Date",
                function (value) {
                  if (dayjs(value).isAfter(dayjs(this.parent.end_time)))
                    return false;
                  return true;
                }
              ),
            end_time: Yup.string()
              .required("End Date is required")
              .test(
                "not-equal",
                "Start Date and End Date cannot be equal",
                function (value) {
                  if (dayjs(value).isSame(dayjs(this.parent.start_time)))
                    return false;
                  return true;
                }
              )
              .test(
                "not-lesser",
                "End Date must be after Start Date",
                function (value) {
                  if (dayjs(this.parent.start_time).isAfter(dayjs(value)))
                    return false;
                  return true;
                }
              ),
            examiner_1_type: Yup.string().required("REQUIRED"),
            examiner_1: Yup.string().required("REQUIRED"),
          })
        ),
      })
    ),
  });

  useEffect(() => {
    // Initialize form data based on selectedRecord
    if (selectedRecord && selectedRecord.course_detail?.length > 0) {
      let selectedData = {
        course: {
          id: 0,
          name: "",
          start_time: dayjs(),
          end_time: dayjs(),
        },
        important_dates: [],
        exam_data: [],
        delete_important_dates: [],
        delete_exam_data: [],
      };

      // Populate selectedData with values from selectedRecord
      selectedData["course"]["id"] = selectedRecord.course_detail[0]?.id;
      selectedData["course"]["name"] = selectedRecord.course_detail[0]?.name;
      selectedData["course"]["start_time"] = dayjs(
        selectedRecord.course_detail[0]?.start_time
      );
      selectedData["course"]["end_time"] = dayjs(
        selectedRecord.course_detail[0]?.end_time
      );
      let updatedImportantDates = selectedRecord.important_dates?.map(
        (item) => ({
          ...item,
          start_date: dayjs(item.start_date),
          end_date: dayjs(item.end_date),
        })
      );
      if (selectedRecord.important_dates.length == 0) {
        updatedImportantDates = [
          {
            id: 0,
            start_date: null,
            end_date: null,
            description: "",
          },
        ];
      }
      selectedData["important_dates"] = updatedImportantDates;

      const updatedExamData = selectedRecord.exam_data?.map((course) => ({
        ...course,
        attempts: course.attempts.map((attempt) => ({
          ...attempt,
          start_time: dayjs(attempt.start_time),
          end_time: dayjs(attempt.end_time),
        })),
      }));
      selectedData["exam_data"] = updatedExamData;
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER
      ) {
        setEditable(true);
      }
      setPrintData(selectedData);

      console.log("selectedData.......................", selectedData);
    } else {
      // Reset the form when no selectedRecord is available
      formik.resetForm();
    }
  }, [selectedRecord]);

  useEffect(() => {
    // Update formik values when data changes
    formik.setValues(data);
  }, [data]);

  // to get Examiner Type list from list api
  useEffect(() => {
    getExaminerTypeList()
      .then((res) => {
        if (res.isOk) {
          res.data.forEach((ele) => {
            ele.label = toTitleCase(ele.label);
          });
          setExaminerTypeList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    getExaminerNameList()
      .then((res) => {
        if (res.isOk) {
          // titlecase  dropdown options
          for (const obj in res.data) {
            res.data[obj].forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
          }

          setExaminerNameList(res.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let exam_data = values.exam_data.map((item, index) => {
      let examAttempt = item.attempts.map((attempt) => {
        return {
          id: attempt.id,
          start_time: attempt.start_time.format("YYYY-MM-DD"),
          end_time: attempt.end_time.format("YYYY-MM-DD"),
          examiner_1: attempt.examiner_1,
          examiner_2: attempt.examiner_2,
          examiner_3: attempt.examiner_3,
        };
      });
      return {
        exam_name: item.exam_name,
        exam_type: item.exam_type,
        attempts: examAttempt,
      };
    });
    console.warn("values are >>>> ", values);
    let submittingData = {
      course: {
        id: values.course.id,
        name: values.course.name,
        start_time: values.course.start_time.format("YYYY-MM-DD"),
        end_time: values.course.end_time.format("YYYY-MM-DD"),
      },
      important_dates: values.important_dates.map((item, index) => {
        return item.start_date && item.end_date && item.description
          ? {
              id: item.id,
              start_date: item.start_date
                ? item.start_date.format("YYYY-MM-DD")
                : "",
              end_date: item.end_date ? item.end_date.format("YYYY-MM-DD") : "",
              description: item.description,
            }
          : {};
      }),
      exam_data: exam_data,
      delete_important_dates: values.delete_important_dates,
      delete_exam_data: values.delete_exam_data,
    };
    console.log("submitting data---------------------", submittingData);

    let requestParams = { id: requestId, data: submittingData };
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setEditable(true);
      await setRequestId(null);
      setRequestId(requestId ? requestParams.id : res.data.local_course_id);
      setSelectedIndex(requestId ? requestId : res.data.local_course_id);

      if (requestId) {
        notify("Course Information Updated", "success");
      } else {
        notify("Course Added", "success");
      }
      if (user?.step?.goal_flow_count <= 0) {
        window.location.href = `/${user.company_url}/${user.custom_url}/steps`;
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object" && isIterable(value)) {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleFormChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
    setIsDirty(true);
  };

  const handlePrintClose = () => {
    setPrintRecord(false);
  };

  return (
    <>
      <Print
        open={printRecord}
        onClose={handlePrintClose}
        printData={printData}
        examinerTypeList={examinerTypeList}
        examinerNameList={examinerNameList}
      />
      <OverlayLoader show={isLoading}>
        <form onSubmit={formik.handleSubmit}>
          <StyledTab
            value={activeTab}
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: colors.secondary,
              },
            }}
          >
            <ErrorTabItem
              label={t("General")}
              {...a11yProps(0)}
              error={
                (formik.touched.course?.name && formik.errors.course?.name) ||
                (formik.touched.course?.start_time &&
                  formik.errors.course?.start_time) ||
                (formik.touched.course?.end_time &&
                  formik.errors.course?.end_time)
              }
            />

            <ErrorTabItem
              label={t("Important Dates")}
              {...a11yProps(1)}
              error={
                formik.touched.important_dates && formik.errors.important_dates
              }
            />

            <ErrorTabItem
              label={t("Exam Data")}
              {...a11yProps(2)}
              error={formik.touched.exam_data && formik.errors.exam_data}
            />
          </StyledTab>

          <div
            style={{ padding: "24px", display: activeTab == "0" ? "" : "none" }}
            id="simple-tabpanel-0"
            aria-labelledby="simple-tab-0"
          >
            <General formik={formik} handleFormChange={handleFormChange} />
          </div>

          <div
            style={{ padding: "24px", display: activeTab == "1" ? "" : "none" }}
            id="simple-tabpanel-1"
            aria-labelledby="simple-tab-1"
          >
            <ImportantDates formik={formik} />
          </div>

          <div
            style={{ padding: "24px", display: activeTab == "2" ? "" : "none" }}
            id="simple-tabpanel-2"
            aria-labelledby="simple-tab-2"
          >
            <ExamData
              formik={formik}
              examinerTypeList={examinerTypeList}
              examinerNameList={examinerNameList}
            />
          </div>
        </form>
      </OverlayLoader>
    </>
  );
};

export default Institute;
