import React from "react";
import Page from "../layout/Page";
import CompaniesList from "../widgets/CompaniesList";

export default function CompaniesListPage() {
  return (
    <>
      <Page title="Companies">
        <CompaniesList title="Companies" />
      </Page>
    </>
  );
}
