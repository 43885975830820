import React from "react";
import styled from "styled-components";
import { breakpoints, colors, flex } from "../../styles/vars";
import ChangePasswordForm from "./Form";
import { masterReference } from "../../constants/masterData";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px;
  ${flex.col}
`;

const AdminContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 60%;
  padding: 15px;
`;
const StyledHeader = styled.h1`
  color: ${colors.dark};
`;

const StepWrapper = styled.div`
  && {
    width: 30%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default function ChangePasswordFormContainer(props) {
  return (
      <Container>
        <StepWrapper>
          <ChangePasswordForm />
        </StepWrapper>
      </Container>
  )
}
