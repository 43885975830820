import React from "react";
import Page from "../layout/Page";
import SchoolsList from "../widgets/SchoolsList";

export default function SchoolsPage() {
  return (
    <>
      <Page title="Schools">
        <SchoolsList title="Schools" />
      </Page>
    </>
  );
}
