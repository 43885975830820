import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import { useNavigate } from "react-router-dom";
import StepLabel from "@mui/material/StepLabel";

import UserForm from "./userForm";
import CompanyForm from "./companyForm";
import { emailRegex } from "../../constants/vars";
import { checkEmailApi } from "../../services/auth";
import useNotistack from "../../hooks/useNotistack";
import useWindowSize from "../../hooks/useWindowSize";
import { addCompanyApi } from "../../services/companies";
import { convertJSONToFormData } from "../../utils/utility";
import { masterReference } from "../../constants/masterData";

const AddCompanyForm = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const companyInitialValues = {
    name: "",
    email: "",
    phone_no: "",
    address_line_1: "",
    address_line_2: "",
    state: "",
    city: "",
    postal_code: "",
    country: "",
    address_type: "",
    address_id: null,
    email_id: null,
    mobile_id: null,
    logo: "",
  };

  const CompanyValidationSchema = yup.object({
    name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(value, masterReference.COMPANY, 0, 0)
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
    // phone_no: yup.string().required(),
    address_line_1: yup.string().required("REQUIRED"),
    city: yup.string().required("REQUIRED"),
    state: yup.string().required("REQUIRED"),
    postal_code: yup.string().required("REQUIRED"),
    country: yup.string().required("REQUIRED"),
  });

  const companyFormik = useFormik({
    initialValues: companyInitialValues,
    validateOnChange: true,
    validationSchema: CompanyValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setFormData((prevState) => ({
        ...prevState,
        name: values.name,
        address: [
          {
            address_id: values.address_id || 0,
            address_line_1: values.address_line_1,
            address_line_2: values.address_line_2,
            address_type: "current",
            postal_code: values.postal_code,
            city: values.city,
            state: values.state,
            country: values.country,
          },
        ],
        contact: [
          {
            contact_id: values.email_id || 0,
            contact_type: "email",
            value: values.email,
          },
          {
            contact_id: values.mobile_id || 0,
            contact_type: "phone",
            value: values.phone_no.replace("-", "").replace(/\s+/g, ""),
          },
        ],
        logo: values.logo,
      }));
      setActiveStep(1);
      setIsLoading(false);
    },
  });

  const userInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
  };

  const UserValidationSchema = yup.object({
    first_name: yup.string().required("REQUIRED"),
    last_name: yup.string().required("REQUIRED"),
    email: yup
      .string()
      .required("REQUIRED")
      .email("Email must be valid")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              checkEmailApi(value, masterReference.USER, 0, 0, 0, "company")
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      ),
  });

  const userFormik = useFormik({
    initialValues: userInitialValues,
    validateOnChange: true,
    validationSchema: UserValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      let submittingData = {
        ...formData,
        user: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone_no: values.phone_no,
        },
      };
      console.log("submittingData", submittingData);
      submittingData = convertJSONToFormData(submittingData);
      let res = await addCompanyApi(submittingData);
      if (res.status === 201) {
        resetForm();
        notify("Company Infomation Added.", "success");
        navigate("/companies");
      } else {
        for (const [key, value] of Object.entries(res.error)) {
          if (typeof value === "object") {
            value.forEach((obj) => {
              for (const key2 in obj) {
                if (obj.hasOwnProperty(key2)) {
                  const value2 = obj[key2];
                  for (let i in value2) {
                    notify(`${key2}: ${value2[i]}`, "error");
                  }
                }
              }
            });
          } else {
            notify(`${key}: ${value}`, "error");
          }
        }
      }
      setIsLoading(false);
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} style={{ marginBottom: "20px" }}>
        <Step>
          <StepLabel>{"Company Details"}</StepLabel>
        </Step>
        <Step>
          <StepLabel>{"Admin Details"}</StepLabel>
        </Step>
      </Stepper>
      <form
        onSubmit={
          activeStep === 0
            ? companyFormik.handleSubmit
            : userFormik.handleSubmit
        }
      >
        {activeStep === 0 ? (
          <CompanyForm
            isLoading={isLoading}
            isMobile={isMobile}
            formik={companyFormik}
          />
        ) : (
          <UserForm
            isLoading={isLoading}
            isMobile={isMobile}
            formik={userFormik}
          />
        )}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            type="button"
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button type="submit" variant="contained">
            {activeStep === 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddCompanyForm;
