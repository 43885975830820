import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import { Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

import InfoLabel from "../../../components/UI/InfoLabel";
import { Content, Wrapper, Heading } from "../../../components/style/style";
import { colors } from "../../../constants/colors";
import { dateFormFormat } from "../../../utils/dates";
import useAuth from "../../../hooks/useAuth";
import { toTitleCase } from "../../../utils/utility";

const Print = ({
  open,
  onClose,
  printData,
  examinerTypeList,
  examinerNameList,
}) => {
  const [data, setData] = useState({});
  const [printSection, setPrintSection] = useState({
    general: true,
    important_dates: true,
    exam_data: true,
  });
  const [edit, setEdit] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  let componentRef = useRef();
  const { user } = useAuth();

  useEffect(() => {
    setData(printData);
    setPrintSection({
      general: true,
      important_dates: true,
      exam_data: true,
    });
  }, [printData, open]);

  const handleClose = () => {
    onClose();
  };

  const checkboxChange = (tabs) => {
    const section = { ...printSection };
    tabs.forEach((tab) => {
      section[`${tab}`] = !printSection[`${tab}`];
    });
    setPrintSection(section);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle>Select Sections to Print</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: colors.red,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Wrapper>
          <Content component="div">
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.general}
                      onChange={() => checkboxChange(["general"])}
                    />
                  }
                  label="General"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.important_dates}
                      onChange={() => checkboxChange(["important_dates"])}
                    />
                  }
                  label="Employer Details"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.exam_data}
                      onChange={() => checkboxChange(["exam_data"])}
                    />
                  }
                  label="Exam Data"
                />
              </Grid>
            </Grid>
          </Content>
        </Wrapper>
        <div style={{ display: "none" }}>
          <Content component="div" ref={(el) => (componentRef = el)}>
            <center>
              <Heading>
                {`Course (${toTitleCase(data?.course?.name)})`}
                <br />
                {`Company - ${toTitleCase(user.company_name)}`}
                <br />
                {user.entity_type === "school"
                  ? `School - ${toTitleCase(user.entity_name)}`
                  : user.entity_type === "foreign_institute"
                  ? `Foreign Institute - ${toTitleCase(user.entity_name)}`
                  : `Hiring Agency - ${toTitleCase(user.entity_name)}`}
              </Heading>
            </center>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Wrapper style={{ padding: "12px" }}>
                  <Heading>General</Heading>
                  <Grid container spacing={2}>
                    {data.course?.name && printSection.general && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel label={"Name"} info={data.course?.name} />
                      </Grid>
                    )}

                    {data.course?.start_time && printSection.general && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel
                          label={"Start Date"}
                          info={dateFormFormat(data.course?.start_time)}
                        />
                      </Grid>
                    )}

                    {data.course?.end_time && printSection.general && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel
                          label={"End Date"}
                          info={dateFormFormat(data.course?.end_time)}
                        />
                      </Grid>
                    )}

                    {data.important_dates?.[0]?.id !== 0 &&
                      printSection.important_dates && (
                        <Grid item xs={12} sm={12}>
                          <Wrapper>
                            <Heading>Important Dates</Heading>
                            <Content component={"div"}>
                              {data.important_dates?.map((item, index) => (
                                <Grid container>
                                  {item.start_date && (
                                    <Grid item xs={12} sm={4}>
                                      <InfoLabel
                                        label={"Start Date"}
                                        info={dateFormFormat(item.start_date)}
                                      />
                                    </Grid>
                                  )}

                                  {item.end_date && (
                                    <Grid item xs={12} sm={4}>
                                      <InfoLabel
                                        label={"End Date"}
                                        info={dateFormFormat(item.end_date)}
                                      />
                                    </Grid>
                                  )}

                                  {item.description && (
                                    <Grid item xs={12} sm={4}>
                                      <InfoLabel
                                        label={"Description"}
                                        info={item.description}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                            </Content>
                          </Wrapper>
                        </Grid>
                      )}

                    {data.exam_data &&
                      printSection.exam_data &&
                      data.exam_data.map((item, index) => (
                        <Grid item xs={12} sm={12}>
                          <Wrapper>
                            <Heading>Exam {index + 1}</Heading>
                            <Content component={"div"}>
                              <Grid container>
                                {item.exam_type && (
                                  <Grid item xs={12} sm={4}>
                                    <InfoLabel
                                      label={"Exam Type"}
                                      info={item.exam_type}
                                    />
                                  </Grid>
                                )}
                                {item.exam_name && (
                                  <Grid item xs={12} sm={4}>
                                    <InfoLabel
                                      label={"Exam Name"}
                                      info={item.exam_name}
                                    />
                                  </Grid>
                                )}
                                {item.attempts &&
                                  item.attempts.map((attempt, attemptIndex) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      key={`attempt${attemptIndex}`}
                                    >
                                      <Wrapper>
                                        <Heading>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <p>Attempt {attemptIndex + 1}</p>
                                          </div>
                                        </Heading>
                                        <Content component={"div"}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                              <InfoLabel
                                                label={"Start Date"}
                                                info={dateFormFormat(
                                                  attempt.start_time
                                                )}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <InfoLabel
                                                label={"End Date"}
                                                info={dateFormFormat(
                                                  attempt.end_time
                                                )}
                                              />
                                            </Grid>
                                            {attempt.examiner_1 &&
                                            examinerNameList[
                                              attempt.examiner_1_type
                                            ] ? (
                                              <Grid item xs={12} sm={4}>
                                                <InfoLabel
                                                  label={"Examiner 1"}
                                                  info={`${
                                                    examinerNameList[
                                                      attempt.examiner_1_type
                                                    ].find(
                                                      (option) =>
                                                        option.value ===
                                                        attempt.examiner_1
                                                    )?.label
                                                  },
                                                                                                ${
                                                                                                  examinerTypeList.find(
                                                                                                    (
                                                                                                      option
                                                                                                    ) =>
                                                                                                      option.value ===
                                                                                                      attempt.examiner_1_type
                                                                                                  )
                                                                                                    ?.label
                                                                                                }`}
                                                />
                                              </Grid>
                                            ) : null}
                                            {attempt.examiner_2 &&
                                            examinerNameList[
                                              attempt.examiner_2_type
                                            ] ? (
                                              <Grid item xs={12} sm={4}>
                                                <InfoLabel
                                                  label={"Examiner 2"}
                                                  info={`${
                                                    examinerNameList[
                                                      attempt.examiner_2_type
                                                    ].find(
                                                      (option) =>
                                                        option.value ===
                                                        attempt.examiner_2
                                                    )?.label
                                                  },
                                                                                            ${
                                                                                              examinerTypeList.find(
                                                                                                (
                                                                                                  option
                                                                                                ) =>
                                                                                                  option.value ===
                                                                                                  attempt.examiner_2_type
                                                                                              )
                                                                                                ?.label
                                                                                            }`}
                                                />
                                              </Grid>
                                            ) : null}
                                            {attempt.examiner_3 &&
                                            examinerNameList[
                                              attempt.examiner_3_type
                                            ] ? (
                                              <Grid item xs={12} sm={4}>
                                                <InfoLabel
                                                  label={"Examiner 3"}
                                                  info={`${
                                                    examinerNameList[
                                                      attempt.examiner_3_type
                                                    ].find(
                                                      (option) =>
                                                        option.value ===
                                                        attempt.examiner_3
                                                    )?.label
                                                  },
                                                                                            ${
                                                                                              examinerTypeList.find(
                                                                                                (
                                                                                                  option
                                                                                                ) =>
                                                                                                  option.value ===
                                                                                                  attempt.examiner_3_type
                                                                                              )
                                                                                                ?.label
                                                                                            }`}
                                                />
                                              </Grid>
                                            ) : null}
                                          </Grid>
                                        </Content>
                                      </Wrapper>
                                    </Grid>
                                  ))}
                              </Grid>
                            </Content>
                          </Wrapper>
                        </Grid>
                      ))}
                  </Grid>
                </Wrapper>
              </Grid>
            </Grid>
          </Content>
        </div>
      </DialogContent>
      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Button
              onClick={() => setEdit(false)}
              variant="contained"
              startIcon={<PrintIcon />}
              disabled={
                !printSection.general &&
                !printSection.important_dates &&
                !printSection.exam_data
              }
            >
              Print
            </Button>
          )}
          content={() => componentRef}
          documentTitle={`(Course).pdf`}
          onBeforeGetContent={() => setEdit(false)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Print;
