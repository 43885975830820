import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/images/login-background.jpg";
import FormContainer from "../widgets/LoginForm";
import { colors } from "../constants/colors";
const RootWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  // height:50vh,
  min-height: 100vh;
  // background-size: cover;
  background-repeat: none;
  background-color: ${colors.background};
  // background-image: url(${backgroundImage});
`;

export default function Login() {
  return (
    <RootWrapper>
      <FormContainer />
    </RootWrapper>
  );
}
