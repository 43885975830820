import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { styled } from "styled-components";
import { Wrap } from "../../style/style";

// Create a styled TextField component
const StyledTextField = styled(TextField)``;

// Define a functional component for InputField
const InputField = ({
  id,
  label,
  value = "",
  required = false,
  variant = "outlined",
  width = "",
  border = " ",
  placeholder = "",
  helperText, // Add helperText prop
  error, // Add error prop
  ...restProps
}) => {
  // Generate the title for the field, indicating if it's required
  const title = `${label}${required ? "*" : ""}`;
  const errorMsg = helperText !== "REQUIRED" ? helperText : "";

  return (
    <Wrap noValidate autoComplete="off">
      <StyledTextField
        id={id}
        label={title}
        variant={variant}
        size="small"
        value={value}
        placeholder={placeholder}
        helperText={errorMsg} // Use the helperText prop here
        error={error} // Use the error prop here
        {...restProps}
        style={{
          width: `${width ? width : "90%"}`,
          border: border ? border : "",
        }}
      />
    </Wrap>
  );
};

// Define propTypes to specify the expected types for props
InputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default InputField;
