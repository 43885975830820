// colors used throughout the application

export const colors = {
  nav: "#fff", // nav background color
  primary: "#314455", // for elements color
  secondary: "#0b4396", // for elements backgroud color
  secondaryList: "#b4d0fb99", // for elements backgroud color
  border: "#ccc", // for all border color
  background: "#ebedf0", // for background color of sections
  dark: "#000", // for black
  white: "#fff", // for white
  primarySelected: "#0b4396", // for selected item(from the sidebar list )
  red: "#ea4335", // for delete cross button
  lightRed: '#ea353540', // for delete cross button background
  addBtn: '#006bb3', // dark blue color for add btn
  suscess: '#66bb6a',
};
