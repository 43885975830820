import styled from 'styled-components';
import Btn1 from '../../components/UI/Btn1';
import { flex, textSizes, breakpoints } from '../../styles/vars';

export const Wrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    ${flex.end}
    margin-bottom: 10px;
  }
`;

export const AddButton = styled(Btn1)`
  display: none;
  ${breakpoints.laptop} {
    display: block;
    width: 150px;
  }
`;
