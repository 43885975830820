import React from "react";
import Page from "../layout/Page";
import HiringAgenciesList from "../widgets/HiringAgenciesList";

export default function HiringAgenciesPage() {
  return (
    <>
      <Page title="Hiring Agencies">
        <HiringAgenciesList title="Hiring Agencies" />
      </Page>
    </>
  );
}
