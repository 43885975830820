import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";

import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { colors } from "../../../constants/colors";
import InputField from "../../../components/UI/InputField";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";

// General component receives 'formik' as a prop
const Goals = ({ formik }) => {
  // Get the isEditable flag from a custom hook
  console.log(formik.values.goals);
  console.log(formik.values.name);
  console.log(formik.values.address);
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addFields = () => {
    let oldFields = [...formik.values.goals];
    oldFields.push({
      id: 0,
      goal_name: "",
    });
    formik.setFieldValue(`goals`, oldFields);
  };

  const removeFields = (index, id) => {
    let oldFields = [...formik.values.goals];
    let oldDeleteGoalData = [...formik.values.delete_goals];
    if (id > 0) {
      oldDeleteGoalData.push(id);
      formik.setFieldValue(`delete_goals`, oldDeleteGoalData);
    }
    oldFields.splice(index, 1);
    formik.setFieldValue(`goals`, oldFields);
  };

  return (
    <Grid container style={{ marginTop: "11px" }}>
      {formik.values.goals.map((goal, index) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <InputField
                width="100%"
                label={"Goal " + (index + 1)}
                value={goal?.goal_name}
                onChange={(event) => {
                  formik.setFieldValue(
                    `goals.${index}.goal_name`,
                    event.target.value.replace(/^\s+/g, "")
                  );
                  setIsDirty(true);
                }}
                placeholder={index === 0 ? "Ex: Nursing": ""}
                InputLabelProps={{ shrink: true }}
                sx={{ marginLeft: "-4px" }}
                required
                error={
                  formik.touched.goals &&
                  formik.touched.goals[index] &&
                  Boolean(formik.errors.goals?.[index]?.goal_name)
                }
              />
              {/* {formik.touched.goals && formik.touched.goals[index] && formik.errors.goals?.[index]?.goal_name && (
                    <InputError>
                        {`Goal ${index + 1} is a required field`}
                    </InputError>
                )} */}
            </Grid>
            {formik.values.goals.length > 1 && (
              <Grid
                item
                sm={1}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "10px",
                  paddingLeft: "0px",
                }}
              >
                <IconButton
                  onClick={() =>
                    goal.goal_count
                      ? handleClickOpen()
                      : removeFields(index, goal.id)
                  }
                  style={{ marginLeft: "auto" }}
                >
                  <Delete color="error" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Fab
          size="small"
          sx={{
            color: "white",
            backgroundColor: colors.addBtn,
            bottom: "-1vh",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: colors.addBtn,
            },
            marginLeft: "20px",
          }}
          onClick={addFields}
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ pb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            You are trying to delete a goal which is mapped to an applicant or a
            student. Please remove the mapping for this goal and try again
            later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Goals;
