import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";

// Local Library Imports
import General from "./General";
import { isIterable } from "../../../utils/common";
import useNotistack from "../../../hooks/useNotistack";
import { getLessonList } from "../../../services/schools";
import { convertJSONToFormData } from "../../../utils/utility";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { masterReference } from "../../../constants/masterData";
import useAuth from "../../../hooks/useAuth";

const Lessons = () => {
  const { user } = useAuth();
  const { notify } = useNotistack();
  // for getting submiiting api value , triggering formik form submit, and change formik form value
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  const initialValues = {
    lessons: [
      {
        id: 0,
        lesson_name: "Lesson 1",
        start_time: "",
        end_time: "",
      },
    ],
    deleted_lessons: [],
  };

  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedRecord, setSelectedIndex, setSelectedRecord } =
    useRecordSelection();

  let endTime = dayjs().startOf("day");
  const validateSchema = Yup.object().shape({
    lessons: Yup.array().of(
      Yup.object().shape({
        lesson_name: Yup.string().required("Lesson Name is required"),
        start_time: Yup.string()
          .required("Start Time is required")
          .test(
            "not-equal",
            "Start Time and End Time cannot be equal",
            function (value) {
              if (dayjs(value).isSame(dayjs(this.parent.end_time)))
                return false;
              return true;
            }
          )
          .test(
            "not-greater",
            "Start Time must be before End Time",
            function (value) {
              if (dayjs(value).isAfter(dayjs(this.parent.end_time)))
                return false;
              return true;
            }
          )
          .test(
            "greater-than-previous-end",
            "Start Time must be greater than or equal to above lesson's End Time",
            function (value) {
              console.log(endTime);
              const prevEndTime = dayjs(this.parent.end_time);
              console.log(prevEndTime);
              console.log(endTime);
              if (dayjs(value).isBefore(endTime)) {
                console.log("hello");
                return false;
              }
              endTime = prevEndTime;
              console.log(endTime);
              return true;
            }
          ),
        end_time: Yup.string()
          .required("End Time is required")
          .test(
            "not-equal",
            "Start Time and End Time cannot be equal",
            function (value) {
              if (dayjs(value).isSame(dayjs(this.parent.start_time)))
                return false;
              return true;
            }
          )
          .test(
            "not-lesser",
            "End Time must be after Start Time",
            function (value) {
              if (dayjs(this.parent.start_time).isAfter(dayjs(value)))
                return false;
              return true;
            }
          ),
      })
    ),
  });

  // list api call to get lessons list
  useEffect(() => {
    let requestParams = {
      page: 1,
      limit: 20,
      q: "",
    };
    getLessonList(requestParams)
      .then((res) => {
        if (res.isOk) {
          // setNoOfPage(Math.ceil(res.total/pageLimit));
          if (!requestId) {
            setRequestId(res.data[0]?.id);
            setSelectedRecord(res.data);
            if (res.data.length) {
              setEditable(false);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]);
      });
  }, [requestId]);

  useEffect(() => {
    let selectedData = {
      lessons: [
        {
          id: 0,
          lesson_name: "Lesson 1",
          start_time: "",
          end_time: "",
        },
      ],
      deleted_lessons: [],
    };
    if (selectedRecord && selectedRecord.length > 0) {
      selectedData.lessons = selectedRecord.map((lesson) => {
        lesson.start_time = dayjs(lesson.start_time, "HH:mm:ss"); // Change it to time component
        lesson.end_time = dayjs(lesson.end_time, "HH:mm:ss"); // Change it to time component
        return lesson;
      });
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER
      ) {
        setEditable(true);
        setFormSubmitRequested(false);
      }
    } else {
      setData(initialValues);
      formik.resetForm();
    }
  }, [selectedRecord, requestId]);

  useEffect(() => {
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let submittingData = {
      lessons: values.lessons.map((lesson) => {
        lesson.start_time = lesson.start_time.format("HH:mm:ss"); // Change this to the desired value
        lesson.end_time = lesson.end_time.format("HH:mm:ss"); // Change this to the desired value
        return lesson;
      }),
      deleted_lessons: values.deleted_lessons,
    };

    let requestParams = { data: convertJSONToFormData(submittingData) };
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      await setRequestId(null);
      setRequestId(requestId);
      setSelectedIndex(requestId);

      if (requestId) {
        notify("Course Information Updated", "success");
      } else {
        notify("Course Added", "success");
      }
    } else {
      for (const [key, value] of Object.entries(res.error)) {
        if (typeof value === "object" && isIterable(value)) {
          value.forEach((obj) => {
            for (const key2 in obj) {
              if (obj.hasOwnProperty(key2)) {
                const value2 = obj[key2];
                for (let i in value2) {
                  notify(`${key2}: ${value2[i]}`, "error");
                }
              }
            }
          });
        } else {
          notify(`${key}: ${value}`, "error");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [isFormSubmitRequested, formik.handleSubmit, setFormSubmitRequested]);

  return (
    <>
      <OverlayLoader show={isLoading}>
        <form onSubmit={formik.handleSubmit} style={{ padding: "24px" }}>
          <General formik={formik} />
        </form>
      </OverlayLoader>
    </>
  );
};

export default Lessons;
