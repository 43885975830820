import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { dateFormFormat } from "../../utils/dates";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const RangePickerContainer = styled.div`
  ${(props) => props.alignment && `text-align: ${props.alignment}`};
  padding: 9px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function DateRangePicker({
  selectedWeek,
  onWeekChange,
  weekStartEndFormat,
  align,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (dates, dateStrings) => {
    if (dates) {
      return onWeekChange(dates);
    }

    onWeekChange(dates);
  };

  function handlePreviousWeek() {
    // Calculate the previous week

    var previousWeekDate = dayjs(
      `${selectedWeek.split("~")[0]}`.replace("/", "-")
    )
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    onWeekChange(previousWeekDate);
  }

  function handleNextWeek() {
    // Calculate the next week

    var nextWeekDate = dayjs(`${selectedWeek.split("~")[0]}`.replace("/", "-"))
      .add(7, "days")
      .format("YYYY-MM-DD");

    onWeekChange(nextWeekDate);
  }

  useEffect(() => {
    selectedWeek.length === 0 ? setIsOpen(true) : setIsOpen(false);
  }, [selectedWeek]);

  return (
    <RangePickerContainer alignment={align}>
      <KeyboardDoubleArrowLeftIcon
        style={{
          marginLeft: "5px",
          display: "flex",
        }}
        onClick={() => {
          handlePreviousWeek();
        }}
      />

      <DatePicker
        value={dayjs(`${selectedWeek.split("~")[1]}`)}
        format={`${dateFormFormat(
          selectedWeek.split("~")[0]
        )} ~ ${dateFormFormat(selectedWeek.split("~")[1])}`}
        picker="week"
        onChange={handleChange}
        inputReadOnly={true}
      />
      <KeyboardDoubleArrowRightIcon
        style={{
          marginLeft: "5px",
          display: "flex",
        }}
        onClick={() => {
          handleNextWeek();
        }}
      />
    </RangePickerContainer>
  );
}

export default DateRangePicker;
