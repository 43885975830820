import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import ReactToPrint from "react-to-print";
import Dialog from "@mui/material/Dialog";
import Checkbox from "@mui/material/Checkbox";
import { Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

import InfoLabel from "../../../components/UI/InfoLabel";
import { Content, Wrapper, Heading } from "../../../components/style/style";
import { colors } from "../../../constants/colors";
import { toTitleCase } from "../../../utils/utility";
import useAuth from "../../../hooks/useAuth";

const Print = ({ open, onClose, printData, entitiesNameList, goalsList }) => {
  const [data, setData] = useState({});
  const [printSection, setPrintSection] = useState({
    general: true,
    contact_information: true,
    report: true,
  });
  const [edit, setEdit] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("xs");
  let componentRef = useRef();

  const { user } = useAuth();

  useEffect(() => {
    setData(printData);
    setPrintSection({
      general: true,
      contact_information: true,
      report: true,
    });
  }, [printData, open]);

  const handleClose = () => {
    onClose();
  };

  const checkboxChange = (tabs) => {
    const section = { ...printSection };
    tabs.forEach((tab) => {
      section[`${tab}`] = !printSection[`${tab}`];
    });
    setPrintSection(section);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle>Select Sections to Print</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: colors.red,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Wrapper>
          <Content component="div">
            <Grid container style={{ padding: 10 }}>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.general}
                      onChange={() => checkboxChange(["general"])}
                    />
                  }
                  label="General"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.contact_information}
                      onChange={() => checkboxChange(["contact_information"])}
                    />
                  }
                  label="Contact Information"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={printSection.report}
                      onChange={() => checkboxChange(["report"])}
                    />
                  }
                  label="Report"
                />
              </Grid>
            </Grid>
          </Content>
        </Wrapper>

        <div style={{ display: "none" }}>
          <Content component="div" ref={(el) => (componentRef = el)}>
            {/* <Wrapper style={{ padding: "12px" }}> */}
            <center>
              <Heading>
                {`Applicant (${toTitleCase(data.first_name)} ${toTitleCase(
                  data.last_name
                )})`}
                <br />
                {`Company - ${toTitleCase(user.company_name)}`}
                <br />
                {user.entity_type === "school"
                  ? `School - ${toTitleCase(user.entity_name)}`
                  : user.entity_type === "foreign_institute"
                  ? `Foreign Institute - ${toTitleCase(user.entity_name)}`
                  : `Hiring Agency - ${toTitleCase(user.entity_name)}`}
              </Heading>
            </center>
            <br></br>
            {/* </Wrapper> */}
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Wrapper style={{ padding: "12px" }}>
                  <Heading>General</Heading>
                  <Grid container spacing={2}>
                    {data.first_name && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel
                          label={"First Name"}
                          info={data.first_name}
                        />
                      </Grid>
                    )}

                    {data.last_name && printSection.general && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InfoLabel label={"Last Name"} info={data.last_name} />
                      </Grid>
                    )}

                    {entitiesNameList.length &&
                    data.entity_type &&
                    printSection.general ? (
                      <Grid item xs={4}>
                        <InfoLabel
                          label={"Entity Type"}
                          info={data.entity_type}
                        />
                      </Grid>
                    ) : null}

                    {entitiesNameList.length &&
                    data.entity_id &&
                    entitiesNameList &&
                    printSection.general ? (
                      <Grid item xs={4}>
                        <InfoLabel
                          label={"Entity"}
                          info={
                            entitiesNameList.find(
                              (option) => option.value === data.entity_id
                            )?.label
                          }
                        />
                      </Grid>
                    ) : null}

                    {goalsList.length &&
                      data.goal_id &&
                      printSection.general && (
                        <Grid item xs={4}>
                          <InfoLabel
                            label={"Goal"}
                            info={
                              goalsList?.find(
                                (option) => option.value === data.goal_id
                              )?.label || goalsList[0]?.label
                            }
                          />
                        </Grid>
                      )}

                    {(data.mobile || data.email) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Contact</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {data.mobile && (
                                <Grid item xs={6} sm={6}>
                                  <InfoLabel
                                    label={"Mobile"}
                                    info={data.mobile}
                                  />
                                </Grid>
                              )}
                              {data.email && (
                                <Grid item xs={6} sm={6}>
                                  <InfoLabel
                                    label={"Email"}
                                    info={data.email}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.address_line_1 ||
                      data.address_line_2 ||
                      data.postal_code ||
                      data.city ||
                      data.state ||
                      data.country) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Current Address</Heading>
                          <Content component={"div"}>
                            <Grid container>
                              {data.address_line_1 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 1"}
                                    info={data.address_line_1}
                                  />
                                </Grid>
                              )}

                              {data.address_line_2 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 2"}
                                    info={data.address_line_2}
                                  />
                                </Grid>
                              )}

                              {data.postal_code && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Postal Code"}
                                    info={data.postal_code}
                                  />
                                </Grid>
                              )}

                              {data.city && (
                                <Grid item sm={4}>
                                  <InfoLabel label={"City"} info={data.city} />
                                </Grid>
                              )}

                              {data.state && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"State"}
                                    info={data.state}
                                  />
                                </Grid>
                              )}

                              {data.country && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Country"}
                                    info={data.country}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {(data.os_address_line_1 ||
                      data.os_address_line_2 ||
                      data.os_postal_code ||
                      data.os_city ||
                      data.os_state ||
                      data.os_country) &&
                    printSection.contact_information ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Home Country Address</Heading>
                          <Content component={"div"}>
                            <Grid container>
                              {data.os_address_line_1 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 1"}
                                    info={data.os_address_line_1}
                                  />
                                </Grid>
                              )}

                              {data.os_address_line_2 && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Address Line 2"}
                                    info={data.os_address_line_2}
                                  />
                                </Grid>
                              )}

                              {data.os_postal_code && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Postal Code"}
                                    info={data.os_postal_code}
                                  />
                                </Grid>
                              )}

                              {data.os_city && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"City"}
                                    info={data.os_city}
                                  />
                                </Grid>
                              )}

                              {data.os_state && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"State"}
                                    info={data.os_state}
                                  />
                                </Grid>
                              )}

                              {data.os_country && (
                                <Grid item sm={4}>
                                  <InfoLabel
                                    label={"Country"}
                                    info={data.os_country}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}

                    {data.reports &&
                    data.reports.length &&
                    data.reports[0].comment &&
                    printSection.report ? (
                      <Grid item xs={12} sm={12}>
                        <Wrapper>
                          <Heading>Comment</Heading>
                          <Content component={"div"}>
                            <Grid container spacing={2}>
                              {Object.values(data.reports).map(
                                (item, index) => (
                                  <Grid
                                    item
                                    xs={4}
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InfoLabel
                                      label={`Comment ${index + 1}`}
                                      info={item.comment}
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Content>
                        </Wrapper>
                      </Grid>
                    ) : null}
                  </Grid>
                </Wrapper>
              </Grid>
            </Grid>
          </Content>
        </div>
      </DialogContent>
      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Button
              onClick={() => setEdit(false)}
              variant="contained"
              startIcon={<PrintIcon />}
              disabled={
                !printSection.general && !printSection.contact_information
              }
            >
              Print
            </Button>
          )}
          content={() => componentRef}
          documentTitle={`${toTitleCase(data.first_name)} ${toTitleCase(
            data.last_name
          )} (Applicant).pdf`}
          onBeforeGetContent={() => setEdit(false)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default Print;
