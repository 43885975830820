import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../layout/Page";
import styled from "styled-components";
import CompanyForm from "../widgets/CompanyForm";
import useNotistack from "../hooks/useNotistack";
import useWindowSize from "../hooks/useWindowSize";
import BreadcrumWithTitle from "../widgets/BreadcrumWithTitle";
import { editCompanyApi, getIndividualCompanyApi } from "../services/companies";
import { convertJSONToFormData } from "../utils/utility";

const StepWrapper = styled.div`
  && {
    width: 85%;
    padding: 35px;
    border-radius: 20px;
    border: 1px solid #cfe5f7;
    background: #fff;
    box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;

function EditCompanyForm(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { notify } = useNotistack();
  const { width } = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  let pageTitle = "Edit Company";

  const MOBILE_WIDTH = 765;
  const isMobile = width > 0 && width <= MOBILE_WIDTH;

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getIndividualCompanyApi(id)
        .then((res) => {
          if (res.isOk && res.data) {
            let FinalData = {
              address_id: null,
              name: "",
              address_line_1: "",
              address_line_2: "",
              address_type: "current",
              postal_code: "",
              city: "",
              state: "",
              country: "",
              email_id: null,
              email: "",
              mobile_id: null,
              phone_no: "",
              logo: "",
              delete_logo: "",
            };

            FinalData.name = res.data?.name;

            if (res.data?.address) {
              res.data.address.forEach((adrs) => {
                FinalData.address_id = adrs?.address_id;
                FinalData.address_line_1 = adrs?.address_line_1;
                FinalData.address_line_2 = adrs?.address_line_2;
                FinalData.postal_code = adrs?.postal_code;
                FinalData.city = adrs?.city;
                FinalData.state = adrs?.state;
                FinalData.country = adrs?.country;
              });
            }

            if (res.data?.contact) {
              res.data.contact.forEach((contact) => {
                if (contact.contact_type === "email") {
                  FinalData.email_id = contact?.contact_id;
                  FinalData.email = contact?.value;
                } else if (contact.contact_type === "phone") {
                  FinalData.mobile_id = contact?.contact_id;
                  FinalData.phone_no = contact?.value;
                }
              });
            }
            FinalData.logo = res.data?.logo;
            setInitialValues(FinalData);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <Page title={pageTitle} pageTitleCenter={true}>
        <BreadcrumWithTitle
          path={[
            { pathname: "/companies", title: "Companies" },
            { pathname: "", title: "Edit Company" },
          ]}
          title={pageTitle}
        />
        <StyledDiv>
          <StepWrapper>
            {initialValues !== null && (
              <CompanyForm
                isLoading={isLoading}
                isMobile={isMobile}
                companyId={id}
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                  setIsLoading(true);

                  let submittingData = {
                    name: values.name,
                    address: [
                      {
                        address_id: values.address_id || 0,
                        address_line_1: values.address_line_1,
                        address_line_2: values.address_line_2,
                        address_type: "current",
                        postal_code: values.postal_code,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                      },
                    ],
                    contact: [
                      {
                        contact_id: values.email_id || 0,
                        contact_type: "email",
                        value: values.email,
                      },
                      {
                        contact_id: values.mobile_id || 0,
                        contact_type: "phone",
                        value: values.phone_no
                          .replace("-", "")
                          .replace(/\s+/g, ""),
                      },
                    ],
                    logo: values.logo ? values.logo : null,
                    delete_logo: values.delete_logo,
                  };

                  submittingData = convertJSONToFormData(submittingData);

                  let res = await editCompanyApi(id, submittingData);
                  setIsLoading(false);
                  if (res.isOk) {
                    resetForm();
                    notify("Company Infomation Updated.", "success");
                    navigate("/");
                  } else {
                    for (const [key, value] of Object.entries(res.error)) {
                      if (typeof value === "object") {
                        value.forEach((obj) => {
                          for (const key2 in obj) {
                            if (obj.hasOwnProperty(key2)) {
                              const value2 = obj[key2];
                              for (let i in value2) {
                                notify(`${key2}: ${value2[i]}`, "error");
                              }
                            }
                          }
                        });
                      } else {
                        notify(`${key}: ${value}`, "error");
                      }
                    }
                  }
                }}
              />
            )}
          </StepWrapper>
        </StyledDiv>
      </Page>
    </>
  );
}

export default EditCompanyForm;
