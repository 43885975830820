// assets


// import logo from '../../../assets/images/chiro-mobile.png';

// styling
import styled from 'styled-components';
import { colors, fonts, textSizes, breakpoints } from '../../../styles/vars';
import theme from 'styled-theming';

import useWindowSize from '../../../hooks/useWindowSize';

// components
import { NavLink } from 'react-router-dom';

// utils
import PropTypes from 'prop-types';
import React from 'react';

const Img = styled.img`
  width: 30px;
  height: 30px;
  will-change: transform;
  transition: transform var(--transition);
`;

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 5px;
  text-decoration: none;
  &:hover,
  &:focus {
    color: inherit;
    Img {
      transform: scale(1.2);
    }
  }
`;

const Text = styled.span`
  font-weight: 700;
  font-family: ${fonts.accent};
  font-size: ${textSizes[24]};

  .highlight {
    color: ${colors.blue};
  }

  ${breakpoints.tablet} {
    font-size: ${textSizes[24]};
  }
`;

const Logo = (props, { compact }) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <Wrapper as={NavLink} to='/' className="logo">
      {/* <Img src={logo} alt="WeCareSoft" /> */}
      {!compact ? (
        <Text style={{ display: props.hideText ? 'none' : '' }}>
          WeCareSoft
          {/* Chiro<span className="highlight">Lead</span> */}
        </Text>
      ) : null}
    </Wrapper>
  );
};

Logo.propTypes = {
  compact: PropTypes.bool
};

export default Logo;
