import React from "react";
import { useNavigate } from "react-router-dom";
import Btn1 from "../../components/UI/Btn1";
import { colors } from "../../styles/vars";

const CancelButtonWidget = ({ text = "Cancel", ...props }) => {
  // Hook declaration
  const navigate = useNavigate();

  return (
    <Btn1
      border={colors.smokedGray}
      textcolor={colors.gray}
      text={text}
      type="button"
      color={colors.white}
      handler={() => {
        navigate(-1);
      }}
      {...props}
    />
  );
};

export default CancelButtonWidget;
