import React from 'react';
import { TableHead } from '@mui/material';
import { fonts } from '../../../styles/vars';

const AppTableHead = ({ children, ...rest }) => {
  return (
    <TableHead sx={{ fontFamily: fonts.body, fontWeight: '700 ! important' }} {...rest}>
      {children}
    </TableHead>
  );
};

export default AppTableHead;
