import React from "react";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// Local Library Imports
import DateField from "../../../../components/UI/DateField";
import InfoLabel from "../../../../components/UI/InfoLabel";
import InputField from "../../../../components/UI/InputField";
import { Content, Wrapper } from "../../../../components/style/style";
import { useCustomFormSubmit } from "../../../../hooks/useCustomFormSubmit";
import { dateFormFormat } from "../../../../utils/dates";

const General = ({ formik }) => {
  const { isEditable, setIsDirty } = useCustomFormSubmit();
  const { t } = useTranslation();

  function CustomTextField(props) {
    const { trulyAnError, ...other } = props;
    return React.createElement(TextField, {
      ...other,
      error: trulyAnError || props.error,
    });
  }

  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Wrapper style={{ padding: "12px" }}>
            <Content component={"div"}>
              <Grid container spacing={2}>
                {isEditable ? (
                  <>
                    <Grid item xs={12} sm={4}>
                      <InputField
                        id="name"
                        label={t("Course Name")}
                        name="name"
                        value={formik.values["course"].name}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "course.name",
                            e.target.value.replace(/^\s+/g, "")
                          );
                          setIsDirty(true);
                        }}
                        helperText={
                          formik.touched.course?.name
                            ? formik.errors.course?.name
                            : ""
                        }
                        error={
                          formik.touched.course?.name &&
                          Boolean(formik.errors.course?.name)
                        }
                        required
                        width="97%"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateField
                        label={t("Start Date")}
                        value={formik.values.course.start_time}
                        id="start_time"
                        name="start_time"
                        // disablePast
                        onChange={(date) => {
                          setIsDirty(true);
                          formik.setFieldValue("course.start_time", date);
                        }}
                        shouldDisableDate={(date) =>
                          date <= dayjs().subtract(1, "day") &&
                          formik.values.course.start_time !== date
                        }
                        // slots={{
                        //   textField: CustomTextField,
                        // }}
                        slotProps={{
                          textField: {
                            error:
                              formik.touched.course?.start_time &&
                              Boolean(formik.errors.course?.start_time),
                            helperText: formik.touched.course?.start_time
                              ? t(formik.errors.course?.start_time)
                              : "",
                            size: "small",
                          },
                        }}
                        required
                        width="97%"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DateField
                        id="end_time"
                        label={t("End Date")}
                        value={formik.values.course.end_time}
                        name="end_time"
                        shouldDisableDate={(date) =>
                          date <= dayjs().subtract(1, "day") &&
                          formik.values.course.end_time !== date
                        }
                        onChange={(date) => {
                          setIsDirty(true);
                          formik.setFieldValue("course.end_time", date);
                        }}
                        slotProps={{
                          textField: {
                            error:
                              formik.touched.course?.end_time &&
                              Boolean(formik.errors.course?.end_time),
                            helperText: formik.touched.course?.end_time
                              ? t(formik.errors.course?.end_time)
                              : "",
                            size: "small",
                          },
                        }}
                        required
                        width="97%"
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    {formik.values.course.name && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel
                          label={"Name"}
                          info={formik.values.course.name}
                        />
                      </Grid>
                    )}

                    {formik.values.course.start_time && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel
                          label={"Start Date"}
                          info={dateFormFormat(formik.values.course.start_time)}
                        />
                      </Grid>
                    )}

                    {formik.values.course.end_time && (
                      <Grid item xs={12} sm={4}>
                        <InfoLabel
                          label={"End Date"}
                          info={dateFormFormat(formik.values.course.end_time)}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Content>
          </Wrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default General;
