import styled from "styled-components";
import { breakpoints, flex } from "../../styles/vars";

export const LinkWrapper = styled.div`
  text-align: right;
  margin: 10px 0px;
  color: #004169;
  font-weight: 600;
`;

export const StyledForm = styled.form`
  width: 100%;

  padding-top: 60px;
  display: flex;
  ${flex.col}
  ${breakpoints.laptop} {
    width: 50%;
  }
`;

export const InputWrapper = styled.div`
  margin: 10px 0px;
`;

export const PasswordInputWrapper = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: flex-start;
  position: relative;
`;
export const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextFieldTitle = styled.div`
  margin-bottom: 10px;
`;
