import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types"; // Import PropTypes
import { styled } from "styled-components";
import { colors } from "../../../constants/colors";

// Create a styled component for the fieldset box
const StyledFieldsetBox = styled(Box)`
  letter-spacing: 0.00938em;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid ${colors.border};
  width: fit-content;
  text-align: center;
  padding: 20px;
`;

const RadioFieldSet = ({ title = "", children }) => {
  return (
    <StyledFieldsetBox component="fieldset">
      <legend>{title}</legend>
      {children}
    </StyledFieldsetBox>
  );
};

// Define propTypes for the title prop
RadioFieldSet.propTypes = {
  title: PropTypes.string,
};

export default RadioFieldSet;
