import React, { useState } from "react";
import { styled } from "styled-components";
import { Box, Tab, Tabs, } from "@mui/material";

import PropTypes from "prop-types";
import { colors } from "../../constants/colors.js";

export const StyledTab = styled(Tabs)`
  && {
    background-color: ${colors.background};
    color: ${colors.primary};
  }
`;

export const TabItem = styled(Tab)`
  && {
    color: ${colors.primary};
    text-transform: inherit;

    &&.Mui-selected {
      color: ${colors.primary};
    }
  }
`;

export function CustomTabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TabBar = ({ tabs = [], selected }) => {
  const [activeTab, setActiveTab] = useState(parseInt(selected) - 1);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div>
      <StyledTab
        value={activeTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: colors.primarySelected,
          },
        }}
      >
        {tabs.map((item, index) => (
          <TabItem label={item.label} key={index} {...a11yProps(index)} />
        ))}
      </StyledTab>

      {tabs.map((item, index) => (
        <CustomTabPanel value={activeTab} key={index} index={index}>
          {item.component}
        </CustomTabPanel>
      ))}
    </div>
  );
};

TabBar.propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.array.isRequired,
  selected: PropTypes.number.isRequired,
};
