import { Typography } from "@mui/material";
import { colors } from "../../constants/colors";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  margin: 15px;
  // padding: 12px;
  background: white;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const NoShadaowWrapper = styled.div`
  border: 2px solid
    ${({ border = "#0b439629" }) =>
      border && border.toLowerCase() === "none" ? "inherit" : "#0b439629"};
  margin: 5px;
  padding: 0 0 10px 0;
  // background: white;
  border-radius: 12px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const Wrap = styled.div`
  margin: 5px;
  padding: 4px 0px;
  width: 100%;
`;

export const Heading = styled(Typography)`
  && {
    // margin: 0 4px;
    border-bottom: 2px solid ${colors.border};
    padding: 6px 10px;
    color: ${colors.primary};
    background-color: ${colors.background};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  && {
    margin: 5px;
    color: ${colors.primary};
  }
  padding-bottom: 5px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainModuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;