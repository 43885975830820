import React from 'react';
import { TableCell } from '@mui/material';
import { fonts } from '../../../styles/vars';

const AppTableCell = ({ children, ...rest }) => {
  return (
    <TableCell sx={{ fontFamily: fonts.body,fontWeight: 'inherit' }} {...rest}>
      {children}
    </TableCell>
  );
};

export default AppTableCell;
