import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import Print from "./print";
import Generate from "./generate";
import General from "./General";
import Finance from "./Finance";
import ContactInfo from "./ContactInfo";
import useAuth from "../../../hooks/useAuth";
import { colors } from "../../../constants/colors";
import useNotistack from "../../../hooks/useNotistack";
import ErrorTabItem from "../../../components/UI/ErrorTabItem";
import { entitiesList, masterReference } from "../../../constants/masterData";
import OverlayLoader from "../../../components/UI/OverlayLoader";
import { getSchoolDropdownList } from "../../../services/schools";
import { StyledTab, a11yProps, TabItem } from "../../../components/TabBar";
import { useCustomFormSubmit } from "../../../hooks/useCustomFormSubmit";
import { useRecordSelection } from "../../../hooks/useRecordSelection";
import { getEntitiesList } from "../../../services/master";
import { toTitleCase } from "../../../utils/utility";
import { checkEmailApi } from "../../../services/auth";
import { emailRegex } from "../../../constants/vars";

const Teacher = () => {
  // Define initial form values
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    place_of_birth: "",
    citizenship: "",
    hr_number: "",
    // dob: dayjs(),
    address_id: 0,
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    situation: "",
    religion: "",
    work_contract: "",
    social_security_number: "",
    health_insurance_provider: "",
    iban_number: "",
    account_owner: "",
    entity_type: "",
    entity_id: "",
    company_id: 0,
  };

  const { user } = useAuth();

  const validateSchema = Yup.object().shape({
    first_name: Yup.string()
      .max(50, "Last Name should not be more than 50 characters")
      .required("REQUIRED"),
    last_name: Yup.string()
      .max(50, "Last Name should not be more than 50 characters")
      .required("REQUIRED"),
    email: Yup.string()
      .email("Enter a valid Email")
      .test("email-valid", "Invalid email format", function (value) {
        if (value && !emailRegex.test(value)) {
          return this.createError({
            path: "email",
            message: "Invalid email format",
          });
        }
        return true;
      })
      .test(
        "email",
        "Email already exist", // <- key, message
        function (value) {
          if (emailRegex.test(value)) {
            return new Promise((resolve, reject) => {
              // for add teacher ( from ADMIN/HR/ACCOUNTANT - entity id will be sent from this.parent and for MANAGER from user profile of the logged in manager)
              checkEmailApi(
                value,
                masterReference.USER,
                formik.values.entity_id
                  ? formik.values.entity_id
                  : user.code === masterReference.HR ||
                    user.code === masterReference.ACCOUNTANT
                  ? this.parent.entity_id
                  : user.entity_id,
                formik.values.company_id || 0,
                requestId || 0,
                user.code === masterReference.SCHOOL_MANAGER
                  ? "school"
                  : user.code === masterReference.FOREIGN_INSTITUTE_MANAGER
                  ? "foreign_institute"
                  : this.parent.entity_type
              )
                .then((res) => {
                  if (res.data.EmailExist) {
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                })
                .catch((error) => {
                  resolve(false);
                });
            });
          }
        }
      )
      .required("REQUIRED"),
    // dob: Yup.date().required("REQUIRED"),
    address_line_1: Yup.string().required("REQUIRED"),
    postal_code: Yup.string().required("REQUIRED"),
    city: Yup.string().required("REQUIRED"),
    state: Yup.string().required("REQUIRED"),
    country: Yup.string().required("REQUIRED"),
    entity_type:
      user.code === masterReference.HR ||
      user.code === masterReference.ACCOUNTANT
        ? Yup.string().required("REQUIRED")
        : Yup.string(),
    entity_id:
      user.code === masterReference.HR ||
      user.code === masterReference.ACCOUNTANT
        ? Yup.string().required("REQUIRED")
        : Yup.string(),
  });

  const [printData, setPrintData] = useState({});
  const [data, setData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [EntitiesNameList, setEntitiesNameList] = useState([]);
  const { selectedRecord, setSelectedIndex } = useRecordSelection();
  // to show notify message
  const { notify } = useNotistack();

  // setting default values
  useEffect(() => {
    setData(initialValues);
  }, []);

  // Get various form submission-related data from a custom hook
  const {
    requestId,
    isEditable,
    setEditable,
    formSubmitAPI,
    isFormSubmitRequested,
    setRequestId,
    setIsDirty,
    printRecord,
    setPrintRecord,
    generateRecord,
    setGenerateRecord,
    setDisableGenerate,
    setFormSubmitRequested,
  } = useCustomFormSubmit();

  useEffect(() => {
    // Initialize form data based on selectedRecord
    if (selectedRecord && selectedRecord.first_name) {
      let selectedData = {
        first_name: "",
        last_name: "",
        email: "",
        place_of_birth: "",
        citizenship: "",
        hr_number: "",
        // dob: null,
        address_id: 0,
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        situation: "",
        religion: "",
        work_contract: "",
        social_security_number: "",
        health_insurance_provider: "",
        iban_number: "",
        account_owner: "",
        entity_type: "",
        entity_id: "",
        company_id: 0,
      };

      // Populate selectedData with values from selectedRecord

      selectedData.first_name = selectedRecord?.first_name;
      selectedData.last_name = selectedRecord?.last_name;
      selectedData.email = selectedRecord.email;
      selectedData.place_of_birth = selectedRecord.place_of_birth;
      selectedData.citizenship = selectedRecord.citizenship;
      selectedData.hr_number = selectedRecord.hr_number;
      // selectedData.dob = dayjs(selectedRecord.dob);
      selectedData.situation = selectedRecord.situation;
      selectedData.religion = selectedRecord.religion;
      selectedData.work_contract = selectedRecord.work_contract;
      selectedData.social_security_number =
        selectedRecord.social_security_number;
      selectedData.health_insurance_provider =
        selectedRecord.health_insurance_provider;
      selectedData.iban_number = selectedRecord.iban_number;
      selectedData.account_owner = selectedRecord.account_owner;
      selectedData.address_id = selectedRecord?.address[0]?.address_id;
      selectedData.address_line_1 = selectedRecord?.address[0]?.address_line_1;
      selectedData.address_line_2 = selectedRecord?.address[0]?.address_line_2;
      selectedData.city = selectedRecord?.address[0]?.city;
      selectedData.postal_code = selectedRecord?.address[0]?.postal_code;
      selectedData.state = selectedRecord?.address[0]?.state;
      selectedData.country = selectedRecord?.address[0]?.country;
      selectedData.entity_type = selectedRecord.entity_type;
      selectedData.entity_id = selectedRecord.entity_id;
      selectedData.company_id = selectedRecord.company_id;
      setDisableGenerate(false);
      setData(selectedData);
      if (
        user.code === masterReference.SCHOOL_MANAGER ||
        user.code === masterReference.FOREIGN_INSTITUTE_MANAGER ||
        user.code === masterReference.HR ||
        user.code === masterReference.ACCOUNTANT
      ) {
        setEditable(true);
      }
      setPrintData(selectedData);
    } else {
      // Reset the form when no selectedRecord is available
      formik.resetForm();
    }
  }, [selectedRecord]);
  useEffect(() => {
    // Update formik values when data changes
    formik.setValues(data);
  }, [data]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    // Prepare data for form submission
    let submittingData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      place_of_birth: values.place_of_birth,
      citizenship: values.citizenship,
      hr_number: values.hr_number,
      // dob: values.dob.format("YYYY-MM-DD"),
      // entity_type: masterReference.TEACHER,
      situation: values.situation,
      religion: values.religion,
      work_contract: values.work_contract,
      social_security_number: values.social_security_number,
      health_insurance_provider: values.health_insurance_provider,
      account_owner: values.account_owner,
      iban_number: values.iban_number,
      entity_type: values.entity_type,
      entity_id: values.entity_id,
      address: [
        {
          address_id: values.address_id,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          address_type: "current",
          postal_code: values.postal_code,
          city: values.city,
          state: values.state,
          country: values.country,
        },
      ],
    };
    let requestParams = {
      id: requestId,
      data: submittingData,
    };
    // Submit the form data using the formSubmitAPI function
    const res = await formSubmitAPI(requestParams);
    setIsLoading(false);
    if (res.isOk) {
      setIsDirty(false);
      setEditable(true);
      await setRequestId(null);
      setRequestId(requestId ? requestParams.id : res.data.teacher_id);
      setSelectedIndex(requestId ? requestId : res.data.teacher_id);

      if (requestId) {
        notify("Teacher Information Updated", "success");
      } else {
        notify("Teacher Added", "success");
      }
    } else {
      console.log("error>>>", res.error);
      for (const [key, value] of Object.entries(res.error)) {
        notify(`${key}: ${value}`, "error");
      }
    }
  };

  // Initialize formik with form configuration
  const formik = useFormik({
    initialValues: data,
    validationSchema: validateSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Check if a form submission is requested
    if (isFormSubmitRequested && isEditable) {
      // Trigger the form submission when isFormSubmitRequested is true
      formik.handleSubmit();
      // Reset the flag after submission
      // setFormSubmitRequested(false);
    }
  }, [
    isFormSubmitRequested,
    isEditable,
    formik.handleSubmit,
    setFormSubmitRequested,
  ]);

  useEffect(() => {
    if (
      user.code === masterReference.HR ||
      user.code === masterReference.ACCOUNTANT
    ) {
      formik.setFieldValue("entity_type", entitiesList[0].value || "");
    }
  }, []);

  // to get School list from list api
  useEffect(() => {
    if (
      (user.code === masterReference.HR ||
        user.code === masterReference.ACCOUNTANT) &&
      formik.values.entity_type
    ) {
      let requestParams = {
        entity_type: formik.values.entity_type,
        company_id: formik.values.company_id,
      };
      getEntitiesList(requestParams)
        .then((res) => {
          if (res.isOk) {
            console.log("entites name list", res.data);
            res.data.forEach((ele) => {
              ele.label = toTitleCase(ele.label);
            });
            setEntitiesNameList(res.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [formik.values.company_id, formik.values.entity_type]);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/g, "");
    formik.setFieldValue(name, trimmedValue);
    setIsDirty(true);
  };

  const handlePrintClose = () => {
    setPrintRecord(false);
  };

  return (
    <>
      <Print
        open={printRecord}
        onClose={handlePrintClose}
        printData={printData}
        EntityTypeList={entitiesList}
        EntitiesNameList={EntitiesNameList}
      />

      <OverlayLoader show={isLoading}>
        {generateRecord ? (
          <div
            style={{
              padding: "24px",
            }}
            id="simple-tabpanel-2"
            aria-labelledby="simple-tab-2"
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setGenerateRecord(false);
                  setDisableGenerate(false);
                }}
              >
                Show Other Tabs
              </Button>
            </div>
            <br />
            <Generate
              requestId={requestId}
              entity_id={formik.values.entity_id}
              entity_type={formik.values.entity_type}
            />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <StyledTab
              value={activeTab}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: colors.secondary,
                },
              }}
            >
              {/* Define tabs for navigation */}
              <ErrorTabItem
                label={"General"}
                {...a11yProps(0)}
                error={
                  (formik.touched.first_name &&
                    Boolean(formik.errors.first_name)) ||
                  (formik.touched.last_name &&
                    Boolean(formik.errors.last_name)) ||
                  (formik.touched.email && Boolean(formik.errors.email)) ||
                  (formik.touched.entity_type &&
                    Boolean(formik.errors.entity_type)) ||
                  (formik.touched.entity_id && Boolean(formik.errors.entity_id))
                }
              />
              <ErrorTabItem
                label={"Contact Information"}
                {...a11yProps(1)}
                error={
                  (formik.touched.address_line_1 &&
                    Boolean(formik.errors.address_line_1)) ||
                  (formik.touched.city && Boolean(formik.errors.city)) ||
                  (formik.touched.state && Boolean(formik.errors.state)) ||
                  (formik.touched.country && Boolean(formik.errors.country)) ||
                  (formik.touched.pincode && Boolean(formik.errors.pincode))
                }
              />
              <TabItem label={"Finance"} {...a11yProps(2)} />
            </StyledTab>

            {/* Render tab content based on activeTab */}
            <div
              style={{
                padding: "24px",
                display: activeTab === 0 ? "" : "none",
              }}
              id="simple-tabpanel-0"
              aria-labelledby="simple-tab-0"
            >
              <General
                formik={formik}
                handleFormChange={handleFormChange}
                EntityTypeList={entitiesList}
                EntitiesNameList={EntitiesNameList}
              />
            </div>

            <div
              style={{
                padding: "24px",
                display: activeTab === 1 ? "" : "none",
              }}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              <ContactInfo
                formik={formik}
                handleFormChange={handleFormChange}
              />
            </div>

            <div
              style={{
                padding: "24px",
                display: activeTab === 2 ? "" : "none",
              }}
              id="simple-tabpanel-2"
              aria-labelledby="simple-tab-2"
            >
              <Finance formik={formik} handleFormChange={handleFormChange} />
            </div>
          </form>
        )}
      </OverlayLoader>
    </>
  );
};

export default Teacher;
